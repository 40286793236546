import React from 'react';

import './styles/TimeDistDays.css';

export const Days = [
	{
		id: 0,
		text: 'All',
	},
	{
		id: 1,
		text: 'Mon',
	},
	{
		id: 2,
		text: 'Tue',
	},
	{
		id: 3,
		text: 'Wed',
	},
	{
		id: 4,
		text: 'Thu',
	},
	{
		id: 5,
		text: 'Fri',
	},
	{
		id: 6,
		text: 'Sat',
	},
	{
		id: 7,
		text: 'Sun',
	},
];

const TimeDistDays = props => {
	return (
		<div className="timedist-days">
			{Days.map(day => (
				<div key={day.id} className={`timedist-day ${props.active.id === day.id ? 'active' : ''}`} onClick={e => props.onChange(e, day)}>
					{day.text}
				</div>
			))}
		</div>
	)
}

export default TimeDistDays;