import React from 'react';
import Humanize from 'humanize-plus';
import moment from 'moment';

import './styles/ReportDetailStatsBar.css';

const ReportDetailStatsBar = ({ total, startDate, endDate, reportType }) => {
	const fieldName = reportType.toLowerCase();

	return (
		<div className="campaign-statboxes report-detail-stat-boxes">
			{startDate && (
				<div className="campaign-statbox report-detail-stat-box">
					<div className="campaign-statbox-title">Start Date</div>
					<div className="campaign-statbox-total">
						{moment(startDate, 'X').format('DD/MM/YYYY')}
					</div>
				</div>
			)}

			{endDate && (
				<div className="campaign-statbox report-detail-stat-box">
					<div className="campaign-statbox-title">End Date</div>
					<div className="campaign-statbox-total">
						{moment(endDate, 'X').format('DD/MM/YYYY')}
					</div>
				</div>
			)}

			<div className="campaign-statbox report-detail-stat-box">
				<div className="campaign-statbox-title">Total {`${reportType}`}</div>
				<div className="campaign-statbox-total">{Humanize.intComma(total[`total_${fieldName}_count`])}</div>
			</div>

			<div className="campaign-statbox report-detail-stat-box">
				<div className="campaign-statbox-title">Total Flagged {`${reportType}`}</div>
				<div className="campaign-statbox-total flagged">{Humanize.intComma(total[`flagged_${fieldName}_count`])}</div>
				<div className="campaign-statbox-detail">(%{total.total_of[`flagged_${fieldName}_count`]} of Total)</div>
			</div>

			{reportType === 'Click' && (
				<div className="campaign-statbox report-detail-stat-box">
					<div className="campaign-statbox-title">Total Rejected {`${reportType}`}</div>
					<div className="campaign-statbox-total rejected">{Humanize.intComma(total.rejected_click_count)}</div>
					<div className="campaign-statbox-detail">(%{total.total_of.rejected_click_count} of Total)</div>
				</div>
			)}
		</div>
	);
};

export default ReportDetailStatsBar;