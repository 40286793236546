import React from 'react';
import { useQuery } from 'react-query';
import ReactNumeric, { predefinedOptions } from 'react-numeric';

import './ExchangeRate.css';

export async function fetchRate({ payout_currency, revenue_currency }) {
  return (await fetch(
    `https://api.exchangeratesapi.io/latest?base=${payout_currency}&symbols=${revenue_currency}`
  )).json();
}

export default function ExchangeRate({
  exchangeRate,
  payout_currency,
  revenue_currency,
  onChangeExchangeRate,
  onSaveExchangeRate,
}) {
  const { data, isLoading, error } = useQuery(
    ['exchange', { payout_currency, revenue_currency }],
    fetchRate
  );

  return (
    <div className="exchange-rate">
      <div className="exchange-rate-payout">
        <div className="exchange-rate-title">Payout Currency</div>
        <div className="exchange-rate-content">1.000 {payout_currency}</div>
      </div>
      <div className="exchange-rate-column">=</div>
      <div className="exchange-rate-revenue">
        <div className="exchange-rate-title">Revenue Currency</div>
        <div className="exchange-rate-content">
          <ReactNumeric
            value={exchangeRate}
            preDefined={predefinedOptions.dollarPos}
            currencySymbol=""
            outputFormat="number"
            decimalPlaces={3}
            onChange={onChangeExchangeRate}
            onBlur={onSaveExchangeRate} />
          {revenue_currency}
        </div>
        {!isLoading && !error && data?.rates?.[revenue_currency] &&
          <div className="exchange-rate-sub" title="Use this rate" onClick={() => {
            onChangeExchangeRate(null, data.rates[revenue_currency].toFixed(3));
            onSaveExchangeRate();
          }}>
            ({data.rates[revenue_currency].toFixed(3)} currently)
					</div>
        }
      </div>
    </div>
  )
}