import React from 'react';
import Humanize from 'humanize-plus';
import get from 'lodash/get';
import { Icon } from 'interceptd-ui';

import './styles/DetailStatsBoxes.css';

const DetailStatsBoxes = props => {
	const {
		data,
		conversionRate,
		showInstalls,
		showImpressions,
		loading,
	} = props;

	const totalImpressions = get(data, 'impressions.total', 0);
	const flaggedImpressions = get(data, 'impressions.flagged', 0);
	const cleanImpressions = get(data, 'impressions.clean', 0);
	const rejectedImpressions = get(data, 'impressions.rejected', 0);

	const totalClick = get(data, 'click.total', 0);
	const rejectedClick = get(data, 'click.rejected', 0);
	const flaggedClick = get(data, 'click.flagged', 0);
	const cleanClick = get(data, 'click.clean', 0);

	const totalInstall = get(data, 'install.total', 0);
	const rejectedInstall = get(data, 'install.rejected', 0);
	const suspiciousInstall = get(data, 'install.flagged', 0);
	const cleanInstall = get(data, 'install.clean', 0);

	const totalEvent = get(data, 'event.total', 0);
	const uniqueEvent = get(data, 'event.unique', 0);
	const rejectedEvent = get(data, 'event.rejected', 0);
	const suspiciousEvent = get(data, 'event.flagged', 0);
	const cleanEvent = get(data, 'event.clean', 0);

	const conversionRatePercent = (totalInstall / (totalClick || 1)) * 100;
	const cleanConversionRatePercent = (totalInstall / (cleanClick || 1)) * 100;

	const clickThroughPercent = totalClick * 100 / (totalImpressions || 1);

	return (
		<div className={`campaign-statboxes ${loading ? 'campaign-statboxes-loading' : ''}`}>
			{showImpressions && <div className="campaign-statbox">
				<div className="campaign-statbox-title">Impression</div>
				<div className="campaign-statbox-total">
					<span tooltip={Humanize.intComma(totalImpressions)}>{Humanize.compactInteger(totalImpressions, 1)}</span>
				</div>
				<div className="campaign-statbox-detail">
					<span className="stats-green" tooltip={Humanize.intComma(cleanImpressions)}>{Humanize.compactInteger(cleanImpressions, 1)}</span> clean, <span className="stats-yellow" tooltip={Humanize.intComma(flaggedImpressions)}>{Humanize.compactInteger(flaggedImpressions, 1)}</span> flagged and <span className="stats-red" tooltip={Humanize.intComma(rejectedImpressions)}>{Humanize.compactInteger(rejectedImpressions, 1)}</span> rejected
				</div>
			</div>}
			{showImpressions && totalImpressions > 0 && (
				<div className="campaign-statbox">
					<div className="campaign-statbox-cr">
						<span tooltip="Click Through Rate">CTR: <span>{Number.isNaN(clickThroughPercent) ? '-' : Humanize.intComma(clickThroughPercent, 2)} %</span></span>
						<div className="campaign-statbox-cr-arrow-wrapper">
							<span className="campaign-statbox-cr-arrow" /> <Icon i="arrow-right" />
						</div>
						<span className="stats-green"></span>
					</div>
				</div>
			)}
			<div className="campaign-statbox">
				<div className="campaign-statbox-title">Clicks</div>
				<div className="campaign-statbox-total">
					<span tooltip={Humanize.intComma(totalClick)}>{Humanize.compactInteger(totalClick, 1)}</span>
				</div>
				<div className="campaign-statbox-detail">
					<span className="stats-green" tooltip={Humanize.intComma(cleanClick)}>{Humanize.compactInteger(cleanClick, 1)}</span> clean <span className="stats-yellow" tooltip={Humanize.intComma(flaggedClick)}>{Humanize.compactInteger(flaggedClick, 1)}</span> flagged and <span className="stats-red" tooltip={Humanize.intComma(rejectedClick)}>{Humanize.compactInteger(rejectedClick, 1)}</span> rejected
				</div>
			</div>
			{conversionRate && totalImpressions > 0 && (
				<div className="campaign-statbox">
					<div className="campaign-statbox-cr">
						<span tooltip="Total Conversion Rate">Total CR: <span>{Number.isNaN(conversionRatePercent) ? '-' : Humanize.intComma(conversionRatePercent, 2)} %</span></span>
						<div className="campaign-statbox-cr-arrow-wrapper">
							<span className="campaign-statbox-cr-arrow" /> <Icon i="arrow-right" />
						</div>
						<span className="stats-green" tooltip="Clean Conversion Rate">Clean CR: <span className="stats-green">{Number.isNaN(cleanConversionRatePercent) ? '-' : Humanize.intComma(cleanConversionRatePercent, 2)} %</span></span>
					</div>
				</div>
			)}
			{showInstalls && (
				<div className="campaign-statbox">
					<div className="campaign-statbox-title">Installs</div>
					<div className="campaign-statbox-total">
						<span tooltip={Humanize.intComma(totalInstall)}>{Humanize.compactInteger(totalInstall, 1)}</span>
					</div>
					<div className="campaign-statbox-detail">
						<span className="stats-green" tooltip={Humanize.intComma(cleanInstall)}>{Humanize.compactInteger(cleanInstall, 1)}</span> clean <span className="stats-yellow" tooltip={Humanize.intComma(suspiciousInstall)}>{Humanize.compactInteger(suspiciousInstall, 1)}</span> flagged and <span className="stats-red" tooltip={Humanize.intComma(rejectedInstall)}>{Humanize.compactInteger(rejectedInstall, 1)}</span> rejected
					</div>
				</div>
			)}
			<div className="campaign-statbox">
				<div className="campaign-statbox-title">Events</div>
				<div className="campaign-statbox-total">
					<span tooltip={Humanize.intComma(totalEvent)}>
						{Humanize.compactInteger(totalEvent, 1)}
					</span>
				</div>
				<div className="campaign-statbox-detail">
					<span className="stats-green" tooltip={Humanize.intComma(cleanEvent)}>{Humanize.compactInteger(cleanEvent, 1)}</span> clean, <span className="stats-yellow" tooltip={Humanize.intComma(suspiciousEvent)}>{Humanize.compactInteger(suspiciousEvent, 1)}</span> flagged, <span className="stats-red" tooltip={Humanize.intComma(rejectedEvent)}>{Humanize.compactInteger(rejectedEvent, 1)}</span> rejected and <span className="stats-purple" tooltip={Humanize.intComma(uniqueEvent)}>{Humanize.compactInteger(uniqueEvent, 1)}</span> unique
				</div>
			</div>
		</div>
	)
}

DetailStatsBoxes.defaultProps = {
	showInstalls: true
}

export default DetailStatsBoxes;
