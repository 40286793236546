import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import get from 'lodash/get';
import moment from 'moment';
import unionBy from 'lodash/unionBy';
import union from 'lodash/union';
import uniq from 'lodash/uniq';
import findIndex from 'lodash/findIndex';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';
import Papa from 'papaparse';
import {
	Box,
	NoData,
	FraudGraph,
	Actions,
	PageTitle,
	Breadcrumbs,
	FilterBox,
	Button,
	Select,
	Info,
	DatePicker,
	Input,
	AppIcon,
} from 'interceptd-ui';

import Table from '../../../components/Table';
import FloatingBox from '../../../components/FloatingBox';

import { columns } from './components/Columns';

import Local from '../../../services/localStorage';
import Settings from '../../../services/settings';
import Api from '../../../services/api';
import SendEvent from '../../../services/events';

import { RenderTableColumns } from '../../../utils/object';
import { downloadFile } from '../../../utils/file';
import { getTimestamp } from '../../../utils/transform';
import {
	shadowText,
	renderAlarmName,
	getInitialDateFilter,
	isShadow,
} from '../../../utils/misc';

import { CampaignBlacklistTypes } from '../../../constants/Campaign';
import { RuleNameMaps } from '../../../constants/Rules';

import withTitle from '../../withTitle';

import './styles/List.css';

Papa.SCRIPT_PATH = "/papaparse.js";

class SubPublishers extends Component {
	state = {
		detailSubpubPath: null,

		loading: true,
		clickFetching: true,
		alarmFetching: true,
		eventFetching: true,
		impFetching: false,
		isBlacklisting: false,
		showImpressionColumn: false,

		subpubs: [],
		sources: [],
		add_subpubs: [],
		eventColumns: [],
		alarmColumns: [],
		remove_subpubs: [],
		eventListOptions: [],
		publisherOptions: [],
		selectedEventColumns: [],
		alarmMap: {},
		eventMap: {},
		clickMap: {},
		campaign: {},
		sourceNameMap: {},
		eventInstallMap: {},

		filters: {
			alarm: null,
			blacklist: null,
			source: get(this, 'props.location.state.sourceId', null),
			search: null,
			...getInitialDateFilter(),
		},

		sendEmail: !!Settings.get('emailMeBlockSummary'),
		supported_rules: Local.getItem('supported_rules'),
		supported_campaign_rules: Local.getItem('supported_campaign_rules'),
	}

	subpubIdSeparator = '_#_';

	papaConf = {
		header: true,
		skipEmptyLines: true,
		dynamicTyping: true,
	};

	componentDidMount = () => {
		this.getCampaignSources();
	}

	getCampaignSources = () => {
		const { match } = this.props;

		Api.getLeoparCampaigns({ id: match.params.id })
			.then(response => {
				const [campaign] = get(response, 'data.data', []);
				const { sources } = campaign;
				const sourceNameMap = {};

				sources.forEach((s) => {
					sourceNameMap[s.id] = s;
				});

				this.setState({
					campaign,
					sources,
					sourceNameMap,
					publisherOptions: sources.slice().map(s => ({
						value: s.id,
						label: s.name,
					})),
				}, this.getData);
			})
			.catch(() => {
				this.setState({ loading: false });
				toast.error(`Couldn't get sources`);
			});
	}

	getData = () => {
		Api.cancelAll();
		this.setState({
			subpubs: [],
			eventInstallMap: {},
			alarmMap: {},
			eventMap: {},
			clickMap: {},
			detailSubpubPath: null,
			isBlacklisting: false,
			showImpressionColumn: false,
			loading: true,
			clickFetching: true,
			alarmFetching: true,
			eventFetching: true,
			impFetching: false,
			eventList: [],
			eventColumns: [],
			eventListOptions: [],
			selectedEventColumns: [],
			alarmColumns: [],
			add_subpubs: [],
			remove_subpubs: [],
		}, () => {
			this.getSubpubList();
			this.getAlarms();
			this.getEventList();
			this.getClicks();
			this.getImpressions();
		});
	}

	getQuery = () => {
		const { filters } = this.state;
		const { match } = this.props;
		return {
			campaign_id: match.params.id,
			ts_start: getTimestamp(filters.start),
			ts_end: getTimestamp(filters.end, true),
		};
	}

	getSubpubList = async () => {
		try {
			this.setState({
				loading: true
			});

			const subpubsResponse = await Api.leoparGetSubpubList(this.getQuery());
			const data = subpubsResponse?.data?.data || [];
			const eventInstallMap = {};

			data.forEach((subpub) => {
				const mapData = {};
				const alarmStats = this.calculateAlarmDetail(subpub);
				mapData.install_based_alarm_total = alarmStats.install_based_alarm_total;
				mapData.install_based_alarm_count = alarmStats.install_based_alarm_count;
				mapData.event_based_alarm_total = alarmStats.event_based_alarm_total;
				mapData.event_based_alarm_count = alarmStats.event_based_alarm_count;

				// mapData.source_name = sourceNameMap[mapData.source_id].name;
				// mapData.publisher_id = sourceNameMap[mapData.source_id].publisher;

				const clean_event = subpub.clean_event_count || 0;
				const flagged_event = subpub.flagged_event_count || 0;
				const rejected_event = subpub.rejected_event_count || 0;
				const unique_event = subpub.unique_event_count || 0

				mapData.total_event = (clean_event + flagged_event + rejected_event);
				mapData.event_count = {
					clean: clean_event,
					rejected: rejected_event,
					flagged: flagged_event,
					unique: unique_event,
					total: mapData.total_event,
					infoContent: alarmStats.event_based_alarm_count.infoContent,
				};

				const clean_install = subpub.clean_install_count || 0;
				const flagged_install = subpub.flagged_install_count || 0;
				const rejected_install = subpub.rejected_install_count || 0;

				mapData.total_install = (clean_install + flagged_install + rejected_install);
				mapData.install_count = {
					clean: clean_install,
					rejected: rejected_install,
					flagged: flagged_install,
					total: mapData.total_install,
					infoContent: alarmStats.install_based_alarm_count.infoContent,
				};

				eventInstallMap[`${subpub.sub_pub_id}${this.subpubIdSeparator}${subpub.source_id}`] = mapData;
			});

			this.setState(({ subpubs }) => ({
				eventInstallMap,
				loading: false,
				subpubs: union(subpubs, Object.keys(eventInstallMap)),
			}));

		} catch (e) {
			this.setState({ loading: false });
		}
	}

	getAlarms = async () => {
		try {
			this.setState({
				alarmFetching: true
			});

			const { match } = this.props;
			const alarms = await Api.leoparGetAlarmList({ campaign_id: match.params.id });
			const data = alarms?.data?.data || [];

			const alarmMap = {};
			data.forEach((alarm) => {
				const mapData = {};

				mapData.is_blocked = alarm.blocks && alarm.blocks.length > 0;
				mapData.alarm_set = union(alarm.alarms, alarm.blocks);
				mapData.blockDate = alarm.ts_block
					? moment(alarm.ts_block, 'X').format('DD MMM YYYY H:mm')
					: null;

				alarmMap[`${alarm.sub_publisher_id}${this.subpubIdSeparator}${alarm.source_id}`] = mapData;
			});

			this.setState(({ subpubs }) => ({
				alarmMap,
				alarmFetching: false,
				subpubs: union(subpubs, Object.keys(alarmMap)),
			}));
		} catch (err) {
			toast.error(`Couldn't fetch alarms.`);
			this.setState({ alarmFetching: false });
		}
	}

	mergeAlarmCount = (alarm) => {
		const total = {};
		for (let key in alarm) {
			const [name] = key.split('_');
			const value = alarm[key];
			total[name] = total[name] || 0;
			total[name] += value;
		}
		return total;
	}

	getAlarmFullName = (shortName) => {
		const { supported_rules } = this.state;
		const sr = find(supported_rules, ['short-name', shortName]);
		return sr ? (RuleNameMaps[sr.name] || sr.name) : shortName;
	}

	getInfoContent = (alarms = {}, field, {
		flagged_install_count,
		rejected_install_count,
		flagged_event_count,
		rejected_event_count,
	}) => {
		if (isEqual(alarms, {})) {
			return null;
		}

		const content = [];
		let fTotal = 0;
		let rTotal = 0;
		Object.keys(alarms).forEach((key) => {
			const name = this.getAlarmFullName(key.replace(/\[Flagged\]/g, ''));

			const isFlag = /\[Flagged\]/.test(key);
			const val = alarms[key];
			if (val) {
				if (isFlag)
					fTotal += val;
				else
					rTotal += val;

				content.push(
					<div key={key}>
						<span className={`info-content-dot ${isFlag ? 'flagged' : 'rejected'}`}>{name}</span>: <span>{val}</span>
					</div>
				);
			}
		});

		const subpubTotal = field === 'event'
			? (flagged_event_count + rejected_event_count)
			: (flagged_install_count + rejected_install_count);

		const subpubTotalF = subpubTotal - fTotal
		if (fTotal > 0 && subpubTotalF > 0) {
			content.push(
				<div key="subpublisher-f-alarms">
					<span className="info-content-dot flagged">Subpublisher Alarms</span>: <span>{subpubTotalF}</span>
				</div>
			);
		}

		const subpubTotalR = subpubTotal - rTotal
		if (rTotal > 0 && subpubTotalR > 0) {
			content.push(
				<div key="subpublisher-r-alarms">
					<span className="info-content-dot rejected">Subpublisher Alarms</span>: <span>{subpubTotalR}</span>
				</div>
			);
		}

		return (
			<div className="subpub-alarm-info">
				{content}
			</div>
		);
	}

	calculateAlarmDetail = ({
		flagged_event_detail,
		rejected_event_detail,
		flagged_install_detail,
		rejected_install_detail,
		...subpub
	}) => {
		const flaggedEventDetail = this.mergeAlarmCount(flagged_event_detail);
		const rejectedEventDetail = this.mergeAlarmCount(rejected_event_detail);
		const flaggedInstallDetail = this.mergeAlarmCount(flagged_install_detail);
		const rejectedInstallDetail = this.mergeAlarmCount(rejected_install_detail);

		const installAlarmNames = uniq([
			...Object.keys(flaggedInstallDetail),
			...Object.keys(rejectedInstallDetail),
		]);
		const eventAlarmNames = uniq([
			...Object.keys(flaggedEventDetail),
			...Object.keys(rejectedEventDetail),
		]);

		const subpubStats = {
			install_based_alarm_total: 0,
			install_based_alarm_count: {
				flagged: 0,
				rejected: 0,
				total: 0,
				alarms: {},
			},
			event_based_alarm_total: 0,
			event_based_alarm_count: {
				flagged: 0,
				rejected: 0,
				total: 0,
				alarms: {},
			},
		};

		installAlarmNames.forEach((alarm) => {
			const f = flaggedInstallDetail[alarm] || 0;
			const r = rejectedInstallDetail[alarm] || 0;
			subpubStats.install_based_alarm_total += (f + r);
			subpubStats.install_based_alarm_count.flagged += f;
			subpubStats.install_based_alarm_count.rejected += r;
			subpubStats.install_based_alarm_count.total += (f + r);
			if (f !== 0) {
				subpubStats.install_based_alarm_count.alarms[`[Flagged]${alarm}`] = f;
			}
			if (r !== 0) {
				subpubStats.install_based_alarm_count.alarms[alarm] = r;
			}
		});

		eventAlarmNames.forEach((alarm) => {
			const f = flaggedEventDetail[alarm] || 0;
			const r = rejectedEventDetail[alarm] || 0;
			subpubStats.event_based_alarm_total += (f + r);
			subpubStats.event_based_alarm_count.flagged += f;
			subpubStats.event_based_alarm_count.rejected += r;
			subpubStats.event_based_alarm_count.total += (f + r);
			if (f !== 0) {
				subpubStats.event_based_alarm_count.alarms[`[Flagged]${alarm}`] = flaggedEventDetail[alarm] || 0;
			}
			if (r !== 0) {
				subpubStats.event_based_alarm_count.alarms[`${alarm}`] = rejectedEventDetail[alarm] || 0;
			}
		});

		subpubStats.install_based_alarm_count.infoContent = this.getInfoContent(subpubStats.install_based_alarm_count.alarms, 'install', subpub);
		subpubStats.event_based_alarm_count.infoContent = this.getInfoContent(subpubStats.event_based_alarm_count.alarms, 'event', subpub);

		return subpubStats;
	}

	getEventList = async () => {
		try {
			this.setState({ eventFetching: true });

			const eventResponse = await Api.leoparGetEventList(this.getQuery());
			const eventsData = eventResponse?.data?.data || [];
			const eventMap = {};

			const options = eventsData.map(e => ({
				value: e.event_name,
				label: e.event_name,
			}));

			eventsData.forEach((e) => {
				const mapData = eventMap[`${e.sub_pub_id}${this.subpubIdSeparator}${e.source_id}`] || {};
				const name = e.event_name;

				mapData.events = mapData.events || {};
				mapData.events[name] = mapData.events[name] || {
					clean_amount: 0,
					clean_event_count: 0,
					clean_reattributed_amount: 0,
					clean_reattributed_event_count: 0,
					flagged_amount: 0,
					flagged_event_count: 0,
					flagged_reattributed_amount: 0,
					flagged_reattributed_event_count: 0,
					rejected_amount: 0,
					rejected_event_count: 0,
					rejected_reattributed_amount: 0,
					rejected_reattributed_event_count: 0,
				};

				mapData.events[name].clean_amount += e.clean_amount;
				mapData.events[name].clean_event_count += e.clean_event_count;
				mapData.events[name].clean_reattributed_amount += e.clean_reattributed_amount;
				mapData.events[name].clean_reattributed_event_count += e.clean_reattributed_event_count;
				mapData.events[name].flagged_amount += e.flagged_amount;
				mapData.events[name].flagged_event_count += e.flagged_event_count;
				mapData.events[name].flagged_reattributed_amount += e.flagged_reattributed_amount;
				mapData.events[name].flagged_reattributed_event_count += e.flagged_reattributed_event_count;
				mapData.events[name].rejected_amount += e.rejected_amount;
				mapData.events[name].rejected_event_count += e.rejected_event_count;
				mapData.events[name].rejected_reattributed_amount += e.rejected_reattributed_amount;
				mapData.events[name].rejected_reattributed_event_count += e.rejected_reattributed_event_count;

				eventMap[`${e.sub_pub_id}${this.subpubIdSeparator}${e.source_id}`] = mapData;
			});
			this.setState(({ subpubs }) => ({
				eventMap,
				eventFetching: false,
				subpubs: union(subpubs, eventMap),
				eventListOptions: unionBy(options, 'value'),
			}));
		} catch (e) {
			this.handleError(e);
			this.setState({ eventFetching: false });
		}
	}

	getClicks = async () => {
		try {
			this.setState({ clickFetching: true });

			const response = await Api.getCampaignSubPubsLink(this.getQuery());
			const file = await Api.getFile(response?.data?.data?.sub_pub_list, 'text');
			const clickData = [];

			Papa.parse(file.data, {
				header: true,
				skipEmptyLines: true,
				dynamicTyping: true,
				worker: true,
				step: result => clickData.push(result.data),
				complete: () => {
					const clickMap = {};

					clickData.forEach((click) => {
						const mapData = clickMap[`${click.sub}${this.subpubIdSeparator}${click.src}`] || {
							clean: 0,
							rejected: 0,
							flagged: 0,
							total: 0
						};

						mapData.clean += click.click_count || 0;
						mapData.flagged += click.flagged_click_count || 0;
						mapData.rejected += click.rejected_click_count || 0;
						mapData.total += (click.click_count || 0) + (click.rejected_click_count || 0) + click.flagged_click_count || 0;

						clickMap[`${click.sub}${this.subpubIdSeparator}${click.src}`] = mapData;
					});

					this.setState(({ subpubs }) => ({
						clickMap,
						clickFetching: false,
						subpubs: union(subpubs, Object.keys(clickMap)),
					}));
				},
			});
		} catch (e) {
			this.handleError(e);
			this.setState({ clickFetching: false })
		}
	}

	getImpressions = async () => {
		try {
			const { campaign } = this.state;

			if (campaign?.type !== 'CPM') {
				return null;
			}

			this.setState({ impFetching: true });

			const link = await Api.getSubpubImpressions(this.getQuery());
			const file = await Api.getFile(link?.data?.data?.sub_pub_list_impression, 'text');

			const { data } = Papa.parse(file.data);
			data.splice(0, 1);
			const impData = data && data[0].length > 1 ? data : null;

			this.setState(({ subpubs }) => {
				if (!impData) return {
					impFetching: false,
				};

				const impressionMap = {};
				impData.forEach(([cmp, src, sub, cri, impression_count, flagged]) => {
					if (!src) return;

					const mapData = impressionMap[`${sub}${this.subpubIdSeparator}${src}`] || {};

					mapData.clean = mapData.clean || 0;
					mapData.flagged = mapData.flagged || 0;
					mapData.total = mapData.total || 0;
					mapData.clean += +impression_count || 0;
					mapData.flagged += +flagged || 0;
					mapData.total += +(impression_count || 0) + +(flagged || 0);

					impressionMap[`${sub}${this.subpubIdSeparator}${src}`] = mapData;
				});

				return {
					impressionMap,
					impFetching: false,
					showImpressionColumn: true,
					subpubs: union(subpubs, Object.keys(impressionMap)),
				}
			});
		} catch (e) {
			this.handleError(e);
			this.setState({ impFetching: false });
		}
	}

	handleRemoveBlacklist = () => {
		this.setState({
			add_subpubs: [],
			remove_subpubs: [],
		}, this.manageIntercom)
	}

	handleBlacklistSummary = event => {
		this.setState({
			sendEmail: event.target.checked
		}, () => {
			Settings.set('emailMeBlockSummary', this.state.sendEmail)
		});
	}

	manageIntercom = () => {
		const { add_subpubs, remove_subpubs } = this.state;
		if (add_subpubs.length > 0 || remove_subpubs.length > 0)
			window.hideIntercomFrame && window.hideIntercomFrame()
		else
			window.showIntercomFrame && window.showIntercomFrame()
	}

	handleTableColumns = eventColumns => {
		const selectedEventColumns = [];

		eventColumns.forEach(ec => {
			selectedEventColumns.push({
				label: ec,
				value: ec,
			});
			selectedEventColumns.push({
				label: `r_${ec}`,
				value: `r_${ec}`,
			});
		});

		this.setState({ eventColumns, selectedEventColumns });
	};

	handleError = (err) => {
		if (err.message) {
			toast.error(get(err, 'message', `Error: Can't fetch data.`));
		}
	}

	getCsv = () => {
		try {
			const {
				selectedEventColumns,
				campaign,
				sourceNameMap,
				eventMap,
				alarmMap,
				eventInstallMap,
				clickMap,
			} = this.state;
			const campaignId = get(this.props, 'match.params.id');
			const subpubs = this.filterData().slice();
			const fileName = `${campaign.id}_campaign_subpub_list.csv`;
			const eventKeys = [];

			const csvData = subpubs.map((s) => {
				const [subpubId, sourceId] = s.split(this.subpubIdSeparator);
				const source = sourceNameMap[sourceId];
				const events = eventMap[s] || {};
				const eventInstall = eventInstallMap[s] || {};
				const alarm = alarmMap[s] || {};
				const alarmSet = union((alarm?.alarm_set || []), (eventInstall?.alarm_set || []));
				const clickData = clickMap[s] || {};

				const eventData = Object.values(events?.events || {}).reduce((acc, curr) => {
					acc.clean_amount += curr.clean_amount;
					acc.clean_event_count += curr.clean_event_count;
					acc.clean_reattributed_amount += curr.clean_reattributed_amount;
					acc.clean_reattributed_event_count += curr.clean_reattributed_event_count;
					acc.flagged_amount += curr.flagged_amount;
					acc.flagged_event_count += curr.flagged_event_count;
					acc.flagged_reattributed_amount += curr.flagged_reattributed_amount;
					acc.flagged_reattributed_event_count += curr.flagged_reattributed_event_count;
					acc.rejected_amount += curr.rejected_amount;
					acc.rejected_event_count += curr.rejected_event_count;
					acc.rejected_reattributed_amount += curr.rejected_reattributed_amount;
					acc.rejected_reattributed_event_count += curr.rejected_reattributed_event_count;
					return acc;
				}, {
					clean_amount: 0,
					clean_event_count: 0,
					clean_reattributed_amount: 0,
					clean_reattributed_event_count: 0,
					flagged_amount: 0,
					flagged_event_count: 1,
					flagged_reattributed_amount: 0,
					flagged_reattributed_event_count: 0,
					rejected_amount: 0,
					rejected_event_count: 0,
					rejected_reattributed_amount: 0,
					rejected_reattributed_event_count: 0,
				});
				const subpub = {
					sub_pub_id: subpubId,
					source_name: source.name,
					source_id: source.id,
					campaign_id: campaignId,
					publisher_id: source.publisher,
					alarm_set: alarmSet.join(', '),
					event_based_alarms: Object.keys(eventInstall?.event_based_alarm_count?.alarms || {}).join(', '),
					install_based_alarms: Object.keys(eventInstall?.install_based_alarm_count?.alarms || {}).join(', '),

					// click
					total_click: clickData?.total ?? 0,
					clean_click: clickData?.clean ?? 0,
					flagged_click: clickData?.flagged ?? 0,
					rejected_click: clickData?.rejected ?? 0,

					// install
					total_install: eventInstall?.total_install ?? 0,
					clean_install_count: eventInstall?.install_count?.clean ?? 0,
					flagged_install_count: eventInstall?.install_count?.flagged ?? 0,
					rejected_install_count: eventInstall?.install_count?.rejected ?? 0,

					// event
					total_event: eventInstall?.total_event ?? 0,
					clean_event_count: eventInstall?.event_count?.clean ?? 0,
					flagged_event_count: eventInstall?.event_count?.flagged ?? 0,
					unique_event_count: eventInstall?.event_count?.unique ?? 0,
					rejected_event_count: eventInstall?.event_count?.rejected ?? 0,

					event_based_alarm_total: eventInstall?.event_based_alarm_total ?? 0,
					install_based_alarm_total: eventInstall?.install_based_alarm_total ?? 0,

					// amount
					clean_amount: eventData.clean_amount ?? 0,
					flagged_amount: eventData.flagged_amount ?? 0,
					rejected_amount: eventData.rejected_amount ?? 0,

					clean_reattributed_amount: eventData.clean_reattributed_amount ?? 0,
					flagged_reattributed_amount: eventData.flagged_reattributed_amount ?? 0,
					rejected_reattributed_amount: eventData.rejected_reattributed_amount ?? 0,

					clean_reattributed_event_count: eventData.clean_reattributed_event_count ?? 0,
					flagged_reattributed_event_count: eventData.flagged_reattributed_event_count ?? 0,
					rejected_reattributed_event_count: eventData.rejected_reattributed_event_count ?? 0,
				};

				selectedEventColumns.forEach(({ value }) => {
					const event = events?.events?.[value];
					Object.keys(event || {}).forEach((key) => {
						const keyName = `${value}_${key}`;
						if (eventKeys.indexOf(keyName) === -1) {
							eventKeys.push(keyName);
						}
						subpub[keyName] = event[key];
					});
				});

				return subpub;
			})
				.sort((a, b) => b.total_click - a.total_click)
				.map((c) => {
					eventKeys.forEach((k) => {
						c[k] = c[k] || 0;
					});
					return c;
				});

			downloadFile(Papa.unparse(csvData), fileName);

			SendEvent({
				action: 'SubPublishers Exported',
				sendMixPanel: true,
			});
		} catch (err) {
			toast.error('Something went wrong!');
		}
	}

	filterData = () => {
		const {
			filters,
			subpubs,
			alarmMap,
			eventInstallMap,
		} = this.state;

		return [...subpubs].filter((subpub) => {
			const [subpubId, sourceId] = subpub.split(this.subpubIdSeparator);
			const filterAlarm = filters?.alarm;
			const sb = eventInstallMap?.[subpub] || {};
			const alarm = alarmMap?.[subpub] || {};
			const alarmSet = union(alarm?.alarm_set || [], (sb?.alarm_set || []));

			return (
				(!filters.search || (subpubId.toString().indexOf(filters.search) > -1)) &&
				(!(filterAlarm === 'ASA') || (alarmSet.length > 0)) &&
				(!(filterAlarm === 'AA') || (alarmSet.length > 0 || sb.install_based_alarm_total > 0 || sb.event_based_alarm_total > 0)) &&
				((filterAlarm && filterAlarm !== 'AA' && filterAlarm !== 'ASA') ? ((alarmSet.indexOf(filterAlarm) > -1) || get(sb, `install_based_alarm_count.alarms.${filterAlarm}`, false) || get(sb, `event_based_alarm_count.alarms.${filterAlarm}`, false)) : true) &&
				(!filters.source || +sourceId === +filters.source) &&
				(typeof filters.blacklist === 'boolean' ? !!+(alarm.is_blocked) === filters.blacklist : true)
			);
		});
	}

	handleBlacklist = () => {
		const {
			sendEmail,
			add_subpubs,
			remove_subpubs,
			campaign,
			alarmMap,
		} = this.state;

		this.setState({ isBlacklisting: true });

		add_subpubs.forEach((as) => {
			as.source_id = +as.source_id;
			alarmMap[`${as.subpub_id}${this.subpubIdSeparator}${as.source_id}`] = {
				is_blocked: 1,
				alarm_set: ['CUSTOMER'],
				blockDate: moment().format('DD MMM YYYY H:mm'),
			};
		});

		remove_subpubs.forEach((rs) => {
			rs.source_id = +rs.source_id;
			alarmMap[`${rs.subpub_id}${this.subpubIdSeparator}${rs.source_id}`] = null;
		});

		const pay = {
			campaign_id: +campaign.id,
			email_summary: sendEmail,
			add_subpubs: add_subpubs,
			remove_subpubs: remove_subpubs,
		};

		Api.leoparBlockSubpub(pay)
			.then(() => {
				this.setState({
					add_subpubs: [],
					remove_subpubs: [],
					isBlacklisting: false,
					alarmMap,
				});
			})
			.catch((err) => {
				this.setState({
					isBlacklisting: false,
					error: get(err, 'response', {}),
				});
			});
	}

	renderStatsCell = (props) => {
		const { id, Header } = props.column;
		const {
			clickFetching,
			clickMap,
			eventInstallMap,
			impressionMap,
			eventMap,
			loading,
		} = this.state;

		if (id === 'click' && props?.original?.infoRequired) {
			return (
				<div style={{ display: 'flex' }}>
					<Info position="left">Subpublisher has less than 1000 clicks.</Info>
				</div>
			);
		}

		if (id === 'impression_count') {
			return (
				<div className="campaign-table-fraud-bar-container">
					<FraudGraph
						data={impressionMap[props.original]}
						showUnique={id === 'event'}
						title={Header}
					/>
				</div>
			);
		}

		if (id === 'click' || id === 'install' || id === 'event') {
			const data = id !== 'click'
				? eventInstallMap?.[props.original]?.[`${id}_count`]
				: clickMap?.[props.original];

			return (
				<div className="campaign-table-fraud-bar-container">
					<FraudGraph
						data={data}
						noDataMessage={id === 'click' && !data ? '< 1K' : null}
						showUnique={id === 'event'}
						title={Header}
						loading={(id === 'click' && clickFetching) || loading}
					/>
				</div>
			);
		}

		const isR = id.indexOf('r_') === 0;
		const eventData = eventMap?.[props.original] || {};

		const event = get(eventData, `events['${isR ? id.replace('r_', '') : id}']`, {});
		const cleanField = `clean_${isR ? 'reattributed_' : ''}event_count`;
		const rejectedField = `rejected_${isR ? 'reattributed_' : ''}event_count`;
		const flaggedField = `flagged_${isR ? 'reattributed_' : ''}event_count`;

		const data = {
			name: Header,
			unknown: isR && event.clean_reattributed_event_count === null,
			clean: event[cleanField] || 0,
			rejected: event[rejectedField] || 0,
			flagged: event[flaggedField] || 0,
			total: 0,
		};

		data.total = data.clean + data.flagged + data.rejected;
		data.unknown = isR && event.clean_reattributed_event_count === null;

		return (
			<div className="campaign-table-fraud-bar-container">
				<FraudGraph
					data={data}
					title={Header}
				/>
			</div>
		);
	}

	renderActions = (props) => {
		const { match: { params: { id } } } = this.props;
		const [subpubId, sourceId] = props.original.split(this.subpubIdSeparator);
		const detailSubpubPath = `/campaign/${id}/source/${sourceId}/subpublisher/${subpubId}`;
		return (
			<Actions>
				<Actions.Item onClick={() => this.setState({ detailSubpubPath })}>
					See Detail
				</Actions.Item>
			</Actions>
		)
	}

	handleDateRange = ({ from, to }) => {
		this.setState(prevState => ({
			filters: {
				...prevState.filters,
				start: from,
				end: to,
			}
		}), this.getData);

		Local.setItem('date_filter', {
			from: from,
			to: to,
		})
	}

	handleInput = event => {
		const { value } = event.target;

		this.setState(prevState => ({
			filters: {
				...prevState.filters,
				search: value
			}
		}));
	}

	handleFilter = (value, name) => {
		this.setState(prevState => ({
			filters: {
				...prevState.filters,
				[name]: value,
			}
		}));
	}

	select = (props, e) => {
		const { add_subpubs, remove_subpubs, sourceNameMap } = this.state;
		const [subpubId, sourceId] = props.original.split(this.subpubIdSeparator);
		const source = sourceNameMap[sourceId];

		let newList;
		const item = {
			source_id: sourceId,
			subpub_id: subpubId,
			publisher_id: source.publisher,
		};

		if (e.target.checked === false) {
			const index = findIndex(add_subpubs, o => isEqual(item, o));

			if (index !== -1) {
				newList = [...add_subpubs];
				newList.splice(index, 1)
				return this.setState({
					add_subpubs: newList
				}, this.manageIntercom);
			}

			return this.setState({
				remove_subpubs: [
					...remove_subpubs,
					item
				]
			}, this.manageIntercom);
		}

		const index = findIndex(remove_subpubs, o => isEqual(item, o));
		if (index !== -1) {
			newList = [...remove_subpubs];
			newList.splice(index, 1);
			return this.setState({
				remove_subpubs: newList
			}, this.manageIntercom);
		}

		return this.setState({
			add_subpubs: [
				...add_subpubs,
				item
			]
		}, this.manageIntercom);
	}

	isSelected = (props) => {
		const {
			add_subpubs,
			remove_subpubs,
			sourceNameMap,
			alarmMap,
		} = this.state;
		const [subpubId, sourceId] = props.original.split(this.subpubIdSeparator);
		const source = sourceNameMap[sourceId];
		const alarm = alarmMap[props.original];
		const item = {
			source_id: sourceId,
			subpub_id: subpubId,
			publisher_id: source?.publisher,
		};

		if (findIndex(add_subpubs, o => isEqual(item, o)) !== -1) {
			return true
		} else if (findIndex(remove_subpubs, o => isEqual(item, o)) !== -1) {
			return false
		}

		return Boolean(alarm?.is_blocked);
	}

	generateFilterBox = () => {
		const {
			searchText,
			publisherOptions,
			filters,
			supported_rules,
			supported_campaign_rules,
		} = this.state;

		return (
			<FilterBox>
				<div className="filter-column filter-column-left">
					<Input
						placeholder="Search"
						name="sub_pub_id"
						value={searchText}
						onChange={this.handleInput}
					/>
				</div>

				<div className="filter-column filter-column-right">
					<Select
						clearable
						placeholder="Alarm Type"
						options={[
							{
								value: 'AA',
								label: 'Any Alarm',
							},
							{
								value: 'ASA',
								label: 'Any Subpublisher Alarm',
							},
							...([...supported_rules, ...supported_campaign_rules]).filter(r => r.type !== 'campaign_rule').map(r => ({
								value: r['short-name'],
								label: renderAlarmName(RuleNameMaps[r.name] || r.name)
							}))
						]}
						value={[filters.alarm]}
						onChange={([val]) => this.handleFilter(val, 'alarm')} />
					<Select
						clearable
						placeholder="Status"
						options={CampaignBlacklistTypes}
						value={[filters.blacklist]}
						onChange={([val]) => this.handleFilter(val, 'blacklist')} />
					<Select
						clearable
						placeholder="All Sources"
						options={publisherOptions}
						value={[filters.source]}
						onChange={([val]) => this.handleFilter(val, 'source')} />
					<DatePicker
						value={{
							from: filters.start,
							to: filters.end,
						}}
						onChange={this.handleDateRange} />
				</div>
			</FilterBox>
		);
	}

	render() {
		const campaignId = get(this.props, 'match.params.id');
		const url = get(this.props, 'match.url');
		const {
			eventInstallMap,
			alarmMap,
			clickMap,
			eventMap,
			impressionMap,
			sourceNameMap,

			campaign,
			detailSubpubPath,
			supported_rules,
			supported_campaign_rules,
			isBlacklisting,
			sendEmail,
			add_subpubs,
			remove_subpubs,
			eventColumns,
			eventListOptions,
			selectedEventColumns,
			subpubClicks,
			showImpressionColumn,
			eventFetching,
			clickFetching,
			loading,
			alarmFetching,
			impFetching,
		} = this.state;

		if (detailSubpubPath) {
			window.open(`${window.location.origin}${detailSubpubPath}`);

			this.setState({
				detailSubpubPath: null,
			});
		}

		const data = this.filterData();

		return (
			<div className="campaign-subpublishers">
				<PageTitle>
					<Breadcrumbs>
						<Breadcrumbs.Crumb><Link to="/">Campaigns</Link></Breadcrumbs.Crumb>
						<Breadcrumbs.Crumb><Link to={`/campaign/${campaignId}`}>Campaign Detail</Link></Breadcrumbs.Crumb>
						<Breadcrumbs.Crumb><Link to={url}>Subpublishers</Link></Breadcrumbs.Crumb>
					</Breadcrumbs>
					<PageTitle.Title>
						Subpublishers - <AppIcon app={campaign.app_name ? campaign.app_info : undefined} isShadow={isShadow()} /> {shadowText(campaign.name, 'Example Campaign')}
					</PageTitle.Title>
				</PageTitle>
				<div className="campaign-subpubs">
					{this.generateFilterBox()}
					<Box
						className="box-table-wrapper"
						right={
							<React.Fragment>
								<Select
									multi
									clearable
									wrapperClassName="subpub-list-event-select"
									placeholder="Events"
									options={eventListOptions}
									value={eventColumns}
									onChange={this.handleTableColumns} />
								<Button bgColor="transparent" onClick={this.getCsv} loading={loading || alarmFetching || clickFetching || eventFetching || impFetching}>CSV Export</Button>
							</React.Fragment>
						}>
						<Table
							data={data}
							columns={RenderTableColumns(
								columns({
									select: this.select,
									isSelected: this.isSelected,
									supportedRules: supported_rules,
									supportedCampaignRules: supported_campaign_rules,
									eventListOptions,
									renderStatsCell: this.renderStatsCell,
									renderActions: this.renderActions,
									subpubIdSeparator: this.subpubIdSeparator,
									subpubClicks,
									showImpressionColumn,
									eventInstallMap,
									alarmMap,
									clickMap,
									eventMap,
									impressionMap,
									sourceNameMap,
								}),
								selectedEventColumns
							)}
							PadRowComponent={() => null}
							loading={
								(loading && alarmFetching && clickFetching && eventFetching)
								|| ((loading || alarmFetching || clickFetching || eventFetching) && data.length === 0)
							}
							onPageChange={() => window.scrollTo(0, 0)}
							defaultSortDesc
							defaultSorted={[{
								id: "click",
								desc: true
							}]}
							showPageSizeOptions={false}
							showPageJump
							NoDataComponent={() => <NoData icon="box">You have no subpublisher</NoData>}
						/>
					</Box>

					<FloatingBox
						acceptText="Save Changes"
						rejectText="Unselect All"
						className={add_subpubs.length > 0 || remove_subpubs.length > 0 ? 'active' : ''}
						onAccept={this.handleBlacklist}
						onReject={this.handleRemoveBlacklist}
						onEmailChange={this.handleBlacklistSummary}
						sendEmail={sendEmail}
						isAccepting={isBlacklisting}
						add_subpubs={add_subpubs}
						remove_subpubs={remove_subpubs} />
				</div>
			</div>
		);
	}
}

export default withTitle(SubPublishers, 'Sub Publisher List', 'id');
