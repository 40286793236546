import React from 'react';

import Local from '../../services/localStorage';

const ShadowImage = ({ className, src, alt, ...rest }) => {
	const shadow = Local.getItem('shadow_login');

	return (
		<img
			className={`${className || ''} ${shadow ? 'shadow-image' : ''}`}
			src={src}
			alt={alt || ''}
			{...rest}
		/>
	);
};

export default ShadowImage;