import React from 'react';
import Humanize from 'humanize-plus';
import { NavLink } from 'react-router-dom';
import { subDays } from 'date-fns';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import {
	PageTitle,
	SecondaryNav,
	FilterBox,
	DatePicker,
	Tooltip,
	Loader,
	Box,
} from 'interceptd-ui';

import Table from '../../components/Table';

import Api from '../../services/api';
import Settings from '../../services/settings';

import { transform } from 'interceptd-ui/dist/utils';

import { default as Columns } from './SavingsColumns';

import './styles/Savings.css';

function TotalStatsItem({
	mini,
	title,
	tooltip,
	total,
	color,
	detail,
}) {
	return (
		<div className={`savings-total-item ${mini ? 'is-mini' : ''}`}>
			<div className="savings-total-item-title">{title}</div>
			<div className="savings-total-item-total" style={color ? { color: `var(--${color})` } : {}}>
				<Tooltip tooltip={tooltip} position="right">${total}</Tooltip>
			</div>
			{detail && (
				<div className="savings-total-item-detail">
					{detail.map((data, key) => (
						<Tooltip key={key} tooltip={data.tooltip}>
							<div className="savings-total-item-data" style={{ color: `var(--${data.color})` }}>${data.count}</div>
						</Tooltip>
					))}
				</div>
			)}
		</div>
	)
}

function TotalStats({ data }) {
	const totalSavings = Object.keys(data.total)
		.reduce((acc, cur) => acc + data.total[cur], 0);
	const totalRejected = Object.keys(data.total)
		.filter(key => key.indexOf('rejected') !== -1)
		.reduce((acc, cur) => acc + data.total[cur], 0);
	const totalFlagged = Object.keys(data.total)
		.filter(key => key.indexOf('flagged') !== -1)
		.reduce((acc, cur) => acc + data.total[cur], 0);

	const impressionSavings = Object.keys(data.total)
		.filter(key => key.indexOf('impression') !== -1)
		.reduce((acc, cur) => acc + data.total[cur], 0);
	const impressionRejected = Object.keys(data.total)
		.filter(key => key.indexOf('impression') !== -1)
		.filter(key => key.indexOf('rejected') !== -1)
		.reduce((acc, cur) => acc + data.total[cur], 0);
	const impressionFlagged = Object.keys(data.total)
		.filter(key => key.indexOf('impression') !== -1)
		.filter(key => key.indexOf('flagged') !== -1)
		.reduce((acc, cur) => acc + data.total[cur], 0);

	const clickSavings = Object.keys(data.total)
		.filter(key => key.indexOf('click') !== -1)
		.reduce((acc, cur) => acc + data.total[cur], 0);
	const clickRejected = Object.keys(data.total)
		.filter(key => key.indexOf('click') !== -1)
		.filter(key => key.indexOf('rejected') !== -1)
		.reduce((acc, cur) => acc + data.total[cur], 0);
	const clickFlagged = Object.keys(data.total)
		.filter(key => key.indexOf('click') !== -1)
		.filter(key => key.indexOf('flagged') !== -1)
		.reduce((acc, cur) => acc + data.total[cur], 0);

	const installSavings = Object.keys(data.total)
		.filter(key => key.indexOf('install') !== -1)
		.reduce((acc, cur) => acc + data.total[cur], 0);
	const installRejected = Object.keys(data.total)
		.filter(key => key.indexOf('install') !== -1)
		.filter(key => key.indexOf('rejected') !== -1)
		.reduce((acc, cur) => acc + data.total[cur], 0);
	const installFlagged = Object.keys(data.total)
		.filter(key => key.indexOf('install') !== -1)
		.filter(key => key.indexOf('flagged') !== -1)
		.reduce((acc, cur) => acc + data.total[cur], 0);

	const eventSavings = Object.keys(data.total)
		.filter(key => key.indexOf('event') !== -1)
		.reduce((acc, cur) => acc + data.total[cur], 0);
	const eventRejected = Object.keys(data.total)
		.filter(key => key.indexOf('event') !== -1)
		.filter(key => key.indexOf('rejected') !== -1)
		.reduce((acc, cur) => acc + data.total[cur], 0);
	const eventFlagged = Object.keys(data.total)
		.filter(key => key.indexOf('event') !== -1)
		.filter(key => key.indexOf('flagged') !== -1)
		.reduce((acc, cur) => acc + data.total[cur], 0);

	return (
		<div className="savings-total-block">
			<TotalStatsItem
				title="Total Savings"
				total={Humanize.compactInteger(totalSavings, 1)}
				tooltip={`$${Humanize.intComma(totalSavings, 2)}`}
				color="green"
				detail={[
					{
						tooltip: `$${Humanize.intComma(totalRejected, 2)} from Rejected`,
						count: Humanize.compactInteger(totalRejected, 1),
						color: 'red',
					},
					{
						tooltip: `$${Humanize.intComma(totalFlagged, 2)} from Flagged`,
						count: Humanize.compactInteger(totalFlagged, 1),
						color: 'yellow',
					},
				]}
			/>
			<TotalStatsItem
				title="Savings From Impression"
				total={Humanize.compactInteger(impressionSavings, 1)}
				tooltip={`$${Humanize.intComma(impressionSavings, 2)}`}
				detail={[
					{
						tooltip: `$${Humanize.intComma(impressionRejected, 2)} from Rejected`,
						count: Humanize.compactInteger(impressionRejected, 1),
						color: 'red',
					},
					{
						tooltip: `$${Humanize.intComma(impressionFlagged, 2)} from Flagged`,
						count: Humanize.compactInteger(impressionFlagged, 1),
						color: 'yellow',
					},
				]}
			/>
			<TotalStatsItem
				title="Savings From Click"
				total={Humanize.compactInteger(clickSavings, 1)}
				tooltip={`$${Humanize.intComma(clickSavings, 2)}`}
				detail={[
					{
						tooltip: `$${Humanize.intComma(clickRejected, 2)} from Rejected`,
						count: Humanize.compactInteger(clickRejected, 1),
						color: 'red',
					},
					{
						tooltip: `$${Humanize.intComma(clickFlagged, 2)} from Flagged`,
						count: Humanize.compactInteger(clickFlagged, 1),
						color: 'yellow',
					},
				]}
			/>
			<TotalStatsItem
				title="Savings From Install"
				total={Humanize.compactInteger(installSavings, 1)}
				tooltip={`$${Humanize.intComma(installSavings, 2)}`}
				detail={[
					{
						tooltip: `$${Humanize.intComma(installRejected, 2)} from Rejected`,
						count: Humanize.compactInteger(installRejected, 1),
						color: 'red',
					},
					{
						tooltip: `$${Humanize.intComma(installFlagged, 2)} from Flagged`,
						count: Humanize.compactInteger(installFlagged, 1),
						color: 'yellow',
					},
				]}
			/>
			<TotalStatsItem
				title="Savings From Event"
				total={Humanize.compactInteger(eventSavings, 1)}
				tooltip={`$${Humanize.intComma(eventSavings, 2)}`}
				detail={[
					{
						tooltip: `$${Humanize.intComma(eventRejected, 2)} from Rejected`,
						count: Humanize.compactInteger(eventRejected, 1),
						color: 'red',
					},
					{
						tooltip: `$${Humanize.intComma(eventFlagged, 2)} from Flagged`,
						count: Humanize.compactInteger(eventFlagged, 1),
						color: 'yellow',
					},
				]}
			/>
		</div>
	)
}

class Savings extends React.Component {
	state = {
		archivedCampaigns: Settings.get('archivedCampaigns', 'account') || [],
		dates: {
			from: subDays(new Date(), 6),
			to: new Date(),
		},
		expanded: {},
		campaigns: [],
		publishers: [],
		apps: [],
		totalSavings: {},
		fetching: true,
	}

	componentDidMount = () => {
		this.getData();
	}

	getData = async () => {
		const { archivedCampaigns } = this.state;

		try {
			const campaignsResponse = await Api.getLeoparCampaigns();
			const campaigns = sortBy(
				(campaignsResponse?.data?.data || [])
					.filter((c) => c.application_type !== 'leancheck')
					.filter(c => archivedCampaigns.indexOf(c.id) === -1)
					.map(c => ({
						...c,
						sources: sortBy(c.sources, 'id').reverse(),
					})),
					'id'
			)
			.reverse()
			.map(c => ({
				...c,
				sources: c.sources.map(s => ({
					...s,
					campaign: c,
					kpis: c.kpis,
				}))
			}))
			const apps = uniqBy(campaigns.map(c => c.app_info), 'id')
				.map(app => ({
					...app,
					sources: campaigns.filter(c => c.app_info.id === app.id)
				}));


			const publishersResponse = await Api.getPartners({ size: 1000 });
			const publishers = uniqBy(
				(publishersResponse?.data?.data || [])
					.filter(p => campaigns.some(c => c.sources.some(s => s.partner === p.id)))
					.map(p => ({
						...p,
						sources: campaigns
							.filter(c => c.sources.some(s => s.partner === p.id))
							.map(c => ({
								...c,
								sources: c.sources.filter(s => s.partner === p.id)
							}))
					})),
					'id'
				);

			this.setState({
				campaigns,
				publishers,
				apps,
			}, this.getSavings);
		} catch({ response }) {
			console.log(response);
		}
	}

	getSavings = async () => {
		const { dates, campaigns, publishers } = this.state;

		if ( campaigns.length === 0 || publishers.length === 0 ) {
			return this.setState({
				fetching: false
			});
		}

		this.setState({
			fetching: true,
		});
		try {
			const savingsResponse = await Api.getSavings({
				source_ids: campaigns.flatMap(c => c.sources.map(s => s.id)),
				ts_start: transform.getTimeStamp(dates.from),
				ts_end: transform.getTimeStamp(dates.to, true),
			});
			const savings = savingsResponse?.data?.data;

			const totalSavings = Object.keys(savings).reduce((acc, source_id) => {
				const newAcc = { ...acc };
				const stats = savings[source_id];
				const campaign = campaigns.find(c => c.sources.some(s => +s.id === +source_id));
				const source = campaign.sources.find(s => +s.id === +source_id);
				const publisher = publishers.find(p => +p.id === +source.partner);

				newAcc.sources[+source.id] = {
					...source,
					savings: { ...stats }
				}

				if ( newAcc.campaigns[+campaign.id] ) {
					Object.keys(stats).forEach((key) => {
						newAcc.campaigns[+campaign.id].savings[key] = +(newAcc.campaigns[+campaign.id].savings[key] || 0) + +(stats[key] || 0);
					})
				} else {
					newAcc.campaigns[+campaign.id] = {
						...campaign,
						savings: { ...stats }
					}
				}

				if ( publisher && newAcc.publishers[+publisher.id] ) {
					Object.keys(stats).forEach((key) => {
						newAcc.publishers[+publisher.id].savings[key] = +(newAcc.publishers[+publisher.id].savings[key] || 0) + +(stats[key] || 0);
					})
				} else if ( publisher ) {
					newAcc.publishers[+publisher.id] = {
						...publisher,
						savings: { ...stats }
					}
				}

				if ( newAcc.apps[campaign.app_info.id] ) {
					Object.keys(stats).forEach((key) => {
						newAcc.apps[campaign.app_info.id].savings[key] = +(newAcc.apps[campaign.app_info.id].savings[key] || 0) + +(stats[key] || 0);
					})
				} else {
					newAcc.apps[campaign.app_info.id] = {
						...campaign.app_info,
						savings: { ...stats }
					}
				}

				Object.keys(stats).forEach((key) => {
					newAcc.total[key] = +(newAcc.total[key] || 0) + +(stats[key] || 0);
				})

				return newAcc;
			}, {
				sources: {},
				campaigns: {},
				publishers: {},
				apps: {},
				total: {},
			})

			this.setState({
				totalSavings,
				fetching: false,
			})
		} catch({ response }) {
			this.setState({
				fetching: false,
			})
		}
	}

	getStats = (original, column) => {
		const { match } = this.props;
		const { totalSavings } = this.state;

		const type = match.url.split('/')[2] || 'campaigns';

		if ( !totalSavings.sources ) return 0;

		const savings = totalSavings?.[original.campaign ? 'sources' : type]?.[original.id]?.savings ?? {};
		return Object.keys(savings)
			.filter((key) => key.indexOf(column) !== -1)
			.reduce((acc, key) => acc + savings[key], 0);
	}

	renderCell = ({ original, column, value }) => {
		const { match } = this.props;
		const { totalSavings } = this.state;

		const type = match.url.split('/')[2] || 'campaigns';

		if ( !totalSavings.sources ) return null;

		const savings = totalSavings?.[original.campaign ? 'sources' : type]?.[original.id]?.savings ?? {};

		const total = Object.keys(savings)
			.filter(key => column.id === 'total' ? true : key.indexOf(column.id) !== -1)
			.reduce((acc, cur) => acc + savings[cur], 0);
		const rejected = Object.keys(savings)
			.filter(key => column.id === 'total' ? true : key.indexOf(column.id) !== -1)
			.filter(key => key.indexOf('rejected') !== -1)
			.reduce((acc, cur) => acc + savings[cur], 0);
		const flagged = Object.keys(savings)
			.filter(key => column.id === 'total' ? true : key.indexOf(column.id) !== -1)
			.filter(key => key.indexOf('flagged') !== -1)
			.reduce((acc, cur) => acc + savings[cur], 0);

		return (
			<TotalStatsItem
				mini
				title=""
				total={Humanize.compactInteger(total, 1)}
				tooltip={`$${Humanize.intComma(total, 2)}`}
				color={column.id === 'total' ? 'green' : null}
				detail={[
					{
						tooltip: `$${Humanize.intComma(rejected, 2)} from Rejected`,
						count: Humanize.compactInteger(rejected, 1),
						color: 'red',
					},
					{
						tooltip: `$${Humanize.intComma(flagged, 2)} from Flagged`,
						count: Humanize.compactInteger(flagged, 1),
						color: 'yellow',
					},
				]}
			/>
		)
	}

	exportCSV = () => {
		console.log('export');
	}

	renderSources = ({ original }) => {
		const { match } = this.props;
		const type = match.url.split('/')[2] || 'campaigns';

		return (
			<div className="sources">
				<Table
					data={original.sources}
					columns={
						Columns({
							isSource: true,
							type,
							getStats: this.getStats,
							renderCell: this.renderCell,
						})
					}
					pages={0}
					PadRowComponent={() => null}
					sortable={false}
					showPagination={false}
					pageSize={original.sources.length}
					getTrProps={(state, rowInfo, column) => {
						return type !== 'publishers' && rowInfo.original.status !== 1 ? ({
							style: {
								filter: 'grayscale(1)',
								textDecoration: 'line-through'
							}
						}) : ({});
					}}
				/>
			</div>
		)
	}

	render() {
		const { match } = this.props;
		const { dates, expanded, totalSavings, fetching } = this.state;

		const type = match.url.split('/')[2] || 'campaigns';

		return (
			<div className="savings-page">
				<PageTitle>
					<PageTitle.Title>Estimated Savings</PageTitle.Title>
				</PageTitle>
				<FilterBox>
					<FilterBox.Left>
						<SecondaryNav>
							<SecondaryNav.NavItem>
								<NavLink
									exact
									activeClassName="active"
									to="/savings"
								>
									Campaigns
								</NavLink>
							</SecondaryNav.NavItem>
							<SecondaryNav.NavItem>
								<NavLink
									exact
									activeClassName="active"
									to="/savings/publishers"
								>
									Publishers
								</NavLink>
							</SecondaryNav.NavItem>
							<SecondaryNav.NavItem>
								<NavLink
									exact
									activeClassName="active"
									to="/savings/apps"
								>
									Apps
								</NavLink>
							</SecondaryNav.NavItem>
						</SecondaryNav>
					</FilterBox.Left>
					<FilterBox.Right>
						<DatePicker
							value={dates}
							onChange={(value) => this.setState({ dates: value }, this.getSavings)}
						/>
					</FilterBox.Right>
				</FilterBox>

				{totalSavings.total && <TotalStats data={totalSavings} />}

				<Box
					className="box-table-wrapper campaign-list-table-box"
					title={type.toUpperCase()}
					right={
						<React.Fragment>
							{fetching && <Loader mini size={25} />}
							{/*<Button bgColor="transparent" onClick={this.exportCSV}>
								<Icon i="download" /> CSV
							</Button>*/}
						</React.Fragment>
					}
				>
					<Table
						data={this.state[type]}
						columns={
							Columns({
								type,
								getStats: this.getStats,
								renderCell: this.renderCell,
								onExpandedChange: (index) => this.setState((prevState) => ({
									expanded: prevState.expanded[index] ? {} : {
										[index]: true
									}
								})),
							})
						}
						pages={0}
						pageSize={this.state[type].length}
						loading={fetching}
						showPagination={false}
						defaultSortDesc={true}
						expanded={expanded}
						PadRowComponent={() => null}
						SubComponent={type === 'campaigns' ? this.renderSources : null}
						getTrGroupProps={(state, rowInfo, column) => {
							if (rowInfo && expanded[rowInfo.viewIndex]) {
								return ({
									className: '-open'
								})
							}
						}}
					/>
				</Box>
			</div>
		)
	}
}

export default Savings;
