import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import sortBy from 'lodash/sortBy';
import axios from 'axios';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import find from 'lodash/find';
import { toast } from 'react-toastify';
import semverCompare from 'semver/functions/compare';
import semverValid from 'semver/functions/valid';
import semverCoerce from 'semver/functions/coerce';
import {
	Box,
	Loader,
	NoData,
	PageTitle,
	Breadcrumbs,
	FilterBox,
	DatePicker,
} from 'interceptd-ui';

import DetailStatsBoxes from '../components/DetailStatsBoxes';
import { Days as DayList } from '../components/TimeDistDays';
import CTITDistGraph from '../components/CTITDistGraph';
import AppVersionPie from '../components/AppVersionPie';
import OSVersionPie from '../components/OSVersionPie';
import SDKVersionPie from '../components/SDKVersionPie';
import IPAnonymityPie from '../components/IPAnonymityPie';
import TimeDistGraph from '../components/TimeDistGraph';
import TimeDistDays from '../components/TimeDistDays';
import CountryDistMap from '../components/CountryDistMap';

import DailyPerformanceChart from './DailyPerformanceChart';

import { shadowText, getInitialDateFilter } from '../../../utils/misc';
import { fillHours, getTimestamp } from '../../../utils/transform';

import Api from '../../../services/api';
import Local from '../../../services/localStorage';

import withTitle from '../../withTitle';

import './styles/Detail.css';

class Detail extends Component {
	state = {
		sourceName: '',
		campaignId: this.props.match.params.id,
		sourceId: this.props.match.params.source_id,
		subpubId: this.props.match.params.subpub_id,
		subpub: {
			status: 1,
		},
		filters: getInitialDateFilter(),

		timeDistActiveDay: DayList[0],
		installTimeDistFetching: false,
		installTimeDist: null,

		ctitDistFetching: false,
		ctitDist: null,

		appVersionDistFetch: false,
		appVersionsLabels: [],
		appVersions: [],

		OSVersionFetch: false,
		OSVersionsLabels: [],
		OSVersions: [],

		SDKVersionFetch: false,
		SDKVersionsLabels: [],
		SDKVersions: [],

		IPAnonymityFetch: false,
		IPAnonymityLabels: ['Legit', 'Anonymous'],
		IPAnonymity: [],

		countryDistFetching: false,
		countryDist: [],

		subpubStatsFetching: true,
		subpubStats: {
			click: {
				clean: 0,
				rejected: 0,
				flagged: 0,
				total: 0,
			},
			install: {
				clean: 0,
				rejected: 0,
				total: 0,
				flagged: 0,
			},
			event: {
				clean: 0,
				rejected: 0,
				total: 0,
				flagged: 0,
				unique: 0,
			},
		},
	}

	componentDidMount = () => {
		this.getData();
	}

	getQuery = () => {
		const {
			campaignId,
			sourceId,
			subpubId,
			filters,
			timeDistActiveDay,
		} = this.state;

		const query = {
			subpub_id: subpubId,
			campaign_id: campaignId,
			source_id: sourceId,
			ts_start: getTimestamp(filters.start),
			ts_end: getTimestamp(filters.end, true),
		};

		if (timeDistActiveDay.id) {
			query.day = timeDistActiveDay.text;
		}

		return query;
	}

	getSubpubCountryDist = () => {
		const query = this.getQuery();


		const setData = (data) => {
			this.setState({
				countryDist: data || [],
				countryDistFetching: false,
			});
		}

		this.setState({ countryDistFetching: true });

		Api.getLeoparSubpubGraphData({
			...query,
			type: 'install',
			metric_type: 'country',
		}).then(response => setData(get(response, 'data.data', [])))
			.catch(() => setData());
	}

	getSubpubCTITDist = () => {
		const query = this.getQuery();

		this.setState({ ctitDistFetching: true });

		Api.getLeoparSubpubGraphData({
			...query,
			type: 'install',
			metric_type: 'log_ctit',
		})
			.then((response) => {
				this.setState({
					ctitDist: get(response, 'data.data', []),
					ctitDistFetching: false,
				});
			})
			.catch(() => this.setState({ ctitDistFetching: false }));
	}

	getSubpubAppVersionDist = () => {
		const query = this.getQuery();

		this.setState({ appVersionDistFetch: true });

		Api.getLeoparSubpubGraphData({
			...query,
			type: 'install',
			metric_type: 'app_version',
		})
			.then((response) => {
				const appVersionData = this.limitPieData(get(response, 'data.data', []));
				this.setState({
					appVersionsLabels: appVersionData.map(d => d.version),
					appVersions: appVersionData.map(d => d.value),
					appVersionDistFetch: false,
				});
			})
			.catch(() => {
				toast.error(`Cant fetch 'App Version Distribution' data.`);
				this.setState({ appVersionDistFetch: false });
			});
	}

	getSubpubOSVersionDist = () => {
		const query = this.getQuery();
		this.setState({ OSVersionFetch: false });

		Api.getLeoparSubpubGraphData({
			...query,
			type: 'install',
			metric_type: 'os_version',
		})
			.then((response) => {
				const rawData = get(response, 'data.data', []).reduce((acc, curr) => {
					const [major, minor] = curr.version.split('.');
					const version = `${major}${minor ? `.${minor}` : ''}`;
					acc[version] = acc[version] || { version, value: 0 };
					acc[version].value += curr.value;
					return acc;
				}, {});
				const osVersionData = this.limitPieData(Object.values(rawData));
				this.setState({
					OSVersionFetch: false,
					OSVersionsLabels: osVersionData.map(d => d.version),
					OSVersions: osVersionData.map(d => d.value),
				});
			})
			.catch((err) => {
				console.log(err)
				this.setState({ OSVersionFetch: false });
			});
	}

	getSubpubSDKVersionDist = () => {
		const query = this.getQuery();
		this.setState({ SDKVersionFetch: false });

		Api.getLeoparSubpubGraphData({
			...query,
			type: 'install',
			metric_type: 'sdk_version'
		})
			.then((response) => {
				const sdkVersionData = this.limitPieData(get(response, 'data.data', []));
				this.setState({
					SDKVersionFetch: false,
					SDKVersionsLabels: sdkVersionData.map(d => d.version),
					SDKVersions: sdkVersionData.map(d => d.value),
				});
			})
			.catch(() => {
				this.setState({ SDKVersionFetch: false });
			});
	}

	getSubpubIPAnonymityDist = () => {
		const query = this.getQuery();

		this.setState({ IPAnonymityFetch: true });

		Api.getLeoparSubpubGraphData({
			...query,
			type: 'install',
			metric_type: 'ip_anonymity',
		})
			.then((response) => {
				const data = get(response, 'data.data', []);
				this.setState({
					IPAnonymityFetch: false,
					IPAnonymity: data.length > 0 ? [data[0].Legit, data[1].Anonymous] : [],
				});
			})
			.catch((err) => {
				this.setState({
					IPAnonymityFetch: false,
				})
			});
	}

	getSubpubStats = () => {
		const query = this.getQuery();

		query.sub_id = query.subpub_id;

		delete query.subpub_id;
		delete query.day;

		this.setState({ subpubStatsFetching: true });
		axios.all([
			Api.getCampaignSubPubsLink(query),
			Api.leoparGetSubpubList(query)
		])
			.then(axios.spread((clicks, leopar) => {
				const leoparD = get(leopar, 'data.data[0]', {});
				const clicksD = get(clicks, 'data.data', {});

				this.setState(prevState => ({
					subpubStatsFetching: false,
					subpubStats: {
						click: !clicksD || isEqual(clicksD, {}) ? ({
							...prevState.subpubStats.click
						}) : ({
							clean: +clicksD.click_count,
							rejected: +clicksD.rejected_click_count,
							flagged: +clicksD.flagged_click_count,
							total: (+clicksD.click_count + +clicksD.rejected_click_count + +clicksD.flagged_click_count),
						}),
						install: !leoparD || isEqual(leoparD, {}) ? ({
							...prevState.subpubStats.install
						}) : ({
							clean: +leoparD.clean_install_count,
							rejected: +leoparD.rejected_install_count,
							flagged: +leoparD.flagged_install_count,
							total: (+leoparD.clean_install_count + +leoparD.rejected_install_count + +leoparD.flagged_install_count),
						}),
						event: !leoparD || isEqual(leoparD, {}) ? ({
							...prevState.subpubStats.event
						}) : ({
							clean: +leoparD.clean_event_count,
							rejected: +leoparD.rejected_event_count,
							flagged: +leoparD.flagged_event_count,
							total: (+leoparD.clean_event_count + +leoparD.rejected_event_count + +leoparD.flagged_event_count),
							unique: +leoparD.unique_event_count,
						}),
					},
				}));
			}))
			.catch(() => {
				this.setState({ subpubStatsFetching: false });
			});
	}

	getData = () => {
		this.getSubpubStats();
		this.getSubpubCountryDist();
		this.getTimeDistData();
		this.getSubpubCTITDist();
		this.getSubpubAppVersionDist();
		this.getSubpubOSVersionDist();
		this.getSubpubSDKVersionDist();
		this.getSubpubIPAnonymityDist();
		this.getCampaign();
	}

	getCampaign = async () => {
		try {
			const { subpubId } = this.state;
			const campaignResponse = await Api.getLeoparCampaigns({ id: this.props.match.params.id });
			const campaign = get(campaignResponse, 'data.data[0]');
			const subpubResponse = await Api.leoparGetSubpubList(this.getQuery());
			const subpubs = get(subpubResponse, 'data.data', []);
			const subpub = find(subpubs, ['sub_pub_id', subpubId]);
			const source = find(campaign.sources, ['id', subpub.source_id]);
			this.setState({
				subpub,
				sourceName: source.name
			});
		} catch (err) {
			console.log(err);
		}
	}

	getTimeDistData = () => {
		const query = this.getQuery();

		this.setState({ installTimeDistFetching: true });

		Api.getLeoparSubpubGraphData({
			...query,
			type: 'install',
			metric_type: 'install_time'
		}).then(response => {
			this.setState({
				installTimeDistFetching: false,
				installTimeDist: [
					{
						name: 'Number of Installs',
						type: 'line',
						data: response.data.data.length > 0 ? fillHours(sortBy(response.data.data, 'x')).map(d => ({ ...d, x: String(d.x) })) : [],
					}
				],
			});
		}).catch(() => {
			this.setState({
				installTimeDistFetching: false,
			});
		})
	}

	limitPieData = (data, limit = 9) => {
		const versions = data.filter(d => semverValid(semverCoerce(d.version)));
		const sortedVersions = versions.sort((a, b) => semverCompare(semverCoerce(b.version), semverCoerce(a.version)));
		const invalids = data.filter(d => d.version !== 'unknown' && !semverValid(semverCoerce(d.version))).sort((a, b) => b.value - a.value);
		const unknown = data.filter(d => d.version === 'unknown').map(d => ({
			...d,
			version: `${d.version} (${d.value})`
		}));

		const list = [
			...sortedVersions,
			...invalids,
		].map(d => ({
			...d,
			version: `${d.version} (${d.value})`
		}))

		if (list.length > limit - 1) {
			const limitedList = list.slice(0, limit - 2);
			const others = list.slice(limit - 2).reduce((acc, curr) => ({
				version: `Other (${(acc.value || 0) + curr.value})`,
				value: (acc.value || 0) + curr.value
			}), {});
			return [
				...limitedList,
				...unknown,
				others,
			];
		} else {
			return [
				...list,
				...unknown,
			];
		}
	}

	handleDateRange = ({ from, to }) => {
		this.setState(prevState => ({
			filters: {
				...prevState.filters,
				start: from,
				end: to,
			}
		}), this.getData);

		Local.setItem('date_filter', { 
			from,
			to,
		})
	}

	handleTimeDistDay = (e, day) => {
		this.setState({
			timeDistActiveDay: day
		}, this.getTimeDistData)
	}

	render() {
		const {
			sourceId,
			subpubId,
			filters,
			sourceName,

			appVersionDistFetch,
			appVersionsLabels,
			appVersions,

			subpubStats,
			subpubStatsFetching,

			OSVersionFetch,
			OSVersionsLabels,
			OSVersions,

			SDKVersionFetch,
			SDKVersionsLabels,
			SDKVersions,

			IPAnonymityFetch,
			IPAnonymityLabels,
			IPAnonymity,

			installTimeDistFetching,
			installTimeDist,
			timeDistActiveDay,

			ctitDistFetching,
			ctitDist,

			countryDistFetching,
			countryDist,
		} = this.state;
		const { match } = this.props;

		return (
			<div className="source-detail">
				<PageTitle>
					<Breadcrumbs>
						<Breadcrumbs.Crumb><Link to="/">Campaigns</Link></Breadcrumbs.Crumb>
						<Breadcrumbs.Crumb><Link to={`/campaign/${match.params.id}`}>Campaign Detail</Link></Breadcrumbs.Crumb>
						<Breadcrumbs.Crumb><Link to={`/campaign/${match.params.id}/subpublishers`}>Subpublishers</Link></Breadcrumbs.Crumb>
						<Breadcrumbs.Crumb><Link to={match.url}>Subpublisher Detail</Link></Breadcrumbs.Crumb>
					</Breadcrumbs>
					<PageTitle.Title>
						Subpublisher - {shadowText(`${subpubId} - ${sourceName}`, 'Example SubPublisher')}
					</PageTitle.Title>
				</PageTitle>

				<FilterBox>
					<div className="filter-column filter-column-left" />
					<div className="filter-column filter-column-right">
						{subpubStatsFetching && <Loader mini size={25} />}
						<DatePicker
							value={{
								from: filters.start,
								to: filters.end,
							}}
							onChange={this.handleDateRange} />
					</div>
				</FilterBox>

				<DetailStatsBoxes data={subpubStats} loading={subpubStatsFetching} conversionRate />

				<div className="source-wrapper">

					<div className="irow">
						<div className="icol">
						<Box title="Daily Install & Event Performance">
								<DailyPerformanceChart sourceId={sourceId} subpubId={subpubId} filters={filters} />
							</Box>
						</div>
						<div className="icol">
							<Box title="Country Distribution">
								{countryDistFetching ? <Loader /> : countryDist.length > 0 ? (<CountryDistMap data={countryDist} />) : <NoData />}
							</Box>
						</div>
					</div>

					<div className="irow">
						<div className="icol">
							<Box title="Install Time Distribution">
								<React.Fragment>
									<TimeDistDays active={timeDistActiveDay} onChange={this.handleTimeDistDay} />
									{installTimeDistFetching ? <Loader /> : installTimeDist && installTimeDist[0].data.length > 0 ? (
										<TimeDistGraph
											series={installTimeDist}
											getOptions={options => ({
												...options,
												yaxis: {
													...options.yaxis,
													title: {
														text: 'Number of Installs'
													}
												}
											})} />
									) : <NoData />}
								</React.Fragment>
							</Box>
						</div>
						<div className="icol">
							<Box title="CTIT Distribution">
								<div style={{ height: 55 }} />
								{ctitDistFetching ? <Loader /> : <CTITDistGraph data={ctitDist} />}
							</Box>
						</div>
					</div>
				</div>

				<div className="source-detail-pie-row">
					<div className="source-detail-pie-col">
						<Box title="App Version Distribution">
							{appVersionDistFetch ? <Loader /> : appVersions.length > 0 ? (
								<AppVersionPie
									series={appVersions}
									getOptions={options => ({
										...options,
										legend: {
											width: 170,
										},
										labels: appVersionsLabels
									})} />
							) : <NoData />}
						</Box>
					</div>

					<div className="source-detail-pie-col">
						<Box title="OS Version Distribution">
							{OSVersionFetch ? <Loader /> : OSVersions.length > 0 ? (
								<OSVersionPie
									series={OSVersions}
									getOptions={options => ({
										...options,
										legend: {
											width: 170,
										},
										labels: OSVersionsLabels
									})} />
							) : <NoData />}
						</Box>
					</div>

					<div className="source-detail-pie-col">
						<Box title="SDK Version Distribution">
							{SDKVersionFetch ? <Loader /> : SDKVersions.length > 0 ? (
								<SDKVersionPie
									series={SDKVersions}
									getOptions={options => ({
										...options,
										legend: {
											width: 170,
										},
										labels: SDKVersionsLabels
									})} />
							) : <NoData />}
						</Box>
					</div>

					<div className="source-detail-pie-col">
						<Box title="IP Anonymity">
							{IPAnonymityFetch ? <Loader /> : (
								(IPAnonymity.length > 0 && (IPAnonymity[0] || IPAnonymity[1])) ? (
									<IPAnonymityPie
										series={IPAnonymity}
										getOptions={options => ({
											...options,
											legend: {
												width: 170,
											},
											labels: IPAnonymityLabels
										})} />
								) : <NoData />
							)}
						</Box>
					</div>
				</div>
			</div>
		);
	}
}

export default withTitle(Detail);