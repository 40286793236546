import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Tooltip } from 'interceptd-ui';

import { shadowText } from '../../utils/misc';

export const columns = ({
	renderActions,
	renderStatsCell,
	eventsColumnWidth,
	getTotalStats,
	showImpression,
	sourceImpressionMap,
	hideHeaders,
}) => [
	{
		Header: hideHeaders ? '' : 'Campaign Name',
		id: 'name',
		accessor: 'name',
		cast: 'string',
		minWidth: 350,
		style: {
			overflow: 'visible',
		},
		sticky: true,
		stickyPosition: 'left',
		Cell: props => (
			<span className="campaign-table-name">
				<span className="campaign-table-name" style={{ flex: 'none' }}><Icon i="corner-down-right" size={18} /></span>
				<Link to={`/campaign/${props.original.campaign.id}/source/${props.original.id}`}>
					{shadowText(props.original.campaign.name, 'Example Source')} ({props.original.name}) - {props.original.campaign.type}
					{props.original.check_bot ? (
						<Tooltip position="right" tooltip="Landing Page Active">
							<Icon i="git-merge" size={16} />
						</Tooltip>
					) : null}
					{(props.original.selected_events && props.original.selected_events.length > 0) ? (
						<Tooltip position="right" tooltip={`Selected Events for Postback: ${props.original.selected_events.join(', ')}`}>
							<Icon i="filter" size={16} />
						</Tooltip>
					) : null}
				</Link>
			</span>
		)
	},
	{
		Header: '',
		id: 'rules',
		accessor: () => null,
		cast: 'actions',
		width: 120,
		resizable: false,
		sortable: false,
		style: {
			overflow: 'visible',
		},
		show: hideHeaders,
	},
	{
		Header: hideHeaders ? '' : 'Impressions',
		id: 'impression',
		accessor: props => getTotalStats(props, 'impression'),
		cast: 'number',
		width: 220,
		show: showImpression,
		Cell: renderStatsCell,
	},
	{
		Header: hideHeaders ? '' : 'Clicks',
		id: 'click',
		accessor: props => getTotalStats(props, 'click'),
		cast: 'number',
		width: 220,
		Cell: renderStatsCell,
	},
	{
		Header: hideHeaders ? '' : 'Installs',
		id: 'install',
		accessor: props => getTotalStats(props, 'install'),
		cast: 'number',
		width: 220,
		Cell: renderStatsCell,
	},
	{
		Header: hideHeaders ? '' : 'Events',
		id: 'event',
		accessor: props => getTotalStats(props, 'event'),
		cast: 'number',
		minWidth: eventsColumnWidth,
		Cell: renderStatsCell,
	},
	{
		Header: '',
		id: 'actions',
		accessor: 'id',
		cast: 'actions',
		width: 65,
		resizable: false,
		sticky: true,
		style: {
			overflow: 'visible',
			justifyContent: 'center',
			padding: 0,
		},
		Cell: props => renderActions(props)
	},
];