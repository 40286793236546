import React from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Loader, AppIcon } from 'interceptd-ui';

import { MultiCampContext } from './context';

import { CampaignTypes, TrafficTypes, DeviceTypes, TrackingTools } from '../../constants/Campaign';

import Api from '../../services/api';
import Local from '../../services/localStorage';

import { isStoreURL, parseArray, isShadow } from '../../utils/misc';

import './styles/Create.css';

const CampaignRow = props => {
	return (
		<div className="create-table-row">
			<div className="create-table-column create-table-column-app">
				{props.app ? (
					<AppIcon app={props.app} isShadow={isShadow()} />
				) : (
					<Loader mini size={24} />
				)}
			</div>
			<div className="create-table-column create-table-column-name">
				<span>{props.campaign.name}</span>
			</div>
			<div className="create-table-column create-table-column-country">
				<span>{props.campaign.country}</span>
			</div>
			<div className="create-table-column create-table-column-status">
				{props.status === 'created' ? (
					<span>Created</span>
				) : (
					<Loader mini size={24} />
				)}
			</div>
		</div>
	)
}

class Create extends React.Component {
	state = {
		countries: Local.getItem('countries'),
		campaigns: [],
		statuses: {},
		apps: {},
	}

	componentDidMount = () => {
		const { headerMapping, parsedResults } = this.context;

		this.setState({
			campaigns: parsedResults.data.map(campaign => {
				const c = {};

				Object.keys(headerMapping).forEach(field => {
					c[headerMapping[field]] = campaign[field];
				})

				return c;
			})
		}, this.checkApps)
	}

	checkApps = () => {
		const { campaigns } = this.state;

		campaigns.forEach((campaign, index) => {
			const store_url = campaign.app;
			const getType = str => (str.startsWith('id') && str.length >= 11 && !isNaN(str.replace('id', ''))) || (!isNaN(str) && str.length >= 9) ? 'ios' :
													(str.startsWith('com') && str.length >= 5) ? 'android' :
													null;
			const type = getType(store_url);
			const url = type === 'ios' ? `https://itunes.apple.com/app/${!isNaN(store_url) ? 'id' : ''}${store_url}` :
									type === 'android' ? `https://play.google.com/store/apps/details?id=${store_url}` :
									isStoreURL(store_url) ? store_url : null;

			if ( store_url !== '' && url ) {
				Api.getAppInfo({ url: url })
					.then(response => {
						this.setState(prevState => ({
							statuses: {
								...prevState.statuses,
								[index]: 'creating'
							},
							apps: {
								...prevState.apps,
								[index]: response.data,
							}
						}), () => {
							this.createCampaign(index, campaign)
						})
					})
					.catch(({ response }) => {
						toast.error('Unable to fetch app info. Please check the store url.', { autoClose: 5000 });
						this.setState(prevState => ({
							statuses: {
								...prevState.statuses,
								[index]: 'app_fetch_error'
							}
						}))
					});
			} else {
				this.setState(prevState => ({
					statuses: {
						...prevState.statuses,
						[index]: 'app_wrong'
					}
				}))
			}

		})
	}

	createCampaign = (index, campaign) => {
		const { countries, apps } = this.state;

		const checkField = (constant, value, accessor = 'text') => constant.find(type => String(type.value).toLowerCase().trim() === String(value).toLowerCase().trim() || String(type[accessor]).toLowerCase().trim() === String(value).toLowerCase().trim());
		const checkCountry = (countries, value) => countries.find(country => country.name.toLowerCase().trim() === value.toLowerCase().trim() || country.alpha2_code.toLowerCase().trim() === value.toLowerCase().trim())

		Api.createCampaign({
			app: apps[index].id,
			app_name: apps[index].title,
			name: campaign.name,
			type: checkField(CampaignTypes, campaign.type) ? checkField(CampaignTypes, campaign.type).value : CampaignTypes[0].value,
			bid: +campaign.bid || null,
			tt: checkField(TrackingTools, campaign.tt, 'label') ? checkField(TrackingTools, campaign.tt, 'label').value : TrackingTools[0].value,
			tracking_url: campaign.tracking_url || '',
			country: parseArray(campaign.country).split(',').filter(c => checkCountry(countries, c)).map(c => checkCountry(countries, c).alpha2_code),
			ts_start: moment(campaign.ts_start).isValid() ? +moment(campaign.ts_start).format('X') : null,
			ts_end: moment(campaign.ts_end).isValid() ? +moment(campaign.ts_end).format('X') : null,
			device_type: checkField(DeviceTypes, campaign.device_type) ? checkField(DeviceTypes, campaign.device_type).value : DeviceTypes[0].value,
			traffic_type: checkField(TrafficTypes, campaign.traffic_type) ? checkField(TrafficTypes, campaign.traffic_type).value : TrafficTypes[0].value,
			daily_cap: +campaign.daily_cap || null,
			total_cap: +campaign.total_cap || null,
			target_os_version: campaign.target_os_version || '',
			target_app_version: campaign.target_app_version || '',
			target_sdk_version: campaign.target_sdk_version || '',
			event_tokens: campaign.event_tokens || [],
		})
			.then(response => {
				this.setState(prevState => ({
					statuses: {
						...prevState.statuses,
						[index]: 'created'
					}
				}))
			})
			.catch(({ response }) => {
				this.setState(prevState => ({
					statuses: {
						...prevState.statuses,
						[index]: 'create_error'
					}
				}))
			});
	}

	render() {
		const { campaigns, statuses, apps } = this.state;

		return (
			<div className="multi-campaign-create">
				{campaigns.length > 0 && (
					<div className="create-table">
						<div className="create-table-header">
							<div className="create-table-column create-table-column-app"></div>
							<div className="create-table-column create-table-column-name">Campaign Name</div>
							<div className="create-table-column create-table-column-country">Country</div>
							<div className="create-table-column create-table-column-status">Status</div>
						</div>
						<div className="create-table-body">
							{campaigns.map((campaign, index) => (
								<CampaignRow
									key={index}
									app={apps[index]}
									campaign={campaign}
									status={statuses[index]} />
							))}
						</div>
					</div>
				)}
			</div>
		)
	}
}

Create.contextType = MultiCampContext;

export default Create;
