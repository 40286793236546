import React from 'react';

import './styles/BetterSwitch.css';

const Switch = ({ id, disabled, tooltip, ...rest }) => {
	return (
		<label htmlFor={id} className={`better-switch-wrapper ${disabled ? 'disabled' : ''}`} {...tooltip}>
			<input id={id} name={id} type="checkbox" disabled={disabled} {...rest} />
			<div className={`better-switch`}>
				<div className="better-switch-toggle" />
			</div>
		</label>
	)
}

export default Switch;