import React, { Component } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import {
	Box,
	Loader,
	Icon,
	Tooltip,
	Button,
	Modal,
	Input,
} from 'interceptd-ui';

import Table from '../../../components/Table';

import Api from '../../../services/api';
import Local from '../../../services/localStorage';

import { validateEmail } from '../../../utils/misc';

import withTitle from '../../withTitle';

import { getColumns } from './MemberTableColumns';

import './styles/TeamSettings.css';

class TeamSettings extends Component {
  state = {
    loading: false,
    users: [],
    accountId: Local.getItem('me')?.account_id,
    openModal: false,
    email: '',
  };

  componentDidMount() {
    this.getUsers();
  }

  toggleModal = () => {
    this.setState(prevState => ({
      openModal: !prevState.openModal,
    }));
  }

  getUsers = () => {
    const { accountId } = this.state;

    this.setState({ loading: true });

    Api.getAccountUsers(accountId)
      .then((response) => {
        this.setState({
          users: (response?.data?.data || []).sort((a, b) => b.id - a.id),
          loading: false,
        });
      }).catch((err) => {
        this.setState({ loading: false });
        toast.error(`Couldn't fetch users.`);
      });
  }

  resend = (id) => {
    console.log(id);
  }

  cancel = (email) => {
    Api.cancelInvite(email)
      .then(() => {
        toast.success(`Invitation is canceled.`);
        this.getUsers();
      })
      .catch((err) => toast.error(`Couldn't cancel invitation.`));
  }

  renderStatus = (props) => {
    const user = props.original;

    if (user.email_verified) {
      const lastLogin = moment(user.ts_last_login, 'X');
      if (lastLogin.isBefore(moment().subtract(1, 'months'))) {
        return <span style={{ color: 'var(--red)' }}>Passive</span>
      }
      return <span>Active</span>
    }

    return (
      <div className="member-table-status-row">
        <span style={{ color: 'var(--yellow)' }}>Pending</span>
      </div>
    )
  }

  renderActions = ({ original }) => {
    if (original.email_verified) {
      return '-';
    }

    return (
      <div className="member-table-action-row">
        {/* <Tooltip tooltip="Resend Invitation">
          <Icon i="repeat" size="18" className="invite-resend-button" onClick={() => this.cancel(original.email)} />
        </Tooltip> */}
        <Tooltip tooltip="Cancel Invitation">
          <Icon i="x" size="18" className="invite-cancel-button" onClick={() => this.cancel(original.email)} />
        </Tooltip>
      </div>
    );
  }

  inviteUser = () => {
    const { email } = this.state;
    Api.inviteTeamUser({ email })
      .then(() => {
        toast.success('User invited.');
        this.setState({
          openModal: false,
        }, this.getUsers);
      })
      .catch(({ response }) => {
        toast.error(response?.data?.error?.message || `Couldn't invite user.`);
      });
  }

  handleEmail = ({ target: { value: email } }) => {
    this.setState({ email });
  }

  render() {
    const {
      users,
      email,
      loading,
      openModal,
    } = this.state;

    if (loading) {
      return <Loader />;
    }

    return (
      <div className="team-settings">
        <Box
          title="MEMBERS"
          right={
            <Button
              mini
              bgColor="transparent"
              className="add-new-campaign-button"
              onClick={this.toggleModal}
            >
              <Icon i="plus" size="15" /> Invite
            </Button>
          }
        >
          <Table
            data={users}
            columns={getColumns({
              renderStatus: this.renderStatus,
              renderActions: this.renderActions,
            })}
            showPagination={false}
          />
        </Box>
        <Modal
        	open={openModal}
        	hideFooter
        	title="Invite New User"
        	onCancel={this.toggleModal}>
          <div className="user-invite-modal-warn">
            <span>Below user will be granted access for all the data on your dashboard.</span>
            <b>This feature should not be used for ad networks.</b>
          </div>
          <div className="user-invite-modal-body">
            <Input
              label="Email Address"
              value={email}
              onChange={this.handleEmail}
            />
            <Button disabled={!validateEmail(email)} onClick={this.inviteUser}>Send</Button>
          </div>
        </Modal>
      </div>
    );
  }
}

export default withTitle(TeamSettings, 'Team Settings');

