import React from 'react';

import ShadowImage from '../../components/ShadowImage';

import { shadowText } from '../../utils/misc';

import icon from '../../assets/icon-gray.svg';
import { Icon } from 'interceptd-ui';

export const columns = ({
	getTotalStats,
	renderStatsCell,
	onExpandedChange,
	redirectSettings,
}) => [
	{
		Header: 'expander',
		Expander: () => null,
		expander: true,
		show: false,
	},
	{
		Header: '',
		id: 'logo',
		accessor: 'logo',
		cast: 'string',
		width: 100,
		sortable: false,
		Cell: props => (
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<div className={`rt-expander ${props.isExpanded ? '-open' : ''}`} onClick={() => onExpandedChange(props.viewIndex)}></div>
				<div className="partner-logo">
					<ShadowImage src={props.value || icon} alt={`${props.original.name} Logo`} />
				</div>
			</div>
		)
	},
	{
		Header: 'Name',
		id: '',
		accessor: '',
		cast: 'string',
		width: 300,
		Cell: props => {
			console.log(props, props.original.name);
			return shadowText(props.original.name ?? props.original.sources[0].name, 'Example Publisher')
		}
	},
	{
		Header: 'Clicks',
		id: 'click',
		accessor: props => getTotalStats(props, 'click'),
		cast: 'number',
		style: {
			padding: 0,
		},
		Cell: renderStatsCell,
	},
	{
		Header: 'Installs',
		id: 'install',
		accessor: props => getTotalStats(props, 'install'),
		cast: 'number',
		style: {
			padding: 0,
		},
		Cell: renderStatsCell,
	},
	{
		Header: 'Events',
		id: 'event',
		accessor: props => getTotalStats(props, 'event'),
		cast: 'number',
		style: {
			padding: 0,
		},
		Cell: renderStatsCell,
	},
	{
		Header: '',
		id: '',
		style: {
			padding: 0,
		},
		Cell: (props) => <Icon style={{ cursor: 'pointer', float: 'right', marginRight: 40 }} onClick={() => redirectSettings(props)} i="settings" size={18} />,
	},
];