import React from 'react';
import { Link } from 'react-router-dom';
import {
	Icon,
	Info,
} from 'interceptd-ui';

import './styles/Ruleset.css';

const CampaignNumber = ({ campaigns }) => {
  const campaignCount = campaigns.length;

  if (campaignCount === 0) {
    return null;
  }

  return (
    <div className="campaign-count-info">
      <Info icon="info" position="left">
        <ul>
          {campaigns.map(c => (
						<li key={c.id}>
							{c.name}
							<ul>
								{c.sources.map((s) => (
									<li key={`${c.id}_${s}`}>{s}</li>
								))}
							</ul>
						</li>
					))}
        </ul>
      </Info>
      <span>{`${campaignCount} Campaign${campaignCount > 1 ? 's' : ''} using`}</span>
    </div>
  );
}

export default function Ruleset({ ruleset, campaigns }) {
  return (
    <div className="ruleset-list-item">
      <span className="ruleset-name">{ruleset.name}</span>
      <div className="ruleset-list-item-actions">
        <CampaignNumber campaigns={campaigns || []} />
        <Link
          to={{
            pathname: `manage-rulesets/create`,
            state: {
              ruleset,
            },
          }}
        >
          <Icon i="copy" className="action-icon" size="15" tooltip="Clone" />
        </Link>
        <Link to={`manage-rulesets/${ruleset.id}`}><Icon i="edit" className="action-icon" size="15" tooltip="Edit" /></Link>
      </div>
    </div>
  );
}