import React from 'react';

const TermsOfUse = props => {
	return (
		<div className="terms-of-use">
			<h1>Terms of Use</h1>
			<p>Thank you for using App Samurai.</p>
			<p>Please read these Terms carefully. By using App Samurai, signing up for an account or paying for campaigns, you are agreeing to these Terms. This is a legal agreement. We are going to start with the basics, including a few definitions that should help you understand this agreement. App Samurai ("App Samurai" or the "Service") is a mobile advertising platform offered through the URL <a href="https://appsamurai.com/">www.appsamurai.com</a> (we will refer to it as the "Website") that allows you to create install-oriented campaigns such as boost campaigns, user acquisition campaigns and video campaigns (each of these is called a "Campaign" unless specific situations are indicated) for your mobile app or apps. App Samurai is owned and operated by APP SAMURAI Inc. ("App Samurai", "we" or "us"). App Samurai has shareholders, employees and partners ("our Team"). As a customer of this Service or a representative of an entity that is a customer of the Service, you are a "Customer" according to this agreement (or "you").</p>
			<p>These Terms of Use (“Terms”, including our Privacy Policy) define the terms and conditions under which you are allowed to use App Samurai, and how we will treat your account while you are a Customer. If you have any questions about our terms, please don't hesitate to reach out to us.</p>
			<h2>ACCOUNT</h2>
			<h3>Eligibility</h3>
			<p>In order to use App Samurai, you must:</p>
			<ol>
				<li>be able to enter an email address and password</li>
				<li>agree to the Terms</li>
				<li>have the required budget (if you want to create a campaign on App
			Samurai)</li>
			</ol>
			<p>By using App Samurai, you represent and warrant that you meet all the requirements listed above, and that you won't use App Samurai in a way that violates any laws or regulations. (Representing and warranting is like making a legally enforceable promise.) App Samurai may refuse service, close accounts of any users, and change eligibility requirements at any time.</p>
			<p>You can create a mobile campaign to promote only your “Free” apps that are in the App Store and Google Play App Store. This means that you are NOT able to promote “Paid” apps through App Samurai. The system will not allow you to create a campaign at the end and you won't see any traffic.</p>
			<h3>Term</h3>
			<p>The Term begins when you sign up for App Samurai and continues as long as you use App Samurai. Clicking the button and entering your email address/password mean that you have "signed" the Terms. If you sign up for App Samurai on behalf of a company or other entity, you represent and warrant that you have the authority to accept these Terms on their behalf.</p>
			<h3>Closing Your Account</h3>
			<p>If you want to close your account, we are able to delete your account and all the data associated with it, including the information of campaigns and payment details. We won't refund in any circumstances.</p>
			<h3>Changes</h3>
			<p>We may change any of the Terms by posting revised Terms of Use on our Website and/or by sending an email to the last email address you gave us when you were signing up. Unless you terminate your account within twenty (20) days, the new Terms will be effective immediately and apply to any continued or new use of App Samurai. We may change the Website, the Service, or any features of the Service at any time.</p>
			<h3>Account and Password</h3>
			<p>You are responsible for keeping your mail address and password confidential. You're also responsible for any account that you have access to. We are not responsible for any losses due to stolen or hacked passwords. We do not have access to your current password, and for security reasons, we may only reset your password.</p>
			<h2>PAYMENT</h2>
			<h3>Minimum Budget Requirement and Payment Period</h3>
			<p>App Samurai is working with a credit system. There is a limitation to create any campaign on the current version of App Samurai. You need to set a campaign as the campaign can get at least 50 installs per day as long as you have at least $100 in your account. In other words, there is no limitation on daily allocated budget for the campaign; the limitation is on the number of installs per day.</p>
			<p>You can make a payment any time you want. If your credit is not enough to continue to get traffic, you need to add credit by making a payment. The credit will decrease suddenly when you will get installs through your campaigns. There are no allocated budgets for each campaign.</p>
			<h3>Number of Needed Downloads</h3>
			<p>These are just recommendations to reach those levels. It does not mean that you are guaranteed to achieve your goal when you get these numbers of needed downloads. It also does not mean that our work has not been successful. These numbers do not affect your payment. This situation changes based on the period, the market, the category parameters and the location.</p>
			<h3>Installs</h3>
			<p>In campaigns, we are working based on a CPI model and we charge just for the number of installs, not clicks or actions (only for installs you get through App Samurai). Any change regarding any campaign, including but not restricted to the changes related to CPI bid, terminating or pausing the campaign, may take up to 24 hours to take effect. Related spendings during this delay period will be added to the campaign's total spend, and will not be refunded.</p>
			<h3>Over Cap</h3>
			<p>Because of technical reasons, capped installs might be exceeded by up to 10%, in which case you will be charged for a maximum of 10% installs overrun.</p>
			<h3>Credit Cards</h3>
			<p>You can pay via many different types of credit cards with the help of our payment service (third-party business). If we are unable to process your credit card order, we will try to contact you by email and suspend your campaigns until your payment can be processed. You will replace the information for any credit card that expires with information for a valid one each time you want to create a campaign.</p>
			<h3>Refunds</h3>
			<p>We are unable to provide a refund under any circumstances or in any situation.</p>
			<h3>Billing Changes</h3>
			<p>We can send you our pricing structure, orders and campaign details by email if it is necessary for you.</p>
			<h2>REFERRAL FEATURE</h2>
			<h3>Referral System</h3>
			<p>In App Samurai, with unique referral link that generated by the system, you can refer App Samurai to your friends and get your free ad credit immediately. If your referral sign up to App Samurai, you will get $10. If he/she launches an ad campaign on App Samurai, you will additionally get $100 automatically.</p>
			<h3>Referral Credit Use</h3>
			<p>You can not create a campaign by only using referral credit. Referral credit can be used only if you load a minimum $100 to system.</p>
			<h3>Referral Abuse</h3>
			<p>In any case of abuse happens by the customer (like, referring via fake email addresses, too many referrals signed up from the same location, in a short period of time etc.) App Samurai has a right to empty out user's credit.</p>
			<h2>RIGHTS</h2>
			<h3>Proprietary Rights Owned by Us</h3>
			<p>You will respect our proprietary rights in the Website and the software used to provide App Samurai (proprietary rights include patents, trademarks, service marks, trade secrets, and copyrights).</p>
			<h3>Proprietary Rights Owned by You</h3>
			<p>You represent and warrant that you either own or have permission to use all of the materials (images, videos) in your campaigns. You retain ownership of the materials that you upload to the Service. We may use or disclose your materials only as described in these Terms and our Privacy Policy.</p>
			<h3>Privacy Policy</h3>
			<p>We may use and disclose your information and your account according to our Privacy Policy. Our Privacy Policy will be treated as part of these Terms.</p>
			<h2>LIABILITY</h2>
			<h3>Limitation of Liability</h3>
			<p>To the maximum extent permitted by law, you assume full responsibility for any loss that results from your use of the Website and the Services. Neither we nor our Team will be liable for any indirect, punitive, special, or consequential damages under any circumstances, even if these damages are based on negligence or we have been advised of the possibility of those damages. Our total liability for all claims made about the Service in any campaign will be no more than what you paid us for the Service for previous campaigns.</p>
			<h3>No Warranties</h3>
			<p>To the maximum extent permitted by law, we provide the material on the Website and the Service as-is. That means we do not provide warranties of any kind, either express or implied, including but not limited to warranties of merchantability and fitness for a particular purpose. Since people use App Samurai for a variety of reasons, we cannot guarantee that it will always or totally meet your specific needs.</p>
			<h3>Disclaimers</h3>
			<p>Neither we nor our Team are responsible for the behavior of any other Customers.</p>
			<h3>Force Majeure</h3>
			<p>We will not be held liable for any delays or failure in performance of any part of the Service, from any cause beyond our control. This includes, but is not limited to, acts of God, changes to law or regulations, embargoes, war, terrorist acts, riots, fires, earthquakes, nuclear accidents, zombie apocalypse, floods, strikes, power blackouts, volcanic action, unusually severe weather conditions, and acts of hackers or third-party Internet service providers.</p>
			<h2>FINE PRINT</h2>
			<h3>Survivability</h3>
			<p>Even if this Agreement is terminated, the following sections will continue to apply: Proprietary Rights Owned by Us, Proprietary Rights Owned by You, Compliance with Laws, Limitation of Liability, No Warranties, Indemnity, Choice of Law, Severability, and Entire Agreement.</p>
			<h3>Severability</h3>
			<p>If it turns out that a section of this Agreement is not enforceable, then that section will be removed or edited as little as necessary, and the rest of the Terms will still be valid.</p>
			<h3>Interpretation</h3>
			<p>The headers and sidebar text are provided only to make this agreement easier to read and understand. The fact that we wrote these Terms will not affect the way this Agreement is interpreted.</p>
			<h3>No Changes in Terms at Request of Customer</h3>
			<p>Because we have so many Customers, we cannot change these Terms for any one Customer or group.</p>
			<h3>Further Actions</h3>
			<p>You will provide all documents and take any actions necessary to meet your obligations under these Terms.</p>
			<h3>Entire Agreement</h3>
			<p>These Terms and our Privacy Policy make up the entire agreement and supersede all prior agreements, representations and understandings.</p>
			<p>Congratulations! You have reached the end of Terms of Use. Thanks for taking the time to learn about the policies of App Samurai.</p>
		</div>
	)
}

export default TermsOfUse;
