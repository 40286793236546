export const downloadFile = (data, filename, filetype = 'text/csv') => {
	const blob = new Blob([data], { type: filetype });
	const tempElem = document.createElement('a');
	tempElem.download = filename;
	tempElem.href = window.URL.createObjectURL(blob);
	tempElem.style.display = 'none';
	document.body.appendChild(tempElem);
	tempElem.click();
	tempElem.remove();
	window.URL.revokeObjectURL(blob);
}

export const copyTextToClipboard = text => {
	let textArea = document.createElement('textarea');
	textArea.style.position = 'fixed';
	textArea.style.top = 0;
	textArea.style.left = 0;
	textArea.style.width = '2em';
	textArea.style.height = '2em';
	textArea.style.padding = 0;
	textArea.style.border = 'none';
	textArea.style.outline = 'none';
	textArea.style.boxShadow = 'none';
	textArea.style.background = 'transparent';
	textArea.value = text;
	document.body.appendChild(textArea);
	textArea.select();
	try {
		document.execCommand('copy');
	} catch (err) {
		console.log('Oops, unable to copy');
	}
	document.body.removeChild(textArea);
}

export const importAllImages = r => {
	let images = {};
	r.keys().map((item, index) => { return images[item.replace('./', '').replace(/\.(png|jpe?g|svg)$/, '')] = r(item); });
	return images;
}

export const bytesToSize = (bytes) => {
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
	if (bytes === 0) return '0 Byte';
	const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
	return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
};