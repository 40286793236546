import React from 'react';
import { Route } from 'react-router-dom';
import { toast } from 'react-toastify';
import uniqBy from 'lodash/uniqBy';
import {
	Loader,
	NoData,
	PageTitle,
	FilterBox,
	Button,
	Select,
	DatePicker,
	AppIcon,
} from 'interceptd-ui';

import Detail from './Detail';
import EventMatch from './components/EventMatch';

import Api from '../../services/api';
import SendEvent from '../../services/events';
import history from '../../services/history';

import { shadowText, getInitialDateFilter, isShadow } from '../../utils/misc';

import withTitle from '../withTitle';

import './styles/List.css';

const DetailRoute = ({ component: Component, filters, app, campaigns, onOpenModal, ...rest }) => (
	<Route {...rest} render={props => {
		return <Component filters={filters} app={app} campaigns={campaigns} onOpenModal={onOpenModal} {...props} />
	}} />
)

class List extends React.Component {
	state = {
		fetching: true,
		data: [],
		campaigns: [],

		filters: {
			app: null,
			...getInitialDateFilter(),
		},

		isMatchModalOpen: false,
	}

	componentDidMount = () => {
		this.fetchData()
	}

	fetchData = () => {
		const { match } = this.props;

		this.setState({
			fetching: true,
		});
		Api.getLeoparCampaigns()
			.then(response => {
				const apps = uniqBy((response.data.data || []).filter((c) => c.application_type !== 'leancheck').filter(c => c.app_info), c => c.app_info.id).map(c => c.app_info);
				this.setState(({ filters }) => ({
					fetching: false,
					data: apps,
					campaigns: response.data.data,
				}), () => {
					if ( apps[0] && !match.params.id )
						this.handleApp(apps[0]?.id)
					else if ( match.params.id )
						this.handleApp(match.params.id)
				})
			})
			.catch(({ response }) => {
				toast.error(`Couldn't get app list`);
				SendEvent({
					description: `getLeoparCampaigns on event analytics app list`,
					fatal: true
				});
				this.setState({
					fetching: false,
					error: true,
				});
			})
	}

	handleApp = app => {
		this.setState(({ filters }) => ({
			filters: {
				...filters,
				app,
			}
		}), () => {
			history.push(`/event-analytics/${app}`)
		})
	}

	handleDateRange = ({ from, to }) => {
		this.setState(({ filters }) => ({
			filters: {
				...filters,
				start: from,
				end: to,
			}
		}));
	}

	handleOpenModal = () => {
		this.setState({
			isMatchModalOpen: true,
		});
	}

	handleCloseModal = () => {
		this.setState({
			isMatchModalOpen: false,
		});
	}

	render() {
		const { match } = this.props;
		const { fetching, data, campaigns, filters, isMatchModalOpen } = this.state;

		return (
			<div className="shortener event-analytics-list">
				<PageTitle>
					<PageTitle.Title>Event Analytics</PageTitle.Title>
				</PageTitle>

				<FilterBox>
					<FilterBox.Left>
						<Select
							placeholder="Select an App"
							options={data.map(app => ({
								...app,
								label: shadowText(app.title, 'Example App'),
								value: app.id,
							}))}
							value={[filters.app]}
							clearable={false}
							onChange={([app]) => this.handleApp(app)}
							renderOptionBefore={({ option }) => (
								<AppIcon app={option} showTooltip={false} isShadow={isShadow()} />
							)}
							wrapperClassName="select-with-app-icon"
							dropdownClassName="select-with-app-icon-dropdown"
						/>
					</FilterBox.Left>
					<FilterBox.Right>
						<Button
							mini
							bgColor="transparent"
							style={{ marginRight: 5 }}
							onClick={this.handleOpenModal}>
							Match Events
						</Button>
						<DatePicker
							value={{
								from: filters.start,
								to: filters.end,
							}}
							onChange={this.handleDateRange} />
					</FilterBox.Right>
				</FilterBox>

				{fetching ? (
					<div className="campaign-detail"><Loader /></div>
				) : (
					!fetching && match.params.id ? (
						<DetailRoute
							key={match.params.id}
							path="/event-analytics/:id"
							component={Detail}
							filters={filters}
							app={data.find(app => app.id === match.params.id) || {}}
							campaigns={campaigns}
							onOpenModal={this.handleOpenModal} />
					) : (
						<NoData icon="filter">
							Select an app from top and see the details
						</NoData>
					)
				)}

				{filters.app &&
					<EventMatch
						app={filters.app}
						open={isMatchModalOpen}
						onClose={this.handleCloseModal} />
				}
			</div>
		)
	}
}

export default withTitle(List, 'Event Analytics');
