import React from 'react';
import { Icon } from 'interceptd-ui';

import Macbook from './Macbook';

import './styles/LoginSlogan.css';
import './styles/Radikal.css';

const LoginSlogan = props => {
	return (
		<div className="login-slogan">
			<div className="slogan-text">
				<h2><b>Intercept</b> mobile ad fraud.<br/> <b>Preserve</b> your data.<br/> <b>Protect</b> your ROI.</h2>
				<h3>Get started with a free account</h3>
				<div className="slogan-sub-info">
					<div><Icon i="check" size={13} /> <span>REAL-TIME:</span> Fraudulent clicks will be blocked before converting to an install..</div>
					<div><Icon i="check" size={13} /> <span>SAVE MONEY:</span> Pay only for GENUINE installs and post-install actions.</div>
					<div><Icon i="check" size={13} /> <span>OPTIMISE:</span> Watch us auto optimise your clean traffic.</div>
					<div><Icon i="check" size={13} /> <span>GROW:</span> Watch the sum-total of our optimisation and protection efforts culminate in your businesses’ growth.</div>
				</div>
			</div>
			<Macbook />
		</div>
	)
}

export default LoginSlogan;
