import React, { useState, useRef, useEffect } from 'react';
import { Icon } from 'interceptd-ui';

import Local from '../../services/localStorage';

const Editable = ({ onFocus, onBlur, onChange, value, onSave, onCancel, children, options, ...rest }) => {
	const [active, setActive] = useState(false);
	const [changed, setChanged] = useState(false);
	const [first, setFirst] = useState(value);
	const [editing, setEditing] = useState(false);
	const inputRef = useRef(null);

	useEffect(() => {
		if ( !options && editing ) {
			inputRef && inputRef.current.focus();
		}
	}, [editing, options])

	const handleFocus = event => {
		setActive(true);
		onFocus && onFocus(event);
	}

	const handleBlur = event => {
		setActive(false);
		onBlur && onBlur(event);
	}

	const handleChange = event => {
		setChanged(true);
		onChange && onChange(event);
	}

	const handleSave = event => {
		setActive(false);
		setChanged(false);
		setFirst(value);
		setEditing(false);
		onSave && onSave({ value, ...rest });
	}

	const handleCancel = event => {
		setActive(false);
		setChanged(false);
		setEditing(false);
		onCancel && onCancel(first, { value, ...rest });
	}

	const handleEdit = event => {
		setEditing(true);
	}

	return (
		<div className={`editable ${active || changed || editing ? 'active' : ''}`}>
			{Local.getItem('me')?.plans?.interceptd === 'free' ? (
				<div className="editable-content">{children}</div>
			) : ((options && !editing) || (children && !editing)) ? (
				<div className="editable-content">{children}</div>
			) : options ? (
				<div className="editable-options">
					{options.map((option, i) => (
						<div
							key={i}
							className={`editable-option ${option.value === value ? 'selected' : ''}`}
							onClick={() => handleChange({
								target: {
									name: rest.name,
									value: option.value
								}
							})}>
							{option.text}
						</div>
					))}
				</div>
			) : (
				<input
					ref={inputRef}
					type="text"
					onFocus={handleFocus}
					onBlur={handleBlur}
					onChange={handleChange}
					value={value || ''}
					{...rest} />
			)}
			<div className="editable-actions">
				<span className="editable-save" onClick={handleSave}>Save</span>
				<span className="editable-cancel" onClick={handleCancel}>Cancel</span>
			</div>
			{((options && !editing) || (children && !editing)) &&
				<div className="editable-icon" tooltip="Edit" onClick={handleEdit}>
					<Icon size={14} i="edit-3" />
				</div>
			}
		</div>
	)
}

export default Editable;
