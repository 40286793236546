import React, { useState } from 'react';
import { Icon, Button } from 'interceptd-ui';

import Image1 from '../../assets/secure-trackers-empty1.svg';
import Image2 from '../../assets/secure-trackers-empty2.svg';
import Image3 from '../../assets/secure-trackers-empty3.svg';


import './styles/Empty.css';

const Empty = ({ create }) => {
	const [open, showDetail] = useState(false);

	return (
		<div className="secure-trackers-empty-page">
			<div className="secure-trackers-empty-page-cart-wrapper">
				<div className="secure-trackers-empty-page-cart">
					<div className="secure-trackers-empty-page-cart-body">
						<img src={Image3} alt="Add Link" />
					</div>
					<div className="secure-trackers-empty-page-cart-footer">
						<span>Give your target URL for each platform or use default</span>
					</div>
				</div>
				<Icon size="70" i="chevron-right" />
				<div>
					<div className="secure-trackers-empty-page-cart">
						<div className="secure-trackers-empty-page-cart-body">
							<img src={Image2} alt="Generate" />
						</div>
						<div className="secure-trackers-empty-page-cart-footer" alt="Generate">
							<span>We will generate short link which redirects to your target URL</span>
						</div>
					</div>
				</div>
				<Icon size="70" i="chevron-right" />
				<div className="secure-trackers-empty-page-cart">
					<div className="secure-trackers-empty-page-cart-body">
						<img src={Image1} alt="Analyze" />
					</div>
					<div className={`secure-trackers-empty-page-cart-footer dropdown-box ${open ? 'open' : ''}`}>
						<span>Then we will analyze all the sources for your link</span>
						<div className={`drop-link ${open ? 'open' : ''}`} onClick={() => showDetail(prev => !prev)}>

							<div className={`drop-link-detail ${open ? 'open' : ''}`}>
								<div className="drop-link-list-item">
									<Icon i="check" size="14" />
									<span>Country / City</span>
								</div>
								<div className="drop-link-list-item">
									<Icon i="check" size="14" />
									<span>IP Entropy</span>
								</div>
								<div className="drop-link-list-item">
									<Icon i="check" size="14" />
									<span>IP Type</span>
								</div>
								<div className="drop-link-list-item">
									<Icon i="check" size="14" />
									<span>IP Reputation</span>
								</div>
								<div className="drop-link-list-item">
									<Icon i="check" size="14" />
									<span>Device/User Agent</span>
								</div>
							</div>

							<span className="drop-link-title">What About?</span>
							<Icon key={open} i={`chevron-${open ? 'up' : 'down'}`} />
						</div>
					</div>
				</div>
			</div>
			<div className="empty-page-create-button">
				<Button color="primaryShade" onClick={create}>Create Link</Button>
			</div>
		</div>
	);
}

export default Empty;