import React from 'react';

import { shadowText } from '../../utils/misc';

import UnknownApp from '../../assets/unknown-app.svg';

const Img = ({ src, alt, ...rest }) => {
	const [source, setSource] = React.useState(src);

	const handleError = () => {
		setSource(UnknownApp);
	}

	return (
		<img src={source} onError={handleError} alt={alt} {...rest} />
	)
}

export const columns = [
	{
		Header: '',
		id: 'icon_url',
		accessor: 'icon_url',
		cast: 'string',
		width: 70,
		Cell: props => (
			<div className="partner-logo">
				<Img src={props.value || UnknownApp} alt={`${props.original.title} Logo`} />
			</div>
		)
	},
	{
		Header: 'Name',
		id: 'title',
		accessor: 'title',
		cast: 'string',
		Cell: props => {
			const { device, campaigns } = props.original;

			const store = device === 'ios' ? 'fab fa-apple' :
										device === 'android' ? 'fab fa-android' : 
										device === 'web' ? 'fas fa-desktop' :
										'far fa-question-circle';

			return (
				<div className="campaign-list-apps-name">
					<span>
						<span>{shadowText(props.value, 'Example App')}</span>
						<span style={{ color: 'var(--darkgray)' }}>{campaigns.length} Campaign{campaigns.length > 1 ? 's' : ''}</span>
					</span>
					<i className={`app-store ${store}`} />
				</div>
			)
		}
	},
];