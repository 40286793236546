import React from 'react';
import { Redirect } from 'react-router-dom';

import Local from '../../services/localStorage';
import SendEvent from '../../services/events';
import Api from '../../services/api';

class Logout extends React.Component {
	componentDidMount() {
		SendEvent({
			category: 'Auth',
			action: 'Logged out',
			nonInteraction: true,
		});

		Local.setStore({});
		Api.setToken();
	}

	render() {
		return <Redirect to="/welcome" />;
	}
}

export default Logout;