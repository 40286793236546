import Api from './api';
import Local from './localStorage';

class Settings {
	constructor() {
		this.key = 'settings';
		this.loginAs = Local.getItem('login_as');

		if (!Local.getItem(this.key))
			Local.setItem(this.key, {});
	}

	get(key, type = 'user') {
		if (key)
			return Local.getItem(this.key)?.[type]?.[key];
		else
			return Local.getItem(this.key);
	}

	set(key, value, type = 'user') {
		const settings = this.get();
		settings[type][key] = value;

		Local.setItem(this.key, settings);

		const endpoint = type === 'account' ? 'updateAccountSettings' : 'updateUserSettings';
		Api[endpoint]({
			settings: settings[type]
		})
			.then(() => console.log('Settings updated.'))
			.catch(() => console.log(`Couldn't change settings`));

		return this.get();
	}

	setLocalSettings(value) {
		return Local.setItem(this.key, value);
	}
}

export default new Settings();
