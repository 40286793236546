import React from 'react';
import { Route, NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';
import find from 'lodash/find';
import {
	PageTitle,
	SecondaryNav,
	FilterBox,
	StackedAvatars,
	Select,
	Info,
} from 'interceptd-ui';

import ReportListTable from './components/ReportListTable';

import Api from '../../services/api';
import Download from '../../services/download';
import history from '../../services/history';

import { parseArray, shadowText } from '../../utils/misc';

import withTitle from '../withTitle';

import './styles/ReportList.css';

const ViewRoute = ({
	component: Component,
	path,
	exact,
	...rest
}) => (
		<Route path={path} exact={exact} render={props => {
			return <Component {...props} {...rest} />
		}} />
	);

class ReportList extends React.Component {
	state = {
		campaigns: [],
		campaignFetching: true,
		fetching: true,
		reports: [],
		campaignFilter: [],
		partnerList: [],
	}

	getCurrentType = () => {
		const { location } = this.props;
		const urlComponents = location.pathname.split('/');
		return urlComponents[urlComponents.length - 1] !== 'reports'
			? urlComponents[urlComponents.length - 1]
			: 'click';
	}

	componentDidMount = () => {
		this.setState({ campaignFetching: true });
		this.getPartners();
		Api.getLeoparCampaigns()
			.then(response => {
				this.setState({
					campaignFetching: false,
					campaigns: get(response, 'data.data', []).filter((c) => c.application_type !== 'leancheck'),
				}, this.getReports);
			})
			.catch((err) => {
				this.setState({ campaignFetching: false });
				toast.error(`Couldn't fetch campaigns.`);
			})
	}

	componentDidUpdate(prevProps) {
		if (this.props.location.pathname !== prevProps.location.pathname) {
			Api.cancelAll();
			this.getReports();
		}
	}

	componentWillUnmount = () => {
		Api.cancelAll();	
	}

	getReports = () => {
		this.setState({
			fetching: true,
		});

		Api.getLeoparOfflineReports({
			report_type: this.getCurrentType(),
		})
			.then((response) => {
				const reports = get(response, 'data.data', []);
				this.setState({
					fetching: false,
					reports: reports.sort((a, b) => b.id - a.id),
				});
			})
			.catch((e) => {
				if (e.message) {
					this.setState({ fetching: false });
					toast.error(`Couldn't get reports`);
				}
			});
	}

	getPartners = async () => {
		try {
			const partnerResponse = await Api.getPartners({ size: 1000 });
			const publisherResponse = await Api.getPublishers({ size: 1000 });
			const partnerList = partnerResponse?.data?.data || [];
			const publisherList = publisherResponse?.data?.data || [];

			const data = publisherList.map((p) => {
				const publisher = p;
				const partner = find(partnerList, ['id', publisher.partner]);
				publisher.logo = partner?.logo || null;
				return ({
					id: publisher?.id,
					src: partner?.logo,
					alt: partner?.name,
				});
			});

			this.setState({ partnerList: data });
		} catch (err) {
			console.error(err);
		}
	};

	handleCampaignFilter = value => {
		const { campaigns } = this.state;
		this.setState({
			campaignFilter: campaigns.filter(c => value.indexOf(c.id) > -1),
		});
	}

	download = (report, openInNewTab) => {
		try {
			const { s3 } = report.detail;

			if (openInNewTab) {
				return window.open(s3);
			}

			const parsed = parseArray(report.source_id);
			const sourceName = parsed.length < 4 ? (parsed.join('-') || '') : `${parsed.length}-sources`;
			const fileName = `${sourceName}-${report.report_type}_report.${(report.report_type === 'click' || report.report_type === 'impression') ? 'csv' : 'tsv'}`;
			const file = new Download(encodeURI(s3), fileName);
			file.get();
		} catch (err) {
			toast.error(`Couldn't download report.`);
		}
	}

	goToCreate = () => {
		history.push(`/reports/create`, {
			selectedReportType: this.getCurrentType(),
		});
	}

	renderCampaignsColumn = props => {
		try {
			const { campaigns, campaignFilter } = this.state;

			const reportCampaigns = uniqBy([...(parseArray(props.value).map(source => campaigns.find(campaign => campaign.sources.some(s => s.id === source)))), campaigns.find(c => c.id === props.original.campaign_id)], 'id').filter(c => c);

			if (reportCampaigns.length === 0) return null;

			if (reportCampaigns.length === 1) {
				return (
					<div className="report-campaign-cell">
						{reportCampaigns.map(campaign => (
							<span key={campaign.id} className={`report-campaign-cell-item ${campaignFilter.some(c => c.id === campaign.id) ? 'filtered' : ''}`}>{shadowText(campaign.name, 'Example Campaign')}</span>
						))}
					</div>
				);
			}

			return (
				<div className="report-campaign-cell">
					<Info>
						<ul>
							{reportCampaigns.map(campaign => (
								<li key={campaign.id} className={`report-campaign-cell-item ${campaignFilter.some(c => c.id === campaign.id) ? 'filtered' : ''}`}>{shadowText(campaign.name, 'Example Campaign')}</li>
							))}
						</ul>
					</Info>
					<span>{`${reportCampaigns.length} Campaigns`}</span>
				</div>
			)
		} catch (e) {
			return null;
		}
	}

	renderSharedPublisherColumn = (props) => {
		const sharedPublishers = props?.original?.config?.shared_publishers;

		if (!sharedPublishers || sharedPublishers.length === 0) {
			return '-';
		}

		const { partnerList } = this.state;

		return (
			<StackedAvatars
				avatars={partnerList.filter(p => sharedPublishers.indexOf(p.id) !== -1)}
			/>
		);
	}


	render() {
		const {
			campaigns,
			fetching,
			reports,
			campaignFilter,
		} = this.state;

		return (
			<div className="campaign-detail campaign-reports">
				<PageTitle>
					<PageTitle.Title>Reports</PageTitle.Title>
				</PageTitle>

				<FilterBox>
					<div className="filter-column filter-column-left">
						<SecondaryNav>
							<SecondaryNav.NavItem>
								<NavLink
									exact
									activeClassName="active"
									to="/reports/impression">
									Impression
								</NavLink>
							</SecondaryNav.NavItem>
							<SecondaryNav.NavItem>
								<NavLink
									exact
									activeClassName="active"
									to="/reports">
									Click
								</NavLink>
							</SecondaryNav.NavItem>
							<SecondaryNav.NavItem>
								<NavLink
									exact
									activeClassName="active"
									to="/reports/install">
									Install
								</NavLink>
							</SecondaryNav.NavItem>
							<SecondaryNav.NavItem>
								<NavLink
									exact
									activeClassName="active"
									to="/reports/event">
									Event
								</NavLink>
							</SecondaryNav.NavItem>
							<SecondaryNav.NavItem>
								<NavLink
									exact
									activeClassName="active"
									to="/reports/summary">
									Summary
								</NavLink>
							</SecondaryNav.NavItem>
						</SecondaryNav>
					</div>
					<div className="filter-column filter-column-right">
						<Select
							multi
							clearable
							placeholder="All Campaigns"
							options={campaigns.map(c => ({
								label: c.name,
								value: c.id,
							}))}
							value={campaignFilter.map(c => c.id)}
							onChange={this.handleCampaignFilter} />
					</div>
				</FilterBox>

				<ViewRoute
					exact
					path="/reports"
					component={ReportListTable}
					campaignFilter={campaignFilter}
					reports={reports}
					fetching={fetching}
					goToCreate={this.goToCreate}
					getReports={this.getReports}
					download={this.download}
					renderCampaignsColumn={this.renderCampaignsColumn}
					renderSharedPublisherColumn={this.renderSharedPublisherColumn}
				/>

				<ViewRoute
					exact
					path="/reports/install"
					component={ReportListTable}
					campaignFilter={campaignFilter}
					reports={reports}
					fetching={fetching}
					goToCreate={this.goToCreate}
					getReports={this.getReports}
					download={this.download}
					renderCampaignsColumn={this.renderCampaignsColumn}
					renderSharedPublisherColumn={this.renderSharedPublisherColumn}
				/>

				<ViewRoute
					exact
					path="/reports/event"
					reportType="event"
					component={ReportListTable}
					campaignFilter={campaignFilter}
					reports={reports}
					fetching={fetching}
					goToCreate={this.goToCreate}
					getReports={this.getReports}
					download={this.download}
					renderCampaignsColumn={this.renderCampaignsColumn}
					renderSharedPublisherColumn={this.renderSharedPublisherColumn}
				/>

				<ViewRoute
					exact
					path="/reports/summary"
					reportType="summary"
					component={ReportListTable}
					campaignFilter={campaignFilter}
					reports={reports}
					fetching={fetching}
					goToCreate={this.goToCreate}
					getReports={this.getReports}
					download={this.download}
					renderCampaignsColumn={this.renderCampaignsColumn}
					renderSharedPublisherColumn={this.renderSharedPublisherColumn}
				/>

				<ViewRoute
					exact
					path="/reports/impression"
					reportType="impression"
					component={ReportListTable}
					campaignFilter={campaignFilter}
					reports={reports}
					fetching={fetching}
					goToCreate={this.goToCreate}
					getReports={this.getReports}
					download={this.download}
					renderCampaignsColumn={this.renderCampaignsColumn}
					renderSharedPublisherColumn={this.renderSharedPublisherColumn}
				/>
			</div>
		);
	}
}

export default withTitle(ReportList, 'Report List');
