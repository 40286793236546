import React from 'react';
import RelativePortal from 'react-relative-portal';
import { Icon, Tooltip } from 'interceptd-ui';

import CampaignRuleModal from './CampaignRuleModal.js';

class RuleSetIcon extends React.Component {
	state = {
		open: false,
		closing: false,
	}

	timer = null
	closing = null
	closeTimeout = 450

	handleToggle = () => {
		clearTimeout(this.closing);
		this.setState(prevState => ({
			open: !prevState.open
		}))
	}

	handleOpen = () => {
		clearTimeout(this.closing);
		this.setState({
			open: true,
			closing: false,
		})
	}

	handleClose = () => {
		clearTimeout(this.closing);
		this.setState({ closing: true });
		this.closing = setTimeout(() => {
			this.setState({
				open: false,
				closing: false,
			})
		}, this.closeTimeout);
	}

	stopPropagation = e => {
		e.stopPropagation();
		return false;
	}

	render() {
		const { open } = this.state;
		const {
			tooltipPosition,
			campaign,
			alarms,
			ruleset,
			onReload
		} = this.props;

		return (
			<Tooltip position={tooltipPosition} tooltip="Manage Campaign Rules">
				<div className={`
					ruleset-icon cruleset-icon
					${alarms.length > 0 ? 'with-alarm' : ''}
					${ruleset ? 'active' : ''}
					${open ? 'open' : ''}`}>
					<Icon i="clipboard" size={18} onClick={this.handleToggle} />
					{open &&
						<RelativePortal top={0} left={0} onOutClick={open ? this.handleClose : null}>
							<div className="closing">
								<CampaignRuleModal 
									campaign={campaign}
									alarms={alarms}
									ruleset={ruleset}
									onClose={this.handleClose}
									onReload={onReload} />
							</div>
						</RelativePortal>
					}
				</div>
			</Tooltip>
		)
	}
}

RuleSetIcon.defaultProps = {
	tooltipPosition: 'top'
}

export default RuleSetIcon;
