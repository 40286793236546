import React from 'react';
import axios from 'axios';
import sortBy from 'lodash/sortBy';

import './styles/Presentation.css';

import logo from '../../assets/logo.svg';
import QR from '../../assets/oblast-qr.svg';
import OBlastIOS from '../../assets/oblast-ios.jpg';

const DeviceID = ({ id }) => {
	const splited = id.split('-');
	const isAdID = splited.length >= 5;

	return isAdID ? splited.map((part, index) => index === splited.length - 1 ? part : part.split('').fill('X').join('')).join('-') : id;
}

class Presentation extends React.Component {
	state = {
		fetching: true,
		stats: {}
	}

	interval = null
	MEHMET = '65D67B47-5F8E-4A32-BAC2-259B69C79443'

	componentDidMount = () => {
		this.getData();

		this.interval = setInterval(() => {
			this.getData();
		}, 5000)
	}

	componentWillUnmount = () => {
		clearInterval(this.interval)
	}

	getData = () => {
		this.setState({ fetching: true });
		axios.get('https://api.interceptd.com/demo/stats')
			.then(res => {
				this.setState({
					fetching: false,
					stats: res.data.data,
				})
			})
	}

	render() {
		const { stats } = this.state;

		return (
			<div className="presentation">
				<header className="presentation-header">
					<img src={logo} alt="Interceptd" data-event-category="Navigation" data-event-action="Logo clicked" />
					<span>Mobile Growth Summit - Demo</span>
				</header>
				<div className="presentation-wrapper">
					<div className="presentation-row">

						<div className="presentation-col">
							<div className="presentation-game">
								<div className="presentation-qr">
									<img src={QR} width={250} alt="O Blast!" />
								</div>
								<div className="presentation-apps">
									<div className="app">
										<div className="app-icon">
											<img src={OBlastIOS} alt="O Blast! Puzzle" />
										</div>
										<div className="app-info">
											<span className="app-title">O Blast! Puzzle</span>
											<span className="app-category">Games</span>
											<span className="app-url">https://apps.apple.com/tr/app/o-blast-puzzle/id1462445312</span>
										</div>
										<i className="app-store fab fa-apple" />
									</div>
									<div className="app">
										<div className="app-icon">
											<img src={OBlastIOS} alt="2048 O Blast! Puzzle" />
										</div>
										<div className="app-info">
											<span className="app-title">2048 O Blast! Puzzle</span>
											<span className="app-category">Puzzle</span>
											<span className="app-url">https://play.google.com/store/apps/details?id=com.shibui.oblast</span>
										</div>
										<i className="app-store fab fa-android" />
									</div>
								</div>
							</div>
						</div>

						<div className="presentation-col presentation-right">
							<div className="presentation-box">
								<div className="presentation-title">Install Count</div>
								<div className="presentation-content">
									<div key={`count-${stats.install_count}`} className="presentation-count">
										{stats.install_count}
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="presentation-row">
						<div className="presentation-col">
							<div className="presentation-box presentation-clicks">
								<div className="presentation-title">
									<span>Advertising ID</span>
									<span>Fake Clicks</span>
								</div>
								<div className="presentation-content">
									<div className="presentation-devices">
										{(stats.device_stats || []).filter(device => device.device_id === this.MEHMET).map(device => (
											<div key={device.device_id} className="presentation-device">
												<div className="presentation-device-id"><DeviceID id={device.device_id} /></div>
												<div className="presentation-device-count">{device.fake_click_count}</div>
											</div>
										))}
										{sortBy((stats.device_stats || []).filter(device => device.device_id !== this.MEHMET), 'fake_click_count').reverse().map(device => (
											<div key={device.device_id} className="presentation-device">
												<div className="presentation-device-id"><DeviceID id={device.device_id} /></div>
												<div className="presentation-device-count">{device.fake_click_count}</div>
											</div>
										))}
									</div>
								</div>
							</div>
						</div>

						<div className="presentation-col presentation-right" />
					</div>

				</div>

			</div>
		)
	}
}

export default Presentation;
