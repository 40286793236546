import React from 'react';
import { toast } from 'react-toastify';
import {
	Box,
	NoData,
	FraudGraph,
} from 'interceptd-ui';

import Table from '../../components/Table';

import { columns as ListPublisherColumns } from './ListPublisherColumns';

import Api from '../../services/api';
import SendEvent from '../../services/events';

import './styles/ListPublishers.css';
import history from '../../services/history';

class ListPublishers extends React.Component {
	state = {
		partners: [],
		partnersFetching: true,

		publishers: [],

		expandedState: {},
	}

	componentDidMount = () => {
		this.getPartners();
	}

	static getDerivedStateFromProps = (props, state) => {
		const { partners, partnersFetching } = state;
		const { campaigns, campaignsFetching } = props;

		if ( partnersFetching || campaignsFetching )
			return null

		const publishers = campaigns.reduce((acc, cur) => {
			const newAcc = [...acc];
			cur.sources.forEach(source => {
				// Find Publisher
				const foundPublisher = newAcc.findIndex(p => p.id === source.partner);
				if ( foundPublisher === -1 ) {
					// No Publisher, add
					const publisher = {
						...(partners.find(partner => source.partner === partner.id) || {})
					};
					publisher.sources = [source];
					newAcc.push(publisher);
				} else {
					// Publisher found, add source
					newAcc[foundPublisher].sources = [
						...(newAcc[foundPublisher].sources),
						source,
					]
				}
			})
			return newAcc;
		}, [])

		return ({ publishers })
	}

	getPartners = () => {
		Api.getPartners({
			page: 1,
			size: 1000,
		})
			.then(response => {
				this.setState({
					partners: response.data.data,
					partnersFetching: false
				});
			})
			.catch(({ response }) => {
				this.setState({
					partnersFetching: false
				});
				toast.error(`Couldn't get publishers`);
			})
	}

	showSources = index => {
		const { expandedState } = this.state;

		SendEvent({
			category: 'Campaign List',
			action: `Campaign sources panel ${expandedState[index] ? 'closed' : 'opened'}`,
			nonInteraction: false,
		});
		this.setState({
			expandedState: expandedState[index] ? {}: {
				[index]: true
			},
		});
	}

	renderStatsCell = ({ original, column }) => {
		const {
			campaignStatsFetching,
			campaignInstallAndEventFetching,
			getCellStats,
		} = this.props;

		const isClickCell = column.id === 'click';
		const isEventCell = column.id === 'event' || column.id.indexOf('kpi') > -1;
		const data = getCellStats({ original, column });

		return  (
			<div className={original.campaign ? 'campaign-table-fraud-bar-container' : ''}>
				<FraudGraph
					data={data}
					showUnique={isEventCell}
					title={column.Header}
					loading={isClickCell ? campaignStatsFetching : campaignInstallAndEventFetching} />
			</div>
		)
	}

	redirectSettings = ({ original }) => {
		history.push('/account/publishers', {
			partner: original.id,
		});
	}

	render() {
		const { campaignsFetching, getTotalStats } = this.props;
		const { publishers, partnersFetching, expandedState } = this.state;

		return (
			<div className="campaign-list-publishers">
				<Box
					className="box-table-wrapper"
					title="PUBLISHERS">
					<Table
						data={publishers}
						columns={
							ListPublisherColumns({
								getTotalStats,
								renderStatsCell: this.renderStatsCell,
								onExpandedChange: this.showSources,
								redirectSettings: this.redirectSettings,
							})
						}
						pages={0}
						pageSize={publishers.length}
						loading={partnersFetching || campaignsFetching}
						showPagination={false}
						showPageSizeOptions={false}
						showPageJump={false}
						defaultSortDesc={true}
						defaultSorted={[{
							id: "name",
							desc: false
						}]}
						expanded={expandedState}
						PadRowComponent={() => null}
						SubComponent={this.props.renderSources}
						NoDataComponent={() => (
							<NoData>You didn't create any campaign yet.</NoData>
						)}
						getTrGroupProps={(state, rowInfo, column) => {
							const { expandedState } = this.state;

							if (rowInfo && expandedState[rowInfo.viewIndex]) {
								return ({
									className: 'table-grouper -open'
								})
							}

							return ({
								className: 'table-grouper'
							})
						}}
					/>
				</Box>
			</div>
		)
	}
}

export default ListPublishers;
