import React from 'react';
import Dropzone from 'react-dropzone'
import Papa from 'papaparse';
import { Button } from 'interceptd-ui';

import { MultiCampContext } from './context';

import sampleCsvFilePath from '../../assets/Sample_Campaign_List.csv';

import './styles/Upload.css';

class Upload extends React.Component {
	state = {
		rejected: false,
	}

	handleDrop = acceptedFiles => {
		const [ file ] = acceptedFiles;
		if ( (file.name.indexOf('.csv') === -1) && (file.name.indexOf('.tsv') === -1) ) {
			this.setState({ rejected: true })
			return false;
		}

		Papa.parse(file, {
			header: true,
			skipEmptyLines: true,
			complete: results => {
				this.context.setState({
					step: 'match',
					parsedResults: results,
				})
			}
		})
	}

	downloadSampleCsv = () => {
		const a = document.createElement('a');
		a.setAttribute('href', sampleCsvFilePath);
		a.setAttribute("target", '_blank');
		a.setAttribute("download", 'Sample_Campaign_List.csv');
		a.click();
		a.remove();
	}

	render() {
		const { rejected } = this.state;

		return (
			<div className="multi-campaign-upload">
				<div className="multi-campaign-upload-buttons">
					<Dropzone onDrop={this.handleDrop}>
						{({getRootProps, getInputProps}) => (
							<section>
								<div {...getRootProps({
									accept: 'text/csv',
									multiple: false,
								})}>
									<input {...getInputProps()} />
									<Button>Import CSV</Button>
								</div>
							</section>
						)}
					</Dropzone>
					<Button bgColor="transparent" className="download-sample-button" onClick={this.downloadSampleCsv}>Download Sample CSV</Button>
				</div>
				{rejected && <p className="rejected">Please select a CSV or TSV file</p>}
			</div>
		)
	}
}

Upload.contextType = MultiCampContext;

export default Upload;
