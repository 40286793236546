import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import semverCompare from 'semver/functions/compare';
import semverValid from 'semver/functions/valid';
import semverCoerce from 'semver/functions/coerce';
import {
	Box,
	Loader,
	NoData,
	Icon,
	FraudGraph,
	Actions,
	PageTitle,
	Breadcrumbs,
	FilterBox,
	Button,
	Select,
	DatePicker,
	AppIcon,
} from 'interceptd-ui';

import ImpressionPixels from '../../../components/ImpressionPixels';
import RuleSetIcon from '../../../components/RuleSet/RuleSetIcon';
import KPIPerformanceTable from '../../../components/KPIPerformanceTable';
import CreativePerformanceTable from '../../../components/CreativePerformanceTable';

import DetailDailyChart from '../../Campaign/DetailDailyChart';
import DetailSankey from '../../Campaign/DetailSankey';

import CTITDistGraph from '../components/CTITDistGraph';
import TimeDistGraph from '../components/TimeDistGraph';
import TimeDistDays from '../components/TimeDistDays';
import { Days as DayList } from '../components/TimeDistDays';
import AppVersionPie from '../components/AppVersionPie';
import OSVersionPie from '../components/OSVersionPie';
import SDKVersionPie from '../components/SDKVersionPie';
import IPAnonymityPie from '../components/IPAnonymityPie';
import CountryDistMap from '../components/CountryDistMap';
import AlarmDistGraph from '../components/AlarmDistGraph';
import DetailStatsBoxes from '../components/DetailStatsBoxes';
import DailyImpressionsChart from './DailyImpressionsChart';
import SourceInfo from './SourceInfo';

import { openFreeModeWarning } from '../../../components/FreeModeWarning';

import Api from '../../../services/api';
import Local from '../../../services/localStorage';
import SendEvent from '../../../services/events';
import history from '../../../services/history';
import Settings from '../../../services/settings';

import { fillHours, getTimestamp } from '../../../utils/transform';
import { copyTextToClipboard } from '../../../utils/file';
import { shadowText, getInitialDateFilter, isShadow } from '../../../utils/misc';

import { RuleNameMaps } from '../../../constants/Rules';
import { EmptyStatsObjects } from '../../../constants/Campaign';

import './styles/SourceDetail.css';

import withTitle from '../../withTitle';

class SourceDetail extends React.Component {
	state = {
		me: Local.getItem('me'),
		goToSubpublishers: false,
		selectedEventForTimeDist: null,
		selectedEvent: null,

		filters: getInitialDateFilter(),

		sourceStatsFetching: false,
		sourceStats: {},
		showImpressions: false,
		impressionStats: [],

		supported_rules: Local.getItem('supported_rules'),
		supported_campaign_rules: Local.getItem('supported_campaign_rules'),

		campaign: {},

		loading: true,
		source: null,

		sourceInfoOpen: false,

		ctitDistFetching: false,
		ctitDist: null,

		itetDistFetching: false,
		itetDist: null,

		alarmChartData: null,
		alarms: [],

		timeDistActiveDay: DayList[0],
		installTimeDistFetching: false,
		installTimeDist: null,

		eventTimeDistFetching: false,
		eventTimeDist: null,

		appVersionsFetching: false,
		appVersionsLabels: null,
		appVersions: [],

		osVersionFetching: false,
		OSVersionsLabels: null,
		OSVersions: null,

		SDKVersionsFetching: false,
		SDKVersionsLabels: null,
		SDKVersions: [],

		IPAnonymityFetching: false,
		IPAnonymityLabels: null,
		IPAnonymity: null,

		countryDistFetching: false,
		countryDist: [],

		sourceKPITable: [],

		revenue_currency: 'USD',

		sourceSettings: Settings.get('sourceSettings', 'account') || {},
		appSettings: Settings.get('appSettings', 'account') || {},
	}

	componentDidMount = () => {
		this.getData();

		Local.listen('me', newValue => {
			this.setState({
				me: newValue,
			})
		})
	}

	getData = () => {
		this.getCampaign();
		this.getSourceCTITDist();
		this.getSourceITETDist();
		this.getTimeDistData();
		this.getAppVersion();
		this.getOsVersion();
		this.getSdkVersion();
		this.getIPAnonymity();
		this.getCountryDist();
	}

	renderRulesCell = ({ original, className = '' }) => {
		const { rulesets, campaign } = this.state;
		const fraudRuleset = get(rulesets, `[${original.campaign}][${original.id}].ruleset`);

		if (!original) return null;

		return (
			<div className={`rules-column ${className}`}>
				<RuleSetIcon
					campaign={campaign}
					source={original.id}
					alarms={campaign.alarms.filter(a => a.alarm_type.indexOf('EIR') === -1)}
					ruleset={fraudRuleset}
					onReload={this.getRulesets} />
			</div>
		);
	}

	getRulesets = () => {
		const { campaign } = this.state;

		Api.getCampaignRuleSets(campaign.id)
			.then(response => {
				this.setState({
					rulesets: response.data.data.reduce((acc, cur) => {
						acc[cur.campaign_ruleset.campaign_id] = {};
						acc[cur.campaign_ruleset.campaign_id].ruleset = cur.campaign_ruleset.ruleset;
						if (cur.source_rulesets && cur.source_rulesets.length > 0) {
							cur.source_rulesets.forEach(source_ruleset => {
								acc[cur.campaign_ruleset.campaign_id][source_ruleset.source_id] = {}
								acc[cur.campaign_ruleset.campaign_id][source_ruleset.source_id].ruleset = source_ruleset.ruleset;
							})
						}
						return acc;
					}, {}),
				});
			})
			.catch(({ response }) => {
				SendEvent({
					description: `getCampaignRuleSets on source detail`,
					fatal: true
				});
			});
	}

	getQuery = (multipleSourceId) => {
		const { match: { params: { id, source_id } } } = this.props;
		const { filters } = this.state;
		const query = {
			source_id: source_id,
			campaign_id: id,
			ts_start: getTimestamp(filters.start),
			ts_end: getTimestamp(filters.end, true),
		};

		if (multipleSourceId) {
			query.source_ids = [source_id];
		} else {
			query.source_id = source_id;
		}

		return query;
	}

	getSourceStats = async () => {
		try {
			this.setState({ sourceStatsFetching: true });
			const q = this.getQuery(true);

			const statsResponse = await Api.getLeoparSourceStats(q);
			const installsResponse = await Api.getLeoparSourceInstallAndEvent(q);
			const impressionResponse = await Api.getDailyImpressionStats(q);

			const clicks = get(statsResponse, 'data.data[0]', {});
			const installs = get(installsResponse, 'data.data[0]', {});
			const impressionStats = get(impressionResponse, 'data.data', []);
			const impressions = impressionStats.reduce((a, c) => {
				const newA = { ...a };
				newA.source_id = c.source_id;
				newA.campaign_id = c.campaign_id;
				newA.clean_impression_count = (newA?.clean_impression_count ?? 0) + c.impression_count;
				newA.flagged_impression_count = (newA?.flagged_impression_count ?? 0) + c.flagged_impression_count;
				newA.rejected_impression_count = (newA?.rejected_impression_count ?? 0) + c.rejected_impression_count;
				newA.total_impression_count = (newA?.total_impression_count ?? 0) + ((c.impression_count || 0) + (c.flagged_impression_count || 0) + (c.rejected_impression_count || 0));
				return newA;
			}, {});

			this.setState(prevState => ({
				sourceStatsFetching: false,
				showImpressions: impressions.total_impression_count > 0,
				sourceStats: {
					...prevState.sourceStats,
					impressions: {
						clean: +(impressions.clean_impression_count || 0),
						rejected: +(impressions.rejected_impression_count || 0),
						flagged: +(impressions.flagged_impression_count || 0),
						total: +(impressions.total_impression_count || 0),
					},
					click: {
						clean: +clicks.click_count || 0,
						rejected: +clicks.rejected_click_count || 0,
						flagged: +clicks.flagged_click_count || 0,
						total: (+clicks.click_count || 0) + (+clicks.rejected_click_count || 0) + (+clicks.flagged_click_count || 0),
					},
					install: {
						clean: +installs.clean_install_count || 0,
						rejected: +installs.rejected_install_count || 0,
						flagged: +installs.flagged_install_count || 0,
						total: (+installs.clean_install_count || 0) + (+installs.rejected_install_count || 0) + (+installs.flagged_install_count || 0),
					},
					event: {
						clean: +installs.clean_event_count || 0,
						rejected: +installs.rejected_event_count || 0,
						flagged: +installs.flagged_event_count || 0,
						total: (+installs.clean_event_count || 0) + (+installs.rejected_event_count || 0) + (+installs.flagged_event_count || 0),
					},
					reattributed_event: {
						clean: +installs.clean_reattributed_event_count || 0,
						flagged: +installs.flagged_reattributed_event_count || 0,
						rejected: +installs.rejected_reattributed_event_count || 0,
					},
					amount: {
						clean: +installs.clean_amount || 0,
						clean_reattributed: +installs.clean_reattributed_amount || 0,
						flagged: +installs.flagged_amount || 0,
						flagged_reattributed: +installs.flagged_reattributed_amount || 0,
						rejected: +installs.rejected_amount || 0,
						rejected_reattributed: +installs.rejected_reattributed_amount || 0,
					}
				},
				impressionStats
			}));
		} catch (err) {
			toast.error(`Couldn't fetch stats data.`, {
				autoClose: 3000,
			});
		}
	}

	getSourceKPIStats = async () => {
		try {
			const { filters, campaign, source } = this.state;
			const params = campaign.sources.map(s => ({
				source_id: s.id,
				event_names: uniq([
					...(campaign.kpis || []).map(kpi => kpi.event_name),
					...Object.keys(s.event_payouts || {}),
				])
			})).filter(p => p.event_names.length > 0);

			if (params.length === 0) return;

			const q = {
				params,
				ts_start: getTimestamp(filters.start),
				ts_end: getTimestamp(filters.end, true),
			};
			const kpisResponse = await Api.getLeoparSourceKPIStats(q);
			const kpis = get(kpisResponse, 'data.data', []);

			this.setState({
				kpis,
				sourceKPITable: uniq([
					...(campaign.kpis || []).map(kpi => kpi.event_name),
					...Object.keys(source.event_payouts || {}),
				]).map(event => {
					const kpi = kpis.find(k => k.event_name === event) || {};

					return ({
						count: {
							clean: +(kpi.clean_event_count || 0),
							rejected: +(kpi.rejected_event_count || 0),
							flagged: +(kpi.flagged_event_count || 0),
							total: (+(kpi.clean_event_count || 0)) + (+(kpi.rejected_event_count || 0)) + (+(kpi.flagged_event_count || 0)),
						},
						amount: {
							clean: +(kpi.clean_amount || 0),
							rejected: +(kpi.rejected_amount || 0),
							flagged: +(kpi.flagged_amount || 0),
							total: (+(kpi.clean_amount || 0)) + (+(kpi.rejected_amount || 0)) + (+(kpi.flagged_amount || 0)),
						},
						rCount: {
							clean: +(kpi.clean_reattributed_event_count || 0),
							rejected: +(kpi.rejected_reattributed_event_count || 0),
							flagged: +(kpi.flagged_reattributed_event_count || 0),
							total: (+(kpi.clean_reattributed_event_count || 0)) + (+(kpi.rejected_reattributed_event_count || 0)) + (+(kpi.flagged_reattributed_event_count || 0)),
						},
						rAmount: {
							clean: +(kpi.clean_reattributed_amount || 0),
							rejected: +(kpi.rejected_reattributed_amount || 0),
							flagged: +(kpi.flagged_reattributed_amount || 0),
							total: (+(kpi.clean_reattributed_amount || 0)) + (+(kpi.rejected_reattributed_amount || 0)) + (+(kpi.flagged_reattributed_amount || 0)),
						},
						eventName: event
					})
				})
			});
		} catch (err) {
			toast.error(`Couldn't fetch kpi stats data.`, {
				autoClose: 3000,
			});
		}
	}

	convertSource = (source) => {
		const { supported_rules, supported_campaign_rules } = this.state;

		if (!source) {
			throw new Error('Source not found.');
		}

		const alarms = get(source, 'alarms', []).map(a => {
			const supported = find([...supported_rules, ...supported_campaign_rules], ['short-name', a.alarm_type]);
			const name = supported ? (RuleNameMaps[supported.name] || supported.name) :
				a?.alarm_type?.startsWith('EI_') ? a.alarm_type.replace('EI_', 'Event/Install Rate ') :
					a?.alarm_type;
			return ({
				short: a.alarm_type,
				name: name,
				count: a.alarm_count,
			});
		});

		const alarmChartData = {
			tooltipTitles: [],
			categories: [],
			series: [{
				data: [],
			}],
		};

		alarms
			.sort((a, b) => a.count - b.count)
			.forEach((a) => {
				alarmChartData.categories.push(a.short);
				alarmChartData.series[0].data.push(a.count);
				alarmChartData.tooltipTitles.push(a.name);
			});

		return ({
			alarmChartData,
			alarms,
		});
	}

	getCampaign = () => {
		const { match: { params: { id, source_id } } } = this.props;

		Api.getLeoparCampaigns({ id })
			.then(response => {
				const [campaign] = get(response, 'data.data');
				const [source] = campaign.sources.filter(s => s.id === +source_id);
				const { alarms, alarmChartData } = this.convertSource(source);
				campaign.sources = [source];

				this.setState(prevState => ({
					alarms,
					alarmChartData,
					campaign,
					source,
					loading: false,
					revenue_currency: prevState.appSettings?.[campaign.app_info.id]?.revenue_currency ?? 'USD',
				}), () => {
					this.getSourceStats();
					this.getSourceKPIStats();
					this.getRulesets();
				});
			}).catch((e) => {
				console.log(e);
				toast.error(`Couldn't fetch campaign.`, {
					autoClose: 3000,
				});
			});
	}

	getSourceITETDist = () => {
		const { selectedEvent } = this.state;
		const query = this.getQuery();

		if (selectedEvent) {
			query.event_name = selectedEvent;
		}

		Api.getLeoparSourceGraphData({
			type: 'event',
			metric_type: 'log_itet',
			...query,
		})
			.then((response) => {
				this.setState({
					itetDistFetching: false,
					itetDist: get(response, 'data.data', []),
				});
			}).catch(() => {
				toast.error(`Couldn't fetch ITET data.`, {
					autoClose: 3000,
				});
				this.setState({ itetDistFetching: false });
			});
	}

	getSourceCTITDist = () => {
		const query = this.getQuery();

		query.metric_type = 'log_ctit';
		query.type = 'install';

		Api.getLeoparSourceGraphData(query)
			.then((response) => {
				this.setState({
					ctitDistFetching: false,
					ctitDist: get(response, 'data.data', []),
				});
			}).catch((err) => {
				this.setState({ ctitDistFetching: false });
				toast.error(`Couldn't fetch CTIT data.`);
			})
	}

	limitPieData = (data, limit = 9) => {
		const versions = data.filter(d => semverValid(semverCoerce(d.version)));
		const sortedVersions = versions.sort((a, b) => semverCompare(semverCoerce(b.version), semverCoerce(a.version)));
		const invalids = data.filter(d => d.version !== 'unknown' && !semverValid(semverCoerce(d.version))).sort((a, b) => b.value - a.value);
		const unknown = data.filter(d => d.version === 'unknown').map(d => ({
			...d,
			version: `${d.version} (${d.value})`
		}));

		const list = [
			...sortedVersions,
			...invalids,
		].map(d => ({
			...d,
			version: `${d.version} (${d.value})`
		}))

		if (list.length > limit - 1) {
			const limitedList = list.slice(0, limit - 2);
			const others = list.slice(limit - 2).reduce((acc, curr) => ({
				version: `Other (${(acc.value || 0) + curr.value})`,
				value: (acc.value || 0) + curr.value
			}), {});
			return [
				...limitedList,
				...unknown,
				others,
			];
		} else {
			return [
				...list,
				...unknown,
			];
		}
	}

	getCountryDist = () => {
		const query = this.getQuery();

		this.setState({ countryDistFetching: true });
		Api.getLeoparSourceGraphData({
			...query,
			type: 'install',
			metric_type: 'country',
		})
			.then(response => {
				this.setState({
					countryDistFetching: false,
					countryDist: get(response, 'data.data', []),
				});
			}).catch(() => {
				this.setState({ countryDistFetching: false });
				toast.error(`Couldn't fetch sdk version.`, {
					autoClose: 3000,
				});
			});
	}

	getIPAnonymity = () => {
		const query = this.getQuery();

		this.setState({ IPAnonymityFetching: true });
		Api.getLeoparSourceGraphData({
			...query,
			type: 'install',
			metric_type: 'ip_anonymity',
		})
			.then(response => {
				const data = get(response, 'data.data', []);
				this.setState({
					IPAnonymityLabels: ['Legit', 'Anonymous'],
					IPAnonymity: data.length > 0 ? [data[0].Legit, data[1].Anonymous] : [],
					IPAnonymityFetching: false,
				});
			}).catch(() => {
				this.setState({ IPAnonymityFetching: false });
				toast.error(`Couldn't fetch sdk version.`, {
					autoClose: 3000,
				});
			});
	}

	getSdkVersion = () => {
		const query = this.getQuery();

		this.setState({ SDKVersionsFetching: true });
		Api.getLeoparSourceGraphData({
			...query,
			type: 'install',
			metric_type: 'sdk_version',
		})
			.then(response => {
				const rawData = get(response, 'data.data', []);
				const data = this.limitPieData(rawData);
				this.setState({
					SDKVersionsLabels: data.map(d => d.version),
					SDKVersions: data.map(d => d.value),
					SDKVersionsFetching: false,
				})
			}).catch(() => {
				this.setState({ SDKVersionsFetching: false });
				toast.error(`Couldn't fetch sdk version.`, {
					autoClose: 3000,
				});
			});
	}

	getAppVersion = () => {
		const query = this.getQuery();

		this.setState({ appVersionsFetching: true });
		Api.getLeoparSourceGraphData({
			...query,
			type: 'install',
			metric_type: 'app_version',
		})
			.then(response => {
				const rawData = get(response, 'data.data', []);
				const data = this.limitPieData(rawData);
				this.setState({
					appVersionsLabels: data.map(d => d.version),
					appVersions: data.map(d => d.value),
					appVersionsFetching: false,
				});
			}).catch(() => {
				this.setState({ appVersionsFetching: false });
				toast.error(`Couldn't fetch app version.`, {
					autoClose: 3000,
				});
			});
	}

	getOsVersion = () => {
		const query = this.getQuery();

		this.setState({ osVersionFetching: true });
		Api.getLeoparSourceGraphData({
			...query,
			type: 'install',
			metric_type: 'os_version',
		})
			.then((response) => {
				const rawData = get(response, 'data.data', []).reduce((acc, curr) => {
					const [major, minor] = curr.version.split('.');
					const version = `${major}${minor ? `.${minor}` : ''}`;
					acc[version] = acc[version] || { version, value: 0 };
					acc[version].value += curr.value;
					return acc;
				}, {});

				const data = this.limitPieData(Object.values(rawData));
				this.setState({
					OSVersionsLabels: data.map(d => d.version),
					OSVersions: data.map(d => d.value),
					osVersionFetching: false,
				});
			}).catch((err) => {
				console.error(err)
				this.setState({ osVersionFetching: false });
				toast.error(`Couldn't fetch os version.`, {
					autoClose: 3000,
				});
			});
	}

	getTimeDistData = (e) => {
		const { match: { params: { id, source_id } } } = this.props;
		const { filters, timeDistActiveDay, selectedEventForTimeDist } = this.state;

		if (e !== 'eventChange') {
			this.setState({ installTimeDistFetching: true });
			Api.getLeoparSourceGraphData({
				type: 'install',
				metric_type: 'install_time',
				source_id: source_id,
				campaign_id: id,
				ts_start: getTimestamp(filters.start),
				ts_end: getTimestamp(filters.end, true),
				day: timeDistActiveDay.id ? timeDistActiveDay.text : null,
			})
				.then(response => {
					const data = get(response, 'data.data', []);
					this.setState({
						installTimeDistFetching: false,
						installTimeDist: [{
							name: 'Number of Installs',
							type: 'line',
							data: data.length > 0 ? fillHours(sortBy(data, 'x')).map(d => ({ ...d, x: String(d.x) })) : [],
						}],
					})
				})
				.catch((e) => {
					console.error(e)
					this.setState({ installTimeDistFetching: false });
					toast.error(`Couldn't fetch Time Distribution data.`, {
						autoClose: 3000,
					});
				});
		}

		const query = {
			source_id: source_id,
			campaign_id: id,
			ts_start: getTimestamp(filters.start),
			ts_end: getTimestamp(filters.end, true),
			day: timeDistActiveDay.id ? timeDistActiveDay.text : null,
		};

		if (selectedEventForTimeDist) {
			query.event_name = selectedEventForTimeDist;
		}

		this.setState({ eventTimeDistFetching: true });
		Api.getLeoparSourceGraphData({
			...query,
			type: 'event',
			metric_type: 'event_time',
		})
			.then(response => {
				const data = get(response, 'data.data', []);
				this.setState({
					eventTimeDistFetching: false,
					eventTimeDist: [{
						name: 'Number of Events',
						type: 'line',
						data: data.length > 0 ? fillHours(sortBy(data, 'x')).map(d => ({ ...d, x: String(d.x) })) : [],
					}],
				})
			})
			.catch((e) => {
				console.error(e);
				this.setState({ eventTimeDistFetching: false });
				toast.error(`Couldn't fetch Event Time Distribution data.`, {
					autoClose: 3000,
				});
			});
	}

	handleDateRange = ({ from, to }) => {
		this.setState(prevState => ({
			filters: {
				...prevState.filters,
				start: from,
				end: to,
			}
		}), this.getData);

		Local.setItem('date_filter', {
			from,
			to,
		});
	}

	handleTimeDistDay = (e, day) => {
		this.setState({
			timeDistActiveDay: day
		}, this.getTimeDistData)
	}

	pauseSource = () => {
		const { source } = this.state;

		Api.blockSource(source.campaign, source.id)
			.then(() => this.getCampaign())
			.catch(({ response }) => {
				SendEvent({
					description: `pauseSource for ${source.id} on source detail`,
					fatal: false
				});
				toast.error(`Couldn't pause the source`);
			})
	}

	startSource = () => {
		const { source } = this.state;

		Api.unblockSource(source.campaign, source.id)
			.then(() => this.getCampaign())
			.catch(({ response }) => {
				SendEvent({
					description: `startSource for ${source.id} on source detail`,
					fatal: false
				});
				toast.error(`Couldn't pause the source`);
			})
	}

	copyUrl = name => {
		const { source } = this.state;
		copyTextToClipboard(source[name]);
		toast.success('URL copied');
	}

	activateBot = () => {
		const { source } = this.state;

		Api.activateCheckBot(source.campaign, source.id)
			.then(res => {
				this.getCampaign()
			})
			.catch(({ response }) => {
				SendEvent({
					description: `activateBot for ${source.id} on source detail`,
					fatal: false
				});
				toast.error(`Couldn't activate bot prevention for the source`);
			})
	}

	disableBot = () => {
		const { source } = this.state;

		Api.deactivateCheckBot(source.campaign, source.id)
			.then(res => {
				this.getCampaign()
			})
			.catch(({ response }) => {
				SendEvent({
					description: `disableBot for ${source.id} on source detail`,
					fatal: false
				});
				toast.error(`Couldn't disable bot prevention for the source`);
			})
	}

	renderFraudGraphCell = ({ original, column, value }) => {
		const data = value || EmptyStatsObjects.event;
		return (
			<div className="campaign-table-fraud-bar-container">
				<FraudGraph
					data={data}
					title={column.Header}
				/>
			</div>
		);
	}

	toggleSourceInfo = () => {
		this.setState(prevState => ({
			sourceInfoOpen: !prevState.sourceInfoOpen,
		}));
	}

	render() {
		const { match } = this.props;
		const {
			me,
			filters,
			campaign,
			loading,
			source,
			ctitDistFetching,
			ctitDist,
			itetDistFetching,
			itetDist,
			timeDistActiveDay,
			installTimeDistFetching,
			installTimeDist,
			eventTimeDistFetching,
			eventTimeDist,
			appVersionsLabels,
			appVersions,
			OSVersionsLabels,
			OSVersions,
			SDKVersionsLabels,
			SDKVersions,
			IPAnonymityLabels,
			IPAnonymity,
			countryDistFetching,
			countryDist,
			goToSubpublishers,
			appVersionsFetching,
			SDKVersionsFetching,
			selectedEvent,
			selectedEventForTimeDist,
			sourceStats,
			sourceStatsFetching,
			impressionStats,
			revenue_currency,
			sourceKPITable,
			sourceInfoOpen,
			showImpressions,
			alarmChartData,
			alarms,
		} = this.state;

		if (goToSubpublishers) {
			let subpubUrl = `/campaign/${match.params.id}/subpublishers`;
			return <Redirect push to={{
				pathname: subpubUrl,
				state: { sourceId: source.id },
			}} />
		}

		if (loading) return <Loader />;

		return (
			<div className="source-detail">
				<PageTitle>
					<Breadcrumbs>
						<Breadcrumbs.Crumb><Link to="/">Campaigns</Link></Breadcrumbs.Crumb>
						<Breadcrumbs.Crumb><Link to={`/campaign/${match.params.id}`}>Campaign Detail</Link></Breadcrumbs.Crumb>
						<Breadcrumbs.Crumb><Link to={match.url}>Source Detail</Link></Breadcrumbs.Crumb>
					</Breadcrumbs>
					<PageTitle.Title>
						Source Detail - <AppIcon app={campaign.app_name ? campaign.app_info : undefined} isShadow={isShadow()} /> {shadowText(source.name, 'Example Source')} - <span className={`source-status ${source.status !== 1 ? 'blocked' : ''}`}>{source.status !== 1 ? 'Blocked' : 'Active'}</span>
					</PageTitle.Title>
				</PageTitle>

				<FilterBox>
					<div className="filter-column filter-column-left">
						{Local.getItem('me')?.plans?.interceptd !== 'free' &&
							<Actions
								className="source-detail-actions"
								attachment="top left"
								targetAttachment="bottom left"
								triggerContent={
									<Button className="button-icon" bgColor="transparent">
										<Icon i="more-vertical" /> Actions
									</Button>
								}>
								<Actions.Item onClick={() => history.push(`/campaign/${match.params.id}/edit/sources?source=${match.params.source_id}`, { back: match.url })} data-event-category="Source Detail" data-event-action="Edit source action clicked">Edit Source</Actions.Item>
								{campaign.type !== 'CPM' && (
									<Actions.Item onClick={source.status !== 1 ? this.startSource : this.pauseSource} data-event-category="Source Detail" data-event-action={`${source.status !== 1 ? 'Start' : 'Pause'} source action clicked`}>{`${source.status !== 1 ? 'Start' : 'Pause'}`} this source</Actions.Item>
								)}
								<Actions.Item className={me.plans?.interceptd === 'free' ? 'bg-red' : ''} onClick={() => me.plans?.interceptd === 'free' ? openFreeModeWarning() : this.copyUrl('tracking_url')} data-event-category="Source Detail" data-event-action="Copy source tracking URL action clicked">Copy tracking URL</Actions.Item>
								{/* {campaign.type !== 'CPM' && (
									<Actions.Item onClick={() => source.check_bot ? this.disableBot() : this.activateBot()} data-event-category="Source Detail" data-event-action={`${source.check_bot ? 'Disable' : 'Activate'} bot prevention action clicked`}>{`${source.check_bot ? 'Disable' : 'Activate'} bot prevention`}</Actions.Item>
								)} */}
								{campaign.type !== 'CPM' && <Actions.Item onClick={() => history.push(`/campaign/${match.params.id}/edit/sources?source=${match.params.source_id}&field=selected_events`, { back: match.url })} data-event-category="Source Detail" data-event-action="Event postback settings action clicked">Event postback settings</Actions.Item>}
								<Actions.Item onClick={() => this.setState({ goToSubpublishers: true })} data-event-category="Source Detail" data-event-action="Go to subpublisher button clickerd">Go to Subpublisher List</Actions.Item>
							</Actions>
						}
						<Button className="button-icon" bgColor="transparent" onClick={this.toggleSourceInfo}>
							{sourceInfoOpen && <Icon i="x-circle" />}
							{sourceInfoOpen ? 'Close Info' : 'Info'}
						</Button>
						{this.renderRulesCell({
							original: source,
							className: 'campaign-detail-filter-rules'
						})}
					</div>

					<div className="filter-column filter-column-right">
						<DatePicker
							value={{
								from: filters.start,
								to: filters.end,
							}}
							onChange={this.handleDateRange} />
					</div>
				</FilterBox>

				<SourceInfo
					match={match}
					type={campaign.type}
					data={source}
					open={sourceInfoOpen} />

				<DetailStatsBoxes
					data={sourceStats}
					loading={sourceStatsFetching}
					conversionRate
					showImpressions={showImpressions}
				/>

				{campaign.type !== 'CPM' && <KPIPerformanceTable
					campaign={campaign}
					getCampaign={this.getCampaign}
					data={sourceKPITable}
					loading={sourceStatsFetching}
					revenueCurrency={revenue_currency}
				/>}

				<CreativePerformanceTable
					sources={[source]}
					filters={filters}
					revenueCurrency={revenue_currency}
				/>

				<div className="source-wrapper">
					<div className="irow">
						<div className="icol">
							<Box title="Publisher Fraud Distribution">
								<DetailSankey
									sourceIds={[source.id]}
									filters={filters}
									campaign={campaign}
									impression={campaign.type === 'CPM'}
									sourceNames={(!loading && campaign.sources) ? campaign.sources.reduce((acc, cur) => {
										acc[cur.id] = cur.name;
										return acc;
									}, {}) : {}}
								/>
							</Box>
						</div>

						<div className="icol">
							<Box title="Country Distribution">
								{countryDistFetching ? (
									<Loader />
								) : countryDist.length > 0 ? (
									<CountryDistMap data={countryDist} />
								) : (
											<NoData />
										)}
							</Box>
						</div>
					</div>

					<div className="irow">
						<div className="icol">
							<Box title="Alarm Distribution">
								{alarms.length > 0 ? (
									<AlarmDistGraph data={alarmChartData} />
								) : (
										<NoData />
									)}
							</Box>
						</div>
						<div className="icol">
							<Box
								title="Event & Install Time Distribution"
								right={
									<React.Fragment>
										{(campaign && campaign.kpis && campaign.kpis.length > 0) && (
											<Select
												mini
												clearable
												placeholder="Event"
												value={selectedEventForTimeDist}
												options={campaign.kpis.map((kpi) => ({
													label: kpi.event_name,
													value: kpi.event_name,
												}))}
												onChange={v => {
													this.setState({ selectedEventForTimeDist: v }, () => this.getTimeDistData('eventChange'))
												}}
												getTetherProps={props => ({
													...props,
													attachment: 'top right',
													targetAttachment: 'bottom right',
												})} />
										)}
									</React.Fragment>
								}>
								{(eventTimeDistFetching || installTimeDistFetching) ? (
									<Loader />
								) : (
										<React.Fragment>
											<TimeDistDays active={timeDistActiveDay} onChange={this.handleTimeDistDay} />
											{((eventTimeDist[0].data.length) || installTimeDist[0].data.length) > 0 ? (
												<TimeDistGraph
													series={
														installTimeDist[0].data.length > 0 ?
															[...installTimeDist, ...eventTimeDist] :
															[...eventTimeDist, ...installTimeDist]
													}
													getOptions={options => {
														return ({
															...options,
															yaxis: installTimeDist[0].data.length > 0 ? [{
																...options.yaxis,
																seriesName: 'Number of Installs',
																min: 0,
																forceNiceScale: true,
																title: {
																	text: 'Number of Installs'
																}
															}, {
																...options.yaxis,
																seriesName: 'Number of Events',
																min: 0,
																forceNiceScale: true,
																opposite: true,
																title: {
																	text: 'Number of Events'
																}
															}] : [{
																...options.yaxis,
																seriesName: 'Number of Events',
																min: 0,
																forceNiceScale: true,
																title: {
																	text: 'Number of Events'
																}
															}, {
																...options.yaxis,
																seriesName: 'Number of Installs',
																min: 0,
																forceNiceScale: true,
																opposite: true,
																title: {
																	text: 'Number of Installs'
																}
															}]
														})
													}} />
											) : <NoData />}
										</React.Fragment>
									)}
							</Box>
						</div>
					</div>

					<div className="irow">
						<div className="icol">
							<Box title="Daily Performance">
								<DetailDailyChart campaign={campaign} source={source} filters={filters} />
							</Box>
						</div>

						<div className="icol">
							<Box
								title="Daily Impressions"
								right={
									<React.Fragment>
										<ImpressionPixels source={source} creative_ids={uniq(impressionStats.map(d => d.creative_id))} />
									</React.Fragment>
								}>
								<DailyImpressionsChart source={source} filters={filters} />
							</Box>
						</div>
					</div>

					<div className="irow">
						<div className="icol">
							<Box
								title="ITET Distribution"
								right={
									<React.Fragment>
										{(campaign && campaign.kpis && campaign.kpis.length > 0) && (
											<Select
												mini
												placeholder="Event"
												value={selectedEvent}
												options={campaign.kpis.map((kpi) => ({
													label: kpi.event_name,
													value: kpi.event_name,
												}))}
												onChange={v => this.setState({ selectedEvent: v }, this.getSourceITETDist)}
												getTetherProps={props => ({
													...props,
													attachment: 'top right',
													targetAttachment: 'bottom right',
												})} />
										)}
									</React.Fragment>
								}>
								{itetDistFetching ? (
									<Loader />
								) : itetDist && itetDist.length > 0 ? (
									<CTITDistGraph data={itetDist} />
								) : (
											<NoData />
										)}
							</Box>
						</div>

						<div className="icol">
							<Box title="CTIT Distribution">
								{ctitDistFetching ? (
									<Loader />
								) : ctitDist && ctitDist.length > 0 ? (
									<CTITDistGraph data={ctitDist} />
								) : (
											<NoData />
										)}
							</Box>
						</div>
					</div>

					<div className="source-detail-pie-row">

						<div className="source-detail-pie-col">
							<Box title="App Version Distribution">
								{appVersionsFetching ? (
									<Loader />
								) : (appVersions && appVersions.length > 0) ? (
									<AppVersionPie
										series={appVersions}
										getOptions={options => ({
											...options,
											legend: {
												width: 170,
											},
											labels: appVersionsLabels
										})} />
								) : (
											<NoData />
										)}
							</Box>
						</div>

						<div className="source-detail-pie-col">
							<Box title="OS Version Distribution">
								{OSVersions && OSVersions.length > 0 ? (
									<OSVersionPie
										series={OSVersions}
										getOptions={options => ({
											...options,
											legend: {
												width: 170,
											},
											labels: OSVersionsLabels
										})} />
								) : <NoData />}
							</Box>
						</div>

						<div className="source-detail-pie-col">
							<Box title="SDK Version Distribution">
								{SDKVersionsFetching ? (
									<Loader />
								) : SDKVersions.length > 0 ? (
									<SDKVersionPie
										series={SDKVersions}
										getOptions={options => ({
											...options,
											legend: {
												width: 170,
											},
											labels: SDKVersionsLabels
										})} />
								) : (
											<NoData />
										)}
							</Box>
						</div>

						<div className="source-detail-pie-col">
							<Box title="IP Anonymity">
								{(IPAnonymity && IPAnonymity.length > 0 && (IPAnonymity[0] || IPAnonymity[1])) ? (
									<IPAnonymityPie
										series={IPAnonymity}
										getOptions={options => ({
											...options,
											legend: {
												width: 170,
											},
											labels: IPAnonymityLabels
										})} />
								) : (
										<NoData />
									)}
							</Box>
						</div>

					</div>

				</div>
			</div>
		)
	}
}

export default withTitle(SourceDetail, 'Source Detail', 'source_id');
