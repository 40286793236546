import React, { useState } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
	Icon,
	Button,
	Select,
	Input,
} from 'interceptd-ui';

import { EventCategories } from '../../../constants/Campaign';

import Settings from '../../../services/settings';

import './styles/FunnelCreate.css';

const EventFunnelCreate = ({ funnel, app, eventMatches, colors, onClose, onSave }) => {
	const categories = EventCategories.map(c => c.label).filter(category => eventMatches.some(match => match.category === category))
	const [state, setState] = useState(funnel ? {
		funnel,
		editing: true,
	} : {
		funnel: {
			name: '',
			events: [null],
			ts_create: null,
			id: null,
		}
	});

	const handleName = event => {
		const { value } = event.target;
		setState(prevState => {
			return ({
				...prevState,
				funnel: {
					...prevState.funnel,
					name: value,
				},
			})
		})
	}

	const handleSave = () => {
		const funnelId = Math.random().toString(36).substr(2, 9);

		Settings.set('funnels', {
			...(Settings.get('funnels', 'account') || {}),
			[app]: state.editing ? ((Settings.get('funnels', 'account') || {})[app] || []).map(funnel => funnel.id === state.funnel.id ? state.funnel : funnel) : [
				...((Settings.get('funnels', 'account') || {})[app] || []),
				{
					...state.funnel,
					id: funnelId,
					ts_create: moment().format('X')
				}
			]
		}, 'account');

		toast.success('Funnel created.');
		onSave((Settings.get('funnels', 'account')?.[app] || []).findIndex(f => f.id === (state.editing ? state.funnel.id : funnelId)));
		onClose();
	}

	const handleAddStep = category => {
		setState(prevState => ({
			...prevState,
			funnel: {
				...prevState.funnel,
				events: [
					...prevState.funnel.events,
					null
				],
			},
		}))
	}

	const handleEditStep = (index, category) => {
		setState(prevState => ({
			...prevState,
			funnel: {
				...prevState.funnel,
				events: prevState.funnel.events.map((c, i) => i === index ? category : c),
			},
		}))
	}

	const handleRemoveStep = index => {
		setState(prevState => ({
			...prevState,
			funnel: {
				...prevState.funnel,
				events: prevState.funnel.events.filter((_, i) => i !== index),
			},
		}))
	}

	const reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		return result;
	};

	const onDragEnd = result => {
		if ( !result.destination ) return

		const items = reorder(
			state.funnel.events,
			result.source.index,
			result.destination.index
		);

		setState(prevState => ({
			...prevState,
			funnel: {
				...prevState.funnel,
				events: items,
			},
		}))
	};

	return (
		<div className="event-funnel-create">
			<div className="event-funnel-create-header">
				<div className="event-funnel-create-title">Create New Funnel</div>
				<div className="event-funnel-create-buttons">
					<Button mini bgColor="transparent" onClick={onClose}>Cancel</Button>
					<Button mini bgColor="shade" onClick={handleSave} disabled={state.funnel.name === '' || state.funnel.events.length < 2 || !state.funnel.events.every(e => e && e !== '')}>{state.editing ? 'Save' : 'Create'}</Button>
				</div>
			</div>

			<div className="event-funnel-create-name">
				<Input
					autoFocus
					label="Name"
					name="funnel-name"
					placeholder="Enter funnel name"
					value={state.funnel.name}
					onChange={handleName} />
			</div>

			<div className="event-funnel-create-info">
				<Icon i="info" size={25} /><span>Select and order events that you want to view in the funnel</span>
			</div>

			<div className="event-funnel-builder">
				<DragDropContext onDragEnd={onDragEnd}>
					<Droppable droppableId="funnel">
						{(provided, snapshot) => (
							<div
								ref={provided.innerRef}
								className="event-funnel-builder-column">
								{state.funnel.events.map((category, index) => (
									<Draggable
										key={index}
										draggableId={`step-${index}`}
										index={index}>
										{(provided, snapshot) => (
											<div
												ref={provided.innerRef}
												{...provided.draggableProps}
												{...provided.dragHandleProps}
												style={{
													...provided.draggableProps.style,
													'--color': colors[category]
												}}
												className={`
													event-funnel-builder-step
													${snapshot.isDragging ? 'is-dragging' : ''}
												`}>
												<Icon className="event-funnel-builder-step-handle" i="menu" size="18" />
												<Icon className="event-funnel-builder-step-remove" i="trash" size="18" onClick={() => handleRemoveStep(index)} />
												<div className="event-funnel-builder-select">
													<Select
														mini
														label="Event"
														placeholder="Select an Event"
														value={[category]}
														options={categories.map(category => ({
															label: category,
															value: category,
															color: colors[category],
														}))}
														onChange={([val]) => handleEditStep(index, val)}
														optionClassName="event-funnel-builder-select-option"
														renderValue={({ displayValue, value }) => (
															<div className="select-value">
																{value[0] && <i className="event-funnel-builder-select-dot" style={{ '--color': colors[value[0]] }} />}
																<span>{displayValue}</span>
																<div className="select-value-icons">
																	<Icon className="select-value-arrow" i="chevron-down" size={14} />
																</div>
															</div>
														)}
														renderOptionBefore={({ option }) => (
															<span className="event-funnel-builder-select-dot" style={{ '--color': colors[option.value] }} />
														)}
														renderOptionAfter={({ option }) => state.funnel.events.some((c, i) => i !== index && c === option.value) ? (
															<span className="event-funnel-builder-select-added">Added</span>
														) : null} />
												</div>
											</div>
										)}
									</Draggable>
								))}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
				<div className="event-funnel-builder-add" onClick={handleAddStep}>
					<Icon i="plus" size={18} />
					<span>Add Event</span>
				</div>
			</div>
		</div>
	);
}

export default EventFunnelCreate;
