import React, { PureComponent } from 'react';
import Chart from 'react-apexcharts';
import moment from 'moment';
import Humanize from 'humanize-plus';
import toUpper from 'lodash/toUpper';
import unionBy from 'lodash/unionBy';
import toString from 'lodash/toString';
import get from 'lodash/get';
import {
	Loader,
	FraudGraph,
	Select,
} from 'interceptd-ui';

import { shadowText } from '../../../utils/misc';

import { colors } from '../../../constants/Color';
import {
	TYPE_SELECT_OPTIONS,
	LINE_FILTER_OPTIONS,
	PLATFORM_OPTIONS,
} from '../../../constants/DailyPerformance';

import './styles/GraphView.css';

export default class GraphView extends PureComponent {
	state = {
		sources: this.props.sources,
		total: GraphView.getDefaultTotalTemplate(),

		currentData: {},

		selectedLineFilter: 'app',

		appData: null,
		selectedApps: [],

		partners: [],
		partner: null,
		selectedPartners: [],

		events: [],
		selectedEvent: null,

		selectedPlatform: [],
		platformOptions: [],
		platform: null,

		selectedType: 'clean_click_count',
	};

	static getDefaultCfrTemplate = () => ({
		cost: 0,
		clean_click_count: 0,
		flagged_click: 0,
		rejected_click: 0,
		clean_install_count: 0,
		flagged_install: 0,
		rejected_install: 0,
		clean_event: 0,
		flagged_event: 0,
		rejected_event: 0,
		total: GraphView.getDefaultTotalTemplate(),
		days: {},
	});

	static getDefaultTotalTemplate = () => ({
		click: {
			clean: 0,
			rejected: 0,
			flagged: 0,
			total: 0,
		},
		install: {
			clean: 0,
			rejected: 0,
			flagged: 0,
			total: 0,
		},
		event: {
			clean: 0,
			rejected: 0,
			flagged: 0,
			total: 0,
		},
	});

	sortByDate = (a, b) => {
		return new Date(a.split('/')[2], a.split('/')[1], a.split('/')[0]) - new Date(b.split('/')[2], b.split('/')[1], b.split('/')[0]);
	}

	separateData = () => {
		const {
			selectedPlatform,
			selectedApps,
			selectedPartners,
			selectedType,
			selectedEvent,
			selectedLineFilter,
		} = this.state;
		const { sources } = this.props;

		const platform = {
			all: GraphView.getDefaultCfrTemplate(),
		};
		const partner = {
			all: GraphView.getDefaultCfrTemplate(),
		};
		const appData = {
			all: GraphView.getDefaultCfrTemplate(),
		};

		sources.forEach((source) => {
			const { stats } = source;
			const appId = toString(source.app.id);
			const partnerId = toString(source.partnerId);
			const sourcePlatform = toString(source.platform);

			if (selectedLineFilter === 'platform') {
				if (selectedPlatform && selectedPlatform.length > 0 && selectedPlatform.indexOf(sourcePlatform) === -1) {
					return;
				}
			} else if (selectedLineFilter === 'partner') {
				if (selectedPartners && selectedPartners.length > 0 && selectedPartners.indexOf(partnerId) === -1) {
					return;
				}
			} else if (selectedLineFilter === 'app') {
				if (selectedApps && selectedApps.length > 0 && selectedApps.indexOf(appId) === -1) {
					return;
				}
			}

			platform[sourcePlatform] = platform[sourcePlatform] || {
				name: toUpper(sourcePlatform),
				total: GraphView.getDefaultTotalTemplate(),
				days: {},
			};

			partner[partnerId] = partner[partnerId] || {
				name: source.partner.name,
				total: GraphView.getDefaultTotalTemplate(),
				days: {},
			};

			appData[appId] = appData[appId] || {
				name: `${source.app.title} (${sourcePlatform})`,
				total: GraphView.getDefaultTotalTemplate(),
				days: {},
			};

			// total data
			Object.keys(stats).forEach((statsKey) => {
				const stat = stats[statsKey];
				Object.keys(stat).forEach((statName) => {
					if (platform[sourcePlatform].total[statsKey]) {
						platform[sourcePlatform].total[statsKey][statName] += stat[statName];
						platform.all.total[statsKey][statName] += stat[statName];
					}
					if (partner[partnerId].total[statsKey]) {
						partner[partnerId].total[statsKey][statName] += stat[statName];
						partner.all.total[statsKey][statName] += stat[statName];
					}
					if (appData[appId].total[statsKey]) {
						appData[appId].total[statsKey][statName] += stat[statName];
						appData.all.total[statsKey][statName] += stat[statName];
					}
				});
			});

			// daily data
			source.daily.forEach((daily) => {
				const day = moment(daily.date, 'X').format('DD/MM/YYYY');

				const getDefaultData = () => ({
					...GraphView.getDefaultCfrTemplate(),
					platform: sourcePlatform,
					partnerId,
					appId,
					date: daily.date,
					events: {}
				});

				platform.all.days[day] = platform.all.days[day] || GraphView.getDefaultCfrTemplate();
				platform.all.days[day].date = daily.date;
				platform[sourcePlatform].days[day] = platform[sourcePlatform].days[day] || getDefaultData();

				partner.all.days[day] = partner.all.days[day] || GraphView.getDefaultCfrTemplate();
				partner.all.days[day].date = daily.date;
				partner[partnerId].days[day] = partner[partnerId].days[day] || getDefaultData();

				appData.all.days[day] = appData.all.days[day] || GraphView.getDefaultCfrTemplate();
				appData.all.days[day].date = daily.date;
				appData[appId].days[day] = appData[appId].days[day] || getDefaultData();

				if (selectedLineFilter !== 'platform' && selectedPlatform && selectedPlatform.length > 0) {
					if (selectedPlatform.indexOf(sourcePlatform) === -1) {
						return 0;
					}
				}

				if (selectedLineFilter !== 'partner' && selectedPartners && selectedPartners.length > 0) {
					if (selectedPartners.indexOf(partnerId) === -1) {
						return 0;
					}
				}

				if (selectedLineFilter !== 'app' && selectedApps && selectedApps.length > 0) {
					if (selectedApps.indexOf(appId) === -1) {
						return 0;
					}
				}

				Object.keys(daily.events)
					.filter(e => !/_reattributed/g.test(e) && !/_amount/g.test(e))
					.forEach((e) => {
						platform[sourcePlatform].days[day].events[e] = platform[sourcePlatform].days[day].events[e] || {
							total: 0,
							clean: 0,
							flagged: 0,
							rejected: 0,
						};
						platform[sourcePlatform].days[day].events[e].total += daily.events[e].total;
						platform[sourcePlatform].days[day].events[e].clean += daily.events[e].clean;
						platform[sourcePlatform].days[day].events[e].flagged += daily.events[e].flagged;
						platform[sourcePlatform].days[day].events[e].rejected += daily.events[e].rejected;

						partner[partnerId].days[day].events[e] = partner[partnerId].days[day].events[e] || {
							total: 0,
							clean: 0,
							flagged: 0,
							rejected: 0,
						};
						partner[partnerId].days[day].events[e].total += daily.events[e].total;
						partner[partnerId].days[day].events[e].clean += daily.events[e].clean;
						partner[partnerId].days[day].events[e].flagged += daily.events[e].flagged;
						partner[partnerId].days[day].events[e].rejected += daily.events[e].rejected;

						appData[appId].days[day].events[e] = appData[appId].days[day].events[e] || {
							total: 0,
							clean: 0,
							flagged: 0,
							rejected: 0,
						};
						appData[appId].days[day].events[e].total += daily.events[e].total;
						appData[appId].days[day].events[e].clean += daily.events[e].clean;
						appData[appId].days[day].events[e].flagged += daily.events[e].flagged;
						appData[appId].days[day].events[e].rejected += daily.events[e].rejected;
					});
					
				platform[sourcePlatform].days[day].cost += daily.cost;
				platform[sourcePlatform].days[day].clean_click_count += daily.stats.click.clean;
				platform[sourcePlatform].days[day].flagged_click += daily.stats.click.flagged;
				platform[sourcePlatform].days[day].rejected_click += daily.stats.click.rejected;

				platform[sourcePlatform].days[day].clean_install_count += daily.stats.install.clean;
				platform[sourcePlatform].days[day].flagged_install += daily.stats.install.flagged;
				platform[sourcePlatform].days[day].rejected_install += daily.stats.install.rejected;

				platform[sourcePlatform].days[day].clean_event += daily.stats.event.clean;
				platform[sourcePlatform].days[day].flagged_event += daily.stats.event.flagged;
				platform[sourcePlatform].days[day].rejected_event += daily.stats.event.rejected;

				partner[partnerId].days[day].cost += daily.cost;
				partner[partnerId].days[day].clean_click_count += daily.stats.click.clean;
				partner[partnerId].days[day].flagged_click_count += daily.stats.click.flagged;
				partner[partnerId].days[day].rejected_click += daily.stats.click.rejected;

				partner[partnerId].days[day].clean_install_count += daily.stats.install.clean;
				partner[partnerId].days[day].flagged_install += daily.stats.install.flagged;
				partner[partnerId].days[day].rejected_install += daily.stats.install.rejected;

				partner[partnerId].days[day].clean_event += daily.stats.event.clean;
				partner[partnerId].days[day].flagged_event += daily.stats.event.flagged;
				partner[partnerId].days[day].rejected_event += daily.stats.event.rejected;

				appData[appId].days[day].cost += daily.cost;
				appData[appId].days[day].clean_click_count += daily.stats.click.clean;
				appData[appId].days[day].flagged_click += daily.stats.click.flagged;
				appData[appId].days[day].rejected_click += daily.stats.click.rejected;

				appData[appId].days[day].clean_install_count += daily.stats.install.clean;
				appData[appId].days[day].flagged_install += daily.stats.install.flagged;
				appData[appId].days[day].rejected_install += daily.stats.install.rejected;

				appData[appId].days[day].clean_event += daily.stats.event.clean;
				appData[appId].days[day].flagged_event += daily.stats.event.flagged;
				appData[appId].days[day].rejected_event += daily.stats.event.rejected;
			});
		});

		const platformCategories = Object.keys(platform.all.days).sort(this.sortByDate);
		const platformSeries = [];
		Object.keys(platform).forEach((p) => {
			if (p === 'all') return;

			const data = platformCategories.map((d) => {
				const day = platform[p].days[d];
				if (!day) return 0;

				if (selectedEvent && /event/gi.test(selectedType)) {
					return (day.events[selectedEvent] && day.events[selectedEvent][selectedType.split('_')[0]]) || 0;
				}

				return day[selectedType];
			});

			platformSeries.push({
				name: platform[p].name,
				data,
				min: 0,
			});
		});

		const partnerCategories = Object.keys(partner.all.days).sort(this.sortByDate);
		const partnerSeries = [];
		Object.keys(partner).forEach((p) => {
			if (p === 'all') return;

			const data = partnerCategories.map((d) => {
				const day = partner[p].days[d];
				if (!day) return 0;

				if (selectedEvent && /event/gi.test(selectedType)) {
					return (day.events[selectedEvent] && day.events[selectedEvent][selectedType.split('_')[0]]) || 0;
				}

				return day[selectedType];
			});

			partnerSeries.push({
				name: partner[p].name,
				data,
				min: 0,
			});
		});

		const appCategories = Object.keys(appData.all.days).sort(this.sortByDate);
		const appSeries = []
		Object.keys(appData).forEach((p) => {
			if (p === 'all') return;

			const data = appCategories.map((d) => {
				const day = appData[p].days[d];
				if (!day) return 0;

				if (selectedEvent && /event/gi.test(selectedType)) {
					return (day.events[selectedEvent] && day.events[selectedEvent][selectedType.split('_')[0]]) || 0;
				}

				return day[selectedType];
			});

			appSeries.push({
				name: appData[p].name,
				data,
				min: 0,
			});
		});

		return ({
			platform: this.fillEmptyData({
				categories: platformCategories,
				series: platformSeries,
			}),
			partner: this.fillEmptyData({
				categories: partnerCategories,
				series: partnerSeries,
			}),
			appData: this.fillEmptyData({
				categories: appCategories,
				series: appSeries,
			}),
		});
	}

	fillEmptyData = (data) => {
		if (!data.categories || data.categories.length !== 1) return data;
		const copy = {...data};

		const [d, m , y] = copy.categories[0].split('/');
		const before = moment(new Date(`${y}/${m}/${d}`)).subtract(1, 'day').format('DD/MM/YYYY');
		const after = moment(new Date(`${y}/${m}/${d}`)).add(1, 'day').format('DD/MM/YYYY');

		copy.categories.unshift(before);
		copy.categories.push(after);

		copy.series.forEach((s) => {
			s.data.unshift(0);
			s.data.push(0);
		});

		return copy;
	}

	getTotal = () => {
		const {
			selectedPlatform,
			selectedApps,
			selectedEvent,
			selectedPartners,
		} = this.state;
		const { sources } = this.props;
		let total = GraphView.getDefaultTotalTemplate();
		let selectedEventTotal = {
			name: null,
			clean: 0,
			flagged: 0,
			rejected: 0,
			total: 0,
		};

		const platforms = (selectedPlatform && selectedPlatform.length > 0)
			? selectedPlatform
			: [];
		const apps = (selectedApps && selectedApps.length > 0)
			? selectedApps
			: [];
		const partners = (selectedPartners && selectedPartners.length > 0)
			? selectedPartners
			: [];

		sources.forEach((source) => {
			if (platforms.length === 0 || platforms.indexOf(toString(source.platform)) > -1) {
				if (partners.length === 0 || partners.indexOf(toString(source.partnerId)) > -1) {
					if (apps.length === 0 || apps.indexOf(toString(source.appId)) > -1) {
						if (selectedEvent) {
							selectedEventTotal.clean += source?.events?.[selectedEvent]?.clean ?? 0;
							selectedEventTotal.flagged += source?.events?.[selectedEvent]?.flagged ?? 0;
							selectedEventTotal.rejected += source?.events?.[selectedEvent]?.rejected ?? 0;
							selectedEventTotal.total += source?.events?.[selectedEvent]?.total ?? 0;
							selectedEventTotal.name = selectedEventTotal?.name || selectedEvent;
						}

						total.click.clean += source?.stats?.click?.clean ?? 0;
						total.click.rejected += source?.stats?.click?.rejected ?? 0;
						total.click.flagged += source?.stats?.click?.flagged ?? 0;
						total.click.total += source?.stats?.click?.total ?? 0;

						total.event.clean += source?.stats?.event?.clean ?? 0;
						total.event.rejected += source?.stats?.event?.rejected ?? 0;
						total.event.flagged += source?.stats?.event?.flagged ?? 0;
						total.event.total += source?.stats?.event?.total ?? 0;

						total.install.clean += source?.stats?.install?.clean ?? 0;
						total.install.rejected += source?.stats?.install?.rejected ?? 0;
						total.install.flagged += source?.stats?.install?.flagged ?? 0;
						total.install.total += source?.stats?.install?.total ?? 0;
					}
				}
			}
		});

		return ({ total, selectedEventTotal });
	};

	generateGraphDataHeader = () => {
		const { total, selectedEventTotal } = this.getTotal();

		return (
			<div className="graph-data-header">
				<div className="graph-stats-box">
					<FraudGraph id="click" data={total.click} title="Total Click" />
				</div>
				<div className="graph-stats-box">
					<FraudGraph data={total.install} title="Total Install" />
				</div>
				<div className="graph-stats-box">
					<FraudGraph data={total.event} title="Total Event" />
				</div>
				{selectedEventTotal.name && <div className="graph-stats-box">
					<FraudGraph data={selectedEventTotal} title={selectedEventTotal.name} />
				</div>}
			</div>
		);
	}

	generateGraph = () => {
		const { selectedLineFilter } = this.state;

		const { partner, platform, appData } = this.separateData();

		let categories;
		let series;

		switch (selectedLineFilter) {
			case 'platform':
				categories = platform.categories;
				series = platform.series;
				break;
		
			case 'partner':
				categories = partner.categories;
				series = partner.series;
				break;

			default:
				categories = appData.categories;
				series = appData.series;
				break;
		}

		return (
			<Chart
				options={{
					chart: {
						width: '100%',
						zoom: {
							enabled: false
						},
					},
					colors,
					stroke: {
						curve: 'smooth',
						width: 2,
					},
					yaxis: {
						min: 0,
						labels: {
							formatter: (v) => {
								return Humanize.intComma(v);
							}
						},
					},
					xaxis: {
						categories,
					},
				}}
				series={series}
				type="line"
				height="400"
			/>
		);
	}

	render() {
		const { loading, sources, apps } = this.props;
		const {
			selectedPartners,
			selectedEvent,
			selectedPlatform,
			selectedLineFilter,
			selectedApps,
			selectedType,
		} = this.state;

		if (loading) {
			return <Loader />;
		}

		const events = Object.keys(get(sources, '[0].events', {})).filter(e => !/_reattributed/g.test(e) && !/_amount/g.test(e));
		const partners = unionBy(sources.map(s => s.partner), 'id');

		return (
			<div className="daily-performance-graph">
				<div className="daily-performance-graph-card">
					<div className="graph-filter">
						<div className="graph-filter-header">
							<Select
								label="Breakdown"
								placeholder="Breakdown"
								options={LINE_FILTER_OPTIONS}
								name="lineFilter"
								value={[selectedLineFilter]}
								onChange={([f]) => this.setState({ selectedLineFilter: f })}
							/>
						</div>
						<div className="graph-filter-body">
							<Select
								multi
								clearable
								label="Platform"
								placeholder="Platform"
								options={PLATFORM_OPTIONS}
								value={selectedPlatform}
								onChange={ss => this.setState({ selectedPlatform: ss })} />
							<Select
								multi
								clearable
								label="Ad Network"
								placeholder="Ad Network"
								options={partners.map(p => ({
									value: toString(p.id),
									label: shadowText(p.name, 'Example Partner'),
								}))}
								value={selectedPartners}
								onChange={sp => this.setState({ selectedPartners: sp })} />
							<Select
								multi
								clearable
								label="App"
								placeholder="App"
								options={apps.map(a => ({
									value: toString(a.id),
									label: shadowText(a.title, 'Example App'),
								}))}
								value={selectedApps}
								onChange={ao => this.setState({ selectedApps: ao })} />
							<Select
								label="Type"
								name="type"
								placeholder="Type"
								options={TYPE_SELECT_OPTIONS}
								value={[selectedType]}
								onChange={([t]) => this.setState({ selectedType: t })} />
							{/event/gi.test(selectedType) && (
								<Select
									clearable
									label="Events"
									name="eventFilter"
									placeholder="Events"
									options={events.map(e => ({
										value: toString(e),
										label: e,
									}))}
									value={selectedEvent || []}
									onChange={se => this.setState({ selectedEvent: se || null })} />
							)}
						</div>
					</div>
					<div className="graph-data">
						{this.generateGraphDataHeader()}
						<div className="graph-data-body">
							{this.generateGraph()}
						</div>
					</div>
				</div>
			</div>
		);
	}
}