import React from 'react';
import { toast } from 'react-toastify';
import ClickOutside from 'react-click-outside';
import moment from 'moment';
import {
	Box,
	Icon,
	Button,
} from 'interceptd-ui';

import Settings from '../../services/settings';

import { copyTextToClipboard } from '../../utils/file';

import './styles/ImpressionPixels.css';

const ImpressionPixels = ({ source, creative_ids = [] }) => {
	const [open, setOpen] = React.useState(false);
	const [changed, setChanged] = React.useState(false);
	const [pixels, setPixels] = React.useState((Settings.get('pixels', 'account') || {})[source.id] || []);

	const handleAddPixel = ({ creative_id, editable = true }) => {
		setPixels(pixels => {
			const pixelId = pixels.length > 0 ? Math.max(...(pixels.map(p => +p.id))) + 1 : 1;
			return [
				...pixels,
				{
					id: pixelId,
					creative_id: creative_id || `Creative ${pixelId}`,
					ts_create: moment.utc().format('X'),
					editable,
				}
			]
		})
		setChanged(true);
	}

	React.useEffect(() => {
		creative_ids.forEach(creative_id => {
			if (creative_id === 'empty') return;
			if ( !pixels.some(p => p.creative_id === creative_id) ) {
				handleAddPixel({ creative_id, editable: false })
				setChanged(false);
			}
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [creative_ids]);

	const handleRemovePixel = id => {
		setPixels(pixels => pixels.filter(pixel => pixel.id !== id));
		setChanged(true);
	}

	const handleEditPixel = (event, id) => {
		const { value } = event.target;
		setPixels(pixels => (
			pixels.map(pixel => {
				return pixel.id === id ? ({
					...pixel,
					creative_id: value
				}) : pixel
			})
		));
		setChanged(true);
	}

	const handleCopyPixel = pixel => {
		copyTextToClipboard(`${source.impression_tracking_url}&creative_id=${encodeURIComponent(pixel.creative_id)}`);
		toast.success('Impression pixel tracking URL copied');
	}

	const handleCancel = () => {
		setPixels((Settings.get('pixels', 'account') || {})[source.id] || []);
		setChanged(false);
		setOpen(false);
	}

	const handleSave = () => {
		Settings.set('pixels', {
			...(Settings.get('pixels', 'account') || {}),
			[source.id]: pixels
		}, 'account');
		setChanged(false);
		setOpen(false);
		toast.success(`Impression pixels saved`);
	}
	
	return (
		<ClickOutside
			onClickOutside={open && !changed ? () => setOpen(false) : () => null}
			className={`pixels ${open ? 'open': ''}`}>
			<Button
				mini
				bgColor="transparent"
				className="add-new-campaign-button"
				onClick={() => open && !changed ? setOpen(false) : setOpen(true)}>
				<Icon i="settings" /> Impression Pixels
				<span className="pixels-count">{pixels.length}</span>
			</Button>
			{open &&
				<Box className="pixels-modal" title="Impression Pixels">
					<div className="pixels-list">
						{pixels.map((pixel, index) => (
							<div className="pixel" key={pixel.id}>
								<div className="pixel-name">
									<input
										disabled={!pixel.editable}
										autoFocus={index === pixels.length - 1}
										type="text"
										spellCheck={false}
										value={pixel.creative_id}
										onChange={e => handleEditPixel(e, pixel.id)} />
								</div>
								<div className="pixel-icon pixel-copy" onClick={e => handleCopyPixel(pixel)} tooltip-left="Copy Tracking URL">
									<Icon i="copy" size={16} />
								</div>
								<div className={`pixel-icon pixel-remove ${!pixel.editable ? 'is-disabled' : ''}`} onClick={e => handleRemovePixel(pixel.id)} tooltip-left={!pixel.editable ? `Can't Remove` : 'Remove Pixel'}>
									<Icon i="trash" size={16} />
								</div>
							</div>
						))}
						<div className="pixels-add" onClick={handleAddPixel}>
							<Icon i="plus" size={13} />
							<span>Add Pixel</span>
						</div>
					</div>
					<div className="pixels-footer">
						{changed &&
							<div onClick={handleCancel}>Cancel</div>
						}
						<Button onClick={handleSave}>Save</Button>
					</div>
				</Box>
			}
		</ClickOutside>
	)
}

export default ImpressionPixels;
