import React from 'react';
import RelativePortal from 'react-relative-portal';
import { Link } from 'react-router-dom';
import { Button } from 'interceptd-ui';

import './styles/Dropdown.css';

class Dropdown extends React.PureComponent {
	state = {
		open: false
	}

	timer = null

	setAsyncState = state => {
		clearTimeout(this.timer);
		this.timer = setTimeout(() => {
			this.setState(state);
		}, 0);
	}

	handleToggle = () => {
		this.setAsyncState(prevState => ({
			open: !prevState.open
		}))
	}

	handleOpen = () => {
		this.setAsyncState({
			open: true
		})
	}

	handleClose = () => {
		this.setAsyncState({
			open: false
		})
	}

	render() {
		const { className, portalProps, buttonText, buttonProps, children } = this.props;
		const { open } = this.state;

		return (
			<div className={`dropdown ${className || ''}`}>
				<div className={`dropdown-trigger ${open ? 'active' : ''}`} onClick={this.handleToggle}>
					<Button {...buttonProps}>{buttonText}</Button>
				</div>
				{open &&
					<RelativePortal {...portalProps} onOutClick={open ? this.handleClose : null}>
						<div className="dropdown-list">{children}</div>
					</RelativePortal>
				}
			</div>
		)
	}
}

Dropdown.Item = ({ className, ...rest }) => rest.to ? <Link className={`dropdown-item ${className}`} {...rest} /> : <div className={`dropdown-item ${className}`} {...rest} />;

Dropdown.defaultProps = {
  buttonProps: {
    bgColor: 'transparent',
  },
	portalProps: {
		left: undefined,
		right: 0
	}
}

export default Dropdown;
