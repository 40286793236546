import React from 'react';

import { AppIcon } from 'interceptd-ui';

import { shadowText } from '../../utils/misc';

import noLogo from '../../assets/icon-gray.svg';

export default ({
	isSource,
	type,
	getStats,
	renderCell,
	onExpandedChange,
}) => [
	{
		Header: 'expander',
		Expander: () => null,
		expander: true,
		show: false,
	},
	{
		Header: 'Name',
		id: 'name',
		accessor: 'id',
		cast: 'number',
		minWidth: 350,
		style: {
			overflow: 'visible',
		},
		sticky: true,
		stickyPosition: 'left',
		Cell: props => (
			<React.Fragment>
				{type === 'campaigns' && !isSource && (
					<div
						className={`rt-expander ${props.isExpanded ? '-open' : ''}`}
						onClick={() => onExpandedChange(props.viewIndex)}
					/>
				)}
				<span className="campaign-table-name" style={isSource ? { paddingLeft: 30 } : null}>
					{isSource && type === 'publishers' ? (
						<AppIcon
							app={props.original.app_info}
						/>
					) : !isSource ? (
						<AppIcon
							showStore={type !== 'publishers'}
							showTooltip={type !== 'publishers'}
							app={
								type === 'apps' ? 
									props.original : 
								type === 'publishers' ?
									({
										icon_url: props.original.logo || noLogo,
										title: props.original.name,
									}) :
									props.original.app_info
							}
						/>
					) : null}
					<span>
						{
							isSource && type === 'publishers' ?
								`${shadowText(props.original.name, 'Example Campaign')} - ${props.original.id} - ${props.original.type}` :
							isSource ?
								`${shadowText(props.original.name, 'Example Source')} - ${props.original.id}` :
							type === 'apps' ?
								`${shadowText(props.original.title, 'Example App')}` :
							type === 'publishers' ?
								`${shadowText(props.original.name, 'Example Publisher')}` :
								`${shadowText(props.original.name, 'Example Campaign')} - ${props.original.id} - ${props.original.type}`
						}
					</span>
				</span>
			</React.Fragment>
		)
	},
	{
		Header: 'Total Savings',
		id: 'total',
		accessor: props => getStats(props, 'total'),
		cast: 'number',
		width: 220,
		Cell: renderCell,
	},
	{
		Header: 'From Impression',
		id: 'impression',
		accessor: props => getStats(props, 'impression'),
		cast: 'number',
		width: 220,
		Cell: renderCell,
	},
	{
		Header: 'From Click',
		id: 'click',
		accessor: props => getStats(props, 'click'),
		cast: 'number',
		width: 220,
		Cell: renderCell,
	},
	{
		Header: 'From Install',
		id: 'install',
		accessor: props => getStats(props, 'install'),
		cast: 'number',
		width: 220,
		Cell: renderCell,
	},
	{
		Header: 'From Event',
		id: 'event',
		accessor: props => getStats(props, 'event'),
		cast: 'number',
		width: 220,
		Cell: renderCell,
	},
];