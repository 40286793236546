import React from 'react';
import { Chart } from "react-google-charts";

import './styles/Sankey.css';

class Sankey extends React.Component {
	sankey = React.createRef();
	observer = null

	componentDidMount = () => {
		const { onNodesChange } = this.props;

		if ( onNodesChange ) {
			this.observer = new MutationObserver(mutations => {
				mutations.forEach(mutation => {
					mutation.addedNodes.forEach(onNodesChange);
				});
			});
			this.observer.observe(this.sankey.current, {
				childList: true,
				subtree: true
			});
		}
	}

	componentWillUnmount = () => {
		this.observer && this.observer.disconnect();
	}

	render() {
		const { id, nodes, links, height, ...rest } = this.props;

		return (
			<div className="sankey" ref={this.sankey}>
				<Chart
					chartType="Sankey"
					width="100%"
					height={height}
					{...rest}
				/>
			</div>
		)
	}
}

Sankey.defaultProps = {
	height: 600,
}

export default Sankey;