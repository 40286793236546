import React from 'react';
import { Icon } from 'interceptd-ui';

import { shadowText } from '../../utils/misc';

export const columns = ({
	renderFraudGraphCell
}) => [
	{
		Header: '',
		id: 'sourceName',
		accessor: 'sourceName',
		cast: 'string',
		sortable: false,
		width: 300,
		style: {
			overflow: 'visible',
		},
		permanent: true,
		Cell: props => (
			<span className="campaign-table-name">
				<Icon i="corner-down-right" size={18} />
				<span>{shadowText(props.value, 'Example Source')}</span>
			</span>
		)
	},
	{
    Header: 'Impression',
    id: 'impression',
    accessor: 'impression',
    cast: 'string',
    width: 220,
    Cell: renderFraudGraphCell,
	},
	{
		Header: 'CTR',
		id: 'ctr',
		accessor: 'ctr',
		cast: 'string',
		width: 220,
	},
  {
    Header: 'Click',
    id: 'click',
    accessor: 'click',
    cast: 'string',
    width: 220,
    Cell: renderFraudGraphCell,
	},
	{
		Header: 'Conversion Rate',
		id: 'conversionRate',
		accessor: 'conversionRate',
		cast: 'string',
		width: 220,
	},
  {
    Header: 'Install',
    id: 'install',
    accessor: 'install',
    cast: 'string',
    width: 220,
    Cell: renderFraudGraphCell,
  },
  {
    Header: 'Event',
    id: 'event',
    accessor: 'event',
    cast: 'string',
    width: 220,
    Cell: renderFraudGraphCell,
  },
];