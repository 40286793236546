export default {

	categoryFraudRateLabels: [
		'Fraudulent',
		'Clean',
	],

	fraudTypesLabels: [
		'Click Spamming',
		'Click Injection',
		'SDK Spoofing',
		'Bot / Emulator',
		'Device Farms',
		'Incent Abuse	Other',
		'Other',
	],

	categories: {
		Beauty: {
			categoryFraudRate: [10, 90],
			fraudTypes: [21, 0, 13, 18, 24, 19, 5],
		},
		Business: {
			categoryFraudRate: [5, 95],
			fraudTypes: [17, 0, 12, 25, 24, 16, 6],
		},
		Comics: {
			categoryFraudRate: [4, 96],
			fraudTypes: [13, 0, 10, 21, 34, 20, 2],
		},
		Communication: {
			categoryFraudRate: [10, 90],
			fraudTypes: [23, 0, 9, 19, 28, 19, 2],
		},
		Dating: {
			categoryFraudRate: [11, 89],
			fraudTypes: [17, 0, 12, 24, 29, 16, 2],
		},
		Education: {
			categoryFraudRate: [7, 93],
			fraudTypes: [18, 0, 13, 26, 20, 17, 6],
		},
		Entertainment: {
			categoryFraudRate: [13, 87],
			fraudTypes: [19, 0, 15, 21, 19, 20, 6],
		},
		Finance: {
			categoryFraudRate: [29, 71],
			fraudTypes: [25, 0, 20, 11, 28, 14, 2],
		},
		'Food & Drink': {
			categoryFraudRate: [16, 84],
			fraudTypes: [20, 0, 14, 23, 21, 19, 3],
		},
		Games: {
			categoryFraudRate: [31, 69],
			fraudTypes: [20, 0, 14, 23, 21, 19, 3],
		},
		'Health & Fitness': {
			categoryFraudRate: [17, 83],
			fraudTypes: [21, 0, 18, 18, 19, 20, 4],
		},
		'House & Home': {
			categoryFraudRate: [4, 96],
			fraudTypes: [19, 0, 11, 25, 20, 19, 6],
		},
		Lifestyle: {
			categoryFraudRate: [21, 79],
			fraudTypes: [19, 0, 21, 14, 28, 15, 3],
		},
		'Maps & Navigation': {
			categoryFraudRate: [9, 91],
			fraudTypes: [22, 0, 9, 21, 29, 14, 5],
		},
		Photography: {
			categoryFraudRate: [9, 91],
			fraudTypes: [19, 0, 8, 21, 18, 19, 3],
		},
		Productivity: {
			categoryFraudRate: [19, 81],
			fraudTypes: [21, 0, 21, 19, 18, 18, 3],
		},
		Shopping: {
			categoryFraudRate: [32, 68],
			fraudTypes: [18, 0, 19, 23, 29, 9, 2],
		},
		Sports: {
			categoryFraudRate: [13, 87],
			fraudTypes: [19, 0, 15, 24, 21, 19, 2],
		},
		Social: {
			categoryFraudRate: [12, 88],
			fraudTypes: [18, 0, 17, 19, 14, 29, 3],
		},
		'Social Networking': {
			categoryFraudRate: [11, 89],
			fraudTypes: [15, 0, 11, 24, 17, 30, 3],
		},
		Travel: {
			categoryFraudRate: [23, 77],
			fraudTypes: [14, 0, 32, 15, 24, 12, 3,],
		}
	},

	gameSubCategories: [
		'Action',
		'Adventure',
		'Arcade',
		'Board',
		'Card',
		'Casino',
		'Casual',
		'Educational',
		'Music',
		'Puzzle',
		'Racing',
		'Role Playing',
		'Simulation',
		'Sports',
		'Strategy',
		'Trivia',
		'Word',
	]

};