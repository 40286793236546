import React from 'react';
import ReactDOM from 'react-dom';
import {
	Button,
	Checkbox,
} from 'interceptd-ui';

import './styles/FloatingBox.css';

class FloatingBox extends React.Component {
	render() {
		const {
			className,
			add_subpubs,
			remove_subpubs,
			onReject,
			rejectText,
			onEmailChange,
			sendEmail,
			isAccepting,
			onAccept,
			acceptText,
		} = this.props;

		return ReactDOM.createPortal(
			<div className={`floatingbox ${className || ''}`}>
				<div>
					<div className="blacklist-count">
						<span>{add_subpubs.length + remove_subpubs.length} subpublisher</span> selected
						<div className="blacklist-popup">
							{add_subpubs.length > 0 &&
								<React.Fragment>
									<input type="radio" id="block" name="page" defaultChecked />
									<label htmlFor="block">Block</label>
								</React.Fragment>
							}
							{remove_subpubs.length > 0 &&
								<React.Fragment>
									<input type="radio" id="unblock" name="page" defaultChecked={add_subpubs.length === 0} />
									<label htmlFor="unblock">Unblock</label>
								</React.Fragment>
							}
							<div className="blacklist-list">
								{add_subpubs.length > 0 &&
									<div className="add-list">
										{add_subpubs.map(i => (
											<span key={i.subpub_id}>{i.subpub_id}</span>
										))}
									</div>
								}
								{remove_subpubs.length > 0 &&
									<div className="remove-list">
										{remove_subpubs.map(i => (
											<span key={i.subpub_id}>{i.subpub_id}</span>
										))}
									</div>
								}
							</div>
						</div>
					</div>
					<Button
						bgColor="transparent"
						onClick={onReject}>
						{rejectText}
					</Button>
				</div>
				<div>
					<div className="blacklist-email">
						<Checkbox
							onChange={onEmailChange}
							checked={sendEmail} />
						<div>E-mail me optimization summary</div>
					</div>
					<Button
						loading={isAccepting}
						onClick={onAccept}>
						{acceptText}
					</Button>
				</div>
			</div>,
			document.getElementById('root'));
	}
};

FloatingBox.defaultProps = {
	acceptText: 'ACCEPT',
	rejectText: 'REJECT',
	isAccepting: false,
	isRejecting: false,
}

export default FloatingBox;
