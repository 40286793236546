import React from 'react';
import values from 'lodash/values';
import {
	Button,
	TagInput,
	Select,
	Input
} from 'interceptd-ui';

import Grouped from '../../components/Grouped';

import Currencies from '../../constants/currency.json';

import './styles/AppBox.css';

class AppBox extends React.Component {
	state = {
		settings: {
			click_lookback_window: '',
			install_lookback_window: '',
			target_app_version: '',
			target_sdk_version: '',
			target_os_version: '',
			revenue_currency: 'USD',
			kpis: [],
		}
	}

	partnerBox = React.createRef()

	componentDidMount = () => {
		this.setState(this.buildState);
	}

	buildState = () => {
		const { settings } = this.props;

		return ({
			settings: {
				click_lookback_window: settings.click_lookback_window || '',
				install_lookback_window: settings.install_lookback_window || '',
				target_app_version: settings.target_app_version || '',
				target_sdk_version: settings.target_sdk_version || '',
				target_os_version: settings.target_os_version || '',
				revenue_currency: settings.revenue_currency || 'USD',
				kpis: settings.kpis || [],
			}
		})
	}

	handleInput = event => {
		const { name, value } = event.target;
		this.setState(prevState => ({
			settings: {
				...prevState.settings,
				[name]: value,
			}
		}))
	}

	handleSelect = ( name, value ) => {
		this.setState(prevState => ({
			settings: {
				...prevState.settings,
				[name]: value,
			}
		}))
	}

	render() {
		const { app, saving, onSave, onCancel } = this.props;
		const { settings } = this.state;

		return (
			<div className="app-setting-detail" ref={this.partnerBox}>
				<div className="app-setting-detail-view app-setting-detail-inputs active">
					<Grouped>
						<Select
							label="Revenue Currency"
							options={values(Currencies).map(currency => ({
								value: currency.code,
								label: `${currency.symbol} - ${currency.name}`,
							}))}
							value={[settings.revenue_currency]}
							clearable={false}
							onChange={([val]) => this.handleSelect('revenue_currency', val)} />
						<Input
							label="Event Lookback Window for Installs in Days"
							placeholder="30"
							name="install_lookback_window"
							value={settings.install_lookback_window}
							onChange={this.handleInput} />
						<Input
							label="Event Lookback Window for Clicks in Days"
							placeholder="30"
							name="click_lookback_window"
							value={settings.click_lookback_window}
							onChange={this.handleInput} />
					</Grouped>
					<Grouped>
						<Input
							label="Min. App Version"
							placeholder="11.3.2"
							name="target_app_version"
							value={settings.target_app_version}
							onChange={this.handleInput} />
						<Input
							label="Min. OS Version"
							placeholder="12.2"
							name="target_os_version"
							value={settings.target_os_version}
							onChange={this.handleInput} />
						<Input
							label="Attribution SDK Version"
							placeholder="9.3.5"
							name="target_sdk_version"
							value={settings.target_sdk_version}
							onChange={this.handleInput} />
					</Grouped>
					<TagInput
						name="kpis"
						label="KPIs"
						placeholder="Enter the event name and press Enter"
						value={settings.kpis}
						onChange={val => this.handleSelect('kpis', val)}
						addOnBlur />
					<div className="app-setting-footer">
						<span className="app-setting-info-toggle" style={{
							marginRight: 'auto',
							padding: '0 5px',
							transform: 'none',
							color: 'var(--rulesetModalFooterTextColor)'
						}} onClick={onCancel}>Cancel</span>
						<Button
							mini
							loading={saving}
							onClick={() => { onSave(app, settings, true) }}>
							Save
						</Button>
					</div>
				</div>
			</div>
		)
	}
}

export default AppBox;
