import React from 'react';

import Switch from '../../components/Switch/BetterSwitch';

import icon from '../../assets/icon-gray.svg';

export const columns = (isEnabled, handleEnable) => [
	{
		Header: '',
		id: 'logo',
		accessor: 'logo',
		cast: 'string',
		width: 70,
		Cell: props => (
			<div className="partner-logo">
				<img src={props.value || icon} alt={`${props.original.name} Logo`} />
			</div>
		)
	},
	{
		Header: '',
		id: 'name',
		accessor: 'name',
		cast: 'string',
	},
	{
		Header: '',
		id: 'is_enabled',
		accessor: props => Boolean(isEnabled(props)),
		cast: 'actions',
		width: 70,
		Cell: props =>
			<Switch
				id={`partner-${props.original.id}`}
				checked={Boolean(isEnabled(props.original))}
				onChange={event => { handleEnable(event.target.checked, props) }} />
	},
];