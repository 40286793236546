import React from 'react';
import { Icon } from 'interceptd-ui';

import './styles/RadioGroup.css';

const RadioGroup = props => {
	const { multi, options, value, onChange } = props;

	return (
		<div className="radio-group">
			{options.map((option, index) => (
				<Item
					key={index}
					onClick={() => {
						if ( multi ) {
							if ( (value || []).indexOf(option.value) !== -1 ) {
								onChange((value || []).filter(v => v !== option.value))
							} else {
								onChange([...(value || []), option.value])
							}
						} else {
							onChange(option.value)
						}
					}}
					selected={multi ? (value || []).indexOf(option.value) !== -1 : option.value === value}
					{...option.props}>
					{option.icon ? (
						<Icon i={option.icon} size={option.size} />
					) : option.label}
				</Item>
			))}
		</div>
	)
}

const Item = props => {
	const { selected, children, ...rest } = props;

	return (
		<div
			className={`radio-group-item ${selected ? 'selected' : ''}`}
			{...rest}>
			{children}
		</div>
	)
}

export default RadioGroup;
