import React from 'react';
import {
	Button,
	Input,
	AppBox,
	AppPlaceholder
} from 'interceptd-ui';

import AppSearch from '../../components/AppSearch';

import history from '../../services/history';

import './styles/Step1.css';

import withTitle from '../withTitle';
import { isShadow, shadowText } from '../../utils/misc';

class Step1 extends React.Component {
	storeUrlInput = React.createRef()

	componentDidMount = () => {
		this.props.setSeenFirstStep();
		
		if ( this.storeUrlInput && this.storeUrlInput.current )
			this.storeUrlInput.current.focus();
	}

	render() {
		const {
			match,
			editing,

			form,
			app,
			isAppFetching,

			onInput,
			setAppData,
		} = this.props;

		return (
			<div className="campaign-create-inner">
				<React.Fragment>
					{app ? <AppBox app={app} isShadow={isShadow()} /> : <AppPlaceholder />}
					<AppSearch setAppData={setAppData} defaultText={app?.title || ''} />
				</React.Fragment>
				<Input
					label="Campaign Name"
					placeholder="Set a campaign name"
					name="name"
					value={shadowText(form.name, 'Example Campaign')}
					onChange={onInput}
					required />

				<Button
					bgColor="shade"
					onClick={() => history.push(`${match.url}/detail`)}
					disabled={
						(isAppFetching || !app) || 
						form.name === ''
					}>
					Start to {!editing ? 'Create' : 'Edit'}
				</Button>
			</div>
		)
	}
}

export default withTitle(Step1, null, null, match => {
	const editing = match.url.indexOf('/edit') !== -1;
	return `Interceptd - Campaign ${editing ? 'Edit' : 'Create'} ${editing ? `for ${match.params.id} - ` : ' - '} Start`
});
