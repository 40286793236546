import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import get from 'lodash/get';
import union from 'lodash/union';
import unionBy from 'lodash/unionBy';
import uniq from 'lodash/uniq';
import sortBy from 'lodash/sortBy';
import capitalize from 'lodash/capitalize';
import {
	Icon,
	PageTitle,
	Breadcrumbs,
	Button,
	TagInput,
	DatePicker,
	Input,
} from 'interceptd-ui';

import Grouped from '../../components/Grouped';
import CheckboxGroup from '../../components/Select/CheckboxGroup';
import RadioGroup from '../../components/Input/RadioGroup';

import Api from '../../services/api';
import Local from '../../services/localStorage';
import Settings from '../../services/settings';
import SendEvent from '../../services/events';

import { getTimestamp } from '../../utils/transform';
import { shadowText, renderAlarmName, getInitialDateFilter } from '../../utils/misc';

import withTitle from '../withTitle';

import { ReportTypes } from '../../constants/Campaign';

import './styles/ReportCreate.css';

class ReportCreate extends Component {
	state = {
		campaignsFetching: true,
		campaigns: [],
		sources: [],
		selectedSources: this.props?.location?.state?.selectedSources || [],
		archivedCampaigns: Settings.get('archivedCampaigns', 'account') || [],
		showArchivedCampaigns: false,

		alarms: unionBy(
			Local.getItem('supported_rules') || [],
			Local.getItem('supported_campaign_rules') || [],
			'short-name'
		),
		selectedAlarms: [],
		alarmFilter: '',

		campaignsExpanded: false,
		campaignsFilter: '',

		clickLookbackWindow: 30,
		installLookbackWindow: 30,

		redirect: false,
		requesting: false,
		reportType: this.props?.location?.state?.selectedReportType || 'impression',
		period: 'daily',
		filters: getInitialDateFilter(),
		email: Local.getItem('me').email,
		selectedEvents: [],

		report: {},
	}

	componentDidMount = () => {
		this.getCampaigns();
	}

	getCampaigns = () => {
		Api.getLeoparCampaigns()
			.then(response => {
				const sourceEventMap = {};
				const campaigns = get(response, 'data.data', []).filter((c) => c.application_type !== 'leancheck').filter(c => {
					if (c.sources.length === 0) return false;
					const payouts = c?.sources?.flatMap((s) => Object.keys(s?.event_payouts || {})) || [];
					const kpis = (c?.kpis || []).map(k => k.event_name);
					const allEvents = uniq([...kpis, ...payouts])

					if (allEvents.length > 0) {
					c.sources.forEach((s) => {
							sourceEventMap[s.id] = allEvents;
						});
					}

					return true;
				});

				this.setState({
					campaignsFetching: false,
					sourceEventMap,
					campaigns: sortBy(campaigns.map(c => ({
						...c,
						sources: sortBy(c.sources, 'id').reverse()
					})), 'id').reverse(),
				});
			}).catch(() => {
				this.setState({ campaignsFetching: false });
				toast.error(`Couldn't fetch campaigns.`);
			});
	}

	createOfflineReport = () => {
		const {
			reportType,
			selectedAlarms,
			selectedSources,
			filters,
			selectedEvents,
			clickLookbackWindow,
			installLookbackWindow,
		} = this.state;

		this.setState({ requesting: true });

		if (selectedSources.length === 0) {
			return;
		}

		const report = {
			source_id: selectedSources,
			ts_start: getTimestamp(filters.start),
			ts_end: getTimestamp(filters.end, true),
		};

		if (selectedEvents.length > 0) {
			report.filtered_events = selectedEvents;
		}

		if (reportType === 'summary') {
			report.install_lookback_window = +installLookbackWindow;
			report.click_lookback_window = +clickLookbackWindow;
			report.deductible_alarms = selectedAlarms;
		}

		Api.createLeoparOfflineReport(report, reportType)
			.then(() => {
				this.setState({
					redirect: true,
					requesting: false,
				});

				SendEvent({
					action: `${capitalize(reportType)} Report Requested.`,
					sendMixPanel: true,
				});
			})
			.catch((r) => {
				const message = get(r, 'response.data.error.message', `Couldn't create report`);
				this.setState({
					requesting: false,
				});
				toast.error(message);
			});
	}

	handleType = type => {
		this.setState({
			reportType: type,
			selectedSources: [],
			selectedEvents: [],
		})
	}

	handleDateRange = ({ from, to }) => {
		this.setState({
			filters: {
				start: from,
				end: to,
			},
		});
	}

	handleEvents = value => {
		this.setState({
			selectedEvents: value.map(v => v.trim())
		});
	}

	handleCampaignSelect = (values, checked) => {
		this.setState(prevState => ({
			selectedSources: checked ?
				(prevState.reportType === 'click' || prevState.reportType === 'impression') ?
					values.length === 1 ?
						prevState.selectedSources.length > 0 ?
							prevState.campaigns.find(c => c.sources.some(s => s.id === prevState.selectedSources[0])).sources.some(s => s.id === values[0]) ?
								union(prevState.selectedSources, values) :
								values :
							values :
						values :
					union(prevState.selectedSources, values) :
				prevState.selectedSources.filter(s => !values.some(v => v === s))
		}), this.setSelectedEvents)
	}

	setSelectedEvents = () => {
		const {
			selectedSources,
			reportType,
			sourceEventMap,
		} = this.state;

		if (reportType !== 'event' && reportType !== 'summary') return;
		let selectedEvents = [];

		selectedSources.forEach((s) => {
			const events = sourceEventMap[s];

			if (!events) return;

			selectedEvents = union(selectedEvents, events);
		});
		this.setState({ selectedEvents });
	}

	handleAlarmSelect = (value, checked) => {
		const val = Array.isArray(value) ? value : [value];

		this.setState((prevState) => {
			let { selectedAlarms } = prevState;
			if (checked) {
				selectedAlarms = union(selectedAlarms, val);
			} else {
				val.forEach(v => {
					const i = selectedAlarms.indexOf(v);
					selectedAlarms.splice(i, 1);
				});
			}

			return ({ selectedAlarms });
		});
	}

	handleCampaignExpand = state => {
		this.setState({
			campaignsExpanded: state
		})
	}

	handleCampaignFilter = event => {
		this.setState({
			campaignsFilter: event.target.value
		})
	}

	handleAlarmFilter = event => {
		this.setState({
			alarmFilter: event.target.value
		})
	}

	handleInputChange = ({ target }) => {
		const { name, value } = target;
		let newValue = value > -1 ? value : 0;
		
		if ((name === 'clickLookbackWindow' || name === 'installLookbackWindow')) {
			newValue = newValue <= 30 ? newValue : 30;
		}

		this.setState({ [name]: newValue });
	}

	render() {
		const { match } = this.props;
		const {
			alarms,
			selectedAlarms,
			alarmFilter,
			campaignsFetching,
			campaigns,
			selectedSources,
			campaignsExpanded,
			campaignsFilter,
			redirect,
			requesting,
			reportType,
			filters,
			selectedEvents,
			clickLookbackWindow,
			installLookbackWindow,
			showArchivedCampaigns,
			archivedCampaigns,
		} = this.state;

		if (redirect) {
			return <Redirect push to={`/reports${(reportType !== 'click' && reportType !== 'impression') ? `/${reportType}` : ''}`} />
		}

		const campaignOptions = showArchivedCampaigns
			? campaigns
			: campaigns.filter(c => archivedCampaigns.indexOf(c.id) === -1);

		return (
			<div className="campaign-detail campaign-reports">
				<PageTitle>
					<Breadcrumbs>
						<Breadcrumbs.Crumb><Link to="/reports">Reports</Link></Breadcrumbs.Crumb>
						<Breadcrumbs.Crumb><Link to={match.url}>Create Report</Link></Breadcrumbs.Crumb>
					</Breadcrumbs>
					<PageTitle.Title>Create Report</PageTitle.Title>
				</PageTitle>

				<div className="report-create-container">
					<span className="report-create-title">Request a Report</span>
					<span className="report-create-sub-title">You can create a report for your campaigns</span>
					<div className="report-create-form">
						<RadioGroup
							options={ReportTypes}
							value={reportType}
							onChange={this.handleType} />

						<DatePicker
							label="Date Range"
							value={{
								from: filters.start,
								to: filters.end,
							}}
							onChange={this.handleDateRange}
							datePickerProps={{
								numberOfMonths: 1
							}} />

						{reportType === 'summary' && <Grouped>
							<Input
								label="Click Lookback Window (Days)"
								value={clickLookbackWindow}
								name="clickLookbackWindow"
								type="number"
								min="0"
								onChange={this.handleInputChange}
							/>
							<Input
								label="Install Lookback Window (Days)"
								value={installLookbackWindow}
								name="installLookbackWindow"
								type="number"
								min="0"
								onChange={this.handleInputChange}
							/>
						</Grouped>}

						<CheckboxGroup
							loading={campaignsFetching}
							view={(reportType !== 'click' && reportType !== 'impression') ? 'checkbox' : 'radio'}
							renderTools={() => (
								<div className={`checkbox-group-expander action-button ${showArchivedCampaigns ? 'active' : ''}`} tooltip-left={`${showArchivedCampaigns ? 'Hide' : 'Show'} archived campaigns.`}>
									<Icon i="archive" size={14} onClick={() => this.setState({ showArchivedCampaigns: !showArchivedCampaigns, selectedSources: [] })} />
								</div>
							)}
							showSelectAll={(reportType !== 'click' && reportType !== 'impression')}
							label="Select sources you want to include to the report"
							options={campaignOptions.map((c) => {
								c.name = shadowText(c.name, 'Example Campaign');
								return c;
							})}
							value={selectedSources}
							parentName="Campaign"
							childName="Source"
							parentLabelSelector="name"
							childOptionsSelector="sources"
							childLabelSelector="name"
							renderLabel={option => `${option.id} - ${option.name}`}
							renderChildLabel={option => `${option.id} - ${option.name}`}
							onChange={this.handleCampaignSelect}
							expanded={campaignsExpanded}
							onExpandedChange={this.handleCampaignExpand}
							renderExpanderTooltip={(expanded) => expanded ? 'Collapse Sources' : 'Expand Sources'}
							filterable
							filter={campaignsFilter}
							onFilterChange={this.handleCampaignFilter}
							filterFunction={(option, index) => {
								const filterText = campaignsFilter.trim().toLowerCase();
								return (
									`${option.id} - ${option.name}`.trim().toLowerCase().indexOf(filterText) !== -1 ||
									option.sources.some(s => (
										`${s.id} - ${s.name}`.trim().toLowerCase().indexOf(filterText) !== -1
									))
								)
							}} />

						{reportType === 'summary' && (
							<CheckboxGroup
								showSelectAll={(reportType !== 'click' && reportType !== 'impression')}
								parentName="Alarm"
								label="Select alarms you want to include to the report"
								options={alarms.map((a) => {
									a.name = renderAlarmName(a.name);
									return a;
								})}
								value={selectedAlarms}
								parentLabelSelector="name"
								parentValueSelector="short-name"
								onChange={this.handleAlarmSelect}
								filterable
								filter={alarmFilter}
								onFilterChange={this.handleAlarmFilter}
								filterFunction={(option, index) => {
									const filterText = alarmFilter.trim().toLowerCase();
									return (`${option.name}`.trim().toLowerCase().indexOf(filterText) !== -1)
								}} />
						)}

						{(reportType === 'event' || reportType === 'summary') && (
							<div className="selected-events-content">
								<TagInput
									name="selectedEvents"
									label="Events"
									placeholder="Enter the event name you want to include in the report and press Enter"
									value={selectedEvents}
									onChange={this.handleEvents} />
							</div>
						)}

						<div className="report-create-form-button">
							<Button disabled={selectedSources.length === 0 || (reportType === 'summary' && selectedAlarms.length === 0)} onClick={this.createOfflineReport} loading={requesting}>Request the Report</Button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withTitle(ReportCreate, 'Report Create');
