import React from 'react';
import { toast } from 'react-toastify';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import { Icon, Button } from 'interceptd-ui';

import Rule from '../../pages/Auth/ManageRulesets/components/Rule';
// import Rule from './Rule';
import Api from '../../services/api';
import Local from '../../services/localStorage';
import Rulesets from '../../services/rulesets';

import { RuleInputs } from '../../constants/Rules';

class RuleSetModal extends React.Component {
	state = {
		saving: false,
		rulesets: Local.getItem('campaignRulesets') || [],
		supported_rules: Local.getItem('supported_campaign_rules') || [],
		multiplied_rules: [],
		editingRuleset: null,
		newRuleset: {
			name: '',
			detail: {
				rules: []
			}
		},
		isTouched: false,
	}

	scrollElem = React.createRef()

	componentDidMount = () => {
		const { ruleset } = this.props;

		if ( ruleset )
			this.handleEditRule(ruleset);

		this.rulesetListener = Local.listen('campaignRulesets', () => {
			this.setState({
				rulesets: Local.getItem('campaignRulesets') || [],
			})
		})

		this.supportedRulesListener = Local.listen('supported_campaign_rules', () => {
			this.setState({
				supported_rules: Local.getItem('supported_campaign_rules') || [],
			})
		})
	}

	componentWillUnmount = () => {
		if ( this.rulesetListener )
			Local.unlisten(this.rulesetListener)

		if ( this.supportedRulesListener )
			Local.unlisten(this.supportedRulesListener)
	}

	handleClose = () => {
		this.props.onClose && this.props.onClose();
	}

	handleEditRule = set => {
		const { supported_rules } = this.state;

		this.setState({
			multiplied_rules: set.detail.rules.filter(rule => rule.name.indexOf('EI_') !== -1).map((rule, index) => ({
				...(find(supported_rules, ['short-name', 'EI'])),
				'short-name': rule.name,
			})),
			editingRuleset: set,
			newRuleset: {
				name: set.name,
				detail: {
					rules: set.detail.rules.map(rule => {
						const name = rule.name.indexOf('EI_') === 0 ? 'EI' : rule.name;
						const supRule = find(supported_rules, ['short-name', name]);
						return ({
							...rule,
							user_inputs: rule.user_inputs ? 
								rule.user_inputs : 
								(supRule && supRule.detail && supRule.detail.user_inputs) ? 
									Object.keys(supRule.detail.user_inputs).reduce((acc, cur) => ({ ...acc, [cur]: supRule.detail.user_inputs[cur].default }), {}) :
									undefined
						})
					})
				}
			},
		})
	}

	handleActivateIfNot = (rule, cb) => {
		const { newRuleset } = this.state;

		if ( findIndex(newRuleset.detail.rules, ['name', rule['short-name']]) === -1 ) {
			this.handleActivateRule(true, rule, cb);
			return true;
		}
		cb();
		return true
	}

	handleActivateRule = (on, rule, cb) => {
		const { newRuleset } = this.state;
		const rules = newRuleset.detail.rules;
		const multi = rule['short-name'].indexOf('EI_') === 0;

		if ( on ) {
			let newRule = {
				name: rule['short-name'],
				block: false,
				alarm: true,
				notify: [],
			}

			const shortName = multi ? 'EI' : rule['short-name'];

			if ( rule.detail && rule.detail.user_inputs ) {
				newRule.user_inputs = {};
				Object.keys(rule.detail.user_inputs).forEach(input => (
					newRule.user_inputs[input] = rule.detail.user_inputs[input].type === 'combobox' ? (RuleInputs[shortName] && RuleInputs[shortName][input] && (rule.detail.user_inputs[input].default || RuleInputs[shortName][input].default)) || rule.detail.user_inputs[input].values[0] : (RuleInputs[shortName] && RuleInputs[shortName][input] && (rule.detail.user_inputs[input].default || RuleInputs[shortName][input].default)) || ''
				));
			}

			let exists = false;
			rules.forEach((r, i) => {
				if (r.name === newRule.name) {
					exists = true;
					rules[i] = {
						...r,
						...newRule,
					};
				}
			});

			if ((multi && !exists) || (!multi && !exists)) {
				rules.push(newRule);
			}

			this.setState({
				newRuleset: {
					name: newRuleset.name,
					detail: {
						rules,
					}
				},
			 }, cb);
		} else {
			const removed = [...newRuleset.detail.rules]
			const index = findIndex(removed, ['name', rule['short-name']]);

			removed.splice(index, 1);

			this.setState({
				newRuleset: {
					name: newRuleset.name,
					detail: {
						rules: removed
					}
				}
			}, cb)
		}
	}

	handleValueChange = (event, rule) => {
		const { newRuleset } = this.state;
		const rules = [...newRuleset.detail.rules];
		const { name, value } = event.target;
		rules[findIndex(rules, ['name', rule['short-name']])].user_inputs[name] = value;

		this.setState({
			newRuleset: {
				name: newRuleset.name,
				detail: {
					rules,
				}
			}
		})
	}

	handleValueBlur = (event, rule) => {
		const { newRuleset } = this.state;
		const rules = [...newRuleset.detail.rules];
		const { name, value, min, max, type } = event.target;

		if ( type !== 'number' ) return true;

		const val = value.replace(/[^0-9.]+/g, '');
		rules[findIndex(rules, ['name', rule['short-name']])].user_inputs[name] = (min && +val < +min) ? min : (max && +val > +max) ? max : val;

		this.setState({
			newRuleset: {
				name: newRuleset.name,
				detail: {
					rules,
				}
			}
		})
	}

	handleBlockToggle = (rule) => {
		this.handleActivateIfNot(rule, () => {
			const { newRuleset } = this.state;
			const rules = [...newRuleset.detail.rules];

			const index = findIndex(rules, ['name', rule['short-name']]);
			rules[index].block = !rules[index].block;

			this.setState({
				newRuleset: {
					name: newRuleset.name,
					detail: {
						rules,
					}
				}
			})
		});
	}

	handleSlackToggle = (event, rule) => {
		this.handleActivateIfNot(rule, () => {
			const { newRuleset } = this.state;
			const rules = [...newRuleset.detail.rules];
			const index = findIndex(rules, ['name', rule['short-name']]);
			const notify = rules[index].notify;

			if ( notify.indexOf('slack') >= 0 ) {
				notify.splice(notify.indexOf('slack'), 1);
			} else {
				notify.push('slack');
			}

			rules[index].alarm = (rules[index].block || (notify && notify.length > 0));

			this.setState({
				newRuleset: {
					name: newRuleset.name,
					detail: {
						rules,
					}
				}
			})
		})
	}

	handleMailToggle = (event, rule) => {
		this.handleActivateIfNot(rule, () => {
			const { newRuleset } = this.state;
			const rules = [...newRuleset.detail.rules];
			const index = findIndex(rules, ['name', rule['short-name']]);
			const notify = rules[index].notify;

			if ( notify.indexOf('email') >= 0 ) {
				notify.splice(notify.indexOf('email'), 1);
			} else {
				notify.push('email');
			}

			rules[index].alarm = (rules[index].block || (notify && notify.length > 0));

			this.setState({
				newRuleset: {
					name: newRuleset.name,
					detail: {
						rules,
					}
				}
			})
		})
	}

	handleAddAnotherRule = (event, rule) => {
		const { multiplied_rules } = this.state;
		const newMultipliedRules = [...multiplied_rules];

		const lastIndex = newMultipliedRules.length > 0 ? newMultipliedRules[newMultipliedRules.length - 1]['short-name'].replace('EI_', '') : '0';
		const lastAdded = newMultipliedRules.push({
			...rule,
			'short-name': `EI_${+lastIndex + 1}`,
			allow_multiple: false,
		})

		this.setState({
			multiplied_rules: newMultipliedRules,
		}, () => {
			this.handleActivateRule(true, this.state.multiplied_rules[lastAdded - 1], () => {
				this.scrollElem.current.scrollTop = this.scrollElem.current.scrollHeight;
			})
		})
	}

	handleRemoveMultipliedRule = (event, rule) => {
		const { multiplied_rules } = this.state;

		this.setState({
			multiplied_rules: multiplied_rules.filter(r => r['short-name'] !== rule['short-name']),
		}, () => {
			this.handleActivateRule(false, rule)
		})
	}

	handleSaveRuleset = () => {
		const { editingRuleset, newRuleset } = this.state;
		const { onReload } = this.props;

		newRuleset.detail.rules.map((r) => {
			if (!r.user_inputs) return r;

			const rule = r;

			Object.keys(r.user_inputs).forEach((key) => {
				if (key !== 'comparison' && key !== 'event_type') {
					rule.user_inputs[key] = (isNaN(r.user_inputs[key]) || r.user_inputs[key] === '')
						? r.user_inputs[key]
						: +r.user_inputs[key];
				}
			});

			return rule;
		});

		this.setState({ saving: true });
		Api.updateCampaignRuleset({
			...newRuleset,
			id: editingRuleset.id,
		})
			.then(response => {
				toast.success('Rules updated.');
				this.setState({
					saving: false,
					isTouched: false,
				});
				Rulesets.getRulesets();
				onReload && onReload();
			})
			.catch(({ response }) => {
				this.setState({ saving: false });
				toast.error(response.data.data.error.code);
			})
	}

	render() {
		const { supported_rules, multiplied_rules, newRuleset, saving, isTouched } = this.state;
		const { alarms, hideSaveButton } = this.props;

		return (
			<div className={`ruleset-modal creating editing campaign-rules`}>
				<div className="ruleset-modal-inner"></div>
				<div className="ruleset-create">
					<div className="ruleset-modal-header">
						<div className="ruleset-modal-title">Campaign Rules</div>
					</div>

					<div className="ruleset-create-rules">
						<div className="infobox">
							<Icon i="info" size={20} />
							Enable alarms to manage your campaign.
						</div>
						<div className="rule-options" ref={this.scrollElem}>
							{supported_rules.map((rule, key) => (
								<Rule
									key={key}
									rule={rule}
									values={find(newRuleset.detail.rules, ['name', rule['short-name']])}

									handleActivateRule={this.handleActivateRule}
									handleValueChange={this.handleValueChange}
									handleValueBlur={this.handleValueBlur}
									hasAlarm={alarms.filter(alarm => alarm.alarm_type === rule['short-name']).length !== 0}
									onValueChange={this.handleValueChange}
									handleBlock={this.handleBlockToggle}
									onSlackChange={this.handleSlackToggle}
									onMailChange={this.handleMailToggle}
									onAddAnother={this.handleAddAnotherRule} />
							))}
							{multiplied_rules.map((rule, key) => (
								<Rule
									key={key}
									rule={rule}
									ruleIndex={rule['short-name'].replace('EI_', '')}
									multiplied
									values={find(newRuleset.detail.rules, ['name', rule['short-name']])}
									hasAlarm={alarms.filter(alarm => alarm.alarm_type === rule['short-name']).length !== 0}
									handleActivateRule={this.handleActivateRule}
									handleValueChange={this.handleValueChange}
									handleValueBlur={this.handleValueBlur}
									onBlockChange={this.handleBlockToggle}
									onSlackChange={this.handleSlackToggle}
									onMailChange={this.handleMailToggle}
									onAddAnother={this.handleAddAnotherRule}
									onRemoveMultiplied={this.handleRemoveMultipliedRule} />
							))}
						</div>
						<div className="ruleset-footer">
							<div className="ruleset-footer-left">
								<div onClick={this.handleClose}>Cancel</div>
							</div>
							{(!hideSaveButton || (hideSaveButton && isTouched)) &&
								<div className="ruleset-footer-right">
									<Button onClick={this.handleSaveRuleset} loading={saving}>Save</Button>
								</div>
							}
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default RuleSetModal;
