import React from 'react';

const PrivacyPolicy = props => {
	return (
		<div className="privacy-policy">
			<h1>Privacy Policy</h1>
			<p>Thank you for using App Samurai. This policy explains the what, how, and why of the information we collect when you use our Services. It also explains the specific ways we use and disclose that information. We take your privacy extremely seriously, and we never sell or share the info of you.</p>
			<h2>THE BASICS</h2>
			<h3>Definitions</h3>
			<p>These definitions should help you understand this policy. When we say "we", "us", "our" and "App Samurai" we are referring to "APP SAMURAI Inc.". We are mobile advertising platform which allows you to start install oriented campaigns like boost, user acquisition and video campaign (the "Service"). When we say "Customer", we are referring to the person or entity that is registered with us to use the Services. When we say "you," we are referring either to a Member or to some other person who visits App Samurai dashboard.</p>
			<p>We offer the Services on www.appsamurai.com. This Privacy Policy applies to this specific website, owned or operated by us ("Website"). The "Website" can include interactive features, applications, widgets blogs, social networks, social network "tabs", or other online, mobile, or wireless offerings that post a link to this Privacy Policy, whether accessed via computer, mobile device, or other technology, manner or means. While providing the Services, and as described in more detail below, we may collect Personal information about a Website visitor, Member, person or email address.</p>
			<h3>Changes</h3>
			<p>We may change this Privacy Policy at any time and from time to time. The most recent version of the Privacy Policy is reflected by the version date located at the bottom of this Privacy Policy. All updates and amendments are effective immediately upon notice, which we may give by any means, including, but not limited to, by posting a revised version of this Privacy Policy or other notice on the Website. We encourage you to review this Privacy Policy often to stay informed of changes that may affect you, as your continued use of the Website signifies your continuing consent to be bound by this Privacy Policy. Our electronically or otherwise properly stored copies of this Privacy Policy are each deemed to be the true, complete, valid, authentic, and enforceable copy of the version of this Privacy Policy which were in effect on each respective date you visited the Website.</p>
			<h3>Scope</h3>
			<p>This Privacy Policy is effective with respect to any data that we have collected, or collect, about and/or from you, according to our Terms of Use.</p>
			<h3>Questions & Concerns</h3>
			<p>If you have any questions or comments, or if you want to update, delete, or change any Personal Information we hold, or you have a concern about the way in which we have handled any privacy matter, please use our contact form to send us a message. You may also contact us by email at:</p>
			<q>App Samurai<br /> hello@appsamurai.com<br /> 28 2nd Street, 3rd Floor #3216 San Francisco, 94105, California, USA</q>
			<h2>YOUR INFORMATION</h2>
			<h3>Information We Collect</h3>
			<p>When you sign up for and use the Services, the information we collect may include only mail address. If you want to create a campaign, you have to use your credit card. This payment model are preserved by our 3rd party business related to payment issues.</p>
			<p>We may receive information about how and when you use the Services, store it in log files or other types of files associated with your account, and link it to other information we collect about you. This information may include, for example, your IP address, time, date, browser used, and actions you have taken within the application. This type of information helps us to improve our Services for both you and for all of our users.</p>
			<p>We may receive more information about you such as name, email address, demographic information, IP addresses, location, and use of social media websites, by searching the Internet or querying third parties.</p>
			<h3>Use and Disclosure of Personal Information</h3>
			<p>We may use and disclose Personal Information only for the following purposes:</p>
			<p>To promote use of our services to you. For example, if you leave your email address when you visit our Website and do not visit again for any of the Services, we may send you an email inviting you to visit again. If you use any of our Services and we think you might benefit from using another Service we offer, we may send you an email about that. You can stop receiving our promotional emails by following the unsubscribe instructions included in every email we send. In addition, we may use information we collect in order to advertise our Services to you or suggest additional features of our Services that you might consider using.</p>
			<p>To communicate with our Customers about their account and provide customer support.</p>
			<p>To enforce compliance with our Terms of Use and applicable law.</p>
			<p>To protect the rights and safety of our Customers and third parties, as well as our own.</p>
			<h3>Public Information and Third Party Websites</h3>
			<p>(a) Blog. We have public blogs on our Websites. Any information you include in a comment on our blog may be read, collected, and used by anyone. If your mail address appears on our blogs and you want it removed, contact us here. If we are unable to remove your information, we will tell you why.</p>
			<p>(b) Social media platforms and widgets. Our Websites include social media features, such as the Facebook Share button. These features may collect information about your IP address and which page you are visiting on our Website, and they may set a cookie to make sure the feature functions properly. Social media features and widgets are either hosted by a third party or hosted directly on our Website. We also maintain presences on social media platforms including Facebook, Twitter, and Instagram. Any information, communications, or materials you submit to us via a social media platform is done at your own risk without any expectation of privacy. We cannot control the actions of other users of these platforms or the actions of the platforms themselves. Your interactions with those features and platforms are governed by the privacy policies of the companies that provide them.</p>
			<p>(c) Links to third-party websites. Our Websites include links to other websites, whose privacy practices may be different from App Samurai's. If you submit mail address to any of those sites, your information is governed by their privacy policies. We encourage you to carefully read the privacy policy of any Website you visit.</p>
			<h3>Contest and Sweepstakes</h3>
			<p>We may, from time to time, offer surveys, contests, sweepstakes, or other promotions on our Websites or through social media (collectively "Our Promotions"). Participation in our Promotions is completely voluntary. Information requested for entry may include personal contact information such as your name, address, date of birth, phone number, email address, username, and similar details. We use the information you provide to administer Our Promotions. We may also, unless prohibited by the Promotion's rules or law, use the information provided to communicate with you, or other people you select, about our Services.</p>
			<h2>SECURITY</h2>
			<h3>Safeguarding Your Information</h3>
			<p>We take reasonable and appropriate measures to protect Personal Information from loss, misuse and unauthorized access, disclosure, alteration and destruction, taking into account the risks involved in the processing and the nature of the Personal Information.</p>
			<p>Our credit card processing vendor uses security measures to protect your information both during the transaction and after it is complete. Our vendor is certified as compliant with card association security initiatives. If you have any questions about the security of your Personal Information, you may contact us at hello@appsamurai.com.</p>
			<p>App Samurai accounts require mail address and password to log in. You must keep your username and password secure. We cannot resend forgotten passwords either. We will only reset them.</p>
			<h2>COMPLIANCE</h2>
			<h3>We operate in the United States</h3>
			<p>Our servers are located in Europe, so your information may be transferred to, stored, or processed in the Europe. While the data protection, privacy, and other laws of the EU might not be as comprehensive as those in your country, we take many steps to protect your privacy. By using our Websites, you understand and consent to the collection, storage, processing, and transfer of your information to our facilities in the EU and those third parties with whom we share it as described in this policy.</p>
			<h3>Accuracy of Data, Transparency, and Choice</h3>
			<p>We do our best to keep your data accurate and up to date, to the extent that you provide us with the information we need to do so. If your data changes (for example, if you have a new email address), then you are responsible for notifying us of those changes. Upon request, we will provide you with information about whether we hold, or process on behalf of a third party, any of your Personal Information.</p>
			<p>We will retain your information for as long as your account is active or as long as needed to provide you with our Services. We may also retain and use your information in order to comply with our legal obligations, resolve disputes, prevent abuse, and enforce our Agreements.</p>
			<h3>Access</h3>
			<p>We will give an individual access to any Personal Information we hold about them within 30 days of any request for that information. Individuals may request to access, correct, amend or delete information we hold about them by contacting us here. Unless it is prohibited by law, we will remove any Personal Information about an individual from our servers at your or their request. There is no charge for an individual to access or update their Personal Information.</p>
			<p>Thanks for taking the time to learn about APP SAMURAI Inc. Privacy Policy, and thanks for trusting us in terms of creating ad campaigns on our dashboard.</p>
			<p>Update February 25, 2017.</p>
		</div>
	)
}

export default PrivacyPolicy;
