import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Humanize from 'humanize-plus';
import {
	Box,
	NoData,
	FraudGraph,
} from 'interceptd-ui';

import Table from '../Table';

import { columns as CreativeColumns } from './CreativeColumns';
import { columns as CreativeSourceColumns } from './CreativeSourceColumns';

import Api from '../../services/api';

import { getTimestamp } from '../../utils/transform';

import { EmptyStatsObjects } from '../../constants/Campaign';

export default function CreativePerformanceColumns({ filters, sources, ...resProps }) {
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const [resizedState, setResizedState] = useState([]);
	const [expandedState, setExpandedState] = useState({});

	useEffect(() => {
		const getCreativePerformance = async () => {
			try {
				setLoading(true)
				const query = {
					source_ids: sources.map(s => s.id),
					ts_start: getTimestamp(filters.start),
					ts_end: getTimestamp(filters.end, true),
				};
	
				const creativeStatsPerformanceResponse = await Api.getCreativeStats(query);
				const creativePerformanceResponse = await Api.getCreativePerformance(query);
				const statsData = creativeStatsPerformanceResponse?.data?.data || [];
				const newData = creativePerformanceResponse?.data?.data || [];
				const creativePerformance = {};

				newData.forEach((d) => {
					const id = d.creative_id;
					const sourceIndex = sources.findIndex(s => s.id === d.source_id);
					const stats = statsData.find(s => s.source_id === d.source_id && s.creative_id === id);
	
					const sourceName = sourceIndex !== -1
						? sources[sourceIndex].name
						: d.source_id;

					creativePerformance[id] = creativePerformance[id] || {
						sources: [],
						creative_id: id,
						click: {
							clean: 0,
							rejected: 0,
							flagged: 0,
							total: 0,
						},
						install: {
							clean: 0,
							rejected: 0,
							flagged: 0,
							total: 0,
						},
						event: {
							clean: 0,
							rejected: 0,
							flagged: 0,
							unique: 0,
							total: 0,
						},
						impression: {
							clean: 0,
							flagged: 0,
							rejected: 0,
							total: 0,
						},
					};
	
					const cleanClickCount = d.click_count || 0;
					const flaggedClickCount = d.flagged_click_count || 0;
					const rejectedClickCount = d.rejected_click_count || 0;
					const totalClick = (cleanClickCount + flaggedClickCount + rejectedClickCount);
	
					const cleanInstallCount = stats?.clean_install_count || 0;
					const flaggedInstallCount = stats?.flagged_install_count || 0;
					const rejectedInstallCount = stats?.rejected_install_count || 0;
					const totalInstall = (cleanInstallCount + flaggedInstallCount + rejectedInstallCount);

					const cleanEventCount = stats?.clean_event_count || 0;
					const flaggedEventCount = stats?.flagged_event_count || 0;
					const rejectedEventCount = stats?.rejected_event_count || 0;
					const uniqueEventCount = stats?.unique_event_count || 0;
					const totalEvent = (cleanEventCount + flaggedEventCount + rejectedEventCount);
	
					const cleanImpressionCount = d.impression_count || 0;
					const flaggedImpressionCount = d.flagged_impression_count || 0;
					const rejectedImpressionCount = d.rejected_impression_count || 0;
					const totalImpression = (cleanImpressionCount + flaggedImpressionCount + rejectedImpressionCount);
	
					creativePerformance[id].click.clean += cleanClickCount;
					creativePerformance[id].click.rejected += rejectedClickCount;
					creativePerformance[id].click.flagged += flaggedClickCount;
					creativePerformance[id].click.total += totalClick;
	
					creativePerformance[id].install.clean += cleanInstallCount;
					creativePerformance[id].install.flagged += flaggedInstallCount;
					creativePerformance[id].install.rejected += rejectedInstallCount;
					creativePerformance[id].install.total += totalInstall;
	
					creativePerformance[id].event.clean += cleanEventCount;
					creativePerformance[id].event.flagged += flaggedEventCount;
					creativePerformance[id].event.rejected += rejectedEventCount;
					creativePerformance[id].event.unique += uniqueEventCount;
					creativePerformance[id].event.total += totalEvent;
	
					creativePerformance[id].impression.clean += cleanImpressionCount;
					creativePerformance[id].impression.flagged += flaggedImpressionCount;
					creativePerformance[id].impression.rejected += rejectedImpressionCount;
					creativePerformance[id].impression.total += totalImpression;
	
					const ctr = creativePerformance[id].click.total * 100 / (creativePerformance[id].impression.total || 1);
					creativePerformance[id].ctr = Number.isNaN(ctr)
						? '-'
						: `${Humanize.intComma(ctr, 2)}%`;
	
					const conversionRatePercent = (creativePerformance[id].install.total / (creativePerformance[id].click.total || 1)) * 100;
					const cleanConversionRatePercent = (creativePerformance[id].install.total / (creativePerformance[id].click.clean || 1)) * 100;
	
					creativePerformance[id].conversionRate = getCrTemplate({
						total: conversionRatePercent,
						clean: cleanConversionRatePercent,
					});
	
					if (sources.length > 0) {
						const sourceCtr = totalClick * 100 / (totalImpression || 1);
						const sourceConversionRatePercent = (totalInstall / (totalClick || 1)) * 100;
						const sourceCleanConversionRatePercent = (totalInstall / (d.click_count || 1)) * 100;
	
						const sourceConversionRate = getCrTemplate({
							total: sourceConversionRatePercent,
							clean: sourceCleanConversionRatePercent,
						});
	
						creativePerformance[id].sources.push({
							sourceName,
							source_id: d.source_id,
							ctr: Number.isNaN(sourceCtr) ? '-' : `${Humanize.intComma(sourceCtr, 2)}%`,
							conversionRate: sourceConversionRate,
							click: {
								clean: cleanClickCount,
								rejected: rejectedClickCount,
								flagged: flaggedClickCount,
								total: totalClick,
							},
							install: {
								clean: cleanInstallCount,
								rejected: rejectedInstallCount,
								flagged: flaggedInstallCount,
								total: totalInstall,
							},
							event: {
								clean: cleanEventCount,
								rejected: rejectedEventCount,
								flagged: flaggedEventCount,
								total: totalEvent,
							},
							impression: {
								clean: cleanImpressionCount,
								flagged: flaggedImpressionCount,
								rejected: rejectedImpressionCount,
								total: totalImpression,
							},
						});
					}
				});

				setLoading(false);
				setData(Object.values(creativePerformance));
			} catch (err) {
				console.error(err);
				setLoading(false);
			}
		}
		getCreativePerformance();
	}, [filters, sources]);

	const getCrTemplate = ({ total, clean }) => (
		<div style={{ display: 'flex', flexDirection: 'column' }}>
		<span><b style={{ fontWeight: 'bold ' }}>Total:</b>{` ${Humanize.intComma(total)}%`}</span>
		<span><b style={{ fontWeight: 'bold ', color: 'var(--green)' }}>Clean:</b>{` ${Humanize.intComma(clean)}%`}</span>
	</div>
	)

	const onExpandedChange = (index) => setExpandedState(prevState => ({
		[index]: !prevState[index],
	}));

	const renderFraudGraphCell = ({ column, value }) => {
		const fieldData = value || EmptyStatsObjects.event;
		return (
			<div className="campaign-table-fraud-bar-container">
				<FraudGraph
					data={fieldData}
					showUnique={column.id === 'event'}
					title={column.Header}
					loading={loading} />
			</div>
		);
	}

	const renderCreativeSources = ({ original }) => {
		const data = original?.sources;

		if (!data || data.length === 0) {
			return null;
		}

		return (
			<div className="sources">
				<Table
					data={data}
					columns={
						CreativeSourceColumns({
							renderFraudGraphCell
						})
					}
					PadRowComponent={() => null}
					resized={resizedState}
					sortable={false}
					pageSize={1000}
					showPageSizeOptions={false}
					showPageJump={false}
					showPagination={false}
				/>
			</div>
		)
	}

	return (data.length === 0 || !data.some((d) => d?.impression?.total > 0)) ? null : (
		<Box
			className="box-table-wrapper"
			title="Creative Performance"
		>
			<Table
				data={data}
				columns={
					CreativeColumns({
						renderFraudGraphCell: renderFraudGraphCell,
						onExpandedChange: onExpandedChange,
					})
				}
				PadRowComponent={() => null}
				sortable={false}
				showPageSizeOptions={false}
				showPageJump={false}
				showPagination={false}
				SubComponent={renderCreativeSources}
				expanded={expandedState}
				loading={resProps.loading || loading}
				pageSize={1000}
				onResizedChange={newResized => setResizedState(newResized)}
				getTrGroupProps={(state, rowInfo) => {
					if (rowInfo && expandedState[rowInfo.index]) {
						return ({
							className: '-open'
						})
					}
				}}
				NoDataComponent={() => <NoData icon="box">There is no data</NoData>}
			/>
		</Box>
	);
}

CreativePerformanceColumns.propTypes = {
	campaign: PropTypes.object,
	getCampaign: PropTypes.func,
	data: PropTypes.array,
};