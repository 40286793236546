import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Input } from 'interceptd-ui';

import LoginSlogan from '../../components/LoginSlogan';

import Auth from '../../services/auth';
import Api from '../../services/api';
import Local from '../../services/localStorage';

import { validateEmail, decodeToken } from '../../utils/misc';

import Logo from '../../assets/logo.svg';

import './styles/Login.css';

import withTitle from '../withTitle';

class Join extends Component {
  state = {
    fullname: '',
    companyName: '',
    email: '',
    plainPassword: {
      first: '',
      second: '',
    },
    isError: false,
    isDisabled: true,
    isFetching: false,
    redirect: false,
    to: '/verify',
  }

  componentDidMount() {
    const { match } = this.props;
    const me = Local.getItem('me');

    if (me) {
      Local.setStore({});
      window.location.reload();
    }
    
    const token = match.params.token;
    const user = decodeToken(match.params.token);

    if (!user) {
      return this.setState({
        to: '/not-found',
        redirect: true,
      });
    }

    this.setState({
      joinedUser: user,
      email: user.email,
      companyName: user.company_name,
      token,
    });
  }

  handleSubmit = event => {
    event.preventDefault();
    const {
      fullname,
      plainPassword,
      isFetching,
      token,
    } = this.state;

    if (isFetching) return false;

    this.setState({ isFetching: true });
    const payload = {
      fullname: fullname.trim(),
      password: plainPassword.first,
      gdpr: true,
    };


    Auth.setToken(token);
    Api.setToken(Auth.getToken());

    Api.registerInvitedUser(payload)
      .then(() => {
        Local.setItem('login_as', false);
        window.location.href = '/create';
      })
      .catch(({ response }) => {
        this.setState({
          isFetching: false,
          error: response
        });
        toast.error(response?.data?.error?.message || 'Something went wrong.');
      });
  }

  checkValidation = () => {
    const {
      fullname,
      email,
      plainPassword,
    } = this.state;

    this.setState({
      isError: (plainPassword.first !== '' || plainPassword.second !== '') && plainPassword.first !== plainPassword.second,
      isDisabled: fullname.trim() === '' || email === '' || !validateEmail(email) || plainPassword.first === '' || plainPassword.second === '' || plainPassword.first !== plainPassword.second
    });
  }

  handleInput = ({ target }) => {
    this.setState({
      [target.name]: target.value,
    }, this.checkValidation);
  }

  handlePassword = (event, order) => {
    const { plainPassword } = this.state;
    this.setState({
      plainPassword: {
        ...plainPassword,
        [order]: event.target.value.trim()
      }
    }, this.checkValidation);
  }

  render() {
    const {
      fullname,
      companyName,
      email,
      plainPassword,
      isError,
      isDisabled,
      isFetching,
      redirect,
      to,
    } = this.state;

    if (redirect) return <Redirect to={to} />;

    return (
      <div className="login">
        <div className="login-wrapper">
          <LoginSlogan />
          <div className="login-right">
            <header className="login-header">
              <img src={Logo} alt="Interceptd" />
            </header>
            <form className="login-form" onSubmit={this.handleSubmit}>
              <div className="login-title">
                <h1>Join Your Team</h1>
              </div>
              <div className="login-form-inner">
                <Input
                  type="text"
                  name="fullname"
                  placeholder="John Doe"
                  label="Full Name"
                  value={fullname}
                  onChange={this.handleInput} />
                <Input
                  type="text"
                  name="companyName"
                  placeholder="Example Inc"
                  label="Company Name"
                  value={companyName}
                  disabled
                />
                <Input
                  type="email"
                  name="email"
                  placeholder="john@doe.com"
                  label="Email"
                  value={email}
                  disabled
                />
                <Input
                  type="password"
                  name="first"
                  label="Password"
                  value={plainPassword.first}
                  onChange={e => this.handlePassword(e, 'first')}
                  error={isError && 'Passwords are not same'} />
                <Input
                  type="password"
                  name="second"
                  label="Confirm Password"
                  value={plainPassword.second}
                  onChange={e => this.handlePassword(e, 'second')}
                  error={isError && 'Passwords are not same'} />
                <Button type="submit" block loading={isFetching} disabled={isDisabled}>Sign Up</Button>
                <span className="disclamer">By clicking "Sign Up", you agree to Interceptd's <a href="https://interceptd.com/terms-of-use/" target="_blank" rel="noopener noreferrer">Term of Services</a>.</span>
                <Button componen={Link} to="/welcome" block bgColor="transparent" data-event-category="NonAuth" data-event-action="I have an account button clicked">I have an account</Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default withTitle(Join, 'Join');