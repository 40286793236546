export const DeductionReportWarn = `Deduction reports can put flags to all installs or events from suspicious 
sub-publishers according to the alarm fired. Thus, you can see a different number of “flagged installs” 
or “flagged events” than the Install Report.`;

export const TotalPayoutDescription = 'The total amount of money spent for postbacked transactions happened in between selected dates';

export const TotalSavingDescription = 'The total amount of money you saved with rejected transactions happened in between selected dates';

export const TotalDeductibleDescription = 'The total amount of money you would deduct from your traffic sources';

export const TotalRevenueDescription = 'The total amount of money you earned from revenue events happened in between selected dates';

export const TotalCohortRevenueDescription = 'The total amount of money you earned from revenue events from the installs happened in between selected dates';

export const TotalSpendingDescription = 'The total amount of money you should pay to your traffic sources';

export const ERoasDescription = 'eROAS is your effective return on ad spent. It is calculated by dividing your clean revenue by your ad spent.';

export const PartnersSummaryTableCsvFields = {
  clean_conversion: 'CLEAN CONVERSION',
  clean_conversion_payout: 'CLEAN CONVERSION PAYOUTS',
  deductible_conversion: 'DEDUCTIBLE CONVERSION',
  deductible_conversion_payout: 'DEDUCTIBLE CONVERSION PAYOUTS',
  rejected_conversion: 'REJECTED CONVERSION',
  rejected_conversion_payout: 'REJECTED CONVERSION PAYOUTS',
}

export const CpiCampaignSummaryTableCsvFields = {
  clean_install: 'CLEAN INSTALL',
  clean_install_payout: 'CLEAN INSTALL PAYOUTS',
  deductible_install: 'DEDUCTIBLE INSTALL',
  deductible_install_payout: 'DEDUCTIBLE INSTALL PAYOUTS',
  rejected_install: 'REJECTED INSTALL',
  rejected_install_payout: 'REJECTED INSTALL PAYOUTS',
}

export const CpaCampaignSummaryTableCsvFields = {
  clean_event: 'CLEAN EVENT',
  clean_event_payout: 'CLEAN EVENT PAYOUTS',
  deductible_event: 'DEDUCTIBLE EVENT',
  deductible_event_payout: 'DEDUCTIBLE EVENT PAYOUTS',
  rejected_event: 'REJECTED EVENT',
  rejected_event_payout: 'REJECTED EVENT PAYOUTS',
};

export const CpaCohortTableCsvFields = {
  clean_cohort_event: 'COHORT CLEANS',
	clean_cohort_event_payout: 'COHORT CLEANS PAYOUTS',
	clean_cohort_event_r: 'REATTRIBUTED COHORT CLEANS',
	clean_cohort_event_payout_r: 'REATTRIBUTED COHORT CLEANS PAYOUTS',
	
  deductible_cohort_event: 'COHORT DEDUCTIBLE',
	deductible_cohort_event_payout: 'COHORT DEDUCTIBLE PAYOUTS',
	deductible_cohort_event_r: 'REATTRIBUTED COHORT DEDUCTIBLE',
	deductible_cohort_event_payout_r: 'REATTRIBUTED COHORT DEDUCTIBLE PAYOUTS',
	
  rejected_cohort_event: 'REJECTED',
	rejected_cohort_event_payout: 'REJECTED PAYOUTS',
	rejected_cohort_event_r: 'REATTRIBUTED REJECTED',
	rejected_cohort_event_payout_r: 'REATTRIBUTED REJECTED PAYOUTS',
	
};

export const CpaEventCohortTableCsvFields = {
  clean_event_cohort_event: 'EVENT COHORT CLEANS',
	clean_event_cohort_event_payout: 'EVENT COHORT CLEANS PAYOUTS',
	clean_event_cohort_event_r: 'REATTRIBUTED EVENT COHORT CLEANS',
	clean_event_cohort_event_payout_r: 'REATTRIBUTED EVENT COHORT CLEANS PAYOUTS',
	
  deductible_event_cohort_event: 'EVENT COHORT DEDUCTIBLE',
	deductible_event_cohort_event_payout: 'EVENT COHORT DEDUCTIBLE PAYOUTS',
	deductible_event_cohort_event_r: 'REATTRIBUTED EVENT COHORT DEDUCTIBLE',
	deductible_event_cohort_event_payout_r: 'REATTRIBUTED EVENT COHORT DEDUCTIBLE PAYOUTS',
	
  rejected_event_cohort_event: 'REJECTED',
	rejected_event_cohort_event_payout: 'REJECTED PAYOUTS',
	rejected_event_cohort_event_r: 'REATTRIBUTED REJECTED',
  rejected_event_cohort_event_payout_r: 'REATTRIBUTED REJECTED PAYOUTS',
};

export const RevenueEventsTableCsvFields = {
	type: 'TYPE',

	clean_event: 'EVENT CLEAN (TOTAL)',
	clean_amount: 'EVENT CLEAN REVENUE',
	clean_reattributed_event: 'REATTRIBUTED CLEAN (TOTAL)',
	clean_reattributed_amount: 'REATTRIBUTED CLEAN REVENUE',

	clean_cohort_event: 'COHORT INSTALL CLEAN (TOTAL)',
	clean_cohort_amount: 'COHORT INSTALL CLEAN REVENUE',
	clean_cohort_event_r: 'REATTRIBUTED COHORT INSTALL CLEAN (TOTAL)',
	clean_cohort_amount_r: 'REATTRIBUTED COHORT INSTALL CLEAN REVENUE',

	clean_event_cohort_event: 'COHORT EVENT CLEAN (TOTAL)',
	clean_event_cohort_amount: 'COHORT EVENT CLEAN REVENUE',
	clean_event_cohort_event_r: 'REATTRIBUTED COHORT EVENT CLEAN (TOTAL)',
	clean_reattributed_amount: 'REATTRIBUTED COHORT EVENT CLEAN REVENUE',
};

export const eRoasTableCsvFields = {
  type: 'TYPE',
  roas_revenue_total: 'REVENUE',
  roas_revenue_clean: 'REVENUE CLEAN',
  roas_spent_total: 'SPENT',
  roas_spent_clean: 'SPENT CLEAN',
  roas_revenue_cohort_total: 'ROAS',
  roas_revenue_cohort_clean: 'ROAS CLEAN',
  roas_spent_cohort_total: 'COHORT REVENUE',
  roas_spent_cohort_clean: 'COHORT REVENUE CLEAN',
  roas_revenue_event_cohort_total: 'COHORT SPENT',
  roas_revenue_event_cohort_clean: 'COHORT SPENT CLEAN',
  roas_spent_event_cohort_total: 'COHORT ROAS',
  roas_spent_event_cohort_clean: 'COHORT ROAS CLEAN',
};

export const SummaryReportTableCsvFields = {
	partners_summary: PartnersSummaryTableCsvFields,
  cpi_summary: CpiCampaignSummaryTableCsvFields,
  cpa_summary: CpaCampaignSummaryTableCsvFields,
  cpa_cohort: CpaCohortTableCsvFields,
  cpa_event_cohort: CpaEventCohortTableCsvFields,
  revenue: RevenueEventsTableCsvFields,
  eroas: eRoasTableCsvFields,
};

export const SummaryReportTabOptions = [{
	value: 'partners_summary',
	label: 'Partners Summary'
},{
	value: 'cpi_summary',
	label: 'CPI Campaigns Summary',
	type: 'CPI',
}, {
	value: 'cpa_summary',
	label: 'CPA Campaigns Summary',
	type: 'CPA',
}, {
	value: 'cpa_cohort',
	label: 'CPA Campaigns - Install Cohort',
	type: 'CPA',
}, {
	value: 'cpa_event_cohort',
	label: 'CPA Campaigns - Event Cohort',
	type: 'CPA',
}, {
	value: 'revenue',
	label: 'Revenue Events',
}, {
	value: 'eroas',
	label: 'eROAS',
}];