import React, { Component } from 'react';
import { Icon } from 'interceptd-ui';

import DescriptionCard from './DescriptionCard';
import FreeToolCard from './FreeToolCard';

import Local from '../../services/localStorage';

import withTitle from '../withTitle';

import './styles/StartFreeTrial.css';

class StartFreeTrial extends Component {
	state = {
		me: Local.getItem('me'),
	};

	componentDidMount() {
		const { me } = Local.getItem('me');

		if (me && me.plans?.interceptd !== 'free') {
			window.location.href = '/';
		}

		const head = document.querySelector('head');
		const script = document.createElement('script');

		script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
		head.appendChild(script);
	}

	render() {
		return (
			<div className="start-free-trial">
				<div className="title">
					<span><b>Congratulations, Your account has been created!</b></span>
				</div>

				<div className="start-free-trial-row">
					<div className="start-free-trial-col">
						<span className="col-title">Let's schedule a 15 min call to complete the first steps together.</span>
						<div className="scheduled-wrapper">
							<DescriptionCard />
							<div className="calendly-wrapper">
								<div
									className="calendly-inline-widget"
									data-url="https://calendly.com/interceptd-demo/15min?hide_event_type_details=1"
									style={{ minWidth: '320px', height: '505px' }}
								/>
							</div>
						</div>
					</div>
					<div className="start-free-trial-col">
						<span className="col-title">Discover our free tools.</span>
						<FreeToolCard icon="heart" title="Fraud Health Assessment" link="/fraud-insights">
							<p>To promote awareness of mobile ad fraud, we are offering free access to your own personalized Mobile App Fraud Health Assessment. Find out things such as:</p>
							<div className="card-list">
								<div className="card-list-item">
									<Icon i="check" size={13} />
									<span>Tailored suggestions to optimise your ad campaigns.</span>
								</div>
								<div className="card-list-item">
									<Icon i="check" size={13} />
									<span>Your over-all App's Fraud Health.</span>
								</div>
								<div className="card-list-item">
									<Icon i="check" size={13} />
									<span>Estimated mobile ad fraud types affecting your app.</span>
								</div>
							</div>
						</FreeToolCard>

						<FreeToolCard icon="link" title="Secure Clicks" link="/secure-link">
							<p>For Secure Clicks being smart is not only about redirecting IOS users to IOS links but also having additional;</p>
							<div className="card-list">
								<div className="card-list-item">
									<Icon i="check" size={13} />
									<span>Real-Time Analytics & Reporting.</span>
								</div>
								<div className="card-list-item">
									<Icon i="check" size={13} />
									<span>Protection Shield via Deep IP & Device Info.</span>
								</div>
							</div>
							<p>We proudly dare to say that, we have the smartest and the most secure link shield available.</p>
						</FreeToolCard>
					</div>
				</div>
			</div>
		);
	}
}

export default withTitle(StartFreeTrial, 'Start Free Trial');