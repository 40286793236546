import React from 'react';
import { Redirect, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import get from 'lodash/get';
import { Button, Input } from 'interceptd-ui';

import LoginSlogan from '../../components/LoginSlogan';

import Auth from '../../services/auth';
import Api from '../../services/api';
import Local from '../../services/localStorage';
import SendEvent from '../../services/events';

import { getQueryParams, validateEmail } from '../../utils/misc';

import Logo from '../../assets/logo.svg';

import './styles/Login.css';

import withTitle from '../withTitle';

class Register extends React.Component {
	state = {
		fullname: '',
		companyName: '',
		email: getQueryParams('userMail') || '',
		plainPassword: {
			first: '',
			second: '',
		},
		isError: false,
		isDisabled: true,
		isFetching: false,
		redirect: false,
		addQuery: '',
		to: '/verify',
	}
	
	componentDidMount() {
		Local.setStore({});
		Api.setToken();

		const { search } = this.props.location;
		if (search && (search.indexOf('app-index') > -1)) {
			this.setState({ addQuery: '?ref=index.interceptd.com' });
		} else if ( search && search.indexOf('utm_source=secure-clicks') > -1 ) {
			this.setState({ addQuery: '?ref=secure-links' });
		} else if (search && search.indexOf('fraud-assessment') > -1) {
			this.setState({ addQuery: '?ref=fraud-assessment' });
		}
	}

	handleSubmit = event => {
		event.preventDefault();
		const {
			email,
			fullname,
			companyName,
			plainPassword,
			isFetching,
			addQuery,
		} = this.state;

		SendEvent({
			category: 'NonAuth',
			action: 'Register button clicked',
			nonInteraction: false,
		});

		if ( isFetching ) return false;

		this.setState({ isFetching: true });
		const payload = {
			fullname: fullname.trim(),
			company_name: companyName.trim(),
			email: email.trim(),
			password: plainPassword.first,
			gdpr: true,
			query: addQuery
		};
	
		Api.register(payload)
			.then(response => {
				SendEvent({
					category: 'NonAuth',
					action: 'Register page redirected to verify page',
					nonInteraction: true,
				});

				Auth.setToken(response.data.token);
				Api.setToken(Auth.getToken());
				Local.setItem('login_as', false);
				this.setState({
					isFetching: false,
					redirect: true,
					to: {
						pathname: '/verify',
						state: { email }
					},
				})
			})
			.catch(({ response }) => {
				SendEvent({
					description: 'register on Register',
					fatal: true,
				});
				this.setState({
					isFetching: false,
					error: response
				});
				toast.error(get(response, 'data.error.message', 'Something went wrong.'));
			});
	}

	checkValidation = () => {
		const {
			fullname,
			companyName,
			email,
			plainPassword,
		} = this.state;

		this.setState({
			isError: ( plainPassword.first !== '' || plainPassword.second !== '' ) && plainPassword.first !== plainPassword.second,
			isDisabled: fullname.trim() === '' || companyName.trim() === '' || email === '' || !validateEmail(email) || plainPassword.first === '' || plainPassword.second === '' || plainPassword.first !== plainPassword.second
		});
	}

	handleInput = ({ target }) => {
		this.setState({
			[target.name]: target.value,
		}, this.checkValidation);
	}

	handlePassword = (event, order) => {
		const { plainPassword } = this.state;
		this.setState({
			plainPassword: {
				...plainPassword,
				[order]: event.target.value.trim()
			}
		}, this.checkValidation);
	}

	render() {
		const {
			fullname,
			companyName,
			email,
			plainPassword,
			isError,
			isDisabled,
			isFetching,
			redirect,
			to,
		} = this.state;

		if ( redirect ) return <Redirect to={to} />;

		return (
			<div className="login">
				<div className="login-wrapper">
					<LoginSlogan />
					<div className="login-right">
						<header className="login-header">
							<img src={Logo} alt="Interceptd" />
						</header>
						<form className="login-form" onSubmit={this.handleSubmit}>
							<div className="login-title">
								<h1>Create Your Free Account</h1>
							</div>
							<div className="login-form-inner">
								<Input
									type="text"
									name="fullname"
									placeholder="John Doe"
									label="Full Name"
									value={fullname}
									onChange={this.handleInput} />
								<Input
									type="text"
									name="companyName"
									placeholder="Example Inc"
									label="Company Name"
									value={companyName}
									onChange={this.handleInput} />
								<Input
									type="email"
									name="email"
									placeholder="john@doe.com"
									label="Email"
									value={email}
									onChange={this.handleInput} />
								<Input
									type="password"
									name="first"
									label="Password"
									value={plainPassword.first}
									onChange={e => this.handlePassword(e, 'first')}
									error={isError && 'Passwords are not same'} />
								<Input
									type="password"
									name="second"
									label="Confirm Password"
									value={plainPassword.second}
									onChange={e => this.handlePassword(e, 'second')}
									error={isError && 'Passwords are not same'} />
								<Button type="submit" block loading={isFetching} disabled={isDisabled}>Sign Up</Button>
								<span className="disclamer">By clicking "Sign Up", you agree to Interceptd's <a href="https://interceptd.com/terms-of-use/" target="_blank" rel="noopener noreferrer">Term of Services</a>.</span>
								<Button component={Link} to="/welcome" block bgColor="transparent" data-event-category="NonAuth" data-event-action="I have an account button clicked">I have an account</Button>
							</div>
						</form>
					</div>
				</div>
			</div>
		)
	}
}

export default withTitle(Register, 'Sign Up');