import React from 'react';
import Humanize from 'humanize-plus';
import {
	Info,
	AppIcon,
} from 'interceptd-ui';

import { shadowText, safeDivide, isShadow } from '../../../utils/misc';
import defaultPartnerLogo from '../../../assets/icon-gray.svg';

const infoData = {
	clean_install: 'The total amount of installs which did not get any alarm from deductible alarm set',
	clean_event: 'The total amount of events which did not get any alarm from deductible alarm set',
	deductible_event: 'The total amount of events which have at least one alarm from deductible alarm set',
	deductible_install: 'The total amount of installs which have at least one alarm from deductible alarm set',
};

const getCommonColumns = () => {
	return [
		{
			Header: '',
			id: 'app_info',
			accessor: 'app_info',
			cast: 'string',
			sortable: false,
			width: 45,
			Cell: props => {
				return (
					<div style={{ marginLeft: 10 }}>
						<AppIcon app={props.original.campaign.target === 'MOBILE' ? props.original.campaign.app_name ? props.original.campaign.app_info : undefined : {
							websafe_url: props.original.campaign.landing_url,
							device: 'web',
						}} showTooltip={false} isShadow={isShadow()} />
					</div>
				);
			},
		},
		{
			Header: 'Campaign Name (Source Name)',
			id: 'campaign',
			accessor: 'campaign',
			cast: 'string',
			width: 300,
			Cell: (props) => shadowText(`${props.value.name} (${props.original.source.name})`, 'Example Campaign'),
			Footer: () => (
				<div style={{ fontWeight: 600, flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
					<span>Total</span>
				</div>
			)
		},
	];
};

const generateFooter = ({
	field1,
	field2,
	color,
	field1Prefix = '',
	field2Prefix = '$',
}) => (props) => {
	const { data } = props;
	const total = data.reduce((acc, curr) => acc + curr._original[field1], 0);
	const totalPayout = data.reduce((acc, curr) => acc + curr._original[field2], 0);
	return (
		<div style={{ display: 'flex', flexDirection: 'column', fontWeight: 600 }}>
			<span>{field1Prefix}{Humanize.intComma(total)}</span>
			{field2 && <span style={{ color: `var(--${color || 'primaryShade'})` }}>{field2Prefix}{Humanize.formatNumber(totalPayout)}</span>}
		</div>
	);
};

const generateHeader = ({ title1, title2, color, infoData }) => (
	<div style={{ display: 'flex', flexDirection: 'column' }}>
		<span style={{ marginBottom: 5, display: 'flex' }}><span style={{ marginRight: 10 }}>{title1}</span> {infoData && <Info>{infoData}</Info>}</span>
		<span style={{ color: `var(--${color || 'primaryShade'})` }}>{title2}</span>
	</div>
);


export const partnersSummaryColumns = (baseFieldName, payout_symbol = '$') => [
	{
		Header: '',
		id: 'partner_logo',
		accessor: 'partner_logo',
		cast: 'string',
		sortable: false,
		width: 80,
		Cell: props => {
			return (
				<div style={{ marginLeft: 10 }}>
					<div className="partner-logo">
						<img src={props.original.partner.logo || defaultPartnerLogo} alt={`${props.original.partner.name} Logo`} />
					</div>
				</div>
			);
		},
	},
	{
		Header: 'Partner Name',
		id: 'partner',
		accessor: 'partner',
		cast: 'string',
		width: 300,
		Cell: (props) => shadowText(`${props.original.partner.name}`, 'Example Partner'),
		Footer: () => (
			<div style={{ fontWeight: 600, flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
				<span>Total</span>
			</div>
		)
	},
	{
		Header: generateHeader({ title1: `CLEAN ${baseFieldName}`, title2: 'PAYOUTS', infoData: infoData[`clean_${baseFieldName}`] }),
		id: `clean_${baseFieldName}`,
		accessor: `clean_${baseFieldName}`,
		cast: 'string',
		Cell: (props) => (
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<span>{props.original[`clean_${baseFieldName}`]}</span>
				<span style={{ color: 'var(--primaryShade)' }}>{payout_symbol}{Humanize.formatNumber(props.original[`clean_${baseFieldName}_payout`])}</span>
			</div>
		),
		Footer: generateFooter({
			field1: `clean_${baseFieldName}`,
			field2: `clean_${baseFieldName}_payout`,
			field2Prefix: payout_symbol
		})
	},
	{
		Header: generateHeader({ title1: `DEDUCTIBLE ${baseFieldName}`, title2: 'PAYOUTS', infoData: infoData[`deductible_${baseFieldName}`] }),
		id: `deductible_${baseFieldName}`,
		accessor: `deductible_${baseFieldName}`,
		cast: 'string',
		Cell: (props) => (
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<span>{props.original[`deductible_${baseFieldName}`]}</span>
				<span style={{ color: 'var(--primaryShade)' }}>{payout_symbol}{Humanize.formatNumber(props.original[`deductible_${baseFieldName}_payout`])}</span>
			</div>
		),
		Footer: generateFooter({
			field1: `deductible_${baseFieldName}`,
			field2: `deductible_${baseFieldName}_payout`,
			field2Prefix: payout_symbol
		})
	},
	{
		Header: generateHeader({ title1: `REJECTED ${baseFieldName}`, title2: 'PAYOUTS' }),
		id: 'rejected_install',
		accessor: 'rejected_install',
		cast: 'string',
		Cell: (props) => (
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<span>{props.original[`rejected_${baseFieldName}`]}</span>
				<span style={{ color: 'var(--primaryShade)' }}>{payout_symbol}{Humanize.formatNumber(props.original[`rejected_${baseFieldName}_payout`])}</span>
			</div>
		),
		Footer: generateFooter({
			field1: `rejected_${baseFieldName}`,
			field2: `rejected_${baseFieldName}_payout`,
			field2Prefix: payout_symbol
		})
	},
];

export const campaignSummaryColumns = (baseFieldName, payout_symbol = '$') => [
	...getCommonColumns(),
	{
		Header: generateHeader({ title1: `CLEAN ${baseFieldName}`, title2: 'PAYOUTS', infoData: infoData[`clean_${baseFieldName}`] }),
		id: `clean_${baseFieldName}`,
		accessor: `clean_${baseFieldName}`,
		cast: 'string',
		Cell: (props) => (
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<span>{props.original[`clean_${baseFieldName}`]}</span>
				<span style={{ color: 'var(--primaryShade)' }}>{payout_symbol}{Humanize.formatNumber(props.original[`clean_${baseFieldName}_payout`])}</span>
			</div>
		),
		Footer: generateFooter({
			field1: `clean_${baseFieldName}`,
			field2: `clean_${baseFieldName}_payout`,
			field2Prefix: payout_symbol
		})
	},
	{
		Header: generateHeader({ title1: `DEDUCTIBLE ${baseFieldName}`, title2: 'PAYOUTS', infoData: infoData[`deductible_${baseFieldName}`] }),
		id: `deductible_${baseFieldName}`,
		accessor: `deductible_${baseFieldName}`,
		cast: 'string',
		Cell: (props) => (
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<span>{props.original[`deductible_${baseFieldName}`]}</span>
				<span style={{ color: 'var(--primaryShade)' }}>{payout_symbol}{Humanize.formatNumber(props.original[`deductible_${baseFieldName}_payout`])}</span>
			</div>
		),
		Footer: generateFooter({
			field1: `deductible_${baseFieldName}`,
			field2: `deductible_${baseFieldName}_payout`,
			field2Prefix: payout_symbol
		})
	},
	{
		Header: generateHeader({ title1: `REJECTED ${baseFieldName}`, title2: 'PAYOUTS' }),
		id: 'rejected_install',
		accessor: 'rejected_install',
		cast: 'string',
		Cell: (props) => (
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<span>{props.original[`rejected_${baseFieldName}`]}</span>
				<span style={{ color: 'var(--primaryShade)' }}>{payout_symbol}{Humanize.formatNumber(props.original[`rejected_${baseFieldName}_payout`])}</span>
			</div>
		),
		Footer: generateFooter({
			field1: `rejected_${baseFieldName}`,
			field2: `rejected_${baseFieldName}_payout`,
			field2Prefix: payout_symbol
		})
	},
];

export const cpACohortColumns = (payout_symbol = '$') => [
	...getCommonColumns(),
	{
		Header: generateHeader({ title1: 'COHORT CLEANS', title2: 'PAYOUTS' }),
		id: 'clean_cohort_event',
		accessor: 'clean_cohort_event',
		cast: 'string',
		Cell: (props) => (
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<span>{(props.original.clean_cohort_event)}</span>
				<span style={{ color: 'var(--primaryShade)' }}>
					{payout_symbol}{Humanize.formatNumber((props.original.clean_cohort_event_payout))}
				</span>
			</div>
		),
		Footer: (props) => {
			const { data } = props;
			const total = data.reduce((acc, curr) => acc + curr._original.clean_cohort_event, 0);
			const totalPayout = data.reduce((acc, curr) => acc + curr._original.clean_cohort_event_payout, 0);
			return (
				<div style={{ display: 'flex', flexDirection: 'column', fontWeight: 600 }}>
					<span>{Humanize.intComma(total)}</span>
					<span style={{ color: `var(--primaryShade)` }}>{payout_symbol}{Humanize.formatNumber(totalPayout)}</span>
				</div>
			);
		}
	},
	{
		Header: generateHeader({ title1: 'REATTRIBUTED COHORT CLEANS', title2: 'PAYOUTS' }),
		id: 'clean_cohort_event_r',
		accessor: 'clean_cohort_event_r',
		cast: 'string',
		Cell: (props) => (
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<span>{props.original.clean_cohort_event_r}</span>
				<span style={{ color: 'var(--primaryShade)' }}>
					{payout_symbol}{Humanize.formatNumber(props.original.clean_cohort_event_payout_r)}
				</span>
			</div>
		),
		Footer: generateFooter({
			field1: 'clean_cohort_event_r',
			field2: 'clean_cohort_event_payout_r',
			field2Prefix: payout_symbol
		})
	},
	{
		Header: generateHeader({ title1: 'COHORT DEDUCTIBLE', title2: 'PAYOUTS' }),
		id: 'deductible_cohort_event',
		accessor: 'deductible_cohort_event',
		cast: 'string',
		Cell: (props) => (
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<span>{props.original.deductible_cohort_event}</span>
				<span style={{ color: 'var(--primaryShade)' }}>
					{payout_symbol}{Humanize.formatNumber(props.original.deductible_cohort_event_payout)}
				</span>
			</div>
		),
		Footer: (props) => {
			const { data } = props;
			const total = data.reduce((acc, curr) => acc + curr._original.deductible_cohort_event, 0);
			const totalPayout = data.reduce((acc, curr) => acc + curr._original.deductible_cohort_event_payout, 0);
			return (
				<div style={{ display: 'flex', flexDirection: 'column', fontWeight: 600 }}>
					<span>{Humanize.intComma(total)}</span>
					<span style={{ color: `var(--primaryShade)` }}>{payout_symbol}{Humanize.formatNumber(totalPayout)}</span>
				</div>
			);
		}
	},
	{
		Header: generateHeader({ title1: 'REATTRIBUTED COHORT DEDUCTIBLE', title2: 'PAYOUTS' }),
		id: 'deductible_cohort_event_r',
		accessor: 'deductible_cohort_event_r',
		cast: 'string',
		Cell: (props) => (
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<span>{props.original.deductible_cohort_event_r}</span>
				<span style={{ color: 'var(--primaryShade)' }}>
					{payout_symbol}{Humanize.formatNumber(props.original.deductible_cohort_event_payout_r)}
				</span>
			</div>
		),
		Footer: generateFooter({
			field1: 'deductible_cohort_event_r',
			field2: 'deductible_cohort_event_payout_r',
			field2Prefix: payout_symbol
		})
	},
	{
		Header: () => (
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<span style={{ marginBottom: 5 }}>REJECTED</span>
				<span style={{ color: 'var(--primaryShade)' }}>PAYOUTS</span>
			</div>
		),
		id: 'rejected_cohort_event_r',
		accessor: 'rejected_cohort_event_r',
		cast: 'string',
		Cell: (props) => (
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<span>{props.original.rejected_cohort_event}</span>
				<span style={{ color: 'var(--primaryShade)' }}>
					{payout_symbol}{Humanize.formatNumber(props.original.rejected_cohort_event_payout)}
				</span>
			</div>
		),
		Footer: (props) => {
			const { data } = props;
			const total = data.reduce((acc, curr) => acc + curr._original.rejected_cohort_event, 0);
			const totalPayout = data.reduce((acc, curr) => acc + curr._original.rejected_cohort_event_payout, 0);
			return (
				<div style={{ display: 'flex', flexDirection: 'column', fontWeight: 600 }}>
					<span>{Humanize.intComma(total)}</span>
					<span style={{ color: `var(--primaryShade)` }}>{payout_symbol}{Humanize.formatNumber(totalPayout)}</span>
				</div>
			);
		}
	},
	{
		Header: () => (
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<span style={{ marginBottom: 5 }}>REATTRIBUTED REJECTED</span>
				<span style={{ color: 'var(--primaryShade)' }}>PAYOUTS</span>
			</div>
		),
		id: 'rejected_cohort_event',
		accessor: 'rejected_cohort_event',
		cast: 'string',
		Cell: (props) => (
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<span>{props.original.rejected_cohort_event_r}</span>
				<span style={{ color: 'var(--primaryShade)' }}>
					{payout_symbol}{Humanize.formatNumber(props.original.rejected_cohort_event_payout_r)}
				</span>
			</div>
		),
		Footer: generateFooter({
			field1: 'rejected_cohort_event_r',
			field2: 'rejected_cohort_event_payout_r',
			field2Prefix: payout_symbol
		})
	},
];

export const cpAEventCohortColumns = (payout_symbol = '$') => [
	...getCommonColumns(),
	{
		Header: generateHeader({ title1: 'EVENT COHORT CLEANS', title2: 'PAYOUTS' }),
		id: 'clean_event_cohort_event',
		accessor: 'clean_event_cohort_event',
		cast: 'string',
		Cell: (props) => (
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<span>{(props.original.clean_event_cohort_event)}</span>
				<span style={{ color: 'var(--primaryShade)' }}>{payout_symbol}{Humanize.formatNumber(props.original.clean_event_cohort_event_payout)}</span>
			</div>
		),
		Footer: (props) => {
			const { data } = props;
			const total = data.reduce((acc, curr) => acc + curr._original.clean_event_cohort_event, 0);
			const totalPayout = data.reduce((acc, curr) => acc + curr._original.clean_event_cohort_event_payout, 0);
			return (
				<div style={{ display: 'flex', flexDirection: 'column', fontWeight: 600 }}>
					<span>{Humanize.intComma(total)}</span>
					<span style={{ color: `var(--primaryShade)` }}>{payout_symbol}{Humanize.formatNumber(totalPayout)}</span>
				</div>
			);
		}
	},
	{
		Header: generateHeader({ title1: 'REATTRIBUTED EVENT COHORT CLEANS', title2: 'PAYOUTS' }),
		id: 'clean_event_cohort_event',
		accessor: 'clean_event_cohort_event',
		cast: 'string',
		Cell: (props) => (
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<span>{props.original.clean_event_cohort_event_r}</span>
				<span style={{ color: 'var(--primaryShade)' }}>{payout_symbol}{Humanize.formatNumber(props.original.clean_event_cohort_event_payout_r)}</span>
			</div>
		),
		Footer: generateFooter({
			field1: 'clean_event_cohort_event_r',
			field2: 'clean_event_cohort_event_payout_r',
			field2Prefix: payout_symbol
		})
	},
	{
		Header: generateHeader({ title1: 'EVENT COHORT DEDUCTIBLE', title2: 'PAYOUTS' }),
		id: 'deductible_event_cohort_event',
		accessor: 'deductible_event_cohort_event',
		cast: 'string',
		Cell: (props) => (
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<span>{props.original.deductible_event_cohort_event}</span>
				<span style={{ color: 'var(--primaryShade)' }}>
					{payout_symbol}{Humanize.formatNumber(props.original.deductible_event_cohort_event_payout)}
				</span>
			</div>
		),
		Footer: (props) => {
			const { data } = props;
			const total = data.reduce((acc, curr) => acc + curr._original.deductible_event_cohort_event, 0);
			const totalPayout = data.reduce((acc, curr) => acc + curr._original.deductible_event_cohort_event_payout, 0);
			return (
				<div style={{ display: 'flex', flexDirection: 'column', fontWeight: 600 }}>
					<span>{Humanize.intComma(total)}</span>
					<span style={{ color: `var(--primaryShade)` }}>{payout_symbol}{Humanize.formatNumber(totalPayout)}</span>
				</div>
			);
		}
	},
	{
		Header: generateHeader({ title1: 'REATTRIBUTED EVENT COHORT DEDUCTIBLE', title2: 'PAYOUTS' }),
		id: 'deductible_event_cohort_event_r',
		accessor: 'deductible_event_cohort_event_r',
		cast: 'string',
		Cell: (props) => (
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<span>{props.original.deductible_event_cohort_event_r}</span>
				<span style={{ color: 'var(--primaryShade)' }}>
					{payout_symbol}{Humanize.formatNumber(props.original.deductible_event_cohort_event_payout_r)}
				</span>
			</div>
		),
		Footer: generateFooter({
			field1: 'deductible_event_cohort_event_r',
			field2: 'deductible_event_cohort_event_payout_r',
			field2Prefix: payout_symbol
		})
	},
	{
		Header: () => (
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<span style={{ marginBottom: 5 }}>REJECTED</span>
				<span style={{ color: 'var(--primaryShade)' }}>PAYOUTS</span>
			</div>
		),
		id: 'rejected_event_cohort_event',
		accessor: 'rejected_event_cohort_event',
		cast: 'string',
		Cell: (props) => (
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<span>{props.original.rejected_event_cohort_event}</span>
				<span style={{ color: 'var(--primaryShade)' }}>{payout_symbol}{Humanize.formatNumber(props.original.rejected_event_cohort_event_payout)}</span>
			</div>
		),
		Footer: (props) => {
			const { data } = props;
			const total = data.reduce((acc, curr) => acc + curr._original.rejected_event_cohort_event, 0);
			const totalPayout = data.reduce((acc, curr) => acc + curr._original.rejected_event_cohort_event_payout, 0);
			return (
				<div style={{ display: 'flex', flexDirection: 'column', fontWeight: 600 }}>
					<span>{Humanize.intComma(total)}</span>
					<span style={{ color: `var(--primaryShade)` }}>{payout_symbol}{Humanize.formatNumber(totalPayout)}</span>
				</div>
			);
		}
	},
	{
		Header: () => (
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<span style={{ marginBottom: 5 }}>REATTRIBUTED REJECTED</span>
				<span style={{ color: 'var(--primaryShade)' }}>PAYOUTS</span>
			</div>
		),
		id: 'rejected_event_cohort_event',
		accessor: 'rejected_event_cohort_event',
		cast: 'string',
		Cell: (props) => (
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<span>{(props.original.rejected_event_cohort_event_r)}</span>
				<span style={{ color: 'var(--primaryShade)' }}>{payout_symbol}{Humanize.formatNumber(props.original.rejected_event_cohort_event_payout_r)}</span>
			</div>
		),
		Footer: generateFooter({
			field1: 'rejected_event_cohort_event_r',
			field2: 'rejected_event_cohort_event_payout_r',
			field2Prefix: payout_symbol
		})
	},
];

export const cohortColumns = (revenue_symbol = '$') => [
	...getCommonColumns(),
	{
		Header: 'TYPE',
		id: 'type',
		accessor: 'type',
		cast: 'string',
		Cell: (props) => props.original.campaign.type,
	},
	{
		Header: generateHeader({ title1: 'EVENT CLEAN (TOTAL)', title2: 'REVENUE', color: 'green' }),
		id: 'clean_event',
		accessor: 'clean_event',
		cast: 'string',
		Cell: (props) => (
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<span>{props.original.clean_event - props.original.clean_reattributed_event}</span>
				<span style={{ color: 'var(--green)' }}>{revenue_symbol}{Humanize.formatNumber(props.original.clean_amount)}</span>
			</div>
		),
		Footer: (props) => {
			const { data } = props;
			const total = data.reduce((acc, curr) => acc + (curr._original.clean_event - curr._original.clean_reattributed_event), 0);
			const totalPayout = data.reduce((acc, curr) => acc + curr._original.clean_amount, 0);
			return (
				<div style={{ display: 'flex', flexDirection: 'column', fontWeight: 600 }}>
					<span>{Humanize.intComma(total)}</span>
					<span style={{ color: `var(--green)` }}>{revenue_symbol}{Humanize.formatNumber(totalPayout)}</span>
				</div>
			);
		}
	},
	{
		Header: generateHeader({ title1: 'REATTRIBUTED CLEAN (TOTAL)', title2: 'REVENUE', color: 'green' }),
		id: 'clean_reattributed_event',
		accessor: 'clean_reattributed_event',
		cast: 'string',
		Cell: (props) => (
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<span>{props.original.clean_reattributed_event}</span>
				<span style={{ color: 'var(--green)' }}>{revenue_symbol}{Humanize.formatNumber(props.original.clean_reattributed_amount)}</span>
			</div>
		),
		Footer: generateFooter({
			field1: 'clean_reattributed_event',
			field2: 'clean_reattributed_amount',
			color: 'green',
			field2Prefix: revenue_symbol
		})
	},
	{
		Header: generateHeader({ title1: 'COHORT INSTALL CLEAN (TOTAL)', title2: 'REVENUE', color: 'green' }),
		id: 'clean_cohort_event',
		accessor: 'clean_cohort_event',
		cast: 'string',
		Cell: (props) => {
			return (
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<span>
						{`${props.original.clean_cohort_event} `}
					</span>

					<span style={{ color: 'var(--green)' }}>{revenue_symbol}{Humanize.formatNumber(props.original.clean_cohort_amount)}</span>
				</div>
			);
		},
		Footer: (props) => {
			const { data } = props;
			const total = data.reduce((acc, curr) => acc + curr._original.clean_cohort_event, 0);
			const totalPayout = data.reduce((acc, curr) => acc + curr._original.clean_cohort_amount, 0);
			return (
				<div style={{ display: 'flex', flexDirection: 'column', fontWeight: 600 }}>
					<span>{Humanize.intComma(total)}</span>
					<span style={{ color: `var(--green)` }}>{revenue_symbol}{Humanize.formatNumber(totalPayout)}</span>
				</div>
			);
		}
	},
	{
		Header: generateHeader({ title1: 'REATTRIBUTED COHORT INSTALL CLEAN (TOTAL)', title2: 'REVENUE', color: 'green' }),
		id: 'clean_cohort_event',
		accessor: 'clean_cohort_event',
		cast: 'string',
		Cell: (props) => {
			return (
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<span>
						{`${(props.original.clean_cohort_event_r)} `}
					</span>

					<span style={{ color: 'var(--green)' }}>{revenue_symbol}{Humanize.formatNumber(props.original.clean_cohort_amount_r)}</span>
				</div>
			);
		},
		Footer: generateFooter({
			field1: 'clean_cohort_event_r',
			field2: 'clean_cohort_amount_r',
			color: 'green',
			field2Prefix: revenue_symbol
		})
	},
	{
		Header: generateHeader({ title1: 'COHORT EVENT CLEAN (TOTAL)', title2: 'REVENUE', color: 'green' }),
		id: 'clean_event_cohort_event',
		accessor: 'clean_event_cohort_event',
		cast: 'string',
		Cell: (props) => (
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<span>
					{`${props.original.clean_event_cohort_event} `}
				</span>
				<span style={{ color: 'var(--green)' }}>
					{revenue_symbol}{Humanize.formatNumber(props.original.clean_event_cohort_amount)}
				</span>
			</div>
		),
		Footer: (props) => {
			const { data } = props;
			const total = data.reduce((acc, curr) => acc + curr._original.clean_event_cohort_event, 0);
			const totalPayout = data.reduce((acc, curr) => acc + curr._original.clean_event_cohort_amount, 0);
			return (
				<div style={{ display: 'flex', flexDirection: 'column', fontWeight: 600 }}>
					<span>{Humanize.intComma(total)}</span>
					<span style={{ color: `var(--green)` }}>{revenue_symbol}{Humanize.formatNumber(totalPayout)}</span>
				</div>
			);
		}
	},
	{
		Header: generateHeader({ title1: 'REATTRIBUTED COHORT EVENT CLEAN (TOTAL)', title2: 'REVENUE', color: 'green' }),
		id: 'clean_event_cohort_event',
		accessor: 'clean_event_cohort_event',
		cast: 'string',
		Cell: (props) => (
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<span>
					{`${props.original.clean_event_cohort_event_r} `}
				</span>
				<span style={{ color: 'var(--green)' }}>
					{revenue_symbol}{Humanize.formatNumber((props.original.clean_reattributed_amount))}
				</span>
			</div>
		),
		Footer: generateFooter({
			field1: 'clean_event_cohort_event_r',
			field2: 'clean_reattributed_amount',
			color: 'green',
			field2Prefix: revenue_symbol
		})
	},
];

export const roasColumns = ({
	type,
	exchangeRate = 1.000,
	payout_symbol = '$',
	revenue_symbol = '$',
}) => {

	if (type === 'cohort') {
		return [
			...getCommonColumns(),
			{
				Header: 'TYPE',
				id: 'type',
				accessor: 'type',
				cast: 'string',
				Cell: (props) => props.original.campaign.type,
			},
			{
				Header: generateHeader({ title1: 'Install COHORT Revenue', title2: 'Clean', color: 'green' }),
				id: 'roas_revenue_cohort_total',
				accessor: 'roas_revenue_cohort_total',
				cast: 'string',
				Cell: (props) => (
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<span>{revenue_symbol}{Humanize.formatNumber(props.original.roas_revenue_cohort_total - props.original.roas_revenue_cohort_total_r)}</span>
						<span style={{ color: 'var(--green)' }}>{revenue_symbol}{Humanize.formatNumber(props.original.roas_revenue_cohort_clean - props.original.roas_revenue_cohort_clean_r)}</span>
					</div>
				),
				Footer: (props) => {
					const { data } = props;
					const total = data.reduce((acc, curr) => acc + (curr._original.roas_revenue_cohort_total - curr._original.roas_revenue_cohort_total_r), 0);
					const totalPayout = data.reduce((acc, curr) => acc + (curr._original.roas_revenue_cohort_clean - curr._original.roas_revenue_cohort_clean_r), 0);
					return (
						<div style={{ display: 'flex', flexDirection: 'column', fontWeight: 600 }}>
							<span>{Humanize.intComma(total)}</span>
							<span style={{ color: `var(--green)` }}>${Humanize.formatNumber(totalPayout)}</span>
						</div>
					);
				}
			},
			{
				Header: generateHeader({ title1: 'REATTRIBUTED Install COHORT Revenue', title2: 'Clean', color: 'green' }),
				id: 'roas_revenue_cohort_total',
				accessor: 'roas_revenue_cohort_total',
				cast: 'string',
				Cell: (props) => (
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<span>{revenue_symbol}{Humanize.formatNumber(props.original.roas_revenue_cohort_total_r)}</span>
						<span style={{ color: 'var(--green)' }}>{revenue_symbol}{Humanize.formatNumber(props.original.roas_revenue_cohort_clean_r)}</span>
					</div>
				),
				Footer: generateFooter({
					field1: 'roas_revenue_cohort_total_r',
					field2: 'roas_revenue_cohort_clean_r',
					color: 'green',
					field1Prefix: revenue_symbol,
					field2Prefix: revenue_symbol,
				})
			},
			{
				Header: generateHeader({ title1: 'Install COHORT Spent', title2: 'Clean', color: 'green' }),
				id: 'roas_spent_cohort_total',
				accessor: 'roas_spent_cohort_total',
				cast: 'string',
				Cell: (props) => (
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<span>{payout_symbol}{Humanize.formatNumber(props.original.roas_spent_cohort_total - props.original.roas_spent_cohort_total_r)}</span>
						<span style={{ color: 'var(--green)' }}>{payout_symbol}{Humanize.formatNumber(props.original.roas_spent_cohort_clean - props.original.roas_spent_cohort_clean_r)}</span>
					</div>
				),
				Footer: (props) => {
					const { data } = props;
					const total = data.reduce((acc, curr) => acc + (curr._original.roas_spent_cohort_total - curr._original.roas_spent_cohort_total_r), 0);
					const totalPayout = data.reduce((acc, curr) => acc + (curr._original.roas_spent_cohort_clean - curr._original.roas_spent_cohort_clean_r), 0);
					return (
						<div style={{ display: 'flex', flexDirection: 'column', fontWeight: 600 }}>
							<span>{Humanize.intComma(total)}</span>
							<span style={{ color: `var(--green)` }}>${Humanize.formatNumber(totalPayout)}</span>
						</div>
					);
				}
			},
			{
				Header: generateHeader({ title1: 'REATTRIBUTED Install COHORT Spent', title2: 'Clean', color: 'green' }),
				id: 'roas_spent_cohort_total',
				accessor: 'roas_spent_cohort_total',
				cast: 'string',
				Cell: (props) => (
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<span>{payout_symbol}{Humanize.formatNumber(props.original.roas_spent_cohort_total_r)}</span>
						<span style={{ color: 'var(--green)' }}>{payout_symbol}{Humanize.formatNumber(props.original.roas_spent_cohort_clean_r)}</span>
					</div>
				),
				Footer: generateFooter({
					field1: 'roas_spent_cohort_total_r',
					field2: 'roas_spent_cohort_clean_r',
					color: 'green',
					field1Prefix: payout_symbol,
					field2Prefix: payout_symbol,
				})
			},
			{
				Header: generateHeader({ title1: 'Install COHORT Roas', title2: 'Clean', color: 'green' }),
				id: 'roas_total_cohort',
				accessor: 'roas_total_cohort',
				cast: 'string',
				Cell: (props) => {
					const d = props.original;
					const install_clean_rev = d.roas_revenue_cohort_clean - d.roas_revenue_cohort_clean_r;
					const install_spt = d.roas_spent_cohort_total - d.roas_spent_cohort_total_r;
					const install_clean_spt = d.roas_spent_cohort_clean - d.roas_spent_cohort_clean_r;

					return (
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							<span>{Humanize.formatNumber(safeDivide(install_clean_rev, install_spt), 2)}</span>
							<span style={{ color: 'var(--green)' }}>{Humanize.formatNumber(safeDivide(install_clean_rev, install_clean_spt), 2)}</span>
						</div>
					)
				},
				Footer: ({ data }) => {
					const roas_spent_total = data.reduce((acc, curr) => acc + (curr._original.roas_spent_cohort_total - curr._original.roas_spent_cohort_total_r), 0);
					const roas_revenue_clean = data.reduce((acc, curr) => acc + (curr._original.roas_revenue_cohort_clean - curr._original.roas_revenue_cohort_clean_r), 0);
					const roas_spent_clean = data.reduce((acc, curr) => acc + (curr._original.roas_spent_cohort_clean - curr._original.roas_spent_cohort_clean_r), 0);

					const roas_total = safeDivide(roas_revenue_clean, (roas_spent_total * exchangeRate));
					const roas_total_clean = safeDivide(roas_revenue_clean, (roas_spent_clean * exchangeRate))

					return (
						<div style={{ display: 'flex', flexDirection: 'column', fontWeight: 600 }}>
							<span>{Humanize.intComma(roas_total, 2)}</span>
							<span style={{ color: 'var(--green)' }}>{Humanize.formatNumber(roas_total_clean, 2)}</span>
						</div>
					);
				}
			},
			{
				Header: generateHeader({ title1: 'REATTRIBUTED Install COHORT Roas', title2: 'Clean', color: 'green' }),
				id: 'roas_total_cohort',
				accessor: 'roas_total_cohort',
				cast: 'string',
				Cell: (props) => {
					const d = props.original;
					const install_clean_rev = d.roas_revenue_cohort_clean_r;
					const install_spt = d.roas_spent_cohort_total_r;
					const install_clean_spt = d.roas_spent_cohort_clean_r;

					return (
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							<span>{Humanize.formatNumber(safeDivide(install_clean_rev, install_spt), 2)}</span>
							<span style={{ color: 'var(--green)' }}>{Humanize.formatNumber(safeDivide(install_clean_rev, install_clean_spt), 2)}</span>
						</div>
					)
				},
				Footer: ({ data }) => {
					const roas_spent_total = data.reduce((acc, curr) => acc + curr._original.roas_spent_cohort_total_r, 0);

					const roas_revenue_clean = data.reduce((acc, curr) => acc + curr._original.roas_revenue_cohort_clean_r, 0);
					const roas_spent_clean = data.reduce((acc, curr) => acc + curr._original.roas_spent_cohort_clean_r, 0);

					const roas_total = safeDivide(roas_revenue_clean, (roas_spent_total * exchangeRate));
					const roas_total_clean = safeDivide(roas_revenue_clean, (roas_spent_clean * exchangeRate))

					return (
						<div style={{ display: 'flex', flexDirection: 'column', fontWeight: 600 }}>
							<span>{Humanize.intComma(roas_total, 2)}</span>
							<span style={{ color: 'var(--green)' }}>{Humanize.formatNumber(roas_total_clean, 2)}</span>
						</div>
					);
				}
			},
		];
	}

	if (type === 'cohort_event') {
		return [
			...getCommonColumns(),
			{
				Header: 'TYPE',
				id: 'type',
				accessor: 'type',
				cast: 'string',
				Cell: (props) => props.original.campaign.type,
			},
			{
				Header: generateHeader({ title1: 'EVENT COHORT Revenue', title2: 'Clean', color: 'green' }),
				id: 'roas_revenue_event_cohort_total',
				accessor: 'roas_revenue_event_cohort_total',
				cast: 'string',
				Cell: (props) => (
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<span>{revenue_symbol}{Humanize.formatNumber(props.original.roas_revenue_event_cohort_total - props.original.roas_revenue_event_cohort_total_r)}</span>
						<span style={{ color: 'var(--green)' }}>{revenue_symbol}{Humanize.formatNumber(props.original.roas_revenue_event_cohort_clean - props.original.roas_revenue_event_cohort_clean_r)}</span>
					</div>
				),
				Footer: (props) => {
					const { data } = props;
					const total = data.reduce((acc, curr) => acc + (curr._original.roas_revenue_event_cohort_total - curr._original.roas_revenue_event_cohort_total_r), 0);
					const totalPayout = data.reduce((acc, curr) => acc + (curr._original.roas_revenue_event_cohort_clean - curr._original.roas_revenue_event_cohort_clean_r), 0);
					return (
						<div style={{ display: 'flex', flexDirection: 'column', fontWeight: 600 }}>
							<span>{Humanize.intComma(total)}</span>
							<span style={{ color: `var(--green)` }}>${Humanize.formatNumber(totalPayout)}</span>
						</div>
					);
				}
			},
			{
				Header: generateHeader({ title1: 'REATTRIBUTED EVENT COHORT Revenue', title2: 'Clean', color: 'green' }),
				id: 'roas_revenue_event_cohort_total',
				accessor: 'roas_revenue_event_cohort_total',
				cast: 'string',
				Cell: (props) => (
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<span>{revenue_symbol}{Humanize.formatNumber(props.original.roas_revenue_event_cohort_total_r)}</span>
						<span style={{ color: 'var(--green)' }}>{revenue_symbol}{Humanize.formatNumber(props.original.roas_revenue_event_cohort_clean_r)}</span>
					</div>
				),
				Footer: generateFooter({
					field1: 'roas_revenue_event_cohort_total_r',
					field2: 'roas_revenue_event_cohort_clean_r',
					color: 'green',
					field1Prefix: revenue_symbol,
					field2Prefix: revenue_symbol,
				})
			},
			{
				Header: generateHeader({ title1: 'EVENT COHORT Spent', title2: 'Clean', color: 'green' }),
				id: 'roas_spent_event_cohort_total',
				accessor: 'roas_spent_event_cohort_total',
				cast: 'string',
				Cell: (props) => (
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<span>{payout_symbol}{Humanize.formatNumber(props.original.roas_spent_event_cohort_total - props.original.roas_spent_event_cohort_total_r)}</span>
						<span style={{ color: 'var(--green)' }}>{payout_symbol}{Humanize.formatNumber(props.original.roas_spent_event_cohort_clean - props.original.roas_spent_event_cohort_clean_r)}</span>
					</div>
				),
				Footer: (props) => {
					const { data } = props;
					const total = data.reduce((acc, curr) => acc + (curr._original.roas_spent_event_cohort_total - curr._original.roas_spent_event_cohort_total_r), 0);
					const totalPayout = data.reduce((acc, curr) => acc + (curr._original.roas_spent_event_cohort_clean - curr._original.roas_spent_event_cohort_clean_r), 0);
					return (
						<div style={{ display: 'flex', flexDirection: 'column', fontWeight: 600 }}>
							<span>{Humanize.intComma(total)}</span>
							<span style={{ color: `var(--green)` }}>${Humanize.formatNumber(totalPayout)}</span>
						</div>
					);
				}
			},
			{
				Header: generateHeader({ title1: 'REATTRIBUTED EVENT COHORT Spent', title2: 'Clean', color: 'green' }),
				id: 'roas_spent_event_cohort_total',
				accessor: 'roas_spent_event_cohort_total',
				cast: 'string',
				Cell: (props) => (
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<span>{payout_symbol}{Humanize.formatNumber(props.original.roas_spent_event_cohort_total_r)}</span>
						<span style={{ color: 'var(--green)' }}>{payout_symbol}{Humanize.formatNumber(props.original.roas_spent_event_cohort_clean_r)}</span>
					</div>
				),
				Footer: generateFooter({
					field1: 'roas_spent_event_cohort_total_r',
					field2: 'roas_spent_event_cohort_clean_r',
					color: 'green',
					field1Prefix: payout_symbol,
					field2Prefix: payout_symbol,
				})
			},
			{
				Header: generateHeader({ title1: 'EVENT COHORT Roas', title2: 'Clean', color: 'green' }),
				id: 'roas_total_event_cohort',
				accessor: 'roas_total_event_cohort',
				cast: 'string',
				Cell: (props) => {
					const d = props.original;
					const install_clean_rev = d.roas_revenue_event_cohort_clean - d.roas_revenue_event_cohort_clean_r;
					const install_spt = d.roas_spent_event_cohort_total - d.roas_spent_event_cohort_total_r;
					const install_clean_spt = d.roas_spent_event_cohort_clean - d.roas_spent_event_cohort_clean_r;

					return (
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							<span>{Humanize.formatNumber(safeDivide(install_clean_rev, install_spt), 2)}</span>
							<span style={{ color: 'var(--green)' }}>{Humanize.formatNumber(safeDivide(install_clean_rev, install_clean_spt), 2)}</span>
						</div>
					)
				},
				Footer: ({ data }) => {
					const roas_spent_total = data.reduce((acc, curr) => acc + (curr._original.roas_spent_event_cohort_total - curr._original.roas_spent_event_cohort_total_r), 0);
					const roas_revenue_clean = data.reduce((acc, curr) => acc + (curr._original.roas_revenue_event_cohort_clean - curr._original.roas_revenue_event_cohort_clean_r), 0);
					const roas_spent_clean = data.reduce((acc, curr) => acc + (curr._original.roas_spent_event_cohort_clean - curr._original.roas_spent_event_cohort_clean_r), 0);

					const roas_total = safeDivide(roas_revenue_clean, (roas_spent_total * exchangeRate))
					const roas_total_clean = safeDivide(roas_revenue_clean, (roas_spent_clean * exchangeRate))

					return (
						<div style={{ display: 'flex', flexDirection: 'column', fontWeight: 600 }}>
							<span>{Humanize.intComma(roas_total, 2)}</span>
							<span style={{ color: 'var(--green)' }}>{Humanize.formatNumber(roas_total_clean, 2)}</span>
						</div>
					);
				}
			},
			{
				Header: generateHeader({ title1: 'REATTRIBUTED EVENT COHORT Roas', title2: 'Clean', color: 'green' }),
				id: 'roas_total_event_cohort',
				accessor: 'roas_total_event_cohort',
				cast: 'string',
				Cell: (props) => {
					const d = props.original;
					const install_clean_rev = d.roas_revenue_event_cohort_clean_r;
					const install_spt = d.roas_spent_event_cohort_total_r;
					const install_clean_spt = d.roas_spent_event_cohort_clean_r;

					return (
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							<span>{Humanize.formatNumber(safeDivide(install_clean_rev, install_spt), 2)}</span>
							<span style={{ color: 'var(--green)' }}>{Humanize.formatNumber(safeDivide(install_clean_rev, install_clean_spt), 2)}</span>
						</div>
					)
				},
				Footer: ({ data }) => {
					const roas_spent_total = data.reduce((acc, curr) => acc + curr._original.roas_spent_event_cohort_total_r, 0);
					const roas_revenue_clean = data.reduce((acc, curr) => acc + curr._original.roas_revenue_event_cohort_clean_r, 0);
					const roas_spent_clean = data.reduce((acc, curr) => acc + curr._original.roas_spent_event_cohort_clean_r, 0);

					const roas_total = safeDivide(roas_revenue_clean, (roas_spent_total * exchangeRate));
					const roas_total_clean = safeDivide(roas_revenue_clean, (roas_spent_clean * exchangeRate));

					return (
						<div style={{ display: 'flex', flexDirection: 'column', fontWeight: 600 }}>
							<span>{Humanize.intComma(roas_total, 2)}</span>
							<span style={{ color: 'var(--green)' }}>{Humanize.formatNumber(roas_total_clean, 2)}</span>
						</div>
					);
				}
			},
		];
	}


	return [
		...getCommonColumns(),
		{
			Header: 'TYPE',
			id: 'type',
			accessor: 'type',
			cast: 'string',
			Cell: (props) => props.original.campaign.type,
		},
		{
			Header: generateHeader({ title1: 'Revenue', title2: 'Clean', color: 'green' }),
			id: 'roas_revenue_total',
			accessor: 'roas_revenue_total',
			cast: 'string',
			Cell: (props) => (
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<span>{revenue_symbol}{Humanize.formatNumber(props.original.roas_revenue_total)}</span>
					<span style={{ color: 'var(--green)' }}>{revenue_symbol}{Humanize.formatNumber(props.original.roas_revenue_clean)}</span>
				</div>
			),
			Footer: generateFooter({
				field1: 'roas_revenue_total',
				field2: 'roas_revenue_clean',
				color: 'green',
				field1Prefix: revenue_symbol,
				field2Prefix: revenue_symbol,
			})
		},
		{
			Header: generateHeader({ title1: 'Spent', title2: 'Clean', color: 'green' }),
			id: 'roas_spent_total',
			accessor: 'roas_spent_total',
			cast: 'string',
			Cell: (props) => (
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<span>{payout_symbol}{Humanize.formatNumber(props.original.roas_spent_total)}</span>
					<span style={{ color: 'var(--green)' }}>{payout_symbol}{Humanize.formatNumber(props.original.roas_spent_clean)}</span>
				</div>
			),
			Footer: generateFooter({
				field1: 'roas_spent_total',
				field2: 'roas_spent_clean',
				color: 'green',
				field1Prefix: payout_symbol,
				field2Prefix: payout_symbol,
			})
		},
		{
			Header: generateHeader({ title1: 'Roas', title2: 'Clean', color: 'green' }),
			id: 'roas_total',
			accessor: 'roas_total',
			cast: 'string',
			Cell: (props) => {
				const d = props.original;
				const roas_total = safeDivide(d.roas_revenue_clean, (d.roas_spent_total * exchangeRate));
				const roas_total_clean = safeDivide(d.roas_revenue_clean, (d.roas_spent_clean * exchangeRate));

				return (
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<span>{Humanize.formatNumber(roas_total, 2)}</span>
						<span style={{ color: 'var(--green)' }}>{Humanize.formatNumber(roas_total_clean, 2)}</span>
					</div>
				)
			},
			Footer: ({ data }) => {
				const roas_spent_total = data.reduce((acc, curr) => acc + curr._original.roas_spent_total, 0);

				const roas_revenue_clean = data.reduce((acc, curr) => acc + curr._original.roas_revenue_clean, 0);
				const roas_spent_clean = data.reduce((acc, curr) => acc + curr._original.roas_spent_clean, 0);

				const roas_total = safeDivide(roas_revenue_clean, (roas_spent_total * exchangeRate));
				const roas_total_clean = safeDivide(roas_revenue_clean, (roas_spent_clean * exchangeRate));

				return (
					<div style={{ display: 'flex', flexDirection: 'column', fontWeight: 600 }}>
						<span>{Humanize.intComma(roas_total, 2)}</span>
						<span style={{ color: 'var(--green)' }}>{Humanize.formatNumber(roas_total_clean, 2)}</span>
					</div>
				);
			}
		},
	];
};