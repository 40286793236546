import React from 'react';
import { Modal } from 'interceptd-ui';

import './styles/RulesetConfirmationModal.css';

class RulesetConfirmationModal extends React.PureComponent {
	render() {
		const { open, onClose, onAccept, children } = this.props;

		return (
			<Modal
				open={open}
				title="These campaigns will be affected by this change"
				onCancel={onClose}
				onAccept={onAccept}>
				<div className="ruleset-conf">
					<div className="ruleset-conf-content">
						{open && children}
					</div>
				</div>
			</Modal>
		)
	}
}

export default RulesetConfirmationModal;