export const colors = [
	'#230060',
	'#5C6BC0',
	'#B9CDE1',
	'#8d9aa7',
	'#6d7782',
	'#036FDB',
	'#55C4C2',
	'#ffc000',
	'#F3B071',
	'#ED6D6D',
	'#EEF5FC',
];