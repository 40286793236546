import moment from 'moment';
import { getUnixTime } from 'date-fns';

export const TransformOptions = (data, placeholder, iterator = null) => {
	const transformed = [
		{
			text: placeholder,
			value: 0,
			placeholder: true,
		},
	]
	Object.keys(data).map((value) => {
		let newObject;

		if (iterator) {
			newObject = iterator(data, value);
		} else {
			newObject = {
				text: data[value],
				value
			}
		}

		return transformed.push(newObject);
	});
	return transformed;
}

export const DashedtoAbbr = str => str.split('-').map(i => i[0].toUpperCase()).join('');

export const ToFloat = number => {
	if (
		!isNaN(number) &&
		isFinite(number) &&
		number % 1 !== 0
	)
		return number.toFixed(2);
	return number;
};

export const ToParam = (payload, encode = true) => {
	return Object.keys(payload).reduce((result, k) => {
		if (payload[k] !== null && payload[k] !== undefined && payload[k] !== '')
			result.push((encode ? encodeURIComponent(k) : k) + '=' + (encode ? encodeURIComponent(payload[k]) : payload[k]));
		return result;
	}, []).join('&');
}

export function getParameterByName(name, url) {
	if (!url) url = window.location.href;
	name = name.replace(/[[\]]/g, '\\$&');
	const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
		results = regex.exec(url);
	if (!results) return null;
	if (!results[2]) return '';
	return decodeURIComponent(results[2]);
}

export const enumerateDaysBetweenDates = (startDate, endDate, format, dayMargin = 2) => {
	const dates = [];
	const currDate = moment.utc(startDate, format).subtract(dayMargin, 'days').startOf('day');
	const lastDate = moment.utc(endDate, format).add(dayMargin, 'days').startOf('day');

	dates.push(currDate.format(format));
	while (currDate.add(1, 'days').diff(lastDate) <= 0) {
		dates.push(currDate.format(format));
	}

	return dates;
}

export const fillHours = data => {
	const hours = [];
	for (let i = 0; i < 24; i++)
		hours.push({ x: i, y: 0 })
	return hours.map(h => {
		const d = data.filter(d => d.x === h.x)[0];
		return ({
			...h,
			y: d ? d.y : h.y,
		})
	})
}
export const getTimestamp = (date, isEnd = false) => {
	if (!(date instanceof Date)) {
		throw new Error('Missing required param: date');
	}

	const newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
	newDate['setUTCHours'](isEnd ? 23 : 0);
	newDate['setUTCMinutes'](isEnd ? 59 : 0);
	newDate['setUTCSeconds'](isEnd ? 59 : 0);

	return getUnixTime(newDate);
}