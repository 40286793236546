import React from 'react';
import axios from 'axios';
import Humanize from 'humanize-plus';
import values from 'lodash/values';
import sortBy from 'lodash/sortBy';
import Papa from 'papaparse';
import {
	Box,
	Icon,
	Button,
	DatePicker,
} from 'interceptd-ui';

import Detail from './Detail';

import Api from '../../services/api';
import Local from '../../services/localStorage';
import Settings from '../../services/settings';

import { enumerateDaysBetweenDates, getTimestamp } from '../../utils/transform';
import { downloadFile } from '../../utils/file';
import { getInitialDateFilter } from '../../utils/misc';

import './styles/Savings.css';

const StatsMap = {
	campaign_id: 0,
	clean_amount: 0,
	clean_click_count: 0,
	clean_event_count: 0,
	clean_install_count: 0,
	clean_reattributed_amount: 0,
	clean_reattributed_event_count: 0,
	date: 0,
	flagged_amount: 0,
	flagged_event_count: 0,
	flagged_install_count: 0,
	flagged_reattributed_amount: 0,
	flagged_reattributed_event_count: 0,
	rejected_amount: 0,
	rejected_click_count: 0,
	rejected_event_count: 0,
	rejected_install_count: 0,
	rejected_reattributed_amount: 0,
	rejected_reattributed_event_count: 0,
	flagged_cost: 0,
	source_id: 0,
}

class Savings extends React.PureComponent {
	state = {
		error: false,

		filters: getInitialDateFilter(),

		archivedCampaigns: Settings.get('archivedCampaigns', 'account') || [],

		fetchingCampaigns: true,
		campaigns: [],
		partners: [],

		fetchingStats: true,
		refetchingStats: true,
		totalStats: [],
		totalSavings: [],
		totalSaving: 0,
		dailySavings: [],

		csv: [],
	}

	componentDidMount = () => {
		this.fetchCampaigns()
	}

	fetchCampaigns = () => {
		const { archivedCampaigns } = this.state;

		this.setState({ fetchingCampaigns: true });

		axios.all([
			Api.getLeoparCampaigns(),
			Api.getPartners({ page: 1, size: 1000 }),
		])
			.then(axios.spread((campaignsRes, partnersRes) => {
				this.setState({
					error: false,
					fetchingCampaigns: false,
					campaigns: sortBy(campaignsRes.data.data.filter((c) => c.application_type !== 'leancheck').filter(c => archivedCampaigns.indexOf(c.id) === -1).map(c => ({ ...c, sources: sortBy(c.sources.map(s => ({ ...s, campaign: c })), 'id').reverse() })), 'id').reverse(),
					partners: partnersRes.data.data,

					csv: sortBy(campaignsRes.data.data.filter(c => archivedCampaigns.indexOf(c.id) === -1).map(c => ({ ...c, sources: sortBy(c.sources.map(s => ({ ...s, campaign: c })), 'id').reverse() })), 'id').reverse().flatMap(c => c.sources).filter(s => s.campaign.type === 'CPI' || (s.campaign.type === 'CPA' && s.event_payouts)).map(s => ({
						'Campaign ID': s.campaign.id,
						'Campaign Name': s.campaign.name,
						'Campaign Type': s.campaign.type,
						'Source ID': s.id,
						'Source Name': s.name,

						'Clean Clicks': 0,
						'Flagged Clicks': 0,
						'Rejected Clicks': 0,
						'Total Clicks': 0,

						'Clean Installs': 0,
						'Flagged Installs': 0,
						'Rejected Installs': 0,
						'Total Installs': 0,

						'Payout Event': s.campaign.type === 'CPA' ? Object.keys(s.event_payouts)[0] : '',
						'Payout': s.campaign.type === 'CPA' ? Object.values(s.event_payouts)[0] : s.bid,
						'Clean Events': 0,
						'Flagged Events': 0,
						'Rejected Events': 0,
						'Total Events': 0,

						'Click Savings': 0,
						'Install Savings': 0,
						'Event Savings': 0,
						'Total Savings': 0,
					}))
				}, this.fetchStats)
			})).catch(() => {
				this.setState({
					error: true,
					fetchingCampaigns: false,
				})
			});
	}

	fetchStats = () => {
		const { filters, campaigns, partners, csv } = this.state;

		this.setState({
			refetchingStats: true,
		});

		const sources = campaigns.flatMap(c => c.sources.map(s => ({
			...s,
			partner: partners.find(partner => partner.id === s.partner) || s.partner,
			app: c.app_info,
		})));

		if (sources.length === 0) {
			return 	this.setState({
				refetchingStats: false,
				fetchingStats: false,
			});
		}

		const query = {
			source_ids: sources.map(s => s.id),
			ts_start: getTimestamp(filters.start),
			ts_end: getTimestamp(filters.end, true),
		};

		axios.all([
			Api.getLeoparInstallAndEventDailyStats(query),
			Api.getLeoparClickDailyStats(query),
			Api.getEventDailyPerformance(query),
			Api.getDailyImpressionStats(query),
		])
			.then(axios.spread((installRes, clickRes, eventRes, impressionCount) => {
				const data = [
					...installRes.data.data,
					...clickRes.data.data,
					...eventRes.data.data,
					...impressionCount.data.data,
				];

				const totalStats = values(data.reduce((acc, cur) => {
					const newAcc = { ...acc };

					const key = `${cur.date}-${cur.source_id}-${cur.event_name || cur.creative_id || ''}`;

					newAcc[key] = {
						...(newAcc[key] || StatsMap),
						...cur,
					}

					return newAcc;
				}, {}));

				const totalSavings = sources.map(source => {
					const stats = totalStats.filter(stat => stat.source_id === source.id);
					let click_savings = 0,
							install_savings = 0,
							event_savings = 0,
							impression_savings = 0;

					if ( source.campaign.type === 'CPA' ) {
						const event_payouts = source.event_payouts || {};

						click_savings = stats.reduce((acc, cur) => {
							if ( !cur.event_name || !event_payouts[cur.event_name] || !cur.clean_click_count )
								return acc;
							return (acc + ((+cur.rejected_click_count * ( +cur.clean_event_count + +cur.rejected_event_count + +cur.flagged_event_count ) / +cur.clean_click_count) * +event_payouts[cur.event_name]))
						}, click_savings)

						install_savings = stats.reduce((acc, cur) => {
							if ( !cur.event_name || !event_payouts[cur.event_name] || !cur.flagged_install_count || !cur.clean_install_count )
								return acc;
							return (acc + ((+cur.rejected_install_count * ( +cur.clean_event_count + +cur.rejected_event_count + +cur.flagged_event_count ) / (+cur.clean_install_count + +cur.flagged_install_count)) * +event_payouts[cur.event_name]))
						}, install_savings)

						event_savings = stats.reduce((acc, cur) => {
							if ( !cur.event_name || !event_payouts[cur.event_name] )
								return acc;
							return (acc + (+cur.rejected_event_count * +event_payouts[cur.event_name]))
						}, event_savings)
					} else if (source.campaign.type === 'CPM') {
						impression_savings = stats.reduce((acc, cur) => {
							if ( !cur.creative_id || !cur.flagged_cost )
								return acc;
							return (acc + (cur.flagged_cost === 0 ? 0 : cur.flagged_cost / 1000));
						}, 0)
					} else {
						click_savings = stats.reduce((acc, cur) => {
							if ( !cur.clean_click_count )
								return acc;
							return (acc + ((+cur.rejected_click_count * ( +cur.clean_install_count + +cur.rejected_install_count + +cur.flagged_install_count ) / +cur.clean_click_count) * +source.bid))
						}, click_savings)

						install_savings = stats.reduce((acc, cur) => {
							return (acc + (+cur.rejected_install_count * +source.bid))
						}, install_savings)
					}

					return ({
						source: source,
						click_savings,
						install_savings,
						event_savings,
						impression_savings,
					})
				})

				const newCSV = csv.map(source => {
					const stats = totalStats.filter(stat => stat.source_id === source['Source ID']);

					let clean_clicks = 0,
							flagged_clicks = 0,
							rejected_clicks = 0,
							total_clicks = 0,

							clean_installs = 0,
							flagged_installs = 0,
							rejected_installs = 0,
							total_installs = 0,

							clean_events = 0,
							flagged_events = 0,
							rejected_events = 0,
							total_events = 0,

							click_savings = 0,
							install_savings = 0,
							event_savings = 0;

					clean_clicks = stats.reduce((acc, cur) => acc + +(cur.clean_click_count || 0), clean_clicks)
					flagged_clicks = stats.reduce((acc, cur) => acc + +(cur.flagged_click_count || 0), flagged_clicks)
					rejected_clicks = stats.reduce((acc, cur) => acc + +(cur.rejected_click_count || 0), rejected_clicks)
					total_clicks = clean_clicks + flagged_clicks + rejected_clicks;

					clean_installs = stats.reduce((acc, cur) => acc + +(cur.clean_install_count || 0), clean_installs)
					flagged_installs = stats.reduce((acc, cur) => acc + +(cur.flagged_install_count || 0), flagged_installs)
					rejected_installs = stats.reduce((acc, cur) => acc + +(cur.rejected_install_count || 0), rejected_installs)
					total_installs = clean_installs + flagged_installs + rejected_installs;

					clean_events = stats.reduce((acc, cur) => {
						if ( !cur.event_name || source['Payout Event'] !== cur.event_name )
							return acc;
						return acc + (+cur.clean_event_count)
					}, clean_events)
					flagged_events = stats.reduce((acc, cur) => {
						if ( !cur.event_name || source['Payout Event'] !== cur.event_name )
							return acc;
						return acc + (+cur.flagged_event_count)
					}, flagged_events)
					rejected_events = stats.reduce((acc, cur) => {
						if ( !cur.event_name || source['Payout Event'] !== cur.event_name )
							return acc;
						return acc + (+cur.rejected_event_count)
					}, rejected_events)

					if ( source['Campaign Type'] === 'CPA' ) {
						click_savings = stats.reduce((acc, cur) => {
							if ( !cur.event_name || source['Payout Event'] !== cur.event_name || !cur.clean_click_count )
								return acc;
							return (acc + ((+cur.rejected_click_count * ( +cur.clean_event_count + +cur.rejected_event_count + +cur.flagged_event_count ) / +cur.clean_click_count) * source['Payout']))
						}, click_savings)

						install_savings = stats.reduce((acc, cur) => {
							if ( !cur.event_name || source['Payout Event'] !== cur.event_name || !cur.flagged_install_count || !cur.clean_install_count )
								return acc;
							return (acc + ((+cur.rejected_install_count * ( +cur.clean_event_count + +cur.rejected_event_count + +cur.flagged_event_count ) / (+cur.clean_install_count + +cur.flagged_install_count)) * source['Payout']))
						}, install_savings)

						event_savings = stats.reduce((acc, cur) => {
							if ( !cur.event_name || source['Payout Event'] !== cur.event_name )
								return acc;
							return (acc + (+cur.rejected_event_count * +source['Payout']))
						}, event_savings)
					} else {
						click_savings = stats.reduce((acc, cur) => {
							if ( !cur.clean_click_count )
								return acc;
							return (acc + ((+cur.rejected_click_count * ( +cur.clean_install_count + +cur.rejected_install_count + +cur.flagged_install_count ) / +cur.clean_click_count) * +source['Payout']))
						}, click_savings)

						install_savings = stats.reduce((acc, cur) => {
							return (acc + (+cur.rejected_install_count * +source['Payout']))
						}, install_savings)
					}

					return ({
						...source,

						'Clean Clicks': clean_clicks,
						'Flagged Clicks': flagged_clicks,
						'Rejected Clicks': rejected_clicks,
						'Total Clicks': total_clicks,

						'Clean Installs': clean_installs,
						'Flagged Installs': flagged_installs,
						'Rejected Installs': rejected_installs,
						'Total Installs': total_installs,

						'Clean Events': clean_events,
						'Flagged Events': flagged_events,
						'Rejected Events': rejected_events,
						'Total Events': total_events,

						'Click Savings': click_savings,
						'Install Savings': install_savings,
						'Event Savings': event_savings,
						'Total Savings': click_savings + install_savings + event_savings,
					})
				})

				const dailySavings = enumerateDaysBetweenDates(filters.start, filters.end, 0).map(d => ({
					x: d,
					y: 0
				})).map(date => {
					const stats = totalStats.filter(stat => +stat.date === +date.x);

					let click_savings = 0,
							install_savings = 0,
							event_savings = 0,
							impression_savings = 0;

					stats.forEach(stat => {
						const source = sources.find(source => +source.id === +stat.source_id);

						if ( source.campaign.type === 'CPA' ) {
							const event_payouts = source.event_payouts || {};

							click_savings += (
								( !stat.event_name || !event_payouts[stat.event_name] || !stat.clean_click_count ) ? 0 : 
								((+stat.rejected_click_count * ( +stat.clean_event_count + +stat.rejected_event_count + +stat.flagged_event_count ) / +stat.clean_click_count) * +event_payouts[stat.event_name])
							);

							install_savings += (
								( !stat.event_name || !event_payouts[stat.event_name] || !stat.flagged_install_count || !stat.clean_install_count ) ? 0 :
								((+stat.rejected_install_count * ( +stat.clean_event_count + +stat.rejected_event_count + +stat.flagged_event_count ) / (+stat.clean_install_count + +stat.flagged_install_count)) * +event_payouts[stat.event_name])
							)

							event_savings += (
								( !stat.event_name || !event_payouts[stat.event_name] ) ? 0 :
								(+stat.rejected_event_count * +event_payouts[stat.event_name])
							)
						} else if ( source.campaign.type === 'CPM' ) {
							impression_savings += (stat.flagged_cost === 0 ? 0 : stat.flagged_cost / 1000);
						} else {
							click_savings += (
								( !stat.clean_click_count ) ? 0 :
								((+stat.rejected_click_count * ( +stat.clean_install_count + +stat.rejected_install_count + +stat.flagged_install_count ) / +stat.clean_click_count) * +source.bid)
							)

							install_savings += (
								(+stat.rejected_install_count * +source.bid)
							)
						}
					})

					return ({
						x: +date.x * 1000,
						y: (click_savings + install_savings + event_savings + impression_savings)
					})
				})

				this.setState({
					error: false,
					fetchingStats: false,
					refetchingStats: false,
					totalStats,
					totalSavings,
					totalSaving: totalSavings.reduce((acc, cur) => (acc + cur.click_savings + cur.install_savings + cur.event_savings + cur.impression_savings), 0),
					dailySavings,
					csv: newCSV,
				})
			}))
			.catch(res => {
				this.setState({
					fetchingStats: false,
					refetchingStats: false,
					error: true,
				})
			})
	}

	refresh = () => {
		this.fetchCampaigns()
	}

	handleCSVDateRange = ({ from, to }) => {
		this.setState({
			ts_start: from,
			ts_end: to,
		}, this.fetchStats)
	}

	downloadCSV = () => {
		const { filters, csv } = this.state;
		const me = Local.getItem('me');
		const name = me.email.split('@')[1].split('.')[0].replace(/\b(\w)/g, s => s.toUpperCase());
		downloadFile(Papa.unparse(csv), `${name}_${filters.start}_${filters.end}_Savings.csv`);
	}

	render() {
		const {
			error,
			filters,
			fetchingStats,
			refetchingStats,
			totalSavings,
			totalSaving,
			dailySavings,
			campaigns,
			partners,
		} = this.state;

		return (
			<div className={`
				savings is-export open
				${fetchingStats || refetchingStats ? 'loading' : ''}
				${error ? 'error' : ''}
			`}>
				<div className="savings-total" onClick={this.toggleOpen}>
					<span>Estimated Savings</span>
					<b>${Humanize.formatNumber(totalSaving, 2)}</b>
					<Icon i="chevron-right" size={18} />
				</div>

				<Box
					className="savings-detail is-export-csv"
					title="Export as CSV">
					<DatePicker
						value={{
							from: filters.start,
							to: filters.end,
						}}
						onChange={this.handleCSVDateRange} />
					<Button loading={fetchingStats || refetchingStats} onClick={this.downloadCSV}>Download as CSV</Button>
				</Box>

				<Detail
					open={true}
					campaigns={campaigns}
					partners={partners}
					totalSaving={totalSaving}
					totalSavings={totalSavings}
					dailySavings={dailySavings}
					loading={fetchingStats} />
			</div>
		)
	}
}

export default Savings;
