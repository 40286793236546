import React from 'react';
import { toast } from 'react-toastify';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import { Loader, NoData } from 'interceptd-ui';

import Table from '../../components/Table';

import { columns } from './AppSettingsColumns';
import AppBox from './AppBox';

import Api from '../../services/api';
import Settings from '../../services/settings';

import './styles/AppSettings.css';

const CampaignProps = {
	'click_lookback_window': 'number',
	'install_lookback_window': 'number',
	'target_app_version': 'string',
	'target_sdk_version': 'string',
	'target_os_version': 'string',
};

class AppSettings extends React.Component {
	state = {
		fetching: true,
		error: false,
		apps: [],
		saving: false,
		appSettings: Settings.get('appSettings', 'account') || {},
		expandedState: {},
	}

	componentDidMount = () => {
		this.fetchData();
	}

	fetchData = () => {
		this.setState({ fetching: true });

		const { location } = this.props;
		const appId = location?.state?.app;

		Api.getLeoparCampaigns()
			.then(response => {
				const apps = sortBy(response.data.data, 'id').filter((c) => c.application_type !== 'leancheck').reverse().reduce((apps, campaign) => {
					let newApps = [ ...apps ];

					if ( newApps.some(app => app.id === campaign.app_info.id) ) {
						newApps = newApps.map(app => app.id === campaign.app_info.id ? {
							...app,
							campaigns: [
								...app.campaigns,
								campaign,
							]
						} : app)
					} else {
						newApps.push({
							...campaign.app_info,
							campaigns: [
								campaign
							]
						})
					}

					return newApps;
				}, []);
				const index = apps.findIndex(app => app.id === appId?.toString());
				let expandedState = {};
				console.log(apps);
				console.log(index);
				console.log(expandedState);
				if (index >= 0) {
					expandedState = {
						[index]: true,
					};
				}
				this.setState({
					error: false,
					fetching: false,
					apps,
					expandedState,
				});
			}).catch(() => {
				this.setState({
					error: true,
					fetching: false,
				})
			});
	}

	handleClose = (_, index) => {
		this.setState(state => ({
			expandedState: {},
		}))
	}

	handleExpand = (_, index) => {
		this.setState(state => ({
			expandedState: state.expandedState[index] ? {} : {
				[index]: true
			},
		}))
	}

	handleSettingsSave = (app, settings) => {
		Settings.set('appSettings', {
			...(Settings.get('appSettings', 'account') || {}),
			[app.id]: {
				...((Settings.get('appSettings', 'account') || {})?.[app.id] ?? {}),
				...settings,
			}
		}, 'account');
		this.setState({
			appSettings: Settings.get('appSettings', 'account') || {},
		})
	}

	handleSave = async (app, settings, updateCampaigns) => {
		this.setState({ saving: true });
		this.handleSettingsSave(app, settings);

		if ( updateCampaigns ) {
			if ( Object.keys(CampaignProps).some(k => settings[k] !== '') ) {
				await Promise.all(app.campaigns.map(async campaign => {
					return await Api.updateCampaign(campaign.id, Object.keys(CampaignProps).filter(k => settings[k] !== '').reduce((a, c) => {
						const newA = { ...a };
						newA[c] = CampaignProps[c] === 'string' ? `${settings[c]}` : +settings[c];
						return newA;
					}, {}))
				}))
			}
			if ( settings.kpis.length > 0 ) {
				await Promise.all(app.campaigns.map(async campaign => {
					return await Api.setCampaignKPIs(campaign.id, {
						events: settings.kpis.reduce((a, c, i) => {
							const newA = { ...a };
							newA[i + 1] = c;
							return newA;
						}, {})
					})
				}))
			}
		}

		this.setState({ saving: false });
		toast.success(updateCampaigns ? 'App settings saved and campaigns updated.' : 'App settings saved.');
	}

	renderAppSettings = props => {
		const { saving, appSettings } = this.state;
		const app = props.original;
		const { campaigns } = app;
		return (
			<AppBox
				key={app.id}
				app={app}
				settings={{
					...(appSettings[app.id] || {}),
					...(Object.keys(CampaignProps).filter(k => 
						campaigns.every(c => 
							c[k] && c[k] !== '' && c[k] === campaigns[0][k]
						)
					).reduce((a, c) => {
						const newA = { ...a };
						newA[c] = campaigns[0][c]
						return newA;
					}, {})),
					kpis: uniq([
						...(appSettings?.[app.id]?.kpis ?? []),
						...campaigns.flatMap(c => (c.kpis || []).map(k => k.event_name))
					])
				}}
				saving={saving}
				onSave={this.handleSave}
				onCancel={this.handleClose}
			/>
		)
	}

	render() {
		const { fetching, apps, expandedState } = this.state;

		return (
			<div className="app-settings">
				{fetching ? (
					<Loader />
				) : (
					<div className="app-settings-table">
						<Table
							data={apps}
							columns={columns}
							showPageSizeOptions={false}
							showPageJump={false}
							showPagination={false}
							pageSize={apps.length}
							expanded={expandedState}
							onExpandedChange={this.handleExpand}
							SubComponent={this.renderAppSettings}
							PadRowComponent={() => null}
							NoDataComponent={() => <NoData>There is no app</NoData>}
							getTrGroupProps={(state, rowInfo, column) => ({
								className: rowInfo && expandedState[rowInfo.viewIndex] ? '--open' : ''
							})} />
					</div>
				)}
			</div>
		)
	}
}

export default AppSettings;
