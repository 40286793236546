import React, { Component } from 'react';
import { Route, NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import {
	Icon,
	Tooltip,
	FraudGraph,
	Actions,
	PageTitle,
	SecondaryNav,
	FilterBox,
	DatePicker,
} from 'interceptd-ui';

import Table from '../../components/Table';
import { openFreeModeWarning } from '../../components/FreeModeWarning';

import ListStatsBoxes from './components/ListStatsBoxes';

import ListCampaigns from './ListCampaigns';
import ListPublishers from './ListPublishers';
import ListApps from './ListApps';

import { EmptyStatsObjects } from '../../constants/Campaign';

import { columns as ListSourceColumns } from './ListSourceColumns';

import Api from '../../services/api';
import Local from '../../services/localStorage';
import Settings from '../../services/settings';
import SendEvent from '../../services/events';
import history from '../../services/history';

import { checkIsFreeAccount, getInitialDateFilter, isDemoAccount } from '../../utils/misc';
import { copyTextToClipboard } from '../../utils/file';
import { getTimestamp } from '../../utils/transform';

import WelcomeBackModal from '../../pages/Modals/WelcomeBackModal';

import './styles/List.css';

import withTitle from '../withTitle';

const ViewRoute = ({
	component: Component,
	path,
	exact,
	...rest
}) => (
		<Route path={path} exact={exact} render={props => {
			return <Component {...props} {...rest} />
		}} />
	)

class Campaigns extends Component {
	state = {
		me: Local.getItem('me'),
		archivedCampaigns: Settings.get('archivedCampaigns', 'account') || [],

		campaignsFetching: true,
		campaigns: [],

		rulesets: {},
		campRulesets: {},

		campaignStatsFetching: true,
		campaignStats: {},

		campaignInstallAndEventFetching: true,
		campaignInstallAndEvent: {},

		campaignKPIStatsFetching: true,
		campaignKPIStats: {},

		showImpression: false,
		sourceImpressionMap: {},

		filters: getInitialDateFilter(),

		eventsColumnWidth: 250,
	}

	meListener = null

	componentDidMount = () => {
		this.meListener = Local.listen('me', newValue => {
			this.setState({
				me: newValue,
			})
		});

		this.getCampaigns();
	}

	componentWillUnmount = () => {
		this.meListener && Local.unlisten(this.meListener)
	}

	getCampaigns = () => {
		this.setState({
			campaignsFetching: true,
			campaigns: [],
		});

		Api.getLeoparCampaigns()
			.then(response => {
				const campaigns = sortBy(response.data.data.filter((c) => c.application_type !== 'leancheck').map(c => ({
					...c,
					sources: sortBy(c.sources, 'id')
				})), 'id').reverse().map(c => ({
					...c,
					sources: c.sources.map(s => ({
						...s,
						campaign: c,
						kpis: c.kpis
					}))
				}));

				checkIsFreeAccount(campaigns.length);

				const counts = campaigns.map(c => 
					Math.max(...(c.sources.map(s => 
						Object.keys(s.event_payouts || {}).length + (s.kpis || []).length
					)))
				);

				const eventsColumnWidth = (Math.max(...counts) * 250) + 250

				this.setState({
					campaignsFetching: false,
					campaigns,
					eventsColumnWidth,
				}, () => {
					this.getRulesets();
					this.getAllStats();
				});
			})
			.catch(({ response }) => {
				SendEvent({
					description: `getLeoparCampaigns on campaign list`,
					fatal: true
				});
				this.setState({
					campaignsFetching: false
				});
			})
	}

	getSourceImpressionCount = async () => {
		try {
			const { match } = this.props;
			const { campaigns, filters } = this.state;
			const sourceIds = campaigns.flatMap(c => c.sources).map(s => s.id);

			if (sourceIds.length === 0) return null;

			const impressionResponse = await Api.getDailyImpressionStats({
				source_ids: sourceIds,
				campaign_id: match.params.id,
				ts_start: getTimestamp(filters.start),
				ts_end: getTimestamp(filters.end, true),
			});

			const impressionStats = impressionResponse?.data?.data || [];
			const sourceImpressionMap = impressionStats.reduce((acc, cur) => {
				const newAcc = { ...acc };
				newAcc[cur.source_id] = newAcc[cur.source_id] || {
					clean: 0,
					flagged: 0,
					rejected: 0,
					total: 0,
				};
				newAcc[cur.source_id].clean += cur.impression_count || 0;
				newAcc[cur.source_id].flagged += cur.flagged_impression_count || 0;
				newAcc[cur.source_id].rejected += cur.rejected_impression_count || 0;
				newAcc[cur.source_id].total += (cur.impression_count || 0) + (cur.flagged_impression_count || 0) + (cur.rejected_impression_count || 0);

				newAcc.clean += cur.impression_count || 0;
				newAcc.flagged += cur.flagged_impression_count || 0;
				newAcc.rejected += cur.rejected_impression_count || 0;
				newAcc.total += (cur.impression_count || 0) + (cur.flagged_impression_count || 0) + (cur.rejected_impression_count || 0);

				return newAcc;
			}, {
				clean: 0,
				flagged: 0,
				rejected: 0,
				total: 0,
			});

			this.setState({
				sourceImpressionMap,
				showImpression: sourceImpressionMap.total > 0,
			});
		} catch (err) {
			toast.error(err?.message || `Couldn't fetch impression count.`);
		}
	}

	getRulesets = () => {
		const { campaigns } = this.state;

		if (campaigns.length === 0) return null;

		Api.getCampaignRuleSets(campaigns.map(c => c.id).join(','))
			.then(response => {
				this.setState({
					rulesets: response.data.data.reduce((acc, cur) => {
						acc[cur.campaign_ruleset.campaign_id] = {};
						acc[cur.campaign_ruleset.campaign_id].ruleset = cur.campaign_ruleset.ruleset;
						if (cur.source_rulesets && cur.source_rulesets.length > 0) {
							cur.source_rulesets.forEach(source_ruleset => {
								acc[cur.campaign_ruleset.campaign_id][source_ruleset.source_id] = {}
								acc[cur.campaign_ruleset.campaign_id][source_ruleset.source_id].ruleset = source_ruleset.ruleset;
							})
						}
						return acc;
					}, {}),
				});
			})
			.catch(({ response }) => {
				SendEvent({
					description: `getCampaignRuleSets on campaign list`,
					fatal: true
				});
			});

		Api.getCampaignCRuleset(campaigns.map(c => c.id).join(','))
			.then(response => {
				this.setState({
					campRulesets: response.data.data.reduce((acc, cur) => {
						acc[cur.campaign_ruleset.campaign_id] = {};
						acc[cur.campaign_ruleset.campaign_id].ruleset = cur.campaign_ruleset.ruleset;
						if (cur.source_rulesets && cur.source_rulesets.length > 0) {
							cur.source_rulesets.forEach(source_ruleset => {
								acc[cur.campaign_ruleset.campaign_id][source_ruleset.source_id] = {}
								acc[cur.campaign_ruleset.campaign_id][source_ruleset.source_id].ruleset = source_ruleset.ruleset;
							})
						}
						return acc;
					}, {}),
				});
			})
			.catch(({ response }) => {
				SendEvent({
					description: `getCampaignCRuleset on campaign list`,
					fatal: true
				});
			});
	}

	getAllStats = () => {
		this.getSourceStats();
		this.getSourceInstallAndEvent();
		this.getSourceKPIStats();
		this.getSourceImpressionCount();
	}

	getSourceStats = () => {
		const { campaigns, filters } = this.state;

		const sourceIds = campaigns.flatMap(c => c.sources.map(s => s.id));

		this.setState({
			campaignStatsFetching: true,
			campaignStats: {}
		});

		if (sourceIds.length === 0) {
			return this.setState({ campaignStatsFetching: false });
		}
	
		Api.getLeoparSourceStats({
			source_ids: sourceIds.join(','),
			ts_start: getTimestamp(filters.start),
			ts_end: getTimestamp(filters.end, true),
		})
			.then(response => {
				const sourceStats = get(response, 'data.data', []);

				this.setState({
					campaignStatsFetching: false,
					campaignStats: sourceStats.reduce((acc, cur) => {
						const { campaign_id, source_id } = cur;
						acc[campaign_id] = acc[campaign_id] || {};
						acc[campaign_id][source_id] = acc[campaign_id][source_id] || {};
						acc[campaign_id][source_id].click = {
							total: +cur.click_count + +cur.rejected_click_count + +cur.flagged_click_count,
							rejected: +cur.rejected_click_count,
							flagged: +cur.flagged_click_count,
							clean: +cur.click_count,
						}

						acc[campaign_id].click = acc[campaign_id].click || {
							...EmptyStatsObjects.click
						};
						Object.keys(acc[campaign_id].click).forEach(key => {
							acc[campaign_id].click[key] += acc[campaign_id][source_id].click[key];
						})
						acc.click = acc.click || {
							rejected_click_count: 0,
							clean_click_count: 0,
							flagged_click_count: 0,
						};
						Object.keys(acc.click).forEach(key => {
							acc.click[key] += acc[campaign_id][source_id].click[key.split('_')[0]];
						})

						return acc;
					}, {}),
				});
			})
			.catch(({ response }) => {
				this.setState({
					campaignStatsFetching: false,
					campaignStats: {}
				})
				SendEvent({
					description: `getLeoparSourceStats on campaign list`,
					fatal: true
				});
			});
	}

	getSourceInstallAndEvent = () => {
		const { campaigns, filters } = this.state;
		const sourceIds = campaigns.flatMap(c => c.sources.map(s => s.id));
		
		this.setState({
			campaignInstallAndEventFetching: true,
			campaignInstallAndEvent: {}
		});

		if (sourceIds.length === 0) return this.setState({
			campaignInstallAndEventFetching: false,
		});;

		Api.getLeoparSourceInstallAndEvent({
			source_ids: sourceIds.join(','),
			ts_start: getTimestamp(filters.start),
			ts_end: getTimestamp(filters.end, true),
		})
			.then(response => {
				const campaignInstallAndEvent = get(response, 'data.data', []);

				this.setState({
					campaignInstallAndEventFetching: false,
					campaignInstallAndEvent: campaignInstallAndEvent.reduce((acc, cur) => {
						const { campaign_id, source_id } = cur;
						acc[campaign_id] = acc[campaign_id] || {};
						acc[campaign_id][source_id] = acc[campaign_id][source_id] || {};

						// Install
						acc[campaign_id][source_id].install = {
							total: +cur.clean_install_count + +cur.flagged_install_count + +cur.rejected_install_count,
							rejected: +cur.rejected_install_count,
							flagged: +cur.flagged_install_count,
							clean: +cur.clean_install_count,
						}
						acc[campaign_id].install = acc[campaign_id].install || {
							...EmptyStatsObjects.install
						};
						Object.keys(acc[campaign_id].install).forEach(key => {
							acc[campaign_id].install[key] += acc[campaign_id][source_id].install[key];
						})
						acc.install = acc.install || {
							rejected_install_count: 0,
							flagged_install_count: 0,
							clean_install_count: 0,
						};
						Object.keys(acc.install).forEach(key => {
							acc.install[key] += acc[campaign_id][source_id].install[key.split('_')[0]];
						})

						// Event
						acc[campaign_id][source_id].event = {
							total: +cur.clean_event_count + +cur.flagged_event_count + +cur.rejected_event_count,
							rejected: +cur.rejected_event_count,
							rejected_amount: +cur.rejected_amount,
							rejected_reattributed_amount: +cur.rejected_reattributed_amount,
							rejected_reattributed_event_count: +cur.rejected_reattributed_event_count,
							flagged: +cur.flagged_event_count,
							flagged_amount: +cur.flagged_amount,
							flagged_reattributed_amount: +cur.flagged_reattributed_amount,
							flagged_reattributed_event_count: +cur.flagged_reattributed_event_count,
							clean: +cur.clean_event_count,
							clean_amount: +cur.clean_amount,
							clean_reattributed_amount: +cur.clean_reattributed_amount,
							clean_reattributed_event_count: +cur.clean_reattributed_event_count,
							unique: +cur.unique_event_count,
						}
						acc[campaign_id].event = acc[campaign_id].event || {
							...EmptyStatsObjects.event
						};
						Object.keys(acc[campaign_id].event).forEach(key => {
							acc[campaign_id].event[key] += acc[campaign_id][source_id].event[key];
						})
						acc.event = acc.event || {
							rejected_event_count: 0,
							flagged_event_count: 0,
							clean_event_count: 0,
						};
						Object.keys(acc.event).forEach(key => {
							acc.event[key] += acc[campaign_id][source_id].event[key.split('_')[0]];
						})

						return acc;
					}, {}),
				});
			})
			.catch(({ response }) => {
				this.setState({
					campaignInstallAndEventFetching: false,
					campaignInstallAndEvent: {}
				})
				SendEvent({
					description: `getSourceInstallAndEvent on campaign list`,
					fatal: true
				});
			});
	}

	getSourceKPIStats = () => {
		const { campaigns, filters } = this.state;

		const params = campaigns.flatMap(c => c.sources.map(s => ({
			source_id: s.id,
			event_names: uniq([
				...(s.kpis || []).map(kpi => kpi.event_name),
				...Object.keys(s.event_payouts || {}),
			])
		}))).filter(p => p.event_names.length > 0);

		if (params.length === 0) {
			return this.setState({
				campaignKPIStatsFetching: false,
				campaignKPIStats: {},
			});
		}

		this.setState({
			campaignKPIStatsFetching: true,
			campaignKPIStats: {},
		});

		Api.getLeoparSourceKPIStats({
			params,
			ts_start: getTimestamp(filters.start),
			ts_end: getTimestamp(filters.end, true),
		})
			.then(response => {
				this.setState({
					campaignKPIStatsFetching: false,
					campaignKPIStats: response.data.data.reduce((acc, cur) => {
						const { campaign_id, source_id, event_name } = cur;

						acc[campaign_id] = acc[campaign_id] || {};
						acc[campaign_id][source_id] = acc[campaign_id][source_id] || {};
						acc[campaign_id][source_id][event_name] = acc[campaign_id][source_id][event_name] || {};

						acc[campaign_id][source_id][event_name] = {
							total: +cur.clean_event_count + +cur.flagged_event_count + +cur.rejected_event_count,
							rejected: +cur.rejected_event_count,
							rejected_amount: +cur.rejected_amount,
							rejected_reattributed_amount: +cur.rejected_reattributed_amount,
							rejected_reattributed_event_count: +cur.rejected_reattributed_event_count,
							flagged: +cur.flagged_event_count,
							flagged_amount: +cur.flagged_amount,
							flagged_reattributed_amount: +cur.flagged_reattributed_amount,
							flagged_reattributed_event_count: +cur.flagged_reattributed_event_count,
							clean: +cur.clean_event_count,
							clean_amount: +cur.clean_amount,
							clean_reattributed_amount: +cur.clean_reattributed_amount,
							clean_reattributed_event_count: +cur.clean_reattributed_event_count,
							unique: +cur.unique_event_count,
						}

						acc[campaign_id][event_name] = acc[campaign_id][event_name] || {
							total: 0,
							rejected: 0,
							rejected_amount: 0,
							rejected_reattributed_amount: 0,
							rejected_reattributed_event_count: 0,
							flagged: 0,
							flagged_amount: 0,
							flagged_reattributed_amount: 0,
							flagged_reattributed_event_count: 0,
							clean: 0,
							clean_amount: 0,
							clean_reattributed_amount: 0,
							clean_reattributed_event_count: 0,
							unique: 0,
						};
						Object.keys(acc[campaign_id][event_name]).forEach(key => {
							acc[campaign_id][event_name][key] += acc[campaign_id][source_id][event_name][key];
						})

						return acc;
					}, {}),
				});
			})
			.catch(({ response }) => {
				this.setState({
					campaignKPIStatsFetching: false,
					campaignKPIStats: {},
				})
				SendEvent({
					description: `getSourceInstallAndEvent on campaign list`,
					fatal: true
				});
			});
	}

	renderStatsCell = ({ original, column }) => {
		const {
			campaignStatsFetching,
			campaignInstallAndEventFetching,
			campaignKPIStatsFetching,
			sourceImpressionMap,
		} = this.state;

		const isClickCell = column.id === 'click';
		const isEventCell = column.id === 'event';
		const isImpressionCell = column.id === 'impression';

		if (isImpressionCell) {
			const data = {
				clean: 0,
				flagged: 0,
				rejected: 0,
				total: 0,
			};

			(original?.sources || [original]).forEach((s) => {
				data.clean += sourceImpressionMap?.[s.id]?.clean ?? 0;
				data.flagged += sourceImpressionMap?.[s.id]?.flagged ?? 0;
				data.rejected += sourceImpressionMap?.[s.id]?.rejected ?? 0;
				data.total += sourceImpressionMap?.[s.id]?.total ?? 0;
			});

			return (
				<div className="campaign-table-fraud-bar-container">
					<FraudGraph
						title="Impression"
						data={data}
					/>
				</div>
			);
		}

		if (!isEventCell) {
			const data = this.getCellStats({ original, column });
			return (
				<div className="campaign-table-fraud-bar-container">
					<FraudGraph
						data={data}
						showPercents
						title={isClickCell ? 'Clicks' : 'Installs'}
						loading={isClickCell ? campaignStatsFetching : campaignInstallAndEventFetching} />
				</div>
			)
		}

		const totalData = this.getCellStats({ original, column });
		const kpis = (original?.kpis || []).map(kpi => kpi.event_name);
		const payouts = [
			...Object.keys(original.event_payouts || {}),
			...(Array.isArray(original.sources) ? original.sources.flatMap(s =>
				Object.keys(s.event_payouts || {})
			) : [])
		]
		const events = uniq([...kpis, ...payouts]).map(event => this.getCellStats({ original, column: { id: 'kpi', event_name: event } }))

		return (
			<div className="campaign-table-fraud-bar-container">
				<FraudGraph
					data={totalData}
					showPercents
					showUnique
					title="Events"
					loading={campaignInstallAndEventFetching} />
				{events.map((event, index) => (
					<FraudGraph
						key={`payout-${index}`}
						data={event}
						showPercents
						showUnique
						title={event.name}
						loading={campaignKPIStatsFetching} />
				))}
			</div>
		);
	}

	getTotalStats = (original, id) => {
		if (id === 'impression') {
			const { sourceImpressionMap } = this.state;
			let totalImpression = 0;
			(original?.sources || []).forEach((s) => {
				totalImpression += sourceImpressionMap[s.id]?.total || 0;
			});
			return totalImpression;
		}

		const data = this.getCellStats({ original, column: { id } });
		return data.total ? +data.total : 0;
	}

	getCellStats = ({ original, column }) => {
		const {
			campaignStats,
			campaignInstallAndEvent,
			campaignKPIStats,
		} = this.state;

		const isSource = !!original.campaign;
		const isClickCell = column.id === 'click';
		const isKPICell = column.id === 'kpi';
		let data = {};
		const dataSource =
			isClickCell ? campaignStats :
				isKPICell ? campaignKPIStats :
					campaignInstallAndEvent;

		if (isSource) {
			if (isKPICell) {
				data = {
					...(get(dataSource, `[${original.campaign.id}][${original.id}][${column.event_name}]`, EmptyStatsObjects.event)),
					name: column.event_name
				};
			} else {
				data = get(dataSource, `[${original.campaign.id}][${original.id}][${column.id}]`, EmptyStatsObjects[column.id]);
			}
		} else {
			if (isKPICell) {
				data = {
					...(get(dataSource, `[${original.id}][${column.event_name}]`, EmptyStatsObjects.event)),
					name: column.event_name
				};
			} else if (!original.sources) {
				data = get(dataSource, `[${original.id}][${column.id}]`, EmptyStatsObjects[column.id]);
			} else {
				data = original.sources.reduce((acc, cur) => {
					const newAcc = { ...acc };
					const sourceData = get(dataSource, `[${cur.campaign.id}][${cur.id}][${column.id}]`, EmptyStatsObjects[column.id])

					Object.keys(EmptyStatsObjects[column.id]).forEach(key => {
						newAcc[key] += +sourceData[key];
					})

					return newAcc;
				}, EmptyStatsObjects[column.id])
			}
		}

		return data;
	}

	updateFilter = (key, value, cb) => {
		const { filters } = this.state;
		return this.setState({
			filters: {
				...filters,
				[key]: value
			}
		}, cb)
	}

	handleDateRange = ({ from, to }) => {
		this.setState(prevState => ({
			filters: {
				...prevState.filters,
				start: from,
				end: to,
			}
		}), this.getAllStats);

		Local.setItem('date_filter', {
			from,
			to,
		});
	}

	archiveCampaign = props => {
		const { archivedCampaigns } = this.state;
		const { id } = props.original;

		if (archivedCampaigns.indexOf(id) === -1) {
			Settings.set('archivedCampaigns', [
				...(Settings.get('archivedCampaigns', 'account') || []),
				id
			], 'account');

			this.setState(prevState => ({
				archivedCampaigns: Settings.get('archivedCampaigns', 'account') || [],
			}), () => {
				toast.success('Campaign archived')
			})
		} else {
			Settings.set('archivedCampaigns', [
				...(Settings.get('archivedCampaigns', 'account') || []).filter(i => i !== id),
			], 'account');

			this.setState(prevState => ({
				archivedCampaigns: Settings.get('archivedCampaigns', 'account') || [],
			}), () => {
				toast.success('Campaign unarchived')
			})
		}
	}

	pauseSource = props => {
		const { original } = props;

		Api.blockSource(original.campaign.id, original.id)
			.then(res => {
				this.getCampaigns()
			})
			.catch(({ response }) => {
				SendEvent({
					description: `pauseSource for ${original.id} on campaign list`,
					fatal: false
				});
				toast.error(`Couldn't pause the source`);
			})
	}

	startSource = props => {
		const { original } = props;

		Api.unblockSource(original.campaign.id, original.id)
			.then(res => {
				this.getCampaigns()
			})
			.catch(({ response }) => {
				SendEvent({
					description: `startSource for ${original.id} on campaign list`,
					fatal: false
				});
				toast.error(`Couldn't pause the source`);
			})
	}

	copyUrl = (props, name) => {
		const { original } = props;
		copyTextToClipboard(original[name]);
		toast.success('URL copied');
	}

	activateBot = props => {
		const { original } = props;

		Api.activateCheckBot(original.campaign.id, original.id)
			.then(res => {
				this.getCampaigns()
			})
			.catch(({ response }) => {
				SendEvent({
					description: `activateBot for ${original.id} on campaign list`,
					fatal: false
				});

				toast.error(`Couldn't activate bot prevention for the source`);
			})
	}

	disableBot = props => {
		const { original } = props;

		Api.deactivateCheckBot(original.campaign.id, original.id)
			.then(res => {
				this.getCampaigns()
			})
			.catch(({ response }) => {
				SendEvent({
					description: `disableBot for ${original.id} on campaign list`,
					fatal: false
				});
				toast.error(`Couldn't disable bot prevention for the source`);
			})
	}

	renderSources = ({ original }) => {
		const { eventsColumnWidth } = this.state;
		return (
			<Table
				data={original.sources}
				columns={
					ListSourceColumns({
						renderStatsCell: this.renderStatsCell,
						getTotalStats: this.getTotalStats,
						eventsColumnWidth,
						renderActions: this.renderSourceActions,
					})
				}
				pages={0}
				PadRowComponent={() => null}
				sortable={false}
				showPageSizeOptions={false}
				showPageJump={false}
				showPagination={false}
				pageSize={original.sources.length}
				getTrProps={(state, rowInfo, column) => {
					return rowInfo.original.status !== 1 ? ({
						style: {
							filter: 'grayscale(1)',
							textDecoration: 'line-through'
						}
					}) : ({});
				}}
			/>
		)
	}

	renderSourceActions = props => {
		const { match } = this.props;
		const { me } = this.state;
		const { original } = props;
		const type = original?.campaign?.type || original.type;
		return Local.getItem('me')?.plans?.interceptd !== 'free' ? (
			<Actions>
				<Actions.Item onClick={() => history.push(`/campaign/${original.campaign.id}/edit/sources?source=${original.id}`, { back: match.url })} data-event-category="Campaign List" data-event-action="Edit source action clicked">Edit source</Actions.Item>
				{type !== 'CPM' && (original.status !== 1 ? (
					<Actions.Item onClick={() => this.startSource(props)} data-event-category="Campaign List" data-event-action="Start source action clicked">Start this source</Actions.Item>
				) : (
						<Actions.Item onClick={() => this.pauseSource(props)} data-event-category="Campaign List" data-event-action="Pause source action clicked">Pause this source</Actions.Item>
					))}
				<Actions.Item className={me.plans?.interceptd === 'free' ? 'bg-red' : ''} onClick={() => me.plans?.interceptd === 'free' ? openFreeModeWarning() : this.copyUrl(props, 'tracking_url')} data-event-category="Campaign List" data-event-action="Copy source tracking URL action clicked">
					<span style={{ marginRight: 10 }}>Copy source tracking URL</span>
					<Tooltip position="left" tooltip="Please share this link with the relevant ad network. Make sure that they use only this link and not the (original) MMP links.">
						<Icon size="14" i="info" />
					</Tooltip>
				</Actions.Item>
				{/* {original.check_bot ? (
					<Actions.Item onClick={() => this.disableBot(props)} data-event-category="Campaign List" data-event-action="Disable bot prevention action clicked">Disable bot prevention</Actions.Item>
				) : (
					<Actions.Item onClick={() => this.activateBot(props)} data-event-category="Campaign List" data-event-action="Activate bot prevention action clicked">Activate bot prevention</Actions.Item>
				)} */}
				{type !== 'CPM' && <Actions.Item onClick={() => history.push(`/campaign/${original.campaign.id}/edit/sources?source=${original.id}&field=selected_events`, { back: match.url })} data-event-category="Campaign List" data-event-action="Event postback settings action clicked">Event postback settings</Actions.Item>}
			</Actions>
		) : null
	}

	render() {
		const { match } = this.props;
		const {
			campaignsFetching,
			campaigns,
			campaignStatsFetching, campaignStats,
			campaignInstallAndEventFetching, campaignInstallAndEvent,
			campaignKPIStats, campaignKPIStatsFetching,
			sourceImpressionMap, showImpression,
			rulesets, campRulesets,
			archivedCampaigns,
			filters,
			eventsColumnWidth,
		} = this.state;

		const isArchivedPage = match.url.indexOf('archive') !== -1;

		return (
			<div className="campaign-list">
				<PageTitle>
					<PageTitle.Title>Campaigns</PageTitle.Title>
				</PageTitle>

				<FilterBox>
					<div className="filter-column filter-column-left">
						<SecondaryNav>
							<SecondaryNav.NavItem>
								<NavLink
									exact
									activeClassName="active"
									to="/">
									Campaigns
								</NavLink>
							</SecondaryNav.NavItem>
							<SecondaryNav.NavItem>
								<NavLink
									exact
									activeClassName="active"
									to="/publishers">
									Publishers
								</NavLink>
							</SecondaryNav.NavItem>
							<SecondaryNav.NavItem>
								<NavLink
									exact
									activeClassName="active"
									to="/apps">
									Apps
								</NavLink>
							</SecondaryNav.NavItem>
						</SecondaryNav>
					</div>
					<div className="filter-column filter-column-right">
						<DatePicker
							value={{
								from: filters.start,
								to: filters.end,
							}}
							onChange={this.handleDateRange} />
					</div>
				</FilterBox>

				{!isArchivedPage &&
					<ListStatsBoxes
						loading={campaignStatsFetching || campaignInstallAndEventFetching}
						totalImpressions={showImpression ? sourceImpressionMap : null}
						data={campaignStatsFetching || campaignInstallAndEventFetching ? {} : {
							...campaignStats.click,
							...campaignInstallAndEvent.install,
							...campaignInstallAndEvent.event,
						}}
					/>
				}

				<ViewRoute
					exact
					path="/"
					component={ListCampaigns}
					showImpression={showImpression}
					getCampaigns={this.getCampaigns}
					getRulesets={this.getRulesets}
					archivedCampaigns={archivedCampaigns}
					archiveCampaign={this.archiveCampaign}
					campaigns={campaigns.filter(c => archivedCampaigns.indexOf(c.id) === -1)}
					campaignsFetching={campaignsFetching}
					campaignStats={campaignStats}
					campaignStatsFetching={campaignStatsFetching}
					campaignInstallAndEvent={campaignInstallAndEvent}
					campaignInstallAndEventFetching={campaignInstallAndEventFetching}
					campaignKPIStats={campaignKPIStats}
					campaignKPIStatsFetching={campaignKPIStatsFetching}
					eventsColumnWidth={eventsColumnWidth}
					rulesets={rulesets}
					campRulesets={campRulesets}
					renderSourceActions={this.renderSourceActions}
					getCellStats={this.getCellStats}
					getTotalStats={this.getTotalStats}
					renderStatsCell={this.renderStatsCell}
				/>

				<ViewRoute
					exact
					path="/archive"
					component={ListCampaigns}
					showImpression={showImpression}
					getCampaigns={this.getCampaigns}
					getRulesets={this.getRulesets}
					archivedCampaigns={archivedCampaigns}
					archiveCampaign={this.archiveCampaign}
					campaigns={campaigns.filter(c => archivedCampaigns.indexOf(c.id) !== -1)}
					campaignsFetching={campaignsFetching}
					campaignStats={campaignStats}
					campaignStatsFetching={campaignStatsFetching}
					campaignInstallAndEvent={campaignInstallAndEvent}
					campaignInstallAndEventFetching={campaignInstallAndEventFetching}
					campaignKPIStats={campaignKPIStats}
					campaignKPIStatsFetching={campaignKPIStatsFetching}
					rulesets={rulesets}
					campRulesets={campRulesets}
					renderSourceActions={this.renderSourceActions}
					getCellStats={this.getCellStats}
					getTotalStats={this.getTotalStats}
					renderStatsCell={this.renderStatsCell}
				/>

				<ViewRoute
					exact
					path="/publishers"
					component={ListPublishers}
					getCampaigns={this.getCampaigns}
					archivedCampaigns={archivedCampaigns}
					archiveCampaign={this.archiveCampaign}
					campaigns={campaigns.filter(c => archivedCampaigns.indexOf(c.id) === -1)}
					campaignsFetching={campaignsFetching}
					campaignStats={campaignStats}
					campaignStatsFetching={campaignStatsFetching}
					campaignInstallAndEvent={campaignInstallAndEvent}
					campaignInstallAndEventFetching={campaignInstallAndEventFetching}
					campaignKPIStats={campaignKPIStats}
					campaignKPIStatsFetching={campaignKPIStatsFetching}
					renderSourceActions={this.renderSourceActions}
					getTotalStats={this.getTotalStats}
					getCellStats={this.getCellStats}
					renderSources={this.renderSources}
				/>

				<ViewRoute
					exact
					path="/apps"
					component={ListApps}
					getCampaigns={this.getCampaigns}
					archivedCampaigns={archivedCampaigns}
					archiveCampaign={this.archiveCampaign}
					campaigns={campaigns.filter(c => archivedCampaigns.indexOf(c.id) === -1)}
					campaignsFetching={campaignsFetching}
					campaignStats={campaignStats}
					campaignStatsFetching={campaignStatsFetching}
					campaignInstallAndEvent={campaignInstallAndEvent}
					campaignInstallAndEventFetching={campaignInstallAndEventFetching}
					campaignKPIStats={campaignKPIStats}
					campaignKPIStatsFetching={campaignKPIStatsFetching}
					getTotalStats={this.getTotalStats}
					getCellStats={this.getCellStats}
					renderSources={this.renderSources}
				/>
				{!isDemoAccount() && <WelcomeBackModal campaigns={campaigns?.length} />}
			</div>
		);
	}
}

export default withTitle(Campaigns, 'Campaign List');
