import React from 'react';
import Chart from 'react-apexcharts';

const AlarmDistGraph = ({ data: { categories, series, tooltipTitles } }) => {
	if (!categories || categories.some((c) => !c)) return null;
	if (!series || series.some((c) => !c)) return null;

  const options = {
    dataLabels: {
      enabled: false,
    },
    chart: {
      id: 'alarm-distribution-bar-chart',
    },
	  colors: ['#230060'],
    xaxis: {
      categories,
    },
    plotOptions: {
      bar: {
        horizontal: true,
      }
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: v => v,
        title: {
          formatter: () => 'Count',
        },
      },
      x: {
        show: true,
        format: 'dd MMM',
        formatter: (seriesName, i) => tooltipTitles[i.dataPointIndex],
      },
      marker: {
        show: true,
      },
    }

  };

  return (
    <Chart
      options={options}
      series={series}
      type="bar"
      height="390"
    />
  );
};

export default AlarmDistGraph;