import React, { useState } from 'react';
import ClickOutside from 'react-click-outside';
import { IntercomAPI } from 'react-intercom';
import {
	Icon,
	Button,
} from 'interceptd-ui';

import { useInterval } from '../../utils/hooks';

import './styles/FreeModeWarning.css';

const Values = [
	'Keep fraudulent installs out',
	'Protect your marketing data',
	'Protect your marketing budget',
	'Increase your profitability',
	'Let us help you to fight fraud',
]

export let openFreeModeWarning = () => {};
export let closeFreeModeWarning = () => {};
export let toggleFreeModeWarning = () => {};

const FreeModeWarning = props => {
	const [state, setState] = useState({
		open: false,
		active: 0,
	})

	useInterval(() => {
		setState(prevState => ({
			...prevState,
			active: prevState.active + 1 > Values.length - 1 ? 0 : prevState.active + 1,
		}));
	}, 5000)

	const open = () => {
		setState(prevState => ({
			...prevState,
			open: true,
		}))
	}
	openFreeModeWarning = open;

	const close = () => {
		setState(prevState => ({
			...prevState,
			open: false,
		}))
	}
	closeFreeModeWarning = close;

	const toggle = () => {
		setState(prevState => ({
			...prevState,
			open: !prevState.open,
		}))
	}
	toggleFreeModeWarning = toggle;

	const talkToSales = () => {
		IntercomAPI('showNewMessage', 'I want to receive and analyze my traffic. Can you help me?');
	}

	return (
		<ClickOutside onClickOutside={close}>
			<div className={`free-mode-warning ${state.open ? 'open' : ''}`}>
				<div className="free-mode-warning-toggle" onClick={toggle}>
					<span>
						<Icon i="info" size={18} />
						<div className="free-mode-warning-texts">
							{Values.map((value, index) => (
								<i key={index} className={`${state.active === index ? 'active' : ''}`}>{value}</i>
							))}
						</div>
					</span>
					<Icon i="chevron-right" size={18} />
				</div>

				<div className="free-mode-warning-detail">
					<div className="free-mode-warning-detail-header">Unable To Analyze The Traffic</div>
					<div className="free-mode-warning-detail-content">
						<span>Since you have a free account you are not able receive and analyze your traffic. Talk to our sales representatives to find the most suitable paid package for your needs.</span>
						<Button mini onClick={talkToSales}>Talk to Sales</Button>
					</div>
				</div>
			</div>
		</ClickOutside>
	)
}

export default FreeModeWarning;
