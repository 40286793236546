import React from 'react';
import { NavLink, Route, Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IntercomAPI } from 'react-intercom';
import { Portal } from 'react-portal';
import {
	Loader,
	NoData,
	Icon,
	Tooltip,
	PageTitle,
	Button,
} from 'interceptd-ui';

import Create from './Create';
import Detail from './Detail';
import Empty from './Empty';

import Api from '../../services/api';
import Local from '../../services/localStorage';
import config from '../../services/config.json';
import SendEvent from '../../services/events';

import { copyTextToClipboard } from '../../utils/file';

import './styles/List.css';

import withTitle from '../withTitle';

const DetailRoute = ({ component: Component, link, onSaveTitle, ...rest }) => (
	<Route {...rest} render={props => {
		return <Component link={link} onSaveTitle={onSaveTitle} />
	}} />
)

class List extends React.Component {
	state = {
		fetching: true,
		data: [],
		create: false,
		opened: false,
		copying: {},
		filter: '',
	}

	componentDidMount = () => {
		this.fetchData()
	}

	fetchData = () => {
		this.setState({
			fetching: true,
		});
		Api.getShortenerLinks()
			.then(response => {
				this.setState({
					fetching: false,
					data: response.data.data,
				})
			})
			.catch(({ response }) => {
				toast.error(`Couldn't get shortener links list`);
				SendEvent({
					description: `getShortenerLinks on shortener links list`,
					fatal: true
				});
				this.setState({
					fetching: false,
					error: true,
				});
			})
	}

	openCreate = () => {
		this.setState({
			create: true
		}, () => {
			setTimeout(() => {
				this.setState({
					opened: true
				})
			}, 50)
		})
	}

	closeCreate = () => {
		this.setState({
			opened: false
		}, () => {
			setTimeout(() => {
				this.setState({
					create: false
				})
			}, 500)
		})
	}

	handleCopy = (event, short_url) => {
		const { copying } = this.state;
		event.preventDefault();
		event.stopPropagation();
		if ( copying[short_url] ) return false;
		this.setState(prevState => ({
			copying: {
				...prevState.copying,
				[short_url]: true
			}
		}))
		const domain = config[window.location.hostname].shortener;
		copyTextToClipboard(`${domain}${short_url}`);
		setTimeout(() => {
			this.setState(prevState => ({
				copying: {
					...prevState.copying,
					[short_url]: false
				}
			}))
		}, 1200);
		return false;
	}

	handleTalktoSales = () => {
		IntercomAPI('showNewMessage', 'I want to create more secure links. Can you help me?');
	}

	handleFilter = event => {
		this.setState({
			filter: event.target.value
		})
	}

	handleSaveTitle = (short_url, title) => {
		this.setState(prevState => ({
			data: prevState.data.map(link => ({
				...link,
				title: link.short_url === short_url ? title : link.title
			}))
		}))
	}

	render() {
		const { match } = this.props;
		const { fetching, data, create, opened, copying, filter } = this.state;

		const domain = config[window.location.hostname].shortener.replace(/(^\w+:|^)\/\//, '')
		const me = Local.getItem('me');

		if (!me) {
			return <Redirect to="/welcome" />
		}

		return (
			<div className="shortener">
				<PageTitle>
					<PageTitle.Title>Secure Clicks</PageTitle.Title>
				</PageTitle>

				{fetching ? (
					<div className="campaign-detail"><Loader /></div>
				) : data.length === 0 ? (
					<Empty icon="link" create={this.openCreate} />
				) : (
					<div className="shortener-list">
						<div className="shortener-list-links">
							<div className="shortener-list-links-title">
								<h2>
									Trackers
									{me.plans?.interceptd === 'free' && data.length >= 5 && (
										<span>Maximum 5 links for free accounts</span>
									)}
								</h2>
								{me.plans?.interceptd === 'free' && data.length >= 5 ? (
									<Button onClick={this.handleTalktoSales}>Talk to Sales</Button>
								) : (
									<Button onClick={this.openCreate}>Create Link</Button>
								)}
							</div>
							{data.length > 10 &&
								<div className="shortener-list-links-filter">
									<Icon i="search" size={14} />
									<input
										type="text"
										placeholder="Search"
										spellCheck={false}
										onChange={this.handleFilter}
										value={filter} />
								</div>
							}
							<div className="shortener-list-links-wrapper">
								{data.filter(link => 
									filter !== '' ? 
										(link.title || '').toLowerCase().indexOf(filter.toLowerCase()) !== -1 || 
										`${domain}${(link.short_url || '')}`.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
										(link.url || '').toLowerCase().indexOf(filter.toLowerCase()) !== -1 :
										true
								).map(link => (
									<NavLink key={link.short_url} className="shortener-list-link" exact to={`/secure-link/${link.short_url}`}>
										<span className="shortener-list-link-title">{link.title}</span>
										<Tooltip
											position="bottom"
											spacing="10px"
											content="Copy"
											className="shortener-list-link-url"
											onClick={e => this.handleCopy(e, link.short_url)}>
											<span className={`shortener-list-link-url-inner ${copying[link.short_url] ? 'copying' : ''}`} data-content="Copied">{domain}{link.short_url}</span>
										</Tooltip>
									</NavLink>
								))}
							</div>
						</div>
						<div className="shortener-list-detail">
							{!fetching && match.params.id ? (
								<DetailRoute path="/secure-link/:id" component={Detail} link={data.find(d => d.short_url === match.params.id) || {}} onSaveTitle={this.handleSaveTitle} />
							) : (
								<NoData icon="link">
									Select a link from left and see the details
								</NoData>
							)}
						</div>
					</div>
				)}
				{create && (
					<Portal>
						<Create
							opened={opened}
							onClose={this.closeCreate}
							onRefresh={this.fetchData} />
					</Portal>
				)}
			</div>
		)
	}
}

export default withTitle(List, 'Secure Link');
