import React from 'react';
import {
	Loader,
	Icon,
	Checkbox,
} from 'interceptd-ui';

import './styles/CheckboxGroup.css';

const CheckboxGroup = ({
	parentName,
	childName,
	expanded,
	onExpandedChange,
	renderExpanderTooltip,
	filter,
	filterable,
	onFilterChange,
	filterFunction,
	label,
	options,
	value,
	loading,
	parentValueSelector,
	parentLabelSelector,
	renderLabel,
	childOptionsSelector,
	childValueSelector,
	childLabelSelector,
	renderChildLabel,
	onChange,
	showSelectAll,
	view,

	renderHeader,
	renderTools,
	renderSearch,
}) => {
	const [heights, setHeights] = React.useState([]);
	const [showSelectedDetail, setShowSelectedDetail] = React.useState(false);
	const optionRefs = React.useRef([]);

	React.useEffect(() => {
		optionRefs.current = optionRefs.current.slice(0, options.length);
		setHeights(optionRefs.current.map(el => el && el.scrollHeight));
  }, [options, filter]);

	const handleChange = (parent, child, checked) => {
		onChange && onChange(parent, child, checked);
	}

	const isAnySelected = Array.isArray(value) && value.length > 0;
	const isAllSelected = childOptionsSelector
		? options.every(o => o[childOptionsSelector].every(c => value.some(v => v === c[childValueSelector])))
		: options.every(c => value.some(v => v === c[parentValueSelector]));

	const totalSelectedChild = value.length;
	let totalSelectedParent = 0;

	if (childOptionsSelector) {
		options.forEach((o) => {
			const isSelected = o[childOptionsSelector].some(child => value.some(v => v === child[childValueSelector]));
			if (isSelected) {
				totalSelectedParent = (totalSelectedParent + 1);
			}
		});
	} else {
		totalSelectedParent = value.length;
	}

	const selectAll = () => {
		const allValues = childOptionsSelector
			? options.map(o => o[childOptionsSelector].map(c => c[childValueSelector])).flat()
			: options.map(o => o[parentValueSelector]);

		onChange && onChange(allValues, !isAllSelected);
	}

	return (
		<div className={`checkbox-group ${expanded ? 'expanded' : ''}`}>
			{label && 
				<div className="checkbox-group-label">
					{label}
					<div className="checkbox-group-label-actions">
						{renderTools && renderTools()}
						{onExpandedChange && <div className="checkbox-group-expander" tooltip-left={renderExpanderTooltip && renderExpanderTooltip(expanded)}>
							{expanded ? 
								<Icon i="minimize-2" size={14} onClick={() => onExpandedChange && onExpandedChange(false)} /> :
								<Icon i="maximize-2" size={14} onClick={() => onExpandedChange && onExpandedChange(true)} />
							}
						</div>}
					</div>
				</div>
			}
			{filterable && (
				<div className="checkbox-group-filter">
					<Icon i="search" size={14} />
					{renderSearch ? renderSearch() : (
						<input
							className="checkbox-group-filter-input"
							type="text"
							placeholder="Search..."
							spellCheck={false}
							value={filter || ''}
							onChange={onFilterChange} />
					)}
				</div>
			)}
			<div className="checkbox-group-total">
				{renderHeader && typeof renderHeader === 'function' ? renderHeader() : (
					<div className="checkbox-group-total-header">
						<span>{`${totalSelectedParent} ${parentName}${totalSelectedParent > 1 ? 's' : ''} ${childName ? (`and ${totalSelectedChild} ${childName}${totalSelectedChild > 1 ? 's' : ''} `) : ''}selected`}</span>
						{childOptionsSelector && <Icon i={showSelectedDetail ? 'eye-off' : 'eye'} size={14} onClick={() => setShowSelectedDetail(prevState => !prevState)} />}
					</div>
				)}
				{childOptionsSelector && <div className={`checkbox-group-total-detail ${showSelectedDetail ? 'show' : ''}`}>
					{options.filter(o => o[childOptionsSelector].some(child => value.some(v => v === child[childValueSelector]))).map((c) => (
						<div>
							<span className="parent-name">{`${c.id} - ${c.name}`}</span>
							{c[childOptionsSelector].filter(child => value.some(v => v === child[childValueSelector])).map((child) => {
								return <span className="child-name">{`${child.id} - ${child.name}`}</span>;
							})}
						</div>
					))}
				</div>}
			</div>
			{loading ? (
				<div className="checkbox-group-loading">
					<Loader mini />
				</div>
			) : (
				<div className="checkbox-group-options">
					{showSelectAll && (
						<div key="child-select-all" className={`checkbox-group-option checkbox-group-parent ${isAllSelected ? 'selected' : isAnySelected ? 'indeterminate' : ''}`}>
							<div className="checkbox-group-option-label-wrapper">
									<div className="checkbox-group-option-check">
										<Checkbox
											checked={isAllSelected || isAnySelected}
											onChange={selectAll} />
									</div>
								<div className="checkbox-group-option-label">Select All</div>
							</div>
						</div>
					)}
					{options.filter((option, index) =>
						(filterable && filter && filter !== '') ? 
							filterFunction ?
								filterFunction(option, index) : 
								option[parentLabelSelector].trim().toLowerCase().indexOf(filter.trim().toLowerCase()) !== -1 || option[parentValueSelector].trim().toLowerCase().indexOf(filter.trim().toLowerCase()) !== -1
						: true
					).map((option, index) => {
						const isSelected = childOptionsSelector
							? option[childOptionsSelector].every(child => value.some(v => v === child[childValueSelector]))
							: value.some(v => v === option[parentValueSelector]);

						const isIndeterminate = childOptionsSelector
							? option[childOptionsSelector].some(child => value.some(v => v === child[childValueSelector]))
							: false;
						
						const optionValue = childOptionsSelector
							? option[childOptionsSelector].map(child => child[childValueSelector])
							: option[parentValueSelector];

						return (
							<div key={`child-${option[parentValueSelector]}`} className={`checkbox-group-option checkbox-group-parent ${isSelected ? 'selected' : isIndeterminate ? 'indeterminate' : ''}`}>
								<div className="checkbox-group-option-label-wrapper">
									<div className="checkbox-group-option-check">
										<Checkbox
											view={view}
											checked={isSelected || isIndeterminate}
											onChange={e => handleChange(optionValue, !!e.target.checked)} />
									</div>
									<div className="checkbox-group-option-label">{renderLabel ? renderLabel(option) : option[parentLabelSelector]}</div>
								</div>
								{childOptionsSelector && <div ref={el => optionRefs.current[index] = el} className="checkbox-group-option-childs" style={{ '--maxHeight': heights[index] ? `${heights[index]}px` : 'none' }}>
									{option[childOptionsSelector].map((child, index) => {
										const isSelected = value.some(v => v === child[childValueSelector]);
										return (
											<div key={`child-${child[childValueSelector]}`} className={`checkbox-group-option checkbox-group-child ${isSelected ? 'selected' : ''}`}>
												<div className="checkbox-group-option-label-wrapper">
													<div className="checkbox-group-option-check">
														<Checkbox
															checked={isSelected}
															onChange={e => handleChange([child[childValueSelector]], !!e.target.checked)} />
													</div>
													<div className="checkbox-group-option-label">{renderChildLabel ? renderChildLabel(child) : child[childLabelSelector]}</div>
												</div>
											</div>
										)
									})}
								</div>}
							</div>
						)
					})}
				</div>
			)}
		</div>
	)
}

CheckboxGroup.defaultProps = {
	expanded: true,
	renderExpanderTooltip: (expanded) => expanded ? 'Collapse' : 'Expand',
	value: [],
	expandedTooltip: 'Collapse',
	collapsedTooltip: 'Expand',
	filterable: false,
	showSelectAll: false,
	parentValueSelector: 'id',
	parentLabelSelector: 'label',
	childOptionsSelector: null,
	childValueSelector: 'id',
	childLabelSelector: null,
	renderHeader: null,
	view: 'checkbox',
	renderTools: () => null,
}

export default CheckboxGroup;
