export const TYPE_SELECT_OPTIONS = [
	{
		label: 'Cost',
		value: 'cost',
		props: {
			style: {
				paddingLeft: 30,
			},
		},
	},
	{
		label: 'Clean',
		props: {
			style: {
				color: 'var(--green)',
				fontWeight: 600,
				pointerEvents: 'none',
			}
		},
	},
	{
		label: 'Clean Click',
		value: 'clean_click_count',
		props: {
			style: {
				paddingLeft: 30,
			}
		},
	},
	{
		label: 'Clean Install',
		value: 'clean_install_count',
		props: {
			style: {
				paddingLeft: 30,
			}
		},
	},
	{
		label: 'Clean Event',
		value: 'clean_event',
		props: {
			style: {
				paddingLeft: 30,
			}
		},
	},
	{
		label: 'Flagged',
		props: {
			style: {
				color: 'var(--yellow)',
				fontWeight: 600,
				pointerEvents: 'none',
			}
		},
	},
	{
		label: 'Flagged Install',
		value: 'flagged_install',
		props: {
			style: {
				paddingLeft: 30,
			}
		},
	},
	{
		label: 'Flagged Event',
		value: 'flagged_event',
		props: {
			style: {
				paddingLeft: 30,
			}
		},
	},
	{
		label: 'Rejected',
		props: {
			style: {
				color: 'var(--red)',
				fontWeight: 600,
				pointerEvents: 'none',
			}
		},
	},
	{
		label: 'Rejected Click',
		value: 'rejected_click',
		props: {
			style: {
				paddingLeft: 30,
			}
		},
	},
	{
		label: 'Rejected Install',
		value: 'rejected_install',
		props: {
			style: {
				paddingLeft: 30,
			}
		},
	},
	{
		label: 'Rejected Event',
		value: 'rejected_event',
		props: {
			style: {
				paddingLeft: 30,
			}
		},
	}
];

export const LINE_FILTER_OPTIONS = [{
	label: 'App',
	value: 'app',
}, {
	label: 'Platform',
	value: 'platform',
}, {
	label: 'Ad Network',
	value: 'partner',
}];

export const PLATFORM_OPTIONS = [{
	label: 'Android',
	value: 'android',
}, {
	label: 'IOS',
	value: 'ios',
}];