import React, { Component } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import get from 'lodash/get';
import {
	Loader,
	NoData,
	Icon,
	Button,
	Select,
} from 'interceptd-ui';

import EventStatsGraph from './components/EventStatsGraph';
import FunnelCreate from './components/FunnelCreate';
import Funnel from './components/Funnel';

import Api from '../../services/api';
import Local from '../../services/localStorage';
import Settings from '../../services/settings';

import { getTimestamp } from '../../utils/transform';

import { EventCategories } from '../../constants/Campaign';

import withTitle from '../withTitle';

import './styles/Detail.css';

class Detail extends Component {
	state = {
		eventMatches: (Settings.get('eventMatches', 'account') || {})[this.props.match.params.id] || [],

		selectedEvents: [],
		graphFirstFetching: true,
		graphFetching: true,
		data: null,
		graph: null,
		colors: [],

		funnels: Settings.get('funnels', 'account')?.[this.props.match.params.id] || [],
		isFunnelCreating: false,
		selectedFunnel: null,
		selectedFunnelIndex: null,
		tableFetching: false,
		selectedFunnelData: null,

		editingFunnel: null,
	}

	colors = EventCategories
		.filter(e => e.color)
		.reduce((a, c) => {
			a[c.label] = c.color
			return a;
		}, {})

	componentDidMount = () => {
		this.initState();
		Local.listen('settings', this.initState)
	}

	initState = () => {
		this.setState(({ selectedFunnel, selectedFunnelIndex, tableFetching }) => {
			const eventMatches = (Settings.get('eventMatches', 'account') || {})[this.props.match.params.id] || []
			const hasSelectedFunnel = selectedFunnel && Settings.get('funnels', 'account')?.[this.props.match.params.id].some(f => f.id === selectedFunnel.id);
			const hasAnyFunnel = Settings.get('funnels', 'account')?.[this.props.match.params.id]?.[0];
			const defaultFunnel = hasAnyFunnel ? {
				...hasAnyFunnel,
				events: hasAnyFunnel.events.filter(event => eventMatches.some(match => match.category === event))
			} : null;

			return ({
				eventMatches,
				funnels: (Settings.get('funnels', 'account') || {})[this.props.match.params.id] || [],
				selectedFunnel: hasSelectedFunnel ? selectedFunnel : defaultFunnel || null,
				selectedFunnelIndex: hasSelectedFunnel ? Settings.get('funnels', 'account')?.[this.props.match.params.id].findIndex(f => f.id === selectedFunnel.id) : hasAnyFunnel ? 0 : null,
				tableFetching: hasSelectedFunnel ? tableFetching : hasAnyFunnel ? true : false,
			})
		}, () => {
			this.getGraphData();
			this.getFunnelData();
		})
	}

	componentDidUpdate(prevProps) {
		if (
			!moment(prevProps.filters.start).isSame(this.props.filters.start, 'day') ||
			!moment(prevProps.filters.end).isSame(this.props.filters.end, 'day')
		) {
			this.getGraphData();
			this.getFunnelData();
		}
	}

	getGraphData = () => {
		const { eventMatches, selectedEvents } = this.state;

		if ( eventMatches.length === 0 ) {
			this.setState({
				graphFirstFetching: false,
				graphFetching: false,
			});
			return
		}

		this.setState({ graphFetching: true });
		const query = this.getQuery();

		Api.getLeoparFunnelData({
			...query,
			graph: true,
			events: selectedEvents.length > 0
				? selectedEvents
						.map(event => eventMatches.find(match => match.category === event).event)
						.join(',')
				: EventCategories
					.map(c => c.label)
					.filter(category => eventMatches.some(match => match.category === category))
					.map(category => eventMatches.find(match => match.category === category).event)
					.join(','),
		})
			.then(response => {
				const { data } = response.data;

				const graph = Object.keys(data[Object.keys(data)[0]] || {}).map(event => ({
					name: eventMatches.find(match => match.event === event).category,
					color: this.colors[event],
					data: Object.keys(data).map(day => ({
						x: +day,
						y: +data[day][event]
					}))
				}))

				this.setState({
					graphFirstFetching: false,
					graphFetching: false,
					data,
					graph,
					colors: graph.map(serie => this.colors[serie.name]),
				});
			}).catch(() => {
				this.setState({
					graphFirstFetching: false,
					graphFetching: false,
				});
				toast.error(`Couldn't fetch funnel data.`);
			});
	}

	selectAllCategories = () => {
		this.setState(prevState => ({
			selectedEvents: [],
		}), this.getGraphData);
	}

	selectCategory = category => {
		const { selectedEvents } = this.state;

		if ( selectedEvents.some(c => c === category) ) {
			this.setState({
				selectedEvents: selectedEvents.filter(c => c !== category)
			}, this.getGraphData)
		} else {
			this.setState({
				selectedEvents: [
					...selectedEvents,
					category,
				]
			}, this.getGraphData)
		}
	}

	handleFunnelCreateOpen = () => {
		this.setState({
			isFunnelCreating: true,
		})
	}

	handleFunnelCreateClose = () => {
		this.setState({
			isFunnelCreating: false,
			editingFunnel: null,
		})
	}

	handleSelectFunnel = ([value]) => {
		this.setState(({ eventMatches, funnels }) => ({
			selectedFunnelIndex: value,
			selectedFunnel: {
				...funnels[value],
				events: funnels[value].events.filter(event => eventMatches.some(match => match.category === event))
			},
			tableFetching: true,
		}), this.getFunnelData)
	}

	handleFunnelEdit = (funnelId, e) => {
		e.stopPropagation();

		this.setState(({ funnels }) => ({
			isFunnelCreating: true,
			editingFunnel: funnels.find(f => f.id === funnelId),
		}))
	}

	handleFunnelRemove = (funnelId, e) => {
		const { match } = this.props;

		e.stopPropagation();

		Settings.set('funnels', {
			...(Settings.get('funnels', 'account') || {}),
			[match.params.id]: ((Settings.get('funnels', 'account') || {})[match.params.id] || []).filter(funnel => funnel.id !== funnelId)
		}, 'account');

		toast.success('Funnel removed.');
	}

	getQuery = () => {
		const { match, campaigns, filters } = this.props;

		return ({
			campaignId: campaigns.filter(campaign => get(campaign, 'app_info.id', '') === match.params.id).map(c => c.id),
			startDate: getTimestamp(filters.start),
			endDate: getTimestamp(filters.end, true),
		});
	}

	getFunnelData = () => {
		const { eventMatches, selectedFunnel } = this.state;

		if ( !selectedFunnel || selectedFunnel.events.length === 0 ) {
			return this.setState({ tableFetching: false });
		}

		const query = this.getQuery();

		this.setState({ tableFetching: true });
		Api.getLeoparFunnelData({
			...query,
			graph: false,
			events: selectedFunnel.events
				.filter(event => eventMatches.some(match => match.category === event))
				.map(event => eventMatches.find(match => match.category === event).event)
				.join(','),
		}).then(response => {
			this.setState({
				tableFetching: false,
				selectedFunnelData: response.data.data,
			});
		}).catch(() => {
			this.setState({ tableFetching: false });
			toast.error(`Couldn't fetch funnel data.`);
		});
	}

	render() {
		const { match, onOpenModal } = this.props;
		const {
			eventMatches,
			selectedEvents,
			graphFirstFetching,
			graphFetching,
			graph,
			colors,
			funnels,
			isFunnelCreating,
			tableFetching,
			selectedFunnel,
			selectedFunnelIndex,
			selectedFunnelData,
			editingFunnel,
		} = this.state;

		if ( eventMatches.length === 0 ) {
			return (
				<div className="event-analytics">
					<div className="event-analytics-graph-nodata">
						<NoData icon="link">
							First, match your events with event categories
							<Button bgColor="shade" onClick={onOpenModal}>Match Events</Button>
						</NoData>
					</div>
				</div>
			)
		}

		return (
			<div className="event-analytics">

				<div className={`event-analytics-stats ${graphFetching ? 'fetching' : ''}`}>
					<h3 className="event-analytics-title">
						Event Stats
						{graphFetching && <Loader mini size={25} />}
					</h3>
					<div className="event-analytics-stats-categories">
						<Button
							className={`event-category event-category-all ${ selectedEvents.length === 0 ? 'active' : '' }`}
							onClick={this.selectAllCategories}>
							All Events
						</Button>
						{EventCategories.map(c => c.label).filter(category => eventMatches.some(match => match.category === category)).map((category, index) => (
							<Button
								key={category}
								onClick={() => this.selectCategory(category)}
								className={`event-category ${ selectedEvents.indexOf(category) !== -1 ? 'active' : '' }`}
								style={{ '--color': this.colors[category] }}>
								{category}
							</Button>
						))}
					</div>

					<div className="event-analytics-graph">
						{graphFirstFetching ? (
							<div className="event-analytics-graph-loader">
								<Loader />
							</div>
						) : (graph && graph.length > 0) ? (
							<EventStatsGraph
								series={graph}
								getOptions={options => ({
									...options,
									colors,
								})} />
						) : (
							<div className="event-analytics-graph-nodata">
								<NoData icon="activity">There is no data</NoData>
							</div>
						)}
					</div>
				</div>

				<div className="event-funnels">
					<h3 className="event-analytics-title">
						Event Funnels
						<Button
							mini
							bgColor="shade"
							style={{ marginLeft: 20 }}
							onClick={this.handleFunnelCreateOpen}>
							New Funnel
						</Button>
					</h3>

					{(funnels.length > 0 && !isFunnelCreating) && (
						<div className="event-funnels-filters">
							<Select
								label="Funnels"
								placeholder="Select a Funnel"
								value={[selectedFunnelIndex]}
								options={funnels.map((funnel, index) => ({
									...funnel,
									label: funnel.name,
									value: index,
								}))}
								onChange={this.handleSelectFunnel}
								dropdownClassName="funnel-selector-dropdown"
								renderOptionAfter={({ option }) => (
									<div className="funnel-select-icons">
										<Icon i="edit" size={14} onClick={(e) => this.handleFunnelEdit(option.id, e)} tooltip-left="Edit" />
										<Icon i="trash" size={14} onClick={(e) => this.handleFunnelRemove(option.id, e)} tooltip-left="Remove" />
									</div>
								)} />
							{tableFetching && <Loader mini size={25} />}
						</div>
					)}

					{(funnels.length === 0 || isFunnelCreating) && (
						<FunnelCreate 
							funnel={editingFunnel}
							app={match.params.id}
							eventMatches={eventMatches}
							colors={this.colors}
							onClose={this.handleFunnelCreateClose}
							onSave={index => this.handleSelectFunnel([index])} />
					)}

					{(selectedFunnel && !tableFetching && !isFunnelCreating && selectedFunnel.events.length > 0) &&
						<Funnel
							selectedFunnel={selectedFunnel}
							selectedFunnelData={selectedFunnelData}
							eventMatches={eventMatches}
							colors={this.colors} />
					}
				</div>
			</div>
		);
	}
}

export default withTitle(Detail, 'Event Analytics', 'id');
