import React, { Fragment, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import truncate from 'lodash/truncate';
import {
	Icon,
	Button,
	Modal,
	AppBox,
} from 'interceptd-ui';

import ShadowImage from '../../../components/ShadowImage';

import Api from '../../../services/api';
import { shadowText, isShadow } from '../../../utils/misc';
import { copyTextToClipboard } from '../../../utils/file';

import './styles/SourceTrackingUrlButton.css';

export default function SourceTrackingUrlButton({ campaign, openDefault }) {
	const [isOpen, setIsOpen] = useState(openDefault);
	const [copiedId, setCopiedId] = useState(null);
	const [showMoreId, setShowMoreId] = useState(null);
	const [publishers, setPublishers] = useState({});
	const trackingUrlLimit = 100;

	useEffect(() => {
		getData();
	}, []);

	const getData = async () => {
		try {
			const publisherResponse = await Api.getPublishers({ size: 1000 });
			const partnerResponse = await Api.getPartners({ size: 1000 });

			const partnerLogoMap = (partnerResponse?.data?.data || []).reduce((acc, curr) => {
				acc[curr.id] = curr.logo;
				return acc;
			}, {});

			const publisherMap = (publisherResponse?.data?.data || []).reduce((acc, curr) => {
				acc[curr.id] = curr;
				acc[curr.id].logo = partnerLogoMap[curr.partner];
				return acc;
			}, {});

			setPublishers(publisherMap);
		} catch (err) {
			console.log(err);
		}
	};

	const toggle = () => {
		setIsOpen(!isOpen)
	};

	const copy = (id, url) => {
		copyTextToClipboard(url);
		setCopiedId(id)
		toast.success('Copied');
	};

	const showMore = (id) => {
		setShowMoreId(showMoreId === id ? null : id);
	};

	return (
		<Fragment>
			<Button onClick={toggle} bgColor="transparent">
				Source Tracking Urls
			</Button>
			<Modal
				open={isOpen}
				onCancel={toggle}
				modalClassName="share-with-partner-modal"
				title="CAMPAIGN'S SOURCE TRACKING lINKS"
				renderHeader={() => (
					<div className="source-tracking-url-modal-header">
						<span>CAMPAIGN'S SOURCE TRACKING lINKS</span>
					</div>
				)}
				renderContent={() => (
					<div className="source-tracking-url-modal">
						<AppBox app={campaign.app_info} isShadow={isShadow()} />
						<div className="source-tracking-list">
							{campaign.sources.map((source) => (
								<div className="source-tracking-list-item">
									<div style={{ display: 'flex' }}>
										<div className="source-tracking-list-item-info">
											<div className="select-value-logo" style={{ marginRight: 10 }}>
												<ShadowImage src={publishers[source.publisher]?.logo} alt={shadowText(publishers[source.publisher]?.name, 'Example Publisher')} />
											</div>
											<div style={{ display: 'flex', flexDirection: 'column' }}>
												<span className="source-tracking-list-item-info-title">Publisher</span>
												<span className="source-tracking-list-item-info-value">{shadowText((publishers[source.publisher]?.name || 'loading...'), 'Example Publisher')}</span>
											</div>
										</div>
										<div style={{ display: 'flex', flexDirection: 'column', marginLeft: 100 }}>
											<span className="source-tracking-list-item-info-title">Source</span>
											<span className="source-tracking-list-item-info-value">{shadowText(source.name, 'Example Source')}</span>
										</div>
									</div>
									<div className="source-tracking-list-item-url">
										<div className="source-tracking-list-item-url-header">
											<span className="source-tracking-list-item-info-title">Tracking URL</span>
											<div className="copy-url-text" onClick={() => copy(source.is, source.tracking_url)}>
												<Icon i={copiedId === source.id ? 'check' : 'copy'} size="13" />
												<span>{copiedId === source.id ? 'Copied' : 'Copy Link'}</span>
											</div>
										</div>
										<span className="source-tracking-list-item-info-value">
											{truncate(shadowText(source.tracking_url, 'Example Tracking Url'), { length: showMoreId === source.id ? 2000 : trackingUrlLimit })}<span onClick={() => showMore(source.id)} className="show-more-text">{showMoreId === source.id ? 'less' : 'more'}</span>
										</span>
									</div>
									{source.impression_tracking_url && (
										<div className="source-tracking-list-item-url">
											<div className="source-tracking-list-item-url-header">
												<span className="source-tracking-list-item-info-title">Impression Tracking URL</span>
												<div className="copy-url-text" onClick={() => copy(source.is, source.impression_tracking_url)}>
													<Icon i={copiedId === source.id ? 'check' : 'copy'} size="13" />
													<span>{copiedId === source.id ? 'Copied' : 'Copy Link'}</span>
												</div>
											</div>
											<span className="source-tracking-list-item-info-value">
												{truncate(shadowText(source.impression_tracking_url, 'Example Tracking Url'), { length: showMoreId === source.id ? 2000 : trackingUrlLimit })}<span onClick={() => showMore(source.id)} className="show-more-text">{showMoreId === source.id ? 'less' : 'more'}</span>
											</span>
										</div>
									)}
								</div>
							))}
						</div>
					</div>
				)}
				renderFooter={() => (
					<div className="share-with-partner-footer">
						<Button mini bgColor="transparent" onClick={toggle}>Close</Button>
					</div>
				)}
			/>
		</Fragment>
	);
}

SourceTrackingUrlButton.defaultProps = {
	openDefault: false,
};