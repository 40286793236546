import React from 'react';
import values from 'lodash/values';
import capitalize from 'lodash/capitalize';
import {
	Icon,
	TagInput,
	Select,
	Input,
	Textarea,
	Checkbox,
} from 'interceptd-ui';

import Grouped from '../../components/Grouped';
import ShadowImage from '../../components/ShadowImage';

import { shadowText } from '../../utils/misc';

import history from '../../services/history';

import { TrackingTools } from '../../constants/Campaign';
import Currencies from '../../constants/currency.json';

import icon from '../../assets/icon-gray.svg';

import './styles/Step3.css';

import withTitle from '../withTitle';

const MacroReplacer = ({
	form,
	app,
	tracking_url,
	partner,
	onConvert,
	type,
}) => {
	const unmatched = [];
	const device = app?.device;
	const macros = (partner?.[type === 'impression' ? 'impression_macros' : 'macros'] || []).filter(macro => {
		return `{${macro.key}}` !== macro.value && (macro.store_type === 'both' || macro.store_type === device);
	});
	const toReplace = macros.filter(macro => tracking_url.indexOf(macro.value) !== -1);
	const replaced = macros.filter(macro => tracking_url.indexOf(`{${macro.key}}`) !== -1);
	let convertedTrackingUrl = tracking_url;
	toReplace.forEach(macro => {
		convertedTrackingUrl = convertedTrackingUrl.split(macro.value).join(`{${macro.key}}`);
	});

	const paramIsExists = /\?(.*?)=./g.test(tracking_url);
	if (paramIsExists) {
		const urlParams = new URLSearchParams(tracking_url.split('?')[1]);
		const paramKeys = urlParams.keys();
		for (let key of paramKeys) {
			const value = (urlParams.get(key) || '').replace(/{/g, '').replace(/}/g, '');
			const isNotMatch = macros.every(macro => macro.key !== value);
			if (isNotMatch) {
				unmatched.push(value);
			}
		}
	}

	const tt = TrackingTools.find(o => o.value === form.tt);
	if (tt.label === 'Appsflyer') {
		try {
			const url = new URL(convertedTrackingUrl);
			url.searchParams.set('clickid', '{publisher_click_id}');
			url.searchParams.set('af_sub5', '{click_id}');
			url.searchParams.set('af_channel', 'Interceptd');

			convertedTrackingUrl = decodeURI(url.href);
		} catch (e) {
			console.log('Invalid URL');
		}
	}

	return (
		<div className="campaign-create-tracking-macros">
			{replaced.length !== 0 && (
				<React.Fragment>
					<div className="campaign-create-tracking-macros-title is-success">
						<Icon i="check-circle" size={16} />
						<span>{replaced.length} macro{replaced.length === 1 ? '' : 's'} converted</span>
					</div>
					<div className="campaign-create-tracking-macros-content is-success">
						<div>New Macro List</div>
						<span>{replaced.map(m => `{${m.key}}`).join(' , ')}</span>
					</div>
				</React.Fragment>
			)}
			{unmatched && unmatched.length > 0 && (
				<React.Fragment>
					<div className="campaign-create-tracking-macros-title is-error">
						<Icon i="info" size={16} />
						<span>{`${unmatched.length} other macros found, but not matched.`}</span>
					</div>
				</React.Fragment>
			)}
			{!paramIsExists ? (
				<React.Fragment>
					<div className="campaign-create-tracking-macros-title is-error">
						<Icon i="info" size={16} />
						<span>We didn't find any macro. Please check your URL.</span>
					</div>
				</React.Fragment>
			) : toReplace.length !== 0 ? (
				<React.Fragment>
					<div className="campaign-create-tracking-macros-title is-info">
						<Icon i="info" size={16} />
						<span>We found {toReplace.length} macro{toReplace.length === 1 ? '' : 's'} in this URL.</span>
						<span className="campaign-create-tracking-macros-convert" onClick={() => onConvert(convertedTrackingUrl)}>Convert All</span>
					</div>
					<div className="campaign-create-tracking-macros-content is-info">
						<div>Macro List</div>
						<span>{toReplace.map(m => m.value).join(' , ')}</span>
					</div>
				</React.Fragment>
			) : null}
		</div>
	)
}

const TrackingURL = ({
	form,
	app,
	name,
	type,
	onChange,
	value,
	partner,
	showPrevButton,
	onPrevButton,
	onClickGenerateButton,
	...rest
}) => {
	const tt = TrackingTools.find(o => o.value === form.tt);
	return (
		<Textarea
			autosize
			name={name}
			label={<div>{capitalize(type)} Tracking URL {type === "impression" && tt.click_to_impression_mapping !== undefined && <span style={{cursor: 'pointer', color: '#5C6BC0'}} onClick={onClickGenerateButton}>Generate from click url</span>}</div>}
			placeholder="Paste your tracking url"
			onChange={e => onChange(e, type)}
			value={value}
			renderBefore={value => showPrevButton && (
				<div className="campaign-create-add" onClick={onPrevButton}>
					<Icon i="copy" size={12} />
					<span>Paste Previous URL</span>
				</div>
			)}
			renderAfter={value => partner && value !== '' && (
				<MacroReplacer
					form={form}
					app={app}
					type={type}
					tracking_url={value}
					partner={partner}
					onConvert={(t) => onChange({ target: { value: t } }, type)} />
			)}
			{...rest} />
	)
}

const Source = ({
	location,
	index,
	length,
	title,
	source,

	editing,
	app,
	form,
	publisherOptions,
	partners,

	onRemove,
	onPublisherChange,
	onNameChange,
	onPayoutCurrencyChange,
	onPayoutChange,
	onEventNameChange,
	onEventPayoutChange,
	onEventRemove,
	onEventAdd,
	onInstallPostbackChange,
	onSourceDisableReattributionChange,
	onOnlyUniqEventInstallPostbackChange,
	onEventPostbackChange,
	onTrackingUrlChange,
	onCopyTrackingUrl,
	onClickGenerateButton
}) => {
	const params = new URLSearchParams(location.search);
	const isEdit = params.get('source');
	const mode = params.get('mode');
	const isFocused =
		isEdit ?
			+isEdit === +(source?.original?.id || 0) :
			mode ?
				mode === 'add' && (index === length - 1 && !source.created) :
				false;

	const focusedField = isFocused ? params.get('field') || 'name' : null;

	const isOpen =
		isEdit ?
			+isEdit === +(source?.original?.id || 0) :
			mode ?
				mode === 'add' && index === length - 1 :
				true;

	const sourceRef = React.useRef();
	const [open, setOpen] = React.useState(isOpen);
	const [height, setHeight] = React.useState(sourceRef?.current?.scrollHeight);

	React.useEffect(() => {
		setHeight(sourceRef?.current?.scrollHeight)
	}, [open, source])

	const randId = Math.random().toString(36).substr(2, 9);

	return (
		<div ref={sourceRef} className={`campaign-create-source ${open ? 'is-open' : ''} ${isFocused ? 'is-focused' : ''}`} style={{ '--height': height }}>
			<div className="campaign-create-source-title" onClick={() => setOpen(!open)}>
				<Icon i={open ? 'chevron-up' : 'chevron-down'} size={20} className="campaign-create-source-toggle" />
				{source.name || title}
				{(length > 1 && !source.created) && <Icon i="trash" size={20} tooltip-left="Remove Source" onClick={onRemove} />}
			</div>
			<Grouped>
				<Select
					clearable={false}
					disabled={source.created}
					label="Publisher"
					options={publisherOptions}
					value={[source.publisher || null]}
					onChange={([val]) => onPublisherChange(val)}
					renderOptionBefore={({ option }) => (
						<div className="select-option-logo">
							<ShadowImage src={option.logo || icon} alt={option.label} />
						</div>
					)}
					wrapperClassName="select-with-value-logo"
					renderBefore={({ value }) => {
						const partner = publisherOptions.find(o => o.value === value[0]);
						return partner && (
							<div className="select-value-logo">
								<ShadowImage src={partner.logo || icon} alt={partner.label} />
							</div>
						)
					}} />
				<Input
					label="Name"
					name={`source-${randId}-name`}
					value={shadowText(source.name, 'Example Source')}
					onChange={onNameChange}
					autoFocus={focusedField === 'name'} />
			</Grouped>
			{form.type !== 'CPM' && (
				<React.Fragment>
					<Select
						clearable={false}
						label="Payout Currency"
						options={values(Currencies).map(currency => ({
							value: currency.code,
							label: `${currency.symbol} - ${currency.name}`,
						}))}
						value={[source.payout_currency]}
						onChange={([val]) => onPayoutCurrencyChange(val)} />
					{form.type === 'CPI' ? (
						<Input
							type="number"
							label={`Payout (${Currencies[source.payout_currency].symbol})`}
							name={`source-${randId}-payout`}
							step="0.1"
							min="0"
							value={source.bid}
							onChange={onPayoutChange}
							autoFocus={focusedField === 'bid'} />
					) : (
							<React.Fragment>
								{(source.event_payouts || []).map((event, index) => (
									<div className="campaign-create-source-event" key={`payout-${index}`}>
										<Grouped className="campaign-create-source-events">
											<Input
												label="Event Name"
												placeholder="Purchase"
												name={`source-${randId}-event-name`}
												value={event.name}
												onChange={e => onEventNameChange(e, index)} />
											<Input
												type="number"
												label="Payout"
												placeholder="1.3"
												name={`source-${randId}-event-payout`}
												step="0.1"
												min="0"
												value={event.bid}
												onChange={e => onEventPayoutChange(e, index)}
												autoFocus={focusedField === 'bid'} />
											<Icon i="trash" size={18} tooltip-left="Remove Event" onClick={() => onEventRemove(index)} />
										</Grouped>
									</div>
								))}
								<div className="campaign-create-add" onClick={onEventAdd}>
									<Icon i="plus" size={12} />
									<span>Add Event</span>
								</div>
							</React.Fragment>
						)}
					<div className="checkbox-wrapper">
						<Checkbox
							id={`source-${randId}-disable-reattribution`}
							checked={source.disable_reattribution}
							onChange={onSourceDisableReattributionChange} />
						<label className="checkbox-label" htmlFor={`source-${randId}-install-postback`}>Disable Reattribution</label>
					</div>
					{(form.type === 'CPA' && form.tt !== 4 /** is apssflayer */) && <div className="checkbox-wrapper">
						<Checkbox
							id={`source-${randId}-install-postback`}
							checked={source.disable_install_postback}
							onChange={onInstallPostbackChange} />
						<label className="checkbox-label" htmlFor={`source-${randId}-install-postback`}>Disable Install Postback</label>
					</div>}
					{(form.type === 'CPA' && form.tt !== 4 /** is apssflayer */) && <div className="checkbox-wrapper">
						<Checkbox
							id={`source-only-uniq-${randId}-install-postback`}
							checked={!!source.unique_events_to_postback}
							onChange={onOnlyUniqEventInstallPostbackChange} />
						<label className="checkbox-label" htmlFor={`source-only-uniq-${randId}-install-postback`}>Postback Only Unique Events</label>
					</div>}
					{(form.tt !== 4) && <div>
						<TagInput
							name={`source-${randId}-selected-events`}
							label="Selected Events for Postback (if left empty, all events will be postbacked)"
							placeholder="Enter the event name and press Enter"
							value={source.selected_events}
							onChange={onEventPostbackChange}
							addOnBlur
							inputProps={{
								autoFocus: focusedField === 'selected_events'
							}} />
					</div>}
				</React.Fragment>
			)}
			<div>
				<TrackingURL
					form={form}
					app={app}
					autosize
					type="click"
					name={`source-${randId}-tracking`}
					onChange={onTrackingUrlChange}
					value={source.redirect_url || ''}
					partner={partners.find(p => p.id === source.publisher)}
					showPrevButton={index !== 0}
					onPrevButton={(e) => onCopyTrackingUrl(e, 'click')}
					autoFocus={focusedField === 'tracking_url'}/>
			</div>
			<div>
				<TrackingURL
					form={form}
					app={app}
					autosize
					type="impression"
					name={`source-imp-${randId}-tracking`}
					onChange={onTrackingUrlChange}
					value={source.impression_redirect_url || ''}
					partner={partners.find(p => p.id === source.publisher)}
					showPrevButton={index !== 0}
					onPrevButton={(e) => onCopyTrackingUrl(e, 'impression')}
					autoFocus={focusedField === 'impression_redirect_url'}
					onClickGenerateButton={onClickGenerateButton}/>
			</div>
		</div>
	)
}

class Step3 extends React.Component {
	componentDidMount = () => {
		const { seenFirstStep, editing } = this.props;

		if (!editing && !seenFirstStep)
			return history.push('/create')
	}

	render() {
		const {
			location,
			editing,

			form,
			app,
			partners,
			sources,

			onSourceRemove,
			onSourceAdd,
			onSourcePublisherChange,
			onSourceNameChange,
			onSourcePayoutCurrencyChange,
			onSourcePayoutChange,
			onSourceEventAdd,
			onSourceEventRemove,
			onSourceEventNameChange,
			onSourceEventPayoutChange,
			onSourceInstallPostbackChange,
			onSourceDisableReattributionChange,
			onOnlyUniqEventInstallPostbackChange,
			onSourceEventPostbackChange,
			onSourceTrackingUrlChange,
			onSourceCopyTrackingUrl,
		} = this.props;

		return (
			<div className="campaign-create-inner">
				{sources.map((source, index) => (
					<Source
						key={`source-${index}`}
						location={location}
						index={index}
						length={sources.length}
						title={`Source ${index + 1}`}
						source={source}
						editing={editing}
						app={app}
						form={form}
						partners={partners}
						publisherOptions={partners.filter(p => form.type !== 'CPM' || p.type === 'programmatic').map(partner => ({
							value: partner.id,
							label: shadowText(partner.name, 'Example Publisher'),
							logo: partner.logo,
						}))}
						onRemove={() => onSourceRemove(index)}
						onPublisherChange={val => onSourcePublisherChange(index, val)}
						onNameChange={e => onSourceNameChange(index, e.target.value)}
						onPayoutCurrencyChange={val => onSourcePayoutCurrencyChange(index, val)}
						onPayoutChange={e => onSourcePayoutChange(index, e.target.value)}
						onEventNameChange={(e, eventIndex) => onSourceEventNameChange(index, eventIndex, e.target.value)}
						onEventPayoutChange={(e, eventIndex) => onSourceEventPayoutChange(index, eventIndex, e.target.value)}
						onEventRemove={eventIndex => onSourceEventRemove(index, eventIndex)}
						onEventAdd={e => onSourceEventAdd(index)}
						onInstallPostbackChange={e => onSourceInstallPostbackChange(index, e.target.checked)}
						onSourceDisableReattributionChange={e => onSourceDisableReattributionChange(index, e.target.checked)}
						onOnlyUniqEventInstallPostbackChange={e => onOnlyUniqEventInstallPostbackChange(index, e.target.checked)}
						onEventPostbackChange={val => onSourceEventPostbackChange(index, val)}
						onTrackingUrlChange={(e, type) => onSourceTrackingUrlChange(index, e.target.value, type)}
						onCopyTrackingUrl={(e, type) => onSourceCopyTrackingUrl(index, type)}
						onClickGenerateButton={() => {
							const tt = TrackingTools.find(o => o.value === form.tt);

							const mapping = tt.click_to_impression_mapping;
							var impression_redirect_url = source.redirect_url;

							if (mapping === undefined || impression_redirect_url === "") {
								return
							}

							Object.keys(mapping).map(function(key) {		
								impression_redirect_url = impression_redirect_url.replace(key, mapping[key]);
							 });
							 
							if (tt.label == "Appsflyer" && !impression_redirect_url.includes("af_sub5"))
							{
								impression_redirect_url = impression_redirect_url.replace("?", "?af_sub5={imp_id}&");
							}

							onSourceTrackingUrlChange(index, impression_redirect_url, "impression");	
						}} />
				))}
				<div className="campaign-create-add" onClick={onSourceAdd}>
					<Icon i="plus" size={12} />
					<span>Add Another Source</span>
				</div>
			</div>
		)
	}
}

export default withTitle(Step3, null, null, match => {
	const editing = match.url.indexOf('/edit') !== -1;
	return `Interceptd - Campaign ${editing ? 'Edit' : 'Create'} ${editing ? `for ${match.params.id} - ` : ' - '} Sources`
});
