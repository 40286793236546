import React from 'react';

import './styles/Grouped.css';

const Grouped = ({ className, children, ...rest }) => (
	<div className={`grouped ${className || ''}`} {...rest}>
		{children}
	</div>
)

export default Grouped;