import { toast } from 'react-toastify';
import get from 'lodash/get';

import Api from './api';
import Local from './localStorage';

class Rulesets {
	getRulesets() {
		Api.getRulesets({
			page: 1,
			size: 250
		}).then(res => {
			Local.setItem('rulesets', get(res, 'data.data', []))
		}).catch((err) => toast.error(get(err, 'data.error.message', `Couldn't fetch 'Rulesets`)));

		Api.getCampaignRulesets().then(res => {
			Local.setItem('campaignRulesets', get(res, 'data.data', []))
		}).catch((err) => toast.error(get(err, 'data.error.message', `Couldn't fetch 'Campaign Rulesets`)));
	}
}

export default new Rulesets();
