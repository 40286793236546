import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import find from 'lodash/find';
import sumBy from 'lodash/sumBy';
import { Icon } from 'interceptd-ui';

import Api from '../../services/api';
import Local from '../../services/localStorage';
import Rulesets from '../../services/rulesets';

import { RuleNameMaps } from '../../constants/Rules';

import './styles/RuleSetModal.css';

class RuleSetModal extends React.Component {
	state = {
		search: '',
		rulesets: Local.getItem('rulesets') || [],
		supported_rules: Local.getItem('supported_rules') || [],
	}

	timer = null
	closing = null
	closeTimeout = 450
	rulesetListener = null
	supportedRulesListener = null

	componentDidMount = () => {
		this.rulesetListener = Local.listen('rulesets', () => {
			this.setState({
				rulesets: Local.getItem('rulesets') || [],
			})
		})

		this.supportedRulesListener = Local.listen('supported_rules', () => {
			this.setState({
				supported_rules: Local.getItem('supported_rules') || [],
			})
		})
	}

	componentWillUnmount = () => {
		if ( this.rulesetListener )
			Local.unlisten(this.rulesetListener)

		if ( this.supportedRulesListener )
			Local.unlisten(this.supportedRulesListener)
	}

	stopPropagation = e => {
		e.stopPropagation();
		return false;
	}

	handleSearch = event => {
		this.setState({
			search: event.target.value
		})
	}

	handleSelectRuleSet = set => {
		const { campaign, source, onReload } = this.props;
		const { supported_rules } = this.state;
		let containInvalidRules = false;

		if (campaign.type !== 'CPM') {
			(set?.detail?.rules || []).forEach((r) => {
				const rule = supported_rules.find(sr => sr['short-name'] === r.name);
				if (rule && rule.supported_campaign_types.indexOf(campaign.type) === -1) {
					containInvalidRules = true;
				}
			});
		}

		if (containInvalidRules) {
			return toast.error(`This rule is not valid for ${campaign.type} ${source ? 'sources' : 'campaigns'}.`);
		}

		const payload = {
			campaign_id: campaign.id,
		};

		if (source) payload.source_id = source;

		Api.applyRuleset(set.id, payload)
			.then(response => {
				onReload && onReload();
				toast.success(`${set.name === 'Default' ? 'Default ruleset' : 'Ruleset'} selected for ${source ? 'source' : 'campaign'}.`);
			})
			.catch(({ response }) => {
				toast.error(`Couldn't select ruleset for ${source ? 'source' : 'campaign'}.`);
			})
	}

	handleUnselectRule = set => {
		const { rulesets } = this.state;
		const { campaign, source } = this.props;

		const defaultRuleset = rulesets.filter(set => set.name === 'Default');

		Api.unapplyRuleset(set.id, {
			campaign_id: campaign.id,
			source_id: source,
		})
			.then(response => {
				if (defaultRuleset.length !== 0) {
					toast.success(`Ruleset removed from ${source ? 'source' : 'campaign'}.`);
					this.handleSelectRuleSet(defaultRuleset[0]);
				} else {
					Rulesets.getRulesets();
					toast.success(`Ruleset removed from ${source ? 'source' : 'campaign'}.`);
				}
			})
			.catch(({ response }) => {
				toast.error(`Couldn't remove ruleset from ${source ? 'source' : 'campaign'}.`);
			})
	}

	handleMarkasSeen = () => {
		const { campaign, onReload } = this.props;

		Api.alarmSeen(campaign.id, {
			type: 'source'
		})
			.then(response => {
				onReload && onReload();
				toast.success('Alarms marked as seen');
			})
			.catch(() => {
				toast.error(`Couldn't mark alarms as seen`);
			})
	}

	render() {
		const { search, rulesets, supported_rules } = this.state;
		const { alarms, ruleset, onClose } = this.props;

		return (
			<div className={`ruleset-modal`}>
				<div className="ruleset-modal-inner">
					<div className="ruleset-modal-header">
						<div className="ruleset-modal-title">Rulesets</div>
						<div className="ruleset-modal-add">
							<Link to={`/account/manage-rulesets?return_url=${window.location.pathname}`} className="rueleset-link">
								<span>Manage Rulesets</span>
							</Link>
						</div>
					</div>

					<div className="ruleset-modal-search">
						<Icon i="search" size={14} />
						<input type="text" placeholder="Search" spellCheck={false} onChange={this.handleSearch} value={search} data-event-category="Ruleset" data-event-action="Ruleset filter input clicked" />
					</div>

					<div className="ruleset-modal-list">
						{ruleset && (
							<div className={`ruleset-modal-set active`} key={ruleset.id}>
								<div className="ruleset-modal-set-name">{ruleset.name}</div>
								{alarms.length > 0 &&
									<div className="ruleset-modal-set-count">{sumBy(alarms, 'alarm_count')}</div>
								}
								{ruleset.name !== 'Default' &&
									<Icon
										i="shield-off"
										tooltip-left="Unapply Ruleset"
										size={16}
										onClick={() => this.handleUnselectRule(ruleset)}
										data-event-category="Ruleset"
										data-event-action="Ruleset unapply icon clicked"
									/>
								}
								{alarms.length > 0 &&
									<div className="ruleset-modal-alarms">
										{alarms.map((alarm, key) => (
											<div className="ruleset-modal-alarm" key={key}>
												<div className="ruleset-modal-alarm-name">{find(supported_rules, ['short-name', alarm.alarm_type]) ? (RuleNameMaps[find(supported_rules, ['short-name', alarm.alarm_type]).name] || find(supported_rules, ['short-name', alarm.alarm_type]).name) : alarm.alarm_type}</div>
												<div className="ruleset-modal-alarm-count">{alarm.alarm_count}</div>
											</div>
										))}
										<div className="ruleset-modal-alarms-clear" onClick={this.handleMarkasSeen}>
											<Icon i="eye" size={12} /> Mark as seen
										</div>
									</div>
								}
							</div>
						)}
						{rulesets.filter(set => !ruleset || ruleset.id !== set.id).filter(set => search !== '' ? set.name.toLowerCase().indexOf(search.toLowerCase()) !== -1 : true).map(set => (
							<div className={`ruleset-modal-set`} key={set.id}>
								<div className="ruleset-modal-set-name">{set.name}</div>
								<div className="ruleset-modal-set-select" onClick={() => this.handleSelectRuleSet(set)} data-event-category="Ruleset" data-event-action="Ruleset select button clicked">Select</div>
							</div>
						))}
					</div>

					<div className="ruleset-footer">
						<div className="ruleset-footer-left">
							<div onClick={onClose}>Close</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default RuleSetModal;
