import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ToastContainer, Slide, toast } from 'react-toastify';
import moment from 'moment';
import Intercom from 'react-intercom';
import get from 'lodash/get';

import Header from './components/Header';
import Footer from './components/Footer';
import { default as SavingsComponent } from './components/Savings';

import ComplianceModal from './pages/Modals/ComplianceModal';

import { default as MyAccount } from './pages/Auth/MyAccount';
import { default as CampaignCreate } from './pages/CampaignCreate/Create';
import { default as CampaignList } from './pages/Campaign/List';
import { default as ShortenerList } from './pages/Shortener/List';
import { default as ReportList } from './pages/Report/ReportList';
import { default as EventAnalyticsList } from './pages/EventAnalytics/List';
import { default as DailyPerformance } from './pages/DailyPerformance';
import { default as Savings } from './pages/Savings/Savings';

import { privateRoutes, publicRoutes } from './routes';
import withTracker from './withTracker';

import { Countries } from './constants/Countries';
import { ReportTypes, ListTypes } from './constants/Campaign';

import Api from './services/api';
import Auth from './services/auth';
import Local from './services/localStorage';
import Settings from './services/settings';
import SendEvent from './services/events';
import Rulesets from './services/rulesets';

import config from './services/config.json';

import { checkPaymentStatus, isDemoAccount } from './utils/misc';

import 'react-toastify/dist/ReactToastify.min.css';
import 'interceptd-ui/dist/interceptd-ui.css';
import './colors.css';
import './global.css';

const PublicRoutes = ({ component: Component, path, nested, ...rest }) => (
	<Route {...rest} render={props => {
		if ( !Component ) return null;

		const C = withTracker(Component, {});

		return <C {...props} />;
	}} />
)

const PrivateRoute = ({ component: Component, nested, ...rest }) => (
	<Route {...rest} render={props => {
		if ( !Component ) return null;

		const C = withTracker(Component, Local.getItem('me') && {
			userId: Local.getItem('me').id
		});

		if (!Auth.isAuthenticated() && !isDemoAccount()) {
			return (
				<Redirect to={{
					pathname: '/welcome',
					state: { from: props.location }
				}} />
			);
		}

		return (
			<section className="page-wrapper">
				{props.location.pathname !== '/' && props.location.pathname.substr(-1) === '/' ? (
					<Redirect to={props.location.pathname.substr(0, props.location.pathname.length - 1)} />
				): (
					nested ? <Component {...props} /> : <C {...props} />
				)}
			</section>
		)
	}} />
)

class App extends Component {
	state = {
		me: null,
		token: null,
	}

	intercomId = config[window.location.hostname].intercom

	componentDidMount = () => {
		moment.updateLocale('en', {
			relativeTime : {
				future: "in %s",
				past:   "%s ago",
				s  : 'a few sec',
				ss : '%d secs',
				m:  "a min",
				mm: "%d mins",
				h:  "an hour",
				hh: "%d hours",
				d:  "a day",
				dd: "%d days",
				M:  "a month",
				MM: "%d months",
				y:  "a year",
				yy: "%d years"
			}
		});
		
		this.checkDependencies()
		this.FSidentify();
		window.addEventListener('click', this.registerEvent);
		window.hideIntercomFrame = this.hideIntercom;
		window.showIntercomFrame = this.showIntercom;
	}

	componentDidUpdate = (prevProps) => {
		this.FSidentify()
		if ( prevProps.location.pathname !== this.props.location.pathname )
			window.scroll(0, 0);
	}

	componentWillUnmount = () => {
		window.removeEventListener('click', this.registerEvent);
	}

	hideIntercom = () => {
		const intercomFrame = document.querySelector('.intercom-app');
		if ( intercomFrame ) {
			intercomFrame.style.opacity = 0;
			intercomFrame.style.visibility = 'hidden';
			intercomFrame.style.pointerEvents = 'none';
		}
	}

	showIntercom = () => {
		const intercomFrame = document.querySelector('.intercom-app');
		if ( intercomFrame ) {
			intercomFrame.style.opacity = 1;
			intercomFrame.style.visibility = 'visible';
			intercomFrame.style.pointerEvents = 'all';
		}
	}

	registerEvent = event => {
		if ( event.target.dataset.eventCategory && event.target.dataset.eventAction ) {
			SendEvent({
				category: event.target.dataset.eventCategory,
				action: event.target.dataset.eventAction,
				label: event.target.dataset.eventLabel,
				nonInteraction: !!event.target.dataset.eventNonInteraction,
			})
		}
	}

	setUserData = (data) => {
		Settings.setLocalSettings(data.settings || {});
	}

	checkDependencies = () => {
		if ( Local.getItem('token') ||	isDemoAccount()) {
			Local.setItem('countries', Countries);
			Api.getMe().then(res => {
				const data = res?.data?.data ?? {};
				const user = data.user ?? {};
				const account = data.account ?? {};
				const me = {
					...user,
					account_id: account.id,
					company_name: account.company_name,
					company_website: account.company_website,
					plans: account.plans,
					settings: {
						account: account.settings || {},
						user: user.settings || {},
					},
				};

				checkPaymentStatus(me);
				Local.setItem('me', me);
				this.setUserData(me);
				this.setState({ me });
			}).catch(Auth.logout);

			!isDemoAccount() && Rulesets.getRulesets();

			Api.getSupportedRules().then(res => {
				Local.setItem('supported_rules', get(res, 'data.data', []))
			}).catch((err) => toast.error(get(err, 'data.error.message', `Couldn't fetch 'Supported Rules'`)));

			Api.getSuppertedCampaignRules().then(res => {
				Local.setItem('supported_campaign_rules', get(res, 'data.data', []))
			}).catch((err) => toast.error(get(err, 'data.error.message', `Couldn't fetch 'Supported Campaign Rules'`)));

			Api.getTrackingMacros().then(res => {
				Local.setItem('tracking_macros', get(res, 'data.data', []))
			}).catch((err) => toast.error(get(err, 'data.error.message', `Couldn't fetch 'Tracking Macros'`)));
		}
	}

	getIntercomData = () => {
		if ( Local.getItem('me') ) {
			const me = Local.getItem('me');
			return ({
				user_id: me.id,
				email: me.email,
				name: me.fullname,
				'User Plan Type': me?.plans?.interceptd,
				created_at: me.ts_registration,
				unsubscribed_from_emails: Settings.get('unsubscribed_from_emails'),
				slack_integrated: Settings.get('notification') && Settings.get('notification').slack === 1,
			})
		}

		return ({});
	}

	FSidentify = () => {
		const { user_id, name, email, ...userData } = this.getIntercomData();

		if ( user_id && window['_fs_namespace'] && window['_fs_namespace'].identify ) {
			window['_fs_namespace'].identify(user_id, {
				displayName: name,
				email: email,
				...userData,
			});
		}

		if ( user_id && window.bugsnagClient ) {
			window.bugsnagClient.user = {
				id: user_id,
				name,
				email,
			}
			window.bugsnagClient.metaData = {
				...window.bugsnagClient.metaData,
				user: {
					...userData,
				},
			}
		}
	}

	render() {
		const { me, token } = this.state;
		const { location } = this.props;

		if ( Local.getItem('token') && !me )
			return false;

		const isSurvey = location.pathname === '/survey';

		return (
			<div
				className={`
					interceptd
					${(Auth.isAuthenticated() || isDemoAccount())  ? 'page-private' : ''}
					page${location.pathname.replace('/', '-')}
				`}>
				{(Auth.isAuthenticated() || isDemoAccount() || isSurvey) && (
					<Header isSurvey={isSurvey} me={me} token={Local.getItem('token')} />
				)}
				{Auth.isAuthenticated() && !isDemoAccount() && <SavingsComponent />}
				<Switch>
					{publicRoutes.map((route, i) => <PublicRoutes key={i} {...route} />)}
					{privateRoutes.map((route, i) => <PrivateRoute key={i} {...route} />)}
				</Switch>
				<PrivateRoute exact nested path={[
					'/',
					'/archive',
					...(ListTypes.map(type => `/${type.value}`))
				]} component={CampaignList} />
				<PrivateRoute nested path="/account" component={MyAccount} />
				<PrivateRoute nested path="/create/:page?" component={CampaignCreate} />
				<PrivateRoute nested path="/campaign/:id/edit/:page?" component={CampaignCreate} />
				<PrivateRoute nested path="/secure-link/:id?" component={ShortenerList} />
				<PrivateRoute nested path="/event-analytics/:id?" component={EventAnalyticsList} />
				<PrivateRoute exact nested path={[
					'/reports/',
					...(ReportTypes.map(type => `/reports/${type.value}`))
				]} component={ReportList} />
				<PrivateRoute exact nested path={[
					'/daily-performance',
					'/daily-performance/table'
				]} component={DailyPerformance} />
				<PrivateRoute exact nested path={[
					'/savings',
					'/savings/publishers',
					'/savings/apps',
				]} component={Savings} />
				{(Auth.isAuthenticated() || isDemoAccount()) && <Footer />}

				<ToastContainer
					position="bottom-left"
					transition={Slide}
					closeButton={false}
					newestOnTop={true}
					autoClose={3000}
				/>

				{!isDemoAccount() && <ComplianceModal />}

				{ window.location.hostname.indexOf('local') === -1 && !Local.getItem('login_as') && !isDemoAccount() ? (
					<Intercom appID={this.intercomId} {...this.getIntercomData()} />
				) : null}
			</div>
		);
	}
}

export default withRouter(App);
