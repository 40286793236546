import React from 'react';

import { MultiCampContext } from './context';

import Upload from './Upload';
import Match from './Match';
import Create from './Create';

import './styles/MultiCampaign.css';

class MultiCampaign extends React.Component {
	state = {
		step: 'upload',
		parsedResults: {},
		headerMapping: {},
		setState: state => this.setState(state)
	}

	subtitles = {
		upload: 'You can create multiple campaigns by uploading a CSV file',
		match: 'Match column to create multiple campaigns',
		create: 'Creating your campaigns'
	}

	render() {
		const { step } = this.state;

		return (
			<MultiCampContext.Provider value={this.state}>
				<div className="multi-campaign">
					<div className="login-title">
						<h1>Create Multiple Campaign</h1>
						<h2>{this.subtitles[step]}</h2>
					</div>
					{
						step === 'upload' ? <Upload /> :
						step === 'match' ? <Match /> :
						step === 'create' ? <Create /> :
						null
					}
				</div>
			</MultiCampContext.Provider>
		)
	}
}

export default MultiCampaign;
