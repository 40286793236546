import React from 'react';
import { NavLink } from 'react-router-dom';
import { Navigation } from 'interceptd-ui';

import Auth from '../../services/auth';
import config from '../../services/config.json';

import './styles/Header.css';

import logoColor from '../../assets/logo.svg';
import icon from '../../assets/icon-white.svg';
import { isDemoAccount } from '../../utils/misc';

const Header = ({ isSurvey, me, token }) => {
	if (!Auth.isAuthenticated() && !isDemoAccount() && isSurvey) {
		return (
			<header className="login-header">
				<a href="https://interceptd.com">
					<img src={logoColor} alt="Interceptd" />
				</a>
			</header>
		);
	}

	const isCampaignActive = (match, location) => (match || location.pathname.split('/')[1] === 'campaign')
	const isFraudActive = (match, location) => (match || location.pathname.split('/')[1] === 'fraud-insights' || location.pathname.split('/')[1] === 'survey')
	const isFreeUser = me && me.plans?.interceptd === 'free';
	const campaignPath = isFreeUser
		? '/start-free-trial'
		: '/';

	const dailyPerformanceCol = isDemoAccount()
		? []
		: [{
			component: NavLink,
			linkProps: {
				to: '/daily-performance',
				activeClassName: 'is-active',
				exact: true,
				'data-event-category': 'Navigation',
				'data-event-action': 'Daily Performance link clicked',
			},
			icon: 'activity',
			title: 'Daily Performance',
		}];

	return (
		<Navigation
			logo={(
				<NavLink to={campaignPath} className="navigation-logo">
					<img src={icon} alt="Interceptd Icon" data-event-category="Navigation" data-event-action="Icon clicked" />
				</NavLink>
			)}
			create={!isFreeUser ? {
				component: NavLink,
				linkProps: {
					to: '/create',
					activeClassName: 'is-active',
					exact: true,
					'data-event-category': 'Navigation',
					'data-event-action': 'New Campaign link clicked',
				},
				icon: 'plus',
				title: 'New Campaign',
			} : null}
			topMenuItems={[
				{
					component: NavLink,
					linkProps: {
						to: campaignPath,
						activeClassName: 'is-active',
						exact: true,
						isActive: isCampaignActive,
						'data-event-category': 'Navigation',
						'data-event-action': 'Campaigns link clicked',
					},
					icon: isFreeUser ? 'calendar' : 'list',
					title: isFreeUser ? 'Start Free Trial' : 'Campaigns',
				},
				...(!isFreeUser ? [
					...dailyPerformanceCol,
					{
						component: NavLink,
						linkProps: {
							to: '/reports/impression',
							activeClassName: 'is-active',
							exact: true,
							'data-event-category': 'Navigation',
							'data-event-action': 'Reports link clicked',
						},
						icon: 'pie-chart',
						title: 'Reports',
					},
					{
						component: NavLink,
						linkProps: {
							to: '/event-analytics',
							activeClassName: 'is-active',
							exact: true,
							'data-event-category': 'Navigation',
							'data-event-action': 'Event Analytics link clicked',
						},
						icon: 'filter',
						title: 'Event Analytics',
					},
					{
						component: NavLink,
						linkProps: {
							to: '/account',
							activeClassName: 'is-active',
							exact: true,
							'data-event-category': 'Navigation',
							'data-event-action': 'Settings link clicked',
						},
						icon: 'settings',
						title: 'Settings',
					},
					{
						component: NavLink,
						linkProps: {
							className: 'navigation-menu-item navigation-savings',
							to: '/savings',
							activeClassName: 'is-active',
							'data-event-category': 'Navigation',
							'data-event-action': 'Savings link clicked',
							style: {
								display: 'none',
							},
						},
						icon: 'savings',
						title: 'Estimated Savings',
					},
				] : [
					{
						component: NavLink,
						linkProps: {
							to: '/event-analytics',
							activeClassName: 'is-active',
							exact: true,
							isActive: isFraudActive,
							'data-event-category': 'Navigation',
							'data-event-action': 'Fraud Health Assessment link clicked',
						},
						icon: 'heart',
						title: 'Fraud Health Assessment',
					}
				]),
			]}
			bottomMenuItems={[]}
			user={me ? {
				name: me?.fullname,
				email: me?.email,
				dropdownItems: [
					{
						component: 'a',
						linkProps: {
							href: 'https://intercom.help/interceptd/en/',
							target: '_blank',
							rel: 'noopener noreferrer',
						},
						icon: 'help-circle',
						title: 'Help',
					},
					{
						seperator: true,
					},
					{
						component: NavLink,
						linkProps: {
							to: '/logout',
							activeClassName: 'is-active',
							exact: true,
						},
						icon: 'log-out',
						title: 'Logout',
					},
				],
			} : null}
		/>
	);
};

export default Header;
