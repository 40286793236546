import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import { Icon, Tooltip, AppIcon } from 'interceptd-ui';

import { shadowText, isShadow } from '../../utils/misc';

export const columns = (apps = {}, scores) => [
	{
		Header: 'Name',
		id: 'app_name',
		accessor: 'app_name',
		cast: 'string',
		minWidth: 200,
		style: {
			overflow: 'visible',
		},
		Cell: props => {
			return (
				<span className="campaign-table-name">
					<AppIcon app={apps[props.original.id]} showStore={props.original.campaigns && props.original.campaigns.length > 1 && (props.original.campaigns.every(c => /apple.com/gi.test(c.store_url)) || props.original.campaigns.every(c => /google.com/gi.test(c.store_url)))} isShadow={isShadow()} />
					{props.original.withoutReport ? <span>{shadowText((props.value || get(apps, `${props.original.id}.title`, '-')), 'Example Name')}</span> : <Link to={`/fraud-insights/${props.original.id}`}>{shadowText((props.value || get(apps, `${props.original.id}.title`, '-')), 'Example Name')}</Link>}
				</span>
			)
		}
	},
	{
		Header: 'Status',
		id: 'status',
		accessor: 'status',
		width: 160,
		Cell: props => props.original.withoutReport ? '-' : (
			<div className="fraud-insight-status">
				<span className={props.value}>{props.value}</span>
				{props.value === 'pending' && <span className="description">(Will be ready in 24 hours)</span>}
			</div>
		),
	},
	{
		Header: 'Fraud Risk Level',
		id: 'status',
		accessor: 'status',
		width: 140,
		Cell: props => (
			<div className="fraud-insight-status">
				<span className={`risk-level ${((scores[props.original.id] && scores[props.original.id].fraudScore) || '-').replace(/ /g, '-').toLowerCase()}`}>{(scores[props.original.id] && `${scores[props.original.id].fraudScore} ${scores[props.original.id].fraudPercent}`) || '-'}</span>
			</div>
		),
	},
	{
		Header: 'Created',
		id: 'ts_created',
		accessor: 'ts_created',
		cast: 'date',
		Cell: props => <Tooltip position="top" className="display-inline" tooltip={moment(props.value).format('MMM D, YYYY [at] h:mmA')}>{moment(props.value).fromNow()}</Tooltip>
	},
	{
		Header: 'Updated',
		id: 'ts_updated',
		accessor: 'ts_updated',
		cast: 'date',
		Cell: props => props.value === '-' ? props.value : <Tooltip position="top" className="display-inline" tooltip={moment(props.value).format('MMM D, YYYY [at] h:mmA')}>{moment(props.value).fromNow()}</Tooltip>
	},
	{
		Header: 'Unique Campaigns',
		id: 'unique_campaign',
		accessor: 'unique_campaign',
		width: 300,
		Cell: props => props.original.withoutReport ? '-' : (props.original.status !== 'pending' ? props.value || '-' : <Icon i="clock" size={17} style={{ color: 'var(--gray)' }} />)
	},
	{
		Header: 'Redirections',
		id: 'redirections',
		accessor: 'redirection_avg',
		width: 200,
		style: {
			overflow: 'visible',
			whiteSpace: 'normal',
		},
		Cell: props => props.original.withoutReport ? '-' : (props.original.status !== 'pending' ? props.value ? <span><b>Avg:</b> {props.original.redirection_avg || 0} <b>Max:</b> {props.original.redirection_max || 0}</span> : '-' : <Icon i="clock" size={17} style={{ color: 'var(--gray)' }} />)
	},
	{
		Header: 'CPI',
		id: 'cpi',
		accessor: 'cpi_avg',
		width: 200,
		style: {
			overflow: 'visible',
			whiteSpace: 'normal',
		},
		Cell: props => props.original.withoutReport ? '-' : (props.original.status !== 'pending' ? props.value ? <span><b>Min:</b> {(props.original.cpi_min || 0).toFixed(2)} <b>Max:</b> {(props.original.cpi_max || 0).toFixed(2)} <br /><b>Avg:</b> {(props.original.cpi_avg || 0).toFixed(2)} <b>Max-Min:</b> {(props.original.cpi_max_min || 0).toFixed(2)}</span> : '-' : <Icon i="clock" size={17} style={{ color: 'var(--gray)' }} />)
	},
];