import React, { Fragment } from 'react';
import { Icon, AppBox } from 'interceptd-ui';

import ShadowImage from '../../components/ShadowImage';

import history from '../../services/history';

import { shadowText, isShadow } from '../../utils/misc';

import Currencies from '../../constants/currency.json';

import icon from '../../assets/icon-gray.svg';

import './styles/Step4.css';

import withTitle from '../withTitle';

const SummaryBox = ({ className, title, editUrl, children, ...rest }) => (
	<div className={`campaign-create-summary-box ${className || ''}`} {...rest}>
		{title && <div className="campaign-create-summary-box-title">
			{title}
			{editUrl && <Icon i="edit-3" size={14} onClick={() => history.push(editUrl)} />}
		</div>}
		<div className="campaign-create-summary-box-inner">
			{children}
		</div>
	</div>
)

const SummaryCell = ({ className, isBlock, isThird, title, renderBefore, children, ...rest }) => (
	<div className={`campaign-create-summary-cell ${className || ''} ${isBlock ? 'is-block' : ''} ${isThird ? 'is-third' : ''}`} {...rest}>
		{renderBefore && renderBefore()}
		<div>
			{title && <div className="campaign-create-summary-cell-title">{title}</div>}
			<div className="campaign-create-summary-cell-content">{children}</div>
		</div>
	</div>
);

class Step4 extends React.Component {
	componentDidMount = () => {
		const { seenFirstStep, editing } = this.props;

		if (!editing && !seenFirstStep)
			return history.push('/create')
	}

	render() {
		const {
			match: { params: { id } },
			editing,
			form,
			app,
			fullStoreUrl,
			tt,
			partners,
			sources,
		} = this.props;

		const baseUrl = editing ? `/campaign/${id}/edit` : `/create`;

		return (
			<div className="campaign-create-inner">
				<SummaryBox>
					<AppBox app={app || {}} isShadow={isShadow()} />
					<SummaryCell title="Campaign Name" isBlock>
						{form.name}
					</SummaryCell>
					<SummaryCell title={'Store URL'} isBlock>
						{fullStoreUrl}
					</SummaryCell>
				</SummaryBox>

				<SummaryBox title="Campaign Detail" editUrl={`${baseUrl}/detail`}>
					<SummaryCell title="Campaign Type" isThird>
						{form.type}
					</SummaryCell>
					<SummaryCell title="Tracking Tool" isThird>
						{tt.label}
					</SummaryCell>
					<SummaryCell isThird />
					<SummaryCell title="Minimum App Version" isThird>
						{form.target_app_version}
					</SummaryCell>
					<SummaryCell title="Minimum OS Version" isThird>
						{form.target_os_version}
					</SummaryCell>
					<SummaryCell title="Minimum SDK Version" isThird>
						{form.target_sdk_version}
					</SummaryCell>
					<SummaryCell title="Country" isBlock>
						{form.country.join(' - ')}
					</SummaryCell>
				</SummaryBox>

				<SummaryBox title="Source Detail" editUrl={`${baseUrl}/sources`}>
					{sources.map((source, index) => {
						const publisher = partners.find(p => p.id === source.publisher);

						return (
							<div className="campaign-create-summary-source" key={`source-${index}`}>
								{publisher &&
									<SummaryCell title="Publisher" renderBefore={() => (
										<div className="select-value-logo">
											<ShadowImage src={publisher.logo || icon} alt={shadowText(publisher.name, 'Example Publisher')} />
										</div>
									)}>
										{shadowText(publisher.name, 'Example Publisher')}
									</SummaryCell>
								}
								<SummaryCell title="Name">
									{source.name}
								</SummaryCell>
								{form.type !== 'CPM' && (
									<React.Fragment>
										{form.type === 'CPI' ? (
											<SummaryCell title={`Payout (${Currencies[source.payout_currency].symbol})`} isBlock>
												{source.bid}
											</SummaryCell>
										) : (
											<React.Fragment>
												<SummaryCell title="Event">
													{source.event_payouts.map((event, index) => (
														<span key={`name-${index}`}>{event.name}<br /></span>
													))}
												</SummaryCell>
												<SummaryCell title="Payout ($)">
													{source.event_payouts.map((event, index) => (
														<span key={`bid-${index}`}>{event.bid}<br /></span>
													))}
												</SummaryCell>
											</React.Fragment>
										)}
										{form.tt !== 4 && (
											<Fragment>
												<SummaryCell title="Install Postback">
													{source.disable_install_postback ? 'Disable' : 'Enable'}
												</SummaryCell>
												<SummaryCell title="Postback Only Unique Events">
													{source.unique_events_to_postback ? 'Enable' : 'Disable'}
												</SummaryCell>
												<SummaryCell title="Event Postback">
													Postback {source.selected_events.length > 0 ? `only "${source.selected_events.join(', ')}" event${source.selected_events.length > 1 ? 's' : ''}` : 'all events'}
												</SummaryCell>
											</Fragment>
										)}
									</React.Fragment>
								)}
								<SummaryCell title="Click Tracking URL" isBlock style={{ wordBreak: 'break-word' }}>
									{source.redirect_url}
								</SummaryCell>
								<SummaryCell title="Impression Tracking URL" isBlock style={{ wordBreak: 'break-word' }}>
									{source.impression_redirect_url}
								</SummaryCell>
							</div>
						)
					})}
				</SummaryBox>
			</div>
		)
	}
}

export default withTitle(Step4, null, null, match => {
	const editing = match.url.indexOf('/edit') !== -1;
	return `Interceptd - Campaign ${editing ? 'Edit' : 'Create'} ${editing ? `for ${match.params.id} - ` : ' - '} Summary`
});
