import React from 'react';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Input } from 'interceptd-ui';

import LoginSlogan from '../../components/LoginSlogan';

import Api from '../../services/api';
import Local from '../../services/localStorage';
import SendEvent from '../../services/events';

import Logo from '../../assets/logo.svg';

import './styles/Login.css';

import withTitle from '../withTitle';

class ResetPassword extends React.Component {
	state = {
		plainPassword: {
			first: '',
			second: '',
		},
		isFetching: false,
		isError: false,
		isDisabled: true,
		redirectToReferrer: false,
	}

	constructor(props) {
		super(props);

		this.handleSubmit = this.handleSubmit.bind(this);
	}
	
	componentDidMount() {
		Local.setStore({});
		Api.setToken();
	}

	checkErrors = () => {
		const { plainPassword } = this.state;
		this.setState({
			isError: plainPassword.first !== '' && plainPassword.second !== '' && plainPassword.first !== plainPassword.second,
			isDisabled: plainPassword.first === '' || plainPassword.second === '' || plainPassword.first !== plainPassword.second
		});
	}

	handleInput = e => {
		const { name, value } = e.target;
		const { plainPassword } = this.state;

		this.setState({
			plainPassword: {
				...plainPassword,
				[name]: value,
			},
		}, this.checkErrors)
	}

	handleSubmit(event) {
		event.preventDefault();
		const { plainPassword, isFetching, isDisabled } = this.state;
		const { match } = this.props;

		SendEvent({
			category: 'NonAuth',
			action: 'Change Password button clicked',
			nonInteraction: false,
		});

		if ( isDisabled || isFetching ) return false;

		this.setState({ isFetching: true });
		Api.resetPassword(match.params.token, {
			password: plainPassword.first
		})
			.then(response => {
				toast.success('Password changed');
				this.setState({
					plainPassword: {
						first: '',
						second: '',
					},
					isFetching: false,
					isError: false,
					isDisabled: true,
					redirectToReferrer: true,
				})
			})
			.catch(({ response }) => {
				SendEvent({
					description: 'resetPassword on Reset Password',
					fatal: true,
				});

				this.setState({
					isFetching: false,
					error: response
				});
				toast.error(`Password couldn't changed`)
			});
	}

	render() {
		const { plainPassword, isFetching, isDisabled, isError, redirectToReferrer } = this.state;

		if ( redirectToReferrer ) return <Redirect to="/welcome" />;

		return (
			<div className="login">
				<div className="login-wrapper">
					<LoginSlogan />
					<div className="login-right">
						<header className="login-header">
							<img src={Logo} alt="Interceptd" />
						</header>
						<form className="login-form" onSubmit={this.handleSubmit}>
							<div className="login-title">
								<h1>Reset Password</h1>
								<h2>Set up your new password now</h2>
							</div>
							<div className="login-form-inner">
								<Input
									type="password"
									name="first"
									label="Password"
									value={plainPassword.first}
									onChange={this.handleInput} />
								<Input
									type="password"
									name="second"
									label="Confirm Password"
									value={plainPassword.second}
									onChange={this.handleInput}
									error={isError && 'Passwords are not same'} />
								<Button type="submit" block loading={isFetching} disabled={isDisabled}>Change Password</Button>
							</div>
						</form>
					</div>
				</div>
			</div>
		)
	}
}

export default withTitle(ResetPassword, 'Reset Password');