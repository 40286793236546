import React from "react";
import ReactGA from "react-ga";
import { IntercomAPI } from 'react-intercom';

import Local from './services/localStorage';

ReactGA.initialize("UA-125730531-2", {
	gaOptions: {
		allowLinker: true,
	},
});

const withTracker = (WrappedComponent, options = {}) => {
	const trackPage = page => {
		if (!Local.getItem('login_as')) {
			ReactGA.set({
				page,
				...options,
			});
			ReactGA.pageview(page);
			IntercomAPI('trackEvent', `Page View: ${page}`);
			IntercomAPI('update');
		}
	};

	const HOC = class extends React.Component {
		componentDidMount() {
			const page = this.props.location.pathname + this.props.location.search;
			trackPage(page);
		}

		componentDidUpdate(prevProps) {
			const currentPage =
				prevProps.location.pathname + prevProps.location.search;
			const nextPage =
				this.props.location.pathname + this.props.location.search;

			if (currentPage !== nextPage) {
				trackPage(nextPage);
			}
		}

		render() {
			return <WrappedComponent {...this.props} />;
		}
	};

	return HOC;
};

export default withTracker;
