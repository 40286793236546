import React from 'react';
import Humanize from 'humanize-plus';

import './styles/ListStatsBoxes.css';

const ListStatsBoxes = ({ loading, totalImpressions, data = {} }) => {
	const rejectedClick = +(data.rejected_click_count || 0);
	const cleanClick = +(data.clean_click_count || 0);
	const flaggedClick = +(data.flagged_click_count || 0);
	const totalClick = rejectedClick + cleanClick + flaggedClick;
	const rejectedClickPercent = totalClick > 0 ? +(rejectedClick / totalClick * 100).toFixed(2) : 0;
	const flaggedClickPercent = totalClick > 0 ? +(flaggedClick / totalClick * 100).toFixed(2) : 0;
	const cleanClickPercent = (100 - (rejectedClickPercent + flaggedClickPercent)).toFixed(2);

	const rejectedInstall = +(data.rejected_install_count || 0);
	const flaggedInstall = +(data.flagged_install_count || 0);
	const cleanInstall = +(data.clean_install_count || 0);
	const totalInstall = rejectedInstall + flaggedInstall + cleanInstall;
	const rejectedInstallPercent = totalInstall > 0 ? +(rejectedInstall / totalInstall * 100).toFixed(2) : 0;
	const flaggedInstallPercent = totalInstall > 0 ? +(flaggedInstall / totalInstall * 100).toFixed(2) : 0;
	const cleanInstallPercent = (100 - (rejectedInstallPercent + flaggedInstallPercent)).toFixed(2);

	const rejectedEvent = +(data.rejected_event_count || 0);
	const flaggedEvent = +(data.flagged_event_count || 0);
	const cleanEvent = +(data.clean_event_count || 0);
	const totalEvent = rejectedEvent + flaggedEvent + cleanEvent;
	const rejectedEventPercent = totalEvent > 0 ? +(rejectedEvent / totalEvent * 100).toFixed(2) : 0;
	const flaggedEventPercent = totalEvent > 0 ? +(flaggedEvent / totalEvent * 100).toFixed(2) : 0;
	const cleanEventPercent = (100 - (rejectedEventPercent + flaggedEventPercent)).toFixed(2);

	const totalImpression = totalImpressions?.total ?? 0;
	const cleanImpression = totalImpressions?.clean ?? 0;
	const rejectedImpression = totalImpressions?.rejected ?? 0;
	const flaggedImpression = totalImpressions?.flagged ?? 0;
	const flaggedImpressionPercent = totalImpression > 0 ? +((flaggedImpression + rejectedImpression) / totalImpression * 100).toFixed(2) : 0;
	const cleanImpressionPercent = (100 - (flaggedImpressionPercent + rejectedImpression)).toFixed(2);

	return (
		<div className={`campaign-statboxes ${loading ? 'campaign-statboxes-loading' : ''}`}>
			{totalImpressions !== null ? (
				<div className="campaign-statbox">
					<div className="campaign-statbox-title">Impression</div>
					<div className="campaign-statbox-total">
						<span tooltip={Humanize.intComma(totalImpression)}>{Humanize.compactInteger(totalImpression, 1)}</span>
					</div>
					<div className="campaign-statbox-detail">
						<span className="campaign-statbox-detail-text">
						<span className="stats-red" tooltip={Humanize.intComma(rejectedImpression)}>{Humanize.compactInteger(rejectedImpression, 1)}</span> rejected <span className="stats-yellow" tooltip={Humanize.intComma(flaggedImpression)}>{Humanize.compactInteger(flaggedImpression, 1)}</span> flagged and <span className="stats-green" tooltip={Humanize.intComma(cleanImpression)}>{Humanize.compactInteger(cleanImpression, 1)}</span> clean
					</span>
						<div className="campaign-statbox-fraudbar">
							<div className="campaign-statbox-fraudbar-suspicious" style={{ '--width': `${flaggedImpressionPercent}%` }}>
								<span>Rejected - {rejectedClickPercent}%</span>
							</div>
							<div className="campaign-statbox-fraudbar-clean" style={{ '--width': `${cleanImpressionPercent}%` }}>
								<span>Clean - {cleanClickPercent}%</span>
							</div>
						</div>
					</div>
				</div>
			) : null}
			<div className="campaign-statbox">
				<div className="campaign-statbox-title">Clicks</div>
				<div className="campaign-statbox-total">
					<span tooltip-right={Humanize.intComma(totalClick)}>{Humanize.compactInteger(totalClick, 1)}</span>
				</div>
				<div className="campaign-statbox-detail">
					<span className="campaign-statbox-detail-text">
						<span className="stats-red" tooltip={Humanize.intComma(rejectedClick)}>{Humanize.compactInteger(rejectedClick, 1)}</span> rejected <span className="stats-yellow" tooltip={Humanize.intComma(flaggedClick)}>{Humanize.compactInteger(flaggedClick, 1)}</span> flagged and <span className="stats-green" tooltip={Humanize.intComma(cleanClick)}>{Humanize.compactInteger(cleanClick, 1)}</span> clean
					</span>
					<div className="campaign-statbox-fraudbar">
						<div className="campaign-statbox-fraudbar-rejected" style={{ '--width': `${rejectedClickPercent}%` }}>
							<span>Rejected - {rejectedClickPercent}%</span>
						</div>
						<div className="campaign-statbox-fraudbar-suspicious" style={{ '--width': `${flaggedClickPercent}%` }}>
							<span>Flagged - {flaggedClickPercent}%</span>
						</div>
						<div className="campaign-statbox-fraudbar-clean" style={{ '--width': `${cleanClickPercent}%` }}>
							<span>Clean - {cleanClickPercent}%</span>
						</div>
					</div>
				</div>
			</div>
			<div className="campaign-statbox">
				<div className="campaign-statbox-title">Installs</div>
				<div className="campaign-statbox-total">
					<span tooltip-right={Humanize.intComma(totalInstall)}>{Humanize.compactInteger(totalInstall, 1)}</span>
				</div>
				<div className="campaign-statbox-detail">
					<span className="campaign-statbox-detail-text">
						<span className="stats-red" tooltip={Humanize.intComma(rejectedInstall)}>{Humanize.compactInteger(rejectedInstall, 1)}</span> rejected, <span className="stats-yellow" tooltip={Humanize.intComma(flaggedInstall)}>{Humanize.compactInteger(flaggedInstall, 1)}</span> flagged and <span className="stats-green" tooltip={Humanize.intComma(cleanInstall)}>{Humanize.compactInteger(cleanInstall, 1)}</span> clean
					</span>
					<div className="campaign-statbox-fraudbar">
						<div className="campaign-statbox-fraudbar-rejected" style={{ '--width': `${rejectedInstallPercent}%` }}>
							<span>Rejected - {rejectedInstallPercent}%</span>
						</div>
						<div className="campaign-statbox-fraudbar-suspicious" style={{ '--width': `${flaggedInstallPercent}%` }}>
							<span>Flagged - {flaggedInstallPercent}%</span>
						</div>
						<div className="campaign-statbox-fraudbar-clean" style={{ '--width': `${cleanInstallPercent}%` }}>
							<span>Clean - {cleanInstallPercent}%</span>
						</div>
					</div>
				</div>
			</div>
			<div className="campaign-statbox">
				<div className="campaign-statbox-title">Events</div>
				<div className="campaign-statbox-total">
					<span tooltip-right={Humanize.intComma(totalEvent)}>
						{Humanize.compactInteger(totalEvent, 1)}
					</span>
				</div>
				<div className="campaign-statbox-detail">
					<span className="campaign-statbox-detail-text">
						<span className="stats-red" tooltip={Humanize.intComma(rejectedEvent)}>{Humanize.compactInteger(rejectedEvent, 1)}</span> rejected, <span className="stats-yellow" tooltip={Humanize.intComma(flaggedEvent)}>{Humanize.compactInteger(flaggedEvent, 1)}</span> flagged and <span className="stats-green" tooltip={Humanize.intComma(cleanEvent)}>{Humanize.compactInteger(cleanEvent, 1)}</span> clean
					</span>
					<div className="campaign-statbox-fraudbar">
						<div className="campaign-statbox-fraudbar-rejected" style={{ '--width': `${rejectedEventPercent}%` }}>
							<span>Rejected - {rejectedEventPercent}%</span>
						</div>
						<div className="campaign-statbox-fraudbar-suspicious" style={{ '--width': `${flaggedEventPercent}%` }}>
							<span>Flagged - {flaggedEventPercent}%</span>
						</div>
						<div className="campaign-statbox-fraudbar-clean" style={{ '--width': `${cleanEventPercent}%` }}>
							<span>Clean - {cleanEventPercent}%</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

ListStatsBoxes.defaultProps = {
	showInstalls: true
}

export default ListStatsBoxes;
