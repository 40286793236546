import React from 'react';
import moment from 'moment';
import Chart from 'react-apexcharts'
import isEqual from 'lodash/isEqual';
import Humanize from 'humanize-plus';
import { Loader, NoData } from 'interceptd-ui';

import Api from '../../../services/api';

import { colors } from '../../../constants/Color';

import { enumerateDaysBetweenDates, getTimestamp } from '../../../utils/transform';

class DailyImpressionsChart extends React.Component {
	state = {
		chartOptions: {
			colors,
			chart: {
				id: 'campaign-chart',
				type: 'line',
				height: 400,
			},
			dataLabels: {
				enabled: false,
			},
			xaxis: {
				type: 'datetime',
				labels: {
					formatter: value => moment(value).format('MMM-D'),
				},
			},
			yaxis: {
				seriesName: 'Impression Count',
				forceNiceScale: true,
				labels: {
					formatter: v => Humanize.intComma(v),
				},
				title: {
					text: 'Impression Count',
				},
				min: 0,
			},
			stroke: {
				width: [2, 2, 2],
				curve: 'smooth',
			},
		},
		chartSeries: [],
		stats: null,
		fetching: true,
	}

	componentDidMount() {
		this.getData();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if ( !isEqual(this.props.filters, prevProps.filters) ) {
			this.getData()
		}
	}

	getData = () => {
		const { filters, source } = this.props;

		this.setState({
			chartSeries: [],
			fetching: true,
		})

		const query = {
			source_ids: Array.isArray(source) ? source.map(s => s.id) : source.id,
			ts_start: getTimestamp(filters.start),
			ts_end: getTimestamp(filters.end, true),
		}

		Api.getDailyImpressionStats(query)
			.then(response => {
				const chartSeries = response.data.data.reduce((acc, cur) => {
					return acc.indexOf(cur.creative_id) === -1 ? [...acc, cur.creative_id] : [...acc];
				}, []).map(serie => {
					const data = enumerateDaysBetweenDates(
						filters.start.format('X'), 
						filters.end.format('X'), 
						'X',
						0
					).map(d => {
						const data = response.data.data.find(r => moment(r.date, 'X').isSame(moment(d, 'X'), 'day') && r.creative_id === serie) || {};
						return ({
							x: moment(d, 'X').format('YYYY-MM-DD'),
							y: (data.impression_count || 0) + (data.flagged_impression_count || 0)
						})
					})

					return ({
						name: serie,
						data,
					})
				})

				this.setState({
					chartSeries,
					stats: response.data.data,
					fetching: false,
				})
			})
			.catch(({ response }) => {
				this.setState({
					chartSeries: [],
					stats: null,
					fetching: false,
				})
			});
	}

	render() {
		const { chartOptions, chartSeries, stats, fetching } = this.state;

		return (
			<div className="campaign-detail-daily">
				{fetching ? (
					<Loader />
				) : stats && stats.length > 0 ? (
					<Chart options={chartOptions} series={chartSeries} type="line" width="100%" height={400} />
				) : (
					<NoData />
				)}
			</div>
		)
	}
}

export default DailyImpressionsChart;