import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Humanize from 'humanize-plus';
import moment from 'moment';
import get from 'lodash/get';
import find from 'lodash/find';
import Papa from 'papaparse';
import { toast } from 'react-toastify';
import {
	Loader,
	PageTitle,
	Breadcrumbs,
	Button,
	Info,
} from 'interceptd-ui';

import RadioGroup from '../../components/Input/RadioGroup';
import ExchangeRate from '../../components/ExchangeRate';

import SummaryReportDetailTable from './components/SummaryReportDetailTable';

import ShareWithPartnerModal from '../Modals/ShareWithPartnerModal';

import Api from '../../services/api';
import Local from '../../services/localStorage';
import Settings from '../../services/settings';

import { downloadFile } from '../../utils/file';
import { safeDivide } from '../../utils/misc';

import Currencies from '../../constants/currency.json';

import {
	partnersSummaryColumns,
	campaignSummaryColumns,
	cpACohortColumns,
	cpAEventCohortColumns,
	cohortColumns,
	roasColumns,
} from './components/SummaryReportColumns';

import {
	TotalPayoutDescription,
	TotalSavingDescription,
	TotalDeductibleDescription,
	TotalRevenueDescription,
	TotalCohortRevenueDescription,
	TotalSpendingDescription,
	ERoasDescription,
	SummaryReportTableCsvFields,
	SummaryReportTabOptions,
} from '../../constants/Report';

import withTitle from '../withTitle';

import './styles/SummaryReportDetail.css';

class SummaryReportDetail extends Component {
	state = {
		alarms: Local.getItem('supported_rules') || [],
		currentTab: 'partners_summary',
		loading: true,
		alarmNames: [],
		cpiCampaignData: [],
		cpaCampaignData: [],
		partnersSummaryData: [],
		sharedPublishers: [],
		reportPartners: [],
		total: {
			totalPayout: 0,
			totalSaving: 0,
			totalDeductible: 0,
			totalSpending: 0,
			totalRevenue: 0,
			totalCohortRevenue: 0,
		},

		sourceSettings: Settings.get('sourceSettings', 'account') || {},
		appSettings: Settings.get('appSettings', 'account') || {},
		summaryReportSettings: Settings.get('summaryReportSettings', 'account') || {},

		exchangeRate: 1.000,
		payout_currency: 'USD',
		revenue_currency: 'USD',

		openShareModal: false,
	}

	componentDidMount() {
		this.getData();
	}

	getData = async () => {
		try {
			const { match } = this.props;
			const { total, alarms, exchangeRate } = this.state;

			const report = get(await Api.getLeoparOfflineReportDetail(match.params.id), 'data.data');
			const allCampaigns = get(await Api.getLeoparCampaigns(), 'data.data', []);
			const allPartners = get(await Api.getPartners({
				page: 1,
				size: 1000,
			}), 'data.data', []);
			const config = JSON.parse(report.config);
			const detail = JSON.parse(report.detail);
			const data = Object.values(detail.data);

			const cpiCampaignData = [];
			const cpaCampaignData = [];
			const alarmNames = [];
			const reportPartners = [];

			const partnersSummaryDataTmp = {};
			const partnersSummaryData = [];

			config.deductible_alarms.forEach((a) => {
				const findedAlarm = find(alarms, ['short-name', a]);
				if (findedAlarm) {
					alarmNames.push(findedAlarm.name);
				}
			});

			JSON.parse(JSON.stringify(data)).forEach((d) => {
				d.campaign = find(allCampaigns.filter((c) => c.application_type !== 'leancheck'), ['id', d.campaign]);
				d.source = find(d.campaign.sources, ['id', d.source]);

				const campaignType = d.campaign.type;

				var clean_conversion = partnersSummaryDataTmp[d.source.partner]?.clean_conversion || 0;
				var clean_conversion_payout = partnersSummaryDataTmp[d.source.partner]?.clean_conversion_payout || 0;

				var deductible_conversion = partnersSummaryDataTmp[d.source.partner]?.deductible_conversion || 0;
				var deductible_conversion_payout = partnersSummaryDataTmp[d.source.partner]?.deductible_conversion_payout || 0;

				var rejected_conversion = partnersSummaryDataTmp[d.source.partner]?.rejected_conversion || 0;
				var rejected_conversion_payout = partnersSummaryDataTmp[d.source.partner]?.rejected_conversion_payout || 0;

				if (campaignType === 'CPI') {
					partnersSummaryDataTmp[d.source.partner] = {
						clean_conversion: clean_conversion + d.clean_install || 0,
						clean_conversion_payout: clean_conversion_payout + d.clean_install_payout || 0,
						
						deductible_conversion: deductible_conversion + d.deductible_install || 0,
						deductible_conversion_payout: deductible_conversion_payout + d.deductible_install_payout || 0,
	
						rejected_conversion: rejected_conversion + d.rejected_install || 0,
						rejected_conversion_payout: rejected_conversion_payout + d.rejected_install_payout || 0,
					}
				} else {
					Object.values(d.by_event_name || {}).forEach((e) => {
						if (campaignType === 'CPA' || campaignType === 'CPI') {
							clean_conversion = partnersSummaryDataTmp[d.source.partner]?.clean_conversion || 0;
							clean_conversion_payout = partnersSummaryDataTmp[d.source.partner]?.clean_conversion_payout || 0;
		
							deductible_conversion = partnersSummaryDataTmp[d.source.partner]?.deductible_conversion || 0;
							deductible_conversion_payout = partnersSummaryDataTmp[d.source.partner]?.deductible_conversion_payout || 0;
			
							rejected_conversion = partnersSummaryDataTmp[d.source.partner]?.rejected_conversion || 0;
							rejected_conversion_payout = partnersSummaryDataTmp[d.source.partner]?.rejected_conversion_payout || 0;
	
							partnersSummaryDataTmp[d.source.partner] = {
								clean_conversion: clean_conversion + e.clean_event || 0,
								clean_conversion_payout: clean_conversion_payout + e.clean_event_payout || 0,
								
								deductible_conversion: deductible_conversion + e.deductible_event || 0,
								deductible_conversion_payout: deductible_conversion_payout + e.deductible_event_payout || 0,
			
								rejected_conversion: rejected_conversion + e.rejected_event || 0,
								rejected_conversion_payout: rejected_conversion_payout + e.rejected_event_payout || 0,
							}
						}
					});
				}
			});

			data.forEach((d) => {
				d.campaign = find(allCampaigns.filter((c) => c.application_type !== 'leancheck'), ['id', d.campaign]);
				d.source = find(d.campaign.sources, ['id', d.source]);

				if (reportPartners.indexOf(d.source.publisher) === -1) {
					reportPartners.push(d.source.publisher);
				}

				const campaignType = d.campaign.type;

				// Event
				d.clean_event = 0;
				d.clean_event_payout = 0;
				d.deductible_event = 0;
				d.deductible_event_payout = 0;
				d.rejected_event = 0;
				d.rejected_event_payout = 0;

				d.clean_amount = 0;
				d.clean_cohort_amount = 0;
				d.clean_cohort_amount_r = 0;
				d.clean_cohort_event = 0;
				d.clean_cohort_event_r = 0;
				d.clean_cohort_event_payout = 0;
				d.clean_cohort_event_payout_r = 0;

				d.clean_event_cohort_event = 0;
				d.clean_event_cohort_event_r = 0;
				d.clean_event_cohort_amount = 0;
				d.clean_event_cohort_event_payout = 0;
				d.clean_event_cohort_event_payout_r = 0;

				d.rejected_event_cohort_amount = 0;
				d.rejected_event_cohort_event = 0;
				d.rejected_event_cohort_event_r = 0;
				d.rejected_event_cohort_event_payout = 0;
				d.rejected_event_cohort_event_payout_r = 0;

				d.deductible_event_cohort_amount = 0;
				d.deductible_event_cohort_event = 0;
				d.deductible_event_cohort_event_r = 0;
				d.deductible_event_cohort_event_payout = 0;
				d.deductible_event_cohort_event_payout_r = 0;
				d.deductible_event_payout = 0;

				d.clean_reattributed_amount = 0;
				d.clean_reattributed_event = 0;
				d.clean_reattributed_event_payout = 0;
				d.deductible_amount = 0;
				d.deductible_cohort_amount = 0;
				d.deductible_cohort_amount_r = 0;
				d.deductible_cohort_event = 0;
				d.deductible_cohort_event_r = 0;
				d.deductible_cohort_event_payout = 0;
				d.deductible_cohort_event_payout_r = 0;

				d.deductible_reattributed_amount = 0;
				d.deductible_reattributed_event = 0;
				d.deductible_reattributed_event_payout = 0;
				d.rejected_amount = 0;
				d.rejected_cohort_amount = 0;
				d.rejected_cohort_amount_r = 0;
				d.rejected_cohort_event = 0;
				d.rejected_cohort_event_r = 0;
				d.rejected_cohort_event_payout = 0;
				d.rejected_cohort_event_payout_r = 0;

				d.rejected_reattributed_amount = 0;
				d.rejected_reattributed_event = 0;
				d.rejected_reattributed_event_payout = 0;

				// roas
				d.roas_revenue_clean = 0;
				d.roas_revenue_total = 0;
				d.roas_spent_clean = 0;
				d.roas_spent_total = 0;
				d.roas_revenue_cohort_total = 0;
				d.roas_revenue_cohort_total_r = 0;
				d.roas_revenue_cohort_clean = 0;
				d.roas_revenue_cohort_clean_r = 0;
				d.roas_spent_cohort_total = 0;
				d.roas_spent_cohort_total_r = 0;
				d.roas_spent_cohort_clean = 0;
				d.roas_spent_cohort_clean_r = 0;
				d.roas_total_cohort = 0;
				d.roas_total_cohort_clean = 0;
				d.roas_total_event_cohort = 0;
				d.roas_total_event_cohort_clean = 0;
				d.roas_revenue_event_cohort_total = 0;
				d.roas_revenue_event_cohort_total_r = 0;
				d.roas_spent_event_cohort_total = 0;
				d.roas_spent_event_cohort_total_r = 0;
				d.roas_spent_event_cohort_clean = 0;
				d.roas_spent_event_cohort_clean_r = 0;
				d.roas_revenue_event_cohort_clean = 0;
				d.roas_revenue_event_cohort_clean_r = 0;

				if (campaignType === 'CPI') {
					d.roas_spent_clean += d.clean_install_payout;
					d.roas_spent_total += d.clean_install_payout + d.deductible_install_payout + d.rejected_install_payout;
				}

				Object.values(d.by_event_name || {}).forEach((e) => {
					if (campaignType === 'CPA' || campaignType === 'CPI') {
						d.clean_event += e.clean_event || 0;
						d.clean_event_payout += e.clean_event_payout || 0;
						d.rejected_event += e.rejected_event || 0;
						d.rejected_event_payout += e.rejected_event_payout || 0;
	
						d.deductible_event += e.deductible_event || 0;
						d.clean_amount += e.clean_amount || 0;
						d.clean_cohort_amount += e.clean_cohort_amount || 0;
						d.clean_cohort_amount_r += e.clean_reattributed_cohort_amount || 0;
	
						d.clean_cohort_event += e.clean_cohort_event || 0;
						d.clean_cohort_event_r += e.clean_reattributed_cohort_event;
						d.clean_cohort_event_payout += e.clean_cohort_event_payout || 0;
						d.clean_cohort_event_payout_r += e.clean_reattributed_cohort_event_payout || 0;
						d.clean_reattributed_amount += e.clean_reattributed_event_cohort_amount || 0;
						d.clean_reattributed_event += e.clean_reattributed_event || 0;
						d.clean_reattributed_event_payout += e.clean_reattributed_event_payout || 0;
						d.deductible_amount += e.deductible_amount || 0;
						d.deductible_cohort_amount += e.deductible_cohort_amount || 0;
						d.deductible_cohort_amount_r += e.deductible_reattributed_cohort_amount || 0;
	
						d.deductible_cohort_event += e.deductible_cohort_event || 0;
						d.deductible_cohort_event_r += e.deductible_reattributed_cohort_event || 0;
						d.deductible_cohort_event_payout += e.deductible_cohort_event_payout || 0;
						d.deductible_cohort_event_payout_r += e.deductible_reattributed_cohort_event_payout || 0;
						d.deductible_reattributed_amount += e.deductible_reattributed_amount || 0;
						d.deductible_reattributed_event += e.deductible_reattributed_event || 0;
						d.deductible_reattributed_event_payout += e.deductible_reattributed_event_payout || 0;
						d.rejected_amount += e.rejected_amount || 0;
						d.rejected_cohort_amount += e.rejected_cohort_amount || 0;
						d.rejected_cohort_amount_r += e.rejected_reattributed_event_cohort_amount || 0;
	
						d.rejected_cohort_event += e.rejected_cohort_event || 0;
						d.rejected_cohort_event_r += e.rejected_reattributed_cohort_event || 0;
						d.rejected_cohort_event_payout += e.rejected_cohort_event_payout || 0;
						d.rejected_cohort_event_payout_r += e.rejected_reattributed_cohort_event_payout || 0;
						d.rejected_reattributed_amount += e.rejected_reattributed_amount || 0;
						d.rejected_reattributed_event += e.rejected_reattributed_event || 0;
						d.rejected_reattributed_event_payout += e.rejected_reattributed_event_payout || 0;
	
						d.clean_event_cohort_event += e.clean_event_cohort_event || 0;
						d.clean_event_cohort_event_r += e.clean_reattributed_event_cohort_event || 0;
						d.clean_event_cohort_amount += e.clean_event_cohort_amount || 0;
						d.clean_event_cohort_event_payout += e.clean_event_cohort_event_payout || 0;
						d.clean_event_cohort_event_payout_r += e.clean_reattributed_event_cohort_event_payout || 0;
	
						d.rejected_event_cohort_amount += e.rejected_event_cohort_amount || 0;
						d.rejected_event_cohort_event += e.rejected_event_cohort_event || 0;
						d.rejected_event_cohort_event_r += e.rejected_reattributed_event_cohort_event || 0;
						d.rejected_event_cohort_event_payout += e.rejected_event_cohort_event_payout || 0;
						d.rejected_event_cohort_event_payout_r += e.rejected_reattributed_event_cohort_event_payout || 0;
	
						d.deductible_event_cohort_amount += e.deductible_event_cohort_amount || 0;
						d.deductible_event_cohort_event += e.deductible_event_cohort_event || 0;
						d.deductible_event_cohort_event_r += e.deductible_reattributed_event_cohort_event || 0;
						d.deductible_event_cohort_event_payout += e.deductible_event_cohort_event_payout || 0;
						d.deductible_event_cohort_event_payout_r += e.deductible_reattributed_event_cohort_event_payout || 0;
						d.deductible_event_payout += e.deductible_event_payout || 0;
	
						d.roas_revenue_clean += e.clean_amount || 0;
						d.roas_revenue_total += (e.clean_amount || 0) + (e.deductible_amount || 0) + (e.rejected_amount || 0);

						d.roas_spent_clean += e.clean_event_payout || 0;
						d.roas_spent_total += (e.clean_event_payout || 0) + (e.deductible_event_payout || 0) + (e.rejected_event_payout || 0);
						d.roas_spent_cohort_total = (e.clean_cohort_event_payout || 0) + (e.deductible_cohort_event_payout || 0) + (e.rejected_cohort_event_payout || 0);
						d.roas_spent_cohort_total_r = (e.clean_reattributed_cohort_event_payout || 0) + (e.deductible_reattributed_cohort_event_payout || 0) + (e.rejected_reattributed_cohort_event_payout || 0);
						d.roas_spent_cohort_clean = e.clean_cohort_event_payout || 0;
						d.roas_spent_cohort_clean_r = e.clean_reattributed_cohort_event_payout || 0;
						d.roas_spent_event_cohort_total = (e.clean_event_cohort_event_payout || 0) + (e.deductible_event_cohort_event_payout || 0) + (e.rejected_event_cohort_event_payout || 0);
						d.roas_spent_event_cohort_total_r = (e.clean_reattributed_event_cohort_event_payout || 0) + (e.deductible_reattributed_event_cohort_event_payout || 0) + (e.rejected_reattributed_event_cohort_event_payout || 0);
						d.roas_spent_event_cohort_clean = e.clean_event_cohort_event_payout || 0;
						d.roas_spent_event_cohort_clean_r = e.clean_reattributed_event_cohort_event_payout || 0;
					} else {
						d.roas_spent_cohort_total = d.roas_spent_total;
						d.roas_spent_cohort_clean = d.roas_spent_clean;
						d.roas_spent_event_cohort_total = d.roas_spent_total;
						d.roas_spent_event_cohort_clean = d.roas_spent_clean;
					}

					d.roas_revenue_cohort_total += (e.clean_cohort_amount || 0) + (e.deductible_cohort_amount || 0) + (e.rejected_cohort_amount || 0);
					d.roas_revenue_cohort_total_r += (e.clean_reattributed_cohort_amount || 0) + (e.deductible_reattributed_cohort_amount || 0) + (e.rejected_reattributed_cohort_amount || 0);
					d.roas_revenue_cohort_clean += e.clean_cohort_amount || 0;
					d.roas_revenue_cohort_clean_r += e.clean_reattributed_cohort_amount || 0;
					d.roas_revenue_event_cohort_total += (e.clean_event_cohort_amount || 0) + (e.deductible_event_cohort_amount || 0) + (e.rejected_event_cohort_amount || 0);
					d.roas_revenue_event_cohort_total_r += (e.clean_reattributed_event_cohort_amount || 0) + (e.deductible_reattributed_event_cohort_amount || 0) + (e.rejected_reattributed_event_cohort_amount || 0);
					d.roas_revenue_event_cohort_clean += e.clean_event_cohort_amount || 0;
					d.roas_revenue_event_cohort_clean_r += e.clean_reattributed_event_cohort_amount || 0;
				});

				d.clean_cohort_event = d.clean_cohort_event - d.clean_cohort_event_r;
				d.clean_cohort_event_payout = d.clean_cohort_event_payout - d.clean_cohort_event_payout_r;

				d.deductible_cohort_event = d.deductible_cohort_event - d.deductible_cohort_event_r;
				d.deductible_cohort_event_payout = d.deductible_cohort_event_payout - d.deductible_cohort_event_payout_r;

				d.rejected_cohort_event = d.rejected_cohort_event - d.rejected_cohort_event_r;
				d.rejected_cohort_event_payout = d.rejected_cohort_event_payout - d.rejected_cohort_event_payout_r;

				//-----

				d.clean_event_cohort_event = d.clean_event_cohort_event - d.clean_event_cohort_event_r;
				d.clean_event_cohort_event_payout = d.clean_event_cohort_event_payout - d.clean_event_cohort_event_payout_r;

				d.deductible_event_cohort_event = d.deductible_event_cohort_event - d.deductible_event_cohort_event_r;
				d.deductible_event_cohort_event_payout = d.deductible_event_cohort_event_payout - d.deductible_event_cohort_event_payout_r;

				d.rejected_event_cohort_event = d.rejected_event_cohort_event - d.rejected_event_cohort_event_r;
				d.rejected_event_cohort_event_payout = d.rejected_event_cohort_event_payout - d.rejected_event_cohort_event_payout_r;

				//-----

				//d.clean_event = d.clean_event - d.clean_reattributed_event;
				d.clean_amount = d.clean_amount - d.clean_reattributed_amount;

				d.clean_cohort_amount = d.clean_cohort_amount - d.clean_cohort_amount_r;

				d.clean_event_cohort_amount = d.clean_event_cohort_amount - d.clean_reattributed_amount;

				d.roas_total = safeDivide(d.roas_revenue_clean, (d.roas_spent_total * exchangeRate));
				d.roas_total_clean = safeDivide(d.roas_revenue_clean, (d.roas_spent_clean * exchangeRate));
				d.roas_total_cohort = safeDivide(d.roas_revenue_cohort_clean, (d.roas_spent_cohort_total * exchangeRate));
				d.roas_total_cohort_clean = safeDivide(d.roas_revenue_cohort_clean, (d.roas_spent_cohort_clean * exchangeRate));
				d.roas_total_event_cohort = safeDivide(d.roas_revenue_event_cohort_clean, (d.roas_spent_event_cohort_total * exchangeRate));
				d.roas_total_event_cohort_clean = safeDivide(d.roas_revenue_event_cohort_clean, (d.roas_spent_event_cohort_clean * exchangeRate));

				d.type = d.campaign.type;
			
				total.totalRevenue += d.clean_amount;
				total.totalCohortRevenue += d.clean_cohort_amount;
				if (d.type === 'CPI') {
					total.totalPayout += d.clean_install_payout + d.deductible_install_payout;
					total.totalSaving += d.rejected_install_payout;
					total.totalDeductible += d.deductible_install_payout;
					total.totalSpending += d.clean_install_payout;
					cpiCampaignData.push(d);
				} else {
					total.totalPayout += d.clean_event_payout + d.deductible_event_payout;
					total.totalSaving += d.rejected_event_payout;
					total.totalDeductible += d.deductible_event_payout;
					total.totalSpending += d.clean_event_payout;
					cpaCampaignData.push(d);
				}
			});

			Object.keys(partnersSummaryDataTmp).forEach(key => {
				partnersSummaryData.push({
					partner: find(allPartners, ['id', parseInt(key)]),
					clean_conversion: partnersSummaryDataTmp[key].clean_conversion,
					clean_conversion_payout: partnersSummaryDataTmp[key].clean_conversion_payout,
					deductible_conversion: partnersSummaryDataTmp[key].deductible_conversion,
					deductible_conversion_payout: partnersSummaryDataTmp[key].deductible_conversion_payout,
					rejected_conversion: partnersSummaryDataTmp[key].rejected_conversion,
					rejected_conversion_payout: partnersSummaryDataTmp[key].rejected_conversion_payout
				});
			});

			const allCampaignsData = [
				...cpiCampaignData,
				...cpaCampaignData,
			];
			const firstSourceId = allCampaignsData?.[0]?.source?.id ?? 0;
			const firstAppId = allCampaignsData?.[0]?.campaign?.app_info?.id ?? 0;

			this.setState(prevState => ({
				total,
				loading: false,
				cpiCampaignData,
				cpaCampaignData,
				partnersSummaryData,
				report,
				alarmNames,
				sharedPublishers: config?.shared_publishers || [],
				reportPartners,

				exchangeRate: prevState.summaryReportSettings?.[match.params.id]?.exchangeRate ?? prevState.exchangeRate,
				payout_currency: prevState.sourceSettings?.[firstSourceId]?.payout_currency ?? 'USD',
				revenue_currency: prevState.appSettings?.[firstAppId]?.revenue_currency ?? 'USD',
			}));
		} catch (err) {
			console.error(err);
			this.setState({ loading: false });
		}
	}

	getCurrency = () => {
		const {
			payout_currency,
			revenue_currency,
			appSettings,
			sourceSettings,
			cpaCampaignData,
			cpiCampaignData,
		} = this.state;

		const allCampaigns = [...cpaCampaignData, ...cpiCampaignData];
		const campaigns = allCampaigns.map(c => c.campaign);
		const sources = allCampaigns.map(c => c.source);

		const total_payout_currency = sources.every(s => (sourceSettings?.[s?.id]?.payout_currency || 'USD') === payout_currency)
			? payout_currency
			: null;

		const total_revenue_currency = campaigns.every(c => (appSettings?.[c?.app_info?.id]?.revenue_currency || 'USD') === revenue_currency)
			? revenue_currency
			: null;

		const payoutSymbol = Currencies?.[total_payout_currency]?.symbol || null;
		const revenueSymbol = Currencies?.[total_revenue_currency]?.symbol || null;

		return ({
			payoutSymbol,
			revenueSymbol,
		});
	}

	buildHeader = () => {
		const {
			total,
			loading,
		} = this.state;
		const {
			payoutSymbol,
			revenueSymbol,
		} = this.getCurrency();

		return (
			<div className="summary-report-total">
				{loading && <div style={{ position: 'absolute', left: 40 }}><Loader mini /></div>}
				<div className="total-element">
					<span className="total-title">Total Payout <span><Info>{TotalPayoutDescription}</Info></span></span>
					<span className="total-value">{payoutSymbol}{Humanize.formatNumber(total.totalPayout)}</span>
				</div>
				<div className="total-element">
					<span className="total-title">Total Saving <span><Info>{TotalSavingDescription}</Info></span></span>
					<span className="total-value">{payoutSymbol}{Humanize.formatNumber(total.totalSaving)}</span>
				</div>
				<div className="total-element">
					<span className="total-title">Total Deductible <span><Info>{TotalDeductibleDescription}</Info></span></span>
					<span className="total-value">{payoutSymbol}{Humanize.formatNumber(total.totalDeductible)}</span>
				</div>
				<div className="total-element">
					<span className="total-title">Total Revenue <span><Info tooltipProps={{ position: 'left' }}>{TotalRevenueDescription}</Info></span></span>
					<span className="total-value" style={{ color: 'var(--green)' }}>{revenueSymbol}{Humanize.formatNumber(total.totalRevenue)}</span>
				</div>
				<div className="total-element">
					<span className="total-title">Total Cohort Revenue <span><Info tooltipProps={{ position: 'left' }}>{TotalCohortRevenueDescription}</Info></span></span>
					<span className="total-value" style={{ color: 'var(--green)' }}>{revenueSymbol}{Humanize.formatNumber(total.totalCohortRevenue)}</span>
				</div>
				<div className="total-element">
					<span className="total-title">Total Spending <span><Info tooltipProps={{ position: 'left' }}>{TotalSpendingDescription}</Info></span></span>
					<span className="total-value blue">{payoutSymbol}{Humanize.formatNumber(total.totalSpending)}</span>
				</div>
			</div>
		);
	}

	saveExchangeRate = () => {
		const { match } = this.props;
		const { exchangeRate } = this.state;

		Settings.set('summaryReportSettings', {
			...(Settings.get('summaryReportSettings', 'account') || {}),
			[match.params.id]: {
				...((Settings.get('summaryReportSettings', 'account') || {})?.[match.params.id] ?? {}),
				exchangeRate,
			}
		}, 'account');
	}

	renderCurrencyRate = () => {
		const { exchangeRate, payout_currency, revenue_currency } = this.state;

		return (
			<ExchangeRate
				exchangeRate={exchangeRate}
				payout_currency={payout_currency}
				revenue_currency={revenue_currency}
				onChangeExchangeRate={(e, value) => this.setState({ exchangeRate: value })}
				onSaveExchangeRate={this.saveExchangeRate} />
		)
	}

	exportCsv = ({ tableName, data }) => {
		const fieldsNameMap = SummaryReportTableCsvFields[tableName];
		const fields = Object.keys(fieldsNameMap);

		const csvData = data.map((d) => {

			var row = {};
			
			if (tableName === "partners_summary") {
				row = {
					'PARTNER NAME': `${d.partner.name}`,
				};
			} else {
				row = {
					'CAMPAIGN NAME': `${d.campaign.name} (${d.source.name})`,
				};
			}

			fields.forEach((f) => {
				const value = d[f];
				row[fieldsNameMap[f]] = Number.isNaN(+value)
					? value
					: Humanize.formatNumber(d[f]);
			});

			return row;
		});

		downloadFile(Papa.unparse(csvData), `${tableName}_summary_report.csv`);
	}

	generateTable = () => {
		const {
			currentTab,
			cpiCampaignData,
			cpaCampaignData,
			partnersSummaryData,

			exchangeRate,
			payout_currency,
			revenue_currency,
		} = this.state;

		const {
			payoutSymbol,
			revenueSymbol
		} = this.getCurrency();

		const allCampaigns = [
			...cpiCampaignData,
			...cpaCampaignData,
		];

		if (currentTab === 'partners_summary') {
			return <SummaryReportDetailTable
				noData={!(partnersSummaryData && partnersSummaryData.length > 0)}
				title="PARTNERS SUMMARY"
				data={partnersSummaryData}
				columns={partnersSummaryColumns('conversion', payoutSymbol)}
				right={(
					<Button
						bgColor="transparent"
						onClick={() => this.exportCsv({ tableName: 'partners_summary', data: partnersSummaryData })}
					>
						CSV Export
				</Button>
				)}
			/>
		}

		if (currentTab === 'cpi_summary') {
			return <SummaryReportDetailTable
				noData={!(cpiCampaignData && cpiCampaignData.length > 0)}
				title="CPI CAMPAIGNS SUMMARY"
				data={cpiCampaignData}
				columns={campaignSummaryColumns('install', payoutSymbol)}
				right={(
					<Button
						bgColor="transparent"
						onClick={() => this.exportCsv({ tableName: 'cpi_summary', data: cpiCampaignData })}
					>
						CSV Export
				</Button>
				)}
			/>
		}

		if (currentTab === 'cpa_summary') {
			return <SummaryReportDetailTable
				noData={!(cpaCampaignData && cpaCampaignData.length > 0)}
				title="CPA CAMPAIGNS SUMMARY"
				data={cpaCampaignData}
				columns={campaignSummaryColumns('event', payoutSymbol)}
				right={(
					<Button
						bgColor="transparent"
						onClick={() => this.exportCsv({ tableName: 'cpa_summary', data: cpaCampaignData })}
					>
						CSV Export
				</Button>
				)}
			/>
		}

		if (currentTab === 'cpa_cohort') {
			return <SummaryReportDetailTable
				noData={!(cpaCampaignData && cpaCampaignData.length > 0)}
				title="CPA CAMPAIGNS - COHORT"
				data={cpaCampaignData}
				columns={cpACohortColumns(payoutSymbol)}
				right={(
					<Button
						bgColor="transparent"
						onClick={() => this.exportCsv({ tableName: 'cpa_cohort', data: cpaCampaignData })}
					>
						CSV Export
				</Button>
				)}
			/>
		}

		if (currentTab === 'cpa_event_cohort') {
			return <SummaryReportDetailTable
				noData={!(cpaCampaignData && cpaCampaignData.length > 0)}
				title="CPA CAMPAIGNS - EVENT COHORT"
				data={cpaCampaignData}
				columns={cpAEventCohortColumns(payoutSymbol)}
				right={(
					<Button
						bgColor="transparent"
						onClick={() => this.exportCsv({ tableName: 'cpa_event_cohort', data: cpaCampaignData })}
					>
						CSV Export
				</Button>
				)}
			/>
		}

		if (currentTab === 'revenue') {
			return <SummaryReportDetailTable
				noData={!(allCampaigns && allCampaigns.length > 0)}
				title="REVENUE EVENTS"
				data={allCampaigns}
				columns={cohortColumns(revenueSymbol)}
				right={(
					<Button
						bgColor="transparent"
						onClick={() => this.exportCsv({ tableName: 'revenue', data: allCampaigns })}
					>
						CSV Export
				</Button>
				)}
			/>
		}

		if (currentTab === 'eroas') {
			return (
				<Fragment>
					<SummaryReportDetailTable
						noData={!(allCampaigns && allCampaigns.length > 0)}
						title={
							<React.Fragment>
								<span style={{ marginRight: 10 }}>eROAS</span>
								<Info>{ERoasDescription}</Info>
							</React.Fragment>
						}
						right={
							<Fragment>
								{payout_currency !== revenue_currency ? this.renderCurrencyRate() : null}
								{/* <Button
									bgColor="transparent"
									onClick={() => this.exportCsv({ tableName: 'eroas', data: allCampaigns })}
								>
									CSV Export
								</Button> */}
							</Fragment>
						}
						data={allCampaigns}
						columns={roasColumns({
							type: null,
							exchangeRate: isNaN(exchangeRate) || exchangeRate === 0 ? 1 : exchangeRate,
							payout_symbol: Currencies[payout_currency].symbol,
							revenue_symbol: Currencies[revenue_currency].symbol,
						})}
					/>
					<SummaryReportDetailTable
						noData={!(allCampaigns && allCampaigns.length > 0)}
						hideTitle
						data={allCampaigns}
						columns={roasColumns({
							type: 'cohort',
							exchangeRate: isNaN(exchangeRate) || exchangeRate === 0 ? 1 : exchangeRate,
							payout_symbol: Currencies[payout_currency].symbol,
							revenue_symbol: Currencies[revenue_currency].symbol,
						})}
					/>
					<SummaryReportDetailTable
						noData={!(allCampaigns && allCampaigns.length > 0)}
						hideTitle
						data={allCampaigns}
						columns={roasColumns({
							type: 'cohort_event',
							exchangeRate: isNaN(exchangeRate) || exchangeRate === 0 ? 1 : exchangeRate,
							payout_symbol: Currencies[payout_currency].symbol,
							revenue_symbol: Currencies[revenue_currency].symbol,
						})}
					/>
				</Fragment>
			);
		}

		return null;
	}

	toggleShareModal = () => {
		this.setState(state => ({
			openShareModal: !state.openShareModal,
		}));
	}

	shareWithPartner = (partnerIds) => {
		const { report } = this.state;

		Api.shareReport({
			report_id: report.id,
			publisher_ids: partnerIds,
		}).then(() => {
			toast.success('Report successfully shared.');
			this.toggleShareModal();
			this.setState({
				sharedPublishers: partnerIds,
			});
		}).catch(() => {
			toast.error(`Couldn't share report.`);
		});
	}

	render() {
		const { match } = this.props;
		const {
			loading,
			report,
			alarmNames,
			currentTab,
			cpaCampaignData,
			cpiCampaignData,
			openShareModal,
			sharedPublishers,
			reportPartners,
		} = this.state;

		return (
			<div className="campagin-detail">
				<PageTitle>
					<Breadcrumbs>
						<Breadcrumbs.Crumb><Link to="/reports/summary">Reports</Link></Breadcrumbs.Crumb>
						<Breadcrumbs.Crumb><Link to={match.url}>Summary Report Detail</Link></Breadcrumbs.Crumb>
					</Breadcrumbs>
					<PageTitle.Title>
						Summary Report Detail
					</PageTitle.Title>
				</PageTitle>
				{report && <div className="report-date-wrapper">
					<span className="report-date">{moment.utc(report.ts_start, 'X').format('DD/MM/YY')} - {moment.utc(report.ts_end, 'X').format('DD/MM/YY')}</span>
					<div style={{ maxWidth: 800 }}>
						<div className="alarm-wrapper">
							<span>Deductible Alarms: </span> {alarmNames.join(', ')}
						</div>
					</div>
				</div>}
				{this.buildHeader()}
				{report && <div className="build-with-partner-button-wrapper">
					<Button bgColor="transparent" mini onClick={this.toggleShareModal}>Share with partner</Button>
				</div>}
				<div className="summary-report-tab-button">
					{!loading && <RadioGroup
						value={currentTab}
						onChange={(v) => this.setState({ currentTab: v })}
						options={SummaryReportTabOptions.filter((option) => {
							if (option.type === 'CPA') return cpaCampaignData.length > 0;
							if (option.type === 'CPI') return cpiCampaignData.length > 0;
							return true;
						})}
					/>}
				</div>
				{loading ? <Loader /> : this.generateTable()}
				{report && <ShareWithPartnerModal
					open={openShareModal}
					reportPartners={reportPartners}
					sharedPublishers={sharedPublishers}
					onSubmit={this.shareWithPartner}
					onCancel={this.toggleShareModal}
				/>}
			</div>
		);
	}
}

export default withTitle(SummaryReportDetail, 'Summary Report');
