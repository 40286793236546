import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
	Icon,
	Tooltip,
	Button,
} from 'interceptd-ui';

import { geSourcePayoutCurrency } from '../../../utils/misc';

import Api from '../../../services/api';
import history from '../../../services/history';

import './styles/SourceInfo.css';

const TrackingUrl = props => {
	const [open, setOpen] = React.useState(false);

	if (!props.children) return null;

	const MAX = 100;

	return (
		<div className={`campaign-summary-col-content ${open ? 'open' : ''}`}>
			<span className="campaign-summary-col-data campaign-tracking-url">
				{!open && props.children.length > MAX ?
					`${props.children.substring(0, MAX - 40)}...` :
					props.children}
				{props.children.length > MAX &&
					<span onClick={() => setOpen(!open)}>{open ? 'less' : 'more'}</span>
				}
			</span>
		</div>
	)
}

const Partner = ({ partner }) => (
	<div className="source-info-partner">
		<div className="select-value-logo">
			<img src={partner.logo} alt={partner.label} />
		</div>
		<div className="source-info-partner-detail">
			<div className="campaign-summary-col-title"><span>Publisher</span></div>
			<div className="campaign-summary-col-content">
				<span className="campaign-summary-col-data">{partner.name}</span>
			</div>
		</div>
	</div>
);

class SourceInfo extends Component {
	state = {
		partner: null,
		currency: geSourcePayoutCurrency(this.props?.data?.id),
	};

	detailRef = React.createRef();

	componentDidMount() {
		const { data } = this.props;

		Api.getPartners({ size: 1000 })
			.then((response) => {
				const [partner] = (response?.data?.data ?? []).filter(p => p.id === data.partner);
				this.setState({ partner });
			})
			.catch(err => console.error(err));
	}

	render() {
		const { match, open, data, type } = this.props;
		const { partner, currency } = this.state;

		return (
			<div className={`campaign-summary ${open ? 'open' : ''}`}>
				<div className="campaign-summary-detail" ref={this.detailRef} style={{ '--max-height': `${this.detailRef.current && (this.detailRef.current.scrollHeight + 50)}px` }}>
					<div className="campaign-summary-detail-inner">

						<div className="campaign-summary-left">

							<div className="campaign-summary-row">

								{partner && <div className="campaign-summary-col">
									<Partner partner={partner} />
								</div>}

								<div className="campaign-summary-col">
									<div className="campaign-summary-col-title"><span>Name</span></div>
									<div className="campaign-summary-col-content">
										<span className="campaign-summary-col-data">{data.name}</span>
									</div>
								</div>

								<div className="campaign-summary-col">
									<div className="campaign-summary-col-title"><span>Install Postback</span></div>
									<div className="campaign-summary-col-content">
										<span className="campaign-summary-col-data">{data.disable_install_postback ? 'Disable' : 'Enable'}</span>
									</div>
								</div>

								<div className="campaign-summary-col">
									<div className="campaign-summary-col-title"><span>Postback Only Unique Events</span></div>
									<div className="campaign-summary-col-content">
										<span className="campaign-summary-col-data">{!data.unique_events_to_postback || data.unique_events_to_postback.length === 0 ? 'Disable' : 'Enable'}</span>
									</div>
								</div>
							</div>

							<div className="campaign-summary-row">
								<div className="campaign-summary-col">
									<div className="campaign-summary-col-title"><span>Bot prevention</span></div>
									<div className="campaign-summary-col-content">
										<span className="campaign-summary-col-data">{data.check_bot ? 'Active' : 'Disable'}</span>
									</div>
								</div>

								{(data.selected_events && data.selected_events.length > 0) && <div className="campaign-summary-col">
									<div className="campaign-summary-col-title"><span>Events</span></div>
									<div className="campaign-summary-col-content">
										<span className="campaign-summary-col-data">{data.selected_events.join(', ')}</span>
									</div>
								</div>}

								{(data.bid !== null && data.bid !== undefined && type === 'CPI') && <div className="campaign-summary-col">
									<div className="campaign-summary-col-title"><span>Bid</span></div>
									<div className="campaign-summary-col-content">
										<span className="campaign-summary-col-data">{data.bid}{currency?.payoutSymbol}</span>
									</div>
								</div>}

								{data.event_payouts && type === 'CPA' && <div className="campaign-summary-col">
									<div className="campaign-summary-col-title"><span>Event Payouts</span></div>
									<div className="campaign-summary-col-content">
										<div className="campaign-summary-col-data">
											<ul>
												{Object.keys(data.event_payouts).map((ep, index) => (
													<li key={`${index}-${ep}`}><span>{ep}</span>: {data.event_payouts[ep]}{currency?.payoutSymbol}</li>
												))}
											</ul>
										</div>
									</div>
								</div>}
							</div>

							<div className="campaign-summary-row campaign-summary-inner-buttons">
								<Button className="button-icon" style={{ marginRight: 10 }} bgColor="transparent" onClick={() => history.push(`/campaign/${match.params.id}/edit/sources?source=${match.params.source_id}`, { back: match.url })}>Edit</Button>
								<Button className="button-icon" bgColor="transparent" to={`${match.url}/history`} component={Link}>History</Button>
							</div>
						</div>

						<div className="campaign-summary-right">
							<div className="campaign-summary-col">
								<div className="campaign-summary-col-title">
									<span style={{ marginRight: 10 }}>Interceptd Tracking Url</span>
									<Tooltip position="left" tooltip="Please share this link with the relevant ad network. Make sure that they use only this link and not the (original) MMP links.">
									  <Icon size="14" i="info" />
									</Tooltip>
								</div>
								<div className="campaign-summary-col-content">
									<TrackingUrl>
										{data.tracking_url}
									</TrackingUrl>
								</div>
							</div>

							<div className="campaign-summary-col">
								<div className="campaign-summary-col-title"><span>Tracking Url</span></div>
								<div className="campaign-summary-col-content">
									<TrackingUrl>
										{data.redirect_url}
									</TrackingUrl>
								</div>
							</div>

						</div>

					</div>
				</div>
			</div>
		)
	}
}

export default SourceInfo;
