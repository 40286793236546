// Public Pages
import Login from './pages/Auth/Login';
import LoginAs from './pages/Auth/LoginAs';
import Register from './pages/Auth/Register';
import Logout from './pages/Auth/Logout';
import ResetPassword from './pages/Auth/ResetPassword';
import ForgotPassword from './pages/Auth/ForgotPassword';
import Verify from './pages/Auth/Verify';
import Join from './pages/Auth/Join';
import Static from './pages/Static';

// Private Pages
import { default as MultiCampaign } from './pages/MultiCampaign';

import { default as CampaignDetail } from './pages/Campaign/Detail';
import { default as TEMP_Sankey } from './pages/Campaign/TEMP_Sankey';
import { default as CampaignHistory } from './pages/Campaign/History';
import { default as SourceDetail } from './pages/Campaign/Source/SourceDetail';
import { default as SourceHistory } from './pages/Campaign/Source/History';
import { default as SubPublishers } from './pages/Campaign/Subpub/List';
import { default as SubPubDetail } from './pages/Campaign/Subpub/Detail';

import { default as ReportDetail } from './pages/Report/ReportDetail';
import { default as SummaryReportDetail } from './pages/Report/SummaryReportDetail';
import { default as ReportCreate } from './pages/Report/ReportCreate';

import { default as StartFreeTrial } from './pages/StartFreeTrial';

import { default as RedirectionReportList } from './pages/RedirectionReport/List';
import { default as RedirectionReportDetail } from './pages/RedirectionReport/Detail';
import { default as RedirectionReportRequest } from './pages/RedirectionReport/Request';

import { default as Survey } from './pages/Survey/Survey';

/* Misc */
import { default as Presentation } from './pages/Misc/Presentation';

import NotFound from './pages/NotFound';

import { ReportTypes, ListTypes } from './constants/Campaign';

import SavingsExport from './components/Savings/SavingsExport';

export const publicRoutes = [
	{
		path: '/welcome',
		component: Login,
	},
	{
		path: '/signup',
		component: Register,
	},
	{
		path: '/logout',
		component: Logout,
	},
	{
		path: '/login_as/:token/',
		component: LoginAs,
	},
	{
		path: '/switch/:token/',
		component: LoginAs,
	},
	{
		path: '/forgot-password',
		component: ForgotPassword,
	},
	{
		path: '/password-reset/:token',
		component: ResetPassword,
	},
	{
		path: '/verify/:code?',
		component: Verify,
	},
	{
		path: '/join/:token?',
		component: Join,
	},
	{
		path: '/static/terms-of-use',
		component: Static,
	},
	{
		path: '/static/privacy-policy',
		component: Static,
	},
	{
		path: '/misc/presentation',
		component: Presentation,
	},
	/**
	 * Survey
	 */
	{
		path: '/survey',
		exact: true,
		component: Survey
	},
];

export const privateRoutes = [
	{
		path: '/create/:page?',
		exact: true,
		component: null
	},
	{
		path: '/campaign/:id/edit/:page?',
		exact: true,
		component: null
	},
	{
		path: '/multi-create',
		exact: true,
		component: MultiCampaign
	},
	{
		path: '/start-free-trial',
		exact: true,
		component: StartFreeTrial,
	},

	/**
	 * Campaigns
	 */
	{
		path: [
			'/',
			...(ListTypes.map(type => `/${type.value}`))
		],
		exact: true,
		component: null
	},
	{
		path: '/publishers',
		exact: true,
		component: null
	},
	{
		path: '/apps',
		exact: true,
		component: null
	},
	{
		path: '/archive',
		exact: true,
		component: null
	},
	{
		path: '/campaign/:id',
		exact: true,
		component: CampaignDetail
	},
	{
		path: '/campaign/:id/sankey',
		exact: true,
		component: TEMP_Sankey
	},
	{
		path: '/campaign/:id/history',
		exact: true,
		component: CampaignHistory
	},
	{
		path: '/campaign/:id/source/:source_id',
		exact: true,
		component: SourceDetail
	},
	{
		path: '/campaign/:id/source/:source_id/history',
		exact: true,
		component: SourceHistory
	},
	{
		path: '/campaign/:id/subpublishers',
		exact: true,
		component: SubPublishers,
	},
	{
		path: '/campaign/:id/source/:source_id/subpublisher/:subpub_id',
		exact: true,
		component: SubPubDetail,
	},

	/**
	 * Reports
	 */
	{
		path: '/reports/create',
		exact: true,
		component: ReportCreate
	},
	{
		path: [
			'/reports',
			...(ReportTypes.map(type => `/reports/${type.value}`))
		],
		exact: true,
		component: null
	},
	{
		path: '/reports/detail/:report_type/:id',
		exact: true,
		component: ReportDetail
	},
	{
		path: '/reports/summary/:id',
		exact: true,
		component: SummaryReportDetail
	},
	
	/**
	 * Daily Performance
	 */
	{
		path: [
			'/daily-performance',
			'/daily-performance/table'
		],
		exact: true,
		component: null,
	},

	/**
	 * Account
	 */
	{
		path: '/account/:page?',
		exact: true,
		component: null
	},
	{
		path: '/account/manage-rulesets/:page?',
		exact: true,
		component: null
	},

	/**
	 * Redirection Report
	 */
	{
		path: '/fraud-insights',
		exact: true,
		component: RedirectionReportList
	},
	{
		path: '/fraud-insights/request',
		exact: true,
		component: RedirectionReportRequest
	},
	{
		path: '/fraud-insights/:id',
		exact: true,
		component: RedirectionReportDetail
	},

	/**
	 * Secure Links
	 */
	{
		path: '/secure-link/:id?',
		exact: true,
		component: null
	},

	/**
	 * Event Analytics
	 */
	{
		path: '/event-analytics/:id?',
		exact: true,
		component: null
	},

	/**
	 * Savings Export
	 */
	{
		path: '/savings-export',
		exact: true,
		component: SavingsExport
	},
	{
		path: [
			'/savings',
			'/savings/publishers',
			'/savings/apps',
		],
		exact: true,
		component: null
	},

	/**
	 * NotFound
	 */
	{
		component: NotFound
	}
];