import React from 'react';

import TermsOfUse from './terms-of-use';
import PrivacyPolicy from './privacy-policy';

import './styles/Static.css';

class Static extends React.PureComponent {
	render() {
		const { match } = this.props;

		const url = match.path.split('/');
		const page = url[url.length - 1];

		return (
			<article className="static-page">
				{page === 'terms-of-use' ? <TermsOfUse /> : <PrivacyPolicy />}
			</article>
		)
	}
}

export default Static;
