import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import get from 'lodash/get';
import find from 'lodash/find';
import {
	Box,
	Loader,
	NoData,
	Icon,
	Actions,
	Button,
	Select,
	Info,
} from 'interceptd-ui';

import Table from '../../components/Table';
import RuleSetIcon from '../../components/RuleSet/RuleSetIcon';
import CampaignRuleIcon from '../../components/CampaignRule/CampaignRuleIcon';

import { columns as ListCampaignColumns } from './ListCampaignColumns';
import { columns as ListSourceColumns } from './ListSourceColumns';

import { Warnings, campaignSortOptions } from '../../constants/Campaign';

import Api from '../../services/api';
import Local from '../../services/localStorage';
import history from '../../services/history';
import SendEvent from '../../services/events';

class ListCampaigns extends React.Component {
	state = {
		expandedState: {},
		resizedState: [],
		sortedState: [],
		
		campaignSortType: null,
	}

	showSources = index => {
		const { expandedState } = this.state;

		SendEvent({
			category: 'Campaign List',
			action: `Campaign sources panel ${expandedState[index] ? 'closed' : 'opened'}`,
			nonInteraction: false,
		});
		this.setState({
			expandedState: expandedState[index] ? {}: {
				[index]: true
			},
		});
	}

	renderSources = ({ original }) => {
		const { resizedState } = this.state;
		const { eventsColumnWidth, getTotalStats, renderStatsCell, showImpression } = this.props;

		return (
			<div className="sources">
				<Table
					ref={el => { this.subTable = el }}
					data={original.sources}
					columns={
						ListSourceColumns({
							hideHeaders: true,
							showImpression,
							renderStatsCell,
							getTotalStats,
							renderActions: this.props.renderSourceActions,
							renderRulesCell: this.renderRulesCell,
							eventsColumnWidth: eventsColumnWidth,
						})
					}
					pages={0}
					PadRowComponent={() => null}
					sortable={false}
					showPageSizeOptions={false}
					showPageJump={false}
					showPagination={false}
					pageSize={original.sources.length}
					resized={resizedState}
					getTrProps={(state, rowInfo, column) => {
						return rowInfo.original.status !== 1 ? ({
							style: {
								filter: 'grayscale(1)',
								textDecoration: 'line-through'
							}
						}) : ({});
					}}
				/>
			</div>
		)
	}

	pauseCampaign = props => {
		const { original } = props;
		const { getCampaigns } = this.props;

		Api.blockCampaign(original.id)
			.then(res => {
				getCampaigns()
			})
			.catch(({ response }) => {
				SendEvent({
					description: `pauseCampaign for ${original.id} on campaign list`,
					fatal: false
				});
				toast.error(`Couldn't pause the source`);
			});
	}

	startCampaign = props => {
		const { original } = props;
		const { getCampaigns } = this.props;

		Api.unblockCampaign(original.id)
			.then(res => {
				getCampaigns()
			})
			.catch(({ response }) => {
				SendEvent({
					description: `startCampaign for ${original.id} on campaign list`,
					fatal: false
				});
				toast.error(`Couldn't pause the source`);
			})
	}

	cloneCampaign = props => {
		history.push('/create', {
			clone: props.original
		});
	}

	archiveCampaign = props => {
		const { archiveCampaign } = this.props;
		archiveCampaign(props);
	}

	goToCreateCampaign = props => {
		history.push('/create');
	}

	renderActions = props => {
		const { match } = this.props;
		const { original } = props;

		const isArchivedPage = match.url.indexOf('archive') !== -1;

		return Local.getItem('me')?.plans?.interceptd !== 'free' ? (
			<Actions>
				<Actions.Item component={Link} to={`/campaign/${original.id}/edit`} data-event-category="Campaign List" data-event-action="Edit campaign action clicked">Edit Campaign</Actions.Item>
				{original.type !== 'CPM' && find(original.campaign_source_states, s => s.blocked_by === 'customer' && s.status === 3) &&
					<Actions.Item onClick={() => this.startCampaign(props)} data-event-category="Campaign List" data-event-action="Start all sources action clicked">Start all sources</Actions.Item>
				}
				{original.type !== 'CPM' && find(original.campaign_source_states, s => s.status === 1) &&
					<Actions.Item onClick={() => this.pauseCampaign(props)} data-event-category="Campaign List" data-event-action="Pause all sources action clicked">Pause all sources</Actions.Item>
				}
				<Actions.Item onClick={() => this.cloneCampaign(props)} data-event-category="Campaign List" data-event-action="Clone this campaign action clicked">Clone this campaign</Actions.Item>
				<Actions.Item className={isArchivedPage ? 'bg-green' : 'bg-red'} onClick={() => this.archiveCampaign(props)} data-event-category="Campaign List" data-event-action="Archive this campaign action clicked">{isArchivedPage ? 'Unarchive' : 'Archive'}</Actions.Item>
			</Actions>
		) : null
	}

	setResized = (newResized, event) => {
		this.setState({
			resizedState: newResized,
		})
	}

	renderWarnings = props => {
		const campaign = props.original;
		const warnings = [];

		if (!campaign.target_app_version)
			warnings.push('am');
		if (!campaign.target_os_version)
			warnings.push('ov');
		if (!campaign.target_sdk_version)
			warnings.push('sv');
		if ((!campaign.country || campaign.country.length === 0))
			warnings.push('cm');

		return warnings.length !== 0 ? (
			<div className="campaign-warnings">
				<Info icon="alert-triangle" iconSize={16} position="right info-warning">
					{warnings.map(w => (
						<div key={w} className="campaign-warning">{Warnings[w]}</div>
					))}
					<Link className="campaign-warning-button" to={`/campaign/${campaign.id}/edit`}>
						<Icon i="edit" size={16} /> Add Missing Targets
					</Link>
				</Info>
			</div>
		) : null
	}

	handleSortType = ([campaignSortType]) => {
		const [id, type] = (campaignSortType || '_').split('_');
		this.setState({
			campaignSortType,
			sortedState: campaignSortType ? [{
				id,
				desc: type !== 'performance',
			}] : []
		});
	}

	renderRulesCell = ({ original }) => {
		const { rulesets, campRulesets, getRulesets } = this.props;

		const fraudRuleset = get(rulesets, `[${original.id}].ruleset`);
		const campRuleset = get(campRulesets, `[${original.id}].ruleset`);

		return (
			<div className="rules-column">
				<RuleSetIcon
					campaign={original}
					alarms={original.alarms.filter(a => a.alarm_type.indexOf('EIR') === -1)}
					ruleset={fraudRuleset}
					onReload={getRulesets} />
				<CampaignRuleIcon 
					campaign={original}
					alarms={original.alarms.filter(a => a.alarm_type.indexOf('EIR') !== -1)}
					ruleset={campRuleset}
					onReload={getRulesets} />
			</div>
		)
	}

	render() {
		const { match, archivedCampaigns, campaignsFetching, campaigns, eventsColumnWidth, renderStatsCell, getTotalStats, showImpression } = this.props;
		const { expandedState, sortedState, campaignSortType } = this.state;

		const isArchivedPage = match.url.indexOf('archive') !== -1;

		return (
			<div className="campaign-list-campaigns">
				<Box
						className="box-table-wrapper campaign-list-table-box"
						title="MOBILE CAMPAIGNS"
						right={
							<React.Fragment>
								{campaignsFetching && <Loader mini size={25} />}
								<Select
									mini
									placeholder={campaignSortType ? `Sort by ${campaignSortOptions.find(o => o.value === campaignSortType).displayName}` : 'Sort'}
									persistPlaceholder
									options={campaignSortOptions}
									value={[campaignSortType]}
									onChange={this.handleSortType}
									getTetherProps={props => ({
										...props,
										attachment: 'top right',
										targetAttachment: 'bottom right',
									})}
									wrapperProps={{
										style: {
											marginRight: 15
										},
									}}
									clearable
									renderSearch={() => null}
								 />
							</React.Fragment>
						}>
						<Table
							data={campaigns}
							columns={
								ListCampaignColumns({
									renderStatsCell,
									getTotalStats,
									eventsColumnWidth,
									campaignSortOptions,
									renderActions: this.renderActions,
									renderWarnings: this.renderWarnings,
									renderRulesCell: this.renderRulesCell,
									onTargetChange: this.handleTargetChange,
									showImpression,
									onExpandedChange: (index) => this.showSources(index),
								})
							}
							pages={0}
							pageSize={campaigns.length}
							loading={campaignsFetching}
							showPageSizeOptions={false}
							showPageJump={false}
							defaultSortDesc={true}
							expanded={expandedState}
							sorted={sortedState}
							onSortedChange={newSorted => this.setState({
								sortedState: newSorted
							})}
							PadRowComponent={() => null}
							SubComponent={this.renderSources}
							NoDataComponent={() => (
								<NoData>
									{isArchivedPage ? (
										`You don't have any archived campaign.`
									) : (
										`You didn't create any campaign yet.`
									)}
									{isArchivedPage ? (
										<Button to="/" component={Link}>Show Active Campaigns</Button>
									) : (
										<Button onClick={this.goToCreateCampaign}>Create your first campaign</Button>
									)}
								</NoData>
							)}
							onResizedChange={this.setResized}
							getTrGroupProps={(state, rowInfo, column) => {
								const { expandedState } = this.state;

								if (rowInfo && expandedState[rowInfo.viewIndex]) {
									return ({
										className: '-open'
									})
								}
							}}
						/>
					</Box>

					{archivedCampaigns.length > 0 &&
						<div className="campaigns-toggle-archive-wrapper">
							<Button className="campaigns-toggle-archive" to={isArchivedPage ? '/' : '/archive'} component={Link}>
								Show {isArchivedPage ? 'Active' : 'Archived'} Campaigns
							</Button>
						</div>
					}
			</div>
		)
	}
}

export default ListCampaigns;
