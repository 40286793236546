import React from 'react';
import { NoData } from 'interceptd-ui';

import './styles/NoResult.css';

const NoResult = props => {
	return (
		<div className="noresult">
			<NoData icon="box">There is no data</NoData>
		</div>
	)
}

export default NoResult;
