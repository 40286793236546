import React from 'react';

const withTitle = (WrappedComponent, title, idSelector, renderTitle) => {
  return class extends React.Component {
    componentDidMount() {
      const { match } = this.props;
      document.title = renderTitle ? renderTitle(match) : title ? `Interceptd - ${title}${idSelector ? ` for ${match.params[idSelector]}` : ''}` : 'Interceptd';
    }
    componentWillUnmount() {
      document.title = 'Interceptd';
    }
    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
}

export default withTitle;