import React, { Fragment } from 'react';
import moment from 'moment';
import find from 'lodash/find';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import startCase from 'lodash/startCase';
import {
	Icon,
	Info,
} from 'interceptd-ui';

const filteredEventColumn = {
	Header: 'Filtered Events',
	id: 'filtered_events',
	accessor: 'filtered_events',
	sortable: false,
	cast: 'string',
	Cell: (props) => {
		const longNames = [];

		(props.value || []).forEach((a) => {
			longNames.push(a);
		});

		if (longNames.length <= 0) {
			return (
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<span style={{ marginLeft: 5 }}>-</span>
				</div>
			);
		}

		if (longNames.length === 1) {
			return (
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<span style={{ marginLeft: 5 }}>{longNames[0]}</span>
				</div>
			);
		}

		return (
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<Info position="right">
					{longNames.join(', ')}
				</Info>
				<span style={{ marginLeft: 5 }}>{`${longNames.length} Events`}</span>
			</div>
		);
	}
};

export const columns = (
	download,
	renderCampaignsColumn,
	renderSharedPublisherColumn,
	reportType,
	alarms,
) => {
	const extraColumns = reportType !== 'summary'
		? reportType === 'event' ? [filteredEventColumn]: []
		: [{
			Header: 'Alarms',
			id: 'alarms',
			accessor: 'config',
			sortable: false,
			cast: 'string',
			Cell: (props) => {
				const longNames = [];
				(props.value.deductible_alarms || []).forEach((a) => {
					const obj = find(alarms, ['short-name', a.replace(/_DET/g, '')]);
					if (obj) {
						longNames.push(obj.name);
					}
				});
				return (
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<Info position="right">
							{longNames.join(', ')}
						</Info>
						<span style={{ marginLeft: 5 }}>{`${longNames.length} Alarms`}</span>
					</div>
				);
			},
		},
		filteredEventColumn,
		{
			Header: 'Click Lookback Window',
			id: 'install_lookback_window',
			accessor: 'install_lookback_window',
			sortable: false,
			cast: 'string',
			Cell: (props) => {
				return `${get(props, 'original.config.click_lookback_window', 0)} Days`;
			}
		}, {
			Header: 'Install Lookback Window',
			id: 'install_lookback_window',
			accessor: 'install_lookback_window',
			sortable: false,
			cast: 'string',
			Cell: (props) => {
				return `${get(props, 'original.config.install_lookback_window', 0)} Days`;
			}
		}, {
			Header: 'Shared Partner',
			id: 'shared_partners',
			Cell: renderSharedPublisherColumn,
		}];

	return [
		{
			Header: 'REPORT TYPE',
			id: 'id',
			accessor: 'id',
			cast: 'string',
			style: {
				overflow: 'visible',
			},
			width: 300,
			Cell: props => {
				const report = props.original;
				const reportType = report.report_type;
				const reportTitle = `${report.id} - ${startCase(reportType)} Report`;

				if (reportType === 'click' || reportType === 'impression') {
					if (report.is_processed === 0) {
						return reportTitle;
					}

					return (
						<Link to={`/reports/detail/${reportType}/${report.id}`}>
							{reportTitle}
						</Link>
					);
				}

				if (reportType === 'summary') {
					if (report.is_processed === 0) {
						return reportTitle;
					}

					return (
						<Link to={`/reports/summary/${report.id}`}>
							{reportTitle}
						</Link>
					);
				}

				return (
					<div className="campaign-table-name deduction-report-table-name">
						<span>{reportTitle}</span>
					</div>
				);
			}
		},
		{
			Header: 'Campaigns',
			id: 'campaigns',
			accessor: 'source_id',
			cast: 'string',
			Cell: renderCampaignsColumn
		},
		{
			Header: 'CREATED DATE',
			id: 'ts_requested',
			accessor: 'ts_requested',
			cast: 'string',
			width: 200,
			Cell: props => {
				const { ts_requested } = props.original;

				return `${moment.utc(ts_requested, 'X').format('MMM DD, YYYY')}`;
			}
		},
		{
			Header: 'Report Range',
			id: 'ts_start',
			accessor: 'ts_start',
			cast: 'string',
			Cell: props => {
				if (!props || !props.original) return '-';
				const { ts_start, ts_end } = props.original;

				return `${moment.utc(ts_start, 'X').format('MMM DD, YYYY')} - ${moment.utc(ts_end, 'X').format('MMM DD, YYYY')}`;
			}
		},
		...extraColumns,
		{
			Header: '',
			id: 'actions',
			accessor: 'actions',
			width: 110,
			sortable: false,
			Cell: props => {
				const report = props.original;

				if (report.is_processed === 0) {
					return <span className="report-table-status">Pending</span>
				}

				return (
					<div className="report-table-actions">
						{!!report.is_processed && (report.report_type === 'click' || report.report_type === 'summary') && (
							<Link
								to={`/reports${report.report_type === 'click' ? '/detail' : ''}/${report.report_type}/${report.id}`}
								className="action-view">
								<Icon i="eye" size={18} />
							</Link>
						)}
						{!!report.is_processed && report.report_type !== 'summary' && (
							<Fragment>
								<span onClick={() => download(report)}>
									<Icon i="download" size={18} />
								</span>
								<span onClick={() => download(report, true)}>
									<Icon i="external-link" size={18} />
								</span>
							</Fragment>
						)}
					</div>
				);
			},
		},
	]
};
