import React, { useEffect, useRef } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';
import get from 'lodash/get';
import {
	Loader,
	NoData,
	Icon,
	Alert,
	Button,
	Input,
	AppBox,
	AppPlaceholder,
} from 'interceptd-ui';

import RadioGroup from '../../components/Input/RadioGroup';
import AppSearch from '../../components/AppSearch';

import Api from '../../services/api';
import Auth from '../../services/auth';
import SendEvent from '../../services/events';

import { versionCompare, validateEmail } from '../../utils/misc';

import './styles/Survey.css';

import withTitle from '../withTitle';

import FraudScoreImage from '../../assets/fraud-result.svg';

const getRandom = () => {
	return Math.floor(Math.random() * 99999) + 10000;
}

const Question = props => {
	const { index, isActive, question, fetching, app, value, onChange, searchApp, error } = props;
	const inputRef = useRef(null);

	useEffect(() => {
		if (isActive) {
			inputRef && inputRef.current && inputRef.current.focus();
		}
	}, [isActive])

	const [id] = React.useState(getRandom());

	return (
		<div className={`survey-question ${isActive ? 'active' : ''}`}>
			<div className="survey-question-wrapper">
				<div className="survey-question-question">
					{question.question}
				</div>
				<div className="survey-question-input">
					{question.type === 'app' ? (
						<React.Fragment>
							{app && !fetching ? (
								<AppBox app={app} />
							) : (
									<AppPlaceholder />
								)}

							<AppSearch setAppData={(value) => searchApp(index, question, value)} defaultText={app?.title || ''} />

							{error && <Alert type="error">{error}</Alert>}
						</React.Fragment>
					) : question.type === 'text' ? (
						<Input
							inputRef={inputRef}
							label={question.label}
							placeholder={question.placeholder}
							name={`question-${id}`}
							value={value}
							onChange={({ target: { value } }) => onChange(index, question, value)}
							required={question.required} />
					) : question.type === 'select-single' ? (
						<RadioGroup
							options={question.options.map(option => ({
								label: option,
								value: option,
							}))}
							value={value}
							onChange={value => onChange(index, question, value)} />
					) : question.type === 'select-multi' ? (
						<RadioGroup
							multi
							options={question.options.map(option => ({
								label: option,
								value: option,
							}))}
							value={value}
							onChange={value => onChange(index, question, value)} />
					) : question.type === 'boolean' ? (
						<RadioGroup
							options={[
								{
									label: question.firstChoice,
									value: question.firstChoice,
								},
								{
									label: question.secondChoice,
									value: question.secondChoice,
								},
							]}
							value={value}
							onChange={value => onChange(index, question, value)} />
					) : null}
				</div>
				<div className="survey-question-spacer" />
			</div>
		</div>
	)
}

class Survey extends React.Component {
	state = {
		loading: true,
		errorMessage: null,
		data: null,
		questions: null,
		showFraudScore: false,

		active: 0,
		isNextActive: false,
		score: 0,
		answers: [],

		app: null,
		store_url: '',

		reportId: null,

		redirect: false,
		redirectToList: false,

		scoreHistory: [],
		appNames: get(this, 'props.location.state.appNames', []),

		email: '',
	}

	timer = null
	nextTimer = null

	componentDidMount = () => {
		this.getData();
	}

	getData = () => {
		Api.getSurveyQuestions()
			.then(response => {
				this.setState({
					loading: false,
					data: get(response, 'data.data', {}),
					questions: JSON.parse(get(response, 'data.data.question', "{}")).payload || [],
				});
			})
			.catch((err) => {
				toast.error(`Couldn't fetch survey questions.`);
				this.setState({
					loading: false
				});
			});
	}

	searchApp = (index, question, app) => {
		const { appNames } = this.state;

		this.handleAnswerChange(index, question, app.id);
		const errorMessage = appNames.indexOf(app.title) > -1
			? 'This app already has a fraud health report.'
			: null;

		this.setState({
			errorMessage,
			app,
			isNextActive: !errorMessage,
		});
	}

	handleAnswerChange = (index, question, value, cb) => {
		this.setState(prevState => {
			const newAnswers = [...prevState.answers];
			if (newAnswers.some(a => a.index === index)) {
				const i = newAnswers.findIndex(a => a.index === index);
				newAnswers[i].question = newAnswers[i].question || question;
				newAnswers[i].answer = value;
			} else {
				newAnswers.push({
					index,
					question,
					answer: value
				})
			}
			return ({
				answers: newAnswers,
				isNextActive: true,
			})
		}, () => {
			if (question.type === 'select-single' || question.type === 'boolean') {
				clearTimeout(this.nextTimer);
				this.nextTimer = setTimeout(this.nextQuestion, 500);
			}
			cb && cb();
		})
	}

	getAnswer = (index) => {
		const { answers } = this.state;
		return (answers.find(a => a.index === index) || {}).answer || '';
	}

	startSurvey = () => {
		this.setState(prevState => ({
			active: prevState.active + 1
		}))
	}

	prevQuestion = () => {
		const { scoreHistory, score } = this.state;
		const lastAction = scoreHistory.pop();
		let newScore = score;

		if (lastAction && lastAction.type === 'increase') {
			newScore -= lastAction.value;
		} else if (lastAction && lastAction.type === 'decrease') {
			newScore += lastAction.value;
		}

		clearTimeout(this.nextTimer);

		this.setState(prevState => {
			return ({
				active: prevState.active - 1 > 0 ? prevState.active - 1 : 0,
				scoreHistory,
				score: newScore,
			});
		});
	}

	nextQuestion = () => {
		this.handlePostActions(() => {
			clearTimeout(this.nextTimer);
			this.setState(prevState => ({
				active: prevState.active + 1 < prevState.questions.length ? prevState.active + 1 : prevState.questions.length,
				isNextActive: false,
			}), () => {
				const { active, questions } = this.state;

				if (active !== questions.length && !this.isShowable()) {
					this.nextQuestion();
				} else if (active === questions.length) {
					this.showEndCard();
				}
			})
		})
	}

	showEndCard = () => {
		this.setState(prevState => ({
			active: prevState.questions.length + 1,
			isNextActive: false,
		}), this.saveAnswers)
	}

	saveAnswers = () => {
		if (!this.isValidEmail()) return;

		const { app, answers, score, email } = this.state;

		const method = Auth.isAuthenticated()
			? 'saveSurveyAnswers'
			: 'savePublicSurveyAnswers';

		const payload = {
			app_id: app.id,
			fraud_score: score,
			payload: {
				ts_updated: moment().format('X'),
				answers,
			},
		};

		if (!Auth.isAuthenticated()) {
			payload.email = email;
			payload.payload.app_name = app.title;
		}

		SendEvent({
			category: 'Survey',
			action: 'Save survey button clicked',
			nonInteraction: false,
		});

		Api[method](payload)
			.then(response => {
				if (Auth.isAuthenticated()) {
					return this.requestReport();
				}

				this.setState({
					showFraudScore: true,
				});
			})
			.catch((err) => {
				toast.error(get(err, 'response.data.error.message', `Couldn't save survey answers`), {
					autoClose: 5000,
				});
				if (Auth.isAuthenticated()) {
					this.setState({
						redirectToList: true,
					});
				}
			})
	}

	handlePostActions = cb => {
		this.setState(prevState => {
			if (!this.isShowable())
				return ({})

			const question = prevState.questions[prevState.active - 1];
			const answer = prevState.answers.find(a => a.index === prevState.active - 1);
			let newScore = prevState.score;

			if (question.actions && question.actions.length > 0) {
				question.actions.forEach(action => {
					let matched = false;
					switch (action.compare) {
						case 'is':
							if (question.type === 'select-single' || question.type === 'text' || question.type === 'boolean') {
								if (answer.answer.toLowerCase().trim() === action.answer.toLowerCase().trim()) {
									matched = true;
								}
							} else if (question.type === 'select-multi') {
								if (answer.answer.some(a => a.toLowerCase().trim() === action.answer.toLowerCase().trim())) {
									matched = true;
								}
							}
							break;
						case 'is-not':
							if (question.type === 'select-single' || question.type === 'text' || question.type === 'boolean') {
								if (answer.answer.toLowerCase().trim() !== action.answer.toLowerCase().trim()) {
									matched = true;
								}
							} else if (question.type === 'select-multi') {
								if (!answer.answer.some(a => a.toLowerCase().trim() === action.answer.toLowerCase().trim())) {
									matched = true;
								}
							}
							break;
						case 'is-one-of':
							if (question.type === 'select-single' || question.type === 'text' || question.type === 'boolean') {
								if (action.answer.some(a => answer.answer.toLowerCase().trim() === a.toLowerCase().trim())) {
									matched = true;
								}
							} else if (question.type === 'select-multi') {
								if (answer.answer.some(a => action.answer.some(b => a.toLowerCase().trim() === b.toLowerCase().trim()))) {
									matched = true;
								}
							}
							break;
						case 'is-not-one-of':
							if (question.type === 'select-single' || question.type === 'text' || question.type === 'boolean') {
								if (!action.answer.some(a => answer.answer.toLowerCase().trim() === a.toLowerCase().trim())) {
									matched = true;
								}
							} else if (question.type === 'select-multi') {
								if (!answer.answer.some(a => action.answer.some(b => a.toLowerCase().trim() === b.toLowerCase().trim()))) {
									matched = true;
								}
							}
							break;
						case 'is-lower':
							if (versionCompare(answer.answer.toLowerCase().trim(), action.answer.toLowerCase().trim()) < 0) {
								matched = true;
							}
							break;
						case 'is-higher':
							if (versionCompare(answer.answer.toLowerCase().trim(), action.answer.toLowerCase().trim()) > 0) {
								matched = true;
							}
							break;
						default:
							matched = false;
					}

					if (matched) {
						const value = +(action.value || 1);

						this.setState(prevState => {
							const { scoreHistory } = prevState;
							scoreHistory.push({
								value,
								type: action.type,
							});
							return ({ scoreHistory });
						});

						if (action.type === 'increase') {
							newScore = +newScore + value;
						} else if (action.type === 'decrease') {
							newScore = +newScore - value;
						}
					} else {
						this.setState(prevState => {
							const { scoreHistory } = prevState;
							scoreHistory.push(null);
							return ({ scoreHistory });
						});
					}
				})
			}

			return ({
				score: newScore
			})
		}, cb)
	}

	isShowable = cb => {
		const { questions, answers, active, app } = this.state;

		const question = questions[active - 1];
		let show = true;

		if (question.conditions && question.conditions.length > 0) {
			show = question.conditions.every(condition => {
				let matched = false;
				const compareAnswer = answers.find(a => +a.index === +condition.question);
				const compareQuestion = compareAnswer.question;
				switch (condition.compare) {
					case 'is':
						if (compareQuestion.type === 'select-single' || compareQuestion.type === 'text' || compareQuestion.type === 'boolean') {
							if (compareAnswer.answer.toLowerCase().trim() === condition.value.toLowerCase().trim()) {
								matched = true;
							}
						} else if (compareQuestion.type === 'select-multi') {
							if (compareAnswer.answer.some(a => a.toLowerCase().trim() === condition.value.toLowerCase().trim())) {
								matched = true;
							}
						} else if (compareQuestion.type === 'app') {
							if (app.device.toLowerCase().trim() === condition.value.toLowerCase().trim()) {
								matched = true;
							}
						}
						break;
					case 'is-not':
						if (compareQuestion.type === 'select-single' || compareQuestion.type === 'text' || compareQuestion.type === 'boolean') {
							if (compareAnswer.answer.toLowerCase().trim() !== condition.value.toLowerCase().trim()) {
								matched = true;
							}
						} else if (compareQuestion.type === 'select-multi') {
							if (!compareAnswer.answer.some(a => a.toLowerCase().trim() === condition.value.toLowerCase().trim())) {
								matched = true;
							}
						} else if (compareQuestion.type === 'app') {
							if (app.device.toLowerCase().trim() !== condition.value.toLowerCase().trim()) {
								matched = true;
							}
						}
						break;
					case 'is-one-of':
						if (compareQuestion.type === 'select-single' || compareQuestion.type === 'text' || compareQuestion.type === 'boolean') {
							if (condition.value.some(a => compareAnswer.answer.toLowerCase().trim() === a.toLowerCase().trim())) {
								matched = true;
							}
						} else if (compareQuestion.type === 'select-multi') {
							if (compareAnswer.answer.some(a => condition.value.some(b => a.toLowerCase().trim() === b.toLowerCase().trim()))) {
								matched = true;
							}
						} else if (compareQuestion.type === 'app') {
							if (condition.value.some(v => v.toLowerCase().trim() === app.device.toLowerCase().trim())) {
								matched = true;
							}
						}
						break;
					case 'is-not-one-of':
						if (compareQuestion.type === 'select-single' || compareQuestion.type === 'text' || compareQuestion.type === 'boolean') {
							if (!condition.value.some(a => compareAnswer.answer.toLowerCase().trim() === a.toLowerCase().trim())) {
								matched = true;
							}
						} else if (compareQuestion.type === 'select-multi') {
							if (!compareAnswer.answer.some(a => condition.value.some(b => a.toLowerCase().trim() === b.toLowerCase().trim()))) {
								matched = true;
							}
						} else if (compareQuestion.type === 'app') {
							if (!condition.value.some(v => v.toLowerCase().trim() === app.device.toLowerCase().trim())) {
								matched = true;
							}
						}
						break;
					default:
						matched = true;
				}
				return matched;
			})
		}

		return show;
	}

	requestReport = () => {
		const { app } = this.state;

		this.setState({ loading: true });

		Api.requestRedirectionReport({
			app_name: app.title,
			package_name: app.id,
		})
			.then(response => {
				toast.success('Report requested', { autoClose: 5000 });
				this.setState({
					reportId: response.data.data,
					redirect: true,
				});
			})
			.catch(({ response }) => {
				this.setState({
					error: response,
					loading: false,
					redirect: false,
				});
				toast.error('Unable to request the report', { autoClose: 5000 });
			});
	}

	isValidEmail = () => Auth.isAuthenticated() || validateEmail(this.state.email);

	generateEmailInput = () => {
		if (Auth.isAuthenticated()) {
			return null;
		}

		const { email } = this.state;

		return (
			<div className="survey-email-wrapper">
				<div className="survey-email-title">
					<span className="main-title">Enter an email address so that we can match your results with your free Interceptd account.</span>
					<span className="sub-title">If you sign up with using this email address your detailed fraud health assessment report will be loaded automatically.</span>
				</div>
				<Input
					type="email"
					name="email"
					placeholder="john@doe.com"
					label="Email Address"
					value={email}
					onChange={({ target: { value } }) => {
						this.setState({ email: value });
					}}
				/>
				<Button disabled={!this.isValidEmail()} onClick={this.saveAnswers}>Continue</Button>
			</div>
		);
	}

	getScoreText = (score) => {
		let fraudScore = '-';

		if (score < 5) fraudScore = 'Low';
		if (score >= 5 && score <= 10) fraudScore = 'Medium';
		if (score > 10 && score <= 20) fraudScore = 'High';
		if (score > 20) fraudScore = 'Extremely High';

		return ({
			fraudScore,
			fraudPercent: `%${(score < 3 ? 3 : score) * 4}`
		});
	}

	generateFraudScorePage = () => {
		const { score, app, email } = this.state;
		const { fraudPercent } = this.getScoreText(score);

		return (
			<div className="fraud-score-wrapper">
				<div className="fraud-score-box">
					<AppBox app={app} />
					<span className="fraud-score-title">There's <b>{fraudPercent}</b> chance your app may exposed to ad fraud.</span>
					<img src={FraudScoreImage} className="fraud-score-image" alt="Fraud Score" />
					<span className="fraud-source-subtitle">You may see the full results with redirection Report, CPI analysis etc. by signing up for free.</span>
					<div className="fraud-score-footer">
						<Button component={Link} to={`/signup?ref=fraud-assessment&userMail=${email}`}>Sign Up</Button>
						<Button component="a" target="_blank" href="https://calendly.com/interceptd-demo/fraud-survey">Request a Call</Button>
					</div>
				</div>
			</div>
		);
	}

	render() {
		const {
			loading,
			questions,
			active,
			isNextActive,
			app,
			store_url,
			reportId,
			redirect,
			redirectToList,
			errorMessage,
			showFraudScore,
		} = this.state;

		if (showFraudScore) {
			return this.generateFraudScorePage();
		}

		if (redirect) return <Redirect push to={`/fraud-insights/${reportId}`} />;

		if (redirectToList) return <Redirect to="/fraud-insights" />;

		if (loading) return <div className="survey"><Loader /></div>;

		if (!loading && (!questions || questions.length === 0)) return <div className="survey"><NoData /></div>;

		if ((active === questions.length + 1 && !Auth.isAuthenticated()) && !showFraudScore) {
			return this.generateEmailInput();
		}

		return (
			<section className="page-wrapper">
				<div className="survey">
					<div className="survey-wrapper" style={{ '--active': active }}>
						<div className={`survey-question ${active === 0 ? 'active' : ''}`}>
							<div className="survey-question-wrapper">
								<div className="survey-question-question">
									App Fraud Health Assessment
								</div>
								<div className="survey-question-intro">
									<span>Start the survey to find out your <b>App Fraud Health</b>.</span>
									<Button onClick={this.startSurvey}>START</Button>
								</div>
								<div className="survey-question-spacer" />
							</div>
						</div>
						{questions.map((question, index) => (
							<Question
								key={index}
								index={index}
								isActive={index === active - 1}
								question={question}
								app={app}
								error={errorMessage}
								store_url={store_url}
								value={this.getAnswer(index)}
								onChange={this.handleAnswerChange}
								searchApp={this.searchApp} />
						))}
					</div>
					<div className={`survey-nav ${(active === 0 || active === questions.length + 1) ? 'nav-hidden' : ''}`}>
						<span className="survey-nav-prev active" onClick={this.prevQuestion}><Icon i="arrow-left" /></span>
						<span className="survey-nav-page">{active === 0 ? 1 : active}/{questions.length}</span>
						<span className={`survey-nav-next ${isNextActive ? 'active' : ''}`} onClick={this.nextQuestion}><Icon i="arrow-right" /></span>
					</div>
				</div>
			</section>
		)
	}
}

export default withTitle(Survey, 'Survey');
