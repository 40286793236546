import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import {
	Box,
	Loader,
	NoData,
	Icon,
	Tooltip,
	FraudGraph,
	Actions,
	PageTitle,
	Breadcrumbs,
	FilterBox,
	Button,
	Select,
	DatePicker,
	AppIcon,
} from 'interceptd-ui';

import RuleSetIcon from '../../components/RuleSet/RuleSetIcon';
import CampaignRuleIcon from '../../components/CampaignRule/CampaignRuleIcon';
import Table from '../../components/Table';
import Dropdown from '../../components/Dropdown';
import KPIPerformanceTable from '../../components/KPIPerformanceTable';
import CreativePerformanceTable from '../../components/CreativePerformanceTable';

import { openFreeModeWarning } from '../../components/FreeModeWarning';

import DetailSankey from './DetailSankey';
import DetailDailyChart from './DetailDailyChart';
import DailyImpressionsChart from './Source/DailyImpressionsChart';
import DetailSummary from './DetailSummary';
import DetailStatsBoxes from './components/DetailStatsBoxes';

import Api from '../../services/api';
import Local from '../../services/localStorage';
import Settings from '../../services/settings';
import SendEvent from '../../services/events';
import history from '../../services/history';

import { EmptyStatsObjects } from '../../constants/Campaign';

import { copyTextToClipboard } from '../../utils/file';
import { shadowText, getInitialDateFilter, isShadow } from '../../utils/misc';
import { getTimestamp } from '../../utils/transform';

import { columns as sourceColumns } from './DetailSourcesColumns';

import './styles/Detail.css';

import withTitle from '../withTitle';

class CampaignDetail extends React.Component {
	state = {
		me: Local.getItem('me'),

		redirectToReportPage: null,

		loading: true,
		campaigns: {},

		sourceImpressionMap: {},
		showImpression: false,

		campaignStatsFetching: false,
		campaignStats: {},

		campaignInstallAndEventFetching: false,
		campaignInstallAndEvent: {},

		campaignKPIStatsFetching: false,
		campaignKPIStats: {},
		campaignKPITable: [],

		ruleset: null,
		campaign_ruleset: null,

		amountSources: [],

		filters: {
			publisher: null,
			...getInitialDateFilter(),
		},

		campaignInfoOpen: false,

		selectedEventForSankey: null,

		appSettings: Settings.get('appSettings', 'account') || {},
		revenue_currency: 'USD',

		eventsColumnWidth: 250,
	}

	sankey = React.createRef()

	componentDidMount() {
		this.getCampaign();
		Local.listen('me', newValue => {
			this.setState({
				me: newValue,
			})
		})
	}

	getSourceImpressionCount = async () => {
		const { match } = this.props;
		const { campaign, filters } = this.state;

		const query = {
			source_ids: campaign.sources.map(s => s.id),
			campaign_id: match.params.id,
			ts_start: getTimestamp(filters.start),
			ts_end: getTimestamp(filters.end, true),
		};

		const impressionStatsResponse = await Api.getDailyImpressionStats(query);
		const impressionStats = impressionStatsResponse?.data?.data || [];

		const sourceImpressionMap = impressionStats.reduce((acc, cur) => {
			const rejected = cur.rejected_impression_count || 0;
			const flagged = cur.flagged_impression_count || 0;
			const clean = cur.impression_count || 0;
			const newAcc = { ...acc };
			newAcc[cur.source_id] = newAcc[cur.source_id] || {
				clean: 0,
				flagged: 0,
				rejected: 0,
				total: 0,
			};
			newAcc[cur.source_id].clean += clean;
			newAcc[cur.source_id].flagged += flagged;
			newAcc[cur.source_id].rejected += rejected;
			newAcc[cur.source_id].total += clean + flagged + rejected;

			newAcc.clean += clean;
			newAcc.flagged += flagged;
			newAcc.rejected += rejected;
			newAcc.total += clean + flagged + rejected;

			return newAcc;
		}, {
			clean: 0,
			flagged: 0,
			rejected: 0,
			total: 0,
		});

		this.setState({
			sourceImpressionMap,
			showImpression: sourceImpressionMap.total > 0,
		});
	}

	getCampaign = (loading = true) => {
		const { match } = this.props;

		if (loading)
			this.setState({ loading: true });
		Api.getLeoparCampaigns({
			id: match.params.id
		})
			.then(response => {
				const campaign = response.data.data.filter((c) => c.application_type !== 'leancheck').map(c => ({
					...c,
					sources: c.sources.map(s => ({
						...s,
						kpis: c.kpis
					}))
				}))[0];
				const eventNames = uniq(
					campaign.sources.flatMap(s => [
						...(s.kpis || []).map(kpi => kpi.event_name),
						...Object.keys(s.event_payouts || {})
					])
				);

				const counts = [campaign].map(c =>
					Math.max(...(c.sources.map(s =>
						Object.keys(s.event_payouts || {}).length + (s.kpis || []).length
					)))
				);

				const eventsColumnWidth = (Math.max(...counts) * 250) + 250

				this.setState(prevState => ({
					campaign,
					eventNames,
					eventsColumnWidth,
					loading: false,
					selectedEventForSankey: eventNames[0] || null,
					revenue_currency: prevState.appSettings?.[(campaign?.app_info?.id ?? 0)]?.revenue_currency ?? 'USD',
				}), () => {
					this.getRulesets();
					this.getAllStats();
				});
			})
			.catch(({ response }) => {
				toast.error(`Couldn't fetch campaign.`);
				this.setState({ loading: false });
			});
	}

	getRulesets = () => {
		const { campaign } = this.state;

		Api.getCampaignRuleSets(campaign.id)
			.then(response => {
				this.setState({
					rulesets: response.data.data.reduce((acc, cur) => {
						acc[cur.campaign_ruleset.campaign_id] = {};
						acc[cur.campaign_ruleset.campaign_id].ruleset = cur.campaign_ruleset.ruleset;
						if (cur.source_rulesets && cur.source_rulesets.length > 0) {
							cur.source_rulesets.forEach(source_ruleset => {
								acc[cur.campaign_ruleset.campaign_id][source_ruleset.source_id] = {}
								acc[cur.campaign_ruleset.campaign_id][source_ruleset.source_id].ruleset = source_ruleset.ruleset;
							})
						}
						return acc;
					}, {}),
				});
			})
			.catch(({ response }) => {
				SendEvent({
					description: `getCampaignRuleSets on campaign detail`,
					fatal: true
				});
			});

		Api.getCampaignCRuleset(campaign.id)
			.then(response => {
				this.setState({
					campRulesets: response.data.data.reduce((acc, cur) => {
						acc[cur.campaign_ruleset.campaign_id] = {};
						acc[cur.campaign_ruleset.campaign_id].ruleset = cur.campaign_ruleset.ruleset;
						if (cur.source_rulesets && cur.source_rulesets.length > 0) {
							cur.source_rulesets.forEach(source_ruleset => {
								acc[cur.campaign_ruleset.campaign_id][source_ruleset.source_id] = {}
								acc[cur.campaign_ruleset.campaign_id][source_ruleset.source_id].ruleset = source_ruleset.ruleset;
							})
						}
						return acc;
					}, {}),
				});
			})
			.catch(({ response }) => {
				SendEvent({
					description: `getCampaignCRuleset on campaign detail`,
					fatal: true
				});
			});
	}

	getAllStats = () => {
		const { campaign } = this.state;

		if (get(campaign, 'sources', []).length === 0) {
			return;
		}

		this.getSourceStats();
		this.getSourceInstallAndEvent();
		this.getSourceKPIStats();
		this.getSourceImpressionCount();
	}

	getSourceStats = () => {
		const { campaign, filters } = this.state;

		this.setState({
			campaignStatsFetching: true,
			campaignStats: {}
		})
		Api.getLeoparSourceStats({
			source_ids: campaign.sources.map(s => s.id).join(','),
			ts_start: getTimestamp(filters.start),
			ts_end: getTimestamp(filters.end, true),
		})
			.then(response => {
				const sourceStats = get(response, 'data.data', []);

				this.setState({
					campaignStatsFetching: false,
					campaignStats: sourceStats.reduce((acc, cur) => {
						const { campaign_id, source_id } = cur;
						acc[campaign_id] = acc[campaign_id] || {};
						acc[campaign_id][source_id] = acc[campaign_id][source_id] || {};
						acc[campaign_id][source_id].click = {
							total: +cur.click_count + +cur.rejected_click_count + +cur.flagged_click_count,
							rejected: +cur.rejected_click_count,
							flagged: +cur.flagged_click_count,
							clean: +cur.click_count,
						}

						acc[campaign_id].click = acc[campaign_id].click || {
							...EmptyStatsObjects.click
						};
						Object.keys(acc[campaign_id].click).forEach(key => {
							acc[campaign_id].click[key] += acc[campaign_id][source_id].click[key];
						})
						acc.click = acc.click || {
							...EmptyStatsObjects.click
						};
						Object.keys(acc.click).forEach(key => {
							acc.click[key] += acc[campaign_id][source_id].click[key];
						})

						return acc;
					}, {}),
				});
			})
			.catch(({ response }) => {
				this.setState({
					campaignStatsFetching: false,
					campaignStats: {}
				})
				SendEvent({
					description: `getLeoparSourceStats on campaign detail`,
					fatal: true
				});
			});
	}

	getSourceInstallAndEvent = () => {
		const { campaign, filters } = this.state;

		this.setState({
			campaignInstallAndEventFetching: true,
			campaignInstallAndEvent: {}
		})
		Api.getLeoparSourceInstallAndEvent({
			source_ids: campaign.sources.map(s => s.id).join(','),
			ts_start: getTimestamp(filters.start),
			ts_end: getTimestamp(filters.end, true),
		})
			.then(response => {
				const campaignInstallAndEvent = get(response, 'data.data', []);

				this.setState({
					campaignInstallAndEventFetching: false,
					campaignInstallAndEvent: campaignInstallAndEvent.reduce((acc, cur) => {
						const { campaign_id, source_id } = cur;
						acc[campaign_id] = acc[campaign_id] || {};
						acc[campaign_id][source_id] = acc[campaign_id][source_id] || {};

						// Install
						acc[campaign_id][source_id].install = {
							total: +cur.clean_install_count + +cur.flagged_install_count + +cur.rejected_install_count,
							rejected: +cur.rejected_install_count,
							flagged: +cur.flagged_install_count,
							clean: +cur.clean_install_count,
						}
						acc[campaign_id].install = acc[campaign_id].install || {
							...EmptyStatsObjects.install
						};
						Object.keys(acc[campaign_id].install).forEach(key => {
							acc[campaign_id].install[key] += acc[campaign_id][source_id].install[key];
						})
						acc.install = acc.install || {
							...EmptyStatsObjects.install
						};
						Object.keys(acc.install).forEach(key => {
							acc.install[key] += acc[campaign_id][source_id].install[key];
						})

						// Event
						acc[campaign_id][source_id].event = {
							total: +cur.clean_event_count + +cur.flagged_event_count + +cur.rejected_event_count,
							rejected: +cur.rejected_event_count,
							rejected_amount: +cur.rejected_amount,
							rejected_reattributed_amount: +cur.rejected_reattributed_amount,
							rejected_reattributed_event_count: +cur.rejected_reattributed_event_count,
							flagged: +cur.flagged_event_count,
							flagged_amount: +cur.flagged_amount,
							flagged_reattributed_amount: +cur.flagged_reattributed_amount,
							flagged_reattributed_event_count: +cur.flagged_reattributed_event_count,
							clean: +cur.clean_event_count,
							clean_amount: +cur.clean_amount,
							clean_reattributed_amount: +cur.clean_reattributed_amount,
							clean_reattributed_event_count: +cur.clean_reattributed_event_count,
							unique: +cur.unique_event_count,
						}
						acc[campaign_id].event = acc[campaign_id].event || {
							...EmptyStatsObjects.event
						};
						Object.keys(acc[campaign_id].event).forEach(key => {
							acc[campaign_id].event[key] += acc[campaign_id][source_id].event[key];
						})
						acc.event = acc.event || {
							...EmptyStatsObjects.event
						};
						Object.keys(acc.event).forEach(key => {
							acc.event[key] += acc[campaign_id][source_id].event[key];
						})

						return acc;
					}, {}),
				});
			})
			.catch(({ response }) => {
				this.setState({
					campaignInstallAndEventFetching: false,
					campaignInstallAndEvent: {}
				})
				SendEvent({
					description: `getSourceInstallAndEvent on campaign detail`,
					fatal: true
				});
			});
	}

	getSourceKPIStats = () => {
		const { campaign, filters, eventNames } = this.state;

		const params = campaign.sources.map(s => ({
			source_id: s.id,
			event_names: eventNames
		})).filter(p => p.event_names.length > 0);

		if (!params || params.length === 0 || campaign.type === 'CPM') {
			return this.setState({
				campaignKPIStatsFetching: false,
				campaignKPIStats: {},
				campaignKPITable: [],
			});
		}

		this.setState({
			campaignKPIStatsFetching: true,
			campaignKPIStats: {},
			campaignKPITable: [],
		});

		Api.getLeoparSourceKPIStats({
			params,
			ts_start: getTimestamp(filters.start),
			ts_end: getTimestamp(filters.end, true),
		})
			.then(response => {
				const { data } = response.data;
				this.setState({
					campaignKPIStatsFetching: false,
					campaignKPIStats: data.reduce((acc, cur) => {
						const { campaign_id, source_id, event_name } = cur;

						acc[campaign_id] = acc[campaign_id] || {};
						acc[campaign_id][source_id] = acc[campaign_id][source_id] || {};
						acc[campaign_id][source_id][event_name] = acc[campaign_id][source_id][event_name] || {};

						acc[campaign_id][source_id][event_name] = {
							total: +cur.clean_event_count + +cur.flagged_event_count + +cur.rejected_event_count,
							rejected: +cur.rejected_event_count,
							rejected_amount: +cur.rejected_amount,
							rejected_reattributed_amount: +cur.rejected_reattributed_amount,
							rejected_reattributed_event_count: +cur.rejected_reattributed_event_count,
							flagged: +cur.flagged_event_count,
							flagged_amount: +cur.flagged_amount,
							flagged_reattributed_amount: +cur.flagged_reattributed_amount,
							flagged_reattributed_event_count: +cur.flagged_reattributed_event_count,
							clean: +cur.clean_event_count,
							clean_amount: +cur.clean_amount,
							clean_reattributed_amount: +cur.clean_reattributed_amount,
							clean_reattributed_event_count: +cur.clean_reattributed_event_count,
							unique: +(cur.unique_event_count || 0),
						}

						acc[campaign_id][event_name] = acc[campaign_id][event_name] || {
							total: 0,
							rejected: 0,
							rejected_amount: 0,
							rejected_reattributed_amount: 0,
							rejected_reattributed_event_count: 0,
							flagged: 0,
							flagged_amount: 0,
							flagged_reattributed_amount: 0,
							flagged_reattributed_event_count: 0,
							clean: 0,
							clean_amount: 0,
							clean_reattributed_amount: 0,
							clean_reattributed_event_count: 0,
							unique: 0,
						};
						Object.keys(acc[campaign_id][event_name]).forEach(key => {
							acc[campaign_id][event_name][key] += acc[campaign_id][source_id][event_name][key];
						})

						return acc;
					}, {}),
					campaignKPITable: eventNames.map(event_name => {
						const stats = data.filter(d => d.event_name === event_name);
						const fields = {
							total: 0,
							clean: 0,
							flagged: 0,
							rejected: 0,
						}
						const reducer = (acc, cur, suffix = '') => {
							const newAcc = { ...acc };
							Object.keys(fields).forEach((key) => {
								if (key !== 'total') {
									newAcc[key] += cur[`${key}${suffix}`];
								}
							})
							newAcc.total = Object.keys(newAcc).filter(k => k !== 'total').reduce((a, c) => a + newAcc[c], 0);
							return newAcc;
						}
						const sources = campaign.sources.map(source => {
							const sourceStats = stats.filter(d => d.source_id === source.id);
							return ({
								sourceName: source.name,
								count: sourceStats.reduce((acc, cur) => reducer(acc, cur, '_event_count'), fields),
								amount: sourceStats.reduce((acc, cur) => reducer(acc, cur, '_amount'), fields),
								rCount: sourceStats.reduce((acc, cur) => reducer(acc, cur, '_reattributed_event_count'), fields),
								rAmount: sourceStats.reduce((acc, cur) => reducer(acc, cur, '_reattributed_amount'), fields),
							})
						})
						return ({
							eventName: event_name,
							count: stats.reduce((acc, cur) => reducer(acc, cur, '_event_count', true), fields),
							amount: stats.reduce((acc, cur) => reducer(acc, cur, '_amount'), fields),
							rCount: stats.reduce((acc, cur) => reducer(acc, cur, '_reattributed_event_count'), fields),
							rAmount: stats.reduce((acc, cur) => reducer(acc, cur, '_reattributed_amount'), fields),
							sources,
						})
					})
				});
			})
			.catch(({ response }) => {
				this.setState({
					campaignKPIStatsFetching: false,
					campaignKPIStats: {},
					campaignKPITable: [],
				})
				SendEvent({
					description: `getSourceInstallAndEvent on campaign detail`,
					fatal: true
				});
			});
	}

	handleDateRange = ({ from, to }) => {
		this.setState(prevState => ({
			filters: {
				...prevState.filters,
				start: from,
				end: to,
			}
		}), this.getAllStats);

		Local.setItem('date_filter', { 
			from,
			to,
		});
	}

	pauseSource = props => {
		const { original } = props;

		Api.blockSource(original.campaign, original.id)
			.then(res => {
				this.getCampaign()
			})
			.catch(({ response }) => {
				SendEvent({
					description: `pauseSource for ${original.id} on campaign detail`,
					fatal: false
				});
				toast.error(`Couldn't pause the source`);
			})
	}

	startSource = props => {
		const { original } = props;

		Api.unblockSource(original.campaign, original.id)
			.then(res => {
				this.getCampaign()
			})
			.catch(({ response }) => {
				SendEvent({
					description: `startSource for ${original.id} on campaign detail`,
					fatal: false
				});
				toast.error(`Couldn't pause the source`);
			})
	}

	copyUrl = (props, name) => {
		const { original } = props;
		copyTextToClipboard(original[name]);
		toast.success('URL copied');
	}

	activateBot = props => {
		const { original } = props;

		Api.activateCheckBot(original.campaign, original.id)
			.then(res => {
				this.getCampaign()
			})
			.catch(({ response }) => {
				SendEvent({
					description: `activateBot for ${original.id} on campaign detail`,
					fatal: false
				});
				toast.error(`Couldn't activate bot prevention for the source`);
			})
	}

	disableBot = props => {
		const { original } = props;

		Api.deactivateCheckBot(original.campaign, original.id)
			.then(res => {
				this.getCampaign()
			})
			.catch(({ response }) => {
				SendEvent({
					description: `disableBot for ${original.id} on campaign detail`,
					fatal: false
				});
				toast.error(`Couldn't disable bot prevention for the source`);
			})
	}

	renderActions = props => {
		const { match } = this.props;
		const { me, campaign } = this.state;
		const { original } = props;

		return Local.getItem('me')?.plans?.interceptd !== 'free' ? (
			<Actions>
				<Actions.Item onClick={() => history.push(`${match.url}/edit/sources?source=${original.id}`, { back: match.url })} data-event-category="Campaign Detail" data-event-action="Edit source action clicked">Edit source</Actions.Item>
				{campaign.type !== 'CPM' && (original.status !== 1 ? (
					<Actions.Item onClick={() => this.startSource(props)} data-event-category="Campaign Detail" data-event-action="Start source action clicked">Start this source</Actions.Item>
				) : (
						<Actions.Item onClick={() => this.pauseSource(props)} data-event-category="Campaign Detail" data-event-action="Pause source action clicked">Pause this source</Actions.Item>
					))}
				<Actions.Item className={me.plans?.interceptd === 'free' ? 'bg-red' : ''} onClick={() => me.plans?.interceptd === 'free' ? openFreeModeWarning() : this.copyUrl(props, 'tracking_url')} data-event-category="Campaign Detail" data-event-action="Copy source tracking URL action clicked">
					<span style={{ marginRight: 10 }}>Copy source tracking URL</span>
					<Tooltip position="left" tooltip="Please share this link with the relevant ad network. Make sure that they use only this link and not the (original) MMP links.">
						<Icon size="14" i="info" />
					</Tooltip>
				</Actions.Item>
				{/* {campaign.type !== 'CPM' && (
					<Actions.Item onClick={() => original.check_bot ? this.disableBot(props) : this.activateBot(props)} data-event-category="Campaign Detail" data-event-action={`${original.check_bot ? 'Disable' : 'Activate'} bot prevention action clicked`}>{`${original.check_bot ? 'Disable' : 'Activate'} bot prevention`}</Actions.Item>
				)} */}
				{campaign.type !== 'CPM' && <Actions.Item onClick={() => history.push(`${match.url}/edit/sources?source=${original.id}&field=selected_events`, { back: match.url })} data-event-category="Campaign Detail" data-event-action="Event postback settings action clicked">Event postback settings</Actions.Item>}
				<Actions.Item onClick={() => this.setState({ goToSubpublishersId: original.id })} data-event-category="Source Detail" data-event-action="Go to subpublisher button clickerd">Go to Subpublisher List</Actions.Item>
			</Actions>
		) : null
	}

	renderStatsCell = ({ original, column }) => {
		const {
			campaignStatsFetching,
			campaignInstallAndEventFetching,
			campaignKPIStatsFetching,
			sourceImpressionMap,
		} = this.state;

		if (column.id === 'impression') {
			return (
				<div className="campaign-table-fraud-bar-container">
					<FraudGraph
						title="Impression"
						data={{
							clean: sourceImpressionMap?.[original.id]?.clean || 0,
							rejected: sourceImpressionMap?.[original.id]?.rejected || 0,
							flagged: sourceImpressionMap?.[original.id]?.flagged || 0,
							total: sourceImpressionMap?.[original.id]?.total || 0,
						}}
					/>
				</div>
			);
		}

		const isClickCell = column.id === 'click';
		const isEventCell = column.id === 'event';

		if (!isEventCell) {
			const data = this.getCellStats({ original, column });
			return isClickCell ? (
				<div className="campaign-table-fraud-bar-container">
					<FraudGraph
						data={data}
						showPercents
						title="Clicks"
						loading={campaignStatsFetching} />
				</div>
			) : (
					<div className="campaign-table-fraud-bar-container">
						<FraudGraph
							data={data}
							showPercents
							title="Installs"
							loading={campaignInstallAndEventFetching} />
					</div>
				)
		} else {
			const totalData = this.getCellStats({ original, column });
			const kpis = (original?.kpis || []).map(kpi => kpi.event_name);
			const payouts = [
				...Object.keys(original.event_payouts || {}),
				...(original.sources ? original.sources.flatMap(s =>
					Object.keys(s.event_payouts || {})
				) : [])
			]
			const events = uniq([...kpis, ...payouts]).map(event => this.getCellStats({ original, column: { id: 'kpi', event_name: event } }))

			return (
				<div className="campaign-table-fraud-bar-container">
					<FraudGraph
						data={totalData}
						showPercents
						showUnique
						title="Events"
						loading={campaignInstallAndEventFetching} />
					{events.map((event, index) => (
						<FraudGraph
							key={`payout-${index}`}
							data={event}
							showPercents
							showUnique
							title={event.name}
							loading={campaignKPIStatsFetching} />
					))}
				</div>
			)
		}
	}

	renderFraudGraphCell = ({ original, column, value }) => {
		const {
			campaignStatsFetching, campaignStats,
			campaignInstallAndEventFetching, campaignInstallAndEvent,
			campaignKPIStatsFetching, campaignKPIStats,
			sourceImpressionMap,
		} = this.state;

		const isSource = !!original.campaign;
		const isClickCell = column.id === 'click';
		const isEventCell = column.id === 'event';
		const isImpressionCol = column.id === 'impression';
		const isKPICell = column.id.indexOf('kpi') >= 0;
		const isKPITable = column.id.indexOf('kpi_table') >= 0;
		let data = {};
		let kpi = null;
		const dataSource =
			isClickCell ? campaignStats :
				isKPICell ? campaignKPIStats :
					campaignInstallAndEvent;

		if (isImpressionCol) {
			data = {
				clean: sourceImpressionMap?.[original.id]?.clean || 0,
				total: sourceImpressionMap?.[original.id]?.total || 0,
				flagged: sourceImpressionMap?.[original.id]?.flagged || 0,
				rejected: sourceImpressionMap?.[original.id]?.rejected || 0
			};
		} else if (isSource) {
			if (isKPITable) {
				data = value || EmptyStatsObjects.event;
			} else if (isKPICell) {
				kpi = original.kpis.find(({ kpi }) => +kpi === +(column.id.replace('kpi', '')));
				data = kpi ? {
					...(get(dataSource, `[${original.campaign}][${original.id}][${kpi.event_name}]`, EmptyStatsObjects.event)),
					name: kpi.event_name
				} : EmptyStatsObjects.event;
			} else {
				data = get(dataSource, `[${original.campaign}][${original.id}][${column.id}]`, EmptyStatsObjects[column.id]);
			}
		} else {
			if (isKPITable) {
				data = value || EmptyStatsObjects.event;
			} else if (isKPICell) {
				kpi = original.kpis.find(({ kpi }) => +kpi === +(column.id.replace('kpi', '')));
				data = kpi ? {
					...(get(dataSource, `[${original.id}][${kpi.event_name}]`, EmptyStatsObjects.event)),
					name: kpi.event_name
				} : EmptyStatsObjects.event;
			} else {
				data = get(dataSource, `[${original.id}][${column.id}]`, EmptyStatsObjects[column.id]);
			}
		}

		return (
			<div className="campaign-table-fraud-bar-container">
				<FraudGraph
					data={data}
					showUnique={!isKPITable && (isEventCell || isKPICell)}
					title={column.Header}
					loading={(isClickCell && campaignStatsFetching) || ((isKPITable || isKPICell) && campaignKPIStatsFetching) || (campaignInstallAndEventFetching)} />
			</div>
		);
	}

	toggleCampaignInfo = event => {
		this.setState(prevState => ({
			campaignInfoOpen: !prevState.campaignInfoOpen,
		}))
	}

	renderRulesCell = ({ original, className = '' }) => {
		const { rulesets, campRulesets } = this.state;
		const fraudRuleset = get(rulesets, `[${original.id}].ruleset`);;
		const campRuleset = get(campRulesets, `[${original.id}].ruleset`);

		return (
			<div className={`rules-column ${className}`}>
				<RuleSetIcon
					campaign={original}
					alarms={original.alarms.filter(a => a.alarm_type.indexOf('EIR') === -1)}
					ruleset={fraudRuleset}
					onReload={this.getRulesets} />
				<CampaignRuleIcon
					campaign={original}
					alarms={original.alarms.filter(a => a.alarm_type.indexOf('EIR') !== -1)}
					ruleset={campRuleset}
					onReload={this.getRulesets} />
			</div>
		)
	}

	getTotalStats = (original, id) => {
		const data = this.getCellStats({ original, column: { id } });
		return data.total ? +data.total : 0;
	}

	getCellStats = ({ original, column }) => {
		const {
			campaignStats,
			campaignInstallAndEvent,
			campaignKPIStats,
		} = this.state;

		const isClickCell = column.id === 'click';
		const isKPICell = column.id === 'kpi';
		let data = {};
		const dataSource =
			isClickCell ? campaignStats :
				isKPICell ? campaignKPIStats :
					campaignInstallAndEvent;

		if (isKPICell) {
			data = {
				...(get(dataSource, `[${original.campaign}][${original.id}][${column.event_name}]`, EmptyStatsObjects.event)),
				name: column.event_name
			};
		} else {
			data = get(dataSource, `[${original.campaign}][${original.id}][${column.id}]`, EmptyStatsObjects[column.id]);
		}

		return data;
	}

	render() {
		const { match } = this.props;
		const {
			loading,
			campaign,
			campaignStatsFetching, campaignStats,
			campaignInstallAndEventFetching, campaignInstallAndEvent,
			campaignKPIStatsFetching, campaignKPITable,
			filters,
			goToSubpublishersId,
			campaignInfoOpen,
			redirectToReportPage,
			eventNames,
			selectedEventForSankey,
			revenue_currency,
			eventsColumnWidth,
			sourceImpressionMap,
			showImpression,
		} = this.state;

		if (goToSubpublishersId) {
			return <Redirect push to={{
				pathname: `/campaign/${match.params.id}/subpublishers`,
				state: { sourceId: goToSubpublishersId },
			}} />
		}

		if (redirectToReportPage) {
			return <Redirect push to={{
				pathname: '/reports/create',
				state: {
					selectedReportType: redirectToReportPage,
					selectedSources: campaign ? campaign.sources.map(s => s.id) : [],
				},
			}} />
		}

		if (loading) return <div className="campaign-detail"><Loader /></div>;

		if (!campaign) {
			return <Redirect to="/404-not-found" />
		}

		return (
			<div className="campaign-detail">
				<PageTitle>
					<Breadcrumbs>
						<Breadcrumbs.Crumb><Link to="/">Campaigns</Link></Breadcrumbs.Crumb>
						<Breadcrumbs.Crumb><Link to={match.url}>Campaign Detail</Link></Breadcrumbs.Crumb>
					</Breadcrumbs>
					<PageTitle.Title>
						Campaign Detail - <AppIcon isShadow={isShadow()} app={campaign?.app_name ? campaign?.app_info : undefined} /> {shadowText(campaign.name, 'Example Campaign')}
					</PageTitle.Title>
				</PageTitle>

				<FilterBox>
					<div className="filter-column filter-column-left">
						<Button className="button-icon" bgColor="transparent" onClick={this.toggleCampaignInfo}>
							{campaignInfoOpen && <Icon i="x-circle" />}
							{campaignInfoOpen ? 'Close Info' : 'Info'}
						</Button>
						<Button component={Link} className="button-icon" bgColor="transparent" to={`${match.url}/subpublishers`}>Subpublishers</Button>
						{this.renderRulesCell({
							original: campaign,
							className: 'campaign-detail-filter-rules'
						})}
					</div>

					<div className="filter-column filter-column-right">
						<DatePicker
							value={{
								from: filters.start,
								to: filters.end,
							}}
							onChange={this.handleDateRange} />
					</div>
				</FilterBox>

				<DetailSummary
					isInitialCreate={(this?.props?.location?.search || '').indexOf('initialCreate') > -1}
					url={match.url}
					data={campaign}
					open={campaignInfoOpen} />

				<DetailStatsBoxes
					loading={campaignStatsFetching || campaignInstallAndEventFetching}
					data={campaignStatsFetching || campaignInstallAndEventFetching ? {} : {
						click: campaignStats.click,
						install: campaignInstallAndEvent.install,
						event: campaignInstallAndEvent.event,
						impressions: sourceImpressionMap,
					}}
					showImpressions={showImpression} />

				<Box
					className="box-table-wrapper campaign-detail-sources-box"
					title="Campaign Sources"
					right={
						<React.Fragment>
							<Dropdown buttonText="Create Report">
								<Dropdown.Item onClick={() => this.setState({ redirectToReportPage: 'click' })}>Click</Dropdown.Item>
								<Dropdown.Item onClick={() => this.setState({ redirectToReportPage: 'install' })}>Install</Dropdown.Item>
								<Dropdown.Item onClick={() => this.setState({ redirectToReportPage: 'event' })}>Event</Dropdown.Item>
							</Dropdown>
							{Local.getItem('me')?.plans?.interceptd !== 'free' &&
								<Button mini bgColor="transparent" className="add-new-campaign-button" onClick={() => history.push(`${match.url}/edit/sources?mode=add`, { back: match.url })}>
									<Icon i="plus" /> Add Source
								</Button>
							}
						</React.Fragment>
					}
					>
					<div className="campaign-sources">
						{!loading && campaign.sources.length > 0 ? (
							<Table
								data={campaign.sources}
								columns={
									sourceColumns({
										match,
										eventsColumnWidth,
										renderActions: this.renderActions,
										renderFraudGraphCell: this.renderStatsCell,
										type: campaign.type,
										showImpression,
									})
								}
								PadRowComponent={() => null}
								sortable={false}
								showPageSizeOptions={false}
								showPageJump={false}
								showPagination={false}
								pageSize={campaign.sources.length}
								NoDataComponent={() => <NoData icon="box">You don't have any source yet</NoData>}
								getTrProps={(state, rowInfo, column) => {
									return rowInfo && rowInfo.original && rowInfo.original.status !== 1 ? ({
										style: {
											filter: 'grayscale(1)',
											textDecoration: 'line-through'
										}
									}) : ({});
								}} />
						) : !loading && campaign.sources.length === 0 ? (
							<NoData icon="box">You don't have any source yet</NoData>
						) : (
									<Loader />
								)}
					</div>
				</Box>

				{campaign.type !== 'CPM' && (
					<KPIPerformanceTable
						campaign={campaign}
						getCampaign={this.getCampaign}
						data={campaignKPITable}
						loading={campaignKPIStatsFetching}
						revenueCurrency={revenue_currency}
					/>
				)}

				<CreativePerformanceTable
					sources={campaign.sources}
					filters={filters}
					revenueCurrency={revenue_currency}
				/>

				<div className="campaign-graphs">
					{campaign.type !== 'CPM' && <div className="campaign-graph">
						<Box title="Publisher Fraud Distribution">
							<DetailSankey
								filters={filters}
								campaign={campaign}
								sourceNames={(!loading && campaign.sources) ? campaign.sources.reduce((acc, cur) => {
									acc[cur.id] = cur.name;
									return acc;
								}, {}) : {}}
							/>
						</Box>
					</div>}

					{campaign.type === 'CPM' && <div className="campaign-graph">
						<Box title="Publisher Fraud Distribution For Impression">
							<DetailSankey
								filters={filters}
								campaign={campaign}
								impression
								sourceNames={(!loading && campaign.sources) ? campaign.sources.reduce((acc, cur) => {
									acc[cur.id] = cur.name;
									return acc;
								}, {}) : {}}
							/>
						</Box>
					</div>}

					{eventNames.length > 0 &&
						<div className="campaign-graph">
							<Box
								title={`Publisher Fraud Distribution for ${selectedEventForSankey} Event`}
								right={
									<Select
										mini
										placeholder="Event"
										value={[selectedEventForSankey]}
										options={eventNames.map(event => ({
											label: event,
											value: event,
										}))}
										onChange={([v]) => {
											this.setState({ selectedEventForSankey: v })
										}}
										getTetherProps={props => ({
											...props,
											attachment: 'top right',
											targetAttachment: 'bottom right',
										})} />
								}>
								<DetailSankey
									filters={filters}
									campaign={campaign}
									sourceNames={(!loading && campaign.sources) ? campaign.sources.reduce((acc, cur) => {
										acc[cur.id] = cur.name;
										return acc;
									}, {}) : {}}
									eventName={selectedEventForSankey}
								/>
							</Box>
						</div>
					}

					<div className="campaign-graph">
						<Box title="Daily Performance">
							<DetailDailyChart
								filters={filters}
								campaign={campaign} />
						</Box>
					</div>

					{campaign.type === 'CPM' && <div className="campaign-graph">
						<Box title="Daily Impressions">
							<DailyImpressionsChart
								source={campaign.sources}
								filters={filters}
							/>
						</Box>
					</div>}
				</div>
			</div>
		)
	}
}

export default withTitle(CampaignDetail, 'Campaign Detail', 'id');
