import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Button } from 'interceptd-ui';

import './styles/FreeToolCard.css';

const FreeToolCard = ({ icon, link, title, children }) => {

	return (
		<div className="free-tool-card">
			<div className="card-title"><Icon i={icon} size={14} /> <span>{title}</span></div>
			<div className="content">{children}</div>
			<div className="card-footer">
				<Button mini to={link} component={Link}>Get Started</Button>
			</div>
		</div>
	);
}

export default FreeToolCard;