import React from 'react';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import {
	Icon,
	Button,
	Select,
} from 'interceptd-ui';

import { MultiCampaignFieldMap } from '../../constants/Campaign';

import { MultiCampContext } from './context';

import './styles/Match.css';

const MatchColumn = props => {
	return (
		<div className={`match-column ${props.matched ? 'matched' : ''}`}>
			<div className="match-column-header">
				<Select
					label={props.matched ? `Matched Column` : `Column Name`}
					placeholder="Select Field"
					options={Object.keys(MultiCampaignFieldMap).map(field => ({
						value: field,
						label: MultiCampaignFieldMap[field]
					}))}
					value={[props.matched]}
					onChange={([v]) => props.onSelect(v, props.name)} />
			</div>
			<div className="match-column-name">
				{props.name}
			</div>
			<ScrollSyncPane>
				<div className="match-column-content">
					{props.children.map((child, index) => (
						<div key={index} className="match-column-content-row">
							{child}
						</div>
					))}
				</div>
			</ScrollSyncPane>
			<div className="match-column-footer">
				<div className="match-column-count">{props.children.length} Row{props.children.length > 1 ? 's' : ''}</div>
				<div className="match-column-actions">
					<span className="match-column-action" onClick={() => props.onRemove(props.name)}>Don't Import</span>
				</div>
			</div>
		</div>
	)
}

class Match extends React.Component {
	state = {
		headerMapping: {},
	}

	componentDidMount = () => {
		const { meta } = this.context.parsedResults;
		if ( Object.keys(this.context.headerMapping).length > 0 ) {
			this.setState({
				headerMapping: this.context.headerMapping
			})
		} else if ( meta ) {
			this.setState({
				headerMapping: meta.fields.reduce((acc, curr) => {
					const found = Object.keys(MultiCampaignFieldMap).find(k => MultiCampaignFieldMap[k].toLowerCase().trim().indexOf(curr.toLowerCase().trim()) !== -1)
					const matched = Object.keys(acc).find(f => acc[f] === found)
					if ( found && !matched )
						acc[curr] = found;
					return acc;
				}, {})
			})
		}
	}

	handleSelect = (value, name) => {
		this.setState(prevState => ({
			headerMapping: {
				...prevState.headerMapping,
				[name]: value,
			}
		}))
	}

	handleRemove = field => {
		this.setState(prevState => {
			const headerMapping = { ...prevState.headerMapping };
			delete headerMapping[field]
			return ({
				headerMapping,
			})
		})
	}

	checkRequiredFields = () => {
		const { headerMapping } = this.state;

		const maps = Object.values(headerMapping);

		return maps.indexOf('name') !== -1 &&
					 maps.indexOf('app') !== -1 &&
					 maps.indexOf('tracking_url') !== -1;
	}

	handleNext = () => {
		this.context.setState({
			step: 'create',
			headerMapping: this.state.headerMapping,
		})
	}

	render() {
		const { parsedResults } = this.context;
		const { headerMapping } = this.state;

		const maps = Object.values(headerMapping);

		return (
			<div className="multi-campaign-match">
				<ScrollSync>
					<div className="match-map">
						{ parsedResults.meta ? (
							parsedResults.meta.fields.map(field => (
								<MatchColumn
									key={field}
									name={field}
									matched={headerMapping[field]}
									onSelect={this.handleSelect}
									onRemove={this.handleRemove}>
									{parsedResults.data.map(col => col[field])}
								</MatchColumn>
							))
						) : (
							<div className="match-map-noop">No</div>
						) }
					</div>
				</ScrollSync>
				<div className="match-footer">
					{!this.checkRequiredFields() &&
						<div className="match-errors">
							<span><Icon i="info" size={17} /> Campaigns are not ready</span>
							{maps.indexOf('app') === -1 &&
								<div className="match-error"><b>Store URL</b> did not matched</div>
							}
							{maps.indexOf('name') === -1 &&
								<div className="match-error"><b>Campaign Name</b> did not matched</div>
							}
							{maps.indexOf('tracking_url') === -1 &&
								<div className="match-error"><b>Tracking URL</b> did not matched</div>
							}
						</div>
					}
					<Button onClick={this.handleNext} disabled={!this.checkRequiredFields()}>Create {parsedResults.data.length} Campaign{parsedResults.data.length > 1 ? 's' : ''}</Button>
				</div>
			</div>
		)
	}
}

Match.contextType = MultiCampContext;

export default Match;
