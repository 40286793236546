import React from 'react';
import moment from 'moment';
import axios from 'axios';
import Humanize from 'humanize-plus';
import ClickOutside from 'react-click-outside';
import values from 'lodash/values';
import sortBy from 'lodash/sortBy';
import { Icon } from 'interceptd-ui';

import Detail from './Detail';

import Api from '../../services/api';
import Settings from '../../services/settings';

import { enumerateDaysBetweenDates, getTimestamp } from '../../utils/transform';
import { getInitialDateFilter } from '../../utils/misc';

import './styles/Savings.css';

const StatsMap = {
	campaign_id: 0,
	clean_amount: 0,
	clean_click_count: 0,
	clean_event_count: 0,
	clean_install_count: 0,
	clean_reattributed_amount: 0,
	clean_reattributed_event_count: 0,
	date: 0,
	flagged_amount: 0,
	flagged_event_count: 0,
	flagged_install_count: 0,
	flagged_reattributed_amount: 0,
	flagged_reattributed_event_count: 0,
	rejected_amount: 0,
	rejected_click_count: 0,
	rejected_event_count: 0,
	rejected_install_count: 0,
	rejected_reattributed_amount: 0,
	rejected_reattributed_event_count: 0,
	flagged_cost: 0,
	source_id: 0,
}

class Savings extends React.PureComponent {
	state = {
		error: false,
		open: false,

		filters: getInitialDateFilter(),
		ts_label: 'Last 7 Days',

		archivedCampaigns: Settings.get('archivedCampaigns', 'account') || [],

		fetchingCampaigns: true,
		campaigns: [],
		partners: [],

		fetchingStats: true,
		refetchingStats: true,
		totalStats: [],
		totalSavings: [],
		totalSaving: 0,
		dailySavings: [],
	}

	componentDidMount = () => {
		this.fetchCampaigns()
	}

	fetchCampaigns = () => {
		const { archivedCampaigns } = this.state;

		this.setState({ fetchingCampaigns: true });

		axios.all([
			Api.getLeoparCampaigns(),
			Api.getPartners({ page: 1, size: 1000 }),
		])
			.then(axios.spread((campaignsRes, partnersRes) => {
				this.setState({
					error: false,
					fetchingCampaigns: false,
					campaigns: sortBy(campaignsRes.data.data.filter((c) => c.application_type !== 'leancheck').filter(c => archivedCampaigns.indexOf(c.id) === -1).map(c => ({ ...c, sources: sortBy(c.sources.map(s => ({ ...s, campaign: c })), 'id').reverse() })), 'id').reverse(),
					partners: partnersRes.data.data,
				}, this.fetchStats)
			})).catch(() => {
				this.setState({
					error: true,
					fetchingCampaigns: false,
				})
			});
	}

	getSourceImpressionCount = async () => {
		const { match } = this.props;
		const { campaign, filters } = this.state;

		const impressionStats = await Api.getDailyImpressionStats({
			source_ids: campaign.sources.map(s => s.id),
			campaign_id: match.params.id,
			ts_start: getTimestamp(filters.start),
			ts_end: getTimestamp(filters.end, true),
		})?.data?.data || []

		const sourceImpressionMap = impressionStats.reduce((acc, curr) => {
			acc[curr.source_id] = acc[curr.source_id] || 0;
			acc[curr.source_id] += curr.impression_count || 0;
			acc.total += curr.impression_count || 0;
			return acc;
		}, { total: 0 });

		this.setState({ sourceImpressionMap });
	}

	fetchStats = () => {
		const { filters, campaigns, partners } = this.state;

		this.setState({
			refetchingStats: true,
		});

		const sources = campaigns.flatMap(c => c.sources.map(s => ({
			...s,
			partner: partners.find(partner => partner.id === s.partner) || s.partner,
			app: c.app_info,
		})));

		if (sources.length === 0) {
			return 	this.setState({
				refetchingStats: false,
				fetchingStats: false,
			});
		}

		const query = {
			source_ids: sources.map(s => s.id),
			ts_start: getTimestamp(filters.start),
			ts_end: getTimestamp(filters.end, true),
		};

		axios.all([
			Api.getLeoparInstallAndEventDailyStats(query),
			Api.getLeoparClickDailyStats(query),
			Api.getEventDailyPerformance(query),
			Api.getDailyImpressionStats(query),
		])
			.then(axios.spread((installRes, clickRes, eventRes, impressionCount) => {
				const data = [
					...installRes.data.data,
					...clickRes.data.data,
					...eventRes.data.data,
					...impressionCount.data.data,
				];

				const totalStats = values(data.reduce((acc, cur) => {
					const newAcc = { ...acc };

					const key = `${cur.date}-${cur.source_id}-${cur.event_name || cur.creative_id || ''}`;

					newAcc[key] = {
						...(newAcc[key] || StatsMap),
						...cur,
					}

					return newAcc;
				}, {}));

				const totalSavings = sources.map(source => {
					const stats = totalStats.filter(stat => stat.source_id === source.id);
					let click_savings = 0,
							install_savings = 0,
							event_savings = 0,
							impression_savings = 0;

					if ( source.campaign.type === 'CPA' ) {
						const event_payouts = source.event_payouts || {};

						click_savings = stats.reduce((acc, cur) => {
							if ( !cur.event_name || !event_payouts[cur.event_name] || !cur.clean_click_count )
								return acc;
							return (acc + ((+cur.rejected_click_count * ( +cur.clean_event_count + +cur.rejected_event_count + +cur.flagged_event_count ) / +cur.clean_click_count) * +event_payouts[cur.event_name]))
						}, click_savings)

						install_savings = stats.reduce((acc, cur) => {
							if ( !cur.event_name || !event_payouts[cur.event_name] || !cur.flagged_install_count || !cur.clean_install_count )
								return acc;
							return (acc + ((+cur.rejected_install_count * ( +cur.clean_event_count + +cur.rejected_event_count + +cur.flagged_event_count ) / (+cur.clean_install_count + +cur.flagged_install_count)) * +event_payouts[cur.event_name]))
						}, install_savings)

						event_savings = stats.reduce((acc, cur) => {
							if ( !cur.event_name || !event_payouts[cur.event_name] )
								return acc;
							return (acc + (+cur.rejected_event_count * +event_payouts[cur.event_name]))
						}, event_savings)
					} else if (source.campaign.type === 'CPM') {
						impression_savings = stats.reduce((acc, cur) => {
							if ( !cur.creative_id || !cur.flagged_cost )
								return acc;
							return (acc + (cur.flagged_cost === 0 ? 0 : cur.flagged_cost / 1000));
						}, 0)
					} else {
						click_savings = stats.reduce((acc, cur) => {
							if ( !cur.clean_click_count )
								return acc;
							return (acc + ((+cur.rejected_click_count * ( +cur.clean_install_count + +cur.rejected_install_count + +cur.flagged_install_count ) / +cur.clean_click_count) * +source.bid))
						}, click_savings)

						install_savings = stats.reduce((acc, cur) => {
							return (acc + (+cur.rejected_install_count * +source.bid))
						}, install_savings)
					}

					return ({
						source: source,
						click_savings,
						install_savings,
						event_savings,
						impression_savings,
					})
				})

				const dailySavings = enumerateDaysBetweenDates(moment(filters.start, 'X'), moment(filters.end, 'X'), 'X', 0).map(d => ({
					x: d,
					y: 0
				})).map(date => {
					const stats = totalStats.filter(stat => +stat.date === +date.x);

					let click_savings = 0,
							install_savings = 0,
							event_savings = 0,
							impression_savings = 0;

					stats.forEach(stat => {
						const source = sources.find(source => +source.id === +stat.source_id);

						if ( source.campaign.type === 'CPA' ) {
							const event_payouts = source.event_payouts || {};

							click_savings += (
								( !stat.event_name || !event_payouts[stat.event_name] || !stat.clean_click_count ) ? 0 : 
								((+stat.rejected_click_count * ( +stat.clean_event_count + +stat.rejected_event_count + +stat.flagged_event_count ) / +stat.clean_click_count) * +event_payouts[stat.event_name])
							);

							install_savings += (
								( !stat.event_name || !event_payouts[stat.event_name] || !stat.flagged_install_count || !stat.clean_install_count ) ? 0 :
								((+stat.rejected_install_count * ( +stat.clean_event_count + +stat.rejected_event_count + +stat.flagged_event_count ) / (+stat.clean_install_count + +stat.flagged_install_count)) * +event_payouts[stat.event_name])
							)

							event_savings += (
								( !stat.event_name || !event_payouts[stat.event_name] ) ? 0 :
								(+stat.rejected_event_count * +event_payouts[stat.event_name])
							)
						} else if ( source.campaign.type === 'CPM' ) {
							impression_savings += (stat.flagged_cost === 0 ? 0 : stat.flagged_cost / 1000);
						} else {
							click_savings += (
								( !stat.clean_click_count ) ? 0 :
								((+stat.rejected_click_count * ( +stat.clean_install_count + +stat.rejected_install_count + +stat.flagged_install_count ) / +stat.clean_click_count) * +source.bid)
							)

							install_savings += (
								(+stat.rejected_install_count * +source.bid)
							)
						}
					})

					return ({
						x: +date.x * 1000,
						y: (click_savings + install_savings + event_savings + impression_savings)
					})
				})

				this.setState({
					error: false,
					fetchingStats: false,
					refetchingStats: false,
					totalStats,
					totalSavings,
					totalSaving: totalSavings.reduce((acc, cur) => (acc + cur.click_savings + cur.install_savings + cur.event_savings + cur.impression_savings), 0),
					dailySavings,
				})
			}))
			.catch(res => {
				this.setState({
					fetchingStats: false,
					refetchingStats: false,
					error: true,
				})
			})
	}

	refresh = () => {
		this.fetchCampaigns()
	}

	toggleOpen = () => {
		this.setState(prevState => ({
			open: !prevState.open
		}))
	}

	handleDateRange = (ts_start, ts_end, ts_label) => {
		this.setState({
			ts_start,
			ts_end,
			ts_label,
		}, this.fetchStats)
	}

	render() {
		const { error, open, ts_label, fetchingStats, refetchingStats, totalSavings, totalSaving, dailySavings, campaigns, partners } = this.state;

		return (
			<ClickOutside onClickOutside={() => open ? this.setState({ open: false }) : null}>
				<div className={`
					savings
					${open ? 'open' : ''}
					${fetchingStats || refetchingStats ? 'loading' : ''}
					${error ? 'error' : ''}
				`}>
					<div className="savings-total" onClick={this.toggleOpen}>
						<span>Estimated Savings ({ ts_label })</span>
						<b>${Humanize.formatNumber(totalSaving, 2)}</b>
						<Icon i="chevron-right" size={18} />
					</div>

					<Detail
						open={open}
						campaigns={campaigns}
						partners={partners}
						totalSaving={totalSaving}
						totalSavings={totalSavings}
						dailySavings={dailySavings}
						loading={fetchingStats}
						onDateRange={this.handleDateRange} />
				</div>
			</ClickOutside>
		)
	}
}

export default Savings;
