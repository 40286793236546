import React from 'react';
import Humanize from 'humanize-plus';

import './styles/Funnel.css';

const Funnel = ({
	selectedFunnel,
	selectedFunnelData,
	eventMatches,
	colors,
}) => {
	const firstEvent = eventMatches.find(match => match.category === selectedFunnel.events[0]).event;
	const lastEvent = eventMatches.find(match => match.category === selectedFunnel.events[selectedFunnel.events.length - 1]).event;
	const totalRate = selectedFunnelData.not_sequential[lastEvent] === 0 ? 100 : (selectedFunnelData.sequential[lastEvent] / selectedFunnelData.sequential[firstEvent] * 100).toFixed(2);

	return (
		<div className="event-funnel">
			<div className="event-funnel-total-rate">Total Conversion Rate: {totalRate}%</div>
			{selectedFunnel.events.map((category, index) => {
				const prev = eventMatches.find(match => match.category === selectedFunnel.events[index - 1]);
				const next = eventMatches.find(match => match.category === selectedFunnel.events[index + 1]);
				const current = eventMatches.find(match => match.category === category);

				return (
					<div className="event-funnel-event" key={index}>
						<div className="event-funnel-event-title">
							<div className="event-funnel-event-name" style={{ '--color': colors[category] }}>{category}</div>
							<div className="event-funnel-event-total">
								{Humanize.intComma(selectedFunnelData.sequential[current.event])}
								{index > 0 &&
									<span>{Humanize.intComma(selectedFunnelData.not_sequential[current.event])} didn't {prev.category}</span>
								}
							</div>
						</div>
						{index < selectedFunnel.events.length -1 &&
							<div className="event-funnel-event-conversion">
								<div>
									<span>{(selectedFunnelData.sequential[next.event] / selectedFunnelData.sequential[current.event] * 100).toFixed(2)}%</span> of <span>{Humanize.intComma(selectedFunnelData.sequential[current.event])}</span><br /> converted to <span>{next.category}</span>
								</div>
							</div>
						}
					</div>
				)
			})}
			<div className="event-funnel-end">End of the funnel</div>
		</div>
	)
}

export default Funnel;
