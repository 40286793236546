import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';
import isEqual from 'lodash/isEqual';
import { toast } from 'react-toastify';
import {
	Loader,
	NoData,
	PageTitle,
	Breadcrumbs,
	Timeline,
	AppIcon,
} from 'interceptd-ui';

import { SourceHistoryNameMap } from '../../../constants/Campaign';

import Api from '../../../services/api';

import { shadowText, isShadow } from '../../../utils/misc';

import '../styles/History.css';

import withTitle from '../../withTitle';

class SourceHistory extends React.Component {
	state = {
		loading: true,
		campaign: null,
		source: null,
		history: null,
		historyItems: [],
	}

	componentDidMount() {
		this.getData();
	}

	getData = () => {
		const { match: { params: { id, source_id } } } = this.props;

		axios.all([
			Api.getLeoparCampaigns({ id }),
			Api.getSourceHistory(id, source_id, {
				page: 1,
				size: 1000,
			})
		])
			.then(axios.spread((campaignRes, historyRes) => {
				this.setState({
					loading: false,
					campaign: campaignRes.data.data[0],
					source: campaignRes.data.data[0].sources.find(s => +s.id === +source_id),
					history: historyRes.data.data,
				}, this.buildTimelineData);
			}))
			.catch(({ response }) => {
				toast.error(`Couldn't fetch campaign history.`);
				this.setState({
					loading: false,
					error: response
				});
			});
	}

	renderState = value => {
		if ( Array.isArray(value) ) {
			return value.length === 0 ? 'empty' : value.join(', ');
		} else if ( value && typeof value === 'object' ) {
			return Object.keys(value).map(key => `${key}: ${value[key]}`).join(', ');
		} else {
			return value
		}
	}

	buildTimelineData = () => {
		const { history } = this.state;

		const historyItems = history.reduce((prev, curr, index, history) => {
			if ( index === history.length - 1 ) return prev;

			const currPayload = curr.payload,
						prevPayload = history[index + 1].payload;

			const changes = Object.keys(SourceHistoryNameMap).filter(item => 
				!isEqual(currPayload[item], prevPayload[item])
			).map(item => ({
				key: item,
				title: SourceHistoryNameMap[item],
				before: this.renderState(prevPayload[item]),
				after: this.renderState(currPayload[item]),
			}))

			if ( changes.length === 0 ) return prev;

			return ([
				...prev,
				{
					timestamp: curr.ts_create,
					changes,
				}
			]);
		}, []);

		this.setState({
			historyItems
		})
	}

	render() {
		const { match: { params: { id, source_id } } } = this.props;
		const { loading, campaign, source, historyItems } = this.state;

		if ( loading ) return <div className="campaign-detail"><Loader /></div>;

		if ( !campaign ) return <div className="campaign-detail"><NoData icon="box">Campaign not found</NoData></div>;

		return (
			<div className="campaign-history-wrapper">
				<PageTitle>
					<Breadcrumbs>
						<Breadcrumbs.Crumb><Link to="/">Campaigns</Link></Breadcrumbs.Crumb>
						<Breadcrumbs.Crumb><Link to={`/campaign/${id}`}>Campaign Detail</Link></Breadcrumbs.Crumb>
						<Breadcrumbs.Crumb><Link to={`/campaign/${id}/source/${source_id}`}>Source Detail</Link></Breadcrumbs.Crumb>
						<Breadcrumbs.Crumb><Link to={this.props.match.url}>Source History</Link></Breadcrumbs.Crumb>
					</Breadcrumbs>
					<PageTitle.Title>
						Source History - <AppIcon app={campaign.app_name ? campaign.app_info : undefined} isShadow={isShadow()} /> {shadowText(source.name, 'Example Source')} - <span className={`source-status ${source.status !== 1 ? 'blocked' : ''}`}>{source.status !== 1 ? 'Blocked' : 'Active'}</span>
					</PageTitle.Title>
				</PageTitle>

				<div className="campaign-history">
					{historyItems.length !== 0 ? (
						<Timeline>
							{historyItems.map(item => (
								<Timeline.TimelineItem
									key={item.timestamp}
									time={moment(item.timestamp, 'X').format('DD MMM YYYY - hh:mm')}
									changes={item.changes} />
							))}
						</Timeline>
					) : (
						<NoData icon="edit">No changes made to this source</NoData>
					)}
				</div>
			</div>
		)
	}
}

export default withTitle(SourceHistory, 'Source History', 'source_id');
