import React from 'react';
import Chart from 'react-apexcharts';
import sortBy from 'lodash/sortBy';
import humanizeDuration from 'humanize-duration';
import { NoData } from 'interceptd-ui';

const humanizeOptions = {
	round: true,
	maxDecimalPoints: 1,
	spacer: '',
	delimiter: ' ',
}

const shortHumanizer = humanizeDuration.humanizer({
	language: 'shortEn',
	languages: {
		shortEn: {
			y: () => 'y',
			mo: () => 'mo',
			w: () => 'w',
			d: () => 'd',
			h: () => 'h',
			m: () => 'm',
			s: () => 's',
			ms: () => 'ms',
		}
	}
});

const CTITDistGraph = ({ getOptions, options, data, ...rest }) => {
	try {
		if (!data || data.length === 0) return <NoData />;
		const rawData = sortBy([...data], [o => +o.x]).filter(d => !Number.isNaN(+d.x));
		const max = (+rawData[rawData.length - 1].x) + 1;
		const min = 0 // +rawData[0].x;
		const filledData = [];

		for (let i = min; i <= max; i += 1) {
			const d = rawData[0];
			const x = d ? +d.x : null;
			if (d && x === i) {
				rawData.shift();
				filledData.push({
					x,
					y: d.y,
				});
			} else {
				filledData.push({
					x: i,
					y: 0,
				});
			}
		}

		const seriesData = sortBy(filledData, [o => +o.x]).reduce((acc, curr, i, arr) => ([
			...acc,
			{
				x1: (Math.E ** (+curr.x)) * 1000,
				x2: arr[i + 1] ? (Math.E ** (+arr[i + 1].x)) * 1000 : '>',
				y: curr.y
			}
		]), []).map(d => ({
			x: `${shortHumanizer(d.x1, humanizeOptions)} - ${d.x2 !== '>' ? shortHumanizer(d.x2, humanizeOptions) : '∞'}`,
			y: d.y
		}));

		const series = [{
			name: 'Number of Installs',
			type: 'line',
			data: seriesData,
		}];

		return (
			<Chart options={options} series={series} type="line" width="100%" height={400} {...rest} />
		);
	} catch (e) {
		return <NoData />;
	}
}

// const formatValue = (val) => {
// 	const [first, last] = val.split(' - ');

// 	const reduceLabel = (label) => {
// 		if (label.length <= 3) return label;
// 		const o = {};

// 		label.split(' ').forEach(element => {
// 			o[element.replace(/\d/g, '')] = +element.replace(/\D/g, '');;
// 		});

// 		if (o.mo) {
// 			label = o.w > 2 ? `${o.mo + 1}mo` : `${o.mo}mo`;
// 		} else if (o.w) {
// 			label = o.d > 3 ? `${o.w + 1}w` : `${o.w}w`;
// 		} else if (o.d) {
// 			label = o.h > 6 ? `${o.d + 1}d` : `${o.d}d`;
// 		} else if (o.h) {
// 			label = o.m > 30 ? `${o.h + 1}h` : `${o.h}h`;
// 		} else if (o.m) {
// 			label = o.s > 30 ? `${o.m + 1}m` : `${o.m}m`;
// 		}

// 		return label;
// 	}

// 	return `${reduceLabel(first)} - ${reduceLabel(last)}`;
// }

CTITDistGraph.defaultProps = {
	getOptions: options => options,
	options: {
		chart: {
			id: 'ctit-dist-chart',
			type: 'line',
			stacked: false,
		},
		colors: ['#230060'],
		stroke: {
			curve: 'smooth',
			width: 2,
		},
		grid: {
			xaxis: {
				lines: {
					show: true,
				},
			},
		},
		dataLabels: {
			enabled: false,
		},
		xaxis: {
			type: 'category',
			title: {
				text: 'Time Span',
			},
			labels: {
				formatter: (val) => {
					if (!val) return null;
					const [v1, v2] = val.split(' - ');
					const [f1, f2] = v1.split(' ');
					const [l1, l2] = v2.split(' ');
					return `${f1} ${f2 || ''} - ${l1} ${l2 || ''}`;
					// return formatValue(val);
				},
			},
		},
		yaxis: [
			{
				min: 0,
				labels: {
					minWidth: 50,
				},
				title: {
					text: 'Number of Installs',
				},
			},
		],
		markers: {
			size: 0,
			style: 'full',
		},
		plotOptions: {
			line: {
				curve: 'smooth',
			}
		},
	},
}

export default CTITDistGraph;