import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';
import isEqual from 'lodash/isEqual';
import find from 'lodash/find';
import { toast } from 'react-toastify';
import {
	Loader,
	NoData,
	PageTitle,
	Breadcrumbs,
	Timeline,
	AppIcon,
} from 'interceptd-ui';

import { CampaignHistoryNameMap, TrackingTools } from '../../constants/Campaign';

import Api from '../../services/api';

import { isShadow } from '../../utils/misc';

import withTitle from '../withTitle';

import './styles/History.css';

class CampaignHistory extends React.Component {
	state = {
		loading: true,
		campaign: null,
		history: null,
		historyItems: [],
	}

	componentDidMount() {
		this.getData();
	}

	getData = () => {
		const { match } = this.props;

		axios.all([
			Api.getLeoparCampaigns({id : match.params.id}),
			Api.getCampaignHistory(match.params.id, {
				page: 1,
				size: 1000,
			})
		])
			.then(axios.spread((campaignRes, historyRes) => {
				this.setState({
					loading: false,
					campaign: campaignRes.data.data[0],
					history: historyRes.data.data,
				}, this.buildTimelineData);
			}))
			.catch(({ response }) => {
				toast.error(`Couldn't fetch campaign history.`);
				this.setState({
					loading: false,
					error: response
				});
			});
	}

	replaceTTName = id => {
		const TT = find(TrackingTools, ['value', id]);
		return TT ? TT.label : id
	}

	renderState = value => {
		if ( Array.isArray(value) ) {
			return value.join(', ');
		} else if ( value && typeof value === 'object' ) {
			return Object.keys(value).map(key => `${key}: ${value[key]}`).join(', ');
		} else {
			return value
		}
	}

	buildTimelineData = () => {
		const { history } = this.state;

		const historyItems = history.reduce((prev, curr, index, history) => {
			if ( index === history.length - 1 ) return prev;

			const currPayload = curr.payload,
						prevPayload = history[index + 1].payload;

			const changes = Object.keys(CampaignHistoryNameMap).filter(item => 
				!isEqual(currPayload[item], prevPayload[item])
			).map(item => ({
				key: item,
				title: CampaignHistoryNameMap[item],
				before: item === 'tt' ? 
					this.replaceTTName(prevPayload[item]) :
					this.renderState(prevPayload[item]),
				after: item === 'tt' ? 
					this.replaceTTName(currPayload[item]) :
					this.renderState(currPayload[item]),
			}))

			if ( changes.length === 0 ) return prev;

			return ([
				...prev,
				{
					timestamp: curr.ts_create,
					changes,
				}
			]);
		}, []);

		this.setState({
			historyItems
		})
	}

	render() {
		const { match } = this.props;
		const { loading, campaign, historyItems } = this.state;

		if ( loading ) return <div className="campaign-detail"><Loader /></div>;

		if ( !campaign ) return <div className="campaign-detail"><NoData icon="box">Campaign not found</NoData></div>;

		return (
			<div className="campaign-history-wrapper">
				<PageTitle>
					<Breadcrumbs>
						<Breadcrumbs.Crumb><Link to="/">Campaigns</Link></Breadcrumbs.Crumb>
						<Breadcrumbs.Crumb><Link to={`/campaign/${match.params.id}`}>Campaign Detail</Link></Breadcrumbs.Crumb>
						<Breadcrumbs.Crumb><Link to={match.url}>History</Link></Breadcrumbs.Crumb>
					</Breadcrumbs>
					<PageTitle.Title>
						History - <AppIcon app={campaign.app_name ? campaign.app_info : undefined} isShadow={isShadow()} /> {campaign.name}
					</PageTitle.Title>
				</PageTitle>

				<div className="campaign-history">
					{historyItems.length !== 0 ? (
						<Timeline>
							{historyItems.map(item => (
								<Timeline.TimelineItem
									key={item.timestamp}
									time={moment(item.timestamp, 'X').format('DD MMM YYYY - hh:mm')}
									changes={item.changes} />
							))}
						</Timeline>
					) : (
						<NoData icon="edit">No changes made to this campaign</NoData>
					)}
				</div>
			</div>
		)
	}
}

export default withTitle(CampaignHistory, 'Campaign History', 'id');
