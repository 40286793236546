import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import bugsnag from '@bugsnag/js'
import bugsnagReact from '@bugsnag/plugin-react'

import App from './App';

import history from './services/history';
import Local from './services/localStorage';
import Auth from './services/auth';
import Api from './services/api';

import { isDemoAccount } from './utils/misc';

import * as serviceWorker from './serviceWorker';

import 'normalize.css';

import packageJson from '../package.json';
global.appVersion = packageJson.version;

window.bugsnagClient = bugsnag({
  apiKey: '8c7eab2466147cbf9bdc30744025204b',
  notifyReleaseStages: [ 'production' ],
  releaseStage: window.location.hostname === "app.interceptd.com" ? 'production' : 'development',
})
window.bugsnagClient.app.version = packageJson.version;
window.bugsnagClient.use(bugsnagReact, React);
const ErrorBoundary = window.bugsnagClient.getPlugin('react');


const render = (Component) => {
	console.log('Version:', global.appVersion);

	Local.setItem('shadow_login', isDemoAccount());
	if (window.location.pathname.indexOf('login_as') !== -1) {
		const paths = window.location.pathname.split('/');
		const token = paths[paths.length - 1];

		window.localStorage.clear();

		Local.setStore({});
		Local.setItem('login_as', true);
		Local.setItem('shadow_login', /shadow_login/gi.test(window.location.search));
		
		Auth.setToken(token);
		Api.setToken(Auth.getToken());
	} else if (window.location.pathname.indexOf('switch') !== -1) {
		const paths = window.location.pathname.split('/');
		const token = isDemoAccount()
			? 'demo-account-token-x'
			: paths[paths.length - 1];

		window.localStorage.clear();

		Local.setStore({});		
		Auth.setToken(token);
		Api.setToken(Auth.getToken());
	}

	return ReactDOM.render(
		<ErrorBoundary>
			<Router history={history}>
				<Component />
			</Router>
		</ErrorBoundary>,
		document.getElementById('root')
	);
};

render(App);

if (module.hot) {
	module.hot.accept('./App', () => {
		const NextApp = require('./App').default;
		render(NextApp);
	});
}

serviceWorker.unregister();
