import ReactGA from 'react-ga';
import { IntercomAPI } from 'react-intercom';

import Local from './localStorage';

const SendEvent = ({ eventType, category, label, action, nonInteraction, fatal, description, sendMixPanel }) => {
	if ( window.location.hostname.indexOf('local') !== -1 || Local.getItem('login_as') )
		return false

	if (fatal) {
		ReactGA.exception({
			description,
			fatal,
		})
		IntercomAPI('trackEvent', `Exception: ${description}`, {
			description,
			fatal,
		});
		return;
	}

	const info = {
		category: category,
		action: action,
		label: label,
		nonInteraction: nonInteraction,
	};

	ReactGA.event(info);
	IntercomAPI(eventType, action, info);

	if ( sendMixPanel ) {
		window.mixpanel.track(action, info);
	}
}

export default SendEvent;
