import React from 'react';

import ShadowImage from '../../components/ShadowImage';

import { shadowText } from '../../utils/misc';

import UnknownApp from '../../assets/unknown-app.svg';
import { Icon } from 'interceptd-ui';

const Img = ({ src, alt, ...rest }) => {
	const [source, setSource] = React.useState(src);

	const handleError = () => {
		setSource(UnknownApp);
	}

	return (
		<ShadowImage src={source} onError={handleError} alt={alt} {...rest} />
	)
}

export const columns = ({
	getTotalStats,
	renderStatsCell,
	onExpandedChange,
	redirectSettings,
}) => [
	{
		Header: 'expander',
		Expander: () => null,
		expander: true,
		show: false,
	},
	{
		Header: '',
		id: 'icon_url',
		accessor: 'icon_url',
		cast: 'string',
		width: 100,
		sortable: false,
		Cell: props => (
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<div className={`rt-expander ${props.isExpanded ? '-open' : ''}`} onClick={() => onExpandedChange(props.viewIndex)}></div>
				<div className="partner-logo">
					<Img src={props.value || UnknownApp} alt={`${props.original.name} Logo`} />
				</div>
			</div>
		)
	},
	{
		Header: 'Name',
		id: 'title',
		accessor: 'title',
		cast: 'string',
		width: 300,
		Cell: props => {
			const { device } = props.original;

			const store = device === 'ios' ? 'fab fa-apple' :
										device === 'android' ? 'fab fa-android' : 
										device === 'web' ? 'fas fa-desktop' :
										'far fa-question-circle';

			return (
				<div className="campaign-list-apps-name">
					<span>{shadowText(props.value, 'Example App')}</span>
					<i className={`app-store ${store}`} />
				</div>
			)
		}
	},
	{
		Header: 'Clicks',
		id: 'click',
		accessor: props => getTotalStats(props, 'click'),
		cast: 'number',
		style: {
			padding: 0,
		},
		Cell: renderStatsCell,
	},
	{
		Header: 'Installs',
		id: 'install',
		accessor: props => getTotalStats(props, 'install'),
		cast: 'number',
		style: {
			padding: 0,
		},
		Cell: renderStatsCell,
	},
	{
		Header: 'Events',
		id: 'event',
		accessor: props => getTotalStats(props, 'event'),
		cast: 'number',
		style: {
			padding: 0,
		},
		Cell: renderStatsCell,
	},
	{
		Header: '',
		id: '',
		style: {
			padding: 0,
		},
		Cell: (props) => <Icon style={{ cursor: 'pointer', float: 'right', marginRight: 40 }} onClick={() => redirectSettings(props)} i="settings" size={18} />,
	},
];