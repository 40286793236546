import React from 'react';
import Chart from 'react-apexcharts'

class TimeDistGraph extends React.PureComponent {
	render() {
		const { options, getOptions, series, ...rest } = this.props;
		return (
			<Chart options={getOptions(options)} series={series} type="line" width="100%" height={405} {...rest} />
		)
	}
}

TimeDistGraph.defaultProps = {
	getOptions: options => options,
	options: {
		chart: {
			id: 'time-dist-chart',
			group: 'time-dist',
			type: 'line',
			height: 400,
			stacked: false,
		},
		colors: ['#230060', '#5C6BC0', '#B9CDE1'],
		stroke: {
			curve: 'smooth',
			width: 2,
		},
		annotations: {
			xaxis: [{
				x: `${String(new Date().getHours())}`,
				strokeDashArray: 0,
				borderColor: '#B9CDE1',
				label: {
					borderColor: '#B9CDE1',
					style: {
						color: '#fff',
						background: '#B9CDE1',
					},
					text: 'Current Hour',
				}
			}]
		},
		grid: {
			xaxis: {
				lines: {
					show: true,
				},
			},
		},
		dataLabels: {
			enabled: false,
		},
		xaxis: {
			type: 'category',
			title: {
				text: 'Hours',
			},
			labels: {
				formatter: val => val < 10 ? `0${val}` : val,
			},
		},
		tooltip: {
			followCursor: false,
			theme: 'dark',
			x: {
				show: true,
				formatter: val => `Hour: ${val}:00`,
			},
			marker: {
				show: true
			},
		},
		yaxis: [
			{
				min: 0,
				labels: {
					minWidth: 50,
				},
			},
		],
		markers: {
			size: 0,
			style: 'full',
		},
		plotOptions: {
			line: {
				curve: 'smooth',
			}
		},
	},
}

export default TimeDistGraph;