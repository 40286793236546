export const RuleInfos = {
	AM: `App version mismatch alarm is triggered when targeted app version and installed app version do not match with each other. This alarm can also be used for auditing ad network targetings. If you have multiple app versions live on the store, you should target the oldest version.`,
	CS: `Click spamming is a fraud type where a network simulates high number of fake clicks from real devices in hopes of getting the attribution of organic installs. Most of the installs have long click-to-install times. Since the installs are actually organic, ad budget is wasted on highly engaged legitimate users.`,
	CI: `Click injection occurs when a fraudulent app on a device listens to other app installs on the device and creates fake clicks while the install is in progress. It results in claiming the attribution of installs and generally click-to-install times are short. `,
	CTIT: `CTIT  Anomaly  alarm  checks  if  a  sub-publisher’s  installs  create  a  distribution  with  very  short click-to-install times. When this alarm is triggered, it might point towards click injection, or fake clicks & installs such as SDK Spoofing or bot traffic.`,
	CM: `Country mismatch occurs when clicks are provided from a country other than the one targeted for that specific campaign. It might occur from wrong targeting, as well as fraudulent activity. Detecting this on click level helps you to save campaign budgets. This alarm can also be used for auditing ad network targetings.`,
	CR: `CR is the rate of installs per click. High CR means that more clicks are converting into installs; which might be resulting from a good targeting, or it might indicate incentivized activity.`,
	CR2: `CR is the rate of installs per click. High CR means that more clicks are converting into installs; which might be resulting from a good targeting, or it might indicate incentivized activity.`,
	DF: `Device farms (or click farms) searches for active ads and uses real or simulated devices to click on the same ads many times and convert them into installs. They may provide as much clicks or installs as they want; however using limited number of device to provide high volume leaves a distinctive pattern behind.`,
	DI: `Duplicate IP occurs when many installs are received from the clicks that are from same IP in a small period of time. Although duplicate IP might result from many people using the same public wifi, it is more probably a pattern fraudsters leave behind when they providing installs from the same physical location.`,	
	EI: `High install-to-event conversion rate is a good indicator of quality traffic as users are engaging with the app.  However  too-good-to-be-true  conversions  might  mean  SDK  spoofing  as  well  and  other  fraud indicators should be checked as well. Low converting subpublishers can be blacklisted automatically as the reason can be a mismatch with the adnetwork targeting or a fraudulent activity is being conducted.`,
	MRP: ``,
	HCS: `High Conversion Speed occurs when fraudsters provide high number of fake clicks and convert them into fake installs with a sudden bursts. Since the installs are fake, in-app activity is almost non-existent. Trying to decrease suspicion, clicks may be provided over a longer time. This may also happen when your ad is placed on a well known offer walls. Fraudster using SDK spoofing also send burst of conversions when they figure out how to fool attribution SDKs.`,
	HCR: `High  click-to-install  conversion  rate  is  a  good  indicator  for  unauthorized  incentivised  activity  or fraudulent intentions as unusual amount of clicks convert into installs.`,
	IM: `IP mismatch occurs when click IPs and install IPs do not match with each other. While this can be normal for users who are mobile or when click to install time is prolonged; fake installs can be detected by cross-checking time patterns and mismatched IPs. In a clean traffic, a certain pattern with click to install time and IP mismatch ratio should emerge for a publisher.`,
	IPE: `Fraud-free traffic should have an homogeneous IP distribution. Even though fraudsters switch IPs regularly to hide their footprints, they disrupt homogeneity of the distribution.`,
	IQ: `Many fraudsters try to hide their footprints by using anonymous IPs such as hosting servers, VPNs or Proxies. This type of fraud can be detected on click level.`,
	IR: `IP  Reputation  checks  if the  install  information  is  received from  an  IP which was  recently  conducting fraudulent activity (not necessarily mobile ad fraud). When detected, individual installs are rejected.`,
	LCR: `Low click-to-install conversion rate means unusual amount of clicks are received and it might indicate click spamming or a fraudsters trying to reduce suspicion for incentivised activity by sending high number of clicks. It may also indicate that the subpublisher is not converting.`,
	MIRT: `Install Referrer Time is provided by Google for Android campaigns and they include information about landing time to Play Store page, Install begin time and Install finish time. Not all attribution tools are capable  of  providing  these  three  timestamps;  however,  if  your  app  has  an  SDK  which  is  capable  of collecting  this  information,  not  having  this  information  in  high  percentages  indicates  fraudulent activity, including SDK Spoofing and bot traffic.`,
	OD: `Operating System Version Distribution happens when fraudsters provide high percentage of installs from devices with older/outdated OS versions. This is a footprint of a fraud since many farms do not update to newest operating system devices.`,
	OM: `Operating System Mismatch happens clicks or installs are received from other platforms (such as iOS, Android, desktop, etc) than the one targeted for the app. It can indicate mis-targeting, fake clicks, or SDK spoofing.`,
	OV: `Operating System mismatch occurs when fraudsters provide installs from devices which have older OS versions than the one targeted for that specific campaign. This may be a footprint of a fraud since many farms use old operating system devices. This alarm can also be used for auditing ad network targetings. Installed OS version should be postbacked in order for Interceptd to analyze the mismatch rate.`,
	SM: `SDK Version mismatch occurs when install information is received from an older version of an MMP’s (attribution  tool)  SDK  than  the  one  used  for  the  app.  It  generally  means  that  an  older  SDK  is reverse-engineered and install and event information is being spoofed by fraudsters. `,
	SV: `SDK Version mismatch occurs when install information is received from an older version of MMP (attribution tool) SDK than the one targeted for that specific campaign. Installs coming from older SDK versions might mean that your attribution tool’s SDK is being spoofed by fraudsters. If you have multiple SDK versions live on the store, you should target the oldest version.`,

	IDT: `Invalid Device Trace monitors device-related irregularities between the click and the install in order to check if attributed clicks are originated from the real user device.`,
	CTR: `CTR is the rate of clicks per impression. High CTR means that more impressions are converting into clicks which might be resulting from a good targeting, or it might indicate incentivized activity.`,
	// ADS: ``,
	IC: `Invalid Click occurs if there is no matching impression for the incoming click.`,
}

export const RuleInputs = {
	CR: {
		'install_threshold': {
			label: 'Install Threshold',
			icon: '',
			default: '10',
			info: `Set the minimum number of installs a subpublisher should provide to start the CR analysis. It should also reach the click threshold.`,
		},
		'click_threshold': {
			label: 'Click Threshold',
			icon: '',
			default: '50',
			info: `Set the minimum number of clicks a subpublisher should provide to start the CR analysis. It should also reach the install threshold.`,
		},
		'comparison': {
			label: 'Greater / Less than',
			icon: '',
			default: '>',
			info: `Decide whether the alarm should fire when a subpublisher provides CR greater or less than the desired percentage.`,
		},
		'percentage': {
			label: 'Percentage',
			icon: 'percent',
			default: '20',
			info: `Set the minimum / maximum conversion rate to fire the alarm.`,
		},
	},
	CR2: {
		'install_threshold': {
			label: 'Install Threshold',
			icon: '',
			default: '10',
			info: `Set the minimum number of installs a subpublisher should provide to start the CR analysis. It should also reach the click threshold.`,
		},
		'click_threshold': {
			label: 'Click Threshold',
			icon: '',
			default: '50',
			info: `Set the minimum number of clicks a subpublisher should provide to start the CR analysis. It should also reach the install threshold.`,
		},
		'comparison': {
			label: 'Greater / Less than',
			icon: '',
			default: '>',
			info: `Decide whether the alarm should fire when a subpublisher provides CR greater or less than the desired percentage.`,
		},
		'percentage': {
			label: 'Percentage',
			icon: 'percent',
			default: '20',
			info: `Set the minimum / maximum conversion rate to fire the alarm.`,
		},
	},
	CM: {
		'click_threshold': {
			label: 'Click Threshold',
			icon: '',
			default: '50',
			info: `Set the minimum number of clicks a subpublisher should provide to start the CM analysis.`,
		},
		'percentage': {
			label: 'Percentage',
			icon: 'percent',
			default: '20',
			info: `Set the maximum acceptable mismatch rate to fire the alarm.`,
		},
	},
	AM: {
		'install_threshold': {
			label: 'Install Threshold',
			icon: '',
			default: '10',
			info: `Set the minimum number of installs a subpublisher should provide to start the analysis.`,
		},
		'percentage': {
			label: 'Percentage',
			icon: 'percent',
			default: '20',
			info: `Set the maximum acceptable mismatch rate to fire the alarm.`,
		},
	},
	CI: {
		'install_threshold': {
			label: 'Install Threshold',
			icon: '',
			default: '10',
			info: `Set the minimum number of installs a subpublisher should provide to start the analysis.`,
		},
		'percentage': {
			label: 'Percentage',
			icon: 'percent',
			default: '20',
			info: `Set the maximum acceptable rate to fire the alarm.`,
		},
	},
	OV: {
		'install_threshold': {
			label: 'Install Threshold',
			icon: '',
			default: '10',
			info: `Set the minimum number of installs a subpublisher should provide to start the analysis.`,
		},
		'percentage': {
			label: 'Percentage',
			icon: 'percent',
			default: '20',
			info: `Set the maximum acceptable mismatch rate to fire the alarm.`,
		},
	},
	IR: {
		'install_threshold': {
			label: 'Install Threshold',
			icon: '',
			default: '25',
			info: `Set the minimum number of installs a subpublisher should provide to start the analysis.`,
		},
		'percentage': {
			label: 'Percentage',
			icon: 'percent',
			default: '40',
			info: `Set the maximum acceptable mismatch rate to fire the alarm.`,
		},
	},
	SV: {
		'install_threshold': {
			label: 'Install Threshold',
			icon: '',
			default: '10',
			info: `Set the minimum number of installs a subpublisher should provide to start the analysis.`,
		},
		'percentage': {
			label: 'Percentage',
			icon: 'percent',
			default: '20',
			info: `Set the maximum acceptable mismatch rate to fire the alarm.`,
		},
	},
	EI: {
		'install_threshold': {
			label: 'Install Threshold',
			icon: '',
			default: '10',
			info: `Set the minimum number of installs a subpublisher should provide to start the analysis.`,
		},
		'time_threshold': {
			label: 'Time Span for Event',
			icon: 'clock',
			default: '24',
			info: `Set the necessary time span in hours between install and events to start the analysis.`,
			valueParser: value => +value * 24,
			displayParser: value => +value / 24,
		},
		'event_type': {
			label: 'Event type',
			icon: '',
			default: 'registration',
			type: 'text',
			info: `Enter the event name you are targeting and make the automatic optimizations for. Keep in mind that the event name should be exactly the same as the one postbacked with event postback.`,
		},
		'comparison': {
			label: 'Greater / Less than',
			icon: '',
			default: '<',
			info: `Decide whether the alarm should fire when a subpublisher provides event conversion less or greater than the desired percentage.`,
		},
		'percentage': {
			label: 'Percentage',
			icon: 'percent',
			default: '20',
			info: `Set the minimum / maximum event conversion rate to fire the alarm.`,
		},
	},
	MRP: {
		'install': {
			label: 'Install Parameter',
			icon: '',
			default: '',
			info: `Select the parameter that you want to require on install.`,
		},
		'event': {
			label: 'Event Parameter',
			icon: '',
			default: '',
			info: `Select the parameter that you want to require on event.`,
		},
		'event_name': {
			label: 'Event Name',
			icon: '',
			default: '',
			type: 'text',
			info: `Enter the event name that you want to require the parameter.`,
		},
	},
	HCS: {
		'threshold': {
			label: 'Time Window (sec)',
			icon: '',
			default: '60',
			info: `Set the look-back time window for analysis to run on each install`,
		},
		'install_greater_than': {
			label: 'Install Threshold',
			icon: '',
			default: '10',
			info: `Set the minimum number of installs a subpublisher should provide to start the analysis.`,
		},
	},
	MIRT: {
		'install_threshold': {
			label: 'Install Threshold',
			icon: '',
			default: '10',
			info: `Set the minimum number of installs a subpublisher should provide to start the analysis.`,
		},
		'percentage': {
			label: 'Percentage',
			icon: 'percent',
			default: '20',
			info: `Set the maximum acceptable missing rate to fire the alarm.`,
		},
	},
	IQ: {
		'click_threshold': {
			label: 'Click Threshold',
			icon: '',
			default: '100',
			info: `Set the minimum number of clicks a subpublisher should provide to start the IQ analysis.`,
		},
		'percentage': {
			label: 'Percentage',
			icon: 'percent',
			default: '20',
			info: `Set the maximum acceptable missing rate to fire the alarm.`,
		},
		'vpn_disabled': {
			label: 'Disable VPN',
			icon: '',
			default: false,
			info: `Disable VPN IP protection if you are specifically targeting them.`,
		},
		'dch_disabled': {
			hidden: true,
			label: 'Disable DCH',
			icon: '',
			default: false,
			info: `Disable DCH IP protection if you are specifically targeting them.`,
		},
		'tor_disabled': {
			hidden: true,
			label: 'Disable TOR',
			icon: '',
			default: false,
			info: `Disable TOR IP protection if you are specifically targeting them.`,
		},
	},
	CTR: {
		'impression_threshold': {
			label: 'Impression Threshold',
			icon: '',
			default: false,
			info: '',
		},
		'percentage': {
			label: 'Percentage',
			icon: 'percent',
			default: '20',
			info: `Set the maximum acceptable missing rate to fire the alarm.`,
		},
	},
	IC: {
		'click_threshold': {
			label: 'Click Threshold',
			icon: '',
			default: '25',
			info: `Set the minimum number of clicks a subpublisher should provide to start the analysis.`,
		},
		'percentage': {
			label: 'Percentage',
			icon: 'percent',
			default: '40',
			info: `Set the maximum acceptable rate to fire the alarm.`,
		},
	},
}

export const RuleNameMaps = {
	'Conversion Rate': 'High Conversion Rate',
	'Conversion Rate 2': 'Low Conversion Rate',
}