import React from 'react';
import find from 'lodash/find';
import reduce from 'lodash/reduce';
import get from 'lodash/get';
import { Checkbox } from 'interceptd-ui';

import Local from '../../../../services/localStorage';

import { shadowText, renderAlarmName, isDemoAccount } from '../../../../utils/misc';

export const columns = ({
	select,
	isSelected,
	supportedRules,
	supportedCampaignRules,
	eventListOptions,
	renderStatsCell,
	renderActions,
	subpubIdSeparator,
	showImpressionColumn,

	eventInstallMap,
	alarmMap,
	clickMap,
	eventMap,
	impressionMap,
	sourceNameMap,
}) => {
	const extraColumns = [];
	const impressionCol = [];
	const actionColumn = isDemoAccount()
		? []
		: [{
			Header: '',
			id: 'actions',
			accessor: 'id',
			cast: 'actions',
			width: 65,
			resizable: false,
			sortable: false,
			sticky: true,
			headerStyle: {
				overflow: 'visible',
				justifyContent: 'center',
				padding: 0,
			},
			style: {
				overflow: 'visible',
				justifyContent: 'center',
				padding: 0,
			},
			permanent: true,
			Cell: props => renderActions(props)
		}];
		
	if (showImpressionColumn) {
		impressionCol.push({
			Header: 'Impression',
			id: 'impression_count',
			accessor: v => impressionMap?.[v]?.total ?? 0,
			cast: 'number',
			width: 220,
			resizable: false,
			permanent: true,
			Cell: renderStatsCell,
		});
	}

	eventListOptions.forEach((ec) => {
		extraColumns.push({
			Header: `Total "${ec.value}"`,
			id: ec.value,
			accessor: (v) => {
				const data = eventMap?.[v] || {};
				const event = data?.events?.[ec.value] || {};
				return ((event.clean_event_count || 0) + (event.flagged_event_count || 0) + (event.rejected_event_count || 0))
			},
			cast: 'number',
			width: 220,
			resizable: false,
			Cell: renderStatsCell,
		});
		extraColumns.push({
			Header: `Reattributed "${ec.value}"`,
			id: `r_${ec.value}`,
			accessor: (v) => {
				const data = eventMap?.[v] || {};
				const event = get(data, `events['${ec.value}']`, {});
				return ((event.clean_reattributed_event_count || 0) + (event.flagged_reattributed_event_count || 0) + (event.rejected_reattributed_event_count || 0))
			},
			cast: 'number',
			width: 220,
			resizable: false,
			Cell: renderStatsCell
		});
	});

	const renderAlarm = (props, name) => {
		const obj = eventInstallMap?.[props.original]?.[name]?.alarms ?? {};
		let arr = Object.keys(obj);

		if (!arr || arr.length === 0) return '';
		const uniqueAlarms = {};

		arr.forEach((a) => {
			const n = a.replace(/\[Flagged\]/g, '');
			uniqueAlarms[n] = uniqueAlarms[n] || 0;
			uniqueAlarms[n] += obj[a];
		});

		const alarms = {};

		Object.keys(uniqueAlarms).forEach(alarm => {
			const supported = [...supportedRules, ...supportedCampaignRules, {
				name: 'Rejected By MMP',
				'short-name': 'TTB'
			}];

			const name = find(supported, ['short-name', alarm]);

			alarms[alarm] = {
				name: name ? name.name : alarm,
				shortName: alarm === 'TTB' ? 'RMMP' : alarm,
				count: uniqueAlarms[alarm],
			}
		});

		return (
			<div className="subpub-alarms">
				{Object.keys(uniqueAlarms).map(a => (
					<span key={a}>
						<span tooltip-top={`${renderAlarmName(alarms[a].name)} ${alarms[a].count}`}>{renderAlarmName(alarms[a].shortName)}</span>
					</span>
				))}
			</div>
		);
	}

	const splitData = (props) => {
		const [subpubId, sourceId] = props.original.split(subpubIdSeparator);
		return {
			subpubId,
			sourceId,
		};
	};

	return [
		{
			Header: 'Name',
			id: 'sub_pub_id',
			cast: 'string',
			sortable: false,
			permanent: true,
			sticky: true,
			stickyPosition: 'left',
			minWidth: 350,
			Cell: (props) => {
				const { subpubId } = splitData(props);
				const alarm = alarmMap?.[props.original];
				return (
					<div style={{ display: 'flex', alignItems: 'center', minWidth: 0 }}>
						{Local.getItem('me')?.plans?.interceptd !== 'free' && <Checkbox
							id={`subpub-${props.index}`}
							onChange={e => select(props, e)}
							checked={isSelected(props)}
						/>}
						<div className="subpub-list-id-col">
							<span style={{ textDecoration: isSelected(props) ? 'line-through' : 'none' }}>{shadowText(subpubId, 'Example SubPub')}</span>
							{alarm?.blockDate && <span><b>Block Date:</b> {alarm.blockDate}</span>}
						</div>
					</div>
				);
			}
		},
		{
			Header: 'Source',
			id: 'source_name',
			accessor: 'source_name',
			cast: 'string',
			sortable: false,
			permanent: true,
			width: 220,
			Cell: (props) => {
				const { sourceId } = splitData(props);
				return shadowText(sourceNameMap?.[sourceId]?.name || '-', 'Example Source');
			},
		},
		{
			Header: 'Subpublisher Alarms',
			id: 'alarms',
			accessor: 'alarm_set',
			cast: 'string',
			sortable: true,
			width: 220,
			style: {
				overflow: 'visible',
			},
			permanent: true,
			Cell: (props) => {
				const alarmSet = alarmMap?.[props.original]?.alarm_set || [];

				if (!alarmSet || alarmSet.length === 0) return '';
				const alarms = reduce(alarmSet, (result, value, key) => {
					result[value] = result[value] ? result[value] + 1 : 1;
					return result;
				}, {});
				Object.keys(alarms).forEach(a => {
					const supported = find([...supportedRules, ...supportedCampaignRules], ['short-name', a]);
					const name = supported ? supported.name :
						a.startsWith('EI_') ? a.replace('EI_', 'Event/Install Rate ') :
							a;
					alarms[a] = {
						name: name,
						count: alarms[a]
					}
				});
				return (
					<div className="subpub-alarms">
						{Object.keys(alarms).map(a => (
							<span key={a}><span tooltip-top={alarms[a].name}>{a}{alarms[a].count > 1 ? ` (${alarms[a].count})` : ''}</span></span>
						))}
					</div>
				);
			},
		}, {
			Header: 'Install Based Alarms',
			id: 'install_based_alarms',
			accessor: 'install_based_alarm_total',
			cast: 'string',
			sortable: true,
			width: 220,
			style: {
				overflow: 'visible',
			},
			permanent: true,
			Cell: props => renderAlarm(props, 'install_based_alarm_count'),
		},
		{
			Header: 'Event Based Alarms',
			id: 'event_based_alarms',
			accessor: 'event_based_alarm_total',
			cast: 'string',
			sortable: true,
			width: 220,
			style: {
				overflow: 'visible',
			},
			permanent: true,
			Cell: props => renderAlarm(props, 'event_based_alarm_count'),
		}
		, ...impressionCol,
		{
			Header: 'Clicks',
			id: 'click',
			accessor: v => clickMap?.[v]?.total ?? 0,
			cast: 'number',
			width: 220,
			resizable: false,
			permanent: true,
			Cell: renderStatsCell,
		},
		{
			Header: 'Installs',
			id: 'install',
			accessor: v => eventInstallMap?.[v]?.install_count?.total ?? 0,
			cast: 'number',
			width: 220,
			resizable: false,
			permanent: true,
			Cell: renderStatsCell
		},
		{
			Header: 'Events',
			id: 'event',
			accessor: v => eventInstallMap?.[v]?.event_count?.total ?? 0,
			cast: 'number',
			width: 220,
			resizable: false,
			permanent: true,
			Cell: renderStatsCell
		},
		...extraColumns,
		...actionColumn,
	];
};
