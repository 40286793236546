import moment from 'moment';

export const getColumns = ({ renderStatus, renderActions }) => [
  {
    Header: 'Email',
    id: 'email',
    accessor: 'email',
  },
  {
    Header: 'Name',
    id: 'fullname',
    accessor: 'fullname',
  },
  {
    Header: 'Status',
    id: 'status',
    accessor: 'status',
    Cell: renderStatus,
  },
  {
    Header: 'INVITATION DATE',
    id: 'ts_registration',
    accessor: 'ts_registration',
    Cell: props => props.value
      ? moment(props.value, 'X').format('DD MMM YYYY H:mm')
      : '-'
  },
  {
    Header: 'LAST LOGIN',
    id: 'ts_last_login',
    accessor: 'ts_last_login',
    Cell: props => props.value
      ? moment(props.value, 'X').format('DD MMM YYYY H:mm')
      : '-',
  },
  {
    Header: 'Actions',
    id: 'actions',
    accessor: 'actions',
    Cell: renderActions,
  },
];