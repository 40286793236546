import { toast } from 'react-toastify';
import { subDays } from 'date-fns';

import Local from '../services/localStorage';
import history from '../services/history';
import Settings from '../services/settings';

import Currencies from '../constants/currency.json';
import { getParameterByName } from './transform';

export function debounce(fn, delay) {
	var timer = null;
	return function () {
		var context = this, args = arguments;
		clearTimeout(timer);
		timer = setTimeout(function () {
			fn.apply(context, args);
		}, delay);
	};
}

export const isStoreURL = url => {
	const store = (url.indexOf('itunes') !== -1 || url.indexOf('apps.apple') !== -1) ? 'ios' :
		url.indexOf('google') !== -1 ? 'play' :
			null;
	const packname = store === 'play' ? getParameterByName('id', url) :
		store === 'ios' ? (new RegExp('/id(\\d+)')).exec(url.split('?')[0])[1] :
			null;
	return url && url !== '' && store && packname;
}

export const isURL = str => {
	const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
		'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
		'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
		'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
		'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
		'(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
	return !!pattern.test(str);
}

export const checkPaymentStatus = (me) => {
	if (isDemoAccount()) {
		return;
	}

	const token = Local.getItem('token');

	if (!me || !token) {
		history.push('/logout');
		return;
	}

	const data = decodeToken(token);

	if (me.plans?.interceptd !== data.plans?.interceptd) {
		history.push('/logout');
	}
}

export const decodeToken = (token = '') => {
	try {
		const buff = new Buffer(token.split('.')[1], 'base64');
		return JSON.parse(buff.toString('ascii'));
	} catch(err) {
		toast.error('Something went wrong');
	}
}

export const shadowText = (str, alt = 'Example') => {
	const shadow = Local.getItem('shadow_login');
	return shadow ? alt : str;
};

export const isShadow = () => Local.getItem('shadow_login');

export const versionCompare = (v1, v2, options) => {
	let lexicographical = options && options.lexicographical,
		zeroExtend = options && options.zeroExtend,
		v1parts = v1.split('.'),
		v2parts = v2.split('.');

	const isValidPart = x => {
		return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x);
	}

	if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
		return NaN;
	}

	if (zeroExtend) {
		while (v1parts.length < v2parts.length) v1parts.push('0');
		while (v2parts.length < v1parts.length) v2parts.push('0');
	}

	if (!lexicographical) {
		v1parts = v1parts.map(Number);
		v2parts = v2parts.map(Number);
	}

	for (var i = 0; i < v1parts.length; ++i) {
		if (v2parts.length === i) {
			return 1;
		}

		if (v1parts[i] === v2parts[i]) {
			continue;
		} else if (v1parts[i] > v2parts[i]) {
			return 1;
		} else {
			return -1;
		}
	}

	if (v1parts.length !== v2parts.length) {
		return -1;
	}

	return 0;
}

export const getStoreUrl = (appId) => {
	if (isStoreURL(appId)) return appId;

	const type = ((appId.startsWith('id') && appId.length >= 11 && !isNaN(appId.replace('id', ''))) || (!isNaN(appId) && appId.length >= 9))
		? 'ios'
		: (appId.startsWith('com') && appId.length >= 5) 
			? 'android'
			: null;

	const url = type === 'ios'
		? `https://itunes.apple.com/app/${!isNaN(appId) ? 'id' : ''}${appId}`
		: `https://play.google.com/store/apps/details?id=${appId}`;

	return type === null ? type : url;
};

export const checkIsFreeAccount = (campaignCount = 0) => {
	const me = Local.getItem('me');

	if (campaignCount === 0 && me?.plans?.interceptd === 'free') {
		window.location.href = '/start-free-trial';
	}
}

export const validateEmail = email => {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export const parseArray = (arr) => {
	let result;
	try {
		result = typeof arr === 'string'
			? JSON.parse(arr)
			: arr;
	} catch(err) {
		result = Array.isArray(arr)
			 ? arr
			 : [];
	}
	return result || [];
}

export const getQueryParams = (name, url) => {
	const urlParams = new URLSearchParams(url || window.location.search);
	return urlParams.get(name);
}

export const geSourcePayoutCurrency = (sourceId) => {
	const sourceSettings = Settings.get('sourceSettings', 'account') || {};
	const payoutCurrency = sourceSettings?.[sourceId]?.payout_currency ?? 'USD';
	const payoutSymbol = Currencies[payoutCurrency].symbol;
	return ({
		payoutCurrency,
		payoutSymbol,
	});
}

export const renderAlarmName = (name = '') => {
	switch (name) {
		case 'Ad Stacking': return 'Click Stuffing';
		case 'ADS': return 'CSF';
		default: return name.replace(/_IPQS/g, '');
	}
}

export const safeDivide = (num1, num2) => {
	if (!num1 || num1 === 0) return 0;
	if (!num2 || num2 === 0) return 0;
	return num1 / num2;
}

export const getInitialDateFilter = () => {
	if (isDemoAccount()) {
		return ({
			start: new Date('12/01/2019'),
			end:new Date('01/31/2020'),
		});
	}

	const globalDate = Local.getItem('date_filter');

	if (globalDate?.from && globalDate?.to) {
		return ({
			start: new Date(globalDate.from),
			end:new Date(globalDate.to),
		});
	}

	return ({
		start: subDays(new Date(), 7),
		end: new Date(),
	});
}

export const isDemoAccount = () => {
	const host = window.location.hostname;
	return host.indexOf('demo') !== -1;
}