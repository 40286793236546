import React from 'react';
import S2S from '../assets/tracking/server-to-server.png';
import Adjust from '../assets/tracking/adjust.svg';
import Tune from '../assets/tracking/tune.svg';
import Branch from '../assets/tracking/branch.svg';
import Appsflyer from '../assets/tracking/appsflyer.svg';
import Kochava from '../assets/tracking/kochava.svg';
import Singular from '../assets/tracking/singular.png';
import FreeAppAnalytics from '../assets/tracking/free-app-analytics.svg';

export const CampaignTypes = [
	{
		value: 'CPM',
		label: 'CPM',
	},
	{
		value: 'CPI',
		label: 'CPI',
	},
	{
		value: 'CPA',
		label: 'CPA',
	},
]

export const CampaignWebTypes = [
	{
		value: 'CPM',
		label: 'CPM',
	},
	{
		value: 'CPA',
		label: 'CPA',
	},
]

export const TrafficTypes = [
	{
		value: 'INCENT',
		label: 'Incent'
	},
	{
		value: 'NONINCENT',
		label: 'Non-Incent'
	},
]

export const DeviceTypes = [
	{
		value: 'PHONE',
		label: 'Phone'
	},
	{
		value: 'TABLET',
		label: 'Tablet'
	},
	{
		value: 'ALL',
		label: 'All'
	},
]

export const CampaignBlacklistTypes = [
	{
		value: false,
		label: 'Not Blacklisted'
	},
	{
		value: true,
		label: 'Blacklisted'
	},
]

export const TrackingTools = [
	{
		label: 'Server to Server',
		value: 0,
		icon: S2S,
	},
	{
		label: 'Adjust',
		value: 1,
		icon: Adjust,
		click_to_impression_mapping: {
			"app.adjust": "view.adjust",
			".com": ".com/impression",
			"{publisher_click_id}": "{publisher_imp_id}",
			"{click_id}": "{imp_id}",
		}
	},
	{
		label: 'Tune',
		value: 2,
		icon: Tune,
	},
	{
		label: 'Branch',
		value: 3,
		icon: Branch,
	},
	{
		label: 'Appsflyer',
		value: 4,
		icon: Appsflyer,
		click_to_impression_mapping: {
			"app.appsflyer": "impression.appsflyer",
			"{click_id}": "{imp_id}",
			"{publisher_click_id}": "{publisher_imp_id}",
			"af_click_lookback": "af_viewthrough_lookback",
		}
	},
	{
		label: 'Kochava',
		value: 5,
		icon: Kochava,
	},
	{
		label: 'Free App Analytics',
		value: 6,
		icon: FreeAppAnalytics,
	},
	{
		label: 'Singular',
		value: 7,
		icon: Singular,
	},
];

export const TrackingStatus = {
	SUCCESS: 1,
	PENDING: 2,
	FAIL: 3,
};

export const TrackingMacros = [
	{
		name: `{click_id}`,
		type: ['iOS', 'Android'],
		info: `Interceptd Click ID`,
		required: true,
	},
	{
		name: `{sub_id}`,
		type: ['iOS', 'Android'],
		info: `Subpublisher ID`,
	},
	{
		name: `{device_id}`,
		type: ['iOS', 'Android'],
		info: `IOS IFA or Google Advertiser ID`,
	},
	{
		name: `{android_id}`,
		type: ['Android'],
		info: `Unique Android Identifier`,
	},
];

export const SankeyCustomerReasons = {
	CUSTOMER_SOURCE_BLOCK: 'Blocked Source',
	CUSTOMER: 'Customer Blacklist',
};

export const Warnings = {
	'am': <React-Fragment><b>App Version Mismatch</b> alarm needs <b>Minimum App Version</b> target to work.</React-Fragment>,
	'ov': <React-Fragment><b>OS Version Mismatch</b> alarm needs <b>Minimum OS Version</b> target to work.</React-Fragment>,
	'sv': <React-Fragment><b>SDK Version Mismatch</b> alarm needs <b>Attribution SDK Version</b> target to work.</React-Fragment>,
	'cm': <React-Fragment><b>Country Mismatch</b> alarm needs <b>Country</b> target to work.</React-Fragment>,
}

export const CampaignHistoryNameMap = {
	app: 'App',
	bid: 'Payout',
	event_payouts: 'Event Payouts',
	country: 'Country',
	event_tokens: 'Adjust Event Tokens',
	name: 'Name',
	target_app_version: 'Minimum App Version',
	target_os_version: 'Minimum OS Version',
	target_sdk_version: 'Attribution SDK Version',
	tt: 'Tracking Tool',
	type: 'Type',
	click_lookback_window: 'Click Lookback Window',
	install_lookback_window: 'Install Lookback Window',
	landing_url: 'Landing URL',
}

export const SourceHistoryNameMap = {
	bid: 'Payout',
	event_payouts: 'Event Payouts',
	selected_events: 'Selected Events for Postback',
	name: 'Name',
	disable_install_postback: 'Disable Install Postback',
	unique_events_to_postback: 'Unique Events to Postback',
	redirect_url: 'Tracking URL',
}

export const MultiCampaignFieldMap = {
	name: 'Campaign Name',
	app: 'App, Store URL',
	bid: 'Payout (Bid), CPI Bid',
	country: 'Country, Countries',
	type: 'Campaign Type',
	traffic_type: 'Traffic Type',
	daily_cap: 'Daily Cap',
	total_cap: 'Total Cap',
	device_type: 'Devices',
	target_app_version: 'Minimum App Version',
	target_os_version: 'Minimum OS Version',
	target_sdk_version: 'Attribution SDK Version',
	ts_start: 'Start Date',
	ts_end: 'End Date',
	tt: 'Tracking Tool',
	event_tokens: 'Adjust Event Tokens',
	tracking_url: 'Tracking URL',
}

export const ClickReportBlockReasonTypes = {
	sbp: 'Subpub Block',
	om: 'OS Mismatch',
	cm: 'Country Mismatch',
	iq: 'IP Quality',
	sb: 'Source Block',
	ub: 'User Block',
	ca: 'Corrupted Advertising Id',
	am: 'App Version Mismatch',
	cs: 'Click Spamming',
	ci: 'Click Injection',
	cr: 'Conversion Rate',
	di: 'Duplicated IP',
	ei: 'High Install to Event Conversion Rate',
	hcs: 'High Conversion Speed',
	hcr: 'High click-to-install Conversion Rate',
	im: 'IP Mismatch',
	ipe: 'IP Entropy',
	mirt: 'MIRT',
	ctit: 'CTIT Anomaly',
	ads: 'Click Stuffing',
	ov: 'OS Mismatch',
	sv: 'SDK Version Mismatch',
	ic: 'Invalid Click',
	ir: 'IP Reputation',
	od: 'OS Version Distribution',
	idt: 'Invalid Device Trace',
}

export const EventCategories = [
	{
		label: 'Standard Event Types',
		value: 'Standard Event Types',
		className: 'event-category-title',
		props: {
			onClick: () => false,
		},
	},
	{
		label: 'Login',
		value: 'Login',
		color: '#230060'
	},
	{
		label: 'Complete registration',
		value: 'Complete registration',
		color: '#F3B071'
	},
	{
		label: 'Purchase',
		value: 'Purchase',
		color: '#55C4C2'
	},
	{
		label: 'Search',
		value: 'Search',
		color: '#5C6BC0'
	},
	{
		label: 'Add to Wishlist',
		value: 'Add to Wishlist',
		color: '#000000'
	},
	{
		label: 'Ad view',
		value: 'Ad view',
		color: '#B271F3'
	},
	{
		label: 'Ad click',
		value: 'Ad click',
		color: '#F371AF'
	},
	{
		label: 'Add to Cart',
		value: 'Add to Cart',
		color: '#F37171'
	},
	{
		label: 'Add payment info',
		value: 'Add payment info',
		color: '#D9F371'
	},
	{
		label: 'Level complete',
		value: 'Level complete',
		color: '#71DCF3'
	},
	{
		label: 'Initiated checkout',
		value: 'Initiated checkout',
		color: '#6D7782'
	},
	{
		label: 'Content view',
		value: 'Content view',
		color: '#B9CDE1'
	},
	{
		label: 'List view',
		value: 'List view',
		color: '#230060'
	},
	{
		label: 'Share',
		value: 'Share',
		color: '#C05C6E'
	},
	{
		label: 'Invite',
		value: 'Invite',
		color: '#C455AC'
	},
	{
		label: 'Subscribe',
		value: 'Subscribe',
		color: '#FF8A00'
	},
	{
		label: 'Start trial',
		value: 'Start trial',
		color: '#F3D671'
	},
	{
		label: 'Gaming',
		value: 'Gaming',
		className: 'event-category-title',
		props: {
			onClick: () => false,
		},
	},
	{
		label: 'Reward unlocked',
		value: 'Reward unlocked',
		color: '#230060'
	},
	{
		label: 'Level start',
		value: 'Level start',
		color: '#F3B071'
	},
	{
		label: 'Level achieved',
		value: 'Level achieved',
		color: '#55C4C2'
	},
	{
		label: 'Bonus Claimed',
		value: 'Bonus Claimed',
		color: '#5C6BC0'
	},
	{
		label: 'eCommerce',
		value: 'eCommerce',
		className: 'event-category-title',
		props: {
			onClick: () => false,
		},
	},
	{
		label: 'View Product',
		value: 'View Product',
		color: '#000000'
	},
	{
		label: 'Remove From Cart',
		value: 'Remove From Cart',
		color: '#B271F3'
	},
	{
		label: 'First Purchase',
		value: 'First Purchase',
		color: '#F371AF'
	},
	{
		label: 'Entertainment - Streaming',
		value: 'Entertainment - Streaming',
		className: 'event-category-title',
		props: {
			onClick: () => false,
		},
	},
	{
		label: 'Media Play',
		value: 'Media Play',
		color: '#F37171'
	},
	{
		label: 'Minutes Consumed',
		value: 'Minutes Consumed',
		color: '#D9F371'
	},
	{
		label: 'Media Downloaded',
		value: 'Media Downloaded',
		color: '#71DCF3'
	},
	{
		label: 'Media Saved',
		value: 'Media Saved',
		color: '#6D7782'
	},
	{
		label: 'Fintech',
		value: 'Fintech',
		className: 'event-category-title',
		props: {
			onClick: () => false,
		},
	},
	{
		label: 'Registration submitted',
		value: 'Registration submitted',
		color: '#B9CDE1'
	},
	{
		label: 'Fill Loan Form',
		value: 'Fill Loan Form',
		color: '#230060'
	},
	{
		label: 'Submit Loan Application',
		value: 'Submit Loan Application',
		color: '#C05C6E'
	},
	{
		label: 'Loan Accepted',
		value: 'Loan Accepted',
		color: '#C455AC'
	},
	{
		label: 'Loan Rejected',
		value: 'Loan Rejected',
		color: '#FF8A00'
	},
	{
		label: 'Payment',
		value: 'Payment',
		color: '#F3D671'
	},
	{
		label: 'Payment Completed',
		value: 'Payment Completed',
		color: '#230060'
	},
	{
		label: 'Navigation - Ride Hailing Apps',
		value: 'Navigation - Ride Hailing Apps',
		className: 'event-category-title',
		props: {
			onClick: () => false,
		},
	},
	{
		label: 'Ride booking requested',
		value: 'Ride booking requested',
		color: '#F3B071'
	},
	{
		label: 'Ride booking confirmed',
		value: 'Ride booking confirmed',
		color: '#55C4C2'
	},
	{
		label: 'Ride booking cancelled',
		value: 'Ride booking cancelled',
		color: '#5C6BC0'
	},
	{
		label: 'Ride booking completed',
		value: 'Ride booking completed',
		color: '#000000'
	},
	{
		label: 'Share ride details',
		value: 'Share ride details',
		color: '#B271F3'
	},
	{
		label: 'Spent credits',
		value: 'Spent credits',
		color: '#F371AF'
	},
	{
		label: 'Travel ',
		value: 'Travel ',
		className: 'event-category-title',
		props: {
			onClick: () => false,
		},
	},
	{
		label: 'Travel booking',
		value: 'Travel booking',
		color: '#F37171'
	},
]

export const ReportTypes = [{
	label: 'Impression',
	value: 'impression',
}, {
	label: 'Click',
	value: 'click',
}, {
	label: 'Install',
	value: 'install',
}, {
	label: 'Event',
	value: 'event',
}, {
	label: 'Summary',
	value: 'summary',
}];

export const ReportTypeNameMap = {
	click: 'Click Reports',
	install: 'Install Reports',
	event: 'Event Reports',
	'deduction': 'Deduction Report',
};

export const EmptyStatsObjects = {
	click: {
		total: 0,
		rejected: 0,
		flagged: 0,
		clean: 0,
	},
	install: {
		total: 0,
		rejected: 0,
		flagged: 0,
		clean: 0,
	},
	event: {
		total: 0,
		rejected: 0,
		rejected_amount: 0,
		rejected_reattributed_amount: 0,
		rejected_reattributed_event_count: 0,
		flagged: 0,
		flagged_amount: 0,
		flagged_reattributed_amount: 0,
		flagged_reattributed_event_count: 0,
		clean: 0,
		clean_amount: 0,
		clean_reattributed_amount: 0,
		clean_reattributed_event_count: 0,
		unique: 0,
	}
}

export const ListTypes = [
	{
		label: 'Publishers',
		value: 'publishers',
	},
	{
		label: 'Apps',
		value: 'apps',
	}
];

export const campaignSortOptions = [
	{
		label: 'Sort By Total',
		props: {
			style: {
				fontWeight: 600,
				pointerEvents: 'none',
			}
		},
	},
	{
		label: 'Click',
		value: 'click_total',
		displayName: 'Total Click',
		props: {
			style: {
				paddingLeft: 30,
			}
		},
	},
	{
		label: 'Install',
		value: 'install_total',
		displayName: 'Total Install',
		props: {
			style: {
				paddingLeft: 30,
			}
		},
	},
	{
		label: 'Event',
		value: 'event_total',
		displayName: 'Total Event',
		props: {
			style: {
				paddingLeft: 30,
			}
		},
	},
	{
		label: 'Sort By Performance',
		props: {
			style: {
				fontWeight: 600,
				pointerEvents: 'none',
			}
		},
	},
	{
		label: 'Click',
		value: 'click_performance',
		displayName: 'Click Performance',
		props: {
			style: {
				paddingLeft: 30,
			}
		},
	},
	{
		label: 'Install',
		value: 'install_performance',
		displayName: 'Install Performance',
		props: {
			style: {
				paddingLeft: 30,
			}
		},
	},
	{
		label: 'Event',
		value: 'event_performance',
		displayName: 'Event Performance',
		props: {
			style: {
				paddingLeft: 30,
			}
		},
	},
];