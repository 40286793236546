import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import get from 'lodash/get';
import {
	Loader,
	NoData,
	Icon,
	Alert,
	PageTitle,
	Breadcrumbs,
	Button,
	Info,
	AppIcon,
} from 'interceptd-ui';

import StaticPie from './StaticPie';

import Api from '../../services/api';
import Local from '../../services/localStorage';

import { isStoreURL, isShadow } from '../../utils/misc';

import './styles/Detail.css';

import withTitle from '../withTitle';

const Redirection = props => {
	const [open, setOpen] = React.useState(false);
	const pathsRef = React.useRef();

	React.useEffect(() => {
		if (pathsRef.current)
			pathsRef.current.scrollTop = pathsRef.current.scrollHeight
	});

	return (
		<div className={`redirection ${open ? 'open' : ''}`}>
			<div className="redirection-info">
				<div className="redirection-column">
					<div className="redirection-column-title">via Partner</div>
					<div className="redirection-column-content">{props.data.partner}</div>
				</div>
				<div className="redirection-column">
					<div className="redirection-column-title">Redirection Count</div>
					<div className="redirection-column-content">{props.data.redirection_count}</div>
				</div>
				<div className="redirection-column">
					<div className="redirection-toggle" onClick={() => setOpen(!open)}>
						<span>Redirection Path</span>
						<Icon i="chevron-down" size={18} />
					</div>
				</div>
			</div>
			<div className="redirection-paths" ref={pathsRef}>
				{props.data.redirections.slice().reverse().map((path, index, array) => (
					<div key={index} className="redirection-path">
						<div>{path}</div>
						{index === array.length - 1 &&
							<span>Your campaign startes here</span>
						}
					</div>
				))}
			</div>
		</div>
	)
}

class Detail extends React.Component {
	state = {
		fetching: true,
		error: false,
		data: {},
		app: null,
	}

	componentDidMount = () => {
		this.fetchData();
	}

	fetchData = () => {
		const { match } = this.props;

		Api.getRedirectionReport(match.params.id)
			.then(response => {
				this.setState({
					error: false,
					data: response.data.data,
				}, this.fetchApp);
			})
			.catch(({ response }) => {
				this.setState({
					fetching: false,
					error: true,
				});
				toast.error('Unable to fetch redirection report', { autoClose: 5000 });
			});
	}

	fetchApp = () => {
		const { data } = this.state;

		if (!data || (!data.package_name && (!data.campaigns || data.campaigns.length === 0))) {
			this.setState({
				fetching: false,
				error: false,
			})
			return false;
		}

		const store_url = data.package_name || data.campaigns[0].store_url;
		const getType = str => (str.startsWith('id') && str.length >= 11 && !isNaN(str.replace('id', ''))) || (!isNaN(str) && str.length >= 9) ? 'ios' : 'android';
		const type = getType(store_url);

		const url = (store_url.indexOf('itunes.apple') > -1 || store_url.indexOf('play.google') > -1) ? store_url :
			type === 'ios' ? `https://itunes.apple.com/app/${!isNaN(store_url) ? 'id' : ''}${store_url}` :
			type === 'android' ? `https://play.google.com/store/apps/details?id=${store_url}` :
			isStoreURL(store_url) ? store_url : null;

		Api.getAppInfo({ url: url })
			.then(response => {
				this.setState({
					fetching: false,
					error: false,
					app: response.data,
				}, this.getFraudScore);
			})
			.catch(({ response }) => {
				this.setState({
					fetching: false,
					error: true,
				});
				toast.error('Unable to fetch the app info', { autoClose: 5000 });
			});
	}

	getFraudScore = () => {
		const { app } = this.state;

		const me = Local.getItem('me');

		Api.getSurveyResult(app.id, me.id)
			.then((response) => {
				const score = get(response, 'data.data.fraud_score', null);
				let fraudScore = '-';

				if (score < 5) fraudScore = 'Low';
				if (score >= 5 && score <= 10) fraudScore = 'Medium';
				if (score > 10 && score <= 20) fraudScore = 'High';
				if (score > 20) fraudScore = 'Extremely High';

				this.setState({
					fraudScore,
					fraudScorePercent: `%${(score < 3 ? 3 : score) * 4}`,
				});
			});
	}

	render() {
		const { match } = this.props;
		const {
			fetching,
			error,
			data,
			fraudScore,
			fraudScorePercent,
			app
		} = this.state;

		return (
			<div className="redirection-report-detail">
				<PageTitle>
					<Breadcrumbs>
						<Breadcrumbs.Crumb><Link to="/fraud-insights">Fraud Health Assessment</Link></Breadcrumbs.Crumb>
						<Breadcrumbs.Crumb><Link to={match.url}>Fraud Assessment</Link></Breadcrumbs.Crumb>
					</Breadcrumbs>
					<PageTitle.Title>
						Fraud Assessment - <AppIcon app={app || {}} showStore={data.campaigns && data.campaigns.length > 1 && (data.campaigns.every(c => /apple.com/gi.test(c.store_url)) || data.campaigns.every(c => /google.com/gi.test(c.store_url)))} isShadow={isShadow()} /> {data.app_name}
					</PageTitle.Title>
				</PageTitle>

				{fetching ? (
					<Loader />
				) : error || !data ? (
					<NoData icon="git-pull-request">
						Report not found
					</NoData>
				) : (
					<React.Fragment>
						<div className="redirection-report-summary">
							<div className="redirection-report-statboxes">
								<div className="redirection-report-statbox">
									<div className="redirection-report-statbox-title">
										<span>Fraud Risk</span>
										<Info position="right">Your risk level in regards to being susceptible to Ad Fraud. This result is based of your App Fraud Health survey’s answers.</Info>
									</div>
									<div className={`redirection-report-statbox-total redirection-report-statbox-fraud-score ${(fraudScore || '-').replace(/ /g, '-').toLowerCase()}`} style={{ minWidth: 170 }}>{fraudScore || 'Unknown'} {fraudScorePercent}</div>
								</div>
								<div className="redirection-report-statbox">
									<div className="redirection-report-statbox-title">
										<span>Status</span>
										{data.status === 'pending' && <Info position="left">This result is still being calculated. When your Fraud Health Assessment results are complete, we will email you.</Info>}
									</div>
									<div className={`redirection-report-statbox-total redirection-report-statbox-${data.status || 'approved'}`}>
										{data.status || 'approved'}
									</div>
								</div>
								<div className="redirection-report-statbox">
									<div className="redirection-report-statbox-title">Unique Campaigns</div>
									<div className="redirection-report-statbox-total">
										{data.status !== 'pending' ? data.unique_campaign || <Icon i="alert-circle" size={18} tooltip="No campaign found." /> : <Icon i="clock" size={18} />}
									</div>
								</div>
								<div className="redirection-report-statbox">
									<div className="redirection-report-statbox-title">
										<span>Redirection (Avr)</span>
										<Info position="left">The average number of times a user is redirected (a.k.a campaign is brokered) between first ad click, to landing upon the app’s marketplace. A redirection average of 1-3 is good, 4 and above is concerning.</Info>
									</div>
									<div className="redirection-report-statbox-total">
										{data.status !== 'pending' ? data.redirection_avg || <Icon i="alert-circle" size={18} tooltip="No campaign found." /> : <Icon i="clock" size={18} />}
									</div>
								</div>
								<div className="redirection-report-statbox">
									<div className="redirection-report-statbox-title">
										<span>Redirection (Max)</span>
										<Info position="left">
											The maximum number of redirections of any campaign. Generally, the maximum should not exceed 3 (or 4 for transparent affiliate advertising).
										</Info>
									</div>
									<div className="redirection-report-statbox-total">
										{data.status !== 'pending' ? data.redirection_max || <Icon i="alert-circle" size={18} tooltip="No campaign found." /> : <Icon i="clock" size={18} />}
									</div>
								</div>
								<div className="redirection-report-statbox">
									<div className="redirection-report-statbox-title">
										<span>CPI (Avr)</span>
										<Info position="left">The average Cost Per Install observed among all campaigns.</Info>
									</div>
									<div className="redirection-report-statbox-total">
										{data.status !== 'pending' ? data.cpi_avg ? data.cpi_avg.toFixed(2) : <Icon i="alert-circle" size={18} tooltip="No campaign found." /> : <Icon i="clock" size={18} />}
									</div>
								</div>
								<div className="redirection-report-statbox">
									<div className="redirection-report-statbox-title">
										<span>CPI (Max)</span>
										<Info position="left">The maximum Cost Per Install observed among all campaigns.</Info>
									</div>
									<div className="redirection-report-statbox-total">
										{data.status !== 'pending' ? data.cpi_max ? data.cpi_max.toFixed(2) : <Icon i="alert-circle" size={18} tooltip="No campaign found." /> : <Icon i="clock" size={18} />}
									</div>
								</div>
								<div className="redirection-report-statbox">
									<div className="redirection-report-statbox-title">
										<span>CPI (Min)</span>
										<Info position="left">The minimum Cost Per Install observed among all campaigns.</Info>
									</div>
									<div className="redirection-report-statbox-total">
										{data.status !== 'pending' ? data.cpi_min ? data.cpi_min.toFixed(2) : <Icon i="alert-circle" size={18} tooltip="No campaign found." /> : <Icon i="clock" size={18} />}
									</div>
								</div>
								<div className="redirection-report-statbox">
									<div className="redirection-report-statbox-title">
										<span>CPI (Max - Min)</span>
										<Info position="left">The range between maximum to minimum CPI.</Info>
									</div>
									<div className="redirection-report-statbox-total">
										{data.status !== 'pending' ? data.cpi_max_min ? data.cpi_max_min.toFixed(2) : <Icon i="alert-circle" size={18} tooltip="No campaign found." /> : <Icon i="clock" size={18} />}
									</div>
								</div>
							</div>
						</div>

						{data && data.status === 'pending' && <div className="warn-wrapper">
							<Alert type="warning" title="Notice">Your results are still being calculated. When your Fraud Health Assessment results are complete, we will email you.</Alert>
						</div>}

						{data && data.status !== 'pending' && <div className="warn-wrapper">
							<Alert type="cta" showTitle={false} target="_blank" button={<Button component="a" target="_blank" href="https://calendly.com/interceptd-demo/15min?utm_source=app-index&utm_medium=banner-cta&utm_campaign=fraud-insights">Schedule a Call</Button>}>Get deeper insights about your app. Start a 30-ish minute conversation with one of our Mobile Ad Fraud consultants.</Alert>
						</div>}

						<div className="redirection-wrapper">
							{data.campaigns && (
								(data.campaigns.every(c => /apple.com/gi.test(c.store_url)) || data.campaigns.every(c => /google.com/gi.test(c.store_url))) ? (
									<div className="redirection-report-content">
										<h4>Redirections</h4>
										<div className="redirections">
											{data.campaigns.length > 0 ? data.campaigns.map((redirection, index) => (
												<Redirection key={index} data={redirection} />
											)) : (
													<NoData icon="git-pull-request">
														No redirection found
												</NoData>
												)}
										</div>
									</div>
								) : (
										<React.Fragment>
											<div className="redirection-report-content">
												<h4>App Store Redirections</h4>
												<div className="redirections">
													{data.campaigns.length > 0 ? data.campaigns.filter(c => /apple.com/gi.test(c.store_url)).map((redirection, index) => (
														<Redirection key={index} data={redirection} />
													)) : (
															<NoData icon="git-pull-request">
																No redirection found
													</NoData>
														)}
												</div>
											</div>
											<div className="redirection-report-content">
												<h4>Playstore Redirections</h4>
												<div className="redirections">
													{data.campaigns.length > 0 ? data.campaigns.filter(c => /google.com/gi.test(c.store_url)).map((redirection, index) => (
														<Redirection key={index} data={redirection} />
													)) : (
															<NoData icon="git-pull-request">
																No redirection found
													</NoData>
														)}
												</div>
											</div>
										</React.Fragment>
									)
							)}

							<StaticPie category={app && app.category} wrapperClass={data.campaigns ? '' : 'pending-page'} status={data.status} />
						</div>

					</React.Fragment>
				)}
			</div>
		)
	}
}

export default withTitle(Detail, 'Fraud Health Assessment', 'id');
