import React from 'react';
import { Route, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import uniq from 'lodash/uniq';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import {
	Loader,
	PageTitle,
	Breadcrumbs,
	StepsNav,
	Button,
	AppIcon,
} from 'interceptd-ui';

import { default as Step1 } from './Step1';
import { default as Step2 } from './Step2';
import { default as Step3 } from './Step3';
import { default as Step4 } from './Step4';

import { CampaignTypes, TrackingTools } from '../../constants/Campaign';

import Api from '../../services/api';
import history from '../../services/history';
import Settings from '../../services/settings';

import { checkIsFreeAccount, shadowText, isShadow } from '../../utils/misc';

import './styles/Create.css';

import withTitle from '../withTitle';

const ViewRoute = ({
	component: Component,
	path,
	exact,
	...rest
}) => (
	<Route path={path} exact={exact} render={props => {
		return <Component {...props} {...rest} />
	}} />
);

const Steps = [
	{
		to: '',
		text: 'Start',
	},
	{
		to: 'detail',
		text: 'Campaign Detail',
	},
	{
		to: 'sources',
		text: 'Source Detail',
	},
	{
		to: 'summary',
		text: 'Summary',
	},
]

class Create extends React.Component {
	state = {
		editing: false,
		fetchingCampaign: true,

		creating: false,
		changed: false,
		seenFirstStep: false,
		campaign: null,
		form: {
			target: 'MOBILE',
			landing_url: '',
			app: null,
			app_name: '',
			name: '',
			type: CampaignTypes[0].value,
			tt: TrackingTools[0].value,
			tracking_url: '',
			country: [],
			target_os_version: '',
			target_app_version: '',
			target_sdk_version: '',
			event_tokens: [],

			// will be removed from backend
			traffic_type: 'INCENT',
			device_type: 'ALL',
		},
		tt: TrackingTools[0],
		app: null,
		fullStoreUrl: '',

		partners: [],
		publishers: [],
		sources: [
			{
				publisher: null,
				name: '',
				payout_currency: 'USD',
				bid: 1,
				event_payouts: [
					{
						name: 'Purchase',
						bid: 1,
					},
				],
				redirect_url: '',
				impression_redirect_url: '',
				disable_install_postback: false,
				unique_events_to_postback: false,
				selected_events: [],
			}
		],

		sourceSettings: Settings.get('sourceSettings', 'account') || {},
	}

	componentDidMount = () => {
		const { location } = this.props;

		if ( location.state && location.state.clone ) {
			this.mergeCampaign(location.state.clone, true)
		} else if ( location.pathname.indexOf('/edit') !== -1 ) {
			this.setState({
				editing: true,
			}, this.getCampaign)
		}

		checkIsFreeAccount();
		this.getPartners();
	}

	getCampaign = () => {
		const { match: { params: { id } } } = this.props;

		this.setState({ fetchingCampaign: true })
		Api.getLeoparCampaigns({ id })
			.then(response => {
				const [ data ] = response.data.data;

				this.mergeCampaign(data)
			})
			.catch(({ response }) => {
				toast.error(`Couldn't fetch campaign ${id}`);
			});
	}

	mergeCampaign = (data, clone) => {
		const { sourceSettings } = this.state;
		const { location } = this.props;
		const params = new URLSearchParams(location.search);
		const mode = params.get('mode');

		this.setState(prevState => {
			const sources = data.sources ? data.sources.map(source => ({
				created: clone ? undefined : true,
				original: clone ? undefined : source,
				publisher: source.partner,
				name: source.name,
				payout_currency: sourceSettings?.[source.id]?.payout_currency ?? 'USD',
				bid: source.bid || 1,
				event_payouts: Object.keys(source.event_payouts || {}).map(event => ({
					name: event,
					bid: source.event_payouts[event],
				})),
				redirect_url: source.redirect_url,
				impression_redirect_url: source.impression_redirect_url,
				disable_install_postback: source.disable_install_postback,
				disable_reattribution: source.disable_reattribution,
				unique_events_to_postback: Array.isArray(source.unique_events_to_postback) && source.unique_events_to_postback.length > 0,
				selected_events: source.selected_events || [],
			})) : prevState.sources;

			if ( mode === 'add' ) {
				sources.push({
					publisher: null,
					name: '',
					payout_currency: 'USD',
					bid: 1,
					event_payouts: [
						{
							name: 'Purchase',
							bid: 1,
						},
					],
					redirect_url: '',
					impression_redirect_url: '',
					disable_install_postback: false,
					unique_events_to_postback: false,
					selected_events: [],
				})
			}

			return ({
				fetchingCampaign: false,
				campaign: data,
				form: {
					...prevState.form,
					target: data.target,
					landing_url: data.landing_url,
					app: data.app,
					app_name: data.app_name,
					name: data.name,
					type: data.type,
					tt: data.tt,
					tracking_url: data.tracking_url,
					country: data.country || [],
					target_os_version: data.target_os_version || get(Settings.get('appSettings', 'account'), `${data.app.id}.target_os_version`, ''),
					target_app_version: data.target_app_version || get(Settings.get('appSettings', 'account'), `${data.app.id}.target_app_version`, ''),
					target_sdk_version: data.target_sdk_version || get(Settings.get('appSettings', 'account'), `${data.app.id}.target_sdk_version`, ''),
					event_tokens: data.event_tokens || [],

					// will be removed from backend
					traffic_type: data.traffic_type || prevState.form.traffic_type,
					device_type: data.device_type || prevState.form.device_type,
				},
				tt: TrackingTools.filter(tt => tt.value === data.tt)[0],
				app: data?.app_info || {},
				fullStoreUrl: data?.app_info?.websafe_url,
				sources,
			})
		})
	}

	getPartners = () => {
		Api.getPartners({
			page: 1,
			size: 1000,
		})
			.then(response => {
				this.setState(prevState => ({
					partners: sortBy(response.data.data, ['name']),
					sources: prevState.sources.map(source => ({
						...source,
						publisher: source.publisher || null,
						name: source.name !== '' ? source.name : '',
					}))
				}));
			})
			.catch(({ response }) => {
				toast.error(`Couldn't get partners`);
			})

		Api.getPublishers({
			page: 1,
			size: 1000,
		})
			.then(response => {
				this.setState({
					publishers: response.data.data,
				});
			})
			.catch(({ response }) => {
				toast.error(`Couldn't get publishers`);
			})
	}

	setSeenFirstStep = () => {
		this.setState({
			seenFirstStep: true,
		})
	}

	setAppData = (app) => {
		this.setState(prevState => ({
			app: app,
			fullStoreUrl: app.websafe_url,
			form: {
				...prevState.form,
				app: app.id,
				app_name: app.title,
				name: prevState.form.name === '' || prevState.form.name === `${prevState.form.app_name} Campaign` ? `${app.title} Campaign` : prevState.form.name,
				target_os_version: get(Settings.get('appSettings', 'account'), `${app.id}.target_os_version`, app.target_os_version || prevState.form.target_os_version),
				target_app_version: get(Settings.get('appSettings', 'account'), `${app.id}.target_app_version`, app.target_app_version || prevState.form.target_app_version),
				target_sdk_version: get(Settings.get('appSettings', 'account'), `${app.id}.target_sdk_version`, prevState.form.target_sdk_version),
			}
		}));


	}

	handleTypeChange = e => {
		this.setState(({ form, partners, sources }) => ({
			changed: true,
			form: {
				...form,
				type: e.target.value,
			},
			sources: sources.map(source => {
				const filteredPartners = partners.filter(p => e.target.value !== 'CPM' || p.type === 'programmatic');
				const publisher = filteredPartners.some(p => p.id === source.publisher) ? source.publisher : null;
				return ({
					...source,
					publisher: source.publisher === publisher ? source.publisher : publisher.id,
					name: source.publisher === publisher ? source.name : publisher.name,
				})
			})
		}))
	}

	handleCountriesChange = country => {
		this.setState(prevState => ({
			changed: true,
			form: {
				...prevState.form,
				country: uniq(country),
			}
		}))
	}

	handleInputChange = event => {
		const { name, value } = event.target;

		this.setState(prevState => ({
			changed: true,
			form: {
				...prevState.form,
				[name]: value
			}
		}))
	}

	handleTrackingToolChange = tt => {
		this.setState(prevState => ({
			changed: true,
			tt,
			form: {
				...prevState.form,
				tt: tt.value
			}
		}))
	}

	handleEventTokensChange = value => {
		this.setState(prevState => ({
			changed: true,
			form: {
				...prevState.form,
				event_tokens: value.map(v => v.replace(/ /g, ''))
			}
		}))
	}

	handleSourceAdd = () => {
		this.setState(prevState => ({
			changed: true,
			sources: [
				...prevState.sources,
				{
					publisher: null,
					name: '',
					payout_currency: 'USD',
					bid: 1,
					event_payouts: [
						{
							name: 'Purchase',
							bid: 1,
						},
					],
					redirect_url: '',
					impression_redirect_url: '',
					disable_install_postback: false,
					disable_reattribution: false,
					selected_events: [],
				}
			]
		}));
	}

	handleSourceRemove = (index) => {
		this.setState(prevState => ({
			changed: true,
			sources: prevState.sources.filter((_, i) => i !== index)
		}));
	}

	handleSourcePublisherChange = (index, value) => {
		this.setState(prevState => ({
			changed: true,
			sources: prevState.sources.map((source, i) => i === index ? ({
				...source,
				publisher: value,
				name: shadowText((prevState.partners.find(p => p.id === value) || {}).name || '', 'Example Source'),
			}) : source)
		}));
	}

	handleSourceNameChange = (index, value) => {
		this.setState(prevState => ({
			changed: true,
			sources: prevState.sources.map((source, i) => i === index ? ({
				...source,
				name: value
			}) : source)
		}));
	}

	handleSourcePayoutCurrencyChange = (index, value) => {
		this.setState(prevState => ({
			changed: true,
			sources: prevState.sources.map((source, i) => i === index ? ({
				...source,
				payout_currency: value
			}) : source)
		}));
	}

	handleSourcePayoutChange = (index, value) => {
		this.setState(prevState => ({
			changed: true,
			sources: prevState.sources.map((source, i) => i === index ? ({
				...source,
				bid: value
			}) : source)
		}));
	}

	handleSourceEventAdd = (index) => {
		this.setState(prevState => ({
			changed: true,
			sources: prevState.sources.map((source, i) => i === index ? ({
				...source,
				event_payouts: [
					...source.event_payouts,
					{
						name: 'Purchase',
						bid: 1,
					},
				]
			}) : source)
		}));
	}

	handleSourceEventRemove = (index, eventIndex) => {
		this.setState(prevState => ({
			changed: true,
			sources: prevState.sources.map((source, i) => i === index ? ({
				...source,
				event_payouts: source.event_payouts.filter((_, i) => i !== eventIndex)
			}) : source)
		}));
	}

	handleSourceEventNameChange = (index, eventIndex, value) => {
		this.setState(prevState => ({
			changed: true,
			sources: prevState.sources.map((source, i) => i === index ? ({
				...source,
				event_payouts: source.event_payouts.map((event, i) => i === eventIndex ? ({
					...event,
					name: value
				}) : event)
			}) : source)
		}));
	}

	handleSourceEventPayoutChange = (index, eventIndex, value) => {
		this.setState(prevState => ({
			changed: true,
			sources: prevState.sources.map((source, i) => i === index ? ({
				...source,
				event_payouts: source.event_payouts.map((event, i) => i === eventIndex ? ({
					...event,
					bid: value
				}) : event)
			}) : source)
		}));
	}

	handleSourceInstallPostbackChange = (index, value) => {
		this.setState(prevState => ({
			changed: true,
			sources: prevState.sources.map((source, i) => i === index ? ({
				...source,
				disable_install_postback: value
			}) : source)
		}));
	}

	onSourceDisableReattributionChange = (index, value) => {
		this.setState(prevState => ({
			changed: true,
			sources: prevState.sources.map((source, i) => i === index ? ({
				...source,
				disable_reattribution: value
			}) : source)
		}));
	}

	handleOnlyUniqEventPostbackChange = (index, value) => {
		this.setState(prevState => ({
			changed: true,
			sources: prevState.sources.map((source, i) => i === index ? ({
				...source,
				unique_events_to_postback: value
			}) : source)
		}));
	}

	handleSourceEventPostbackChange = (index, value) => {
		this.setState(prevState => ({
			changed: true,
			sources: prevState.sources.map((source, i) => i === index ? ({
				...source,
				selected_events: value.filter(v => v && v !== '').map(v => v.trim())
			}) : source)
		}));
	}

	handleSourceTrackingUrlChange = (index, value, type) => {
		this.setState(prevState => ({
			changed: true,
			sources: prevState.sources.map((source, i) => i === index ? ({
				...source,
				[type === 'click' ? 'redirect_url' : 'impression_redirect_url']: value.trim()
			}) : source)
		}));
	}

	handleSourceCopyTrackingUrl = (index, type) => {
		const fieldName = type === 'click' ? 'redirect_url' : 'impression_redirect_url';
		this.setState(prevState => ({
			changed: true,
			sources: prevState.sources.map((source, i) => i === index ? ({
				...source,
				[fieldName]: prevState.sources[index - 1][fieldName]
			}) : source)
		}));
	}

	saveSourceSettings = (source, settings) => {
		Settings.set('sourceSettings', {
			...(Settings.get('sourceSettings', 'account') || {}),
			[source.id]: {
				...((Settings.get('sourceSettings', 'account') || {})?.[source.id] ?? {}),
				...settings,
			}
		}, 'account');
	}

	save = async () => {
		const { location, match: { params: { id } } } = this.props;
		const { editing, campaign: prevCampaign, creating, form, partners, publishers, sources } = this.state;

		if ( creating ) return false;

		this.setState({
			creating: true,
		})

		try {
			let campaignResponse;
			if ( editing ) {
				campaignResponse = await Api.updateCampaign(id, {
					...form,
					app: undefined,
					app_name: undefined,
					target: undefined,
				});
			} else {
				campaignResponse = await Api.createCampaign(form);
			}
			const campaign = editing ? prevCampaign : campaignResponse.data.data;

			for ( const source of sources ) {
				try {
					if ( !source.created ) {
						let publisher = publishers.find(publisher => publisher.partner === source.publisher);

						if ( !publisher ) {
							const partner = partners.find(partner => partner.id === source.publisher);
							if ( partner ) {
								const publisherResponse = await Api.createPublisher({
									partner: partner.id,
									parameters: partner.parameters_schema || [],
									event_mapping: [],
									contact_name: '',
									notes: '',
									is_active: true,
								})
								publisher = publisherResponse.data.data;
							} else {
								toast.error(`Couldn't create publisher ${partner.name}`);
							}
						}

						if ( publisher ) {
							const sourceRes = await Api.addCampaignSource(campaign.id, null, {
								name: source.name !== '' ? source.name : publisher.name,
								publisher: publisher.id,
								partner: publisher.partner,
								bid: source.bid !== '' ? +source.bid : 0,
								event_payouts: (form.type === 'CPA' && source.event_payouts.length > 0) ? source.event_payouts.reduce((acc, cur) => {
									const newAcc = { ...acc };
									newAcc[cur.name] = +cur.bid;
									return newAcc;
								}, {}) : undefined,
								redirect_url: source.redirect_url,
								impression_redirect_url: source.impression_redirect_url,
								disable_install_postback: source.disable_install_postback,
								disable_reattribution: source.disable_reattribution,
								unique_events_to_postback: source.unique_events_to_postback && source.selected_events.length > 0
									? source.selected_events
									: undefined,
								selected_events: source.selected_events,
							})
							const createdSource = sourceRes.data.data;
							this.saveSourceSettings(createdSource, {
								payout_currency: source.payout_currency,
							})
						}
					} else {
						await Api.updateSource(campaign.id, source.original.id, {
							name: source.name,
							bid: +source.bid,
							event_payouts: source.event_payouts.length > 0 ? source.event_payouts.reduce((acc, cur) => {
								const newAcc = { ...acc };
								newAcc[cur.name] = +cur.bid;
								return newAcc;
							}, {}) : undefined,
							redirect_url: source.redirect_url,
							impression_redirect_url: source.impression_redirect_url,
							disable_install_postback: source.disable_install_postback,
							disable_reattribution: source.disable_reattribution,
							unique_events_to_postback: source.unique_events_to_postback && source.selected_events.length > 0
								? source.selected_events
								: undefined,
							selected_events: source.selected_events,

							status: source.original.status,
							check_bot: source.original.check_bot,
							event_mapping: source.original.event_mapping,
						})
						this.saveSourceSettings(source.original, {
							payout_currency: source.payout_currency,
						})
					}
				} catch (e) {
					toast.error(`Couldn't create/update source ${source.name}`);
				}
			}

			toast.success(`Campaign "${form.name}" ${editing ? 'updated' : 'created'}`, { autoClose: 3000 });
			const redirectUrl = editing
				? location?.state?.back ?? '/'
				: `/campaign/${campaign.id}?initialCreate=1`;
			history.push(redirectUrl, {
				campaign: campaign
			});
		} catch (e) {
			toast.error(`Couldn't ${editing ? 'updated' : 'created'} campaign ${form.name}`);
			this.setState({
				creating: false,
			})
		}
	}

	render() {
		const { location, match: { url, params: { id, page } } } = this.props;
		const {
			fetchingCampaign,
			creating,
			seenFirstStep,
			form,
			app,
			fullStoreUrl,
			tt,
			partners,
			sources,
		} = this.state;

		const activeStepIndex = Steps.findIndex(step => (page || '') === step.to);

		const editing = location.pathname.indexOf('/edit') !== -1;
		const params = new URLSearchParams(location.search);
		const sourceCrumb = params.get('source');

		return (
			<div className="campaign-create">
				<div className="campaign-create-header">
					<PageTitle>
						{editing &&
							<Breadcrumbs>
								<Breadcrumbs.Crumb><Link to="/">Campaigns</Link></Breadcrumbs.Crumb>
								<Breadcrumbs.Crumb><Link to={`/campaign/${id}`}>Campaign Detail</Link></Breadcrumbs.Crumb>
								{sourceCrumb && <Breadcrumbs.Crumb><Link to={`/campaign/${id}/source/${sourceCrumb}`}>Source Detail</Link></Breadcrumbs.Crumb>}
								<Breadcrumbs.Crumb><Link to={url}>Campaign Edit</Link></Breadcrumbs.Crumb>
							</Breadcrumbs>
						}
						<div className="campaign-create-header-inner">
							<PageTitle.Title>
								Campaign {editing ? 'Edit' : 'Create'} {app ? '-' : ''} {app && <AppIcon app={app} isShadow={isShadow()} />} {shadowText(form.name, 'Example Campaign')}
							</PageTitle.Title>
							{editing &&
								<div className="campaign-create-buttons">
									<Button
										mini
										bgColor="shade"
										loading={creating}
										onClick={this.save}>
										Save
									</Button>
								</div>
							}
						</div>
					</PageTitle>
					<StepsNav active={activeStepIndex} data-active={activeStepIndex}>
						{setProgressWidth => Steps.map((step, index) => (
							<StepsNav.Step key={`step-${index}`} active={(page || '') === step.to} setProgressWidth={setProgressWidth}>
								<Link to={`${editing ? `/campaign/${id}/edit` : `/create`}${step.to === '' ? '' : '/'}${step.to}`}>{step.text}</Link>
							</StepsNav.Step>
						))}
					</StepsNav>
				</div>

				{editing && fetchingCampaign ? (
					<Loader />
				) : (
					<React.Fragment>
						<ViewRoute
							exact
							path={[
								"/create",
								"/campaign/:id/edit",
							]}
							component={Step1}
							editing={editing}
							form={form}
							app={app}
							setAppData={this.setAppData}
							setSeenFirstStep={this.setSeenFirstStep}
							onTargetChange={this.handleTargetChange}
							onInput={this.handleInputChange} />

						<ViewRoute
							exact
							path={[
								"/create/detail",
								"/campaign/:id/edit/detail",
							]}
							component={Step2}
							seenFirstStep={seenFirstStep}
							editing={editing}
							form={form}
							tt={tt}
							onTypeChange={this.handleTypeChange}
							onCountriesChange={this.handleCountriesChange}
							onInputChange={this.handleInputChange}
							onTrackingToolChange={this.handleTrackingToolChange}
							onEventTokensChange={this.handleEventTokensChange} />

						<ViewRoute
							exact
							path={[
								"/create/sources",
								"/campaign/:id/edit/sources",
							]}
							app={app}
							component={Step3}
							seenFirstStep={seenFirstStep}
							editing={editing}
							form={form}
							partners={partners}
							sources={sources}
							onSourceAdd={this.handleSourceAdd}
							onSourceRemove={this.handleSourceRemove}
							onSourcePublisherChange={this.handleSourcePublisherChange}
							onSourceNameChange={this.handleSourceNameChange}
							onSourcePayoutCurrencyChange={this.handleSourcePayoutCurrencyChange}
							onSourcePayoutChange={this.handleSourcePayoutChange}
							onSourceEventAdd={this.handleSourceEventAdd}
							onSourceEventRemove={this.handleSourceEventRemove}
							onSourceEventNameChange={this.handleSourceEventNameChange}
							onSourceEventPayoutChange={this.handleSourceEventPayoutChange}
							onSourceInstallPostbackChange={this.handleSourceInstallPostbackChange}
							onSourceDisableReattributionChange={this.onSourceDisableReattributionChange}
							onOnlyUniqEventInstallPostbackChange={this.handleOnlyUniqEventPostbackChange}
							onSourceEventPostbackChange={this.handleSourceEventPostbackChange}
							onSourceTrackingUrlChange={this.handleSourceTrackingUrlChange}
							onSourceCopyTrackingUrl={this.handleSourceCopyTrackingUrl} />

						<ViewRoute
							exact
							path={[
								"/create/summary",
								"/campaign/:id/edit/summary",
							]}
							component={Step4}
							seenFirstStep={seenFirstStep}
							editing={editing}
							form={form}
							app={app}
							fullStoreUrl={fullStoreUrl}
							tt={tt}
							partners={partners}
							sources={sources} />
							
						{(page || '') !== Steps[0].to &&
							<div className="campaign-create-buttons">
								<Button
									mini
									disabled={creating}
									bgColor="transparent"
									onClick={() => history.push(`${editing ? `/campaign/${id}/edit` : `/create`}${Steps[activeStepIndex - 1].to === '' ? '' : '/'}${Steps[activeStepIndex - 1].to}`)}>
									Prev
								</Button>
								<Button
									mini
									bgColor="shade"
									loading={creating}
									disabled={activeStepIndex === 2 && sources.some(s => !s.publisher)}
									onClick={() => 
										activeStepIndex === Steps.length - 1 ?
											this.save() :
											history.push(`${editing ? `/campaign/${id}/edit` : `/create`}${Steps[activeStepIndex + 1].to === '' ? '' : '/'}${Steps[activeStepIndex + 1].to}`)
									}>
									{activeStepIndex === Steps.length - 1 ? 'Done' : 'Next'}
								</Button>
							</div>
						}
					</React.Fragment>
				)}
			</div>
		)
	}
}

export default withTitle(Create, null, null, match => {
	const editing = match.url.indexOf('/edit') !== -1;
	return `Interceptd - Campaign ${editing ? 'Edit' : 'Create'} ${editing ? `for ${match.params.id}` : ''}`
});
