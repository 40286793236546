import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import ReactGA from 'react-ga';
import { Button, Input } from 'interceptd-ui';

import LoginSlogan from '../../components/LoginSlogan';

import Auth from '../../services/auth';
import Api from '../../services/api';
import Local from '../../services/localStorage';
import SendEvent from '../../services/events';

import Logo from '../../assets/logo.svg';

import './styles/Login.css';

import withTitle from '../withTitle';

class Login extends React.Component {
	state = {
		email: '',
		password: '',
		validationError: {
			email: null,
			password: null,
		},
		isFetching: false,
		redirect: false,
		to: '/',
	}

	componentDidMount() {
		const { state } = this.props.location;
		Local.setStore({});
		Api.setToken();
		if ( state && state.from && state.from.state ) {
			if ( state.from.state.referrer !== '/verify' ) {
				setTimeout(() => { toast.dismiss() }, 0);
			}
			if ( state.from.state.redirectTo ) {
				this.setState({
					to: state.from.state.redirectTo
				})
			}
		}
	}

	handleSubmit = event => {
		event.preventDefault();
		const { email, password, isFetching } = this.state;

		if (this.checkValidation()) {
			return;
		}

		if (!password || password.length < 6) {
			return toast.error('Password must be at least 6 characters.');
		}

		SendEvent({
			category: 'NonAuth',
			action: 'Login button clicked',
			nonInteraction: false,
		});

		if ( isFetching ) return false;

		this.setState({ isFetching: true });
		Api.login({
			email,
			password
		})
			.then(response => {
				if ( !Auth.isRoleValid(response.data.roles) ) {
					return Promise.reject({
						response: {
							data: {
								error: {
									message: `You don't have required authorization to login`
								}
							}
						}
					})
				}
				Auth.setToken(response.data.token);
				Api.setToken(Auth.getToken());
				Local.setItem('login_as', false);

				Api.getLeoparCampaigns()
					.then(res => {
						if ( res.data.data.filter((c) => c.application_type !== 'leancheck').length === 0 ) {
							this.setState({
								isFetching: false,
								redirect: true,
								to: this.state.to === '/' ? '/create' : this.state.to,
							})
						} else {
							this.setState({
								isFetching: false,
								redirect: true,
							})
						}
					});
			})
			.catch(({ response }) => {
				SendEvent({
					description: 'login on Login',
					fatal: true,
				});

				this.setState({
					isFetching: false,
					error: response,
				});

				if ( response.data.error.code === 'UserNotVerified' ) {
					SendEvent({
						category: 'NonAuth',
						action: 'Login page redirected to verify page',
						nonInteraction: true,
					});
					this.setState({
						redirect: true,
						to: {
							pathname: '/verify',
							state: { email }
						}
					})
				} else {
					const message = response?.data?.error?.message;
					toast.error(typeof message === 'string' ? message : 'Wrong credentials');
				}
			});
	}

	checkValidation = () => {
		const { email, password } = this.state;
		const validationError = {
			email: (email === '' || !this.validateEmail(email)) ? `Email can't be blank` : null,
			password: password === '' ? `Password can't be blank`: null,
		};
		
		this.setState({ validationError });

		return validationError.email || validationError.password;
	}

	validateEmail = value => {
		const rx = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
		return rx.test(value);
	}

	handleUsername = event => {
		this.setState({
			email: event.target.value,
		}, this.checkValidation);
	}

	handlePassword = event => {
		this.setState({
			password: event.target.value
		}, this.checkValidation);
	}

	handleRequestDemo = event => {
		ReactGA.outboundLink({
			label: 'Request a Demo link clicked'
		}, () => {
			window.open('https://interceptd.com/free-consultation/');
		});
	}

	render() {
		const { email, password, validationError, isFetching, redirect, to } = this.state;

		if ( redirect ) return window.location.href = to;

		return (
			<div className="login">
				<div className="login-wrapper">
					<LoginSlogan />
					<div className="login-right">
						<header className="login-header">
							<img src={Logo} alt="Interceptd" />
						</header>
						<form className="login-form" onSubmit={this.handleSubmit}>
							<div className="login-title">
								<h1>Sign In</h1>
								<h2>Real-Time Actions for Pure Quality</h2>
							</div>
							<div className="login-form-inner">
								<Input
									type="email"
									name="email"
									placeholder="john@doe.com"
									label="Email Address"
									value={email}
									error={validationError.email}
									onChange={this.handleUsername} />
								<Input
									type="password"
									name="password"
									label="Password"
									value={password}
									error={validationError.password}
									onChange={this.handlePassword} />
								<Button type="submit" block loading={isFetching}>Sign In</Button>
								<div className="login-button-group">
									<Button onClick={this.handleRequestDemo} block>Request a demo</Button>
									<Button component={Link} to="/signup" block bgColor="transparent">Create an account</Button>
								</div>
								<Link to="/forgot-password" className="forgot-password" data-event-category="NonAuth" data-event-action="Did you forget your password link clicked">Did you forget your password?</Link>
							</div>
						</form>
					</div>
				</div>
			</div>
		)
	}
}

export default withTitle(Login, 'Login');