import React from 'react';
import { Box, NoData } from 'interceptd-ui';

import Table from '../../../components/Table';

export default function SummaryReportDetailTable({
  noData,
  data,
  columns,
  title,
	right,
	hideTitle,
}) {
  return (
    <Box
      className="box-table-wrapper report-list-table"
      title={title}
			right={right}
			headerProps={{
				style: {
					display: hideTitle ? 'none' : 'flex',
				}
			}}
    >
      {noData ? (
        <NoData>There is no data</NoData>
      ) : (
        <Table
          stickyHeader={true}
          defaultSortDesc
          data={data}
          columns={columns}
					showPagination={false}
					pageSize={1000}
          NoDataComponent={() =>
            <NoData icon="file-minus">There is no data for this report</NoData>
          } />
      )}
    </Box>
  );
}
