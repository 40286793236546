import React from 'react';
import findIndex from 'lodash/findIndex';
import {
	Icon,
	Button,
	Input,
	Textarea,
} from 'interceptd-ui';

import Grouped from '../../components/Grouped';
import TrackingUrlArea from '../../components/Input/TrackingUrlArea';

import './styles/PartnerBox.css';

class PartnerBox extends React.Component {
	state = {
		showInfo: false,
		publisher: {
			partner: null,
			parameters: [],
			event_mapping: [],
			operational_account_managers: [''],
			notes: '',
			is_active: true,
		}
	}

	partnerBox = React.createRef()

	UNSAFE_componentWillMount = () => {
		this.setState(this.buildState);
	}

	buildState = () => {
		const { partner, publisher } = this.props;

		return ({
			publisher: {
				partner: partner.id,
				parameters: partner.parameters_schema ? partner.parameters_schema.map(param => ({
					...param,
					value: (publisher && publisher.parameters && publisher.parameters.filter(p => p.name === param.name)[0]) ? publisher.parameters.filter(p => p.name === param.name)[0].value : (param.default || ''),
				})) : [],
				event_mapping: publisher ? publisher.event_mapping || [] : [],
				operational_account_managers: Array.isArray(publisher?.operational_account_managers)
					? publisher.operational_account_managers
					: [''],
				notes: publisher ? publisher.notes : '',
				is_active: publisher ? Boolean(publisher.is_active) : true,
			}
		})
	}

	handleParameter = event => {
		const { name, value } = event.target;
		this.setState(prevState => {
			const { parameters } = prevState.publisher;
			const newParameters = [...parameters];
			const index = findIndex(newParameters, ['name', name]);
			newParameters[index].value = value;
			return ({
				publisher: {
					...prevState.publisher,
					parameters: newParameters
				}
			})
		})
	}

	handleInput = event => {
		const { name, value } = event.target;
		this.setState(prevState => ({
			publisher: {
				...prevState.publisher,
				[name]: value,
			}
		}))
	}

	handleAccountManager = ({ target: { value } }, index) => {
		this.setState((prevState) => {
			prevState.publisher.operational_account_managers[index] = value;
			return prevState;
		});
	}

	removeAccountManager = (index) => {
		this.setState((prevState) => {
			prevState.publisher.operational_account_managers.splice(index, 1);
			return prevState;
		});
	}

	addAccountManager = () => {
		this.setState((prevState) => {
			const managers = prevState.publisher.operational_account_managers || [];
			managers.push('');
			return ({
				publisher: {
					...prevState.publisher,
					operational_account_managers: managers,
				}
			})
		})
	}

	handleCheckbox = event => {
		const { name, checked } = event.target;

		this.setState(prevState => ({
			publisher: {
				...prevState.publisher,
				[name]: checked,
			}
		}))
	}

	handleEventMappingAdd = () => {
		this.setState(prevState => ({
			publisher: {
				...prevState.publisher,
				event_mapping: [
					...prevState.publisher.event_mapping,
					{ name: '', value: '' },
				]
			}
		}))
	}

	handleEventMappingRemove = index => {
		this.setState(prevState => ({
			publisher: {
				...prevState.publisher,
				event_mapping: prevState.publisher.event_mapping.filter((e, i) => i !== index),
			}
		}))
	}

	handleEventMappingInput = (event, index) => {
		const { name, value } = event.target;

		this.setState(prevState => ({
			publisher: {
				...prevState.publisher,
				event_mapping: prevState.publisher.event_mapping.map((event_map, i) => {
					if (i === index)
						return { ...event_map, [name.replace('event-', '')]: String(value).trim() }

					return event_map;
				}),
			}
		}))
	}

	toggleInfo = event => {
		this.setState(prevState => ({
			showInfo: !prevState.showInfo
		}), () => {
			window.scrollTo({
				left: 0,
				top: this.partnerBox.current.offsetTop,
				behavior: 'smooth'
			})
		})
	}

	render() {
		const { saving, onSave, partner, publisher: propPub } = this.props;
		const { showInfo, publisher } = this.state;

		return (
			<div className="partner-detail" ref={this.partnerBox}>
				<div className={`partner-detail-view partner-detail-info ${showInfo ? 'active' : ''}`}>
					<h3>Postback URLs</h3>
					{partner.android_install_postback_url &&
						<TrackingUrlArea
							autosize
							name="android_install_postback_url"
							label="Android Install Postback URL"
							placeholder="https://"
							value={partner.android_install_postback_url}
							readOnly />
					}
					{partner.ios_install_postback_url &&
						<TrackingUrlArea
							autosize
							name="ios_install_postback_url"
							label="iOS Install Postback URL"
							placeholder="https://"
							value={partner.ios_install_postback_url}
							readOnly />
					}
					{partner.android_event_postback_url &&
						<TrackingUrlArea
							autosize
							name="android_event_postback_url"
							label="Android Event Postback URL"
							placeholder="https://"
							value={partner.android_event_postback_url}
							readOnly />
					}
					{partner.ios_event_postback_url &&
						<TrackingUrlArea
							autosize
							name="ios_event_postback_url"
							label="iOS Event Postback URL"
							placeholder="https://"
							value={partner.ios_event_postback_url}
							readOnly />
					}
					{partner.macros.length > 0 &&
						<h3>Macros</h3>
					}
					{partner.macros.length > 0 &&
						<div className="macros">
							<div className="macros-head">
								<span>Interceptd Parameter</span>
								<span>Partner's Macro</span>
								<span>Store</span>
							</div>
							<div className="macros-body">
								{partner.macros.map((macro, index) => (
									<div className="macro" key={index}>
										<span>{macro.key}</span>
										<span>{macro.value}</span>
										<span>{macro.store_type}</span>
									</div>
								))}
							</div>
						</div>
					}
					<div className="partner-footer">
						<span className="partner-info-toggle" onClick={this.toggleInfo}><Icon i="arrow-left-circle" key="arrow-left-circle" /> Back</span>
					</div>
				</div>
				<div className={`partner-detail-view partner-detail-inputs ${!showInfo ? 'active' : ''}`}>
					{publisher.parameters.length !== 0 &&
						<React.Fragment>
							<h3>Parameters</h3>
							{publisher.parameters.map(param => (
								<div className="partner-parameter" key={param.name}>
									<Input
										label={param.name}
										placeholder=""
										name={param.name}
										value={param.value}
										required={param.required}
										onChange={this.handleParameter} />
								</div>
							))}
						</React.Fragment>
					}
					{partner.event_mapping ? (
						<React.Fragment>
							<h3>Event Mapping</h3>
							<div className="event-maps">
								{publisher.event_mapping.map((event_map, index) => (
									<div key={index} className="event-map">
										<span className="event-map-remove" onClick={() => this.handleEventMappingRemove(index)}>
											<Icon i="x-circle" size={20} />
										</span>
										<Grouped>
											<Input
												label="Event Name"
												placeholder="Register"
												name="event-name"
												value={event_map.name}
												onChange={e => this.handleEventMappingInput(e, index)} />
											<Input
												label="Publisher's Value"
												placeholder=""
												name="event-value"
												value={event_map.value}
												onChange={e => this.handleEventMappingInput(e, index)} />
										</Grouped>
									</div>
								))}
								<div className="event-map-add" onClick={this.handleEventMappingAdd}>
									<Icon i="plus" size={16} />
									<span>Add New Map</span>
								</div>
							</div>
						</React.Fragment>
					) : null}
					<h3>Management</h3>


					{publisher.operational_account_managers.map((val, index) => (
						<div className="input-with-minus-operator">
							<Input
								label={`${index + 1} - Account Manager Email`}
								placeholder="mail@domain.com"
								name="operational_account_managers"
								value={publisher.operational_account_managers[index]}
								onChange={(e) => this.handleAccountManager(e, index)}
							/>
							{index > 0 && (
								<span onClick={() => this.removeAccountManager(index)}>
									<Icon i="minus" size="20" />
								</span>
							)}
						</div>
					))}

					<div className="campaign-create-add" onClick={this.addAccountManager}>
						<Icon i="plus" size="12" />
						<span>Add Account Manager</span>
					</div>

					<Textarea
						label="Notes"
						placeholder=""
						name="notes"
						value={publisher.notes}
						onChange={this.handleInput} />
					<div className="partner-footer">
						<span className="partner-info-toggle" onClick={this.toggleInfo}><Icon i="info" key="info" /> Postback Detail</span>
						<Button loading={saving} onClick={() => { onSave(publisher) }}>{propPub ? 'Save' : 'Save & Enable'}</Button>
					</div>
				</div>
			</div>
		)
	}
}

export default PartnerBox;
