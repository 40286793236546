import React from 'react';

import { shadowText } from '../../utils/misc';

import Currencies from '../../constants/currency.json';

export const columns = ({
	renderFraudGraphCell,
	revenueCurrency,
	onExpandedChange,
}) => [
		{
			Header: 'expander',
			Expander: () => null,
			expander: true,
			show: false,
		},
		{
			Header: 'Event Name',
			id: 'eventName',
			accessor: 'eventName',
			cast: 'string',
			sortable: false,
			width: 300,
			style: {
				overflow: 'visible',
			},
			permanent: true,
			Cell: props => (
				<div style={{ display: 'flex', alignItems: 'center' }}>
					{onExpandedChange && <div className={`rt-expander ${props.isExpanded ? '-open' : ''}`} onClick={() => onExpandedChange(props.viewIndex)} style={{ marginRight: 10 }}></div>}
					<span className="campaign-table-name">
						{shadowText(props.value, 'Example KPI')}
					</span>
				</div>
			)
		},
		{
			Header: 'Total Count',
			id: 'kpi_table_count',
			accessor: 'count',
			cast: 'string',
			width: 220,
			Cell: renderFraudGraphCell,
		},
		{
			Header: 'Reattributed Count',
			id: 'kpi_table_rcount',
			accessor: 'rCount',
			cast: 'string',
			width: 220,
			Cell: renderFraudGraphCell,
		},
		{
			Header: `(${Currencies[revenueCurrency].symbol}) Amount`,
			id: 'kpi_table_amount',
			accessor: 'amount',
			cast: 'string',
			width: 220,
			Cell: renderFraudGraphCell,
		},
		{
			Header: `(${Currencies[revenueCurrency].symbol}) Reattributed Amount`,
			id: 'kpi_table_ramount',
			accessor: 'rAmount',
			cast: 'string',
			width: 220,
			Cell: renderFraudGraphCell,
		},
	];