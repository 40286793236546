import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import get from 'lodash/get';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import {
	Loader,
	Button,
	Input,
} from 'interceptd-ui';

import Table from '../../../components/Table';

import SelectedAlarms from './components/SelectedAlarms';
import Rule from './components/Rule';

import Local from '../../../services/localStorage';
import Api from '../../../services/api';

import { rulesetCampaignsColumns } from './RulesetCampaignColumns';

import { RuleInputs } from '../../../constants/Rules';

import RulesetConfirmationModal from '../../Modals/RulesetConfirmationModal';

import './styles/RulesetCreateEdit.css';

export default class RulesetCreateEdit extends Component {
  state = {
    loading: false,
    isSaving: false,
    redirect: false,

    confirmationOpen: false,
    rulesetCampaignsFetching: false,
    rulesetCampaigns: [],
    rulesetCampaignsPages: -1,
    rulesetCampaignsCount: 0,

    isClone: !!get(this.props, 'location.state.ruleset'),
    rulesetId: get(this.props, 'match.params.ruleset_id', null),
    supportedRules: Local.getItem('supported_rules'),
    ruleset: {
      name: '',
      detail: {
        rules: [],
      },
    },
  };

  componentDidMount() {
    this.getRuleset();
    const cloneRuleset = get(this.props, 'location.state.ruleset');
    if (cloneRuleset) {
      this.setState({
        ruleset: {
          name: cloneRuleset.name,
          detail: cloneRuleset.detail,
        },
      });
    }
  }

  getRuleset = () => {
    const { rulesetId } = this.state;

    if (!rulesetId) return;

    this.setState({ loading: true });

    // TODO: Need a new endpoint for fetching single rule set
    Api.getRulesets({})
      .then((response) => {
        const rulesets = get(response, 'data.data', []);
        const ruleset = find(rulesets, ['id', +rulesetId]);
        this.setState({
          ruleset,
          loading: false,
        });
      })
      .catch((err) => {
        toast.error(`Couldn't fetch ruleset.`);
      });
  }

  getRulesetCampaigns = (state, instance) => {
    const { ruleset } = this.state;
    this.setState({ rulesetCampaignsFetching: true })
    Api.getRulesetCampaigns(ruleset.id, {
      page: state.page + 1,
      size: state.pageSize,
    })
      .then(response => {
        if (response.data.data.length === 0) {
          this.handleCloseConfModal();
          this.handleSaveRuleset();
        } else {
          this.setState({
            rulesetCampaignsFetching: false,
            rulesetCampaigns: response.data.data,
            rulesetCampaignsPages: Math.ceil(response.data.page.total / state.pageSize),
            rulesetCampaignsCount: response.data.page.total,
          })
        }
      })
      .catch(({ response }) => {
        this.setState({
          rulesetCampaignsFetching: false
        });
      })
  }

  setUserInputDefaults = (supportedRule) => {
    const userInputs = supportedRule.detail && supportedRule.detail.user_inputs;
    const shortName = supportedRule['short-name'];
    const result = {};

    if (userInputs) {
      Object.keys(userInputs).forEach((input) => {
        const currentInput = userInputs[input];
        const defaultVal = RuleInputs[shortName] && RuleInputs[shortName][input] && RuleInputs[shortName][input].default;
        if (currentInput.type === 'textbox') {
          result[input] = currentInput.default || defaultVal || 0;
        } else if (currentInput.type === 'combobox') {
          result[input] = currentInput.default || defaultVal || (currentInput.values && currentInput.values[0]);
        }
      });
    }

    return result;
  }

  handleBlock = ({ 'short-name': shortName }) => {
    const { ruleset } = this.state;
    ruleset.detail.rules.forEach((rule) => {
      if (rule.alarm && rule.name === shortName) {
        rule.block = !rule.block;
      }
    });
    this.setState({ ruleset });
  }

  handleActivateRule = (on, supportedRule) => {
    const { ruleset } = this.state;
    const index = findIndex(ruleset.detail.rules, ['name', supportedRule['short-name']]);

    if (on && index === -1) {
      const addedRule = {
        alarm: true,
        block: false,
        name: supportedRule['short-name'],
        notify: [],
      };

      if (supportedRule.detail && supportedRule.detail.user_inputs) {
        addedRule.user_inputs = this.setUserInputDefaults(Object.assign({}, supportedRule));
      }

      ruleset.detail.rules.push(addedRule);
    } else if (!on && index !== -1) {
      ruleset.detail.rules.splice(index, 1);
    }
    this.setState({ ruleset });
  }

  handleValueChange = (event, { ...rule }) => {
    const { ruleset } = this.state;
    const { type, name, value, checked } = event.target;
    const rules = [...ruleset.detail.rules];
    rules[findIndex(rules, ['name', rule['short-name']])].user_inputs[name] = type === 'checkbox' ? !!(checked) : value;
    ruleset.detail.rules = rules;
    this.setState(ruleset);
  }

  handleValueBlur = (event, rule) => {
    const { ruleset } = this.state;
    const rules = [...ruleset.detail.rules];
    const { name, value, min, max, type } = event.target;

    if (type !== 'number') return true;

    const val = value.replace(/[^0-9.]+/g, '');
    rules[findIndex(rules, ['name', rule['short-name']])].user_inputs[name] = (min && +val < +min) ? min : (max && +val > +max) ? max : val;

    this.setState({
      ruleset: {
        name: ruleset.name,
        detail: {
          rules,
        }
      }
    })
  }

  handleRulesetName = ({ target }) => {
    const { ruleset } = this.state;
    ruleset.name = target.value;
    this.setState({ ruleset });
  }

  handleAskBeforeSave = () => {
    const { ruleset } = this.state;

    if (!this.validateRuleset()) {
      return;
    }

    this.setState({ saving: true });
    if (ruleset.id) {
      this.setState({
        confirmationOpen: true
      })
    } else {
      this.handleSaveRuleset();
    }
  }

  handleCloseConfModal = () => {
    this.setState({
      saving: false,
      confirmationOpen: false,
      rulesetCampaignsFetching: false,
      rulesetCampaigns: [],
      rulesetCampaignsPages: -1,
      rulesetCampaignsCount: 0,
    })
  }

  handleSaveRuleset = () => {
    const { ruleset, rulesetId } = this.state;
    const editing = !!rulesetId;

    ruleset.detail.rules.forEach((r) => {
      if (!r.user_inputs) return;

      Object.keys(r.user_inputs).forEach((k) => {
        if (k !== 'comparison' && k !== 'event_type') {
          r.user_inputs[k] = +r.user_inputs[k];
        }
      });
    });

    this.handleCloseConfModal();
    this.setState({ loading: true });

    delete ruleset.account_id;
    delete ruleset.ts_create;
    delete ruleset.ts_update;
  
    Api[editing ? 'updateRuleset' : 'addRuleset'](ruleset)
      .then(response => {
        this.setState({
          redirect: true,
        });
      })
      .catch(({ response }) => {
        this.setState({ loading: false });
        const errorMessage = get(response, 'data.error.message', 'Unable to create ruleset');
        toast.error(errorMessage);
      });
  }

  validateRuleset = () => {
    const { ruleset } = this.state;

    if (!ruleset.name) {
      toast.error(`Ruleset name can't be blank.`);
      return false;
    }

    if (!ruleset.detail.rules || ruleset.detail.rules.length === 0) {
      toast.error('Ruleset must have at least one alarm.');
      return false;
    }

    for (let i = 0; i < ruleset.detail.rules.length; i += 1) {
      const r = ruleset.detail.rules[i];
      if (r.user_inputs) {
        let emptyField = null;
        Object.keys(r.user_inputs).forEach((ui) => {
          if (r.user_inputs[ui] === null || r.user_inputs[ui] === undefined || r.user_inputs[ui] === '' || typeof r.user_inputs[ui] === 'object') {
            emptyField = ui;
          }
        });
        if (emptyField) {
          toast.error('Please fill all empty fields.');
          return false;
        }
      }
    }

    return true;
  }

  render() {
    const {
      loading,
      ruleset,
      redirect,
      supportedRules,
      confirmationOpen,
      rulesetCampaigns,
      rulesetCampaignsCount,
      rulesetCampaignsPages,
      rulesetCampaignsFetching,
    } = this.state;
    const rulesetAlarms = get(ruleset, 'detail.rules', []);

    if (redirect) return <Redirect to="/account/manage-rulesets" />;

    if (loading) return <Loader />;

    return (
      <div className="manage-ruleset-wrapper">
        <div className="manage-ruleset-page">
          <div className="manage-ruleset-page-header">
            <div className="back-to-campaign-link" />
            <div className="manage-ruleset-create-buttons">
              <Button
								mini
								component={Link}
                bgColor="transparent"
                to="/account/manage-rulesets"
              >
                Cancel
              </Button>
              <Button
                mini
                bgColor="transparent"
                className="add-new-campaign-button"
                onClick={this.handleAskBeforeSave}
              >
                Save
              </Button>
            </div>
          </div>
          <SelectedAlarms
            alarms={rulesetAlarms}
            supportedRules={supportedRules}
          />
          <div className="ruleset-form">
            <Input
              autoFocus
              label="Ruleset Name"
              value={ruleset.name}
              onChange={this.handleRulesetName}
            />
            <h3 className="ruleset-form-title">RULES</h3>
            {supportedRules.map(rule => (
              <Rule
                rule={rule}
                handleBlock={this.handleBlock}
                handleActivateRule={this.handleActivateRule}
                handleValueChange={this.handleValueChange}
                handleValueBlur={this.handleValueBlur}
                values={find(rulesetAlarms, ['name', rule['short-name']])}
              />))}
          </div>
        </div>
        <RulesetConfirmationModal open={confirmationOpen} onAccept={this.handleSaveRuleset} onClose={this.handleCloseConfModal}>
          <Table
            data={rulesetCampaigns}
            columns={rulesetCampaignsColumns(rulesetCampaignsCount)}
            pages={rulesetCampaignsPages}
            PadRowComponent={() => null}
            loading={rulesetCampaignsFetching}
            manual
            onFetchData={this.getRulesetCampaigns}
            sortable={false}
            showPageSizeOptions={false}
            showPageJump={true} />
        </RulesetConfirmationModal>
      </div>
    );
  }
}