import React from 'react';

import { shadowText } from '../../utils/misc';

export const columns = ({
	renderFraudGraphCell,
	onExpandedChange,
}) => [
		{
			Header: 'expander',
			Expander: () => null,
			expander: true,
			show: false,
		},
		{
			Header: 'Creative',
			id: 'creative_id',
			accessor: 'creative_id',
			cast: 'string',
			width: 300,
			style: {
				overflow: 'visible',
			},
			Cell: props => (
				<div style={{ display: 'flex', alignItems: 'center' }}>
					{onExpandedChange && <div className={`rt-expander ${props.isExpanded ? '-open' : ''}`} onClick={() => onExpandedChange(props.viewIndex)} style={{ marginRight: 10 }}></div>}
					<span className="campaign-table-name">
						{shadowText(props.value, 'Example KPI')}
					</span>
				</div>
			)
		},
		{
			Header: 'Impression',
			id: 'impression',
			accessor: 'impression',
			cast: 'string',
			width: 220,
			Cell: renderFraudGraphCell,
		},
		{
			Header: 'CTR',
			id: 'ctr',
			accessor: 'ctr',
			cast: 'string',
			width: 220,
		},
		{
			Header: 'Click',
			id: 'click',
			accessor: 'click',
			cast: 'string',
			width: 220,
			Cell: renderFraudGraphCell,
		},
		{
			Header: 'Conversion Rate',
			id: 'conversionRate',
			accessor: 'conversionRate',
			cast: 'string',
			width: 220,
		},
		{
			Header: 'Install',
			id: 'install',
			accessor: 'install',
			cast: 'string',
			width: 220,
			Cell: renderFraudGraphCell,
		},
		{
			Header: 'Event',
			id: 'event',
			accessor: 'event',
			cast: 'string',
			width: 220,
			Cell: renderFraudGraphCell,
		},
	];