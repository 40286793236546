import React, { useState, useEffect } from 'react';
import { Modal } from 'interceptd-ui';

import Local from '../../services/localStorage';
import Settings from '../../services/settings';

import './styles/WelcomeBackModal.css';

const WelcomeBackModal = ({ campaigns }) => {
	const [open, setIsOpen] = useState(false);
	const [userName, setUserName] = useState(null);
	const hideWelcomeBackModal = Settings.get('hideWelcomeBackModal');
	const me = Local.getItem('me');
	const name = me?.fullname || 'User';
	const initialOpen = !hideWelcomeBackModal && me.plans?.interceptd === 'free' && campaigns > 0;

	useEffect(() => {
		setUserName(name);
		setIsOpen(initialOpen);
	}, [name, initialOpen]);

	return (
		<Modal
			open={open}
			title="Welcome Back"
			onCancel={() => {
				setIsOpen(false);
				Settings.set('hideWelcomeBackModal', true);
			}}
			defaultCancelText="Ok"
			acceptProps={{ style: { display: 'none' } }}
		>
			<div className="welcome-back-modal">
				<p>{`Dear ${userName},`}</p>
				<p>We are very happy to have you with us! Please contact <span className="welcome-back-modal-address">success@interceptd.com</span> if you need assistance. Looking forward to seeing your message!</p>
				<span>Customer Success Team @ Interceptd</span>
			</div>
		</Modal>
	);
}

export default WelcomeBackModal;