import React, { useState, useMemo, Fragment } from 'react';
import Humanize from 'humanize-plus';
import moment from 'moment';
import ClickOutside from 'react-click-outside';
import Chart from 'react-apexcharts';
import {
	Box,
	Loader,
	NoData,
	Icon,
	AppIcon,
} from 'interceptd-ui';

import { shadowText, isShadow } from '../../utils/misc';

import defaultPartnerLogo from '../../assets/icon-gray.svg';

const AggregationOptions = [
	{
		value: 'campaigns',
		label: 'Campaigns',
	},
	{
		value: 'publishers',
		label: 'Publishers',
	},
	{
		value: 'apps',
		label: 'Apps',
	},
]

const RangeOptions = [
	{
		value: `${+moment.utc().startOf('day').format('X')}-${+moment.utc().endOf('day').format('X')}-Today`,
		label: 'Today',
	},
	{
		value: `${+moment.utc().subtract(1, 'day').startOf('day').format('X')}-${+moment.utc().subtract(1, 'day').endOf('day').format('X')}-Yesterday`,
		label: 'Yesterday',
	},
	{
		value: `${+moment.utc().subtract(7, 'day').startOf('day').format('X')}-${+moment.utc().endOf('day').format('X')}-Last 7 Days`,
		label: 'Last 7 Days',
	},
	{
		value: `${+moment.utc().startOf('month').format('X')}-${+moment.utc().endOf('day').format('X')}-This Month`,
		label: 'This Month',
	},
	{
		value: `${+moment.utc().subtract(1, 'month').startOf('month').format('X')}-${+moment.utc().subtract(1, 'month').endOf('month').format('X')}-Last Month`,
		label: 'Last Month',
	},
]

const chartOptions = {
	chart: {
		sparkline: {
			enabled: true
		},
		background: '#EEF9F9',
	},
	colors: ['#55C4C2'],
	stroke: {
		curve: 'smooth',
		width: 2,
	},
	fill: {
		opacity: 0.3
	},
	xaxis: {
		type: 'datetime',
		labels: {
			format: 'MMM-D',
		},
		crosshairs: {
			width: 1
		},
	},
	yaxis: {
		min: 0,
		forceNiceScale: true,
		labels: {
			show: false,
			formatter: v => `$${Humanize.formatNumber(v, 2)}`,
		},
	},
	tooltip: {
		y: {
			title: {
				formatter: () => ''
			}
		},
	},
}

const Select = ({
	value,
	defaultValue,
	options,
	onChange,
	position,
}) => {
	const [open, setOpen] = useState(false);
	const label = options.find(option => option.value === (value || defaultValue)).label;

	const handleSelect = option => {
		onChange(option.value)
		setOpen(false);
	}

	return (
		<ClickOutside onClickOutside={() => open ? setOpen(false) : null}>
			<div className={`
				savings-select
				${open ? 'open' : ''}
				${position}
			`}>
				<div className="savings-select-value" onClick={() => setOpen(!open)}>
					<span>{label}</span>
					<Icon i={open ? 'chevron-up' : 'chevron-down'} size={16} />
				</div>
				<div className="savings-select-options">
					{options.map((option, index) => (
						<div
							key={`option-${index}`}
							className={`
								savings-select-option
								${value === option.value ? 'selected' : ''}
							`}
							onClick={() => handleSelect(option)}>
							{option.label}
						</div>
					))}
				</div>
			</div>
		</ClickOutside>
	)
}

const Source = ({
	index,
	source,
	savings,
	isExpanded,
	onExpand,
}) => {
	const [calcSavings, setCalcSavings] = useState({
		click_savings: 0,
		install_savings: 0,
		event_savings: 0,
		impression_savings: 0,
		totalSaving: 0,
	});

	useMemo(() => {
		const click_savings = savings.reduce((acc, cur) => (acc + cur.click_savings), 0);
		const install_savings = savings.reduce((acc, cur) => (acc + cur.install_savings), 0);
		const event_savings = savings.reduce((acc, cur) => (acc + cur.event_savings), 0);
		const impression_savings = savings.reduce((acc, cur) => (acc + cur.impression_savings), 0);
		const totalSaving = click_savings + install_savings + event_savings + impression_savings;

		setCalcSavings({
			click_savings,
			install_savings,
			event_savings,
			impression_savings,
			totalSaving,
		})
	}, [savings]);

	return (
		<div className={`savings-campaign ${isExpanded ? 'expanded' : ''}`}>
			<div className="savings-campaign-total" onClick={() => onExpand(isExpanded ? null : index)}>
				<Icon i="chevron-right" size={18} />
				<span>{shadowText(source.name, 'Example Source')} - {source.id} - {source.campaign.type}</span>
				<b>${Humanize.formatNumber(calcSavings.totalSaving, 2)}</b>
			</div>
			<div className="savings-campaign-detail">
				{source.campaign.type !== 'CPM' ? (
					<Fragment>
						<div className="savings-campaign-detail-row">
							<span>Savings from Clicks</span>
							<b>${Humanize.formatNumber(calcSavings.click_savings, 2)}</b>
						</div>
						<div className="savings-campaign-detail-row">
							<span>Savings from Installs</span>
							<b>${Humanize.formatNumber(calcSavings.install_savings, 2)}</b>
						</div>
						<div className="savings-campaign-detail-row">
							<span>Savings from Events</span>
							<b>${Humanize.formatNumber(calcSavings.event_savings, 2)}</b>
						</div>
					</Fragment>
				) : (
					<div className="savings-campaign-detail-row">
						<span>Savings from Impressions</span>
						<b>${Humanize.formatNumber(calcSavings.impression_savings, 2)}</b>
					</div>
				)}
			</div>
		</div>
	)
}

const Campaign = ({
	index,
	type,
	campaign,
	publisher,
	app,
	savings,
	isExpanded,
	onExpand,
}) => {
	const [expandedSource, setExpandedSource] = useState();
	const [calcSavings, setCalcSavings] = useState({
		click_savings: 0,
		install_savings: 0,
		event_savings: 0,
		impression_savings: 0,
		totalSaving: 0,
	});

	useMemo(() => {
		const click_savings = savings.reduce((acc, cur) => (acc + cur.click_savings), 0);
		const install_savings = savings.reduce((acc, cur) => (acc + cur.install_savings), 0);
		const event_savings = savings.reduce((acc, cur) => (acc + cur.event_savings), 0);
		const impression_savings = savings.reduce((acc, cur) => (acc + cur.impression_savings), 0);
		const totalSaving = click_savings + install_savings + event_savings + impression_savings;

		setCalcSavings({
			click_savings,
			install_savings,
			event_savings,
			impression_savings,
			totalSaving,
		})
	}, [savings]);

	const handleSourceExpand = index => {
		setExpandedSource(index);
	}

	const appInfo = type === 'campaigns' ? (
		campaign.app_name ? campaign.app_info : undefined
	) : type === 'apps' ? (
		app
	) : ({
		icon_url: publisher.logo || defaultPartnerLogo,
		title: shadowText(publisher.name, 'Example Publisher'),
	})

	return (
		<div className={`savings-campaign type-${type} ${isExpanded ? 'expanded' : ''}`}>
			<div className="savings-campaign-total" onClick={() => onExpand(isExpanded ? null : index)}>
				<Icon i="chevron-right" size={18} />
				<AppIcon app={appInfo} showStore={type !== 'publishers'} isShadow={isShadow()} />
				<span>{type === 'campaigns' ? `${shadowText(campaign.name, 'Example Campaign')} - ${campaign.id} - ${campaign.type}` : type === 'apps' ? shadowText(app.title, 'Example App') : shadowText(publisher.name, 'Example Publisher')}</span>
				<b>${Humanize.formatNumber(calcSavings.totalSaving, 2)}</b>
			</div>
			<div className="savings-campaign-detail">
				{type === 'campaigns' ? (
					campaign.type !== 'CPM' ? (
						<Fragment>
							<div className="savings-campaign-detail-row">
								<span>Savings from Clicks</span>
								<b>${Humanize.formatNumber(calcSavings.click_savings, 2)}</b>
							</div>
							<div className="savings-campaign-detail-row">
								<span>Savings from Installs</span>
								<b>${Humanize.formatNumber(calcSavings.install_savings, 2)}</b>
							</div>
							<div className="savings-campaign-detail-row">
								<span>Savings from Events</span>
								<b>${Humanize.formatNumber(calcSavings.event_savings, 2)}</b>
							</div>
						</Fragment>
					) : (
						<div className="savings-campaign-detail-row">
							<span>Savings from Impressions</span>
							<b>${Humanize.formatNumber(calcSavings.impression_savings, 2)}</b>
						</div>
					)
				) : (
					<Fragment>
						<div className="savings-campaign-detail-row">
							<span>Savings from Clicks</span>
							<b>${Humanize.formatNumber(calcSavings.click_savings, 2)}</b>
						</div>
						<div className="savings-campaign-detail-row">
							<span>Savings from Installs</span>
							<b>${Humanize.formatNumber(calcSavings.install_savings, 2)}</b>
						</div>
						<div className="savings-campaign-detail-row">
							<span>Savings from Events</span>
							<b>${Humanize.formatNumber(calcSavings.event_savings, 2)}</b>
						</div>
						<div className="savings-campaign-detail-row">
							<span>Savings from Impressions</span>
							<b>${Humanize.formatNumber(calcSavings.impression_savings, 2)}</b>
						</div>
					</Fragment>
				)}
			</div>
			{(type === 'campaigns' ? campaign.sources : type === 'apps' ? app.sources : publisher.sources) &&
				<div className="savings-campaign-sources">
					{(type === 'campaigns' ? campaign.sources : type === 'apps' ? app.sources : publisher.sources).map((source, index) => (
						<Source
							key={`source-${index}`}
							index={index}
							source={source}
							savings={savings.filter(saving => saving.source.id === source.id)}
							isExpanded={index === expandedSource}
							onExpand={handleSourceExpand} />
					))}
				</div>
			}
		</div>
	)
}

const Detail = ({
	loading,
	open,
	campaigns,
	partners,
	totalSaving,
	totalSavings,
	dailySavings,
	onDateRange,
}) => {
	const [expanded, setExpanded] = useState()
	const [aggregation, setAggregation] = useState(AggregationOptions[0].value)
	const [range, setRange] = useState(RangeOptions[2].value)
	const [publishers, setPublishers] = useState([])
	const [apps, setApps] = useState([])
	
	const handleExpand = index => {
		setExpanded(index)
	}
	
	const handleAggregation = value => {
		setAggregation(value)
	}
	
	const handleRange = value => {
		setRange(value)
		const [ts_start, ts_end, label] = value.split('-');
		onDateRange && onDateRange(ts_start, ts_end, label);
	}

	useMemo(() => {
		const publishers = campaigns.reduce((acc, cur) => {
			const newAcc = [...acc];
			cur.sources.forEach(source => {
				// Find Publisher
				const foundPublisher = newAcc.findIndex(p => p.id === source.partner);
				if ( foundPublisher === -1 ) {
					// No Publisher, add
					const publisher = {
						...(partners.find(partner => source.partner === partner.id) || {})
					};
					publisher.sources = [source];
					newAcc.push(publisher);
				} else {
					// Publisher found, add source
					newAcc[foundPublisher].sources = [
						...(newAcc[foundPublisher].sources),
						source,
					]
				}
			})
			return newAcc;
		}, [])
		setPublishers(publishers);
	}, [campaigns, partners])

	useMemo(() => {
		const apps = campaigns.reduce((acc, cur) => {
			const newAcc = [...acc];
			cur.sources.forEach(source => {
				// Find Publisher
				const foundApp = newAcc.findIndex(a => a.id === cur?.app_info?.id);
				if ( foundApp === -1 ) {
					// No Publisher, add
					const app = {
						...(cur?.app_info)
					};
					app.sources = [source];
					newAcc.push(app);
				} else {
					// Publisher found, add source
					newAcc[foundApp].sources = [
						...(newAcc[foundApp].sources),
						source,
					]
				}
			})
			return newAcc;
		}, [])
		setApps(apps);
	}, [campaigns])

	return (
		<Box
			className="savings-detail"
			title=""
			titleProps={{
				style: {
					display: 'none'
				}
			}}
			left={
				<Select
					position="left"
					value={aggregation}
					options={AggregationOptions}
					onChange={handleAggregation} />
			}
			right={onDateRange && (
				<Select
					position="right"
					value={range}
					options={RangeOptions}
					onChange={handleRange} />
			)}>
			{(range !== RangeOptions[0].value && range !== RangeOptions[1].value && dailySavings.length > 3) &&
				<div className="savings-detail-chart">
					<Chart options={chartOptions} series={[{ data: dailySavings }]} type="area" width="100%" height={160} />
				</div>
			}
			<div className="savings-detail-list-header">
				<span>Estimated Savings ({ range.split('-')[2] })</span>
				<b>${Humanize.formatNumber(totalSaving, 2)}</b>
			</div>
			<div className={`savings-detail-list ${loading ? 'loading' : ''}`}>
				{loading ? (
					<Loader mini />
				) : open ? (
					aggregation === 'campaigns' ? (
						(campaigns && campaigns.length > 0) ? (
							campaigns.map((campaign, index) => (
								<Campaign
									key={`campaign-${index}`}
									type={aggregation}
									index={index}
									campaign={campaign}
									savings={totalSavings.filter(saving => saving.source.campaign.id === campaign.id)}
									isExpanded={index === expanded}
									onExpand={handleExpand} />
							))
						) : <NoData>Create your first campaign and start saving!</NoData>
					) : aggregation === 'apps' ? (
						(apps && apps.length > 0) ? (
							apps.map((app, index) => (
								<Campaign
									key={`app-${index}`}
									type={aggregation}
									index={index}
									app={app}
									savings={totalSavings.filter(saving => saving.source.app.id === app.id)}
									isExpanded={index === expanded}
									onExpand={handleExpand} />
							))
						) : <NoData>Create your first campaign and start saving!</NoData>
					) : (
						(publishers && publishers.length > 0) ? (
							publishers.map((publisher, index) => (
								<Campaign
									key={`publisher-${index}`}
									type={aggregation}
									index={index}
									publisher={publisher}
									savings={totalSavings.filter(saving => saving.source.partner.id === publisher.id)}
									isExpanded={index === expanded}
									onExpand={handleExpand} />
							))
						) : <NoData>Create your first campaign and start saving!</NoData>
					)
				) : null}
			</div>
		</Box>
	)
}

export default Detail;
