import axios from 'axios';

import config from './config.json';
import history from './history';
import Local from './localStorage';
import mockApi from './mockApi';

import { ToParam } from '../utils/transform';
import { isDemoAccount } from '../utils/misc';

class Api {
	constructor() {
		this.url = window.location.hostname;
		this.cancelAll = () => true;

		this.api = axios.create({
			baseURL: config[this.url].endpoint,
			headers: {
				'Content-Type': 'application/json',
				'Authorization': Local.getItem('token'),
			}
		});

		this.shortenerConfig = {
			baseURL: config[this.url].shortener
		}

		const CancelToken = axios.CancelToken;

		this.api.interceptors.request.use(
			config => {
				const newConfig = {
					...config,
					cancelToken: new CancelToken(c => {
						this.cancelAll = c;
					})
				}
				return newConfig;
			},
			error => Promise.reject(error)
		);

		this.api.interceptors.response.use(
			response => response,
			error => {
				if ( error.response ) {
					if (error.response.status === 401 || error.response.status === 403) {
						if ( Local.getItem('token') ) {
							this.cancelAll();
							Local.setStore({});
							this.api.defaults.headers['Authorization'] = null;
							history.push('/welcome', {
								from: history.location,
								hideToast: true
							});
						}else {
							Local.setStore({});
							this.api.defaults.headers['Authorization'] = null;
						}
					}
				}

				return Promise.reject(error);
			}
		);

		if ( isDemoAccount() ) {
			this.api = mockApi;
		}
	}

	setToken(token) {
		this.api.defaults.headers['Authorization'] = token;
	}

	/**
	 * Generic
	 */
	get(...args) {
		return this.api.get(...args);
	}

	/**
	 * General
	 */
	login(payload) {
		return this.api.post('/doberman/login', payload);
	}
	register(payload) {
		return this.api.post('/doberman/register', payload);
	}
	forgotPassword(payload) {
		return this.api.post('/doberman/forgot-password', payload);
	}
	resetPassword(token, payload) {
		return this.api.post(`/doberman/reset-password`, payload, {
			headers: {
				Authorization: token
			}
		});
	}
	verifyUser(token) {
		return this.api.get(`/doberman/verify-email?token=${ token }`);
	}
	resendVerifyEmail(payload) {
		return this.api.post('/doberman/resend-verification-email', payload);
	}
	getMe() {
		return this.api.get('/doberman/user/me');
	}
	updateAccount(payload) {
		return this.api.post(`/doberman/update-user`, payload);
	}
	deleteAccount() {
		return this.api.post('/doberman/user/delete');
	}
	updateUserSettings(payload) {
		return this.api.post(`/doberman/user/settings`, payload);
	}
	updateAccountSettings(payload) {
		return this.api.put(`/doberman/update-account`, payload);
	}
	getTrackingMacros() {
		return this.api.get('/campaign/macro');
	}

	/**
	 * Team
	 */
	inviteTeamUser({ email }) {
		return this.api.post('/doberman/invite-user', { email });
	}
	cancelInvite(email) {
		return this.api.post('/doberman/cancel-invitation', { email });
	}
	registerInvitedUser(payload) {
		return this.api.post('/doberman/register-invited-user', payload);
	}
	updateTeamAccount({
		company_name,
    company_website,
		settings,
	}) {
		return this.api.put('/doberman/update-account', {
			company_name,
			company_website,
			settings,
		});
	}
	getAccountUsers(accountId) {
		return this.api.get(`/doberman/${accountId}/account-users`);
	}

	/**
	 * Apps
	 */
	getAppInfo(payload) {
		return this.api.get(`/app?${ToParam(payload)}`);
	}
	getAppInfoByName(payload) {
		return this.api.get(`/app/search?${ToParam(payload)}`);
	}

	/**
	 * Campaign
	 */
	createCampaign(payload) {
		return this.api.post(`/campaign`, payload);
	}
	getLeoparCampaigns(payload = {}) {
		return this.api.get(`/campaign/v2/campaigns?${ToParam(payload)}`);
	}
	getLeoparSourceStats(payload = {}) {
		return this.api.get(`/campaign/v2/sources/stats?${ToParam(payload, false)}`);
	}
	getLeoparSourceInstallAndEvent(payload = {}) {
		return this.api.get(`/leopar/stats?${ToParam(payload, false)}`);
	}
	getLeoparSourceKPIStats(payload) {
		return this.api.post(`/leopar/event/stats`, payload);
	}
	updateCampaign(id, payload) {
		return this.api.post(`/campaign/${ id }`, payload);
	}
	getCampaignHistory(id, payload) {
		return this.api.get(`/campaign/${ id }/history?${ToParam(payload)}`);
	}
	setCampaignKPIs(id, payload) {
		return this.api.post(`/campaign/${ id }/event`, payload);
	}
	blockCampaign(id) {
		return this.api.get(`/campaign/${ id }/block`);
	}
	unblockCampaign(id) {
		return this.api.get(`/campaign/${ id }/unblock`);
	}
	getLeoparFunnelData({ campaignId, startDate, endDate, events, graph = false }) {
		return this.api.get(`/leopar/funnel?campaign_id=${campaignId}&ts_start=${startDate}&ts_end=${endDate}&funnel=${events}&graph=${graph}`);
	}
	getClickThrough(campaignId, sourceId) {
		return this.api.get(`/campaign/${campaignId}/source/${sourceId}/checkbot`);
	}
	getLeoparClickDailyStats(payload) {
		return this.api.get(`/campaign/v2/sources/daily-stats?${ToParam(payload, false)}`);
	}
	getLeoparInstallAndEventDailyStats(payload) {
		return this.api.get(`/leopar/daily-stats?${ToParam(payload, false)}`);
	}
	getLeoparInstallAndEventSubPubDailyStats(payload) {
		return this.api.get(`/leopar/subpub/daily-stats?${ToParam(payload, false)}`);
	}
	getLeoparSankey(campaignId, payload) {
		return this.api.get(`/leopar/${campaignId}/sankey?${ToParam(payload, false)}`);
	}
	getLeoparEventSankey(campaignId, payload) {
		return this.api.get(`/leopar/${campaignId}/sankey/event?${ToParam(payload, false)}`);
	}
	getImpressionSankey(campaignId, payload) {
		return this.api.get(`/campaign/${campaignId}/sankey/impression?${ToParam(payload, false)}`)
	}
	getCreativePerformance(payload) {
		return this.api.get(`/campaign/v2/sources/creative-stats?${ToParam(payload)}`);
	}
	getCreativeStats(payload) {
		return this.api.get(`/leopar/stats/creative?${ToParam(payload)}`);
	}

	/**
	 * Offline Reports
	 */
	getLeoparOfflineReports(query) {
		query.report_type = query.report_type ? query.report_type.replace(/-/g, '_') : null;
		return this.api.get(`/leopar/offline/customer/list?${ToParam(query, false)}`);
	}
	getLeoparOfflineReportDetail(id) {
		return this.api.get(`/leopar/offline/detail/${id}`);
	}
	createLeoparOfflineReport(payload, reportType) {
		return this.api.post(`/leopar/offline/customer/${reportType}`, payload);
	}
	getOfflineReports(query) {
		query.report_type = query.report_type ? query.report_type.replace(/-/g, '_') : null;
		return this.api.get(`/report/offline?${ToParam(query, false)}`);
	}
	getOfflineReportDetail(id) {
		return this.api.get(`/report/offline/${id}`);
	}
	createOfflineReport(payload, reportType, deduction) {
		let url = '/report/offline/';

		if ((reportType === 'install' || reportType === 'event') && !deduction) {
			url += `non-deduction-${reportType}`;
		} else {
			url += reportType;
		}

		return this.api.post(url, payload);
	}
	shareReport(payload) {
		return this.api.post('leopar/offline/customer/summary/share', payload);
	}

	/**
	 * Source
	 */
	getSourceHistory(campaign_id, source_id, payload) {
		return this.api.get(`/campaign/${ campaign_id }/source/${ source_id }/history?${ToParam(payload)}`);
	}
	getCohortDailyPerformance(query) {
		return this.api.get(`/leopar/daily-event-performance?${decodeURIComponent(ToParam(query))}`)
	}
	getEventDailyPerformance(query) {
		return this.api.get(`/leopar/daily-event-stats?${decodeURIComponent(ToParam(query))}`)
	}
	addCampaignSource(id, source_id, payload) {
		return this.api.post(`/campaign/${ id }/source`, payload);
	}
	updateSource(id, source_id, payload) {
		return this.api.post(`/campaign/${ id }/source/${ source_id }`, payload);
	}
	activateCheckBot(id, source_id) {
		return this.api.post(`/campaign/${ id }/source/${ source_id }/checkbot/activate`);
	}
	deactivateCheckBot(id, source_id) {
		return this.api.post(`/campaign/${ id }/source/${ source_id }/checkbot/deactivate`);
	}
	blockSource(id, source_id) {
		return this.api.get(`/campaign/${ id }/source/${ source_id }/block`);
	}
	unblockSource(id, source_id) {
		return this.api.get(`/campaign/${ id }/source/${ source_id }/unblock`);
	}
	updateSourceSelectedEvents(id, source_id, payload) {
		return this.api.post(`/campaign/${ id }/source/${ source_id }/select-events`, payload);
	}


	/**
	 * Source Graphs
	 */
	getDailyImpressionStats(query) {
		return this.api.get(`/campaign/v2/sources/daily-impression-stats?${ToParam(query, false)}`);
	}
	getLeoparSourceGraphData(query) {
		return this.api.get(`/leopar/source?${ToParam(query)}`);
	}

	/**
	 * Subpub Graphs
	 */
	getLeoparSubpubGraphData(query) {
		return this.api.get(`/leopar/subpub?${ToParam(query)}`)
	}

	/**
	 * Subpub
	 */
	getSubpubImpressions(query) {
		return this.api.get(`/subpub/impression?${ToParam(query)}`);
	}
	leoparGetSubpubList(query) {
		return this.api.get(`/leopar/subpub/list?${ToParam(query)}`);
	}
	leoparGetEventList(query) {
		return this.api.get(`/leopar/subpub/event/list?${ToParam(query)}`);
	}
	leoparGetAlarmList(query) {
		return this.api.get(`/leopar/alarm?${ToParam(query)}`);
	}
	leoparBlockSubpub(payload) {
		return this.api.post('/leopar/subpub/block', payload);
	}
	getCampaignSubPubs(payload) {
		return this.api.get(`/subpub?${ToParam(payload)}`);
	}
	getCampaignSubPubsLink(payload) {
		return this.api.get(`/subpub/v2?${ToParam(payload)}`);
	}
	blockSubPub(payload) {
		return this.api.post(`/subpub/block`, payload);
	}

	/**
	 * Ruleset
	 */
	getRulesets(payload) {
		return this.api.get(`/ruleset?${ToParam(payload)}`);
	}
	addRuleset(payload) {
		return this.api.post(`/ruleset`, payload);
	}
	updateRuleset({ id, ...rest }) {
		return this.api.put(`/ruleset/${ id }`, rest);
	}
	deleteRuleset(id) {
		return this.api.delete(`/ruleset/${ id }`);
	}
	getSupportedRules() {
		return this.api.get('/ruleset/rule/supported');
	}
	getCampaignRuleSets(id) {
		return this.api.get(`/ruleset/campaign?ids=${ id }`);
	}
	applyRuleset(id, payload) {
		return this.api.post(`/ruleset/${ id }/apply`, payload);
	}
	unapplyRuleset(id, payload) {
		return this.api.post(`/ruleset/${ id }/unapply`, payload);
	}
	getRulesetCampaigns(id, payload) {
		return this.api.get(`/ruleset/${ id }/campaigns?${ToParam(payload)}`)
	}
	alarmSeen(id, payload) {
		return this.api.get(`/campaign/${ id }/alarms-seen?${ToParam(payload)}`);
	}
	getSuppertedCampaignRules() {
		return this.api.get('/campaign/rule/supported');
	}

	/**
	 * Campaign Savings
	 */
	getSavings(payload) {
		return this.api.post('/leopar/savings', payload);
	}

	/**
	 * Campaign Rules
	 */
	getCampaignRulesets() {
		return this.api.get('/campaign/ruleset');
	}
	updateCampaignRuleset({ id, ...rest }) {
		return this.api.put(`/campaign/ruleset/${ id }`, rest);
	}
	getCampaignCRuleset(id) {
		return this.api.get(`/campaign/ruleset/campaign?ids=${ id }`);
	}
	
	/**
	 * Partners
	 */
	getPartners(payload) {
		return this.api.get(`/partner?${ ToParam(payload) }`);
	}

	/**
	 * Publishers
	 */
	getPublishers(payload) {
		return this.api.get(`/publisher?${ToParam(payload)}`);
	}
	createPublisher(payload) {
		return this.api.post(`/publisher`, payload);
	}
	getPublisher(id) {
		return this.api.get(`/publisher/${ id }`);
	}
	updatePublisher(id, payload) {
		return this.api.post(`/publisher/${ id }`, payload);
	}

	/**
	 * Redirection Reports
	 */
	getRedirectionReports() {
		return this.api.get(`/redirection/report`);
	}
	getRedirectionReport(id) {
		return this.api.get(`/redirection/report/${ id }`);
	}
	requestRedirectionReport(payload) {
		return this.api.post(`/redirection/report`, payload);
	}

	/**
	 * Link Shortener
	 */
	getShortenerLinks() {
		return this.api.get(`/tracker`, this.shortenerConfig);
	}
	getShortenerLink(id) {
		return this.api.get(`/tracker/${ id }`, this.shortenerConfig);
	}
	createShortenerLink(payload) {
		return this.api.post(`/tracker`, payload, this.shortenerConfig);
	}
	updateShortenerLink(id, payload) {
		return this.api.put(`/tracker/${ id }`, payload, this.shortenerConfig);
	}
	
	/**
	 * Survey
	 */
	getSurveyQuestions() {
		return this.api.get(`/app/survey/questions`);
	}
	saveSurveyAnswers(payload) {
		return this.api.post(`/app/survey`, payload);
	}
	savePublicSurveyAnswers(payload) {
		return this.api.post('/app/survey/public', payload);
	}
	getSurveyResult(appId, userId) {
		return this.api.get(`/app/survey/fraud_score/${userId}?app_id=${appId}`);
	}

	/**
	 * File
	 */
	getFile(url, responseType = 'blob', options = {}) {
		return this.api({
			url,
			...options,
			method: 'GET',
			responseType,
			transformRequest: [(data, headers) => {
				delete headers.Authorization;
				return data;
			}],
		});
	}
}

export default new Api();
