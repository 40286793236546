import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
	Icon,
	Select,
	Modal,
	Input,
} from 'interceptd-ui';

import { EventCategories } from '../../../constants/Campaign';

import Settings from '../../../services/settings';

import './styles/EventMatch.css';

const EventMatch = props => {
	const { app, open, onClose } = props;

	const [state, setState] = useState({
		matches: (Settings.get('eventMatches', 'account') || {})[app] || [
			{
				event: '',
				category: null,
			}
		],
		saving: false,
	});

	useEffect(() => {
		setState({
			matches: (Settings.get('eventMatches', 'account') || {})[app] || [
				{
					event: '',
					category: null,
				}
			],
			saving: false,
		})
	}, [app])

	const handleEvent = (event, index) => {
		const { value } = event.target;
		setState(prevState => ({
			...prevState,
			matches: prevState.matches.map((m, i) => i === index ? ({
				...m,
				event: value
			}) : m)
		}))
	}

	const handleCategory = (value, index) => {
		setState(prevState => ({
			...prevState,
			matches: prevState.matches.map((m, i) => i === index ? ({
				...m,
				category: value
			}): m)
		}))
	}

	const removeMatch = index => {
		if ( state.matches.length === 1 ) {
			setState(prevState => ({
				...prevState,
				matches: [
					{
						event: '',
						category: null,
					}
				]
			}))
		} else {
			setState(prevState => ({
				...prevState,
				matches: prevState.matches.filter((c, i) => i !== index)
			}))
		}
	}

	const handleAdd = () => {
		if ( state.matches[state.matches.length - 1].event !== '' ) {
			setState(prevState => ({
				...prevState,
				matches: [
					...prevState.matches,
					{
						event: '',
						category: null,
					}
				]
			}))
		}
	}

	const handleSave = () => {
		setState(prevState => ({
			...prevState,
			saving: true,
		}))

		Settings.set('eventMatches', {
			...(Settings.get('eventMatches', 'account') || {}),
			[app]: state.matches.filter(match => match.event !== '')
		}, 'account');

		setState(prevState => ({
			...prevState,
			saving: false,
		}))
		toast.success('Event matches saved.');
		onClose();
	}

	const isSaveDisabled = state.matches.some(match => match.category === null || match.event === '');

	return (
		<Modal
			open={open}
			title="Match your events with event types"
			onCancel={onClose}
			onAccept={handleSave}
			acceptProps={{
				disabled: isSaveDisabled
			}}>
			<div className="event-matches">
				{state.matches.map((match, index) => (
					<div className={`match ${match.event === '' ? 'empty' : ''}`} key={index}>
						<Select
							label="Event Type"
							placeholder="Select a Type"
							options={EventCategories}
							value={[match.category]}
							dropdownClassName="event-categories-list"
							onChange={([value]) => handleCategory(value, index)} />
						<div className="operator">
							<span>match to</span>
							<div className="remove-match" onClick={() => removeMatch(index)}>
								<Icon i="trash" size="20" />
							</div>
						</div>
						<Input
							label="Your Event"
							name={`${index}-event-input`}
							placeholder="Event Name"
							value={match.event}
							onChange={event => handleEvent(event, index)}
						/>
					</div>
				))}
			</div>
			<div className="event-matches-add" onClick={handleAdd}>
				<Icon i="plus" size={20} /><span>Add another</span>
			</div>
		</Modal>
	);
}

export default EventMatch;
