import React, { Fragment, useState, useEffect } from 'react';
import sortBy from 'lodash/sortBy';
import find from 'lodash/find';
import {
	Loader,
	NoData,
	StackedAvatars,
	Button,
	Modal,
	Input,
} from 'interceptd-ui';

import CheckboxGroup from '../../components/Select/CheckboxGroup';

import Api from '../../services/api';

import icon from '../../assets/icon-gray.svg';

import './styles/ShareWithPartnerModal.css';

const ShareWithPartnerModal = ({
	open,
	onCancel,
	onSubmit,
	sharedPublishers,
	reportPartners,
}) => {
	const sharedPartnerListLimit = 5;
	const [loading, setLoading] = useState(true);
	const [seeMore, setSeeMore] = useState(false);
	const [partners, setPartners] = useState([]);
	const [selectedPartners, setSelectedPartners] = useState(sharedPublishers);
	const [partnerNameMap, setPartnerMap] = useState({});
	const [searchText, setSearchText] = useState('');

	useEffect(() => {
		const getData = async () => {
			try {
				const partnerResponse = await Api.getPartners({ size: 1000 });
				const publisherResponse = await Api.getPublishers({ size: 1000 });
				const partnerList = partnerResponse?.data?.data || [];
				const publisherList = publisherResponse?.data?.data || [];

				const data = publisherList.filter(d => reportPartners.indexOf(d.id) !== -1).map((p) => {
					const publisher = p;
					const partner = find(partnerList, ['id', publisher.partner]);
					publisher.logo = partner?.logo || null;
					return publisher;
				});

				setLoading(false);
				setPartners(sortBy(data, ['name']));
				setPartnerMap(data.reduce((acc, curr) => {
					acc[curr.id] = curr.name;
					return acc;
				}, {}));
			} catch (err) {
				setLoading(false);
			}
		};

		getData();
	}, [reportPartners]);

	const renderCheckboxGroupHeader = () => {
		if (selectedPartners.length === 0) {
			return null;
		}

		return (
			<div className="selected-partner-logo-list">
				<StackedAvatars avatars={selectedPartners.map((id) => {
					const partner = partners.find(p => p.id === id);
					return ({
						src: partner?.logo,
						alt: partner?.name,
					});
				})} />
			</div>
		);
	}

	const handleSelectPartner = (value, checked) => {
		let newSelectedPartners = [...selectedPartners];
		if (Array.isArray(value)) {
			newSelectedPartners = checked
				? value
				: [];
		} else {
			const i = newSelectedPartners.indexOf(value);
			if (i === -1) {
				newSelectedPartners.push(value);
			} else {
				newSelectedPartners.splice(i, 1);
			}
		}
		setSelectedPartners(newSelectedPartners)
	}

	const revokedPartners = sharedPublishers.filter(p => selectedPartners.indexOf(p) === -1);

	return (
		<Modal
			open={open}
			onCancel={onCancel}
			modalClassName="share-with-partner-modal"
			title="SHARE WITH PARTNER"
			subtitle="The customized report will be shared on the partner dashboard. If you want to revoke the report later please uncheck the partner."
			renderContent={() => (
				<div className="share-with-partner-wrapper">
					<div className="share-with-partner-search">
						<Input
							placeholder="Search partner"
							onChange={e => setSearchText(e.target.value.toLocaleLowerCase())}
						/>
					</div>
					{loading ? <Loader /> : (
						partners.length === 0 ? <NoData icon="box">You don't have any publisher</NoData> : (
							<Fragment>
								<div className="share-with-partner-list">
									<CheckboxGroup
										showSelectAll
										renderHeader={renderCheckboxGroupHeader}
										onChange={handleSelectPartner}
										value={selectedPartners}
										options={!searchText ? partners : partners.filter(p => (p?.name || '').toLocaleLowerCase().indexOf(searchText) > -1)}
										renderLabel={o => (
											<div className="share-with-partner-item">
												<div className="partner-logo">
													<img src={o.logo || icon} alt={`${o.name} logo`} />
												</div>
												<span>{o.name}</span>
											</div>
										)}
									/>
								</div>
								{selectedPartners.length > 0 && <div className={`share-with-partner-selected ${seeMore ? 'expand' : ''}`}>
									<span className="share-with-partner-selected-title">Share List:</span>
									{selectedPartners.filter((s, i) => (seeMore || i + 1 <= sharedPartnerListLimit)).map(s => partnerNameMap[s]).join(', ')}
									{selectedPartners.length > sharedPartnerListLimit && <span className="share-with-partner-selected-title see-more" onClick={() => setSeeMore(!seeMore)}>see {seeMore ? 'less' : 'more'}</span>}
								</div>}
								{revokedPartners.length > 0 && <div className={`share-with-partner-selected ${seeMore ? 'expand' : ''}`}>
									<span className="share-with-partner-selected-title">Unshare List:</span>
									{revokedPartners.filter((s, i) => (seeMore || i + 1 <= sharedPartnerListLimit)).map(s => partnerNameMap[s]).join(', ')}
									{revokedPartners.length > sharedPartnerListLimit && <span className="share-with-partner-selected-title see-more" onClick={() => setSeeMore(!seeMore)}>see {seeMore ? 'less' : 'more'}</span>}
								</div>}
							</Fragment>
						)
					)}
				</div>
			)}
			renderFooter={() => (
				<div className="share-with-partner-footer">
					<Button mini bgColor="transparent" onClick={onCancel}>Dismiss</Button>
					<Button mini onClick={() => onSubmit(selectedPartners)} disabled={loading}>Update</Button>
				</div>
			)}
		/>
	)
}

ShareWithPartnerModal.defaultProps = {
	open: false,
	sharedPublishers: [],
};

export default ShareWithPartnerModal;