import React from 'react';
import { toast } from 'react-toastify';
import { Link, Redirect } from 'react-router-dom';
import get from 'lodash/get';
import {
	Box,
	NoData,
	Icon,
	PageTitle,
	Button,
} from 'interceptd-ui';

import Table from '../../components/Table';

import Api from '../../services/api';
import SendEvent from '../../services/events';
import Local from '../../services/localStorage';

import { columns } from './ListColumns';

import { isStoreURL } from '../../utils/misc';

import './styles/List.css';

import withTitle from '../withTitle';

class List extends React.Component {
	state = {
		fetching: true,
		error: false,
		data: [],
		pages: -1,
		apps: {},
		empty: false,
		scores: {},
	}

	fetchData = async (state) => {
		try {
			this.setState({
				fetching: true,
				data: []
			});

			const reports = await Api.getRedirectionReports({
				page: state.page + 1,
				size: state.pageSize,
			});
			const data = get(reports, 'data.data', []);
			this.setState({	
				data,
				fetching: false,
				isEmpty: data.length === 0,
			}, this.fetchApps)

		} catch (err) {
			toast.error(`Couldn't get redirection report list`);

			SendEvent({
				description: `getRedirectionReports on redirection report list`,
				fatal: true
			});

			this.setState({
				fetching: false,
				error: true,
			});
		}
	}

	fetchApps = () => {
		const { data } = this.state;

		data.forEach(report => {
			if (report.package_name || (report.campaigns && report.campaigns.length > 0)) {
				this.getAppInfo(report.id, (report.package_name || report.campaigns[0].store_url))
				this.getScores(report.id, (report.package_name || report.campaigns[0].store_url))
			}
		})
	}

	getAppInfo = (id, package_name) => {
		const store_url = package_name;
		const getType = str => (str.startsWith('id') && str.length >= 11 && !isNaN(str.replace('id', ''))) || (!isNaN(str) && str.length >= 9) ? 'ios' : 'android';
		const type = getType(store_url);

		const url = (package_name.indexOf('itunes.apple') > -1 || package_name.indexOf('play.google') > -1) ? package_name :
			type === 'ios' ? `https://itunes.apple.com/app/${!isNaN(store_url) ? 'id' : ''}${store_url}` :
				type === 'android' ? `https://play.google.com/store/apps/details?id=${store_url}` :
					isStoreURL(store_url) ? store_url : null;

		Api.getAppInfo({ url: url })
			.then(response => {
				this.setState(prevState => ({
					apps: {
						...prevState.apps,
						[id]: response.data,
					}
				}))
			}).catch(() => {
				toast.error('Unable to fetch app info. Please check the store url.', { autoClose: 5000 });
			});
	}

	getScores = (reportId, appId) => {
		if (!appId) return;

		const me = Local.getItem('me');

		Api.getSurveyResult(appId, me.id)
			.then((response) => {
				const score = get(response, 'data.data.fraud_score', null);
				let fraudScore = '-';

				if (score < 5) fraudScore = 'Low';
				if (score >= 5 && score <= 10) fraudScore = 'Medium';
				if (score > 10 && score <= 20) fraudScore = 'High';
				if (score > 20) fraudScore = 'Extremely High';

				this.setState(prevState => ({
					scores: {
						...prevState.scores,
						[reportId]: {
							fraudScore,
							fraudPercent: `%${(score < 3 ? 3 : score) * 4}`
						},
					}
				}));
			});
	}

	render() {
		const { fetching, data, pages, apps, isEmpty, scores } = this.state;
		const me = Local.getItem('me');

		if (isEmpty) {
			return <Redirect push to="/survey?ref=insight" />
		}

		return (
			<div className="redirection-report-list">
				<PageTitle>
					<PageTitle.Title>Fraud Health Assessment</PageTitle.Title>
				</PageTitle>

				<Box
					className="box-table-wrapper"
					title="Reports"
					right={
						<React.Fragment>
							{!fetching && (me.plans?.interceptd !== 'free' || (data && data.length < 2)) && (
								<Link
									to={{
										pathname: '/survey',
										state: {
											appNames: data.map(d => d.app_name)
										}
									}}
									className="button bg-primary"
									data-event-category="Redirection Report List"
									data-event-action="Create new campaign button clicked">
									<Icon i="plus" /> Start New Fraud Health Assessment
								</Link>
							)}
						</React.Fragment>
					}>
					<Table
						ref={el => { this.table = el }}
						data={data}
						columns={columns(apps, scores)}
						pages={pages}
						pageSize={100}
						PadRowComponent={() => null}
						loading={fetching}
						manual
						onFetchData={this.fetchData}
						showPagination={false}
						sortable={false}
						defaultSortDesc={true}
						NoDataComponent={() =>
							<NoData icon="git-pull-request">
								You didn't request any report yet
								<Button to="/fraud-insights/request" component={Link}>Request a Fraud Health Assessment Report</Button>
							</NoData>
						} />
				</Box>
			</div>
		);
	}
}

export default withTitle(List, 'Fraud Health Assessment');
