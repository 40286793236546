import React from 'react';
import { toast } from 'react-toastify';
import { IntercomAPI } from 'react-intercom';
import { Redirect } from 'react-router-dom';
import get from 'lodash/get';
import {
	Icon,
	Button,
	Modal,
	Input,
} from 'interceptd-ui';

import { shadowText } from '../../utils/misc';

import Api from '../../services/api';
import Local from '../../services/localStorage';
import Auth from '../../services/auth';

class MyAccountUpdate extends React.Component {
	state = {
		changingPassword: false,
		form: {
			fullname: shadowText(Local.getItem('me').fullname, 'Full Name'),
			new_email: shadowText(Local.getItem('me').email, 'Email'),
			password: '',
			new_password: '',
			repeat_new_password: '',
		},
		isSubmiting: false,
		openModal: false,
		logout: false,
	}

	updateForm = (key, value) => {
		const { form } = this.state;
		return this.setState({
			form: {
				...form,
				[key]: value
			}
		})
	}

	handleInput = event => {
		const key = event.target.name;
		const value = event.target.value;
		this.updateForm(key, value);
	}

	handleChangePassword = () => {
		this.setState({
			changingPassword: true
		})
	}

	handleSubmit = () => {
		const { changingPassword, form } = this.state;

		const payload = changingPassword ? ({
			...form,
			settings: Local.getItem('settings'),
			repeat_new_password: undefined,
		}) : ({
			...form,
			settings: Local.getItem('settings'),
			new_password: undefined,
			repeat_new_password: undefined,
		});

		this.setState({ isSubmiting: true })
		Api.updateAccount(payload)
			.then(response => {
				toast.success(changingPassword ? `Password changed` : `Account updated`);
				Api.getMe().then(res => {
					const me = get(res, 'data.data.user') || get(res, 'data.data', {});
					Local.setItem('me', me)
					this.setState({
						isSubmiting: false,
						changingPassword: false,
						form: {
							fullname: me.fullname,
							new_email: me.email,
							password: '',
							new_password: '',
							repeat_new_password: '',
						}
					});
					if (!Local.getItem('login_as')) {
						IntercomAPI('update', {
							email: me.email,
							name: me.fullname,
						});
					}
				}).catch(Auth.logout)
			})
			.catch(({ response }) => {
				this.setState({
					isSubmiting: false
				});
				toast.error(changingPassword ? `Couldn't change password` : `Couldn't update account`);
			})
	}

	openModal = () => {
		this.setState({openModal: true });
	}

	closeModal = () => {
		this.setState({ openModal: false });
	}

	deleteAccount = () => {
		Api.deleteAccount()
			.then(() => {
				this.setState({logout: true});
			})
			.catch(() => {
				toast.error(`Couldn't delete account`);
			});
	}

	render() {
		const {
			changingPassword,
			form,
			isSubmiting,
			openModal,
			logout,
		} = this.state;

		if (logout) {
			return <Redirect to="/logout" />
		}

		return (
			<div className="account-page">
				<h4>Update Account</h4>
				{!changingPassword &&
					<React.Fragment>
						<Input
							label="Full Name"
							placeholder=""
							name="fullname"
							value={form.fullname}
							onChange={this.handleInput} />
						<Input
							label="Email Address"
							placeholder=""
							name="new_email"
							value={form.new_email}
							onChange={this.handleInput} />
					</React.Fragment>
				}
				<Input
					type="password"
					label={changingPassword ? 'Old Password' : 'Password'}
					placeholder=""
					name="password"
					value={form.password}
					onChange={this.handleInput} />
				{changingPassword ? (
					<React.Fragment>
						<Input
							type="password"
							label="New Password"
							placeholder=""
							name="new_password"
							value={form.new_password}
							onChange={this.handleInput} />
						<Input
							type="password"
							label="Repeat New Password"
							placeholder=""
							name="repeat_new_password"
							value={form.repeat_new_password}
							onChange={this.handleInput}
							error={(form.new_password !== '' && form.repeat_new_password !== '' && form.new_password !== form.repeat_new_password) && 'Passwords are not same'} />
					</React.Fragment>
				) : (
					<div className="change-password-toggle" onClick={this.handleChangePassword}>
						<Icon i="edit-2" size={18} />
						<span>Change password</span>
					</div>
				)}
				<div className="account-page-action-buttons">
					<Button onClick={this.handleSubmit} loading={isSubmiting} disabled={changingPassword ? ((form.new_password !== '' && form.repeat_new_password !== '' && form.new_password !== form.repeat_new_password) || form.password === '' || form.new_password === '' || form.repeat_new_password === '') : form.password === ''}>{changingPassword ? 'Update password' : 'Save changes'}</Button>
					<Button onClick={this.openModal} loading={isSubmiting} bgColor="transparent" className="delete-my-account-button">Delete My Account</Button>
				</div>
				<Modal
					open={openModal}
					title="Removed accounts can't be restored. Are you absolutely sure?"
					onCancel={this.closeModal}
					onAccept={this.deleteAccount}
					defaultAcceptText="Delete My Account"
					acceptProps={{
						bgColor: 'red'
					}} />
			</div>
		)
	}
}

export default MyAccountUpdate;
