import React from 'react';
import { Redirect } from 'react-router-dom';

import SendEvent from '../../services/events';

class LoginAs extends React.Component {
	UNSAFE_componentWillMount() {
		SendEvent({
			category: 'NonAuth',
			action: 'LoggedIn as user',
			nonInteraction: true,
		});
	}

	render() {
		return <Redirect to="/" />;
	}
}

export default LoginAs;