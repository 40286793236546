import React from 'react';
import moment from 'moment';
import { Tooltip, AppIcon } from 'interceptd-ui';

import { isShadow } from '../../../utils/misc';

export const rulesetCampaignsColumns = rulesetCampaignsCount => [
	{
		Header: 'Name',
		id: 'name',
		accessor: 'name',
		cast: 'string',
		minWidth: 190,
		style: {
			overflow: 'visible',
		},
		permanent: true,
		Cell: props => (
			<span className="campaign-table-name">
				<AppIcon app={props.original.app_name ? props.original.app_info : undefined} isShadow={isShadow()} />
				<span>{props.value}</span>
			</span>
		),
		Footer: props => `${rulesetCampaignsCount} Campaigns`
	},
	{
		Header: 'Country',
		id: 'country',
		accessor: 'country',
		cast: 'string',
		minWidth: 90,
		Cell: props => <Tooltip position="top" tooltip={props.value && props.value.length >= 5 && props.value.join(', ')}>{props.value ? props.value.length < 5 ? props.value.join(', ') : 'Multi' : ''}</Tooltip>,
	},
	{
		Header: 'Start Date',
		id: 'ts_start',
		accessor: 'ts_start',
		cast: 'date',
		minWidth: 95,
		Cell: props => moment(props.value, 'X').format('DD-MM-YYYY'),
	},
	{
		Header: 'End Date',
		id: 'ts_end',
		accessor: 'ts_end',
		cast: 'date',
		minWidth: 95,
		Cell: props => props.value ? moment(props.value, 'X').format('DD-MM-YYYY') : 'No End Date',
	},
];