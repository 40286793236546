import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Tooltip } from 'interceptd-ui';

import { shadowText } from '../../utils/misc';

export const columns = ({
	match,
	renderActions,
	renderFraudGraphCell,
	eventsColumnWidth,
	showImpression,
	type,
}) => [
	{
		Header: 'Source Name',
		id: 'name',
		accessor: 'name',
		cast: 'string',
		width: 300,
		style: {
			overflow: 'visible',
		},
		permanent: true,
		sticky: true,
		stickyPosition: 'left',
		Cell: props => (<Link className="source-table-name" to={`${match.url}/source/${props.original.id}`}>{shadowText(props.value, 'Example Source')} - {props.original.id} {props.original.check_bot ? (<Tooltip position="right" tooltip="Landing Page Active"><Icon i="git-merge" size={16} /></Tooltip>) : null} {(props.original.selected_events && props.original.selected_events.length > 0) ? (<Tooltip position="right" tooltip={`Selected Events for Postback: ${props.original.selected_events.join(', ')}`}><Icon i="filter" size={16} /></Tooltip>) : null}</Link>)
	},
	{
		Header: 'Impression',
		id: 'impression',
		accessor: 'id',
		cast: 'string',
		width: 220,
		show: showImpression,
		Cell: renderFraudGraphCell,
	},
	{
		Header: 'Clicks',
		id: 'click',
		accessor: 'stats',
		cast: 'string',
		width: 220,
		Cell: renderFraudGraphCell,
	},
	{
		Header: 'Installs',
		id: 'install',
		accessor: 'stats',
		cast: 'string',
		width: 220,
		Cell: renderFraudGraphCell,
	},
	{
		Header: 'Events',
		id: 'event',
		accessor: 'stats',
		cast: 'string',
		minWidth: eventsColumnWidth,
		Cell: renderFraudGraphCell,
	},
	{
		Header: '',
		id: 'actions',
		accessor: 'id',
		cast: 'actions',
		width: 65,
		resizable: false,
		sticky: true,
		headerStyle: {
			overflow: 'visible',
			justifyContent: 'center',
			padding: 0,
		},
		style: {
			overflow: 'visible',
			justifyContent: 'center',
			padding: 0,
		},
		permanent: true,
		Cell: props => renderActions(props)
	},
];