import React, { Component, Fragment } from 'react';
import { Route, NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import findIndex from 'lodash/findIndex';
import unionBy from 'lodash/unionBy';
import {
	Loader,
	PageTitle,
	SecondaryNav,
	FilterBox,
	Select,
	DatePicker,
} from 'interceptd-ui';

import GraphView from './components/GraphView';
import TableView from './components/TableView';

import { shadowText, geSourcePayoutCurrency, getInitialDateFilter } from '../../utils/misc';
import { getTimestamp } from '../../utils/transform';

import Api from '../../services/api';
import Local from '../../services/localStorage';
import Settings from '../../services/settings';

import withTitle from '../withTitle';

import './styles/DailyPerformance.css';

class DailyPerformance extends Component {
	state = {
		loading: true,
		showCohortData: false,
		detailFetching: false,
		statsFetching: false,
		expandedState: {},
		sourceAppMap: {},
		apps: {},
		stores: {},
		appIds: [],
		sources: [],
		newSourceList: [],
		partners: [],
		appOptions: [],
		eventNameOptions: [],
		eventColumns: [],
		selectedApps: [],
		selectedPlatform: null,
		newSorted: null,
		selectedPartners: [],
		sourcePartners: [],
		appsFetching: false,
		dailyData: [],
		selectKey: '0',
		filters: getInitialDateFilter(),
		archivedCampaigns: Settings.get('archivedCampaigns', 'account') || [],
		sourceSettings: Settings.get('sourceSettings', 'account') || {},
	}

	componentDidMount = () => {
		Api.getPartners({
			page: 1,
			size: 1000
		})
			.then((response) => {
				const partners = get(response, 'data.data', []);
				this.setState({ partners }, this.getCampaigns);
			})
			.catch(() => {
				this.setState({ loading: false });
				toast.error(`Can't fetch partners.`);
			});
	}

	convertToStatsCellFormat = (data) => {
		const d = { ...data };

		const cleanClickCount = (+d.clean_click_count || 0);
		const rejectedClickCount = (+d.rejected_click_count || 0);
		const flaggedClickCount = (+d.flagged_click_count || 0);

		const cleanInstallCount = (+d.clean_install_count || 0);
		const rejectedInstallCount = (+d.rejected_install_count || 0);
		const flaggedInstallCount = (+d.flagged_install_count || 0);

		const cleanAmount = (+d.clean_amount || 0);
		const rejectedAmount = (+d.rejected_amount || 0);
		const flaggedAmount = (+d.flagged_amount || 0);

		const clean_reattributed_amount = (+d.clean_reattributed_amount || 0);
		const flagged_reattributed_amount = (+d.flagged_reattributed_amount || 0);
		const rejected_reattributed_amount = (+d.rejected_reattributed_amount || 0);

		d.click__total = (cleanClickCount + rejectedClickCount);
		d.install__total = (cleanInstallCount + rejectedInstallCount + flaggedInstallCount);
		d.event__total = 0;

		d.stats = {
			click: {
				clean: cleanClickCount,
				rejected: rejectedClickCount,
				flagged: flaggedClickCount,
				total: (cleanClickCount + rejectedClickCount + flaggedClickCount),
			},
			install: {
				clean: cleanInstallCount,
				rejected: rejectedInstallCount,
				flagged: flaggedInstallCount,
				total: (cleanInstallCount + rejectedInstallCount + flaggedInstallCount),
			},
			event: {
				clean: 0,
				rejected: 0,
				flagged: 0,
				total: 0,
			},
			event_reattributed_count: {
				clean: 0,
				rejected: 0,
				flagged: 0,
				total: 0,
			},
			amount: {
				clean: cleanAmount,
				rejected: rejectedAmount,
				flagged: flaggedAmount,
				total: (cleanAmount + rejectedAmount + flaggedAmount),
			},
			reattributed_amount: {
				clean: clean_reattributed_amount,
				rejected: rejected_reattributed_amount,
				flagged: flagged_reattributed_amount,
				total: (clean_reattributed_amount + rejected_reattributed_amount + flagged_reattributed_amount),
			},
			event_amount: {
				clean: 0,
				rejected: 0,
				flagged: 0,
				total: 0,
			},
			event_reattributed_amount: {
				clean: 0,
				rejected: 0,
				flagged: 0,
				total: 0,
			},
		};

		return d;
	}

	switchCohort = (on) => {
		this.setState({ showCohortData: on }, this.getDailyData);
	}

	exportData = (campaigns) => {
		const { partners } = this.state;
		const sourceAppMap = {};
		const apps = {};
		const sources = [];

		campaigns.forEach((c) => {
			apps[c.app] = apps[c.app] || c.app_info;
			c.sources.forEach((s) => {
				const i = findIndex(partners, ['id', s.partner]);
				s.appId = c.app;
				s.campaignName = c.name;
				s.type = c.type;
				s.app = c.app_info;
				s.platform = c.app_info.device;
				sourceAppMap[s.id] = c.app_info;
				s.events = {};
				s.daily = [];
				if (partners[i]) {
					s.partnerId = partners[i].id;
					s.partner = partners[i];
				}
				s.stats = {
					click: {
						clean: 0,
						rejected: 0,
						flagged: 0,
						total: 0,
					},
					install: {
						clean: 0,
						rejected: 0,
						flagged: 0,
						total: 0,
					},
					event: {
						clean: 0,
						rejected: 0,
						flagged: 0,
						total: 0,
					},
					event_reattributed_count: {
						clean: 0,
						rejected: 0,
						flagged: 0,
						total: 0,
					},
					amount: {
						clean: 0,
						rejected: 0,
						flagged: 0,
						total: 0,
					},
					reattributed_amount: {
						clean: 0,
						rejected: 0,
						flagged: 0,
						total: 0,
					},
					event_amount: {
						clean: 0,
						rejected: 0,
						flagged: 0,
						total: 0,
					},
					event_reattributed_amount: {
						clean: 0,
						rejected: 0,
						flagged: 0,
						total: 0,
					},
				};
				sources.push(s);
			});
		});

		const appOptions = Object.values(apps).map(a => ({
			label: shadowText(a.title, 'Example App'),
			value: a.id,
		}));

		return {
			sources,
			apps,
			sourceAppMap,
			appOptions,
		};
	}

	getCampaigns = async () => {
		try {
			this.setState({ loading: true });

			const { archivedCampaigns } = this.state;

			const res = await Api.getLeoparCampaigns();

			const campaigns = get(res, 'data.data', []).filter((c) => c.application_type !== 'leancheck').filter(s => !archivedCampaigns.some(c => c === s.id));
			const {
				sources,
				sourceAppMap,
				apps,
				appOptions,
			} = this.exportData(campaigns);

			this.setState({
				apps,
				sources,
				appOptions,
				sourceAppMap,
				loading: false,
				sourcePartners: unionBy(sources.map(s => s.partner), 'id'),
			}, this.getDailyData);
		} catch (err) {
			this.setState({
				loading: false,
			});
		}
	}

	getDailyData = async () => {
		try {
			const { sources, filters, showCohortData } = this.state;
			const sourceIds = [...sources].map(s => s.id);
			this.setState({ detailFetching: true });

			if (!sourceIds || sourceIds.length === 0) {
				return this.setState({ detailFetching: false });
			};

			const q = {
				source_ids: sourceIds,
				ts_start: getTimestamp(filters.start),
				ts_end: getTimestamp(filters.end, true),
			};

			const clickResponse = await Api.getLeoparClickDailyStats(q);
			const installResponse = await Api.getLeoparInstallAndEventDailyStats(q);
			const clickCounts = get(clickResponse, 'data.data', []);
			const installCounts = get(installResponse, 'data.data', []);
			const idMap = {};

			sources.forEach((d) => {
				if (!d.partnerId) return;

				const sourceClicks = clickCounts.filter(c => c.source_id === d.id);
				const sourceInstalls = installCounts.filter(i => i.source_id === d.id);
				const [primary, secondary] = sourceClicks.length > sourceInstalls.length
					? [sourceClicks, sourceInstalls]
					: [sourceInstalls, sourceClicks];
				const daily = [];

				primary.forEach((sc) => {
					const i = findIndex(secondary, ['date', sc.date]);
					const si = i > -1
						? secondary[i]
						: {
							clean_install_count: 0,
							flagged_install_count: 0,
							rejected_install_count: 0,
						};
					const dailyData = {
						...sc,
						...secondary[i],
						events: {},
						stats: {
							click: {
								clean: sc.clean_click_count,
								flagged: sc.flagged_click_count,
								rejected: sc.rejected_click_count,
							},
							install: {
								clean: si.clean_install_count,
								flagged: si.flagged_install_count,
								rejected: si.rejected_install_count,
							},
							event: {
								clean: 0,
								flagged: 0,
								rejected: 0,
							}
						}
					};

					daily.push(this.convertToStatsCellFormat({
						...d,
						...dailyData,
					}));
				});

				const key = `${d.name}-${d.id}`;
				idMap[key] = idMap[key] || {
					...d,
					name: d.name,
					clean_amount: 0,
					clean_reattributed_amount: 0,
					clean_click_count: 0,
					flagged_click_count: 0,
					flagged_install_count: 0,
					rejected_click_count: 0,
					flagged_amount: 0,
					clean_install_count: 0,
					flagged_reattributed_amount: 0,
					rejected_install_count: 0,
					rejected_amount: 0,
					rejected_reattributed_amount: 0,
					cost: 0,
					daily,
					events: {},
				};

				idMap[key].daily.forEach((sourceDaily) => {
					idMap[key].clean_amount += (+sourceDaily.clean_amount) || 0;
					idMap[key].clean_reattributed_amount += (+sourceDaily.clean_reattributed_amount) || 0;
					idMap[key].clean_click_count += (+sourceDaily.clean_click_count) || 0;
					idMap[key].flagged_click_count += (+sourceDaily.flagged_click_count) || 0;
					idMap[key].flagged_amount += (+sourceDaily.flagged_amount) || 0;
					idMap[key].flagged_install_count += (+sourceDaily.flagged_install_count) || 0;
					idMap[key].flagged_reattributed_amount += (+sourceDaily.flagged_reattributed_amount) || 0;
					idMap[key].clean_install_count += (+sourceDaily.clean_install_count) || 0;
					idMap[key].rejected_amount += (+sourceDaily.rejected_amount) || 0;
					idMap[key].rejected_click_count += (+sourceDaily.rejected_click_count) || 0;
					idMap[key].rejected_install_count += (+sourceDaily.rejected_install_count) || 0;
					idMap[key].rejected_reattributed_amount += (+sourceDaily.rejected_reattributed_amount) || 0;
				});
			});

			const newSourceList = [...Object.values(idMap).map(this.convertToStatsCellFormat)];

			const response = showCohortData
				? await Api.getCohortDailyPerformance({
					start: getTimestamp(filters.start),
					end: getTimestamp(filters.end, true),
					source_ids: sourceIds,
				})
				: await Api.getEventDailyPerformance({
					ts_start: getTimestamp(filters.start),
					ts_end: getTimestamp(filters.end, true),
					source_ids: sourceIds,
				});
			const data = get(response, 'data.data', []);

			const eventNameOptions = uniq([...data].map(d => d.event_name)).map(e => ({
				label: e,
				value: e,
			}));

			newSourceList.map((s) => {
				const source = s;
				source.name = shadowText(s.name, 'Example Source');

				data.forEach((e) => {
					const event = e;
					const name = e.event_name;

					source.events[name] = source.events[name] || {
						clean: 0,
						rejected: 0,
						flagged: 0,
						total: 0,
					};

					const cc = (+event.clean_event_count || 0);
					const fc = (+event.flagged_event_count || 0);
					const rc = (+event.rejected_event_count || 0);
					const t_count = (cc + fc + rc);

					const ca = (+e.clean_amount || 0);
					const fa = (+e.flagged_amount || 0);
					const ra = (+e.rejected_amount || 0);
					const t_amount = (ca + fa + ra);

					const crc = (+e.clean_reattributed_event_count || 0);
					const frc = (+e.flagged_reattributed_event_count || 0);
					const rrc = (+e.rejected_reattributed_event_count || 0);
					const total_reattributed_count = (crc + frc + rrc);

					const cra = (+e.clean_reattributed_amount || 0);
					const fra = (+e.flagged_reattributed_amount || 0);
					const rra = (+e.rejected_reattributed_amount || 0);
					const total_reattributed_amount = (cra + fra + rra);

					source.daily.map((day) => {
						const dailyData = day;

						dailyData.clean_click_count = dailyData.clean_click_count || 0;
						dailyData.events[name] = dailyData.events[name] || {
							clean: 0,
							rejected: 0,
							flagged: 0,
							total: 0,
						};
						dailyData.events[`${name}_reattributed`] = dailyData.events[`${name}_reattributed`] || {
							clean: 0,
							rejected: 0,
							flagged: 0,
							total: 0,
						};
						dailyData.events[`${name}_amount`] = dailyData.events[`${name}_amount`] || {
							clean: 0,
							rejected: 0,
							flagged: 0,
							total: 0,
						};
						dailyData.events[`${name}_reattributed_amount`] = dailyData.events[`${name}_reattributed_amount`] || {
							clean: 0,
							rejected: 0,
							flagged: 0,
							total: 0,
						};

						if ((Number.parseInt(dailyData.id) !== Number.parseInt(e.source || e.source_id)) || dailyData.date !== e.date) {
							return dailyData;
						}

						dailyData.events[name].clean = cc;
						dailyData.events[name].flagged = fc;
						dailyData.events[name].rejected = rc;
						dailyData.events[name].total = (cc + fc + rc);

						const crec = (+e.clean_reattributed_event_count || 0);
						const frec = (+e.flagged_reattributed_event_count || 0);
						const rrec = (+e.rejected_reattributed_event_count || 0);
						dailyData.events[`${name}_reattributed`].clean = crec;
						dailyData.events[`${name}_reattributed`].flagged = frec;
						dailyData.events[`${name}_reattributed`].rejected = rrec;
						dailyData.events[`${name}_reattributed`].total = (crec + frec + rrec);

						const cea = (+e.clean_amount || 0);
						const fea = (+e.flagged_amount || 0);
						const rea = (+e.rejected_amount || 0);
						dailyData.events[`${name}_amount`].clean = cea;
						dailyData.events[`${name}_amount`].flagged = fea;
						dailyData.events[`${name}_amount`].rejected = rea;
						dailyData.events[`${name}_amount`].total = (cea + fea + rea);

						const cera = (+e.clean_reattributed_amount || 0);
						const fera = (+e.flagged_reattributed_amount || 0);
						const rera = (+e.rejected_reattributed_amount || 0);
						dailyData.events[`${name}_reattributed_amount`].clean = cera;
						dailyData.events[`${name}_reattributed_amount`].flagged = fera;
						dailyData.events[`${name}_reattributed_amount`].rejected = rera;
						dailyData.events[`${name}_reattributed_amount`].total = (cera + fera + rera);

						dailyData.event__total += t_count;
						dailyData.stats.event.clean += cc;
						dailyData.stats.event.flagged += fc;
						dailyData.stats.event.rejected += rc;
						dailyData.stats.event.total += t_count;
						dailyData.stats.event_amount.clean += ca;
						dailyData.stats.event_amount.flagged += fa;
						dailyData.stats.event_amount.rejected += ra;
						dailyData.stats.event_amount.total += t_amount;

						dailyData.stats.event_reattributed_count.clean += crc;
						dailyData.stats.event_reattributed_count.flagged += frc;
						dailyData.stats.event_reattributed_count.rejected += rrc;
						dailyData.stats.event_reattributed_count.total += total_reattributed_count;

						dailyData.stats.event_reattributed_amount.clean += cra;
						dailyData.stats.event_reattributed_amount.flagged += fra;
						dailyData.stats.event_reattributed_amount.rejected += rra;
						dailyData.stats.event_reattributed_amount.total += total_reattributed_amount;

						return dailyData;
					});

					source.events[name] = source.events[name] || {
						clean: 0,
						flagged: 0,
						rejected: 0,
						total: 0,
					};

					source.events[`${name}_amount`] = source.events[`${name}_amount`] || {
						clean: 0,
						flagged: 0,
						rejected: 0,
						total: 0,
					};

					source.events[`${name}_reattributed`] = source.events[`${name}_reattributed`] || {
						clean: 0,
						flagged: 0,
						rejected: 0,
						total: 0,
					};

					source.events[`${name}_reattributed_amount`] = source.events[`${name}_reattributed_amount`] || {
						clean: 0,
						flagged: 0,
						rejected: 0,
						total: 0,
					};

					if (Number.parseInt(source.id) !== Number.parseInt(e.source || e.source_id)) {
						return;
					}

					source.event__total += t_count;
					source.stats.event.clean += cc;
					source.stats.event.flagged += fc;
					source.stats.event.rejected += rc;
					source.stats.event.total += t_count;
					source.stats.event_amount.clean += ca;
					source.stats.event_amount.flagged += fa;
					source.stats.event_amount.rejected += ra;
					source.stats.event_amount.total += t_amount;

					source.stats.event_reattributed_count.clean += crc;
					source.stats.event_reattributed_count.flagged += frc;
					source.stats.event_reattributed_count.rejected += rrc;
					source.stats.event_reattributed_count.total += total_reattributed_count;

					source.stats.event_reattributed_amount.clean += cra;
					source.stats.event_reattributed_amount.flagged += fra;
					source.stats.event_reattributed_amount.rejected += rra;
					source.stats.event_reattributed_amount.total += total_reattributed_amount;

					source.events[name] = source.events[name] || {
						clean: 0,
						flagged: 0,
						rejected: 0,
						total: 0,
					};
					source.events[name].clean += cc;
					source.events[name].flagged += fc;
					source.events[name].rejected += rc;
					source.events[name].total += t_count;

					source.events[`${name}_amount`] = source.events[`${name}_amount`] || {
						clean: 0,
						flagged: 0,
						rejected: 0,
						total: 0,
					};
					source.events[`${name}_amount`].clean += ca;
					source.events[`${name}_amount`].flagged += fa;
					source.events[`${name}_amount`].rejected += ra;
					source.events[`${name}_amount`].total += t_amount;

					source.events[`${name}_reattributed`] = source.events[`${name}_reattributed`] || {
						clean: 0,
						flagged: 0,
						rejected: 0,
						total: 0,
					};
					source.events[`${name}_reattributed`].clean += crc;
					source.events[`${name}_reattributed`].flagged += frc;
					source.events[`${name}_reattributed`].rejected += rrc;
					source.events[`${name}_reattributed`].total += total_reattributed_count;

					source.events[`${name}_reattributed_amount`] = source.events[`${name}_reattributed_amount`] || {
						clean: 0,
						flagged: 0,
						rejected: 0,
						total: 0,
					};
					source.events[`${name}_reattributed_amount`].clean += cra;
					source.events[`${name}_reattributed_amount`].flagged += fra;
					source.events[`${name}_reattributed_amount`].rejected += rra;
					source.events[`${name}_reattributed_amount`].total += total_reattributed_amount;
				});

				const cost = this.calculateCost(source);
				source.cost = cost.cost;
				source.costByEvent = cost.costByEvent;
				source.payout_symbol = cost.payout_symbol;

				source.daily.forEach((d) => {
					const dailyCost = this.calculateCost(d);
					d.cost = dailyCost.cost;
					d.costByEvent = dailyCost.costByEvent;
					d.payout_symbol = dailyCost.payout_symbol;
				});

				return source;
			});

			const eventColumns = [];
			[...eventNameOptions].forEach((e) => {
				if (newSourceList.some(s => s.events[e.label].total > 0)) {
					eventColumns.push({
						Header: e.label,
						id: e.label,
						commonId: e.label,
						accessor: 'events',
						cast: 'string',
						width: 200,
						Cell: this.renderStatsCell,
					});
				}

				if (newSourceList.some(s => s.events[`${e.label}_reattributed`].total > 0)) {
					eventColumns.push({
						Header: `${e.label} Reattributed`,
						id: `${e.label}_reattributed`,
						commonId: e.label,
						accessor: 'events',
						cast: 'string',
						width: 200,
						Cell: this.renderStatsCell,
					});
				}

				if (newSourceList.some(s => s.events[`${e.label}_amount`].total > 0)) {
					eventColumns.push({
						Header: `${e.label} Amount`,
						id: `${e.label}_amount`,
						commonId: e.label,
						accessor: 'events',
						cast: 'string',
						width: 200,
						Cell: this.renderStatsCell,
					});
				}

				if (newSourceList.some(s => s.events[`${e.label}_reattributed_amount`].total > 0)) {
					eventColumns.push({
						Header: `${e.label} Reattributed Amount`,
						id: `${e.label}_reattributed_amount`,
						commonId: e.label,
						accessor: 'events',
						cast: 'string',
						width: 200,
						Cell: this.renderStatsCell,
					});
				}
			});

			this.setState({
				newSourceList: newSourceList,
				eventNameOptions: eventNameOptions.map((c) => {
					const col = c;
					col.hide = newSourceList.every(s => !s.events[c.value] || s.events[c.value].total === 0);
					return col;
				}),
				eventColumns,
				detailFetching: false,
			});
		} catch (err) {
			this.setState({ detailFetching: false });
			toast.error(`Couldn't fetch daily stats data.`);
		}
	}

	calculateCost = (data) => {
		const currency = geSourcePayoutCurrency(data.id);
		const payout_symbol = currency?.payoutSymbol;

		const result = {
			cost: 0,
			costByEvent: null,
			payout_symbol,
		};

		if (data.type === 'CPI') {
			result.cost = data.bid && data.bid > 0
				? data.bid * (data.stats.install.clean + data.stats.install.flagged)
				: 0;
			return result;
		}

		result.costByEvent = [];
		Object.keys(data.event_payouts || {}).forEach((e) => {
			const bid = data.event_payouts[e];
			const eventCost = bid * ((data?.events?.[e]?.clean ?? 0) + (data?.events?.[e]?.flagged ?? 0));
			result.costByEvent.push(`${e}: ${payout_symbol}${eventCost}`);
			result.cost += eventCost;
		});
		return result;
	}

	handleDateRange = ({ from, to }) => {
		this.setState({
			filters: {
				start: from,
				end: to,
			}
		}, this.getDailyData);

		Local.setItem('date_filter', {
			from,
			to,
		});
	}

	generateContent = (view) => {
		const {
			loading,
			newSourceList,
			apps,
			detailFetching,
			expandedState,
			eventColumns,
			sourceAppMap,
			selectedPlatform,
			selectedApps,
			selectedPartners,
			eventNameOptions,
			showCohortData,
			resizedState,
			newSorted,
			filters,
		} = this.state;

		if (loading || detailFetching) {
			return <div className="loader-wrapper"><Loader /></div>;
		}

		return (
			<div className="campaign-list daily-performance-list">
				<ViewRoute
					exact
					path="/daily-performance"
					component={GraphView}
					sources={newSourceList}
					apps={Object.values(apps)}
					appNameMap={apps}
				/>

				<ViewRoute
					exact
					path="/daily-performance/table"
					component={TableView}
					sources={newSourceList}
					filters={filters}
					detailFetching={detailFetching}
					expandedState={expandedState}
					eventColumns={eventColumns}
					sourceAppMap={sourceAppMap}
					selectedPlatform={selectedPlatform}
					selectedApps={selectedApps}
					selectedPartners={selectedPartners}
					eventNameOptions={eventNameOptions}
					showCohortData={showCohortData}
					resizedState={resizedState}
					newSorted={newSorted}
					switchCohort={this.switchCohort}
					handleTableColumns={this.handleTableColumns}
				/>
			</div>
		);
	}

	render() {
		const {
			filters,
			selectedPlatform,
			appOptions,
			sourcePartners,
			selectedApps,
			selectedPartners,
		} = this.state;

		const view = /\/table/.test(this.props.location.pathname) ? 'table' : 'graph';

		return (
			<div className="campaign-detail">
				<PageTitle>
					<PageTitle.Title>Daily Performance</PageTitle.Title>
				</PageTitle>

				<FilterBox>
					<FilterBox.Left>
						<SecondaryNav>
							<SecondaryNav.NavItem>
								<NavLink
									exact
									activeClassName="active"
									to="/daily-performance">
									Graph View
								</NavLink>
							</SecondaryNav.NavItem>
							<SecondaryNav.NavItem>
								<NavLink
									exact
									activeClassName="active"
									to="/daily-performance/table">
									Table View
								</NavLink>
							</SecondaryNav.NavItem>
						</SecondaryNav>
					</FilterBox.Left>

					<FilterBox.Right>
						{view === 'table' && (
							<Fragment>
								<Select
									multi
									clearable
									placeholder="App"
									options={appOptions}
									value={selectedApps}
									onChange={sa => this.setState({ selectedApps: sa })} />
								<Select
									multi
									clearable
									placeholder="Publisher"
									options={sourcePartners.map(p => ({
										label: p.name,
										value: p.id,
									}))}
									value={selectedPartners}
									onChange={sp => this.setState({ selectedPartners: sp })} />
								<Select
									placeholder="Platform"
									options={[{
										label: 'Android',
										value: 'android',
									}, {
										label: 'iOS',
										value: 'ios',
									}]}
									value={[selectedPlatform]}
									onChange={([p]) => this.setState({ selectedPlatform: p })} />
							</Fragment>
						)}
						<DatePicker
							value={{
								from: filters.start,
								to: filters.end,
							}}
							onChange={this.handleDateRange} />
					</FilterBox.Right>
				</FilterBox>
				{this.generateContent(view)}
			</div>
		);
	}
}

const ViewRoute = ({
	component: Component,
	path,
	exact,
	...rest
}) => (
		<Route path={path} exact={exact} render={props => {
			return <Component {...props} {...rest} />
		}} />
	);

export default withTitle(DailyPerformance, 'Daily Performance');
