import React from 'react';
import findIndex from 'lodash/findIndex';
import isEqual from 'lodash/isEqual';
import {
	Loader,
	NoData,
} from 'interceptd-ui';

import Sankey from '../../components/Sankey';

import Api from '../../services/api';
import Local from '../../services/localStorage';

import { shadowText, renderAlarmName } from '../../utils/misc';
import { getTimestamp } from '../../utils/transform';

import { SankeyCustomerReasons } from '../../constants/Campaign';
import { RuleNameMaps } from '../../constants/Rules';

class DetailSankey extends React.Component {
	state = {
		fetching: true,
		supported_rules: Local.getItem('supported_rules') || [],
		supported_campaign_rules: Local.getItem('supported_campaign_rules') || [],
		graph: null,
		nodes: null,
		links: null,
		sankeyOptions: {
			sankey: {
				node: {
					interactivity: true,
					width: 20,
					nodePadding: 10,
					labelPadding: 10,
					rx: 3,
					ry: 3,
					label: {
						fontName: 'proxima-nova',
						fontSize: 13,
						color: '#8299B1',
						bold: true,
						italic: false,
					},
					colors: Array(300).fill('#b8d7f5'),
				},
				link: {
					color: {
						fill: '#bcd3ea',
						fillOpacity: 0.5,
					},
				}
			},
			tooltip: {
				isHtml: true
			}
		},
	}

	sankeyParent = React.createRef()

	colors = {
		'Rejected by MMP': '#ED6D6D',
		'Rejected': '#ED6D6D',
		'Flagged': '#F3B071',
		'Clean': '#55C4C2',
	}

	excludeSources = [
		'Rejected by MMP',
		'Rejected',
		'Flagged',
		'Clean',
	]

	supportedRulesListener = null
	supportedCampRulesListener = null

	componentDidMount() {
		if ( !this.props.mock )
			this.getData();
		else
			this.getMockData();

		this.supportedRulesListener = Local.listen('supported_rules', () => {
			this.setState({
				supported_rules: Local.getItem('supported_rules') || [],
			})
		})
		this.supportedCampRulesListener = Local.listen('supported_campaign_rules', () => {
			this.setState({
				supported_campaign_rules: Local.getItem('supported_campaign_rules') || [],
			})
		})
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (!isEqual(this.props.filters, prevProps.filters)) {
			this.getData()
		} else if ( prevProps.eventName !== this.props.eventName ) {
			this.getData()
		}
	}

	componentWillUnmount = () => {
		if ( this.supportedRulesListener )
			Local.unlisten(this.supportedRulesListener)
		if ( this.supportedCampRulesListener )
			Local.unlisten(this.supportedCampRulesListener)
	}

	getData = () => {
		const { filters, campaign, eventName, impression, sourceIds } = this.props;

		this.setState({
			fetching: true,
			graph: null,
			nodes: null,
			links: null,
		});

		const endpoint = impression ? 'getImpressionSankey' : eventName ? 'getLeoparEventSankey' : 'getLeoparSankey';

		Api[endpoint](campaign.id, {
			ts_start: getTimestamp(filters.start),
			ts_end: getTimestamp(filters.end, true),
			event_names: eventName || undefined,
			source_ids: sourceIds || (campaign?.sources || []).map(s => s.id),
		})
			.then(response => {
				const data = response.data.data.filter(d => d.value > 0);
				const graphData = this.mutateSankeyData(data);
				this.setState({ 
					fetching: false,
					...graphData
				});
			})
			.catch(({ response }) => {
				this.setState({
					fetching: false,
					error: response
				});
			});
	}

	getMockData = () => {
		const { mock } = this.props;
		const data = mock.data.filter(d => d.value > 0);
		const graphData = this.mutateSankeyData(data);
		this.setState({ 
			fetching: false,
			...graphData
		});
	}

	mutateSankeyData = data => {
		const { supported_rules, supported_campaign_rules } = this.state;
		const { sourceNames, eventName, campaign } = this.props;

		const supportedRules = [
			...supported_rules,
			{
				name: 'Rejected By MMP',
				'short-name': 'TTB',
			}
		];

		const graph = [
			...data
		].reduce((accumulator, currentValue, currentIndex) => {
			const target = (() => {
				const t = currentValue.target;
				if (t.startsWith('EI_'))  return t.replace('EI_', 'Event/Install Rate ');
				return t.split('_')[0];
			})();

			const curr = {
				...currentValue,
				target,
			}
			const isDuplicate = findIndex(accumulator, i => i.source === curr.source && i.target === curr.target);
			if (isDuplicate !== -1) {
				const newAcc = [...accumulator];
				newAcc[isDuplicate].value = +newAcc[isDuplicate].value + +curr.value;
				return newAcc;
			} else {
				return ([...accumulator, curr]);
			}
		}, []);

		if (
			data.length !== 0 &&
			data.filter(l => l.target === 'Clean').length !== 0
		) {
			graph.push({
				source: 'Clean',
				target: '',
				value: 0.000000001
			})
		}

		if (
			data.length !== 0 &&
			data.filter(l => l.target === 'Rejected').length !== 0 &&
			data.filter(l => l.source === 'Rejected').length === 0
		) {
			graph.push({
				source: 'Rejected',
				target: '',
				value: 0.000000001
			})
		}

		if (
			data.length !== 0 &&
			data.filter(l => l.target === 'Rejected by MMP').length !== 0 &&
			data.filter(l => l.source === 'Rejected by MMP').length === 0
		) {
			graph.push({
				source: 'Rejected by MMP',
				target: '',
				value: 0.000000001
			})
		}

		const nodes = [
			{ type: 'string', label: 'From' },
			{ type: 'string', label: 'To' },
			{ type: 'number', label: 'Weight' },
			{ type: 'string', role: 'tooltip' },
		];

		const map = array => array.map(link => {
			const source = (link.publisher === 0 ? 'Default' : link.publisher_name) || link.source;
			const shadowSourceName = this.excludeSources.indexOf(source) > -1 ? source : shadowText(source, 'Example Source');
			const isRule = supportedRules.filter(r => r['short-name'] === link.target);
			const isCampRule = supported_campaign_rules.filter(r => r['short-name'] === link.target);
			const target = isRule.length > 0 ? (RuleNameMaps[isRule[0].name] || isRule[0].name) : isCampRule.length > 0 ? (RuleNameMaps[isCampRule[0].name] || isCampRule[0].name) : SankeyCustomerReasons[link.target] || link.target;
			const sourceName = Number.isInteger(shadowSourceName) ? sourceNames[shadowSourceName] : shadowSourceName;
			return ([
				sourceName,
				renderAlarmName(target),
				+link.value,
				`${link.value} ${eventName ? `"${eventName}" event${+link.value > 1 ? 's' : ''}` : `${campaign.type === 'CPM' ? 'impression' : 'install'}${+link.value > 1 ? 's' : ''}`} from ${sourceName} is ${renderAlarmName(target)}`,
			]);
		});

		const otherLinks = graph && map(graph);

		const links = this.reduceLinks([
			...otherLinks,
		]);

		return ({
			graph,
			nodes,
			links,
		});
	}

	handleNodesChange = node => {
		if (node.tagName === 'text' && (node.innerHTML === 'Rejected by MMP' || node.innerHTML === 'Rejected' || node.innerHTML === 'Flagged' || node.innerHTML === 'Clean')) {
			node.setAttribute('fill', this.colors[node.innerHTML]);
		}
		if (node.tagName === 'path' && node.getAttribute('fill-opacity') === '0.6') {
			node.setAttribute('fill-opacity', '0.3');
		}
		if (node.tagName === 'path' && node.getAttribute('fill-opacity') === '0.8') {
			node.setAttribute('fill-opacity', '0.5');
		}
		if (node.classList && node.classList.contains('google-visualization-tooltip')) {
			let text = node.innerHTML;
			['Rejected by MMP', 'Rejected', 'Flagged', 'Clean'].forEach(word => {
				text = text.replace(new RegExp(`(${word})`, 'g'), `<span class="${word.toLowerCase()}">$1</span>`)
			});
			node.innerHTML = text;
		}
	}

	reduceLinks = (data) => {
		const copy = data.slice();
		const linkMap = {};

		let count = 0;
		copy.forEach(([graph, name, value]) => {
			linkMap[name] = linkMap[name] || 0;
			linkMap[name] += value;
			if (this.excludeSources.indexOf(graph) !== -1 && name) {
				count += 1;
			}
		});

		const linkNames = Object.keys(linkMap);

		if (count <= 4) {
			return data;
		}

		const linkList = [];
		const constantLinks = [];
		linkNames.forEach((name) => {
			if (this.excludeSources.indexOf(name) === -1) {
				linkList.push({
					name,
					value: linkMap[name],
				});
			} else {
				constantLinks.push({
					name,
					value: linkMap[name],
				});
			}
		});

		let firstFourLink = linkList
			.sort((a, b) => b.value - a.value)
			.splice(0, 4);

		firstFourLink = firstFourLink.concat(constantLinks);

		const others = []
		linkList.forEach(({ name, value }) => {
			others.push(name);
		});

		const reducedData = copy.filter(([graph, name]) => {
			let ie = false;
			for (let i = 0; i < firstFourLink.length; i += 1) {
				if (firstFourLink[i].name === name) ie = true;
			}
			return ie;
		});

		copy.forEach((c) => {
			if (others.indexOf(c[1]) !== -1) {
				c[1] = 'Other';
				reducedData.push(c);
			}
		});

		return reducedData;
	}

	render() {
		const { fetching, graph, nodes, links, sankeyOptions } = this.state;

		return (
			<div className="campaign-detail-sankey" ref={this.sankeyParent}>
				{fetching ? (
					<Loader />
				) : graph && graph.length > 0 ? (
					<Sankey
						columns={nodes}
						rows={links}
						options={sankeyOptions}
						height={415}
						width={this.sankeyParent.current.getBoundingClientRect().width - 95}
						onNodesChange={this.handleNodesChange} />
				) : (
					<NoData />
				)}
			</div>
		)
	}
}

export default DetailSankey;
