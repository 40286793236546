import React from 'react';
import { Icon } from 'interceptd-ui';

import './styles/Footer.css';

const Footer = props => {
	return (
		<footer className="footer">
			<div className="footer-text">Sleep well, we will protect you from fraud day and night...</div>
			<div className="footer-icons">
				<Icon className="footer-shield" i="shield">
					<Icon className="footer-shield-inner" i="shield" />
				</Icon>
				<Icon className="footer-heart" i="heart" />
				<Icon className="footer-moon" i="moon" />
			</div>
		</footer>
	)
}

export default Footer;
