import isEqual from 'lodash/isEqual';

export const containsObject = (obj, list) => {
	let i;
	for (i = 0; i < list.length; i++) {
		if ( isEqual(list[i], obj) ) {
			return true;
		}
	}
	return false;
}

export const containsObjectbyId = (obj, list, selector = 'id') => {
	let i;
	for (i = 0; i < list.length; i++) {
		if (list[i][selector] === obj[selector]) {
			return true;
		}
	}
	return false;
}

export const findIndex = (obj, list) => {
	let i;
	for (i = 0; i < list.length; i++) {
		if ( isEqual(list[i], obj) ) {
			return i;
		}
	}
	return -1;
}

export const objectFilter = (obj, predicate) => 
		Object.keys(obj)
					.filter( key => predicate(key, obj[key]) )
					.reduce( (res, key) => { res[key] = obj[key]; return res }, {});

export const getObjectValue = (object, key) => {
	const keys = key.split('.');
	let o = {...object};
	for ( let i = 0; i < keys.length; i++ ) {
		if ( !o.hasOwnProperty(keys[i]) )
			return null;
		o = o[keys[i]];
	}
	return o;
}

export const RenderTableColumns = (columns, options) =>
	columns.map(col => ({
		...col,
		show: col.permanent || options.some(option => option.value === col.id)
	}))
