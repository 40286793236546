import React from 'react';
import Chart from 'react-apexcharts'
import moment from 'moment'

class EventStatsGraph extends React.PureComponent {
	render() {
		const { options, getOptions, series, ...rest } = this.props;
		return (
			<Chart options={getOptions(options)} series={series} type="line" width="100%" height={405} { ...rest } />
		)
	}
}

EventStatsGraph.defaultProps = {
	getOptions: options => options,
	options: {
		chart: {
			id: 'event-stats',
			type: 'line',
			height: 400,
			stacked: false,
			animations: {
				animateGradually: {
					enabled: false,
				},
			}
		},
		stroke: {
			curve: 'smooth',
			width: 2,
		},
		grid: {
			row: {
				colors: ['transparent'],
				opacity: 0.5
			},
		},
		dataLabels: {
			enabled: false,
		},
		xaxis: {
			type: 'category',
			title: {
				text: 'Dates',
			},
			labels: {
				formatter: val => val ? moment(+val, 'X').format('MMM D') : '',
			},
		},
		tooltip: {
			x: {
				show: true,
				formatter: val => val ? moment(+val, 'X').format('MMM D') : '',
			},
			marker: {
				show: true
			},
		},
		yaxis: [
			{
				min: 0,
				labels: {
					minWidth: 50,
				},
			},
		],
		markers: {
			size: 0,
			style: 'full',
		},
		plotOptions: {
			line: {
				curve: 'smooth',
			}
		},
		legend: {
			show: false,
		},
	},
}

export default EventStatsGraph;