import React from 'react';
import { IntercomAPI } from 'react-intercom';
import get from 'lodash/get';
import {
	Icon,
	Select,
} from 'interceptd-ui';

import Switch from '../../components/Switch';

import Local from '../../services/localStorage';
import Settings from '../../services/settings';
import SendEvent from '../../services/events';

class MyAccountNotification extends React.Component {
	state = {
		notification: Object.assign({
			campaignSummary: {
				enabled: 0,
			},
		}, Settings.get('notification')),
		unsubscribed_from_emails: Settings.get('unsubscribed_from_emails'),
	}

	scheduledOptions = {
		period: [
			{
				label: 'Monthly',
				value: 'monthly',
			},
			{
				label: 'Weekly',
				value: 'weekly',
			}, {
				label: 'Daily',
				value: 'daily',
			},
		],
		target: [
			{
				label: 'Active',
				value: 'active',
			}, {
				label: 'All',
				value: 'all',
			}
		]
	}

	sendEvent = () => {
		SendEvent({
			action: 'Notification Settings Change',
			sendMixPanel: true,
		});
	};

	toggleMarkettingEmails = event => {
		const { unsubscribed_from_emails } = this.state;
		Settings.set('unsubscribed_from_emails', !unsubscribed_from_emails);
		this.setState({
			unsubscribed_from_emails: Settings.get('unsubscribed_from_emails')
		})
		if (!Local.getItem('login_as')) {
			IntercomAPI('update', {
				unsubscribed_from_emails: Settings.get('unsubscribed_from_emails')
			});
		}
	}

	toggleScheduledReports = event => {
		const notification = Settings.get('notification') || {};
		const campaignSummary = notification.campaignSummary || {};
		Settings.set('notification', {
			...notification,
			campaignSummary: {
				...campaignSummary,
				enabled: !!campaignSummary.enabled ? 0 : 1,
				period: this.scheduledOptions.period[1].value,
				target: this.scheduledOptions.target[0].value,
			}
		});
		this.sendEvent();
		this.setState({
			notification: Settings.get('notification')
		})
	}

	handleSchedulOptions = (value, name) => {
		const notification = Settings.get('notification') || {};
		const campaignSummary = notification.campaignSummary || {};
		Settings.set('notification', {
			...notification,
			campaignSummary: {
				...campaignSummary,
				[name]: value
			}
		});
		this.sendEvent();
		this.setState({
			notification: Settings.get('notification')
		});
	}

	render() {
		const { notification, unsubscribed_from_emails } = this.state;
		const isScheduledReportsActive = get(notification, 'campaignSummary.enabled', 0);

		return (
			<div className="account-page">
				<h4>Notifications</h4>
				<div className={`integration scheduled ${isScheduledReportsActive ? 'active' : ''}`}>
					<div className="scheduled-switch" onClick={this.toggleScheduledReports}>
						<Icon i="calendar" size={18} />
						<span className="integration-name">Scheduled Campaign Summary</span>
						<Switch on={isScheduledReportsActive} />
					</div>
					{isScheduledReportsActive === 1 && <div className="scheduled-options">
						<Select
							mini
							label="Period"
							options={this.scheduledOptions.period}
							value={[notification.campaignSummary.period]}
							onChange={([val]) => this.handleSchedulOptions(val, 'period')} />
						{/* <Select
							mini
							label="Target"
							options={this.scheduledOptions.target}
							value={[notification.campaignSummary.target]}
							onChange={([val]) => this.handleSchedulOptions(val, 'target')} /> */}
					</div>}
				</div>
				<div className={`integration ${!unsubscribed_from_emails ? 'active' : ''}`} onClick={this.toggleMarkettingEmails}>
					<Icon i="gift" size={18} />
					<span className="integration-name">Marketting Emails</span>
					<Switch on={!unsubscribed_from_emails} />
				</div>
			</div>
		)
	}
}

export default MyAccountNotification;
