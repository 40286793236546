import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'interceptd-ui';

import './styles/NotFound.css';

import withTitle from '../withTitle';

const NotFound = props => {
	return (
		<div className="notfound">
			<div className="four-oh-four">
				<span className="four">
					<span className="four-horizontal" />
					<span className="four-vertical" />
				</span>
				<span className="oh" />
				<span className="four">
					<span className="four-horizontal" />
					<span className="four-vertical" />
				</span>
			</div>
			<div className="notfound-text">
				<div className="notfound-title">This page doesn't exist</div>
				<Button to="/" component={Link}>Go to Homepage</Button>
			</div>
		</div>
	)
}

export default withTitle(NotFound, 'Page Not Fount');
