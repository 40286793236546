import React from 'react';
import Chart from 'react-apexcharts'

import { colors } from '../../../constants/Color';

class OSVersionPie extends React.PureComponent {
	render() {
		const { options, getOptions, series, ...rest } = this.props;
		return (
			<Chart options={getOptions(options)} series={series} type="pie" height="300" width="100%" { ...rest } />
		);
	}
}

OSVersionPie.defaultProps = {
	getOptions: options => options,
	options: {
		chart: {
			id: 'app-version-pie',
			type: 'pie',
		},
		colors,
	},
}

export default OSVersionPie;