import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Input } from 'interceptd-ui';

import LoginSlogan from '../../components/LoginSlogan';

import Api from '../../services/api';
import Local from '../../services/localStorage';
import SendEvent from '../../services/events';

import Logo from '../../assets/logo.svg';

import './styles/Login.css';

import withTitle from '../withTitle';

class ForgotPassword extends React.Component {
	state = {
		email: '',
		isFetching: false,
	}
	
	componentDidMount() {
		Local.setStore({});
		Api.setToken();
	}

	handleSubmit = event => {
		event.preventDefault();
		const { email, isFetching } = this.state;

		SendEvent({
			category: 'NonAuth',
			action: 'Reset Password button clicked',
			nonInteraction: false,
		});

		if ( isFetching ) return false;

		this.setState({ isFetching: true });
		Api.forgotPassword({
			email
		})
			.then(response => {
				Local.setItem('username', email);
				toast.success('Password reset mail sent');
				this.setState({
					isFetching: false,
				})
			})
			.catch(({ response }) => {
				SendEvent({
					description: 'forgotPassword on Forgot Password',
					fatal: true,
				});

				this.setState({
					isFetching: false,
					error: response
				});
				toast.error(response.data.error.message);
			});
	}

	handleUsername = event => {
		this.setState({
			email: event.target.value
		});
	}

	validateEmail = value => {
		const rx = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
		return rx.test(value);
	}

	render() {
		const { email, isFetching } = this.state;

		return (
			<div className="login">
				<div className="login-wrapper">
					<LoginSlogan />
					<div className="login-right">
						<header className="login-header">
							<img src={Logo} alt="Interceptd" />
						</header>
						<form className="login-form" onSubmit={this.handleSubmit}>
							<div className="login-title">
								<h1>Forgot Password</h1>
								<h2>We will send reset password link to your e-mail</h2>
							</div>
							<div className="login-form-inner">
								<Input
									type="email"
									name="email"
									placeholder="john@doe.com"
									label="Email Address"
									value={email}
									onChange={this.handleUsername} />
								<Button type="submit" block loading={isFetching} disabled={!this.validateEmail(email)}>Reset Password</Button>
								<Button
									block
									to="/welcome"
									component={Link}
									bgColor="transparent"
									data-event-category="NonAuth"
									data-event-action="Did you remember button clicked"
								>
									Did you remember? Go to Sign In
								</Button>
							</div>
						</form>
					</div>
				</div>
			</div>
		)
	}
}

export default withTitle(ForgotPassword, 'Forgot Password');