import React from 'react';
import moment from 'moment';
import axios from 'axios';
import Chart from 'react-apexcharts'
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import Humanize from 'humanize-plus';
import { Loader, NoData } from 'interceptd-ui';

import Api from '../../../services/api';

import { colors } from '../../../constants/Color';

import { enumerateDaysBetweenDates, getTimestamp } from '../../../utils/transform';

class DailyPerformanceChart extends React.Component {
	state = {
		chartOptions: {
			colors,
			chart: {
				id: 'campaign-chart',
				type: 'line',
				height: 400,
				stacked: false,
			},
			dataLabels: {
				enabled: false,
			},
			xaxis: {
				type: 'datetime',
				categories: [],
				labels: {
					formatter: value => moment(value).format('MMM-D'),
				},
			},
			yaxis: [
				{
					seriesName: 'Installs',
					forceNiceScale: true,
					labels: {
						style: {
							color: '#6d7782',
						},
						formatter: v => Humanize.intComma(v),
					},
					title: {
						text: 'Installs and Events',
					},
					min: 0,
				},
				{
					seriesName: 'Installs',
					forceNiceScale: true,
					show: false,
					labels: {
						formatter: v => Humanize.intComma(v),
					},
				},
				{
					seriesName: '',
					forceNiceScale: true,
					opposite: true,
					labels: {
						style: {
							color: '#6d7782',
						},
						formatter: v => Humanize.intComma(v),
					},
					title: {
						text: '',
					},
					min: 0,
				},
			],
			legend: {
				horizontalAlign: 'left',
				offsetX: 40
			},
			stroke: {
				width: [2, 2, 2],
				curve: 'smooth',
			},
			plotOptions: {
				bar: {
					columnWidth: '20%',
				},
			},
			tooltip: {
				custom: ({ series, seriesIndex, dataPointIndex, w }) => {
					const date = w.config.series[seriesIndex].data[dataPointIndex].x;
					const groups = w.config.series;
					const colors = w.config.colors;
					return `
						<div class="apexcharts-tooltip-title">${moment(date).format('MMM-D')}</div>
						${groups.map((group, index) => `
							<div class="apexcharts-tooltip-series-group active">
								<span class="apexcharts-tooltip-marker" style="background-color: ${colors[index]}"></span>
								<div class="apexcharts-tooltip-text">
									<div class="apexcharts-tooltip-y-group">
										<span class="apexcharts-tooltip-text-label">${group.name}: </span>
										<span class="apexcharts-tooltip-text-value">${Humanize.intComma(group.data[dataPointIndex].y)}</span>
									</div>
								</div>
							</div>
							${'rejected' in group.data[dataPointIndex] && group.data[dataPointIndex].y ? `
								<div class="apexcharts-tooltip-series-group active apexcharts-tooltip-series-subgroup apexcharts-tooltip-series-rejected">
									<span class="apexcharts-tooltip-marker" style="background-color: transparent"></span>
									<div class="apexcharts-tooltip-text">
										<div class="apexcharts-tooltip-y-group">
											<span class="apexcharts-tooltip-text-label">Rejected: </span>
											<span class="apexcharts-tooltip-text-value">${Humanize.intComma(group.data[dataPointIndex].rejected)}</span>
										</div>
									</div>
								</div>
							` : ''}
							${'flagged' in group.data[dataPointIndex] && group.data[dataPointIndex].y ? `
								<div class="apexcharts-tooltip-series-group active apexcharts-tooltip-series-subgroup apexcharts-tooltip-series-flagged">
									<span class="apexcharts-tooltip-marker" style="background-color: transparent"></span>
									<div class="apexcharts-tooltip-text">
										<div class="apexcharts-tooltip-y-group">
											<span class="apexcharts-tooltip-text-label">Flagged: </span>
											<span class="apexcharts-tooltip-text-value">${Humanize.intComma(group.data[dataPointIndex].flagged)}</span>
										</div>
									</div>
								</div>
							` : ''}
							${'clean' in group.data[dataPointIndex] && group.data[dataPointIndex].y ? `
								<div class="apexcharts-tooltip-series-group active apexcharts-tooltip-series-subgroup apexcharts-tooltip-series-clean">
									<span class="apexcharts-tooltip-marker" style="background-color: transparent"></span>
									<div class="apexcharts-tooltip-text">
										<div class="apexcharts-tooltip-y-group">
											<span class="apexcharts-tooltip-text-label">Clean: </span>
											<span class="apexcharts-tooltip-text-value">${Humanize.intComma(group.data[dataPointIndex].clean)}</span>
										</div>
									</div>
								</div>
							` : ''}
						`).join('')}
					`
				},
				y: {
					formatter: (value, { series, seriesIndex, dataPointIndex, w }) => {
						const { rejected = 0, flagged = 0, clean = 0 } = get(w, `config.series[${seriesIndex}].data[${dataPointIndex}]`, {});
						const isClicks = get(w, `config.series[${seriesIndex}].name`, '') === 'Clicks';
						return `${Humanize.intComma(value)} (${Humanize.intComma(clean)} clean${isClicks ? '' : `, ${Humanize.intComma(flagged)} flagged`} and ${Humanize.intComma(rejected)} rejected)`;
					},
				}
			}
		},
		chartSeries: [
			{
				name: '',
				type: 'line',
				data: enumerateDaysBetweenDates(this.props.filters.start, this.props.filters.end).map(d => ({
					x: moment(d, 'X').format('YYYY-MM-DD'),
					y: 0
				})),
			},
			{
				name: 'Installs',
				type: 'column',
				data: enumerateDaysBetweenDates(this.props.filters.start, this.props.filters.end, 'X').map(d => ({
					x: moment(d, 'X').format('YYYY-MM-DD'),
					y: 0
				})),
			},
			{
				name: 'Events',
				type: 'column',
				data: enumerateDaysBetweenDates(this.props.filters.start, this.props.filters.end, 'X').map(d => ({
					x: moment(d, 'X').format('YYYY-MM-DD'),
					y: 0
				})),
			},
		],
		stats: null,
		installAndEventLoading: true,
	}

	componentDidMount() {
		this.getData();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if ( !isEqual(this.props.filters, prevProps.filters) ) {
			this.getData()
		}
	}

	setMax = (seriesName, max) => {
		this.setState(pS => ({
			chartOptions: {
				...pS.chartOptions,
				yaxis: [
					...(pS.chartOptions.yaxis.map(a => ({
						...a,
						max: a.seriesName === seriesName ? max : a.max,
					})))
				]
			}
		}))
	}

	getData = () => {
		const { filters, sourceId, subpubId } = this.props;

		this.setState({
			chartSeries: [],
			stats: null,
			installAndEventLoading: true,
		})

		const query = {
			source_id: sourceId,
			subpub_id: subpubId,
			ts_start: getTimestamp(filters.start),
			ts_end: getTimestamp(filters.end, true),
		}

		axios.all([
			Api.getLeoparInstallAndEventSubPubDailyStats(query),
		])
			.then(axios.spread((leoparRes) => {
				const reducer = (acc, cur, suffix, data) => {
					data.forEach(d => {
						const date = acc.some(r => r.date === d.date);
						if ( date ) {
							acc = acc.map(r => r.date === d.date ? {
								...r,
								[`${suffix}_count`]: r[`${suffix}_count`] + d[`${cur}_${suffix}_count`]
							} : r)
						} else {
							acc.push({
								date: d.date,
								[`${suffix}_count`]: d[`${cur}_${suffix}_count`]
							})
						}
					})
					return acc;
				}
				const transformedData = {
					install: ['rejected', 'flagged', 'clean'].reduce((acc, cur) => reducer(acc, cur, 'install', leoparRes.data.data), []),
					rejected_install: ['rejected'].reduce((acc, cur) => reducer(acc, cur, 'install', leoparRes.data.data), []),
					flagged_install: ['flagged'].reduce((acc, cur) => reducer(acc, cur, 'install', leoparRes.data.data), []),
					clean_install: ['clean'].reduce((acc, cur) => reducer(acc, cur, 'install', leoparRes.data.data), []),
					event: ['rejected', 'flagged', 'clean'].reduce((acc, cur) => reducer(acc, cur, 'event', leoparRes.data.data), []),
					rejected_event: ['rejected'].reduce((acc, cur) => reducer(acc, cur, 'event', leoparRes.data.data), []),
					flagged_event: ['flagged'].reduce((acc, cur) => reducer(acc, cur, 'event', leoparRes.data.data), []),
					clean_event: ['clean'].reduce((acc, cur) => reducer(acc, cur, 'event', leoparRes.data.data), []),
				}
				const days = enumerateDaysBetweenDates(filters.start, filters.end, 'X');
				const filterData = (d, colId, countId) => {
					const data = transformedData[colId].filter(r => moment(r.date, 'X').isSame(moment(d, 'X'), 'day'))
					return ({
						x: moment(d, 'X').format('YYYY-MM-DD'),
						y: data[0] ? data[0][countId] : 0
					})
				}

				const rejectedInstallData = days.map(d => filterData(d, 'rejected_install', 'install_count'));
				const flaggedInstallData = days.map(d => filterData(d, 'flagged_install', 'install_count'));
				const cleanInstallData = days.map(d => filterData(d, 'clean_install', 'install_count'));
				const installData = days.map(d => filterData(d, 'install', 'install_count')).map(td => ({
					...td,
					rejected: rejectedInstallData.find(rd => rd.x === td.x).y,
					flagged: flaggedInstallData.find(rd => rd.x === td.x).y,
					clean: cleanInstallData.find(rd => rd.x === td.x).y,
				}));

				const rejectedEventData = days.map(d => filterData(d, 'rejected_event', 'event_count'));
				const flaggedEventData = days.map(d => filterData(d, 'flagged_event', 'event_count'));
				const cleanEventData = days.map(d => filterData(d, 'clean_event', 'event_count'));
				const eventData = days.map(d => filterData(d, 'event', 'event_count')).map(td => ({
					...td,
					rejected: rejectedEventData.find(rd => rd.x === td.x).y,
					flagged: flaggedEventData.find(rd => rd.x === td.x).y,
					clean: cleanEventData.find(rd => rd.x === td.x).y,
				}));
		
				this.setMax('Installs', Math.max(...[...installData, ...eventData].map(d => +d.y)));

				this.setState(prevState => ({
					stats: transformedData,
					chartSeries: [
						...prevState.chartSeries,
						{
							name: 'Installs',
							type: 'column',
							data: installData,
						},
						{
							name: 'Events',
							type: 'column',
							data: eventData,
						},
					],
					installAndEventLoading: false,
				}));
			}))
			.catch(({ response }) => {
				this.setState({
					error: response
				});
			});
	}


	render() {
		const { chartOptions, chartSeries, stats, installAndEventLoading } = this.state;

		return (
			<div className="campaign-detail-daily">
				{installAndEventLoading ? (
					<Loader />
				) : stats && Object.values(stats).some(s => s.length > 0) ? (
					<Chart options={chartOptions} series={chartSeries} type="line" width="100%" height={400} />
				) : (
					<NoData />
				)}
			</div>
		)
	}
}

export default DailyPerformanceChart;