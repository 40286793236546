import React, { Component } from 'react';
import { toast } from 'react-toastify';
import {
	Loader,
	NoData,
} from 'interceptd-ui';

import Table from '../../components/Table';

import Api from '../../services/api';

import { columns } from './ListColumns';
import PartnerBox from './PartnerBox';

import './styles/List.css';

import withTitle from '../withTitle';

class Publishers extends Component {
	state = {
		partnerLoading: true,
		publisherLoading: true,
		error: false,
		partners: [],
		publishers: [],
		expandedState: {},
		publisherSaving: false,
	}

	UNSAFE_componentWillMount = () => {
		this.getData();
	}

	getData = async () => {
		this.setState({
			partnerLoading: true,
			publisherLoading: true,
		});

		let publishers = [];
		let partners = [];
		try {
			const response = await Api.getPublishers({
				page: 1,
				size: 1000,
			});
			const { data } = response.data;
			publishers = data;
			this.setState({
				publishers: data,
				publisherLoading: false,
			});
		} catch (e) {
			this.setState({
				publisherLoading: false
			});
			toast.error(`Couldn't get publishers`);
		}

		try {
			const response = await Api.getPartners({
				page: 1,
				size: 1000,
			});
			const { data } = response.data;
			partners = data;
			this.setState({
				partners: data,
				partnerLoading: false,
			});
		} catch (e) {
			this.setState({
				partnerLoading: false
			});
			toast.error(`Couldn't get partners`);
		}

		const { location } = this.props;
		const partnerId = location?.state?.partner;

		const tempPartners = partners.filter(partner => publishers
			.filter(publisher => publisher.is_active)
			.filter(publisher => publisher.partner === partner.id)[0]);

		const index = tempPartners.reverse().findIndex(v => v.id === partnerId);
		let expandedState = {};
		if (index >= 0) {
			expandedState = {
				[index]: true,
			};
		}
		this.setState({ expandedState });
		window.scrollTo(0, index * 82);
		
	}

	handleExpandPartner = (newExpanded, index, event) => {
		this.setState(prevState => {
			return prevState.expandedState[index] ? ({
				expandedState: {},
			}) : ({
				expandedState: {
					[index]: true
				},
			})
		})
	}

	renderPublisherDetail = props => {
		const { publisherSaving } = this.state;
		const partner = props.original;
		const publisher = this.hasPublisher(props.original);
		return (
			<PartnerBox
				key={partner.id}
				partner={partner}
				publisher={publisher}
				saving={publisherSaving}
				onSave={this.handlePublisherSave} />
		)
	}

	hasPublisher = partner => {
		const { publishers } = this.state;

		return publishers.filter(publisher => publisher.partner === partner.id)[0];
	}

	hasEnabledPublisher = partner => {
		const { publishers } = this.state;

		return publishers
			.filter(publisher => publisher.is_active)
			.filter(publisher => publisher.partner === partner.id)[0];
	}

	handleEnable = (checked, props) => {
		const partner = props.original;
		const publisher = this.hasPublisher(partner);

		if ( checked && !publisher ) {
			// There is no publisher, create it
			this.handleExpandPartner(null, props.viewIndex, null);
		} else {
			// Publisher found, change status
			this.handleTogglePublisher(publisher)
		}
	}

	handleTogglePublisher = publisher => {
		Api.updatePublisher(publisher.id, {
			partner: publisher.partner,
			parameters: publisher.parameters,
			operational_account_managers: publisher.operational_account_managers.filter((m) => m !== ''),
			notes: publisher.notes,
			is_active: Boolean(!publisher.is_active),
		})
			.then(response => {
				this.getData();
				toast.success(`Publisher ${Boolean(!publisher.is_active) ? 'activated' : 'disabled'}`);
			})
			.catch(({ response }) => {
				toast.error(`Couldn't ${Boolean(!publisher.is_active) ? 'activated' : 'disabled'} publisher`);
			})
	}

	handlePublisherSave = payload => {
		const { publishers } = this.state;

		this.setState({ publisherSaving: true });
		const oldPub = publishers.filter(publisher => publisher.partner === payload.partner)[0];
		if ( oldPub ) {
			Api.updatePublisher(oldPub.id, {
				...payload,
				operational_account_managers: payload?.operational_account_managers.filter((m) => m !== ''),
				parameters: payload.parameters.map(param => ({
					name: param.name,
					value: param.value
				}))
			})
				.then(response => {
					this.setState({
						publisherSaving: false,
						expandedState: {},
					});
					this.getData();
					toast.success(`Publisher updated`);
				})
				.catch(({ response }) => {
					this.setState({ publisherSaving: false });
					toast.error(`Couldn't update publisher`);
				})
		} else {
			Api.createPublisher({
				...payload,
				operational_account_managers: payload?.operational_account_managers.filter((m) => m !== ''),
				parameters: payload.parameters.map(param => ({
					name: param.name,
					value: param.value
				}))
			})
				.then(response => {
					this.setState({
						publisherSaving: false,
						expandedState: {},
					});
					this.getData();
					toast.success(`Publisher enabled`);
				})
				.catch(({ response }) => {
					this.setState({ publisherSaving: false });
					toast.error(`Couldn't enable publisher`);
				})
		}
	}

	render() {
		const {
			partnerLoading,
			publisherLoading,
			expandedState,
			partners,
		} = this.state;

		if (partnerLoading || publisherLoading) {
			return <div className="campaign-detail"><Loader /></div>;
		}

		return (
			<div className="partners">
				<Table
					ref={el => { this.table = el }}
					data={partners.sort((a, b) => b.name.localeCompare(a.name))}
					columns={columns(this.hasEnabledPublisher, this.handleEnable)}
					showPageSizeOptions={false}
					showPageJump={false}
					showPagination={false}
					pageSize={partners.length}
					expanded={expandedState}
					sorted={[{
						id: 'is_enabled',
						desc: true,
					}]}
					defaultFilterMethod={(filter, row, column) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) !== -1}
					onExpandedChange={this.handleExpandPartner}
					SubComponent={this.renderPublisherDetail}
					PadRowComponent={() => null}
					NoDataComponent={() => <NoData>There is no publisher</NoData>}
					getTrGroupProps={(state, rowInfo, column) => ({
						className: `
									${rowInfo && this.hasPublisher(rowInfo.original) ? '--enabled' : ''}
									${rowInfo && expandedState[rowInfo.viewIndex] ? '--open' : ''}
								`
					})} />
			</div>
		);
	}
}

export default withTitle(Publishers, 'Publisher List');
