import React from 'react';
import {
	Button,
	Modal,
	Checkbox,
} from 'interceptd-ui';

import Auth from '../../services/auth';
import Local from '../../services/localStorage';
import Settings from '../../services/settings';

import './styles/ComplianceModal.css';

class ComplianceModal extends React.PureComponent {
	state = {
		terms_of_use: false,
		privacy_policy: false,
		open: !Local.getItem('login_as') && Auth.isAuthenticated() && Local.getItem('settings') && !Settings.get('compliance'),
		loading: false,
	}

	componentDidMount() {
		Local.listen('settings', newValue => {
			this.setState({
				open: !Local.getItem('login_as') && Auth.isAuthenticated() && Local.getItem('settings') && !Settings.get('compliance'),
			})
		})
	}

	handleCheckbox = event => {
		const { name, checked } = event.target;

		this.setState({
			[name]: checked
		})
	}

	handleAccept = () => {
		this.setState({
			loading: true,
		}, () => {
			Settings.set('compliance', true);
			this.setState({
				loading: false,
				open: false,
			});
		});
	}

	render() {
		const { terms_of_use, privacy_policy, open, loading } = this.state;
		const { onClose } = this.props;

		return (
			<Modal
				open={open}
				title="Please accept the Terms of Use and Privacy Policy in order to continue using Interceptd"
				onCancel={onClose}
				onAccept={this.handleAccept}
				renderFooter={({ onCancel, onAccept }) => (
					<div className="modal-footer compliance-footer">
						<div className="compliance-contact">
							If you have any questions, feel free to <a href="https://interceptd.com/contact-us/" target="_blank" rel="noopener noreferrer">contact us</a>!
						</div>
						<Button
							mini
							bgColor="primary"
							onClick={onAccept}
							disabled={!terms_of_use || !privacy_policy}
							loading={loading}>
							Accept and Continue
						</Button>
					</div>
				)}>
				<div className="compliance">
					<div className="compliance-checkboxes">
						<div className="compliance-checkbox">
							<Checkbox
								id="terms_of_use"
								name="terms_of_use"
								checked={terms_of_use}
								onChange={this.handleCheckbox} />
							<label htmlFor="terms_of_use">I agree to the <a href="https://interceptd.com/terms-of-use/" target="_blank" rel="noopener noreferrer">Terms of Use</a></label>
						</div>
						<div className="compliance-checkbox">
							<Checkbox
								id="privacy_policy"
								name="privacy_policy"
								checked={privacy_policy}
								onChange={this.handleCheckbox} />
							<label htmlFor="privacy_policy">I agree to the <a href="https://interceptd.com/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a></label>
						</div>
					</div>
				</div>
			</Modal>
		)
	}
}

export default ComplianceModal;