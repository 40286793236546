import React from 'react';
import Chart from 'react-apexcharts';
import Humanize from 'humanize-plus';
import { Box, NoData } from 'interceptd-ui';

import { Countries } from '../../../constants/Countries';

import './styles/ReportDetailPie.css';

const countryNameMap = {};
Countries.forEach((c) => {
	countryNameMap[c.alpha2_code] = c.name;
});

const ReportDetailPie = ({ labels, series, colors, countryPie, alert, ...rest }) => {
	const options = {
		labels,
		colors,
		legend: {
			horizontalAlign: 'center',
			width: 200,
			formatter: (text, config) => {
				const value = Humanize.intComma(series[config.seriesIndex]);
				return `${countryPie ? (countryNameMap[text] || 'other') : text}: ${value}`;
			},
			offsetY: 50,
			markers: {
				shape: 'square',
				radius: 2
			},
			itemMargin: {
				horizontal: 5,
				vertical: 5
			},
		},
	};

	return (
		<Box {...rest}>
			{alert && (
				<div className="wrong-country-cart">
					<span>{alert}</span>
				</div>
			)}
			{(series && series.length > 0 && !series.every(s => s === 0)) ? (
				<Chart
					options={options}
					series={series || []}
					height="300"
					type="pie"
				/>
			) : (
				<NoData />
			)}
		</Box>
	);
};

ReportDetailPie.defaultProps = {
	colors: [
		'#036FDB',
		'#ffc000',
		'#ED6D6D',
		'#230060',
		'#5C6BC0',
		'#B9CDE1',
		'#8d9aa7',
		'#6d7782',
		'#55C4C2',
		'#F3B071',
	],
}

export default ReportDetailPie;