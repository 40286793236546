import React, { PureComponent, Fragment } from 'react';
import debounce from 'lodash/debounce';
import { toast } from 'react-toastify';
import moment from 'moment';
import Papa from 'papaparse';
import Humanize from 'humanize-plus';
import {
	Box,
	NoData,
	Icon,
	FraudGraph,
	Button,
	Select,
} from 'interceptd-ui';

import Table from '../../../components/Table';
import Switch from '../../../components/Switch';

import SendEvent from '../../../services/events';

import { getColumns } from '../Columns';

import { downloadFile } from '../../../utils/file';

export default class TableView extends PureComponent {
	state = {
		resizedState: [],
		expandedState: {},
		totalExpandedState: {},
		selectedEventColumns: [],
	};

	dailyTable = React.createRef();
	totalTable = React.createRef();

	renderStatsCell = ({ column: { id, Header }, original }) => {
		const [fieldName] = id.split('__');
		return (
			<div className="campaign-table-fraud-bar-container">
				<FraudGraph
					data={original.stats[fieldName] || original.events[fieldName]}
					loading={this.props.detailFetching}
					title={Header}
				/>
			</div>
		);
	}

	setResized = (resizedState) => {
		debounce(() => {
			SendEvent({
				category: 'Source List',
				action: `Source list column resized`,
				nonInteraction: false,
			});
		}, 1000);
		this.setState({ resizedState });
	}

	renderDetail = ({ original }) => {
		const { eventColumns } = this.props;
		const {
			selectedEventColumns,
			resizedState,
			newSorted,
		} = this.state;

		const dailyColumns = getColumns({
			renderStatsCell: this.renderStatsCell,
			eventColumns,
			selectedEventColumns,
			columnType: 'daily',
		});

		return (
			<div className="sources daily-performance-columns">
				<Table
					data={original.daily}
					columns={dailyColumns}
					PadRowComponent={() => null}
					sortable={false}
					showPageSizeOptions={false}
					showPageJump={false}
					showPagination={false}
					sorted={newSorted || []}
					pageSize={original.daily.length}
					resized={resizedState}
					manual
					NoDataComponent={() => <NoData icon="box">There is no data</NoData>}
				/>
			</div>
		);
	}

	convertSourceToCsvFormat = (source) => {
		const { selectedEventColumns } = this.state;
		const titles = selectedEventColumns;

		const data = {
			name: source.name,
			campaign_name: source.campaignName,
			date: source.date ? moment(source.date, 'X').format('DD/MM/YYYY') : '-',
			clean_click_count: source.stats.click.clean,
			rejected_click_count: source.stats.click.rejected,
			total_click_count: source.stats.click.total,

			clean_event_count: source.stats.event.clean,
			rejected_event_count: source.stats.event.rejected,
			flagged_event_count: source.stats.event.flagged,
			total_event_count: source.stats.event.total,

			clean_install_count: source.stats.install.clean,
			rejected_install_count: source.stats.install.rejected,
			flagged_install_count: source.stats.install.flagged,
			total_install_count: source.stats.install.total,
			[`cost (${source.payout_symbol})`]: Humanize.formatNumber(source.cost),
		};

		titles.forEach((title) => {
			const o = source.events[title] || source.stats[title];

			if (!o) return;

			Object.keys(o).forEach((key) => {
				data[`${key}_${title}`] = o[key];
			});
		});

		return data;
	}

	csvExport = () => {
		try {
			const { filters } = this.props;
			const sources = this.filterData();
			const dailyTotal = this.getTotalColumn([...sources.slice()]);

			const data = [this.convertSourceToCsvFormat({
				...dailyTotal,
				name: 'Total',
				date: null
			})];

			dailyTotal.daily.forEach((daily) => {
				data.push(this.convertSourceToCsvFormat({
					...daily,
					name: '-',
				}));
			});

			sources.forEach((s) => {
				const d = this.convertSourceToCsvFormat({
					...s,
					date: null,
				});

				data.push(d);

				s.daily.forEach((daily) => {
					const dailyData = {
						...daily,
						name: '-',
					};

					data.push(this.convertSourceToCsvFormat(dailyData));
				});
			});

			const csvData = Papa.unparse(data);
			downloadFile(csvData, `${moment(filters.start, 'X').format('DD/MM/YYYY')}_${moment(filters.end, 'X').format('DD/MM/YYYY')}_daily_performance.csv`);

			SendEvent({
				action: `Daily Performance Exported`,
				sendMixPanel: true,
			});
		} catch (err) {
			toast.error('Somethings went wrong.');
		}
	}

	handleTableColumns = (columns) => {
		SendEvent({
			category: 'Source List',
			action: `Source List type table columns updated`,
			nonInteraction: false,
		});

		this.setState({
			selectedEventColumns: columns,
		});
	}

	getTotalColumn = (data) => {
		const dailyMap = {};
		const totalCol = {
			events: {},
			stats: {},
			cost: 0,
			payout_symbol: data.every(d => data?.[0]?.payout_symbol === d?.payout_symbol)
				? data?.[0]?.payout_symbol
				: null,
		};

		data.slice().forEach((d) => {
			const shallowSource = JSON.parse(JSON.stringify(d));
			totalCol.cost += d.cost;
			Object.keys(shallowSource.stats).forEach((statsKey) => {
				totalCol.stats[statsKey] = totalCol.stats[statsKey] || {};
				const stat = shallowSource.stats[statsKey];
				Object.keys(stat).forEach((key) => {
					totalCol.stats[statsKey][key] = totalCol.stats[statsKey][key] || 0;
					totalCol.stats[statsKey][key] += stat[key];
				});
			});

			Object.keys(shallowSource.events).forEach((eventName) => {
				totalCol.events[eventName] = totalCol.events[eventName] || {};
				const ev = shallowSource.events[eventName];
				Object.keys(ev).forEach((key) => {
					totalCol.events[eventName][key] = totalCol.events[eventName][key] || 0;
					totalCol.events[eventName][key] += ev[key];
				});
			});

			const { daily } = shallowSource;
			daily.slice().forEach((dailyData) => {
				const shallowDailyData = JSON.parse(JSON.stringify(dailyData));

				dailyMap[shallowDailyData.date] = dailyMap[shallowDailyData.date] || {
					date: shallowDailyData.date,
					stats: {},
					events: {},
					cost: 0,
					payout_symbol: shallowDailyData.payout_symbol,
				};

				dailyMap[shallowDailyData.date].cost += shallowDailyData.cost || 0;
				Object.keys(shallowDailyData.stats).forEach((statsKey) => {
					dailyMap[shallowDailyData.date].stats[statsKey] = dailyMap[shallowDailyData.date].stats[statsKey] || {};
					const stat = shallowDailyData.stats[statsKey];
					Object.keys(stat).forEach((key) => {
						dailyMap[shallowDailyData.date].stats[statsKey][key] = dailyMap[shallowDailyData.date].stats[statsKey][key] || 0;
						dailyMap[shallowDailyData.date].stats[statsKey][key] += stat[key];
					});
				});

				Object.keys(shallowDailyData.events).forEach((eventName) => {
					dailyMap[shallowDailyData.date].events[eventName] = dailyMap[shallowDailyData.date].events[eventName] || {};
					const event = shallowDailyData.events[eventName];
					Object.keys(event).forEach((key) => {
						dailyMap[shallowDailyData.date].events[eventName][key] = dailyMap[shallowDailyData.date].events[eventName][key] || 0;
						dailyMap[shallowDailyData.date].events[eventName][key] += event[key];
					});
				});
			});

		});

		totalCol.daily = Object.values(dailyMap);

		return totalCol;
	}

	filterData = () => {
		const {
			sources,
			selectedPlatform,
			selectedApps,
			selectedPartners,
		} = this.props;
		return sources.filter((s) => {
			return (!selectedPlatform || s.platform === selectedPlatform) &&
				(!selectedApps || selectedApps.length === 0 || selectedApps.indexOf(s.appId) > -1) &&
				(!selectedPartners || selectedPartners.length === 0 || selectedPartners.indexOf(s.partnerId) > -1);
		});
	}

	onExpandedChange = (field) => (index) => {
		this.setState(prevState => ({
			[field]: {
				[index]: !prevState[field][index],
			},
		}));
	}

	render() {
		const {
			eventColumns,
			sourceAppMap,
			eventNameOptions,
			showCohortData,
			switchCohort,
		} = this.props;
		const {
			newSorted,
			resizedState,
			expandedState,
			totalExpandedState,
			selectedEventColumns,
		} = this.state;

		const data = this.filterData();

		const columns = getColumns({
			renderStatsCell: this.renderStatsCell,
			eventColumns,
			selectedEventColumns,
			sourceAppMap,
			onExpandedChange: this.onExpandedChange('expandedState'),
		});
		const totalColumns = getColumns({
			renderStatsCell: this.renderStatsCell,
			eventColumns,
			selectedEventColumns,
			sourceAppMap,
			columnType: 'total',
			onExpandedChange: this.onExpandedChange('totalExpandedState'),
		});

		const dailyTotal = this.getTotalColumn([...data.slice()]);

		return (
			<div className="campaign-list-campaigns">
				<Box
					className="box-table-wrapper daily-performance-table-view daily-performance-total-table"
					title=""
					left={
						<Fragment>
							<Switch on={showCohortData} onClick={switchCohort} />
							<span style={{ marginLeft: 10 }} tooltip="COHORT">COHORT</span>
						</Fragment>
					}
					right={
						<Fragment>
							<Select
								mini
								multi
								placeholder="Add Event"
								options={eventNameOptions.filter(o => !o.hide)}
								onChange={this.handleTableColumns}
								value={selectedEventColumns}
								getTetherProps={props => ({
									...props,
									attachment: 'top right',
									targetAttachment: 'bottom right',
								})} />
							<Button bgColor="transparent" onClick={this.csvExport}><Icon i="download" /> CSV Export</Button>
						</Fragment>
					}>
					<Table
						ref={this.totalTable}
						data={[dailyTotal]}
						columns={totalColumns}
						PadRowComponent={() => null}
						syncScrollTableRef={this.dailyTable}
						sortable={false}
						stickyHeader={true}
						showPageSizeOptions={false}
						showPageJump={false}
						showPagination={false}
						resized={resizedState}
						pageSize={1000}
						defaultSortDesc={true}
						NoDataComponent={() => <NoData icon="box">You don't have any source yet</NoData>}
						SubComponent={this.renderDetail}
						expanded={totalExpandedState}
						onResizedChange={this.setResized}
						getTrGroupProps={(state, rowInfo) => {
							const { totalExpandedState } = this.state;
							if (rowInfo && totalExpandedState[rowInfo.viewIndex]) {
								return ({
									className: '-open'
								});
							}
						}}
					/>
				</Box>
				<Box
					className="box-table-wrapper daily-performance-table-view"
					headerProps={{
						style: {
							display: 'none'
						}
					}}
				>
					<Table
						ref={this.dailyTable}
						data={data}
						columns={columns}
						syncScrollTableRef={this.totalTable}
						PadRowComponent={() => null}
						sortable={false}
						stickyHeader={true}
						showPageSizeOptions={false}
						showPageJump={false}
						showPagination={false}
						pageSize={1000}
						sorted={newSorted}
						resized={resizedState}
						defaultSortDesc={true}
						NoDataComponent={() => <NoData icon="box">You don't have any source yet</NoData>}
						SubComponent={this.renderDetail}
						expanded={expandedState}
						onSortedChange={(newSorted) => {
							this.setState({ newSorted, expandedState: {} });
						}}
						onResizedChange={this.setResized}
						getTrGroupProps={(state, rowInfo) => {
							const { expandedState } = this.state;
							if (rowInfo && expandedState[rowInfo.viewIndex]) {
								return ({
									className: '-open'
								});
							}
						}}
					/>
				</Box>
			</div>
		);
	}
}