import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Modal, Input } from 'interceptd-ui';

import Api from '../../services/api';
import SendEvent from '../../services/events';

import { isURL } from '../../utils/misc';

import './styles/Create.css';

const Create = props => {
	const [state, setState] = useState({
		creating: false,
		title: '',
		url: '',
		ios_url: '',
		android_url: '',
	});

	const handleInput = event => {
		const { name, value } = event.target;

		setState(prevState => {
			const newState = {
				...prevState,
				[name]: value
			};

			return newState;
		});
	}

	const createLink = () => {
		setState({
			...state,
			creating: true,
		})

		Api.createShortenerLink({
			title: state.title,
			url: state.url,
			ios_url: state.ios_url,
			android_url: state.android_url,
		})
			.then(response => {
				toast.success(`Secure link created`);
				setState({
					creating: false,
					title: '',
					url: '',
				})
				props.onRefresh()
				props.onClose()
			})
			.catch(({ response }) => {
				toast.error(`Couldn't create secure link`);
				SendEvent({
					description: `createShortenerLink on shortener link create`,
					fatal: true
				});
				setState({
					...state,
					creating: false,
				});
			})
	}

	return (
		<Modal
			open={props.opened}
			title="Create Secure Link"
			icon="check-circle"
			modalClassName="shortener-create"
			onCancel={props.onClose}
			onAccept={createLink}
			acceptProps={{
				loading: state.creating,
				disabled: (!state.title || (!isURL(state.url) && !isURL(state.ios_url) && !isURL(state.android_url)))
			}}
			defaultAcceptText="Create">
			<Input
				label="Title"
				placeholder="My Secure Link"
				name="title"
				value={state.title}
				onChange={handleInput} />
			<Input
				label="URL"
				placeholder="https://example.com/track"
				name="url"
				value={state.url}
				onChange={handleInput} />
			<Input
				label="IOS URL"
				placeholder="http://itunes.apple.com/<country>/app/<app–name>/id<app-ID>?mt=8"
				name="ios_url"
				value={state.ios_url}
				onChange={handleInput} />
			<Input
				label="Android URL"
				placeholder="https://play.google.com/store/apps/details?id=<package_name>"
				name="android_url"
				value={state.android_url}
				onChange={handleInput} />
		</Modal>
	)
}

export default Create;
