import React from 'react';
import Chart from 'react-apexcharts';
import { Button } from 'interceptd-ui';

import { colors } from '../../constants/Color';

import staticData from '../../constants/FraudInsights';

import './styles/StaticPie.css';

const categoryNames = Object.keys(staticData.categories);

const StaticPie = ({ category, wrapperClass, status}) => {
	if (!staticData.categories[category] && staticData.gameSubCategories.indexOf(category) !== -1) {
		category = 'Games';
	}

	category = category || categoryNames[Math.floor(Math.random() * (categoryNames.length - 1))];

	const legend = {
		horizontalAlign: 'center',
		width: 170,
		markers: {
			shape: 'square',
			radius: 2
		},
		itemMargin: {
			horizontal: 5,
			vertical: 10
		},
	};

	return (
		<div className="redirection-report-content">
			<div className="redirection-report-pie-wrapper">
				<div className="redirection-report-pie">
					<div className="source-col-title">
						<span>{category} - Fraud Rates</span>
					</div>
					<Chart
						options={{
							colors,
							legend,
							labels: staticData.categoryFraudRateLabels,
						}}
						series={(staticData.categories[category] && staticData.categories[category].categoryFraudRate) || []}

						width="420"
						height="300"
						type="pie"
					/>
				</div>

				<div className="redirection-report-pie">
					<div className="source-col-title">
						<span>{category} - Fraud Types</span>
					</div>
					<Chart
						options={{
							colors,
							legend,
							labels: staticData.fraudTypesLabels
						}}
						series={(staticData.categories[category] && staticData.categories[category].fraudTypes) || []}

						width="420"
						height="300"
						type="pie"
					/>
				</div>
				
				{status === 'pending' && (
					<div className="redirection-report-pie redirection-cta">
						<span className="title">Let's Talk!</span>
						<span className="body">Get deeper insights and learn how to protect your campaigns and ROAS from Mobile Ad Fraud. Request a free consultation today.</span>
						<Button component="a" target="_blank" href="https://calendly.com/interceptd-demo/15min?utm_source=app-index&utm_medium=boxed-cta&utm_campaign=fraud-insights">Free Consultation</Button>
					</div>
				)}
			</div>
		</div>
	);
}

export default StaticPie;