import React from 'react';
import ClickOutside from 'react-click-outside';
import {
	TagInput,
	Select,
	Input,
	RadioGroup,
} from 'interceptd-ui';

import Grouped from '../../components/Grouped';
import ShadowImage from '../../components/ShadowImage';

import { CampaignTypes, TrackingTools } from '../../constants/Campaign';

import Local from '../../services/localStorage';
import history from '../../services/history';

import icon from '../../assets/icon-gray.svg';

import './styles/Step2.css';

import withTitle from '../withTitle';

class Step2 extends React.Component {
	state = {
		countries: Local.getItem('countries') || [],
		countryFilter: '',
		countryFocused: false,
	}

	countryInputRef = React.createRef()
	countryListRef = React.createRef()

	countriesListener = null

	componentDidMount = () => {
		const { seenFirstStep, editing } = this.props;

		if ( !editing && !seenFirstStep )
			return history.push('/create')

		this.countriesListener = Local.listen('countries', newValue => {
			this.setState({
				countries: newValue || []
			})
		})
	}

	componentWillUnmount = () => {
		if ( this.countriesListener )
			Local.unlisten(this.countriesListener)
	}

	handleCountryFocus = event => {
		this.setState({
			countryFocused: true
		})
	}

	handleCountryOutClick = event => {
		if (
			this.countryListRef.current && (
				event.target === this.countryListRef.current ||
				this.countryListRef.current.contains(event.target)
			)
		) return;

		this.setState({
			countryFocused: false
		})
	}

	handleCountrySelect = (country) => {
		const { form } = this.props;

		this.countryInputRef.current.focus();
		this.props.onCountriesChange([
			...form.country,
			country.alpha2_code
		]);
		this.setState({
			countryFilter: ''
		})
	}

	handleCountryFilter = event => {
		this.setState({
			countryFilter: event.target.value
		})
	}

	handleCountryFilterKeyPress = event => {
		if ( event.keyCode === 13 ) {
			const { countries, countryFilter } = this.state;
			const firstCountry = countries
					.filter(country => 
						countryFilter !== '' && `${country.name}-${country.alpha2_code}`.toLowerCase().indexOf(countryFilter.toLowerCase()) !== -1
					)
			if ( firstCountry.length )
				this.handleCountrySelect(firstCountry[0]);
		}
	}

	renderCountryOptions = focused => {
		const { form } = this.props;
		const { countries, countryFilter } = this.state;
		return focused && (
			<div className="tags-country-list" ref={this.countryListRef}>
				{countries
					.filter(country => 
						countryFilter !== '' ?
							`${country.name}-${country.alpha2_code}`.toLowerCase().indexOf(countryFilter.toLowerCase()) !== -1 : true
					)
					.map(country => {
						const selected = form.country.indexOf(country.alpha2_code) !== -1;
						return (
							<div
								key={country.alpha2_code}
								className={`
									tags-country-list-item
									${selected ? 'selected' : ''}
								`}
								onClick={event => this.handleCountrySelect(country)}>
								{country.name}
							</div>
						)
					})
				}
			</div>
		)
	}

	render() {
		const {
			editing,
			form,
			tt,

			onTypeChange,
			onCountriesChange,
			onInputChange,
			onTrackingToolChange,
			onEventTokensChange,
		} = this.props;
		const {
			countryFocused,
			countryFilter,
		} = this.state;

		return (
			<div className="campaign-create-inner">
				<RadioGroup
					disabled={editing}
					name="campaign-type"
					label="Campaign Type"
					value={form.type}
					options={CampaignTypes}
					onChange={onTypeChange} />

				<ClickOutside onClickOutside={this.handleCountryOutClick}>
					<TagInput
						name={`country-${Math.random().toString(36).substr(2, 9)}`}
						label="Countries"
						placeholder="Enter campaign countries"
						value={form.country}
						addKeys={[]}
						addOnPaste={false}
						pasteSplit={null}
						onChange={onCountriesChange}
						focused={countryFocused}
						wrapperProps={{
							onClick: this.handleCountryFocus
						}}
						inputProps={{
							onChange: this.handleCountryFilter,
							onKeyDown: this.handleCountryFilterKeyPress,
							onFocus: null,
							onBlur: null,
							value: countryFilter,
							ref: this.countryInputRef,
						}}
						portalRootOptions={{
							className: 'tags-country-list-root',
						}}
						portalOptions={{
							left: 0,
							right: 0,
							fullWidth: true,
							onClick: this.handleCountryFocus
						}}
						withOptions
						renderOptions={this.renderCountryOptions} />
				</ClickOutside>

				<Grouped>
					<Input
						label="Minimum App Version"
						placeholder="11.3.02"
						name="target_app_version"
						value={form.target_app_version}
						onChange={onInputChange} />
					<Input
						label="Minimum OS Version"
						placeholder="12.2"
						name="target_os_version"
						value={form.target_os_version}
						onChange={onInputChange} />
					<Input
						label="Attribution SDK Version"
						placeholder="9.3.5"
						name="target_sdk_version"
						value={form.target_sdk_version}
						onChange={onInputChange} />
				</Grouped>

				<Select
					label="Select Your Tracking Tool"
					options={TrackingTools}
					value={[tt.value]}
					clearable={false}
					onChange={([value]) => onTrackingToolChange(TrackingTools.find(o => o.value === value))}
					renderOptionBefore={({ option }) => (
						<div className="select-option-logo">
							<img src={option.icon || icon} alt={option.label} />
						</div>
					)}
					wrapperClassName="select-with-value-logo"
					renderBefore={({ value }) => {
						const tt = TrackingTools.find(o => o.value === value[0]);
						return tt && (
							<div className="select-value-logo">
								<img src={tt.icon || icon} alt={tt.label} />
							</div>
						)
					}} />
				{tt && tt.label === 'Adjust' &&
					<TagInput
						name="event_tokens"
						label="Adjust Event Tokens"
						placeholder="Enter the token and press Enter"
						value={form.event_tokens}
						onChange={onEventTokensChange} />
				}
			</div>
		)
	}
}

export default withTitle(Step2, null, null, match => {
	const editing = match.url.indexOf('/edit') !== -1;
	return `Interceptd - Campaign ${editing ? 'Edit' : 'Create'} ${editing ? `for ${match.params.id} - ` : ' - '} Detail`
});
