import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';
import find from 'lodash/find';
import capitalize from 'lodash/capitalize';
import Humanize from 'humanize-plus';
import {
	Loader,
	NoData,
	PageTitle,
	Breadcrumbs,
	AppIcon,
} from 'interceptd-ui';
import { Grid, Row, Col } from 'react-flexbox-grid';

import StatsBar from './components/ReportDetailStatsBar';
import ReportDetailPie from './components/ReportDetailPie';

import Api from '../../services/api';

import { parseArray, shadowText, isShadow } from '../../utils/misc';

import { ClickReportBlockReasonTypes } from '../../constants/Campaign';
import { Countries } from '../../constants/Countries';

import withTitle from '../withTitle';

import './styles/ReportDetail.css';

const countryNameMap = {};
Countries.forEach((c) => {
	countryNameMap[c.alpha2_code] = c.name;
});

class ReportDetail extends Component {
	state = {
		loading: true,
		campaigns: [],
		report: null,
		totalWrongCountry: 0,
		countryDist: {},
		wrongCountryDist: {},
		reportType: this.props.match?.params?.report_type || 'click',
	}

	round = num => (Math.round(num * 100) / 100)

	handleError = err => {
		this.setState({ loading: false });
		toast.error(get(err, 'data.messaage', 'Something went wrong!'));
	}

	componentDidMount() {
		this.getData();
	}

	getData = async () => {
		try {
			const { match } = this.props;
			const report = get(await Api.getLeoparOfflineReportDetail(match.params.id), 'data.data');
			const publishers = get(await Api.getPublishers({}), 'data.data', []);
			const allCampaigns = get(await Api.getLeoparCampaigns(), 'data.data', []);
			const sources = {};

			allCampaigns.filter((c) => c.application_type !== 'leancheck').forEach((c) => {
				c.sources.forEach((s) => {
					sources[s.id] = sources[s.id] || s;
					sources[s.id].publisherId = s.publisher;
					sources[s.id].publisher = find(publishers, ['id', s.publisher]);
					sources[s.id].publisherName = sources[s.id].publisher.name;
				});
			});

			report.detail = JSON.parse(report.detail);
			report.detail.total = this.totalReportData(report.detail.data);
			report.detail.osDistribution = this.getOsDistribution(report.detail.total);
			report.detail.sourceBlacklistReason = this.getSourceBlacklistReason(report.detail.data);
			report.detail.sourceFlagListReason = this.getSourceFlagListReason(report.detail.data);
			report.detail.totalAnonymousPie = this.getAnonymousClickRate(report);
			report.detail.adNetDistOfRejecteds = this.getAdNetDist(report.detail.data, sources);

			const campaigns = uniqBy([...(parseArray(report.source_id).map(source => allCampaigns.find(campaign => campaign.sources.some(s => s.id === source)))), allCampaigns.find(c => c.id === report.campaign_id)], 'id').filter(c => c);

			let totalWrongCountry = 0;
			let countryDist = null

			if (campaigns.length === 1) {
				totalWrongCountry = this.calculateWrongCountry(report, campaigns[0]);
				countryDist = this.getCountryDist(report, campaigns[0]);
			}

			this.setState({
				campaigns,
				report,
				totalWrongCountry,
				countryDist,
				loading: false,
			});
		} catch (err) {
			this.handleError(err);
		}
	}

	getAdNetDist = (data, sources) => {
		const { reportType } = this.state;
		const adNets = {};
		const labels = [];
		const series = [];
		const fieldName = reportType === 'click'
			? 'rejected'
			: 'flagged';

		data.forEach((d) => {
			const source = sources[d.source];
			adNets[source.publisherName] = adNets[source.publisherName] || 0;
			adNets[source.publisherName] += +d[`${fieldName}_${reportType}_count`];
		});

		for (let key in adNets) {
			labels.push(key);
			series.push(adNets[key]);
		}

		return {
			labels,
			series,
		};
	}

	getOsDistribution = (report) => {
		const { reportType } = this.state;

		const labels = [
			'IOS',
			'Android',
			'Other',
		];

		const iosCount = report[`ios_${reportType}_count`];
		const androidCount = report[`android_${reportType}_count`];
		const totalCount = report[`total_${reportType}_count`];
		const series = [
			iosCount,
			androidCount,
			(totalCount - (iosCount + androidCount)),
		];

		return {
			labels,
			series,
		};
	}

	getCountryDist = (report) => {
		const { reportType } = this.state;
		const countryDist = {};

		get(report, 'detail.data', []).forEach((r) => {
			const countryName = `${countryNameMap[r.device_country || r.country_code || r.country_code || 'other'] || 'other'}`;
			countryDist[countryName] = (+r[`total_${reportType}_count`] + (countryDist[countryName] || 0));
		});

		return countryDist;
	}

	getAnonymousClickRate = (report) => {
		const result = {
			labels: [],
			series: [],
			colors: [
				'#ffc000',
				'#55C4C2',
			],
		};

		const totalAnonymousClick = report.detail.total.anonymous_click_count;
		const totalPublicClick = (report.detail.total.total_click_count - report.detail.total.anonymous_click_count);

		if (totalAnonymousClick > 0) {
			result.labels.push('Anonymous');
			result.series.push(totalAnonymousClick);
		}

		if (totalPublicClick > 0) {
			result.labels.push('Legit');
			result.series.push(totalPublicClick);
		}

		return result;
	}

	totalReportData = (data) => {
		const { reportType } = this.state;
		const fieldName = reportType === 'click' ? 'rejection' : 'flag';
		const total = {
			[`total_${reportType}_count`]: 0,
			[`rejected_${reportType}_count`]: 0,
			[`flagged_${reportType}_count`]: 0,
			[`sbp_${fieldName}_count`]: 0, // subpub block
			[`om_${fieldName}_count`]: 0, // os mismatch
			[`cm_${fieldName}_count`]: 0, // Country Mismatch
			[`iq_${fieldName}_count`]: 0, // Ip Quality
			[`sb_${fieldName}_count`]: 0, // source block
			[`ub_${fieldName}_count`]: 0, // user block
			[`vpn_${reportType}_count`]: 0,
			[`public_proxy_${reportType}_count`]: 0,
			[`tor_exit_${reportType}_count`]: 0,
			[`hosting_provider_${reportType}_count`]: 0,
			[`android_${reportType}_count`]: 0,
			[`ios_${reportType}_count`]: 0,
			anonymous_click_count: 0,
		};

		const objectKeys = Object.keys(total);
		
		data.forEach((d) => {
			objectKeys.forEach((key) => {
				total[key] += Number.parseInt(d[key]);
			});
		});
		
		total.total_of = {};
		objectKeys.forEach((key) => {
			if (key !== `total_${reportType}_count` && 'key' !== 'total_of') {
				total.total_of[key] = (total[key] / total[`total_${reportType}_count`]) * 100;
				total.total_of[key] = Number.isNaN(total.total_of[key])
					? 0
					: this.round(total.total_of[key]);
			}
		});

		return total;
	}

	getSourceBlacklistReason = (data) => {
		const result = {
			labels: [],
			series: [],
		};

		const rejectionFields = [
			'sbp_rejection_count', // subpub block
			'om_rejection_count', // os mismatch
			'cm_rejection_count', // Country Mismatch
			'iq_rejection_count', // Ip Quality
			'sb_rejection_count', // source block
			'ub_rejection_count', // user block
			'ca_rejection_count',
		];

		const totals = {};
		data.forEach((d) => {
			rejectionFields.forEach((rf) => {
				totals[rf] = totals[rf] || 0;
				totals[rf] += Number.parseInt(d[rf]);
			});
		});

		Object.keys(totals).forEach((key) => {
			const [shortCut] = key.split('_');
			if (totals[key]) {
				result.series.push(totals[key]);
				result.labels.push(ClickReportBlockReasonTypes[shortCut]);
			}
		});

		return result;
	}

	getSourceFlagListReason = (data) => {
		const result = {
			labels: [],
			series: [],
		};

		const flagFields = [
			'am_flag_count',
			'ci_flag_count',
			'cm_flag_count',
			'cr_flag_count',
			'cs_flag_count',
			'di_flag_count',
			'ei_flag_count',
			'ic_flag_count',
			'im_flag_count',
			'iq_flag_count',
			'ir_flag_count',
			'od_flag_count',
			'om_flag_count',
			'ov_flag_count',
			'sv_flag_count',
			'ads_flag_count',
			'hcs_flag_count',
			'idt_flag_count',
			'ipe_flag_count',
			'ctit_flag_count',
			'mirt_flag_count',
		];

		const totals = {};
		data.forEach((d) => {
			flagFields.forEach((rf) => {
				totals[rf] = totals[rf] || 0;
				totals[rf] += Number.parseInt(d[rf]);
			});
		});

		Object.keys(totals).forEach((key) => {
			const [shortCut] = key.split('_');
			if (totals[key]) {
				result.series.push(totals[key]);
				result.labels.push(ClickReportBlockReasonTypes[shortCut] || shortCut);
			}
		});

		return result;
	}

	calculateWrongCountry = (report, campaign) => {
		const { reportType } = this.state;
		let total = 0;
		report.detail.data.forEach((r) => {
			if (parseArray(campaign.country).indexOf(r.device_country || r.country_code) === -1) {
				total += (+r[`total_${reportType}_count`]);
			}
		});

		return total;
	}

	render() {
		const { match } = this.props;
		const {
			loading,
			campaigns,
			report,
			totalWrongCountry,
			countryDist,
			reportType
		} = this.state;

		if (loading) {
			return (
				<div className="campaign-detail">
					<Loader />
				</div>
			);
		}

		if (!report) {
			return <NoData icon="box" />;
		}

		return (
			<div className="campaign-detail">
				<PageTitle>
					<Breadcrumbs>
						<Breadcrumbs.Crumb><Link to="/reports">Reports</Link></Breadcrumbs.Crumb>
						<Breadcrumbs.Crumb><Link to={match.url}>{`${capitalize(reportType)} Report Detail`}</Link></Breadcrumbs.Crumb>
					</Breadcrumbs>
					<PageTitle.Title>
						{`${capitalize(reportType)} Report Detail `}
						{campaigns.length === 1 && (
							<React.Fragment>
								- <AppIcon app={campaigns[0].app_name ? campaigns[0].app_info : undefined} isShadow={isShadow()} /> {shadowText(campaigns[0].name, 'Example Campaign')}
							</React.Fragment>
						)}
					</PageTitle.Title>
				</PageTitle>

				<StatsBar
					total={report.detail.total}
					startDate={report.ts_start}
					endDate={report.ts_end}
					reportType={capitalize(reportType)}
				/>

				<div className="top-charts">
					<Grid fluid>
						<Row>
							{reportType === 'click' && (
								<Col xs={12} lg={6}>
									<ReportDetailPie
										title={`Rejection Reason Distribution of Rejected ${capitalize(reportType)}s`}
										labels={report.detail.sourceBlacklistReason.labels}
										series={report.detail.sourceBlacklistReason.series}
									/>
								</Col>
							)}
							<Col xs={12} lg={6}>
								<ReportDetailPie
									title={`Flag Reason Distribution of Flagged ${capitalize(reportType)}s`}
									labels={report.detail.sourceFlagListReason.labels}
									series={report.detail.sourceFlagListReason.series}
								/>
							</Col>


							{campaigns.length === 1 && (
								<React.Fragment>
									<Col xs={12} lg={6}>
										<ReportDetailPie
											title={`Ad Network Distribution of ${reportType === 'click' ? 'Rejected' : 'Flagged'} ${capitalize(reportType)}s`}
											labels={report.detail.adNetDistOfRejecteds.labels}
											series={report.detail.adNetDistOfRejecteds.series}
										/>
									</Col>
									<Col xs={12} lg={6}>
										<ReportDetailPie
											title={`OS Distribution of All ${capitalize(reportType)}s`}
											labels={report.detail.osDistribution.labels}
											series={report.detail.osDistribution.series}
										/>
									</Col>
								</React.Fragment>
							)}

							<Col xs={12} lg={6}>
								<ReportDetailPie
									title={`Country Distribution of All ${capitalize(reportType)}s`}
									labels={countryDist ? Object.keys(countryDist) : []}
									series={countryDist ? Object.values(countryDist) : []}
									alert={`Wrong Country: ${Humanize.intComma(totalWrongCountry)}`}
								/>
							</Col>

							<Col xs={12} lg={6}>
								<ReportDetailPie
									title="Anonymous IP Rate"
									labels={report.detail.totalAnonymousPie.labels}
									series={report.detail.totalAnonymousPie.series}
									colors={report.detail.totalAnonymousPie.colors}
								/>
							</Col>
						</Row>
					</Grid>
				</div>
			</div>
		);
	}
}

export default withTitle(ReportDetail, 'Report Detail');
