import React from 'react';
import { Icon } from 'interceptd-ui';

import logo from '../../assets/icon.svg';

import './styles/DescriptionCard.css';

const DescriptionCart = () => (
	<div className="description-card">
		<div className="logo">
			<img src={logo} alt="Interceptd" />
			<span>Interceptd</span>
		</div>

		<span className="title">Your bright future starts with a simple conversation.</span>

		<div className="time">
			<Icon i="clock" size="16" />
			<span>15 min</span>
		</div>

		<p>
			Get deeper insights from our experts to learn how to protect your campaigns and ROAS
			from Mobile Ad Fraud. Pick a suitable time for you and get started.
		</p>
	</div>
);

export default DescriptionCart;