import Local from './localStorage';
import Api from './api';

class Auth {
	roles = [
		'ROLE_ACCOUNT_MANAGER',
		'ROLE_CUSTOMER',
	]
	isAuthenticated() {
		return !!Local.getItem('token');
	}
	getToken() {
		return Local.getItem('token');
	}
	setToken(token) {
		Local.setItem('token', token);
	}
	removeToken() {
		Local.removeItem('token');
	}
	isRoleValid(roles) {
		return roles.filter(r => this.roles.indexOf(r) !== -1).length !== 0
	}
	logout() {
		Local.setStore({});
		Api.setToken();
		window.location.href = '/';
	}
}

export default new Auth();
