import React from 'react';
import { toast } from 'react-toastify';
import Chart from 'react-apexcharts'
import { IntercomAPI } from 'react-intercom';
import {
	Box,
	Loader,
	NoData,
	Icon,
	Tooltip,
	Button,
} from 'interceptd-ui';

import Editable from '../../components/Input/Editable';
import CountryDistMap from './../Campaign/components/CountryDistMap';

import Api from '../../services/api';
import Local from '../../services/localStorage';
import config from '../../services/config.json';
import SendEvent from '../../services/events';

import { copyTextToClipboard } from '../../utils/file';

import { RuleNameMaps } from '../../constants/Rules';

import './styles/Detail.css';

const PieGraph = props => {
	const { options, getOptions, series, ...rest } = props;
	options.legend = {
		width: 170,
	};

	return (
		<Chart options={getOptions(options)} series={series} type="pie" height="330" { ...rest } />
	)
}

PieGraph.defaultProps = {
	getOptions: options => options,
	options: {
		chart: {
			id: 'app-version-pie',
			type: 'pie',
		},
	},
}

class Detail extends React.Component {
	state = {
		supported_rules: Local.getItem('supported_rules'),
		isPaid: Local.getItem('me').plans?.interceptd === 'paid',

		fetching: true,
		data: {},
		countries: [],
		device_distribution: null,
		device_distribution_labels: null,
		os_distribution: null,
		os_distribution_labels: null,
		alarm_distribution: null,
		alarm_distribution_labels: null,

		copying: {},
		title: this.props.link.title,
	}

	componentDidMount = () => {
		this.fetchData()
	}

	componentDidUpdate = prevProps => {
		if ( prevProps.link.short_url !== this.props.link.short_url )
			this.fetchData()
	}

	fetchData = () => {
		const { link: { title, short_url } } = this.props;

		this.setState({
			fetching: true,
			data: {},
			device_distribution: null,
			device_distribution_labels: null,
			os_distribution: null,
			os_distribution_labels: null,
			alarm_distribution: null,
			alarm_distribution_labels: null,

			copying: {},
			title: title,
		});
		Api.getShortenerLink(short_url)
			.then(response => {
				const { data } = response.data;
				const countries = [];	

				if (data.countries) {
					Object.keys(data.countries).forEach((key) => {
						countries.push({
							id: key,
							value: data.countries[key],
						});
					});
				}

				this.setState(prevState => ({
					fetching: false,
					data: data,
					countries,

					device_distribution: Object.values(data.device_distribution),
					device_distribution_labels: Object.keys(data.device_distribution),

					os_distribution: Object.values(data.os_distribution),
					os_distribution_labels: Object.keys(data.os_distribution),

					alarm_distribution: data.hit_count > 0 ? [
						data.hit_count - Object.values(data.alarms).reduce((acc, curr) => acc + curr, 0),
						...Object.values(data.alarms),
					] : [],
					alarm_distribution_labels: data.hit_count > 0 ? [
						'Clean',
						...Object.keys(data.alarms).map(alarm => {
							const found = prevState.supported_rules.find(rule => rule['short-name'] === alarm);
							return found ? (RuleNameMaps[found.name] || found.name) : alarm
						}),
					] : [],
				}))
			})
			.catch(({ response }) => {
				toast.error(`Couldn't get shortener link detail`);
				SendEvent({
					description: `getShortenerLinks on shortener link detail`,
					fatal: true
				});
				this.setState({
					fetching: false,
					error: true,
				});
			})
	}

	handleCopy = (event, short_url) => {
		const { copying } = this.state;
		event.stopPropagation();
		if ( copying[short_url] ) return false;
		this.setState(prevState => ({
			copying: {
				...prevState.copying,
				[short_url]: true
			}
		}))
		const domain = config[window.location.hostname].shortener;
		copyTextToClipboard(`${domain}${short_url}`);
		setTimeout(() => {
			this.setState(prevState => ({
				copying: {
					...prevState.copying,
					[short_url]: false
				}
			}))
		}, 1200);
		return false;
	}

	handleTitle = event => {
		this.setState({
			title: event.target.value
		})
	}

	handleTitleCancel = value => {
		this.setState({
			title: value
		})
	}

	handleSave = () => {
		const { link: { short_url }, onSaveTitle } = this.props;
		const { title } = this.state;

		if ( title === this.props.link.title )
			return false;

		Api.updateShortenerLink(short_url, { title })
			.then(response => {
				onSaveTitle(short_url, title);
			})
			.catch(({ response }) => {
				toast.error(`Couldn't update link title`);
				SendEvent({
					description: `updateShortenerLink on shortener link detail`,
					fatal: true
				});
				this.setState({
					fetching: false,
					error: true,
				});
			})
	}

	talkToSales = () => {
		IntercomAPI('showNewMessage', 'I want to receive more than 10k clicks to my secure link. Can you help me?');
	}

	render() {
		const { link: { url, short_url, ios_url, android_url } } = this.props;
		const { 
			fetching,
			data,
			countries,
			device_distribution,
			device_distribution_labels,
			os_distribution,
			os_distribution_labels,
			alarm_distribution,
			alarm_distribution_labels,
			copying,
			title,
			isPaid,
		} = this.state;

		const domain = config[window.location.hostname].shortener.replace(/(^\w+:|^)\/\//, '')

		return (
			<React.Fragment>
				{fetching ? (
					<Loader />
				) : (
					<div className="shortener-detail-wrapper">
						<div className="shortener-detail-info">
							<div className="shortener-detail-title">
								<Editable
									placeholder="Title"
									spellCheck={false}
									value={title}
									onChange={this.handleTitle}
									onSave={this.handleSave}
									onCancel={this.handleTitleCancel} />
							</div>
							<div className="shortener-detail-url">
								<span>{url}</span>
								<div className="shortener-detail-url-type">
									{android_url && (
										<Tooltip tooltip={android_url}>
											<span><i className="app-store fab fa-android" /></span>
										</Tooltip>
									)}
									{ios_url && (
										<Tooltip tooltip={ios_url}>
											<span><i className="app-store fab fa-apple" /></span>
										</Tooltip>
									)}
								</div>
							</div>
							<div className="shortener-detail-footer">
								<Tooltip
									position="bottom"
									spacing="10px"
									content="Copy"
									className="shortener-detail-shorturl"
									onClick={e => this.handleCopy(e, short_url)}>
									<div className={`shortener-detail-shorturl-inner ${copying[short_url] ? 'copying' : ''}`} data-content="Copied">{domain}{short_url}</div>
								</Tooltip>
								<div className="shortener-detail-stats">
									{
										isPaid
										? (<span>Total Click</span>)
										: (
											<span className="shortener-total-click">
												<Icon i="help-circle" size="15" />
												Remaining Free Click
												<div className="free-mode-warning-detail">
													<div className="free-mode-warning-detail-content">
														<span>Each secure URLs can be used 10K at max in free mode. To use it without any limitation.</span>
														<Button mini onClick={this.talkToSales}>Talk to Sales</Button>
													</div>
												</div>
											</span>
										)
									}
									<b>{isPaid ? data.hit_count : (10000 - data.hit_count) }</b>
								</div>
							</div>
						</div>

						<div className="shortener-detail-graphs">

							<div className="irow">

								<div className="icol">
									<Box title="Alarms">
										{alarm_distribution.length > 0 ? (
											<PieGraph
												series={alarm_distribution}
												getOptions={options => ({
													...options,
													labels: alarm_distribution_labels,
													chart: {
														...options.chart,
														id: 'alarm-dist',
													},
													colors: ['#66DA26', '#E91E63', '#E91E63', '#E91E63']
												})} />
										) : (
											<NoData />
										)}
									</Box>
								</div>

								<div className="icol">
									<Box title="Country Distribution">
										{countries.length > 0 ? (
											<CountryDistMap data={countries} />
										) : (
											<NoData />
										)}
									</Box>
								</div>

							</div>

							<div className="irow">
								<div className="icol">
									<Box title="OS Distribution">
										{os_distribution.length > 0 ? (
											<PieGraph
												series={os_distribution}
												getOptions={options => ({
													...options,
													labels: os_distribution_labels,
													chart: {
														...options.chart,
														id: 'os-dist',
													}
												})} />
										) : (
											<NoData />
										)}
									</Box>
								</div>

								<div className="icol">
									<Box title="Device Distribution">
										{device_distribution.length > 0 ? (
											<PieGraph
												series={device_distribution}
												getOptions={options => ({
													...options,
													labels: device_distribution_labels,
													chart: {
														...options.chart,
														id: 'device-dist',
													}
												})} />
										) : (
											<NoData />
										)}
									</Box>
								</div>

							</div>

						</div>
					</div>
				)}
			</React.Fragment>
		)
	}
}

export default Detail;
