import React from 'react';
import find from 'lodash/find';

import './styles/SelectedAlarms.css';

export default function SelectedAlarms({ alarms, supportedRules }) {
  return (
    <div className="common-box selected-alarms-container">
      <div className="selected-alarm-dot" />
      <div className="selected-alarm-body">
        <span className="selected-alarms-title">Selected Alarms</span>
        <span className="selected-alarms-alarms">{alarms.length === 0 ? '-' : alarms.filter(a => {
          return !!find(supportedRules, ['short-name', a.name]);
        }).map(a => find(supportedRules, ['short-name', a.name]).name).join(', ')}</span>
      </div>
    </div>
  );
}

SelectedAlarms.defaultProps = {
  alarms: [],
};