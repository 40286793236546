import React from 'react';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import queryString from 'query-string'
import { Icon, Button } from 'interceptd-ui';

import LoginSlogan from '../../components/LoginSlogan';

import Api from '../../services/api';
import Auth from '../../services/auth';
import Local from '../../services/localStorage';
import SendEvent from '../../services/events';

import Logo from '../../assets/logo.svg';

import config from '../../services/config.json'

import './styles/Login.css';

import withTitle from '../withTitle';

class Verify extends React.Component {
	state = {
		sending: false,
		sent: false,
		redirect: false,
		to: '/login',
		redirectTo: '/create',
	}

	UNSAFE_componentWillMount = () => {
		const { match, location } = this.props;
		const searchParams = queryString.parse(location.search);
		if ( match.params.code ) {
			Api.verifyUser(match.params.code)
				.then(response => {
					const token = response?.data?.data?.token;

					if ( token ) {
						Auth.setToken(token);
						Api.setToken(Auth.getToken());
						Local.setItem('login_as', false);
						const to = searchParams ?
							searchParams.ref === 'influencer' ? `${config.influencerUrl}/switch/${token}` :
							searchParams.ref === 'index.interceptd.com' ? '/survey' :
							searchParams.ref === 'secure-links' ? '/secure-link' : 
							searchParams.ref === 'fraud-assessment' ? '/fraud-insights' : 
							'/create' : '/create';
						window.location.href = to;
					} else {
						toast.success('Email is verified. You can sign in now!');
						this.setState({
							redirect: true,
							to: '/login',
							redirectTo: searchParams ?
								searchParams.ref === 'index.interceptd.com' ? '/survey' :
								searchParams.ref === 'secure-links' ? '/secure-link' : 
								'/create' : '/create',
						})
					}
				})
				.catch(({ response }) => {
					this.setState({
						error: response,
					});
					toast.error(response.data.error.message);
				});
		}
	}

	resendVerifyEmail = () => {
		const { location: { state } } = this.props;

		SendEvent({
			category: 'NonAuth',
			action: 'Send again verify mail button clicked',
			nonInteraction: false,
		});

		this.setState({ sending: true })
		Api.resendVerifyEmail({
			email: state.email
		})
			.then(response => {
				this.setState({
					sending: false,
					sent: true
				})
			})
			.catch(({ response }) => {
				SendEvent({
					description: 'resendVerifyEmail on Verify',
					fatal: true,
				});

				this.setState({
					isFetching: false,
					error: response,
				});
				toast.error(response.data.error.message);
			});
	}

	render() {
		const { sending, sent, redirect, to, redirectTo } = this.state;
		const { location: { state }, match } = this.props;

		if ( redirect ) {
			return <Redirect to={{
				pathname: to,
				state: {
					referrer: '/verify',
					redirectTo: redirectTo,
				},
			}} />
		}

		return (
			<div className="login">
				<div className="login-wrapper">
					<LoginSlogan />
					<div className="login-right">
						<header className="login-header">
							<img src={Logo} alt="Interceptd" />
						</header>
						<div className="verify-wrapper">
							{match.params.code ? (
								<div className="verify">
									<Icon i="activity" size={45} />
									<h1>Verifing your email address...</h1>
								</div>
							) : (
								<div className="verify">
									<Icon i="mail" size={45} />
									<h1>Verify Email Address</h1>
									<p>Look for the verification email in your inbox and click the link in that email. A confirmation message will appear in your web browser.</p>
									<p>Didn't get the email? Check your spam folder to make sure it didn't end up there</p>
									{state && state.email &&
										<Button block onClick={this.resendVerifyEmail} loading={sending} disabled={sent}>{sent ? 'Sent' : 'Send again'}</Button>
									}
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default withTitle(Verify, 'Verify Your Account');
