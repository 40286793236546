import React, { useState, useEffect, useCallback } from 'react';
import TetherComponent from 'react-tether';
import debounce from 'lodash/debounce';
import unionBy from 'lodash/unionBy';
import {
	Loader,
	Icon,
	Input,
	AppIcon,
} from 'interceptd-ui';

import Api from '../../services/api';

import { getStoreUrl, isShadow, shadowText } from '../../utils/misc';
import { useOutClick } from '../../utils/hooks';

import './AppSearch.css';

export default function AppSearch({ setAppData, defaultText = '' }) {
	const [searchText, setSearchText] = useState(defaultText);
	const [appList, setAppList] = useState([]);
	const [userAppList, setUserAppList] = useState([]);
	const [loading, setLoading] = useState(true);
	const [isOpen, setIsOpen] = useState(false);
	const [filterText, setFilterText] = useState('');
	const inputRef = React.useRef();
	const listRef = React.useRef();

	useEffect(() => {
		Api.getLeoparCampaigns({})
			.then((response) => {
				const userApps = unionBy((response?.data?.data || []).filter((c) => c.application_type !== 'leancheck').map((a) => {
					const appInfo = a.app_info;
					const sourceNames = a.sources.map((s) => s.name).join(', ');
					appInfo.publisher = sourceNames;
					appInfo.target_os_version = a.target_os_version;
					appInfo.target_app_version = a.target_app_version;
					appInfo.title = shadowText(appInfo.title, 'Example App');
					return appInfo;
				}), 'id');
				setUserAppList(userApps);
				setLoading(false);
			})
			.catch((err) => {
				console.error(err);
				setLoading(false);
			});
	}, []);

	useOutClick([inputRef, listRef], () => {
		setIsOpen(false);
	}, isOpen, [isOpen]);

	const handleTextChange = ({ target }) => {
		setSearchText(target.value);
		search(target.value);
		if (target.value.length < 4) {
			setAppList([]);
		}
	};

	const handleSelect = (selectedApp) => {
		setIsOpen(false);
		setSearchText(selectedApp.title);
		setAppData(selectedApp);
	};

	const search = useCallback(debounce(async (text) => {
		try {
			if (text.length < 4 && loading) return;

			setLoading(true);
			setIsOpen(true);
			setAppList([]);
			const storeUrl = getStoreUrl(text);
			const list = [];

			if (!storeUrl) {
				const response = await Api.getAppInfoByName({ text });
				const data = response?.data?.data || {};

				(data?.android?.results || []).map((a) => {
					const androidApp = convertAndroid(a);
					return list.push(androidApp);
				});

				(data?.ios?.results || []).map((a) => {
					const iosApp = convertIos(a);
					return list.push(iosApp);
				});
			} else {
				const app = await searchByUrl(storeUrl);
				if (app) {
					list.push(app);
				}
			}

			setIsOpen(true);
			setLoading(false);
			setAppList(list);
		} catch (err) {
			console.error(err);
			setIsOpen(false);
			setLoading(false);
		}
	}, 1200), []);

	const searchByUrl = async (url) => {
		try {
			const response = await Api.getAppInfo({ url });
			const app = response?.data || {};
			app.publisher = '';
			return app;
		} catch (err) {
			return null
		}
	};

	return (
		<div className="app-search-margin">
			<TetherComponent
				targetAttachment="bottom left"
				attachment="top left"
				renderTarget={(ref) => (
					<div ref={ref}>
						<div ref={inputRef} className="app-search-wrapper">
							<Input
								label="App Name or Link (App Store or Google Play)"
								className="app-search-input"
								placeholder="Search your app..."
								value={shadowText(searchText, 'Example Text')}
								onChange={handleTextChange}
								onFocus={() => {
									setIsOpen(loading || userAppList.length > 0 || appList.length > 0);
								}}
							/>
						</div>
					</div>
				)}
				renderElement={(ref) => isOpen && (
					<div ref={ref}>
						<div
							ref={listRef}
							className="app-search-list-wrapper"
							style={{ width: (inputRef && inputRef.current) ? inputRef.current.getBoundingClientRect().width : 200 }}
						>
							<div className="select-search">
								<Icon i="search" size={14} />
								<input
									className="select-search-input"
									type="text"
									placeholder="Filter..."
									spellCheck={false}
									value={filterText}
									onChange={({ target }) => setFilterText(target.value)}
								/>
							</div>
							{loading ? <div className="mini-loader-wrapper"><Loader mini /></div> : (searchText !== '' ? appList : userAppList).filter((app) => {
								if (!filterText) return true;
								return (app?.title?.toLowerCase().indexOf(filterText.trim().toLowerCase()) > -1 || app?.publisher?.toLowerCase()?.indexOf(filterText.trim().toLowerCase()) > -1);
							}).map((app, index) => app && (
								<div className="app-search-list-item" key={`${app.id}-${index}`} onClick={() => handleSelect(app)}>
									<AppIcon showStore app={app} isShadow={isShadow()} />
									<div className="app-search-list-item-detail">
										<span className="app-search-list-item-detail-title">{app.title}</span>
										<span className="app-search-list-item-detail-publisher">{app.publisher}</span>
									</div>
								</div>
							))}
						</div>
					</div>
				)}
			/>
		</div>
	);
}

const convertAndroid = (app) => {
	return ({
		id: app.package_name,
		device: "android",
		websafe_url: app.market_url,
		title: app.title,
		icon_url: app.icon_72,
		category: app.category,
		publisher: app.developer,
		bundle_id: app.package_name,
		target_os_version: app.min_sdk.replace(/([\w|\s])\w+/g, ''),
		target_app_version: app.version,
	});
};

const convertIos = (app) => {
	return ({
		id: `${app.trackId}`,
		device: "ios",
		websafe_url: app.trackViewUrl,
		title: app.trackCensoredName,
		icon_url: app.artworkUrl60,
		category: app.primaryGenreName,
		publisher: app.artistName,
		bundle_id: app.bundleId,
		target_os_version: app.minimumOsVersion,
		target_app_version: app.version,
	});
};