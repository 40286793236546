import React from 'react';
import { NavLink, Route, Switch } from 'react-router-dom';
import {
	PageTitle,
	SecondaryNav,
	FilterBox,
} from 'interceptd-ui';

import MyAccountUpdate from './MyAccountUpdate';
import MyAccountNotification from './MyAccountNotification';
import ManageRulesets from './ManageRulesets';
import RulesetCreateEdit from './ManageRulesets/RulesetCreateEdit';
import AppSettings from './AppSettings';
import TeamSettings from './TeamSettings';
import { default as Publishers } from '../Publishers/List';

import './styles/MyAccount.css';

import withTitle from '../withTitle';

class MyAccount extends React.Component {
	state = {

	}

	render() {
		return (
			<div className="account">
				<PageTitle>
					<PageTitle.Title>Account settings</PageTitle.Title>
				</PageTitle>

				<FilterBox>
					<div className="filter-column filter-column-left">
						<SecondaryNav>
							<SecondaryNav.NavItem>
								<NavLink
									exact
									activeClassName="active"
									to="/account">
									Update Account
								</NavLink>
							</SecondaryNav.NavItem>
							<SecondaryNav.NavItem>
								<NavLink
									exact
									activeClassName="active"
									to="/account/team">
									Team Settings
								</NavLink>
							</SecondaryNav.NavItem>
							<SecondaryNav.NavItem>
								<NavLink
									exact
									activeClassName="active"
									to="/account/notifications">
									Notifications
								</NavLink>
							</SecondaryNav.NavItem>
							<SecondaryNav.NavItem>
								<NavLink
									exact
									activeClassName="active"
									to="/account/apps">
									Apps
								</NavLink>
							</SecondaryNav.NavItem>
      		    <SecondaryNav.NavItem>
								<NavLink
									exact
									activeClassName="active"
									to="/account/publishers">
									Publishers
								</NavLink>
							</SecondaryNav.NavItem>
							<SecondaryNav.NavItem>
								<NavLink
									exact
									isActive={(match, location) => (location?.pathname?? '').indexOf('manage-rulesets') !== -1}
									activeClassName="active"
									to="/account/manage-rulesets">
									Manage Rulesets
								</NavLink>
							</SecondaryNav.NavItem>
						</SecondaryNav>
					</div>
				</FilterBox>

				<div className="account-content">
					<Switch>
						<Route exact path="/account" component={MyAccountUpdate}/>
						<Route exact path="/account/team" component={TeamSettings}/>
						<Route exact path="/account/notifications" component={MyAccountNotification}/>
						<Route exact path="/account/apps" component={AppSettings}/>
						<Route exact path="/account/publishers" component={Publishers}/>
						<Route exact path="/account/manage-rulesets" component={ManageRulesets}/>
						<Route exact path="/account/manage-rulesets/create" component={RulesetCreateEdit} />
						<Route exact path="/account/manage-rulesets/:ruleset_id" component={RulesetCreateEdit} />
					</Switch>
				</div>
			</div>
		)
	}
}

export default withTitle(MyAccount, 'Account');
