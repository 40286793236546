import React from 'react';
import {
	Icon,
	Info,
} from 'interceptd-ui';

import Switch from '../../../../components/Switch';

import { RuleInfos, RuleInputs } from '../../../../constants/Rules';

import { renderAlarmName } from '../../../../utils/misc';

import './styles/Rule.css';

export default function Rule({
  rule,
  values,
  handleBlock,
  handleActivateRule,
  handleValueChange,
  onValueBlur,
}) {
  const bodyRef = React.useRef();
  const [height, setHeight] = React.useState(0);

  React.useEffect(() => {
    setHeight(bodyRef && bodyRef.current ? bodyRef.current.scrollHeight : 0);
  }, [rule, values, bodyRef]);

  const shortName = rule['short-name'];
  const inputs = RuleInputs[shortName];
  const info = RuleInfos[shortName];

  return (
    <div className={`common-box rule-container ${values && values.alarm ? 'is-open' : ''}`}>
      <div className="rule-header">
        <span className="rule-name">{renderAlarmName(rule.name)}</span>
        <div className="rule-actions">
          <div className={`rule-block ${values && values.block ? 'active' : ''}`} tooltip={`Block ${shortName === 'EI' ? 'Event/Install' : shortName === 'IR' ? 'Install' : 'Publisher'}`} onClick={() => handleBlock(rule)}>
            <Icon i="slash" size={18} data-event-category="Ruleset" data-event-action="Rule block icon clicked" />
          </div>
          <div className="rule-alarm" tooltip="Send Alarm">
            <Switch on={values && values.alarm} onClick={on => handleActivateRule(on, rule)} />
          </div>
        </div>
      </div>
      <div ref={bodyRef} className="rule-body" style={{ '--height': `${height}px` }}>
        {info && <div className="rule-info">
          <Icon i="info" size="14" />
          <div>{RuleInfos[shortName]}</div>
        </div>}
        {rule.detail && rule.detail.user_inputs &&
          <div className="rule-inputs-account">
            {Object.keys(rule.detail.user_inputs).filter((input) => (inputs && inputs[input] ? !inputs[input].hidden : true)).map((input, key) => {
              return (
                <div className={`rule-input-account ${rule.detail.user_inputs[input].type === 'boolean' ? 'rule-input-account-inline' : ''}`} key={key}>
                  <div className="rule-input-account-label">
                    <label htmlFor={`${shortName}-${rule.detail.user_inputs[input].type}-${key}`}>
                      {inputs?.[input]?.label || input} {rule.detail.user_inputs[input].type !== 'boolean' && (inputs && inputs[input] && inputs[input].info !== '' && <Info position="right" modalName={`${shortName}-info`} data-event-category="Ruleset" data-event-action={`Rule ${rule.name} ${input} input info icon clicked`}>{inputs[input].info}</Info>)}
                    </label>
                    {rule.detail.user_inputs[input].type === 'boolean' && ((inputs?.[input]?.info || '') !== '' && <Info position="right" modalName={`${shortName}-info`} data-event-category="Ruleset" data-event-action={`Rule ${rule.name} ${input} input info icon clicked`}>{inputs[input].info}</Info>)}
                  </div>
                  {rule.detail.user_inputs[input].type === 'textbox' ? (
                    <input
                      name={input}
                      id={`${shortName}-${rule.detail.user_inputs[input].type}-${key}`}
                      type={inputs?.[input]?.type || 'number'}
                      spellCheck={false}
                      value={values && values.user_inputs && values.user_inputs[input]}
                      min={rule.detail.user_inputs[input].min || 0}
                      max={rule.detail.user_inputs[input].max}
                      onChange={e => handleValueChange(e, rule)}
                      onBlur={e => onValueBlur(e, rule)}
                      data-event-category="Ruleset"
                      data-event-action="Rule input clicked"
                    />
                  ) : rule.detail.user_inputs[input].type === 'boolean' ? (
                    <label
                      className="rule-input-account-checkbox"
                      htmlFor={`${shortName}-${rule.detail.user_inputs[input].type}-${key}`}
                      data-event-category="Ruleset"
                      data-event-action="Rule input checkbox clicked"
                    >
                      <input
                        name={input}
                        id={`${shortName}-${rule.detail.user_inputs[input].type}-${key}`}
                        type="checkbox"
                        checked={!!(values && values.user_inputs[input])}
                        onChange={e => handleValueChange(e, rule)}
                      />
                      <i>{!!(values && values.user_inputs[input]) ? 'Yes' : 'No'}</i>
                      <span />
                    </label>
                  ) : (
                      <select
                        name={input}
                        id={`${rule['short-name']}-${rule.detail.user_inputs[input].type}-${key}`}
                        placeholder={input}
                        value={values && values.user_inputs[input]}
                        onChange={e => handleValueChange(e, rule)}
                        data-event-category="Ruleset"
                        data-event-action="Rule input select clicked"
                      >
                        {rule.detail.user_inputs[input].values.map(value => (
                          <option value={value} key={value}>{value}</option>
                        ))}
                      </select>
                      )}
                  {inputs && inputs[input] && inputs[input].icon !== '' &&
                    <Icon i={inputs[input].icon} size={15} />
                  }
                </div>
              )
            })}
          </div>
        }
      </div>
    </div>
  );
}

Rule.defaultProps = {
  rule: {},
  values: null,
  handleBlock: () => null,
  handleActivateRule: () => null,
  handleValueChange: () => null,
  onValueBlur: () => null,
};
