import React from 'react';
import {
	Box,
	NoData,
	FraudGraph,
} from 'interceptd-ui';

import Table from '../../components/Table';

import { columns as ListAppsColumns } from './ListAppsColumns';

import SendEvent from '../../services/events';

import './styles/ListApps.css';
import history from '../../services/history';

class ListApps extends React.Component {
	state = {
		apps: [],

		expandedState: {},
	}

	static getDerivedStateFromProps = (props, state) => {
		const { campaigns, campaignsFetching } = props;

		if ( campaignsFetching )
			return null

		const apps = campaigns.reduce((acc, cur) => {
			const newAcc = [...acc];
			cur.sources.forEach(source => {
				// Find App
				const foundApp = newAcc.findIndex(a => a.id === cur.app_info.id);
				if ( foundApp === -1 ) {
					// No App, add
					const app = {
						...(cur.app_info)
					};
					app.sources = [source];
					newAcc.push(app);
				} else {
					// App found, add source
					newAcc[foundApp].sources = [
						...(newAcc[foundApp].sources),
						source,
					]
				}
			})
			return newAcc;
		}, [])

		return ({ apps })
	}

	showSources = index => {
		const { expandedState } = this.state;

		SendEvent({
			category: 'Campaign List',
			action: `Campaign sources panel ${expandedState[index] ? 'closed' : 'opened'}`,
			nonInteraction: false,
		});
		this.setState({
			expandedState: expandedState[index] ? {}: {
				[index]: true
			},
		});
	}

	renderStatsCell = ({ original, column }) => {
		const {
			campaignStatsFetching,
			campaignInstallAndEventFetching,
			getCellStats,
		} = this.props;

		const isClickCell = column.id === 'click';
		const isEventCell = column.id === 'event' || column.id.indexOf('kpi') > -1;
		const data = getCellStats({ original, column });

		return  (
			<div className={original.campaign ? 'campaign-table-fraud-bar-container' : ''}>
				<FraudGraph
					data={data}
					showUnique={isEventCell}
					title={column.Header}
					loading={isClickCell ? campaignStatsFetching : campaignInstallAndEventFetching} />
			</div>
		)
	}

	redirectSettings = ({ original }) => {
		history.push('/account/apps', {
			app: original.id,
		});
	}

	render() {
		const { campaignsFetching, getTotalStats } = this.props;
		const { apps, expandedState } = this.state;

		return (
			<div className="campaign-list-apps">
				<Box
					className="box-table-wrapper"
					title="APPS">
					<Table
						data={apps}
						columns={
							ListAppsColumns({
								getTotalStats,
								renderStatsCell: this.renderStatsCell,
								onExpandedChange: this.showSources,
								redirectSettings: this.redirectSettings,
							})
						}
						pages={0}
						pageSize={apps.length}
						loading={campaignsFetching}
						showPagination={false}
						showPageSizeOptions={false}
						showPageJump={false}
						defaultSortDesc={true}
						defaultSorted={[{
							id: "name",
							desc: false
						}]}
						expanded={expandedState}
						PadRowComponent={() => null}
						SubComponent={this.props.renderSources}
						NoDataComponent={() => (
							<NoData>You didn't create any campaign yet.</NoData>
						)}
						getTrGroupProps={(state, rowInfo, column) => {
							const { expandedState } = this.state;

							if (rowInfo && expandedState[rowInfo.viewIndex]) {
								return ({
									className: 'table-grouper -open'
								})
							}

							return ({
								className: 'table-grouper'
							})
						}}
					/>
				</Box>
			</div>
		)
	}
}

export default ListApps;
