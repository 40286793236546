import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import ClickOutside from 'react-click-outside';
import find from 'lodash/find';
import {
	Icon,
	Button,
	Select,
	Input,
} from 'interceptd-ui';

import Api from '../../services/api';
import SendEvent from '../../services/events';

import { TrackingTools } from '../../constants/Campaign';

import './styles/KPISettings.css';

class KPISettings extends React.PureComponent {
	state = {
		isOpen: false,
		selectIsOpen: false,
		isSaving: false,
		kpis: {
			1: '',
			2: '',
			3: '',
			4: '',
			5: '',
		},
	}

	UNSAFE_componentWillMount = () => {
		const { campaign } = this.props;
		const { kpis } = this.state;

		const newKpis = {};
		Object.keys(kpis).forEach(kpi => {
			const before = find(campaign.kpis, ['kpi', +kpi]);
			newKpis[kpi] = before ? before.event_name : '';
		})

		this.setState({
			kpis: newKpis
		})
	}

	handleToggle = () => {
		this.setState(prevState => ({
			isOpen: !prevState.isOpen
		}))
	}

	handleOpen = () => {
		this.setState({
			isOpen: true
		})
	}

	handleClose = () => {
		this.setState({
			isOpen: false
		})
	}

	handleInput = event => {
		const { kpis } = this.state;
		const { name, value } = event.target;

		const n = name.replace('kpi-input-', '');

		this.setState({
			kpis: {
				...kpis,
				[+n]: value,
			}
		})
	}

	handleSelect = (value, name) => {
		const { kpis } = this.state;

		this.setState({
			kpis: {
				...kpis,
				[+name]: value
			}
		})
	}

	handleSubmit = () => {
		const { campaign, refreshKpi } = this.props;
		const { kpis } = this.state;

		const events = {...kpis};
		Object.keys(events).forEach((key) => {
			if ( events[key] === '' || events[key] === null ) {
				delete events[key]
			}
		});


		this.setState({ isSaving: true });
		Api.setCampaignKPIs(campaign.id, {
			events: events
		})
			.then(response => {
				toast.success(`Campaign KPIs saved`);
				if (refreshKpi && typeof refreshKpi === 'function') {
					refreshKpi();
				}
				this.setState({
					isSaving: false,
				}, this.handleClose);

				SendEvent({
					action: 'Campaign Detail KPI Set',
					sendMixPanel: true,
				})
			})
			.catch(({ response }) => {
				this.setState({ isSaving: false });
				toast.error(`Couldn't save campaign KPIs`);
			})
	}

	render() {
		const { campaign } = this.props;
		const { isOpen, kpis, isSaving, selectIsOpen } = this.state;

		const TT = find(TrackingTools, ['value', campaign.tt]);

		return (
			<ClickOutside onClickOutside={!selectIsOpen ? this.handleClose : () => {}} className={`kpi-settings ${isOpen ? 'focused': ''}`}>
				<Button mini bgColor="transparent" className="add-new-campaign-button" onClick={this.handleToggle}>
					<Icon i="settings" /> Set KPI
				</Button>
				{isOpen &&
					<div className="kpi-modal">
						{TT.label === 'Adjust' && (!campaign.event_tokens || campaign.event_tokens.length === 0) ? (
							<div className="infobox kpi-no-event">
								<Icon i="alert-circle" size={20} />
								<span>Enter Adjust event tokens to track your KPI's. You can add them in <Link to={`/campaign/${campaign.id}/edit/detail`}>Campaign Edit</Link> page.</span>
							</div>
						) : (
							<div className="infobox">
								<Icon i="info" size={20} />
								Enter event names that you want to track
							</div>
						)}
						{!(TT.label === 'Adjust' && (!campaign.event_tokens || campaign.event_tokens.length === 0)) && Object.keys(kpis).map((kpi, index) => (
							<div className="kpi" key={index}>
								{TT.label === 'Adjust' ? (
									<Select
										mini
										clearable
										label={`KPI ${kpi}: `}
										placeholder={`Select an event`}
										name={`kpi-input-${kpi}`}
										options={campaign.event_tokens.map(token => ({
											value: token,
											label: token,
										}))}
										onFocus={() => this.setState({ selectIsOpen: true })}
										onBlur={() => this.setState({ selectIsOpen: false })}
										value={campaign.event_tokens.indexOf(kpis[kpi]) !== -1 ? [kpis[kpi]] : []}
										onChange={([val]) => this.handleSelect(val, kpi)} />
								) : (
									<Input 
										label={`KPI ${kpi}:`}
										placeholder="Event name"
										name={`kpi-input-${kpi}`}
										onChange={this.handleInput}
										value={kpis[kpi]} />
								)}
							</div>
						))}
						{!(TT.label === 'Adjust' && (!campaign.event_tokens || campaign.event_tokens.length === 0)) &&
							<div className="kpi-footer">
								<Button onClick={this.handleSubmit} loading={isSaving}>Save</Button>
							</div>
						}
					</div>
				}
			</ClickOutside>
		)
	}
}

export default KPISettings;
