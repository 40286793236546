import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import { toast } from 'react-toastify';
import {
	Icon,
	Button,
} from 'interceptd-ui';

import Ruleset from './components/Ruleset';

import Api from '../../../services/api';
import Local from '../../../services/localStorage';
import RulesetService from '../../../services/rulesets';

import { getQueryParams } from '../../../utils/misc';

import './styles/ManageRulesets.css';

export default class ManageRuleset extends Component {
	state = {
		rulesets: [],
		ruleCampaignMap: {},
		supportedRules: [],
		returnUrl: getQueryParams('return_url', get(this, 'props.location.search', '')),
	};

	componentDidMount() {
		this.getRuleData();
		this.getCampaigns();
		this.rulesetListener = Local.listen('rulesets', () => {
			this.setState({
				rulesets: Local.getItem('rulesets') || [],
			});
		});
		this.supportedRulesListener = Local.listen('supported_rules', () => {
			this.setState({
				supportedRules: Local.getItem('supported_rules') || [],
			});
		});
	}

	componentWillUnmount() {
		if (this.rulesetListener) Local.unlisten(this.rulesetListener)
		if (this.supportedRulesListener) Local.unlisten(this.supportedRulesListener)
	}

	getCampaigns = async () => {
		try {
			const campaignResponse = await Api.getLeoparCampaigns();
			const campaigns = (campaignResponse?.data?.data || []).filter((c) => c.application_type !== 'leancheck');
			const rulesetResponse = await Api.getCampaignRuleSets(campaigns.map((c) => c.id).join(','));
			const rulesetData = (rulesetResponse?.data?.data || []).filter((r) => r?.source_rulesets.length > 0).flatMap((r) => r?.source_rulesets);
			const campaignNameMap = {};
			const ruleCampaignMap = {};
			const sourceNameMap = {};

			campaigns.forEach((campaign) => {
				campaignNameMap[campaign.id] = campaign.name;
				campaign.sources.forEach((source) => {
					sourceNameMap[source.id] = source.name;
				});
			});

			rulesetData.forEach(({ campaign_id, source_id, ruleset, }) => {
				ruleCampaignMap[ruleset.id] = ruleCampaignMap[ruleset.id] || {};
				ruleCampaignMap[ruleset.id][campaign_id] = ruleCampaignMap[ruleset.id][campaign_id] || {
					name: campaignNameMap[campaign_id],
					sources: [],
				};
				ruleCampaignMap[ruleset.id][campaign_id].sources.push(sourceNameMap[source_id]);
			});
			
			Object.keys(ruleCampaignMap).forEach((key) => {
				ruleCampaignMap[key] = Object.values(ruleCampaignMap[key]);
			});

			this.setState({ ruleCampaignMap })
		} catch (err) {
			console.error(err);
		}
	}

	getRuleData = () => {
		RulesetService.getRulesets();

		Api.getSupportedRules().then(res => {
			Local.setItem('supported_rules', get(res, 'data.data', []))
		}).catch((err) => toast.error(get(err, 'data.error.message', `Couldn't fetch 'Supported Rules'`)));
	}

	render() {
		const {
			rulesets,
			returnUrl,
			ruleCampaignMap,
		} = this.state;

		return (
			<div className="manage-ruleset-wrapper">
				<div className="manage-ruleset-page">
					<div className="manage-ruleset-page-header">
						<div className="back-to-campaign-link">
							{returnUrl && (
								<Link to={returnUrl}>
									<Icon i="arrow-left" size="15" /> <span>back</span>
								</Link>
							)}
						</div>
						<Button
							mini
							bgColor="transparent"
							className="add-new-campaign-button"
							to="manage-rulesets/create"
							component={Link}
						>
							<Icon i="plus" size="15" /> Add New Ruleset
            </Button>
					</div>
					<div className="manage-ruleset-list">
						{rulesets.map(r => <Ruleset key={r.id} ruleset={r} campaigns={ruleCampaignMap[r.id]} />)}
					</div>
				</div>
			</div>
		);
	}
}