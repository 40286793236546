import React from 'react';

import DetailSankey from './DetailSankey';

import Local from '../../services/localStorage';

//Mock
import { CareemAndroidRejectedByMMP } from '../../mocks/RejectedByMMP';
import { CareemIOSRejectedByMMP } from '../../mocks/RejectedByMMP';

const TEMP_Sankey = props => {
	return (
		<div style={{ padding: '50px 0' }}>
			<div className="campaign-graphs">
				<DetailSankey
					title="Careem Android Fraud"
					filters={{
						"publisher": null,
						"start": null,
						"end": null
					}}
					campaign={{
						"id": 16070,
						"account": 75,
						"user": 75,
						"app": "com.careem.acma",
						"app_name": "Careem - Car Booking App",
						"tt": 1,
						"name": "Careem - Android",
						"customer_campaign": "UNAWRLG_X4s-KNRKl6pAee5LT22vsvTR",
						"bid": null,
						"daily_cap": null,
						"type": "CPI",
						"country": "Array[10]",
						"event_tokens": "Array[1]",
						"event_categories": null,
						"note": null,
						"total_cap": null,
						"device_type": "ALL",
						"traffic_type": "NONINCENT",
						"target_os_version": "5.0",
						"target_app_version": "8.8.8",
						"target_sdk_version": "4.18.0",
						"ts_start": 1565770180,
						"ts_end": null,
						"ts_create": 1565770180,
						"ts_update": 1565771529,
						"tracking_url": "https://app.adjust.com/{extra_4}?campaign={publisher_campaign_id}&adgroup={sub_id}&creative={creative_id}&gps_adid={device_id}&deep_link=careem%3A%2F%2F%3Futm_source%3D{pub_name}%26utm_medium%3Dapp%26utm_campaign%3D{publisher_campaign_id}%26utm_content%3D{sub_id}%26utm_term%3D{creative_id}",
						"labels": null,
						"target": "MOBILE",
						"landing_url": null,
						"event_payouts": null,
						"stats": "Object",
						"alarm": "Array[11]",
						"campaign_source_states": "Array[1]",
						"kpi": "Array[1]",
						"user_info": null,
						"app_info": "Object"
					}}
					sourceNames={{
						"139555": "Performics (Digitouch)",
						"139558": "Mobvista",
						"139559": "Yeah Mobi"
					}}
					supported_rules={Local.getItem('supported_rules')}
					supported_campaign_rules={Local.getItem('supported_campaign_rules')}
					mock={CareemAndroidRejectedByMMP} />
				<DetailSankey
					title="Careem iOS Fraud"
					filters={{
						"publisher": null,
						"start": null,
						"end": null
					}}
					campaign={{
						"id": 16070,
						"account": 75,
						"user": 75,
						"app": "com.careem.acma",
						"app_name": "Careem - Car Booking App",
						"tt": 1,
						"name": "Careem - Android",
						"customer_campaign": "UNAWRLG_X4s-KNRKl6pAee5LT22vsvTR",
						"bid": null,
						"daily_cap": null,
						"type": "CPI",
						"country": "Array[10]",
						"event_tokens": "Array[1]",
						"event_categories": null,
						"note": null,
						"total_cap": null,
						"device_type": "ALL",
						"traffic_type": "NONINCENT",
						"target_os_version": "5.0",
						"target_app_version": "8.8.8",
						"target_sdk_version": "4.18.0",
						"ts_start": 1565770180,
						"ts_end": null,
						"ts_create": 1565770180,
						"ts_update": 1565771529,
						"tracking_url": "https://app.adjust.com/{extra_4}?campaign={publisher_campaign_id}&adgroup={sub_id}&creative={creative_id}&gps_adid={device_id}&deep_link=careem%3A%2F%2F%3Futm_source%3D{pub_name}%26utm_medium%3Dapp%26utm_campaign%3D{publisher_campaign_id}%26utm_content%3D{sub_id}%26utm_term%3D{creative_id}",
						"labels": null,
						"target": "MOBILE",
						"landing_url": null,
						"event_payouts": null,
						"stats": "Object",
						"alarm": "Array[11]",
						"campaign_source_states": "Array[1]",
						"kpi": "Array[1]",
						"user_info": null,
						"app_info": "Object"
					}}
					sourceNames={{
						"139555": "Performics (Digitouch)",
						"139558": "Mobvista",
						"139559": "Yeah Mobi"
					}}
					supported_rules={Local.getItem('supported_rules')}
					supported_campaign_rules={Local.getItem('supported_campaign_rules')}
					mock={CareemIOSRejectedByMMP} />
			</div>
		</div>
	)
}

export default TEMP_Sankey;
