import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import find from 'lodash/find';
import {
	Icon,
	Button,
	Copy,
} from 'interceptd-ui';

import Editable from '../../components/Input/Editable';
import SourceTrackingUrlButton from './components/SourceTrackingUrlButton';

import Api from '../../services/api';
import Local from '../../services/localStorage';
import Settings from '../../services/settings';

import { TrackingTools } from '../../constants/Campaign';

import './styles/DetailSummary.css';

class DetailSummary extends React.Component {
	state = {
		data: this.props.data,

		archivedCampaigns: Settings.get('archivedCampaigns', 'account') || [],
	}

	detailRef = React.createRef()

	static getDerivedStateFromProps = (props, state) => {
		return ({
			data: props.data
		})
	}

	handleInputChange = event => {
		const { name, value } = event.target;

		this.setState(prevState => ({
			data: {
				...prevState.data,
				[name]: value,
			},
		}))
	}

	handleInputCancel = (defaultValue, event) => {
		const { name } = event;

		this.setState(prevState => ({
			data: {
				...prevState.data,
				[name]: defaultValue,
			},
		}))
	}

	handleInputSave = event => {
		const { data } = this.state;

		Api.updateCampaign(data.id, {
			target_os_version: data.target_os_version,
			target_app_version: data.target_app_version,
			target_sdk_version: data.target_sdk_version,
		})
			.then(response => {
				toast.success('Campaign updated');
			})
			.catch(({ response }) => {
				toast.error('Something went wrong');
			});
	}

	archiveCampaign = () => {
		const { data, archivedCampaigns } = this.state;
		const isArchived = archivedCampaigns.indexOf(data.id) !== -1;

		if (!isArchived) {
			Settings.set('archivedCampaigns', [
				...(Settings.get('archivedCampaigns', 'account') || []),
				data.id
			], 'account');

			this.setState(prevState => ({
				archivedCampaigns: Settings.get('archivedCampaigns', 'account') || [],
			}), () => {
				toast.success('Campaign archived')
			})
		} else {
			Settings.set('archivedCampaigns', [
				...(Settings.get('archivedCampaigns', 'account') || []).filter(i => i !== data.id),
			], 'account');

			this.setState(prevState => ({
				archivedCampaigns: Settings.get('archivedCampaigns', 'account') || [],
			}), () => {
				toast.success('Campaign unarchived')
			})
		}
	}

	render() {
		const { open, url, isInitialCreate } = this.props;
		const { data, archivedCampaigns } = this.state;

		const isArchived = archivedCampaigns.indexOf(data.id) !== -1;

		const TT = find(TrackingTools, ['value', data.tt]);
		return (
			<div className={`campaign-summary ${open ? 'open' : ''}`}>
				<div className="campaign-summary-detail" ref={this.detailRef} style={{ '--max-height': `${this.detailRef.current && (this.detailRef.current.scrollHeight + 50)}px` }}>
					<div className="campaign-summary-detail-inner">

						<div className="campaign-summary-left">

							<div className="campaign-summary-row">
								<div className="campaign-summary-col">
									<div className="campaign-summary-col-title"><span>ID</span></div>
									<div className="campaign-summary-col-content">
										<span className="campaign-summary-col-data">{data.id}</span>
									</div>
								</div>

								<div className="campaign-summary-col">
									<div className="campaign-summary-col-title"><span>Campaign Type</span></div>
									<div className="campaign-summary-col-content">
										<span className="campaign-summary-col-data">{data.type}</span>
									</div>
								</div>

								<div className="campaign-summary-col">
									<div className="campaign-summary-col-title"><span>Minimum App Version</span></div>
									<div className="campaign-summary-col-content">
										<span className="campaign-summary-col-data">
											<Editable
												name="target_app_version"
												placeholder="11.3.02"
												spellCheck={false}
												value={data.target_app_version || ''}
												onChange={this.handleInputChange}
												onSave={this.handleInputSave}
												onCancel={this.handleInputCancel}>
												{data.target_app_version || 'Not set'}
											</Editable>
										</span>
									</div>
								</div>

								<div className="campaign-summary-col">
									<div className="campaign-summary-col-title"><span>Minimum OS Version</span></div>
									<div className="campaign-summary-col-content">
										<span className="campaign-summary-col-data">
											<Editable
												name="target_os_version"
												placeholder="12.2"
												spellCheck={false}
												value={data.target_os_version || ''}
												onChange={this.handleInputChange}
												onSave={this.handleInputSave}
												onCancel={this.handleInputCancel}>
												{data.target_os_version || 'Not set'}
											</Editable>
										</span>
									</div>
								</div>

								<div className="campaign-summary-col">
									<div className="campaign-summary-col-title"><span>Countries</span></div>
									<div className="campaign-summary-col-content">
										<span className="campaign-summary-col-data">{(data.country || []).length > 0 ? data.country.map(c => find(Local.getItem('countries'), ['alpha2_code', c]).name).join(', ') : 'Global'}</span>
									</div>
								</div>

								<div className="campaign-summary-col">
									<div className="campaign-summary-col-title"><span>Attribution SDK Version</span></div>
									<div className="campaign-summary-col-content">
										<span className="campaign-summary-col-data">
											<Editable
												name="target_sdk_version"
												placeholder="9.3.5"
												spellCheck={false}
												value={data.target_sdk_version || ''}
												onChange={this.handleInputChange}
												onSave={this.handleInputSave}
												onCancel={this.handleInputCancel}>
												{data.target_sdk_version || 'Not set'}
											</Editable>
										</span>
									</div>
								</div>

								<div className="campaign-summary-col">
								<div className="campaign-summary-col-title"><span style={{ marginRight: 10 }}>App URL</span> <Copy value={data?.app_info?.websafe_url}>Copy</Copy></div>
									<div className="campaign-summary-col-content">
										<span className="campaign-summary-col-data">
											{data?.app_info?.websafe_url}
										</span>
									</div>
								</div>
							</div>

							<div className="campaign-summary-row campaign-summary-inner-buttons">
								{Local.getItem('me')?.plans?.interceptd !== 'free' &&
									<Button mini className={`button-icon campaign-archive-button ${isArchived ? 'bg-green' : 'bg-red'}`} bgColor="transparent" onClick={this.archiveCampaign} style={{ marginRight: 10 }}>
										<Icon i="archive" />
										{isArchived ? 'Unarchive' : 'Archive'}
									</Button>
								}
								{Local.getItem('me')?.plans?.interceptd !== 'free' &&
									<Button mini style={{ marginRight: 10 }} bgColor="transparent" to={`${url}/edit`} component={Link}>Edit</Button>
								}
								<Button
									mini
									bgColor="transparent"
									to={`${url}/history`}
									style={{ marginRight: 10 }}
									component={Link}
								>
									History
								</Button>
								<SourceTrackingUrlButton campaign={data} openDefault={isInitialCreate} />
							</div>

						</div>

						<div className="campaign-summary-right">

							<div className="campaign-summary-col">
								<div className="campaign-summary-col-title"><span>Tracking Tool</span></div>
								<div className="campaign-summary-col-content">
									<div className="campaign-summary-col-data">{TT.label}</div>
								</div>
							</div>

						</div>

					</div>
				</div>
			</div>
		)
	}
}

export default DetailSummary;
