import React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Icon } from 'interceptd-ui';

import { downloadFile, bytesToSize } from '../utils/file';

export default class Download {
	constructor(url, fileName) {
		this.config.url = url;
		this.fileName = fileName;
	}

	fileName = '';
	toastId = null;
	cancel = false;
	config = {
		method: 'GET',
		responseType: 'blob',
		onDownloadProgress: p => this.onDownloadProgress(p),
		transformRequest: [(data, headers) => {
			delete headers.Authorization;
			return data;
		}],
	};

	onDownloadProgress = ({ loaded, total }) => {
		if (total < 10000) return;

		const progress = loaded / total;
		const text = `${this.fileName} - ${bytesToSize(total)}`;

		if (this.toastId === null) {
			this.toastId = toast(text, {
				progress,
				progressClassName: 'progress-bar',
				autoClose: 3000,
				onClose: () => this.cancel = true,
				closeButton: <Icon i="x-circle" size="13" />,
			});
		} else {
			toast.update(this.toastId, {
				render: text,
				progress,
			});
		}
	}

	get = () => {
		axios(this.config).then((data) => {
			toast.update(this.toastId, {
				autoClose: 1000,
			});
			toast.done(this.toastId);
			if (!this.cancel) {
				downloadFile(data.data, this.fileName);
			}
		});
	};
}