import React from 'react';
import { toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';
import {
	Button,
	Input,
	AppBox,
	AppPlaceholder,
} from 'interceptd-ui';

import Api from '../../services/api';

import { isStoreURL, isShadow } from '../../utils/misc';

import './styles/Request.css';

class Request extends React.Component {
	state = {
		fetching: false,
		requesting: false,
		redirect: false,
		app: null,
		store_url: '',
	}

	appSearchTimeout = null
	storeUrlInput = React.createRef()

	searchApp = () => {
		const { store_url } = this.state;

		const getType = str => (str.startsWith('id') && str.length >= 11 && !isNaN(str.replace('id', ''))) || (!isNaN(str) && str.length >= 9) ? 'ios' :
												(str.startsWith('com') && str.length >= 5) ? 'android' :
												null;
		const type = getType(store_url);
		const url = type === 'ios' ? `https://itunes.apple.com/app/${!isNaN(store_url) ? 'id' : ''}${store_url}` :
								type === 'android' ? `https://play.google.com/store/apps/details?id=${store_url}` :
								isStoreURL(store_url) ? store_url : null;

		if ( store_url !== '' && url ) {
			Api.cancelAll();
			this.setState({ fetching: true });
			Api.getAppInfo({ url: url })
				.then(response => {
					this.setState({
						fetching: false,
						app: response.data,
					})
				})
				.catch(({ response }) => {
					this.setState({
						error: response,
						fetching: false,
						app: null,
					});
					toast.error('Unable to fetch app info. Please check the store url.', { autoClose: 5000 });
				});
		} else {
			this.setState({
				fetching: false,
				app: null,
			})
			Api.cancelAll();
		}
	}

	handleStoreURL = event => {
		this.setState({
			store_url: event.target.value
		}, () => {
			clearTimeout(this.appSearchTimeout);
			this.appSearchTimeout = setTimeout(this.searchApp, 3000);
		})
	}

	requestReport = () => {
		const { app } = this.state;

		this.setState({ requesting: true })
		Api.requestRedirectionReport({
			app_name: app.title
		})
				.then(response => {
					toast.success('Report requested', { autoClose: 5000 });
					this.setState({
						requesting: false,
						redirect: true,
					})
				})
				.catch(({ response }) => {
					this.setState({
						error: response,
						requesting: false,
						redirect: false,
					});
					toast.error('Unable to request the report', { autoClose: 5000 });
				});
	}

	render() {
		const { fetching, requesting, redirect, app, store_url } = this.state;

		if ( redirect )
			return <Redirect to="/fraud-insights" />

		return (
			<div className="redirection-report-request">
				<div className="login-title">
					<h1>App Fraud Health Report Request</h1>
					<h2>Find your app and request a App Fraud Health report</h2>
				</div>
				<div className="redirection-report-form">
					{app && !fetching ? (
						<AppBox app={app} isShadow={isShadow()} />
					) : (
						<AppPlaceholder loading={fetching || store_url !== ''} />
					)}
					<Input
						inputRef={this.storeUrlInput}
						label="Store URL"
						placeholder="https://itunes.apple.com/app/id44718837"
						name="store_url"
						value={store_url}
						onChange={this.handleStoreURL}
						required />
					<Button block onClick={this.requestReport} disabled={!app} loading={requesting}>Request a Report</Button>
				</div>
			</div>
		)
	}
}

export default Request;
