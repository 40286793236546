import React from 'react';
import sortBy from 'lodash/sortBy';
import {
	ComposableMap,
	Geographies,
	Geography,
} from 'react-simple-maps';
import { scaleLinear } from 'd3-scale';
import { Portal } from 'react-portal';

import World from '../../../constants/world.json';

class CountryDistMap extends React.PureComponent {
	state = {
		currentGeo: null,
		currentValue: 0,
		pageX: 0,
		pageY: 0,
		tooltipActive: false,
	}

	getMinMaxValue = (data = this.props.data) => {
		const sortedData = sortBy(data, ['val']);
		return [
			this.props.minValue || sortedData[0].val,
			this.props.maxValue || sortedData[sortedData.length - 1].val
		];
	}

	customScale = scaleLinear()
		.domain(this.getMinMaxValue())
		.range([this.props.minColor, this.props.maxColor])

	setCountryColor = (val) => {
		if (val < 100) return '#B9CDE1';
		if (val < 200) return '#8d9aa7';
		if (val < 300) return '#6d7782';
		if (val < 400) return '#5C6BC0';
		return '#230060';
	}

	handleMouseEnter = () => {
		this.setState({
			tooltipActive: true,
		})
	}

	handleMouseMove = (geo, evt) => {
		const { data } = this.props;
		const country = data.find(d => d.id === geo.properties['Alpha-2']);

		this.setState({
			currentGeo: geo,
			currentValue: country ? country.value : 0,
			pageX: evt.pageX,
			pageY: evt.pageY,
			tooltipActive: true,
		})
	}

	handleMouseLeave = () => {
		this.setState({
			tooltipActive: false,
		})
	}

	render() {
		const { data } = this.props;
		const { currentGeo, currentValue, pageX, pageY, tooltipActive } = this.state;

		return (
			<div className="country-dist">
				<ComposableMap style={{ width: "100%", height: '400px' }}>
					<Geographies geography={World}>
						{(geos, proj) => geos.map((geo, i) => {
							const country = data.find(d => d.id === geo.properties['Alpha-2'])

							return (
								<Geography
									key={geo.properties['Alpha-2'] + i}
									cacheId={geo.properties['Alpha-2'] + i}
									geography={ geo }
									projection={ proj }
									onMouseEnter={this.handleMouseEnter}
									onMouseMove={this.handleMouseMove}
									onMouseLeave={this.handleMouseLeave}
									style={{
										default: {
											fill: country ? this.setCountryColor(country.value) : "#ECEFF1",
											stroke: "#FFF",
											strokeWidth: 0.75,
											outline: "none",
										},
										hover: {
											fill: "#263238",
											stroke: "#FFF",
											strokeWidth: 0.75,
											outline: "none",
										},
										pressed: {
											fill: "#263238",
											stroke: "#FFF",
											strokeWidth: 0.75,
											outline: "none",
										}
									}} />
							)
						})}
					</Geographies>
				</ComposableMap>
				{tooltipActive &&
					<Portal>
						<span
							className="country-dist-tooltip"
							style={{ '--pageX': `${pageX}px`, '--pageY': `${pageY}px` }}>
							{currentGeo ? `${currentGeo.properties.name}: ${currentValue} ${currentValue > 1 ? 'Installs' : 'Install'}` : ''}
						</span>
					</Portal>
				}
			</div>
		)
	}
}

CountryDistMap.defaultProps = {
	minColor: '#B9CDE1',
	maxColor: '#230060',
}

export default CountryDistMap;