export const CareemAndroidRejectedByMMP = {
	"data": [
		{"source": 139558, "target": "Clean", "value": 85},
		{"source": 139558, "target": "Flagged", "value": 902},
		{"source": 139558, "target": "Rejected by MMP", "value": 2590},

		{"source": "Flagged", "target": "CI", "value": 404},
		{"source": "Flagged", "target": "CTIT", "value": 21},
		{"source": "Flagged", "target": "CM", "value": 8},
		{"source": "Flagged", "target": "AM", "value": 18},
		{"source": "Flagged", "target": "IPE", "value": 30},
		{"source": "Flagged", "target": "IR", "value": 120},
		{"source": "Flagged", "target": "IQ", "value": 16},
		{"source": "Flagged", "target": "IM", "value": 3},
		{"source": "Flagged", "target": "DI", "value": 2},
		{"source": "Flagged", "target": "MIRT", "value": 19},
		{"source": "Flagged", "target": "EI", "value": 246},
		{"source": "Flagged", "target": "CR", "value": 6},
		{"source": "Flagged", "target": "SV", "value": 1},
		{"source": "Flagged", "target": "OV", "value": 8},
		
		{"source": "Rejected by MMP", "target": "IPE", "value": 1},
		{"source": "Rejected by MMP", "target": "IQ", "value": 1},
		{"source": "Rejected by MMP", "target": "MIRT", "value": 137},
		{"source": "Rejected by MMP", "target": "EI", "value": 1546},
		{"source": "Rejected by MMP", "target": "CR", "value": 129},
		{"source": "Rejected by MMP", "target": "OV", "value": 2},
	]
}

export const CareemIOSRejectedByMMP = {
	"data": [
		{"source": 139558, "target": "Clean", "value": 74},
		{"source": 139558, "target": "Flagged", "value": 428},
		{"source": 139558, "target": "Rejected by MMP", "value": 259},

		{"source": "Flagged", "target": "CM", "value": 34},
		{"source": "Flagged", "target": "IQ", "value": 237},
		{"source": "Flagged", "target": "AM", "value": 45},
		{"source": "Flagged", "target": "IR", "value": 6},
		{"source": "Flagged", "target": "CS", "value": 31},
		{"source": "Flagged", "target": "IPE", "value": 65},
		{"source": "Flagged", "target": "EI", "value": 8},
		{"source": "Flagged", "target": "CTIT", "value": 2},
		
		{"source": "Rejected by MMP", "target": "IQ", "value": 20},
		{"source": "Rejected by MMP", "target": "AM", "value": 138},
		{"source": "Rejected by MMP", "target": "CS", "value": 94},
	]
}