import React from 'react';
import moment from 'moment';
import Humanize from 'humanize-plus';
import { Icon, Tooltip, AppIcon } from 'interceptd-ui';

import { shadowText, isShadow } from '../../utils/misc';

export const getColumns = ({
	renderStatsCell,
	selectedEventColumns = [],
	columnType = 'source',
	sourceAppMap = {},
	eventColumns = [],
	onExpandedChange,
}) => {
	const getMethod = columnType === 'daily'
		? getDailyColumns
		: getSourceColumns;

	const eventColumnsWithRenderStatsCell = eventColumns.slice().map((ec) => {
		const eventCol = ec;
		eventCol.Cell = renderStatsCell;
		return eventCol;
	});

	const columns = getMethod({
		renderStatsCell,
		sourceAppMap,
		columnType,
		eventColumns: eventColumnsWithRenderStatsCell,
		onExpandedChange,
	});

	return columns.filter(c => c.permanent || selectedEventColumns.some(o => o === c.commonId))
};

const getSourceColumns = ({
	renderStatsCell,
	eventColumns,
	sourceAppMap = {},
	columnType,
	onExpandedChange,
}) => {
	const isTotalColumn = columnType === 'total';
	return [
		{
			Header: 'expander',
			Expander: () => null,
			expander: true,
			show: false,
			permanent: true,
			Cell: props => (
				<span className="campaign-table-name">
					{sourceAppMap[props.original.id] ? <AppIcon app={sourceAppMap[props.original.id]} isShadow={isShadow()} /> : ''}
				</span>
			),
		},
		{
			Header: isTotalColumn ? '' : 'Campaign Name',
			id: 'campaignName',
			accessor: 'campaignName',
			cast: 'string',
			width: 350,
			style: {
				overflow: 'visible',
			},
			sortable: false,
			permanent: true,
			Cell: props => (
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<div className={`rt-expander ${props.isExpanded ? '-open' : ''}`} onClick={() => onExpandedChange(props.viewIndex)}></div>
					<span className="campaign-table-name">
						{isTotalColumn
							? <span style={{ fontWeight: 'bold' }}>Total</span>
							: (
								<React.Fragment>
									{sourceAppMap[props.original.id] ? <AppIcon app={sourceAppMap[props.original.id]} /> : ''}
									<span style={{ marginLeft: 20 }}>{shadowText(props.value, 'Example Campaign')} - {props.original.id} - {props.original.type}</span>
								</React.Fragment>
							)
						}
					</span>
				</div>
			)
		},
		{
			Header: isTotalColumn ? '' : 'Type',
			id: 'type',
			accessor: 'type',
			width: 70,
			permanent: true,
			Cell: props => props?.value || '',
		},
		{
			Header: isTotalColumn ? '' : 'Source Name',
			id: 'sourceName',
			accessor: 'name',
			cast: 'string',
			width: 300,
			style: {
				overflow: 'visible',
			},
			sortable: false,
			permanent: true,
			Cell: props => (!isTotalColumn && props.value) ? shadowText(`${props.original.id} - ${props.value}`, 'Example Source') : '',
		},
		{
			Header: 'Clicks',
			id: 'click',
			accessor: 'click__total',
			cast: 'string',
			width: 200,
			Cell: renderStatsCell,
			sortable: true,
			permanent: true,
		},
		{
			Header: 'Installs',
			id: 'install',
			accessor: 'install__total',
			cast: 'string',
			width: 200,
			Cell: renderStatsCell,
			sortable: true,
			permanent: true,
		},
		{
			Header: 'Events',
			id: 'event__total',
			accessor: 'event__total',
			cast: 'string',
			width: 200,
			Cell: renderStatsCell,
			sortable: true,
			permanent: true,
		},
		{
			Header: 'Cost',
			id: 'cost',
			accessor: 'cost',
			cast: 'string',
			Cell: props => {
				if (!props.value && props.value !== 0) return '';

				return (
					<Tooltip tooltip={props.original.costByEvent ? props.original.costByEvent.join(', ') : null}>
						<span style={{ cursor: 'default', color: 'var(--green)' }}>{props.original.payout_symbol}{Humanize.formatNumber(props.value)}</span>
					</Tooltip>
				)
			},
			sortable: true,
			permanent: true,
		},
		...eventColumns,
	];
};

export const getDailyColumns = ({ renderStatsCell, eventColumns }) => {
	return [
		{
			Header: '',
			id: 'appName',
			accessor: 'date',
			cast: 'string',
			sortable: false,
			width: 350,
			style: {
				overflow: 'visible',
			},
			permanent: true,
			Cell: props => (
				<span className="campaign-table-name">
					<Icon i="corner-down-right" size={18} />
					<span style={{ marginLeft: 20 }}>{props.value ? moment(props.original.date, 'X').format('DD/MM/YYYY') : '-'}</span>
				</span>
			),
		},
		{
			Header: 'Type',
			id: 'type',
			accessor: 'type',
			width: 70,
			permanent: true,
		},
		{
			Header: '',
			id: 'sourceName',
			accessor: 'name',
			cast: 'string',
			width: 300,
			style: {
				overflow: 'visible',
			},
			sortable: false,
			permanent: true,
			Cell: props => props.value ? shadowText(`${props.original.id} - ${props.value}`, 'Example Source') : '',
		},
		{
			Header: '',
			id: 'click',
			accessor: 'click___total',
			cast: 'string',
			width: 200,
			Cell: renderStatsCell,
			sortable: true,
			permanent: true,
		},
		{
			Header: '',
			id: 'install',
			accessor: 'install__total',
			cast: 'string',
			width: 200,
			Cell: renderStatsCell,
			sortable: true,
			permanent: true,
		},
		{
			Header: '',
			id: 'event__total',
			accessor: 'event__total',
			cast: 'string',
			width: 200,
			Cell: renderStatsCell,
			sortable: true,
			permanent: true,
		},
		{
			Header: 'Cost',
			id: 'cost',
			accessor: 'cost',
			cast: 'string',
			Cell: props => {
				if (!props.value && props.value !== 0) return '';

				return (
					<Tooltip tooltip={props.original.costByEvent ? props.original.costByEvent.join(', ') : null}>
						<span style={{ cursor: 'default', color: 'var(--green)' }}>{props.original.payout_symbol}{Humanize.formatNumber(props.value)}</span>
					</Tooltip>
				)
			},
			sortable: true,
			permanent: true,
		},
		...eventColumns,
	];
};