import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
	Box,
	NoData,
	FraudGraph,
} from 'interceptd-ui';

import KPISettings from '../KPISettings';
import Table from '../Table';

import { columns as KPIColumns } from './KPIColumns';
import { columns as KPISourceColumns } from './KPISourceColumns';

import Local from '../../services/localStorage';

import { EmptyStatsObjects } from '../../constants/Campaign';

export default class KPIPerformanceTable extends PureComponent {
  state = {
    resizedState: [],
		expandedState: {},
  };

  setResized = (newResized) => {
		this.setState({
			resizedState: newResized,
		});
  }
  
  onExpandedChange = (index) => this.setState(prevState => ({
		expandedState: {
			[index]: !prevState.expandedState[index],
		},
  }));

  renderFraudGraphCell = ({ original, column, value }) => {
		const { loading } = this.props;
		const data = value || EmptyStatsObjects.event;

		return (
			<div className="campaign-table-fraud-bar-container">
				<FraudGraph
					data={data}
					title={column.Header}
					loading={loading} />
			</div>
		);
	}
  
  renderKPISources = ({ original }) => {
    const { resizedState } = this.state;
    const data = original?.sources;

    if (!data || data.length === 0) {
      return null;
    }

		return (
			<div className="sources">
				<Table
					data={original.sources}
					columns={
						KPISourceColumns({
							renderFraudGraphCell: this.renderFraudGraphCell
						})
					}
					PadRowComponent={() => null}
					resized={resizedState}
					sortable={false}
					showPageSizeOptions={false}
					showPageJump={false}
					showPagination={false}
				/>
			</div>
		)
	}

  render() {
    const {
      campaign,
      getCampaign,
      data,
      revenueCurrency,
      loading,
    } = this.props;
    const { expandedState } = this.state;

    return (
      <Box
        className="box-table-wrapper"
        title="KPI Performances"
        right={
          <Fragment>
          	{Local.getItem('me')?.plans?.interceptd !== 'free' &&
            	<KPISettings refreshKpi={() => getCampaign && getCampaign(false)} campaign={campaign} />
          	}
          </Fragment>
        }>
        <Table
          data={data}
          columns={
            KPIColumns({
              revenueCurrency,
              renderFraudGraphCell: this.renderFraudGraphCell,
              onExpandedChange: this.onExpandedChange,
            })
          }
          loading={loading}
          PadRowComponent={() => null}
          sortable={false}
          showPageSizeOptions={false}
          showPageJump={false}
          showPagination={false}
          SubComponent={this.renderKPISources}
          expanded={expandedState}
          onResizedChange={this.setResized}
          getTrGroupProps={(state, rowInfo) => {
            const { expandedState } = this.state;
            if (rowInfo && expandedState[rowInfo.index]) {
              return ({
                className: '-open'
              })
            }
          }}
          NoDataComponent={() => <NoData icon="box">Set a KPI to see the stats</NoData>}
        />
      </Box>
    );
  }
}

KPIPerformanceTable.propTypes = {
  campaign: PropTypes.object,
  getCampaign: PropTypes.func,
  data: PropTypes.array,
};