import React from 'react';
import TextareaAutosize from 'react-autosize-textarea';

import './styles/TrackingUrlArea.css';

class TrackingUrlArea extends React.Component {
	state = {
		maxHeight: false,
	}

	constructor(props) {
		super(props)

		this.additions = React.createRef();
	}

	setMaxHeight = () => {
		this.setState({
			maxHeight: this.additions.current.scrollHeight
		});
	}

	removeMaxHeight = () => {
		this.setState({
			maxHeight: false
		});
	}
	
	render() {
		const { className, label, id, name, additions, required, validation, autosize, onChange, ...rest } = this.props;
		const { maxHeight } = this.state;

		return (
			<div className={`
				tracking-url
				${className || ''}
				${required ? 'required' : ''}
				${validation ? validation(rest.value) ? 'filled' : '' : rest.value && rest.value !== '' ? 'filled' : ''}
			`} onMouseOver={additions ? this.setMaxHeight : undefined} onMouseLeave={additions ? this.removeMaxHeight : undefined}>
				{label &&
					<label className="tracking-url-label" htmlFor={id || name}>{label}</label>
				}
				{autosize ? (
					<TextareaAutosize className="tracking-url-content autosize" onChange={event => onChange(event, name)} {...rest} />
				) : (
					<textarea className="tracking-url-content" onChange={event => onChange(event, name)} {...rest} />
				)}
				{additions &&
					<div className="tracking-url-additions" ref={this.additions} style={{ '--max-height': maxHeight ? `${maxHeight}px` : undefined }}>{additions}</div>
				}
			</div>
		)
	}
}

export default TrackingUrlArea;
