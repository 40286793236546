import React from 'react';
import ReactTable, { ReactTableDefaults } from 'react-table';
import Draggable from 'react-draggable';
import { Portal } from 'react-portal';
import { StickyContainer, Sticky } from 'react-sticky';
import { Loader } from 'interceptd-ui';

import NoResult from '../NoResult';

import './styles/Table.css';

class StickyTable extends React.Component {
	render() {
		return (
			<StickyContainer>
				<ReactTableDefaults.TableComponent {...this.props} />
			</StickyContainer>
		)
	}
}

class StickyThead extends React.Component {
	render() {		
		const { className, style: compStyle, ...rest } = this.props;
		return (
			<Sticky>
				{({ style, isSticky }) => (
					<div
						className={`rt-thead ${className} ${isSticky ? '-sticked' : ''}`}
						style={{ ...style, ...compStyle }}
						{ ...rest } />
				)}
			</Sticky>
		)
	}
}

class Table extends React.PureComponent {
	state = {
		dragging: false,
		minimapPercent: 0,
		id: `table-${Date.now()}`,
	}

	minimapInterval = null
	tableWrapper = React.createRef()
	minimapWrapper = React.createRef()
	minimap = React.createRef()
	minimapHandleWrapper = React.createRef()
	minimapHandle = React.createRef()
	minimapColumns = React.createRef()
	syncScrollTableRef = this.props.syncScrollTableRef;

	componentDidMount = () => {
		if ( this.props.minimap ) {
			this.minimapInterval = setInterval(this.calculateMinimap, 500);
			window.addEventListener('scroll', this.handleSticky);
			window.addEventListener('resize', this.handleSticky);
			this.handleSticky();
		}
	}

	componentWillUnmount = () => {
		if ( this.minimapInterval ) {
			clearInterval(this.minimapInterval);
			window.removeEventListener('scroll', this.handleSticky);
			window.removeEventListener('resize', this.handleSticky);
		}
	}

	handleMinimapDragStart = (e, data) => {
		this.setState({
			dragging: true
		})
	}

	handleMinimapDragStop = (e, data) => {
		this.setState({
			dragging: false
		})
	}

	setScrollLeft = (scrollLeft) => {
		const { id } = this.state;
		const table = document.querySelector(`.${id} .rt-table`);
		if (table) table.scrollLeft = scrollLeft;
	}

	handleMinimapDrag = (e, data) => {
		requestAnimationFrame(() => {
			const { id } = this.state;
			const table = document.querySelector(`.${id} .rt-table`);
			if ( table && this.minimapHandleWrapper.current ) {
				table.scrollLeft = data.x * table.scrollWidth / this.minimapHandleWrapper.current.offsetWidth;
				if ( this.syncScrollTableRef ) {
					this.syncScrollTableRef.current.setScrollLeft(table.scrollLeft);
				}
			}
			if ( this.minimapColumns.current && this.minimapHandleWrapper.current ) this.minimapColumns.current.scrollLeft = data.x * this.minimapColumns.current.scrollWidth / this.minimapHandleWrapper.current.offsetWidth;
		})
	}

	calculateMinimap = () => {
		requestAnimationFrame(() => {
			const { minimapPercent, id } = this.state;
			const table = document.querySelector(`.${id} .rt-table`);

			if ( table && minimapPercent !== table.offsetWidth / table.scrollWidth * 100 ) {
				this.setState({
					minimapPercent: table.offsetWidth / table.scrollWidth * 100
				})
			}
		})
	}

	calculateMinimapPosition = () => {
		const { id, dragging } = this.state;
		if ( dragging ) return false;
		const table = document.querySelector(`.${id} .rt-table`);
		if ( table && this.minimapHandleWrapper.current ) this.minimapHandle.current.style.transform = `translate(${table.scrollLeft * this.minimapHandleWrapper.current.offsetWidth / table.scrollWidth}px, 0px)`;
	}

	handleTableBodyScroll = () => {
		requestAnimationFrame(() => {
			const { dragging, id } = this.state;
			if ( dragging ) return false;

			const table = document.querySelector(`.${id} .rt-table`);
			if ( table && this.syncScrollTableRef ) {
				this.syncScrollTableRef.current.setScrollLeft(table.scrollLeft);
			}
			this.calculateMinimapPosition();
		})
	}

	handleSticky = (e) => {
		if ( this.tableWrapper.current && this.minimapWrapper.current ) {
			const minimapBounds = this.minimapWrapper.current.getBoundingClientRect();
			const tableBounds = this.tableWrapper.current.getBoundingClientRect();
			const viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

			if ( tableBounds.top <= (viewportHeight - minimapBounds.height) && tableBounds.bottom >= viewportHeight ) {
				this.setState({
					stick: true
				}, () => {
					this.calculateMinimap()
					this.calculateMinimapPosition()
				})
			} else {
				this.setState({
					stick: false
				}, () => {
					this.calculateMinimap()
					this.calculateMinimapPosition()
				})
			}
		}
	}

	render() {
		const { className, children, getTableProps, minimap, getTdProps, getTheadThProps, stickyHeader, ...rest } = this.props;
		const { stick, minimapPercent, id } = this.state;

		const isNoData =
			!rest.loading && (
				!rest.data || (
					rest.data && rest.data.length === 0
				)
			);

		return (
			<div className="table-wrapper" ref={this.tableWrapper}>
				<ReactTable
					ref="table"
					className={`
						${id}
						${className}
						${rest.loading ? '-loading': ''}
						${isNoData ? '-nodata': ''}
					`}
					pageSizeOptions={[5, 10, 20, 25, 50, 100]}
					getTableProps={minimap ? () => ({
						onScroll: this.handleTableBodyScroll
					}) : getTableProps}
					getTdProps={(state, rowInfo, column) => {
						return column.sticky ? ({
							className: `
								sticky-cell
								stick-to-${column.stickyPosition || 'right'}
							`,
							...(getTdProps ? getTdProps(state, rowInfo, column) : {})
						}) : (getTdProps ? getTdProps(state, rowInfo, column) : {})
					}}
					getTheadThProps={(state, rowInfo, column) => {
						return column.sticky ? ({
							className: `
								sticky-cell
								stick-to-${column.stickyPosition || 'right'}
							`,
							...(getTheadThProps ? getTheadThProps(state, rowInfo, column) : {})
						}) : (getTheadThProps ? getTheadThProps(state, rowInfo, column) : {})
					}}
					TableComponent={stickyHeader ? StickyTable : ReactTableDefaults.TableComponent}
					TheadComponent={stickyHeader ? StickyThead : ReactTableDefaults.TheadComponent}
					{...rest}>
					{children}
				</ReactTable>

				{minimap && minimapPercent < 98 && rest.data.length > 8 && (
					<div ref={this.minimapWrapper} className={`table-minimap-wrapper ${stick ? 'stick' : ''}`}>
						<div className="table-minimap-ghost" />
						{stick ? (
							<Portal>
								<div className="table-minimap stick" ref={this.minimap}>
									<div className="table-minimap-handler-wrapper" ref={this.minimapHandleWrapper}>
										<Draggable
											axis="x"
											bounds="parent"
											onStart={this.handleMinimapDragStart}
											onStop={this.handleMinimapDragStop}
											onDrag={this.handleMinimapDrag}>
											<div className="table-minimap-handler" ref={this.minimapHandle} style={{ '--handleWidth': `${minimapPercent}%` }} />
										</Draggable>
									</div>
									<div className="table-minimap-columns" ref={this.minimapColumns}>
										{rest.columns.map((c, i) => (
											<span key={`table-minimap-column-${i}`} className="table-minimap-column" />
										))}
									</div>
								</div>
							</Portal>
						) : (
							<div className="table-minimap" ref={this.minimap}>
								<div className="table-minimap-handler-wrapper" ref={this.minimapHandleWrapper}>
									<Draggable
										axis="x"
										bounds="parent"
										onStart={this.handleMinimapDragStart}
										onStop={this.handleMinimapDragStop}
										onDrag={this.handleMinimapDrag}>
										<div className="table-minimap-handler" ref={this.minimapHandle} style={{ '--handleWidth': `${minimapPercent}%` }} />
									</Draggable>
								</div>
								<div className="table-minimap-columns" ref={this.minimapColumns}>
									{rest.columns.map((c, i) => (
										<span key={`table-minimap-column-${i}`} className="table-minimap-column" />
									))}
								</div>
							</div>
						)}
					</div>
				)}
			</div>
		)
	}
}

Table.defaultProps = {
	className: '-striped -highlight',
	LoadingComponent: Loader,
	NoDataComponent: NoResult,
	showPageJump: false,
	stickyHeader: false,
	minimap: true,
	syncScrollTableRef: null,
};

export default Table;
