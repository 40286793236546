import React from 'react';

import './styles/Switch.css';

class Switch extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			on: props.on
		};

		this.handleClick = this.handleClick.bind(this);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({
			on: nextProps.on
		});
	}

	handleClick(e) {
		e.preventDefault();
		const { enabled, onClick } = this.props;
		const { on } = this.state;

		if ( enabled ) {
			this.setState({ on: !on }, () => {
				onClick(!on);
			});
		}
	};

	render() {
		const { className, style, enabled, children, mini } = this.props;
		const { on } = this.state;

		return (
			<div className={`switch-wrapper ${mini ? 'mini' : ''}`} style={style}>
				<div className={`
					switch
					${className}
					${on ? 'on' : ''}
					${enabled ? '' : 'disabled'}
				`} onClick={this.handleClick}>
					<div className="switch-toggle" />
				</div>
				{children}
			</div>
		);
	}
}

Switch.defaultProps = {
	on: false,
	onClick: () => {},
	enabled: true,
	className: ''
}

export default Switch;
