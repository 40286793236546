import React from 'react';
import Chart from 'react-apexcharts'

import { colors } from '../../../constants/Color';

class AppVersionPie extends React.PureComponent {
	render() {
		const { options, getOptions, series, ...rest } = this.props;
		return (
			<Chart options={getOptions(options)} series={series} height="300" type="pie" width="100%" {...rest} />
		)
	}
}

AppVersionPie.defaultProps = {
	getOptions: options => options,
	options: {
		chart: {
			id: 'app-version-pie',
			type: 'pie',
		},
		colors,
		responsive: [{
			breakpoint: 1400,
			options: {
				height: 450,
			},
		}],
	},
}

export default AppVersionPie;