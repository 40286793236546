import axios from 'axios';

const CAMPAIGN_LIST = '/campaign/v2/campaigns';
const SOURCE_STATS = '/campaign/v2/sources/stats';
const INSTALL_EVENT = '/leopar/stats';
const GET_SOURCE_KPI_STATS = '/leopar/event/stats';
const GET_DAILY_IMPRESSION = '/campaign/v2/sources/daily-impression-stats';
const SOURCE_DAILY_STATS = '/campaign/v2/sources/daily-stats';
const RULESET_CAMPAIGN = '/ruleset/campaign';
const CAMPAIGN_RULESET = '/campaign/ruleset/campaign';
const LEOPAR_ALARM = '/leopar/alarm';
const LEOPAR_DAILY_STATS = '/leopar/daily-stats';
const SOURCE_GRAPH_DATA = '/leopar/source';
const LEOPAR_SUBPUB_LIST = '/leopar/subpub/list';
const LEOPAR_SUBPUB_EVENT_LIST = '/leopar/subpub/event/list';
const SUBPUB_V2 = '/subpub/v2';

const mockApi = function mockApi(options) {
	this.defaults = {
		...axios.defaults,
	};

	// for file download
	if (options && options.method === 'GET') {
		return axios(options);
	}
};

mockApi.get = (path) => {
	return new Promise(async (resolve, reject) => {
		try {
			const [folder, params] = path.split('?');

			console.log('Mock Api Request Logs:', { folder, params });

			let detail = '';
			if (params) {
				const searchParams = new URLSearchParams(params);

				if (folder.indexOf('sankey') === -1) {
					switch (folder) {
						case CAMPAIGN_LIST:
							detail = `${searchParams.get('id')}/`;
							break;

						case SOURCE_STATS:
						case INSTALL_EVENT:
						case GET_DAILY_IMPRESSION:
						case LEOPAR_DAILY_STATS:
						case SOURCE_DAILY_STATS: {
							const sourceIds = searchParams.get('source_ids').split(',');
							const data = sourceIds.map((sourceId) => require(`../mocks${folder}/${sourceId}/get.json`));
							return resolve({ data: { data: data.flat(Infinity) } });
						}
						case RULESET_CAMPAIGN:
						case CAMPAIGN_RULESET: {
							const campaignIds = searchParams.get('ids').split(',');
							const data = campaignIds.map((campaignId) => require(`../mocks${folder}/${campaignId}/get.json`));
							return resolve({ data: { data: data.flat(Infinity) } });
						}
						case SOURCE_GRAPH_DATA: {
							const sourceId = searchParams.get('source_id');
							const type = searchParams.get('type');
							const metricType = searchParams.get('metric_type');
							const data = require(`../mocks${folder}/${sourceId}/${type}/${metricType}/get.json`);
							return resolve({ data: { data } });
						}
						case LEOPAR_ALARM:
						case LEOPAR_SUBPUB_LIST:
						case LEOPAR_SUBPUB_EVENT_LIST:
						case SUBPUB_V2: {
							const campaignId = searchParams.get('campaign_id');
							const data = require(`../mocks${folder}/${campaignId}/get.json`);
							return resolve({ data: { data: Array.isArray(data) ? data.flat(Infinity) : data } });
						}
						default:
							break;
					}
				} else {
					const sourceIds = searchParams.get('source_ids').split(',');
					const eventNames = searchParams.get('event_names');

					if (!eventNames) {
						const data = sourceIds.map((sourceId) => require(`../mocks${folder}/${sourceId}/get.json`));
						return resolve({ data: { data: data.flat(Infinity) } });
					}

					const events = eventNames.split(',');

					const data = sourceIds.map((sourceId) => {
						const d = [];
						events.forEach((e) => {
							d.push(require(`../mocks${folder}/${sourceId}/${e}/get.json`))
						});
						return d;
					});

					return resolve({ data: { data: data.flat(Infinity) } });
				}
			}

			const response = require(`../mocks${folder}/${detail}get.json`);
			resolve({ data: response });
		} catch (err) {
			console.error('Mock Api Error: ', err);
			reject({ data: err });
		}
	});
};

mockApi.post = (path, payload) => {
	return new Promise((resolve, reject) => {
		try {
			const [folder] = path.split('?');

			if (path === GET_SOURCE_KPI_STATS) {
				const data = [];

				payload.params.forEach((pay) => {
					const stats = require(`../mocks${path}/${pay.source_id}/post.json`);
					const sourceData = stats.filter((stat) => pay.event_names.indexOf(stat.event_name) !== -1);
					data.push(sourceData);
				});
				
				return resolve({ data: { data: data.flat(Infinity) } });
			}

			if (path.indexOf('login') === -1) {
				return reject({
					response: {
						data: {
							error: {
								message: 'Demo account can not make changes'
							}
						}
					}
				});
			}

			const response = require(`../mocks${folder}/post.json`);
			resolve({ data: response });
		} catch (err) {
			console.error('POST ERROR: ', err);
		}
	});
};

mockApi.put = (path, payload) => {
	const [folder] = path.split('?');
	return new Promise((resolve, reject) => {
		const response = require(`../mocks${folder}/put.json`);
		resolve({ data: response });
	})
};

mockApi.delete = (path) => {
	const [folder] = path.split('?');
	return new Promise((resolve, reject) => {
		const response = require(`../mocks${folder}/delete.json`);
		resolve({ data: response });
	})
};

export default mockApi;