import React, { Fragment } from 'react';
import intersection from 'lodash/intersection';
import {
	Box,
	NoData,
	Icon,
	Button,
} from 'interceptd-ui';

import Table from '../../../components/Table';

import Local from '../../../services/localStorage';

import { parseArray } from '../../../utils/misc';

import { columns } from './ReportColumns';

export default function ReportListTable({
	fetching,
	reports,
	campaignFilter,
	goToCreate,
	getReports,
	download,
	renderCampaignsColumn,
	renderSharedPublisherColumn,
	reportType,
}) {
	const alarms = Local.getItem('supported_rules') || [];
	return (
		<Box
			className="box-table-wrapper report-list-table"
			title={
				<Button
					mini
					bgColor="transparent"
					className="add-new-campaign-button"
					onClick={goToCreate}>
					<Icon i="plus" size="15" /> Create New
        </Button>
			}
			right={
				<Fragment>
					<Icon i="refresh-ccw" size={18} className={fetching ? 'spin' : ''} onClick={() => fetching ? null : getReports()} />
				</Fragment>
			}>
			<Table
				data={reports.filter(report => campaignFilter.length > 0 ? report.source_id ? intersection(parseArray(report.source_id), campaignFilter.flatMap(c => c.sources.map(s => s.id))).length > 0 : report.campaign_id ? campaignFilter.some(c => c.id === report.campaign_id) : true : true)}
				columns={columns(
					download,
					renderCampaignsColumn,
					renderSharedPublisherColumn,
					reportType,
					alarms,
				)}
				PadRowComponent={() => null}
				loading={fetching}
				pageSize={10}
				showPageSizeOptions={false}
				showPageJump={true}
				defaultSortDesc={true}
				NoDataComponent={() =>
					<NoData icon="file-minus">
						You didn't create any report in these date range
								<Button onClick={goToCreate}>Create New</Button>
					</NoData>
				} />
		</Box>
	);
}