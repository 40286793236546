import React from 'react';
import { Link } from 'react-router-dom';
import { AppIcon } from 'interceptd-ui';

import { shadowText, isShadow } from '../../utils/misc';

export const columns = ({
	renderActions,
	renderWarnings,
	renderStatsCell,
	renderRulesCell,
	getTotalStats,
	onTargetChange,
	eventsColumnWidth,
	onExpandedChange,
	showImpression,
}) => [
	{
		Header: 'expander',
		Expander: () => null,
		expander: true,
		show: false,
	},
	{
		Header: 'Name',
		id: 'name',
		accessor: 'name',
		cast: 'string',
		minWidth: 350,
		style: {
			overflow: 'visible',
		},
		sticky: true,
		stickyPosition: 'left',
		Cell: props => (
			<React.Fragment>
				<div className={`rt-expander ${props.isExpanded ? '-open' : ''}`} onClick={() => onExpandedChange(props.viewIndex)}></div>
				<span className="campaign-table-name">
					<AppIcon app={props?.original?.app_name ? props?.original?.app_info || {} : undefined} isShadow={isShadow()} />
					<Link to={`/campaign/${props.original.id}`}>
						{shadowText(props.value, 'Example Campaign')} - {props.original.id} - {props.original.type}
					</Link>
					{renderWarnings(props)}
				</span>
			</React.Fragment>
		)
	},
	{
		Header: 'Rules',
		id: 'rules',
		accessor: 'id',
		cast: 'actions',
		width: 120,
		resizable: false,
		sortable: false,
		style: {
			overflow: 'visible',
		},
		Cell: renderRulesCell
	},
	{
		Header: 'Impression',
		id: 'impression',
		accessor: props => getTotalStats(props, 'impression'),
		cast: 'number',
		width: 220,
		show: showImpression,
		Cell: renderStatsCell,
	},
	{
		Header: 'Clicks',
		id: 'click',
		accessor: props => getTotalStats(props, 'click'),
		cast: 'number',
		width: 220,
		Cell: renderStatsCell,
	},
	{
		Header: 'Installs',
		id: 'install',
		accessor: props => getTotalStats(props, 'install'),
		cast: 'number',
		width: 220,
		Cell: renderStatsCell,
	},
	{
		Header: 'Events',
		id: 'event',
		accessor: props => getTotalStats(props, 'event'),
		cast: 'number',
		minWidth: eventsColumnWidth,
		Cell: renderStatsCell,
	},
	{
		Header: '',
		id: 'actions',
		accessor: 'id',
		cast: 'actions',
		width: 65,
		resizable: false,
		sortable: false,
		sticky: true,
		headerStyle: {
			overflow: 'visible',
			justifyContent: 'center',
			padding: 0,
		},
		style: {
			overflow: 'visible',
			justifyContent: 'center',
			padding: 0,
		},
		Cell: props => renderActions(props)
	},
];