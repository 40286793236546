import React from 'react';
import { Icon } from 'interceptd-ui';

import { shadowText } from '../../utils/misc';

export const columns = ({
	renderFraudGraphCell
}) => [
	{
		Header: '',
		id: 'sourceName',
		accessor: 'sourceName',
		cast: 'string',
		sortable: false,
		width: 300,
		style: {
			overflow: 'visible',
		},
		permanent: true,
		Cell: props => (
			<span className="campaign-table-name">
				<Icon i="corner-down-right" size={18} />
				<span>{shadowText(props.value, 'Example Source')}</span>
			</span>
		)
	},
	{
		Header: '',
		id: 'kpi_table_count',
		accessor: 'count',
		cast: 'string',
		width: 220,
		Cell: renderFraudGraphCell,
	},
	{
		Header: '',
		id: 'kpi_table_rcount',
		accessor: 'rCount',
		cast: 'string',
		width: 220,
		Cell: renderFraudGraphCell,
	},
	{
		Header: 'Amount',
		id: 'kpi_table_amount',
		accessor: 'amount',
		cast: 'string',
		width: 220,
		Cell: renderFraudGraphCell,
	},
	{
		Header: '',
		id: 'kpi_table_ramount',
		accessor: 'rAmount',
		cast: 'string',
		width: 220,
		Cell: renderFraudGraphCell,
	},
];